import { HttpEventType } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { FINDINGS_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/findings-permission';
import { MaterialCode } from 'src/app/models/material-code';
import { FindingsService } from 'src/app/services/findings.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { FindingRoutingDialogComponent } from '../finding-routing/finding-routing-dialog.component';
import { FindingStockDialogComponent } from '../finding-stock-dialog/finding-stock-dialog.component';
import { Finding } from '../model';
import { countDecimalPlaces } from '../util';
import { FINDINGS_TYPE, FINDING_MAX_WEIGHT } from './../constants';
import { FindingsStock } from './../model';
import { LobsterClawDialogComponent } from './lobster-claw-dialog/lobster-claw-dialog.component';

@Component({
  selector: 'finding-lobster-claw',
  templateUrl: './lobster-claw.component.html',
  styleUrls: ['./lobster-claw.component.scss'],
})
export class LobsterClawComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() findings: Finding[];
  @Output() onReloadList = new EventEmitter();
  @Output() onOpenSetting = new EventEmitter<number>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selectedLobsterClaw: Finding;
  selectedStock: FindingsStock;
  stocks: FindingsStock[] = [];
  lobsterClaws = new MatTableDataSource<Finding>([]);
  materialCodes: MaterialCode[] = [];
  displayedColumns = ['name', 'size_1'];

  isSavingStock = false;
  defaultFindingsId = 0;
  findingsType = FINDINGS_TYPE;
  featureKey = FINDINGS_FEATURE_KEY;

  constructor(
    private toastrService: ToastrService,
    private findingsService: FindingsService,
    private materialCodeService: MaterialCodeService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const selectedFindingsId = localStorage.getItem(
      'selected-findings-id-lobster-claws',
    );
    if (selectedFindingsId) {
      this.defaultFindingsId = +selectedFindingsId;
      localStorage.removeItem('selected-findings-id-lobster-claws');
    }
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject;
    });
  }
  ngOnChanges(): void {
    this.lobsterClaws.data = this.findings.filter(
      (f) => f.findingsTypesID === FINDINGS_TYPE.LOBSTER_CLAW,
    );
    this.selectedLobsterClaw = this.defaultFindingsId
      ? this.lobsterClaws.data.find(
          (f) => f.findingsId === this.defaultFindingsId,
        )
      : this.lobsterClaws.data?.[this.lobsterClaws.data?.length - 1] ??
        ({} as Finding);
    this.getStockBySizeId();
  }
  ngAfterViewInit() {
    this.lobsterClaws.sort = this.sort;
    this.lobsterClaws.paginator = this.paginator;
  }
  getStockBySizeId() {
    if (!this.selectedLobsterClaw.findingSizesID) {
      this.selectedStock = {} as FindingsStock;
      return;
    }
    this.findingsService
      .getFindingsStockBySizeId(this.selectedLobsterClaw.findingSizesID)
      .subscribe((data) => {
        this.stocks = data ?? [];
        const [stock = {} as FindingsStock] = data ?? [];
        this.selectedStock = this.selectedStock.materialsCodeID
          ? {
              ...(data.find(
                (s) => s.materialsCodeID === this.selectedStock.materialsCodeID,
              ) ?? ({} as FindingsStock)),
            }
          : { ...stock };
      });
  }

  uploadFindingImage(files, input: HTMLInputElement) {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    formData.append('file[]', files[0]);

    formData.append(
      'findingsId',
      this.selectedLobsterClaw.findingsId.toString(),
    );

    this.findingsService.uploadImage(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' + Math.round((100 * event.loaded) / event.total) + '%',
        );
      } else if (event.type === HttpEventType.Response) {
        this.selectedLobsterClaw.picPath = event.body;
        input.value = '';
      }
    });
  }
  onTableRowClick(selected: Finding) {
    this.selectedLobsterClaw = selected;
    this.getStockBySizeId();
  }
  onAddNewLobsterClaw() {
    const dialogRef = this.dialog.open(LobsterClawDialogComponent, {
      disableClose: true,
      maxWidth: '300px',
      width: '100%',
      data: {
        lobsterClaws: this.lobsterClaws.data,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((reload: boolean) => {
      if (reload) {
        this.onReloadList.emit();
      }
    });
  }
  onSaveStock() {
    const {
      findingsStockID,
      findingsSizesID,
      materialsCodeID,
      weight_G,
      qtyInStock,
      suppliersID,
    } = this.selectedStock;
    const stock = {
      findingsStockID,
      weight_G,
      qtyInStock,
      suppliersID,
      materialCodesID: materialsCodeID,
      findingsSizesID:
        findingsSizesID ?? this.selectedLobsterClaw.findingSizesID,
    };
    this.isSavingStock = true;
    this.findingsService.setFindingsStock(stock).subscribe(() => {
      this.getStockBySizeId();
      this.toastrService.success('Successfully saved.', 'Lobster Claw');
      this.isSavingStock = false;
    });
  }
  onChangeMaterialCode() {
    const stock = this.stocks.find(
      (s) => s.materialsCodeID === this.selectedStock.materialsCodeID,
    );
    this.selectedStock = stock
      ? { ...stock }
      : ({
          materialsCodeID: this.selectedStock.materialsCodeID,
        } as FindingsStock);
  }
  get isFormValid() {
    return (
      this.selectedStock.materialsCodeID &&
      this.selectedStock.qtyInStock >= 0 &&
      Number.isInteger(this.selectedStock.qtyInStock) &&
      this.selectedStock.weight_G < 100 &&
      countDecimalPlaces(this.selectedStock.weight_G) <= FINDING_MAX_WEIGHT
    );
  }

  onRoutingDialogOpen() {
    this.dialog.open(FindingRoutingDialogComponent, {
      disableClose: true,
      maxWidth: '1400px',
      width: '100%',
      autoFocus: false,
      data: {
        findingId: this.selectedLobsterClaw.findingsId,
        editMode: true,
      },
    });
  }
  onStockDialogOpen() {
    const ref = this.dialog.open(FindingStockDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
      autoFocus: false,
      data: {
        selectedFinding: this.selectedLobsterClaw,
        stocks: this.stocks,
      },
    });
    ref.afterClosed().subscribe((isReload) => {
      if (isReload) {
        this.getStockBySizeId();
      }
    });
  }
  onOpen() {
    this.onOpenSetting.emit(FINDINGS_TYPE.LOBSTER_CLAW);
  }
}
