<h3 mat-dialog-title>Custom Component</h3>

<mat-dialog-content class="mat-typography">
  <div class="sub-item-dialog__column">
    <div>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput autofocus [(ngModel)]="data.name" [maxlength]="50" />
      </mat-form-field>
    </div>
    <div class="custom-grid-1">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>UOM</mat-label>
        <mat-select [(ngModel)]="data.uom">
          <mat-option
            *ngFor="let unit of uomOptions$ | async"
            [value]="unit.unitsOfMeasureId"
          >
            {{ unit.uom }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Batch Qty</mat-label>
        <input matInput type="number" min="1" [(ngModel)]="data.batchQTY" />
      </mat-form-field>
    </div>
  </div>
  <div style="position: relative">
    <span style="position: absolute; right: 8px; top: 4px"
      >{{ data.description?.length ?? 0 }}/150</span
    >
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        autofocus
        [(ngModel)]="data.description"
        rows="4"
        [maxlength]="150"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="sub-item-dialog__column">
    <div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Sizes</mat-label>
        <mat-select [(ngModel)]="selectedSize" (ngModelChange)="onChangeSize()">
          <mat-option [value]="0">All</mat-option>
          <mat-option
            *ngFor="let size of props.sizes"
            [value]="size.productsSizesID"
          >
            {{ size.size }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Qty</mat-label>
        <input
          matInput
          type="number"
          min="1"
          [(ngModel)]="data.subItemsInfo?.[selectedSubItemIndex].qty"
          [disabled]="!data.subItemsInfo?.[selectedSubItemIndex]"
        />
      </mat-form-field>
    </div>
  </div>
  <div
    class="sub-item-dialog__column"
    [ngClass]="data.subItemsInfo?.[selectedSubItemIndex].mfgMethod === 1 || data.subItemsInfo?.[selectedSubItemIndex].mfgMethod === 2 ? 'sub-item-dialog__column' : 'sub-item-dialog__column-one'"
  >
    <div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>MFG Method</mat-label>
        <mat-select
          [(ngModel)]="data.subItemsInfo?.[selectedSubItemIndex].mfgMethod"
          [disabled]="selectedSize == null"
        >
          <mat-option
            *ngFor="let option of moldingOptions"
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field
      style="width: 100%"
      appearance="fill"
      *ngIf="data.subItemsInfo?.[selectedSubItemIndex].mfgMethod === 1 || data.subItemsInfo?.[selectedSubItemIndex].mfgMethod === 2"
    >
      <mat-label>MFG Details</mat-label>
      <input
        matInput
        autofocus
        [(ngModel)]="data.subItemsInfo?.[selectedSubItemIndex].mfgMethodDetails"
      />
    </mat-form-field>
  </div>
  <div class="sub-items__image-container">
    <div>
      <div class="sub-items__image-file" *ngIf="!data.picPath">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-photo"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="15" y1="8" x2="15.01" y2="8" />
          <rect x="4" y="4" width="16" height="16" rx="3" />
          <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
          <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
        </svg>
        No Image
      </div>
      <img
        *ngIf="data.picPath"
        style="width: 100%"
        [src]="sanitize(data.picPath)"
        onerror="this.src='assets/images/no-image.png'"
      />
      <input
        type="file"
        #uploadImage
        (change)="onUploadChange(uploadImage.files)"
        style="display: none"
        accept="image/*"
        multiple
      />
      <button
        class="riva-button"
        (click)="uploadImage.click()"
        style="
          margin: 15px 0;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-upload"
          width="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          style="margin-right: 10px"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
          <polyline points="7 9 12 4 17 9" />
          <line x1="12" y1="4" x2="12" y2="16" />
        </svg>
        Upload
      </button>
    </div>
    <div *ngIf="data.subItemsInfo?.[selectedSubItemIndex].mfgMethod === 3">
      <div class="sub-items__image-file">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-3d-cube-sphere"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M6 17.6l-2 -1.1v-2.5" />
          <path d="M4 10v-2.5l2 -1.1" />
          <path d="M10 4.1l2 -1.1l2 1.1" />
          <path d="M18 6.4l2 1.1v2.5" />
          <path d="M20 14v2.5l-2 1.12" />
          <path d="M14 19.9l-2 1.1l-2 -1.1" />
          <line x1="12" y1="12" x2="14" y2="10.9" />
          <line x1="18" y1="8.6" x2="20" y2="7.5" />
          <line x1="12" y1="12" x2="12" y2="14.5" />
          <line x1="12" y1="18.5" x2="12" y2="21" />
          <path d="M12 12l-2 -1.12" />
          <line x1="6" y1="8.6" x2="4" y2="7.5" />
        </svg>
        <span *ngIf="!data.subItemsInfo?.[selectedSubItemIndex].stlPath"
          >No STL File</span
        >
        <button
          *ngIf="data.subItemsInfo?.[selectedSubItemIndex].stlPath"
          class="riva-button riva-button-sm"
          style="width: 100px; height: 30px; margin-top: 5px"
          (click)="onViewStlFile()"
        >
          View STL File
        </button>
      </div>
      <input
        type="file"
        #uploadStlFile
        (change)="onUploadStlChange(uploadStlFile.files)"
        style="display: none"
        accept=".stl"
      />
      <div class="progress-container" *ngIf="isUploadingStl">
        <nz-progress
          [nzPercent]="stlUploadingProgress"
          nzSize="small"
          [nzStatus]="stlUploadStatus"
        ></nz-progress>
        <p style="color: #ef5350" *ngIf="stlUploadStatus === 'exception'">
          There is an error while uploading the STL File.
        </p>
      </div>
      <button
        *ngIf="!isUploadingStl"
        class="riva-button"
        (click)="uploadStlFile.click()"
        style="
          margin: 15px 0;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-upload"
          width="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          style="margin-right: 10px"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
          <polyline points="7 9 12 4 17 9" />
          <line x1="12" y1="4" x2="12" y2="16" />
        </svg>
        Upload STL File
      </button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px"
>
  <button
    class="btn btn-default"
    mat-dialog-close
    style="font-size: 12px; padding: 5px; height: 40px; color: white"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    style="font-size: 12px; padding: 5px; height: 40px"
    (click)="onSave()"
    [disabled]="isSaving || !data.name || !data.uom"
  >
    Save
  </button>
</mat-dialog-actions>

<swal
  #errorSubItem
  [title]="error.title"
  [text]="error.description"
  [focusConfirm]="false"
  icon="error"
  confirmButtonText="Ok, Got it."
>
</swal>
