import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductEnamel } from 'src/app/models/product';
import { EnamelService } from 'src/app/services/enamel.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';
import { SupplierService } from 'src/app/services/supplier.service';
import { ConfirmationService } from './../../riva-confirmation/riva-confirmation.service';

@Component({
  selector: 'app-enamel-variation',
  templateUrl: './enamel-variation.component.html',
  styleUrls: ['./enamel-variation.component.scss'],
})
export class EnamelVariationComponent implements OnInit, OnChanges {
  @Input() productId: number;
  variationDisplayedColumns: string[] = [
    'color',
    'sku',
    'name',
    'pantoneColor',
    'hardness',
    'delete',
  ];
  productVariations = new MatTableDataSource<ProductEnamel>([]);

  constructor(
    private enamelService: EnamelService,
    private supplierService: SupplierService,
    private productService: ProductService,
    private productBomService: ProductBomService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.productService.reloadEnamelVariation = () => {
      this.getProductVariation();
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.productVariations.data = [];
    if (this.productId) {
      this.getProductVariation();
    }
  }

  getProductVariation() {
    forkJoin([
      this.enamelService.getList(),
      this.supplierService.getList(),
      this.productBomService.getProductEnamel(this.productId),
    ])
      .pipe(
        map(([enamelList, supplierList, productVariationList]) => {
          const enamels = enamelList.map((enamel) => {
            const currentSupplier = supplierList.find(
              (s) => s.suppliersId === enamel.supplierId,
            );
            enamel.supplierDisplayName = currentSupplier
              ? `(${currentSupplier.supplierId}) ${currentSupplier.companyName}`
              : '';

            return enamel;
          });

          const productVariations = productVariationList
            .filter((v) => v.variation)
            .map((p) => {
              const enamel =
                enamels.find((e) => e.enamelId === p.enamelID) ?? {};
              p.supplierId = enamel.supplierId;
              p.supplierDisplayName = enamel.supplierDisplayName;
              const pantoneColor = enamel.pantoneColor ? `[${enamel.pantoneColor}] ` : '';
              p.displayText = `${pantoneColor}${p.enamelName}`;
              return p;
            });

          return { enamels, productVariations };
        }),
      )
      .subscribe(({ productVariations }) => {
        this.productVariations.data = productVariations;
      });
  }

  onDeleteVariation(variation: ProductEnamel) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Enamel Variation',
        content: 'Are you sure you want to delete this variation?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productVariations.data = this.productVariations.data.filter(
          (p) => p.enamelID !== variation.enamelID,
        );
        if (variation.productsBOMEnamelID) {
          this.productService
            .deleteProductVariations(variation.productsBOMEnamelID)
            .subscribe();
        }
      });
  }
}
