import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ChainService } from 'src/app/services/chain.service';
import { ChainStyle } from './../models';

@Component({
  templateUrl: './chain-dialog.component.html',
  styleUrls: ['./chain-dialog.component.scss'],
})
export class ChainDialogComponent implements OnInit {
  isSaving = false;
  chainStyle: ChainStyle = {} as ChainStyle;
  chainStyles: ChainStyle[] = [];
  displayedColumns = ['styleName'];

  constructor(
    public dialogRef: MatDialogRef<ChainDialogComponent>,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private chainService: ChainService,
  ) {}

  ngOnInit(): void {
    this.getChainStyles();
  }

  get isFormValid() {
    return this.chainStyle.styleName;
  }

  getChainStyles() {
    this.chainService.getChainStyles().subscribe((data) => {
      this.chainStyles = data;
    });
  }

  onSaveChainStyle() {
    this.isSaving = true;
    this.chainService.setChainStyles(this.chainStyle).subscribe((id) => {
      this.toastrService.success('Chain style successfully saved.');
      this.dialogRef.close(id);
    });
  }
}
