import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import orderBy from 'lodash/orderBy';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ChainService } from 'src/app/services/chain.service';
import { ChainRaw } from '../../riva-chain/models';
import { ChainFinish } from '../models';

@Component({
  templateUrl: './chain-finish-dialog.component.html',
  styleUrls: ['./chain-finish-dialog.component.scss'],
})
export class ChainFinishDialogComponent implements OnInit {
  isSaving = false;
  chainFinish: ChainFinish = {
    name: '',
    description: '',
  } as ChainFinish;
  chainRawControl = new FormControl();
  chainRaw: ChainRaw = {} as ChainRaw;
  chainRaws: ChainRaw[] = [];
  filteredChainRaws: Observable<ChainRaw[]>;
  imageFiles: FileList | null = null;

  constructor(
    public dialogRef: MatDialogRef<ChainFinishDialogComponent>,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private chainService: ChainService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.getChainRaws();
  }

  getChainRaws() {
    this.chainService.getChainRaws().subscribe((data) => {
      this.chainRaws = orderBy(data, ['styleName']);

      this.filteredChainRaws = this.chainRawControl.valueChanges.pipe(
        startWith(this.chainRaw.styleName),
        map((value) => this._filterChainRaw(value)),
      );
    });
  }

  private _filterChainRaw(name: string): ChainRaw[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.chainRaws.filter(
        (option) =>
          option.styleName.toLowerCase().includes(filterValue) ||
          option.sku.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['styleName'];
      return this.chainRaws.filter(
        (option) =>
          option.styleName.toLowerCase().includes(filterValue.toLowerCase()) ||
          option.sku.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.chainRaws;
  }

  onUploadChange(files: FileList) {
    this.imageFiles = files;
    this.chainFinish.picPath = URL.createObjectURL(files[0]);
  }

  sanitize(url: string) {
    if (!url) return 'assets/images/no-image.png';
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  uploadImage(chainFinishedId) {
    if (!chainFinishedId) {
      return;
    }

    const formData = new FormData();

    formData.append('file[]', this.imageFiles[0]);

    formData.append('chainFinishedId', chainFinishedId.toString());

    this.chainService.uploadFinishedImage(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' + Math.round((100 * event.loaded) / event.total) + '%',
        );
      } else if (event.type === HttpEventType.Response) {
        this.toastrService.success('Chain successfully saved.');
        this.dialogRef.close(chainFinishedId);
      }
    });
  }

  get isFormValid() {
    return this.chainRaw.chainRawID && this.chainFinish.name;
  }

  displayFn(item: ChainRaw): string {
    let text = item?.sku ?? '';
    if (item?.chainStyle?.styleName) {
      text = text
        ? `${text} | ${item?.chainStyle?.styleName}`
        : item?.chainStyle?.styleName ?? '';
    }
    return text;
  }

  onSaveChainFinish() {
    this.chainFinish.chainRawID = this.chainRaw.chainRawID;
    this.isSaving = true;
    this.chainService.setChainFinished(this.chainFinish).subscribe((id) => {
      if (this.imageFiles?.length > 0) {
        this.uploadImage(id);
        return;
      }
      this.toastrService.success('Chain successfully saved.');
      this.dialogRef.close(id);
    });
  }
}
