import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { TokenStorageService } from './token-storage.service';
const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );
  constructor(
    private tokenStorageService: TokenStorageService,
    private authenticationService: AuthenticationService,
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.tokenStorageService.getToken();
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes('auth/signin') &&
          error.status === 401
        ) {
          return this.handle401Error(authReq, next);
        }
        return throwError(error);
      }),
    );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.tokenStorageService.getRefreshToken();
      const user = this.tokenStorageService.currentUser;
      if (user.usersID && token)
        return this.authenticationService
          .refreshToken(user.usersID, token)
          .pipe(
            switchMap((token: any) => {
              this.isRefreshing = false;
              this.tokenStorageService.saveToken(token.accessToken);
              this.tokenStorageService.saveRefreshToken(token.refreshToken);
              this.refreshTokenSubject.next(token.accessToken);

              return next.handle(
                this.addTokenHeader(request, token.accessToken),
              );
            }),
            catchError((err) => {
              this.isRefreshing = false;

              this.authenticationService.signOut();
              return throwError(err);
            }),
          );
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token))),
    );
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`),
    });
  }
}
export const authenticationInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
  },
];
