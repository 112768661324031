import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { OtherComponentsRoutingComponent } from './other-components-routing.component';

@Component({
  selector: 'other-components-routing-dialog',
  templateUrl: './other-components-routing-dialog.component.html',
})
export class OtherComponentsRoutingDialogComponent implements OnInit {
  @ViewChild(OtherComponentsRoutingComponent)
  routingComponent: OtherComponentsRoutingComponent;

  constructor(
    public dialogRef: MatDialogRef<OtherComponentsRoutingDialogComponent>,
    public loadService: LoadService,
    @Inject(MAT_DIALOG_DATA)
    public props: { otherComponentsId: number; editMode: boolean },
  ) {}

  ngOnInit(): void {}

  onSave() {
    this.routingComponent.onSaveRouting();
  }
}
