import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { AlertService } from 'src/app/custom/_alert';
import { SupplierService } from 'src/app/services/supplier.service';
import { GenericComponent } from '../generic/generic.component';
import {
  GemCuts,
  GemInventory,
  Gems,
  GemSizes,
  GemTypes,
} from './../../models/gems.model';
import { Suppliers } from './../../models/suppliers.model';
import { GemService } from './../../services/gem.service';

@Component({
  selector: 'app-gems',
  templateUrl: './gems.component.html',
  styleUrls: ['./gems.component.scss'],
})
export class GemsComponent extends GenericComponent implements OnInit {
  gemlist: GemInventory[]; // Data bind list
  allgemlist: GemInventory[]; // Stored list for dynamic binding

  gemCuts: GemCuts[] = []; // Gem Cuts
  gemTypes: GemTypes[] = [];
  allGemSizes: GemSizes[] = [];
  gemSizes: GemSizes[] = [];
  selectedCut = new GemCuts();
  selectedType = new GemTypes();
  selectedSize = new GemSizes();
  selectedInventory = new GemInventory();

  suppliers: Suppliers[];
  newSize: string = '';

  // This will control the behavior of the updating
  currentGem = new Gems();

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    private gemSvc: GemService,
    private supplierService: SupplierService,
    private userPermissionService: UserPermissionService,
  ) {
    super(loadService, alertService);
    this.userPermissionService.checkPagePermission(PAGE_NAME.stone);
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    forkJoin([
      this.gemSvc.getList(),
      this.gemSvc.getCutList(),
      this.gemSvc.getTypeList(),
      this.gemSvc.getSizeList(),
      this.supplierService.getList(),
    ])
      .pipe(
        map(([gemlist, cutlist, typelist, sizelist, suppliers]) => {
          this.gemlist = gemlist.responseObject;
          this.allgemlist = gemlist.responseObject;

          this.gemCuts = cutlist.responseObject;
          if (this.gemCuts.length > 0) {
            this.selectedCut = this.gemCuts[0];
          }

          this.gemTypes = typelist.responseObject;
          if (this.gemTypes.length > 0) {
            this.selectedType = this.gemTypes[0];
          }

          this.allGemSizes = sizelist.responseObject;

          this.getupdatedSize();

          this.suppliers = suppliers;

          this.getSelectedInventory();

          return { gemlist, cutlist, typelist, sizelist, suppliers };
        }),
      )
      .subscribe(
        () => {
          this.loadService.loadContent(false);
        },
        (error) => {
          this.alertService.error(error.statusText);
          this.loadService.loadContent(false);
        },
      );
  }

  getSelectedType(type: GemTypes) {
    this.selectedType = type;
    this.getSelectedInventory();
  }

  getSelectedCut(cut: GemCuts) {
    this.selectedCut = cut;
    this.getupdatedSize();

    this.getSelectedInventory();
  }

  getSelectedSize(size: GemSizes) {
    this.selectedSize = size;
    this.getSelectedInventory();
  }

  getSelectedInventory() {
    this.selectedInventory = new GemInventory();
    this.selectedInventory.gemTypesId = this.selectedType.gemTypesId;
    this.selectedInventory.gemCutsId = this.selectedCut.gemCutsId;
    if (this.selectedSize != null) {
      this.selectedInventory.gemSizesId = this.selectedSize.gemSizesId;
    }

    this.gemSvc.getGemInventory(this.selectedInventory).subscribe((res) => {
      this.selectedInventory = res.responseObject;
    });
  }

  saveInventory() {
    this.selectedInventory.stockQty = parseInt(
      this.selectedInventory.stockQty.toString(),
    );

    this.gemSvc.addupdate(this.selectedInventory).subscribe(
      (res) => {
        this.selectedInventory = res.responseObject;

        this.gemSvc.getList().subscribe(
          (ret) => {
            this.gemlist = ret.responseObject;
            this.allgemlist = ret.responseObject;
          },
          (err) => {
            err;
          },
        );

        this.alertService.clear();
        this.alertService.success('Gem Inventory SAVED!');
      },
      (error) => {
        this.alertService.error(
          error.statusText +
            '. ' +
            (error.error.errorText !== undefined ? error.error.errorText : ''),
        );
        this.loadService.loadContent(false);
      },
    );
  }

  addsize() {
    if (this.newSize == '') return;

    let gemsize = new GemSizes();
    gemsize.gemSizeDesc = this.newSize;
    gemsize.gemCutsId = this.selectedCut.gemCutsId;

    var gemcheck = this.allGemSizes.filter(
      (x) => x.gemSizeDesc == this.newSize && x.gemCutsId == gemsize.gemCutsId,
    );
    if (gemcheck.length > 0) {
      this.alertService.clear();
      this.alertService.success('Duplicate GEM SIZE!');
      return;
    }

    this.gemSvc.addupdateSize(gemsize).subscribe((res) => {
      this.allGemSizes.push(res);
      this.getupdatedSize();
      this.getSelectedInventory();
      this.newSize = '';
      this.alertService.clear();
      this.alertService.success('Added NEW SIZE!');
    });
  }

  getupdatedSize() {
    this.gemSizes = this.allGemSizes.filter(
      (x) => x.gemCutsId == this.selectedCut.gemCutsId,
    );

    // this.gemSizes = this.allGemSizes;
    if (this.gemSizes.length > 0) {
      this.selectedSize = this.gemSizes[0];
    } else {
      this.selectedSize = null;
    }
  }

  getHighlightedCut() {
    let value: Boolean = false;

    var gemInventory = this.gemlist.filter(
      (x) =>
        x.gemTypesId == this.selectedType.gemTypesId &&
        x.gemCutsId == this.selectedCut.gemCutsId,
    )[0];

    if (gemInventory != null || gemInventory != undefined) {
      value = true;
    }

    return value;
  }

  getHighlightedCutbyCut(cut: GemCuts) {
    let value: Boolean = false;

    var gemInventory = this.gemlist.filter(
      (x) =>
        x.gemTypesId == this.selectedType.gemTypesId &&
        x.gemCutsId == cut.gemCutsId,
    )[0];

    if (gemInventory != null || gemInventory != undefined) {
      value = true;
    }

    return value;
  }

  getHighlightedSizebyCut(item: GemSizes) {
    let value: Boolean = false;

    var gemInventory = this.gemlist.filter(
      (x) =>
        x.gemTypesId == this.selectedType.gemTypesId &&
        x.gemCutsId == this.selectedCut.gemCutsId &&
        x.gemSizesId == item.gemSizesId,
    )[0];

    if (gemInventory != null || gemInventory != undefined) {
      value = true;
    }

    return value;
  }
} // end class
