import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InvoiceDetail } from '../components/invoicing-shipping/invoicing/models/invoice';
import { Shipment } from '../components/invoicing-shipping/shipping/models/shipment';
import { ShipmentLookupItem } from '../components/invoicing-shipping/shipping/models/shipment-lookup-item';
import { ShipmentRelatedInvoice } from '../components/invoicing-shipping/shipping/models/shipment-related-invoice';
import { WorkOrderType } from '../components/work-order-view/work-order-type';
import { GenericService } from './generic.service';

const processFindingsSize = (size = '') => {
  return size
    .split('x')
    .reduce((sz, val) => {
      const trimmedValue = val.trim();
      return trimmedValue !== '' ? [...sz, trimmedValue] : sz;
    }, [])
    .join(' x ');
};

const getLookupProductName = (item, workOrderType) => {
  if (workOrderType === WorkOrderType.Finding) {
    return (
      item.productName ??
      `${item.findingsType} | ${processFindingsSize(item.size)}`
    );
  } else {
    return item.productName;
  }
};

@Injectable({
  providedIn: 'root',
})
export class ShipmentService extends GenericService {
  baseUrl = environment.apiUrl + 'shipments';

  constructor(http: HttpClient) {
    super(http);
  }

  getShipments() {
    return this.http
      .get<Shipment[]>(this.baseUrl, { headers: this.headers })
      .pipe(
        map((invoices) =>
          invoices.map((i) => ({
            ...i,
            shipDate: i.shipDate
              ? DateTime.fromJSDate(new Date(i.shipDate)).toFormat(
                  'MM/dd/yyyy hh:mm a',
                )
              : '',
          })),
        ),
      );
  }

  getPagedShipments(params) {
    return this.http.get<Shipment[]>(`${this.baseUrl}/paged`, {
      headers: this.headers,
      params: { ...params },
      observe: 'response',
    });
  }

  getShipmentById(id) {
    return this.http
      .get<Shipment>(`${this.baseUrl}/${id}`, { headers: this.headers })
      .pipe(
        map((shipment) => ({
          ...shipment,
          shipDate: shipment.shipDate
            ? DateTime.fromJSDate(new Date(shipment.shipDate)).toFormat(
                'MM/dd/yyyy hh:mm a',
              )
            : '',
          shipmentsDetails: shipment.shipmentsDetails.map((s) => ({
            ...s,
            productName:
              s.findingsType != null
                ? getLookupProductName(s, WorkOrderType.Finding)
                : s.productName,
          })),
        })),
      );
  }

  setShipments(data) {
    return this.http.post(this.baseUrl, data, {
      headers: this.headers,
    });
  }

  getLookUpItems(workOrderId, workOrderType = WorkOrderType.Product) {
    return this.http
      .get<ShipmentLookupItem[]>(`${this.baseUrl}/lookup-items`, {
        headers: this.headers,
        params: {
          workOrderId,
        },
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            qty: item.availableQtyToShip,
            productName: getLookupProductName(item, workOrderType),
          })),
        ),
      );
  }

  generateInvoice(shipmentId) {
    return this.http
      .get<InvoiceDetail[]>(`${this.baseUrl}/generate-invoice`, {
        headers: this.headers,
        params: {
          shipmentId,
        },
      })
      .pipe(
        map((invoicesDetails) =>
          invoicesDetails.map((d) => ({
            ...d,
            price: 0,
            weight: d.avgWeightGrams ?? 0,
            poNumber: d.poExternal ?? d.poInternal,
          })),
        ),
      );
  }

  getRelatedInvoices(shipmentId) {
    return this.http.get<ShipmentRelatedInvoice[]>(
      `${this.baseUrl}/invoices/${shipmentId}`,
      {
        headers: this.headers,
      },
    );
  }
}
