import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FindingsService } from 'src/app/services/findings.service';
import { FindingsType } from '../model';

@Component({
  templateUrl: './finding-setting-dialog.component.html',
  styleUrls: ['./finding-setting-dialog.component.scss'],
})
export class FindingSettingDialogComponent implements OnInit {
  isSaving = false;
  productSetting = {
    workOrderLimit: 0,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { findingsType: FindingsType },
    public dialogRef: MatDialogRef<FindingSettingDialogComponent>,
    private findingsService: FindingsService,
  ) {}

  ngOnInit(): void {}

  onSave() {
    this.isSaving = true;
    this.findingsService
      .setFindingsType(this.data.findingsType)
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }
}
