import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { StoneSize } from '../../riva-gems.model';
import { getDisplayText } from '../../stone.mapper';
import { StoneService } from '../../stone.service';

@Component({
  templateUrl: './stone-size-dialog.component.html',
  styleUrls: ['./stone-size-dialog.component.scss'],
})
export class StoneSizeDialogComponent implements OnInit {
  displayColumns = ['displayText'];
  isSaving = false;
  stoneSize: StoneSize = { stoneSizesID: 0 } as StoneSize;
  stoneSizes: StoneSize[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { productId: number },
    public dialogRef: MatDialogRef<StoneSizeDialogComponent>,
    private stoneService: StoneService,
  ) {}

  ngOnInit(): void {
    this.stoneService.getSizes().subscribe((data) => {
      this.stoneSizes = data;
    });
  }
  addDimension() {
    this.stoneSizes = [
      ...this.stoneSizes,
      { ...this.stoneSize, displayText: getDisplayText(this.stoneSize) },
    ];
    this.stoneSize = { stoneSizesID: 0 } as StoneSize;
  }
  onSave() {
    const requests = this.stoneSizes.reduce((r, s) => {
      if (s.stoneSizesID) return r;
      return [...r, this.stoneService.setStoneSize(s)];
    }, []);
    this.isSaving = true;
    forkJoin(requests).subscribe(() => {
      this.isSaving = false;
      this.dialogRef.close(true);
    });
  }
}
