import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { AlertService } from 'src/app/custom/_alert';
import { GenericComponent } from '../generic/generic.component';

import { Nonprecious } from 'src/app/models/nonprecious.model';
import { NonPreciousService } from 'src/app/services/non-precious.service';

import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { MaterialCode } from 'src/app/models/material-code';
import { Suppliers } from 'src/app/models/suppliers.model';
import { UnitOfMeasure } from 'src/app/models/unit-of-measure';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { SuppliersService } from 'src/app/services/suppliers.service';
import { UnitOfMeasureService } from 'src/app/services/unit-of-measurement.service';
import { Historylogs } from '../../models/historylogs.model';

@Component({
  selector: 'app-non-precious',
  templateUrl: './non-precious.component.html',
  styleUrls: ['./non-precious.component.scss'],
})
export class NonPreciousComponent extends GenericComponent implements OnInit {
  tableCollapsed = false;
  editMode = false;

  uom: UnitOfMeasure[];
  materialCodes: MaterialCode[];
  suppliers: Suppliers[];

  nonPreciousList: Nonprecious[];
  allNonPrecious: Nonprecious[];
  nonPrecious = new Nonprecious();

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    private uomService: UnitOfMeasureService,
    private tokenStorageService: TokenStorageService,
    private materialCodeService: MaterialCodeService,
    private supplierService: SuppliersService,
    private _date: DatePipe,
    private nonPreciousService: NonPreciousService,
    private userPermissionService: UserPermissionService,
  ) {
    super(loadService, alertService);
    this.loadService.loadContent(true);
    this.load();
    this.userPermissionService.checkPagePermission(PAGE_NAME.nonPrecious);
  }

  tableActive = false;
  selectedUOM = 1;
  newFlag = false;
  lastUpdated = new Historylogs();

  @ViewChild('uploadImage') imageUploader: ElementRef;

  ngOnInit(): void {
    this.loadService.loadContent(false);
  }

  load() {
    forkJoin([
      this.uomService.getList(),
      this.nonPreciousService.getList(),
      this.materialCodeService.getList(),
      this.supplierService.getList(),
    ])
      .pipe(
        map(
          ([unitOfMeasurements, nonpreciouslist, materialCodes, suppliers]) => {
            this.uom = unitOfMeasurements.responseObject;
            this.nonPreciousList = nonpreciouslist;
            this.allNonPrecious = nonpreciouslist;
            this.materialCodes = materialCodes.responseObject;
            this.suppliers = suppliers;

            return {
              unitOfMeasurements,
              nonpreciouslist,
              materialCodes,
              suppliers,
            };
          },
        ),
      )
      .subscribe(
        (response) => {
          if (this.allNonPrecious.length > 0) {
            this.nonPrecious = this.allNonPrecious[0];

            this.getHistoryLog(this.nonPrecious.nonPreciousId);
          }

          this.loadService.loadContent(false);
        },
        (error) => {
          this.alertService.error(error.statusText);
          this.loadService.loadContent(false);
        },
      );
  }

  getHistoryLog(id: number) {
    this.nonPreciousService.lastupdate(id).subscribe((res) => {
      this.lastUpdated = res;

      if (this.lastUpdated != null) {
        this.lastUpdated = res;
      } else {
        this.lastUpdated = new Historylogs();
      }
    });
  }

  searchTimeout() {
    this.nonPreciousList = this.allNonPrecious.filter(
      (p) =>
        p.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
        p.sku.toLowerCase().includes(this.searchString.toLowerCase()) ||
        p.supplierSku.toLowerCase().includes(this.searchString.toLowerCase()) ||
        p.stockQty
          .toString()
          .toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        p.supplierPrice
          .toString()
          .toLowerCase()
          .includes(this.searchString.toLowerCase()) ||
        p.nonPreciousId
          .toString()
          .toLowerCase()
          .includes(this.searchString.toLowerCase()),
    );
  }

  sortColumn = [
    { column: 'nonPreciousId', sort: '' },
    { column: 'name', sort: '' },
    { column: 'sku', sort: '' },
    { column: 'supplierSku', sort: '' },
    { column: 'stockQty', sort: '' },
    { column: 'supplierPrice', sort: '' },
  ];

  get totalNonPrecious() {
    if (this.nonPreciousList !== undefined) {
      return this.nonPreciousList.length;
    }
    return 0;
  }

  applyColorBand(nonPrecious: Nonprecious) {
    let index = this.allNonPrecious.indexOf(nonPrecious);
    if (index % 2 == 0 || index == 0) {
      return false;
    }
    return true;
  }

  uploadProductImage(files) {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    formData.append('file[]', files[0]);

    formData.append('nonPreciousId', this.nonPrecious.nonPreciousId.toString());
    formData.append('sku', this.nonPrecious.sku);

    // FUNCTION UNDER-CONTRUCTION
    this.nonPreciousService.uploadImage(formData).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log(
            'Uploading: ' +
              Math.round((100 * event.loaded) / event.total) +
              '%',
          );
        } else if (event.type === HttpEventType.Response) {
          this.nonPrecious.picPath = event.body.responseObject[0];
        }
      },
      (err) => {
        err;
      },
    );
  }

  formatDate(iDate: Date) {
    return this._date.transform(iDate, 'MM.dd.yyyy HH:mm');
  }

  newProduct() {
    this.editMode = !this.editMode;
    this.newFlag = true;
    this.nonPrecious = new Nonprecious();
    this.nonPrecious.name = 'NEW_ITEM';
    this.nonPrecious.sku = 'NEW_ITEM';
    this.nonPrecious.createdBy = '';
    this.nonPrecious.createdDate = new Date();

    this.nonPrecious.createdBy =
      this.tokenStorageService.currentUser?.userName ?? '';

    this.nonPreciousService.addupdate(this.nonPrecious).subscribe((res) => {
      this.nonPrecious = res;
    });
  }

  save() {
    this.editMode = !this.editMode;

    this.nonPreciousService.addupdate(this.nonPrecious).subscribe((res) => {
      this.nonPrecious = res;

      // Need to recall, freaking array doesn't work
      this.nonPreciousService.getList().subscribe((res) => {
        this.nonPreciousList = res;
        this.allNonPrecious = res;
      });

      this.alertService.success('Item details updated.');
    });
  }

  toggleEdit() {
    this.editMode = !this.editMode;
    this.newFlag = false;
  }

  toggleCancel() {
    this.editMode = !this.editMode;

    if (this.newFlag == false) {
      return;
    }

    // TODO : UNDER CONSTRUCTION

    this.nonPreciousService.delete(this.nonPrecious.nonPreciousId).subscribe();
  }

  getNonPrecious(nonPrecious: Nonprecious) {
    this.nonPrecious = nonPrecious;

    this.getHistoryLog(nonPrecious.nonPreciousId);
  }

  sortClass(column) {
    if (this.sortColumn.find((s) => s.column === column).sort === 'asc') {
      return 'fa-arrow-up';
    } else if (
      this.sortColumn.find((s) => s.column === column).sort === 'desc'
    ) {
      return 'fa-arrow-down';
    }
    return '';
  }

  onSort(column: string) {
    if (
      this.checkStringIfEmpty(
        this.sortColumn.find((s) => s.column === column).sort,
      ) ||
      this.sortColumn.find((s) => s.column === column).sort !== 'asc'
    ) {
      this.sortColumn.forEach((s) => {
        s.sort = '';
      });
      this.sortColumn.find((s) => s.column === column).sort = 'asc';
      this.nonPreciousList.sort((a, b) => {
        if (a[column] > b[column]) {
          return 1;
        }
        if (a[column] < b[column]) {
          return -1;
        }
        return 0;
      });
    } else {
      this.sortColumn.forEach((s) => {
        s.sort = '';
      });
      this.sortColumn.find((s) => s.column === column).sort = 'desc';
      this.nonPreciousList.sort((a, b) => {
        if (a[column] < b[column]) {
          return 1;
        }
        if (a[column] > b[column]) {
          return -1;
        }
        return 0;
      });
    }
  }
}
