import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductEnamel } from 'src/app/models/product';
import { EnamelService } from 'src/app/services/enamel.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-enamel-constant',
  templateUrl: './enamel-constant.component.html',
  styleUrls: ['./enamel-constant.component.scss'],
})
export class EnamelConstantComponent implements OnInit, OnChanges {
  @Input() productId: number;
  productEnamels: ProductEnamel[] = [];

  constructor(
    private enamelService: EnamelService,
    private productService: ProductService,
    private productBomService: ProductBomService,
  ) {}

  ngOnInit(): void {
    this.productService.reloadEnamelConstant = () => {
      this.getProductVariation();
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.productEnamels = [];
    if (this.productId) {
      this.getProductVariation();
    }
  }

  getProductVariation() {
    forkJoin([
      this.enamelService.getList(),
      this.productBomService.getProductEnamel(this.productId),
    ])
      .pipe(
        map(([enamels, productEnamelList]) => {
          const productEnamels = productEnamelList
            .filter((e) => !e.variation)
            .map((p) => {
              const enamel =
                enamels.find((e) => e.enamelId === p.enamelID) ?? {};
              const pantoneColor = enamel.pantoneColor ? `[${enamel.pantoneColor}] ` : '';
              return {
                ...enamel,
                ...p,
                displayText: `${pantoneColor}${p.enamelName}`,
              };
            });

          return { enamels, productEnamels };
        }),
      )
      .subscribe(({ productEnamels }) => {
        this.productEnamels = productEnamels;
      });
  }
}
