export class Enamel {
  enamelId: number = 0;
  enamelSku: string = '';
  enamelName: string = '';
  pantoneColor: string = '';
  hardness: boolean = false;
  supplierId: number = 0;
  supplierDisplayName?: string = '';
  colorHex: string = '';
  variation?: boolean;
}

export class EnamelColumns {
  'ID': number = 0;
  'SKU': string = '';
  'Name': string = '';
  'Pantone Color': string = '';
  'Hardness': boolean = false;
  'Supplier': string = '';
  'Color': string = '';
}
