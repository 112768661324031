<div class="form-row">
  <div class="col-md-6">
    <h2 class="mt-3">
      BOM Table A
      <span
        *ngIf="autosaveCounter > 0 && showAutosaveCounter"
        class="autosave-note"
      >
        Saving... <span class="fa fa-refresh fa-spin"></span>
      </span>
      <span
        *ngIf="autosaveCounter == 0 && showAutosaveCounter"
        class="autosave-note"
      >
        Saved <span class="fa fa-check"></span>
      </span>
    </h2>
    <table class="table bom-table">
      <thead>
        <th scope="col">QTY</th>
        <th scope="col">SKU</th>
        <th scope="col">Name</th>
        <th scope="col">Notes</th>
        <th scope="col">Locked Size</th>
        <th scope="col"></th>
      </thead>
      <tbody>
        <tr
          *ngFor="let a of tableA"
          class="row-data"
          [ngClass]="rowColor(a.itemType)"
        >
          <td scope="row">
            <span *ngIf="!editMode">{{ a.bomQty }}</span>
            <input
              *ngIf="editMode"
              [(ngModel)]="a.bomQty"
              type="number"
              style="border: solid 1px black; color: black; width: 50px"
              (change)="updateTableAItem(a)"
            />
          </td>
          <td scope="row">{{ a.sku }}</td>
          <td scope="row">{{ a.itemName }}</td>
          <td scope="row">
            <span *ngIf="!editMode">{{ a.itemDescription }}</span>
            <textarea
              *ngIf="editMode"
              [(ngModel)]="a.itemDescription"
              (change)="updateTableAItem(a)"
            ></textarea>
          </td>
          <td scope="row">
            <span *ngIf="!editMode">{{ a.isSizeDependent }}</span>
            <mat-form-field *ngIf="editMode" appearance="outline">
              <mat-select [(ngModel)]="a.productsInfoId">
                <mat-option (click)="updateTableAItem(a)" [value]="0"
                  >-- None --</mat-option
                >
                <mat-optgroup
                  *ngFor="let x of distinctMaterials"
                  [label]="getMaterial(x.materialCodeId)"
                  [disabled]="false"
                >
                  <mat-option
                    *ngFor="let info of getMaterialSizes(x.materialCodeId)"
                    [value]="info.productsInfoId"
                    (click)="updateTableAItem(a)"
                  >
                    {{ info.size }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </td>
          <td scope="row">
            <span
              *ngIf="editMode"
              class="fa fa-times"
              (click)="delete(a.bomId)"
            ></span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-2" *ngIf="editMode">
      <div class="row">
        <div class="col">
          <div (clickOutside)="showDropdown(false)">
            <input
              id="search-option"
              class=""
              type="text"
              [(ngModel)]="keyword"
              (focus)="showDropdown(true)"
            />
            <div class="options-container hidden">
              <div *ngFor="let item of tableAOptions | filter: keyword">
                <div
                  *ngIf="!checkIfListed(item.id, item.type)"
                  class="options-item"
                  (click)="addToTableA(item.id, item.type, 1)"
                >
                  <span>{{ item.sku }} ({{ item.name }})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <h2 class="mt-3">
      BOM Table B
      <span
        *ngIf="autosaveCounter > 0 && showAutosaveCounter"
        class="autosave-note"
      >
        Saving... <span class="fa fa-refresh fa-spin"></span>
      </span>
      <span
        *ngIf="autosaveCounter == 0 && showAutosaveCounter"
        class="autosave-note"
      >
        Saved <span class="fa fa-check"></span>
      </span>
    </h2>
    <table class="table bom-table">
      <thead>
        <th scope="col">QTY</th>
        <th scope="col">SKU</th>
        <th scope="col">Name</th>
        <th scope="col">Notes</th>
        <th scope="col">Locked Size</th>
        <th scope="col"></th>
      </thead>
      <tbody>
        <tr
          *ngFor="let b of tableB"
          class="row-data"
          [ngClass]="rowColor(b.itemType)"
        >
          <td scope="row">
            <span *ngIf="!editMode">{{ b.bomQty }}</span>
            <input
              *ngIf="editMode"
              [(ngModel)]="b.bomQty"
              (change)="updateTableBItem(b)"
              type="number"
              style="border: solid 1px black; color: black; width: 50px"
            />
          </td>
          <td scope="row">{{ b.sku }}</td>
          <td scope="row">{{ b.itemName }}</td>
          <td scope="row">
            <span *ngIf="!editMode">{{ b.itemDescription }}</span>
            <textarea
              *ngIf="editMode"
              [(ngModel)]="b.itemDescription"
              (change)="updateTableBItem(b)"
            ></textarea>
          </td>
          <td scope="row">
            <span *ngIf="!editMode">{{ b.isSizeDependent }}</span>
            <mat-form-field *ngIf="editMode" appearance="outline">
              <mat-select [(ngModel)]="b.productsInfoId">
                <mat-option (click)="updateTableBItem(b)" [value]="0"
                  >-- None --</mat-option
                >
                <mat-optgroup
                  *ngFor="let x of distinctMaterials"
                  [label]="getMaterial(x.materialCodeId)"
                  [disabled]="false"
                >
                  <mat-option
                    *ngFor="let info of getMaterialSizes(x.materialCodeId)"
                    (click)="updateTableBItem(b)"
                    [value]="info.productsInfoId"
                  >
                    {{ info.size }}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </td>
          <td scope="row">
            <span
              *ngIf="editMode"
              class="fa fa-times"
              (click)="delete(b.bomId)"
            ></span>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="editMode">
      <div class="row">
        <div class="col">
          <select
            [(ngModel)]="bomType"
            class="custom-select form-control"
            [disabled]="!editMode"
          >
            <option [value]="3">Chain</option>
            <option [value]="4">Enamel</option>
            <option [value]="5">Findings</option>
            <option [value]="6">Gems</option>
            <option [value]="7">Manufactured Materials</option>
            <option [value]="9">Non-Precious</option>
          </select>
        </div>
        <div class="col">
          <h3 class="new-table-b">
            &nbsp;<span
              class="fa fa-plus"
              (click)="openModal(content); showTableBOptions(bomType)"
            ></span>
          </h3>
        </div>
        <div class="col"></div>
      </div>
    </div>

    <ng-template #content let-modal>
      <div class="add-table-b-popup">
        <div class="modal-header row">
          <h5 class="modal-title col-10" id="modal-basic-title">
            Add BOM ({{ getPopupLabel(bomType) }})
          </h5>
          <button
            type="button"
            class="close col-2"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div *ngIf="bomType == 6" class="modal-body">
          <app-gems-bom
            (gemInventoryOutput)="addGemToTableB($event)"
          ></app-gems-bom>
        </div>

        <div *ngIf="bomType != 6" class="modal-body">
          <input
            *ngIf="bomType != 6"
            [(ngModel)]="searchString"
            (keyup)="searchTimeout()"
            class="form-control mb-3"
            type="text"
            placeholder="SEARCH"
          />
          <table class="table">
            <thead>
              <tr class="col">
                <th *ngFor="let column of popupTableB_ColumnNames">
                  {{ column }}
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody *ngIf="bomType == 3">
              <tr
                *ngFor="let x of tableB_filtered"
                class="table-b-item row-data"
                [ngClass]="{ listed: checkIfListed(x.ID, bomType) }"
              >
                <td>{{ x.ID }}</td>
                <td>{{ x.Name }}</td>
                <td>{{ x.SKU }}</td>
                <td scope="row">
                  <select
                    *ngIf="editMode"
                    class="custom-select form-control"
                    (change)="
                      addToTableB(x.ID, bomType, 1, $event.srcElement.value);
                      modal.dismiss('Add item')
                    "
                  >
                    <option
                      *ngFor="let info of getOptionSizes(x.ID)"
                      [value]="info.id"
                    >
                      {{ getMaterial(info.materialCodeId) }} - {{ info.size }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="bomType == 4">
              <tr
                *ngFor="let x of tableB_filtered"
                class="table-b-item row-data"
                [ngClass]="{ listed: checkIfListed(x.enamelId, bomType) }"
              >
                <td>{{ x.enamelId }}</td>
                <td>{{ x.enamelSku }}</td>
                <td>
                  {{ x.enamelName }}
                  <span *ngIf="checkIfListed(x.enamelId, bomType)"
                    >*listed*</span
                  >
                </td>
                <td>{{ x.pantoneColor }}</td>
                <td>{{ x.hardness }}</td>
                <td>{{ supplierName(x.supplierId) }}</td>
                <td>
                  <div
                    style="width: 30px; height: 30px"
                    [ngStyle]="{ 'background-color': x.colorHex }"
                  ></div>
                </td>
                <td>
                  <span
                    class="fa fa-plus"
                    *ngIf="!checkIfListed(x.enamelId, bomType)"
                    (click)="addToTableB(x.enamelId, bomType, 1)"
                  ></span>
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="bomType == 5">
              <tr
                *ngFor="let x of tableB_filtered"
                class="table-b-item row-data"
                [ngClass]="{ listed: checkIfListed(x.ID, bomType) }"
              >
                <td>{{ x.ID }}</td>
                <td>
                  {{ x.Name }}
                  <span *ngIf="checkIfListed(x.ID, bomType)">*listed*</span>
                </td>
                <td>{{ x.SKU }}</td>
                <td>{{ x.Supplier }}</td>
                <td>{{ x.Unit }}</td>
                <td>
                  <select
                    *ngIf="editMode"
                    class="custom-select form-control"
                    (change)="
                      addToTableB(x.ID, bomType, 1, $event.srcElement.value);
                      modal.dismiss('Add item')
                    "
                  >
                    <option
                      *ngFor="let info of getOptionSizes(x.ID)"
                      [value]="info.id"
                    >
                      {{ getMaterial(info.materialCodeId) }} - {{ info.size }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="bomType == 7">
              <tr
                *ngFor="let x of tableB_filtered"
                class="table-b-item row-data"
                [ngClass]="{ listed: checkIfListed(x.ID, bomType) }"
              >
                <td>{{ x.ID }}</td>
                <td>
                  {{ x.Name }}
                  <span *ngIf="checkIfListed(x.ID, bomType)">*listed*</span>
                </td>
                <td>{{ x.SKU }}</td>
                <td>{{ x.Supplier }}</td>
                <td>{{ x.Unit }}</td>
                <td>
                  <select
                    *ngIf="editMode"
                    class="custom-select form-control"
                    (change)="
                      addToTableB(x.ID, bomType, 1, $event.srcElement.value);
                      modal.dismiss('Add item')
                    "
                  >
                    <option
                      *ngFor="let info of getOptionSizes(x.ID)"
                      [value]="info.id"
                    >
                      {{ getMaterial(info.materialCodeId) }} - {{ info.size }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="bomType == 9">
              <tr
                *ngFor="let x of tableB_filtered"
                class="table-b-item row-data"
                [ngClass]="{ listed: checkIfListed(x.ID, bomType) }"
              >
                <td>{{ x.ID }}</td>
                <td>
                  {{ x.Name }}
                  <span *ngIf="checkIfListed(x.ID, bomType)">*listed*</span>
                </td>
                <td>{{ x.SKU }}</td>
                <td>{{ x.Supplier }}</td>
                <td>{{ x.Unit }}</td>
                <td>{{ x['Stock QTY'] }}</td>
                <td>{{ x['Supplier Price'] }}</td>
                <td>
                  <span
                    class="fa fa-plus"
                    *ngIf="!checkIfListed(x.ID, bomType)"
                    (click)="addToTableB(x.ID, bomType, 1)"
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </div>
</div>
