import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { Product, ProductsRouting } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { ProductRoutingComponent } from './product-routing.component';

@Component({
  selector: 'product-routing-copy-dialog',
  templateUrl: './product-routing-copy-dialog.component.html',
  styleUrls: ['./product-routing-copy-dialog.component.scss'],
})
export class ProductRoutingCopyDialogComponent implements OnInit {
  @ViewChild(ProductRoutingComponent)
  productRoutingComponent: ProductRoutingComponent;

  productId: number = 0;
  filteredProducts: Observable<Product[]>;
  productControl = new FormControl();
  products: Product[] = [];
  product: Product = new Product();
  routings: ProductsRouting[];

  constructor(
    public dialogRef: MatDialogRef<ProductRoutingCopyDialogComponent>,
    public loadService: LoadService,
    public productService: ProductService,
  ) {}

  ngOnInit(): void {
    this.productService.getList().subscribe((products) => {
      this.products = products;
      this.filteredProducts = this.productControl.valueChanges.pipe(
        startWith(this.product.productName),
        map((value) => this._filterProduct(value)),
      );
    });
  }

  private _filterProduct(name: string): Product[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.products.filter((option) =>
        option.productName.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['productName'];
      return this.products.filter((option) =>
        option.productName.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.products;
  }

  displayFn(item: Product): string {
    return item && item.productName ? item.productName : '';
  }

  onRoutingChange($events) {
    this.routings = $events;
  }

  onConfirm() {
    this.dialogRef.close(this.routings);
  }
}
