import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { cloneDeep } from 'lodash';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FINDINGS_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/findings-permission';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { MaterialCode } from 'src/app/models/material-code';
import { RoutingCode } from 'src/app/models/routing-code';
import { FindingsService } from 'src/app/services/findings.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { RoutingCodeService } from 'src/app/services/routing-code.service';
import { ConfirmationService } from '../../riva-confirmation/riva-confirmation.service';
import { FindingRoutingDialogComponent } from './finding-routing-dialog.component';
import { FindingRouting } from './model';

@Component({
  selector: 'finding-routing',
  templateUrl: './finding-routing.component.html',
  styleUrls: ['./finding-routing.component.scss'],
})
export class FindingRoutingComponent implements OnInit, OnChanges {
  @Input() findingsID: number;
  @Input() readonly?: boolean;
  @Output() onRoutingChange = new EventEmitter<FindingRouting[]>();
  @ViewChild('table') table: MatTable<string>;
  routingDisplayColumn: string[] = [
    'draggable',
    'activityCode',
    'department',
    'activityDescription',
    'standardTime',
    'comment',
    'delete',
  ];
  findingRoutings: FindingRouting[] = [];
  findingRoutingsRaw: FindingRouting[] = [];
  findingRoutingsForDelete: FindingRouting[] = [];
  findingRoutingsCodes: RoutingCode[] = [];
  rawFindingRoutingCodes: RoutingCode[] = [];
  filteredFindingRoutingCodes: Observable<RoutingCode[]>;
  findingRoutingsControl = new FormControl();
  selectedFindingRouting: RoutingCode;
  isSaving = false;
  dragEnabled = false;
  materialCodes: MaterialCode[] = [];
  selectedMaterialCodeId = 0;
  featureKey = FINDINGS_FEATURE_KEY;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FindingRoutingDialogComponent>,
    private routingCodeService: RoutingCodeService,
    private findingsService: FindingsService,
    private loadService: LoadService,
    private confirmationService: ConfirmationService,
    private materialCodeService: MaterialCodeService,
  ) {}

  ngOnInit(): void {
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject.filter((code) => !code.multiMetal);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadFindingRoutings();
  }

  loadFindingRoutings() {
    if (!this.findingsID) {
      this.findingRoutings = [];
      this.findingRoutingsCodes = [];
      this.rawFindingRoutingCodes = [];
      this.onRoutingChange.emit(this.findingRoutings);
      return;
    }
    forkJoin([
      this.routingCodeService.getAll(),
      this.findingsService.getFindingsRoutingByFindingsId(this.findingsID),
    ]).subscribe(([findingRoutingsCodes, findingRoutings]) => {
      this.findingRoutingsCodes = [...findingRoutingsCodes];
      this.rawFindingRoutingCodes = [...findingRoutingsCodes];
      this.findingRoutingsRaw = findingRoutings.map((r) => ({
        ...r,
        routingCode: findingRoutingsCodes.find(
          (code) => code.routingCodesId === r.routingCodesID,
        ),
      }));
      this.onFilterRoutings();
      this.onRoutingChange.emit(this.findingRoutings);
      this.initiateFilters();
    });
  }

  onFilterRoutings() {
    if (this.selectedMaterialCodeId > 0) {
      const noMaterialFindings = this.findingRoutingsRaw.filter(
        (f) => f.materialCodesID === 0,
      );
      const data = noMaterialFindings.map((f) => {
        const withMaterial = this.findingRoutingsRaw.find(
          (r) =>
            r.routingCodesID === f.routingCodesID &&
            r.materialCodesID === this.selectedMaterialCodeId,
        );
        if (withMaterial == null) {
          return f;
        }

        return {
          ...withMaterial,
          routingOrder: f.routingOrder,
        };
      });
      this.findingRoutings = cloneDeep(data);
    } else {
      const data = this.findingRoutingsRaw.filter(
        (f) => f.materialCodesID === 0,
      );
      this.findingRoutings = cloneDeep(data);
    }
  }

  onCheckFindingRoutingCode() {
    this.findingRoutingsCodes = this.rawFindingRoutingCodes.filter(
      (findingRoutingsCode) => {
        return !this.findingRoutings.some(
          (findingRoutings) =>
            findingRoutings.routingCodesID ===
            findingRoutingsCode.routingCodesId,
        );
      },
    );
  }

  displayFn(item: RoutingCode): string {
    return item?.activityCode ?? '';
  }

  onSelectFindingRoutingCode(findingRoutingsCode: RoutingCode) {
    const findingRoutings: FindingRouting = {
      findingsID: this.findingsID,
      routingCodesID: findingRoutingsCode.routingCodesId,
      findingsRoutingID: 0,
      standardTime: 0,
      comment: '',
      routingCode: findingRoutingsCode,
      materialCodesID: 0,
      tempRoutingId: +new Date(),
    };
    const data = [...this.findingRoutings, findingRoutings];
    this.findingRoutings = cloneDeep(data);

    this.selectedFindingRouting = null;
  }

  initiateFilters() {
    this.filteredFindingRoutingCodes =
      this.findingRoutingsControl.valueChanges.pipe(
        startWith(this.selectedFindingRouting?.activityCode),
        map((value) => this._filterFindingRoutingCode(value)),
      );
  }

  private _filterFindingRoutingCode(name: string): RoutingCode[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.findingRoutingsCodes.filter(
        (option) =>
          option.activityCode.toLowerCase().includes(filterValue) ||
          option.department.toLowerCase().includes(filterValue) ||
          option.activityDesc.toLowerCase().includes(filterValue),
      );
    }
    return this.findingRoutingsCodes;
  }

  dropTable(event: CdkDragDrop<string[]>) {
    const prevIndex = this.findingRoutings.findIndex(
      (d) => d === event.item.data,
    );
    moveItemInArray(this.findingRoutings, prevIndex, event.currentIndex);
  }

  onSaveFindingRouting() {
    if (!this.findingRoutings?.length) {
      this.dialogRef.close();
      return;
    }
    if (this.selectedMaterialCodeId === 0) {
      const data = this.findingRoutings.map(
        ({ routingCode, tempRoutingId, ...p }, index) => ({
          ...p,
          routingOrder: index + 1,
          findingsRoutingID: p.findingsRoutingID ?? 0,
        }),
      );
      this.loadService.isSavingFindingRouting = true;
      this.findingsService.setFindingsRouting(data).subscribe(
        () => {
          this.loadService.isSavingFindingRouting = false;
          this.dialogRef.close();
        },
        () => {
          this.loadService.isSavingFindingRouting = false;
        },
      );
    } else {
      const routings = [];
      this.findingRoutings.forEach(
        ({ routingCode, tempRoutingId, ...routing }, index) => {
          const originalRouting = this.findingRoutingsRaw.find(
            (r) => r.findingsRoutingID === routing.findingsRoutingID,
          );
          if (originalRouting == null) {
            routings.push({
              ...routing,
              routingOrder: index + 1,
              materialCodesID: 0,
            });
            routings.push({
              ...routing,
              routingOrder: index + 1,
              materialCodesID: this.selectedMaterialCodeId,
            });
          } else {
            const hasChanges =
              originalRouting.comment !== routing.comment ||
              originalRouting.standardTime !== routing.standardTime;
            if (hasChanges && originalRouting.materialCodesID === 0) {
              routings.push({
                ...routing,
                findingsRoutingID: 0,
                routingOrder: index + 1,
                materialCodesID: this.selectedMaterialCodeId,
              });
              routings.push({
                ...originalRouting,
                routingOrder: index + 2,
              });
            } else {
              routings.push({
                ...routing,
                routingOrder: index + 1,
              });
            }
          }
        },
      );
      this.loadService.isSavingFindingRouting = true;
      this.findingsService.setFindingsRouting(routings).subscribe(
        () => {
          this.loadService.isSavingFindingRouting = false;
          this.dialogRef.close();
        },
        () => {
          this.loadService.isSavingFindingRouting = false;
        },
      );
    }
  }
  onDeleteFindingRouting(findingRoutings: FindingRouting) {
    this.confirmationService
      .showConfirmation({
        content: `Continue to delete routing from Finding Routing?`,
        title: 'Delete finding routing?',
        confirmLabel: 'Continue',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (findingRoutings.findingsRoutingID) {
            const data = this.findingRoutings.filter(
              (p) => p.findingsRoutingID !== findingRoutings.findingsRoutingID,
            );
            this.findingRoutings = cloneDeep(data);
            this.findingsService
              .deleteFindingsRouting(findingRoutings.findingsRoutingID)
              .subscribe();
          } else {
            const data = this.findingRoutings.filter(
              (p) => p.tempRoutingId !== findingRoutings.tempRoutingId,
            );
            this.findingRoutings = cloneDeep(data);
          }
        }
      });
  }
}
