<div class="invoicing-shipping">
  <div class="invoicing-shipping__content">
    <button
      type="button"
      class="btn btn-danger"
      style="
        width: 250px;
        position: absolute;
        right: 20px;
        top: 70px;
        z-index: 10000;
      "
      (click)="onOpenOrphanWorkOrderDialog()"
    >
      Orphaned Work Orders
    </button>
    <div class="tab-container" style="margin-top: 30px">
      <nz-tabset
        nzType="card"
        [nzSelectedIndex]="selectedTabIndex"
        (nzSelectedIndexChange)="onTabChange($event)"
      >
        <nz-tab nzTitle="Invoicing">
          <invoicing></invoicing>
        </nz-tab>
        <nz-tab nzTitle="Shipping">
          <shipping></shipping>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>

<swal
  #confirmationWorkOrder
  [title]="confirmation.title"
  [text]="confirmation.description"
  [icon]="confirmation.icon"
  confirmButtonText="Done"
  [showConfirmButton]="confirmation.icon === 'error'"
  [timer]="confirmation.timer"
>
</swal>
