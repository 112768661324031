export class MetalGrain {
  metalGrainsId: number = 0;
  name: string;
  sku: string;
  suppliersId: number;
  supplierSku: string;
  commentBox: string;
  picPath: string;
  materialCode: number;
  uom: number;
  qtyinStock: number;
  qtyinScrap: number;
}
