import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import {
  RivaGems,
  StoneSizeOrderDetails,
} from 'src/app/components/riva-gems/riva-gems.model';
import { WorkOrderType } from 'src/app/components/work-order-view/work-order-type';
import { environment } from 'src/environments/environment';
import { Invoice } from '../components/invoicing-shipping/invoicing/models/invoice';
import { InvoicingLookupItem } from '../components/invoicing-shipping/invoicing/models/invoicing-lookup-item';
import { ShipmentDetail } from '../components/invoicing-shipping/shipping/models/shipment';
import { RelatedShipment } from './../components/invoicing-shipping/invoicing/models/related-shipment';
import { getOrderDetailsSizeDisplayText } from './../components/work-order-view/work-order-view.component';
import { GenericService } from './generic.service';

const getStoneDetail = (stone: RivaGems) => {
  const detail = [];
  if (stone.type) detail.push(stone.type);
  if (stone.color) detail.push(stone.color);
  if ((stone.size as StoneSizeOrderDetails).dimension1)
    detail.push(
      getOrderDetailsSizeDisplayText(stone.size as StoneSizeOrderDetails),
    );
  return detail.join(' | ');
};

const getChainName = (chain) => {
  if (chain == null) return '';
  const data = [];
  if (chain.sku) data.push(chain.sku);
  if (chain.styleName) data.push(chain.styleName);
  if (chain.lengthOrSize) data.push(chain.lengthOrSize);
  return data.join(' | ');
};

const getLookupChainName = (data) => {
  if (data == null) return '';
  const values = [];
  if (data.ordersDetailsChainSKU) values.push(data.ordersDetailsChainSKU);
  if (data.ordersDetailsChainStyleName)
    values.push(data.ordersDetailsChainStyleName);
  if (data.ordersDetailsChainLengthOrSize)
    values.push(data.ordersDetailsChainLengthOrSize);
  return values.join(' | ');
};

const processFindingsSize = (size = '') => {
  return size
    .split('x')
    .reduce((sz, val) => {
      const trimmedValue = val.trim();
      return trimmedValue !== '' ? [...sz, trimmedValue] : sz;
    }, [])
    .join(' x ');
};

const getLookupProductName = (item, workOrderType) => {
  if (workOrderType === WorkOrderType.Finding) {
    return (
      item.productName ??
      `${item.findingsType} | ${processFindingsSize(item.size)}`
    );
  } else {
    return `${item.productName}${item.sku ? ` | ${item.sku}` : ''}`;
  }
};

export const invoiceMapper = (i) => ({
  ...i,
  link: `/invoicing-and-shipping/invoicing/${i.invoicesID}`,
  invoicedDate: i.invoicedDate
    ? DateTime.fromJSDate(new Date(i.invoicedDate)).toFormat('MM/dd/yyyy')
    : '',
  invoicesDetails: i.invoicesDetails.map((d, index) => ({
    ...d,
    key: index,
    weight: (d.wghtGrms ?? 0) > 0 ? d.wghtGrms : d.unitWeightGrams,
    // weight:
    //   d.productWeights?.find(
    //     (w) =>
    //       w.materialCodesID === d.materialsCodeId &&
    //       w.productsSizesID === d.productsSizeId,
    //   )?.avgWeightGrams ?? 0,
    stoneType: d.stone?.type,
    stoneColor: d.stone?.color,
    stoneSize: d.stone?.size
      ? getOrderDetailsSizeDisplayText(d.stone?.size as StoneSizeOrderDetails)
      : '',
    chain: getChainName(d.ordersDetailsChain),
    // size:
    //   (d.ordersDetailsChain?.ordersDetailsChainID ?? 0) > 0
    //     ? d.chainRawLength
    //       ? `${d.chainRawLength}"`
    //       : ''
    //     : d.size,
    productName:
      d.findingsType != null
        ? getLookupProductName(d, WorkOrderType.Finding)
        : d.productName,
    hasWeight: (d.wghtGrms ?? 0) > 0,
  })),
});

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends GenericService {
  baseUrl = environment.apiUrl + 'invoices';

  constructor(http: HttpClient) {
    super(http);
  }

  getInvoices() {
    return this.http
      .get<Invoice[]>(this.baseUrl, { headers: this.headers })
      .pipe(map((invoices) => invoices.map(invoiceMapper)));
  }

  getPagedInvoices(params) {
    return this.http.get<Invoice[]>(`${this.baseUrl}/paged`, {
      headers: this.headers,
      params: { ...params },
      observe: 'response',
    });
  }

  getShipmentByInvoiceId(invoiceId) {
    return this.http
      .get<ShipmentDetail[]>(`${this.baseUrl}/generate-shipment`, {
        headers: this.headers,
        params: {
          invoiceId,
        },
      })
      .pipe(
        map((data) =>
          data.map((item) => ({
            ...item,
            qty: item.qtyToShip,
            productName: getLookupProductName(
              item,
              item.findingsType != null
                ? WorkOrderType.Finding
                : WorkOrderType.Product,
            ),
          })),
        ),
      );
  }

  getInvoiceById(id) {
    return this.http
      .get<Invoice>(`${this.baseUrl}/${id}`, { headers: this.headers })
      .pipe(map((invoice) => invoiceMapper(invoice)));
  }

  getInvoicesExcel(params) {
    return this.http.get(`${this.baseUrl}/export`, {
      headers: this.headers,
      params: { ...params },
      responseType: 'arraybuffer',
    });
  }

  setInvoices(data) {
    return this.http.post(this.baseUrl, data, {
      headers: this.headers,
    });
  }

  updateInvoices(data) {
    return this.http.put(this.baseUrl, data, {
      headers: this.headers,
    });
  }

  getLookUpItems(workOrderId, workOrderType = WorkOrderType.Product) {
    return this.http
      .get<InvoicingLookupItem[]>(`${this.baseUrl}/lookup-items`, {
        headers: this.headers,
        params: {
          workOrderId,
        },
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            productName: getLookupProductName(item, workOrderType),
            qty: item.availableQtyToInvoice,
            chain: getLookupChainName(item),
            size:
              workOrderType === WorkOrderType.Finding
                ? processFindingsSize(item.size)
                : item.size,
          })),
        ),
      );
  }

  getOrderItems(orderId) {
    return this.http
      .get<InvoicingLookupItem[]>(`${this.baseUrl}/order-items`, {
        headers: this.headers,
        params: {
          orderId,
        },
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            qty: item.availableQtyToInvoice,
            chain: getLookupChainName(item),
            productName: getLookupProductName(
              item,
              item.findingsType != null
                ? WorkOrderType.Finding
                : WorkOrderType.Product,
            ),
          })),
        ),
      );
  }

  generatePdf(id) {
    return this.http.get<string>(`${this.baseUrl}/generate-pdf`, {
      headers: this.headers,
      responseType: 'text' as 'json',
      params: {
        id,
      },
    });
  }

  getRelatedShipments(invoiceId) {
    return this.http.get<RelatedShipment[]>(
      `${this.baseUrl}/shipments/${invoiceId}`,
      {
        headers: this.headers,
      },
    );
  }

  byPassWorkOrder(data) {
    return this.http.put(`${this.baseUrl}/bypass-work-order`, data, {
      headers: this.headers,
    });
  }

  deleteInvoiceCharge(invoicesDetailsChargesID) {
    return this.http.delete(
      `${this.baseUrl}/details-charges/${invoicesDetailsChargesID}`,
      {
        headers: this.headers,
      },
    );
  }

  updatedInvoiceCharge(data) {
    return this.http.post(`${this.baseUrl}/details-charges`, data, {
      headers: this.headers,
    });
  }

  deleteLineItem(invoicesDetailsID) {
    return this.http.delete(`${this.baseUrl}/line-items/${invoicesDetailsID}`, {
      headers: this.headers,
    });
  }

  updateLineItem(invoicesDetailsID, data) {
    return this.http.put(
      `${this.baseUrl}/line-items/${invoicesDetailsID}`,
      data,
      {
        headers: this.headers,
      },
    );
  }
}
