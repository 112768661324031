import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import {
  ProductStone,
  StoneOverride,
  StoneOverrideMultiple,
} from 'src/app/components/riva-gems/riva-gems.model';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';

interface OverrideValue {
  productSizeId: number;
  qty: number;
  size: string;
  tempId?: number;
}

@Component({
  templateUrl: './stone-override-size-multiple-dialog.component.html',
  styleUrls: ['./stone-override-size-multiple-dialog.component.scss'],
})
export class StoneOverrideSizeMultipleDialogComponent implements OnInit {
  displayColumns = ['size', 'qty'];
  isSaving = false;
  overrideSizes: StoneOverrideMultiple[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { productId: number; stones: ProductStone[] },
    public dialogRef: MatDialogRef<StoneOverrideSizeMultipleDialogComponent>,
    private productBomService: ProductBomService,
    private productService: ProductService,
  ) {}

  ngOnInit(): void {
    this.productService.getProduct(this.data.productId).subscribe((product) => {
      const { productSizes } = product?.responseObject ?? {};
      const requests = this.data.stones.map((stone) =>
        this.productBomService.getStoneOverride(stone.productsBOMStonesID),
      );
      forkJoin(requests).subscribe((overrides) => {
        const flattenOverrides = overrides.reduce((accum, o) => [
          ...accum,
          ...o,
        ]);

        this.overrideSizes = productSizes?.map((p) => {
          const overrideValue = flattenOverrides.filter(
            (o) => o.productsSizesID === p.productsSizesID,
          );
          const overrides = this.data.stones.reduce((accum, s) => {
            const { productsBOMStonesQTYsID = 0 } =
              overrideValue.find(
                (o) => o.productsBOMStonesID === s.productsBOMStonesID,
              ) ?? {};
            return [
              ...accum,
              {
                productsBOMStonesQTYsID,
                productsBOMStonesID: s.productsBOMStonesID,
              },
            ];
          }, []);
          const [item] = overrideValue ?? [];
          return {
            overrides,
            productsSizesID: p.productsSizesID,
            size: p.size,
            qty: item?.qty || '',
          };
        });
      });
    });
  }

  onOverrideSize() {
    const data = this.overrideSizes.reduce(
      (overrides, overrideSize) => {
        const items = overrideSize.overrides.map((o) => ({
          ...o,
          productsSizesID: overrideSize.productsSizesID,
          qty: overrideSize.qty,
          size: overrideSize.size,
        }));
        if (overrideSize.qty) {     
          overrides.insert = [...overrides.insert, ...items];
        } else if (overrideSize.overrides.some((o) => o.productsBOMStonesQTYsID) && !overrideSize.qty) {
          overrides.delete = [...overrides.delete, ...items];
        }
        return overrides;
      },
      {
        delete: [] as StoneOverride[],
        insert: [] as StoneOverride[],
      },
    );
    if (data.insert.length === 0 && data.delete.length === 0) {
      this.dialogRef.close();
      return;
    }
    this.isSaving = true;
    const insertRequests =
      data.insert.length > 0
        ? [this.productBomService.setStoneOverride(data.insert)]
        : [];
    const deleteRequests = data.delete.map((d) =>
      this.productBomService.deleteStoneOverride(d.productsBOMStonesQTYsID),
    );
    forkJoin([...insertRequests, ...deleteRequests]).subscribe(() => {
      this.isSaving = false;
      this.dialogRef.close(true);
    });
  }
}
