<div class="riva-chain-product-container">
  <div class="tab-container">
    <nz-tabset nzType="card">
      <nz-tab nzTitle="Unfinished">
        <riva-chain-unfinished-product
          [productId]="productId"
        ></riva-chain-unfinished-product>
      </nz-tab>
      <nz-tab nzTitle="Finished">
        <riva-chain-finished-product
          [productId]="productId"
        ></riva-chain-finished-product>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
