import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import orderBy from 'lodash/orderBy';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';

import { AlertService } from 'src/app/custom/_alert';
import { GenericComponent } from '../generic/generic.component';

import { PageEvent } from '@angular/material/paginator';
import { DateTime } from 'luxon';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { PURCHASE_ORDER_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/purchase-order-permission';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { Customers } from 'src/app/models/customer';
import { MaterialCode } from 'src/app/models/material-code';
import {
  OrderParams,
  Orders,
  OrdersDetails,
  OrderStatus,
  RivaOrders,
} from 'src/app/models/orders.model';
import { Product, ProductsInfo } from 'src/app/models/product';
import { CustomerService } from 'src/app/services/customer.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { OrderService } from 'src/app/services/order.service';
import { OrderdetailsService } from 'src/app/services/orderdetails.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-orderview',
  templateUrl: './orderview.component.html',
  styleUrls: ['./orderview.component.scss'],
})
export class OrderviewComponent extends GenericComponent implements OnInit {
  editMode: boolean = false;
  hideOrderDetails: boolean = true;

  customersList: Customers[];
  selectedCustomer = new Customers();
  selectedCustomerId: number = 0;
  selectedStatus = 'open';
  selectedSort = 'receivedDate';
  sort = 'desc';
  searchString = '';

  productList: Product[];
  productPerCustomer: Product[];
  materialCodes: MaterialCode[];

  orderList: RivaOrders[];
  ordersAllList: RivaOrders[];
  selectedOrder = new Orders();
  orderDetailList: OrdersDetails[];

  orderStatusList: OrderStatus[];
  cancelledOrderIdx: number = 0;

  productsInfo: ProductsInfo[];
  allProductsInfo: ProductsInfo[];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    waitForAnimate: false,
  };
  purchaseOrderFeatureKey = PURCHASE_ORDER_FEATURE_KEY;
  orderParams: OrderParams = {
    SearchQuery: '',
    CustomerId: 0,
    Status: 'open',
    ReceivedFrom: DateTime.local().minus({ months: 9 }).toFormat('yyyy-MM-dd'),
    ReceivedTo: DateTime.local().toFormat('yyyy-MM-dd'),
    SortBy: 'receiveddate',
    SortDirection: 'desc',
    PageNumber: 1,
    PageSize: 20,
    OrderType: [0],
  };
  pagination = {
    totalCount: 0,
  };

  orderPresets = [
    {
      label: 'All Open',
      config: {
        SearchQuery: '',
        CustomerId: 0,
        Status: 'open',
        ReceivedFrom: DateTime.local()
          .minus({ years: 2 })
          .toFormat('yyyy-MM-dd'),
        ReceivedTo: DateTime.local().toFormat('yyyy-MM-dd'),
        SortBy: 'receiveddate',
        SortDirection: 'desc',
        PageNumber: 1,
        PageSize: 20,
        OrderType: 0,
      },
    },
    {
      label: 'All Staged',
      config: {
        SearchQuery: '',
        CustomerId: 0,
        Status: 'staged',
        ReceivedFrom: DateTime.local()
          .minus({ years: 2 })
          .toFormat('yyyy-MM-dd'),
        ReceivedTo: DateTime.local().toFormat('yyyy-MM-dd'),
        SortBy: 'receiveddate',
        SortDirection: 'desc',
        PageNumber: 1,
        PageSize: 20,
        OrderType: 0,
      },
    },
    {
      label: 'Late on Top',
      config: {
        SearchQuery: '',
        CustomerId: 0,
        Status: 'open',
        ReceivedFrom: DateTime.local()
          .minus({ years: 2 })
          .toFormat('yyyy-MM-dd'),
        ReceivedTo: DateTime.local().toFormat('yyyy-MM-dd'),
        SortBy: 'duedate',
        SortDirection: 'asc',
        PageNumber: 1,
        PageSize: 20,
        OrderType: 0,
      },
    },
  ];
  private debouncer = new Subject<void>();
  orderTypeControl = new FormControl([0]);
  orderTypePreviousValues: number[] = [0];
  isLoading = false;
  searchQueryChange: Subject<string> = new Subject<string>();
  @ViewChild('paginator') paginator;

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    private router: Router,
    private customerService: CustomerService,
    private productService: ProductService,
    private orderService: OrderService,
    private orderDetailService: OrderdetailsService,
    private materialCodeService: MaterialCodeService,
    private userPermissionService: UserPermissionService,
    private modalService: NgbModal,
    private fb: FormBuilder,
  ) {
    super(loadService, alertService);
    this.userPermissionService.checkPagePermission(PAGE_NAME.order);
    this.searchQueryChange
      .pipe(
        tap(() => (this.isLoading = true)),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .subscribe((searchQuery) => {
        this.orderParams.SearchQuery = searchQuery;
        this.getOrdersWithPagination();
      });
    this.debouncer.pipe(debounceTime(1000)).subscribe(() => {
      this.orderParams.OrderType = this.orderTypeControl.value;
      this.getOrdersWithPagination();
    });
  }

  onSelectionChange() {
    const currentValue = this.orderTypeControl.value;
    let values = currentValue;
    const currentSelection = currentValue.find(
      (v) => !this.orderTypePreviousValues.includes(v),
    );

    if (currentSelection === 0 || currentValue?.length === 0) {
      values = [0];
    } else if (currentValue?.length > 1) {
      values = currentValue.filter((o) => o !== 0)
    }
    this.orderTypeControl.setValue(values);
    this.orderTypePreviousValues = values;
    this.debouncer.next();
  }

  onDateRangeChange({ start, end }) {
    this.orderParams.ReceivedFrom = start;
    this.orderParams.ReceivedTo = end;
    this.getOrdersWithPagination();
  }

  onPageChanged(event: PageEvent) {
    this.orderParams.PageNumber = event.pageIndex + 1;
    this.getOrdersWithPagination(false);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.load();
  }

  load() {
    forkJoin([
      this.customerService.getList(),
      this.productService.getList(),
      // this.orderService.getOrders(),
      this.materialCodeService.getList(),
    ])
      .pipe(
        map(([customerlist, productList, materialCodes]) => {
          this.customersList = orderBy(customerlist, 'companyName');
          this.selectedCustomer =
            this.customersList.length > 0 ? this.customersList[0] : null;

          this.productList = productList;

          this.materialCodes = materialCodes.responseObject;

          this.getOrdersWithPagination();

          return {
            customerlist,
            productList,
            materialCodes,
          };
        }),
      )
      .subscribe(
        (response) => {
          this.loadService.loadContent(false);
        },
        (error) => {
          this.alertService.error(error.statusText);
          this.loadService.loadContent(false);
        },
      );
  }

  getOrdersWithPagination(resetPage = true) {
    if (resetPage) {
      this.paginator._pageIndex = 0;
      this.orderParams.PageNumber = 1;
    }
    this.isLoading = true;
    return this.orderService
      .getOrdersWithPagination(this.orderParams)
      .subscribe(({ body, headers }) => {
        const paginationData = headers.get('X-Pagination');
        this.pagination =
          paginationData != null
            ? JSON.parse(paginationData)
            : { totalCount: 0 };
        this.orderList = body.map((o) => {
          const cipoStr = o.cipo
            ?.reduce((items, item) => {
              if (!item) return items;
              return [...items, item.toLowerCase()];
            }, [])
            .join();
          const start = DateTime.fromISO(o.requiredDate);
          const end = DateTime.local();
          const diff = start.diff(end, 'days').days;
          const isAlmostDue = diff <= 4 && diff > 0;
          const isAlreadyDue = diff <= 0;
          const link = `/main/orders/${o.ordersId}`;
          return {
            ...o,
            customerName:
              this.customersList.find((c) => c.custIdno === o.customerId)
                ?.companyName ?? '',
            receivedDate: DateTime.fromJSDate(
              new Date(o.receivedDate),
            ).toFormat('MM/dd/yyyy'),
            requiredDate: DateTime.fromJSDate(
              new Date(o.requiredDate),
            ).toFormat('MM/dd/yyyy'),
            isAlmostDue,
            isAlreadyDue,
            cipoStr,
            link,
            hasOpenOrder:
              o.orderType !== 2 &&
              o.items.some((i) => !i.byPassWKOs) &&
              o.status === 'Open' &&
              o.items.length > o.totalWorkOrderCreated,
          };
        });
        this.isLoading = false;
      });
  }

  onSearch(searchQuery: string) {
    this.searchQueryChange.next(searchQuery);
  }

  filterOrderList() {
    this.orderList = this.ordersAllList.filter(
      (x) =>
        (!this.selectedCustomerId || x.customerId == this.selectedCustomerId) &&
        (this.selectedStatus === 'all' ||
          x.status?.toLowerCase() === this.selectedStatus),
    );
    this.onSort();
  }

  searchCustomer() {
    this.selectedCustomer = this.customersList.filter(
      (x) => x.custIdno == this.selectedCustomerId,
    )[0];
    this.filterOrderList();
  }

  gotoOrders(ordersId) {
    this.router.navigate(['/main/orders/' + ordersId]);
  }

  onSortChange() {
    this.orderParams.SortDirection =
      this.orderParams.SortDirection === 'asc' ? 'desc' : 'asc';
    this.getOrdersWithPagination();
  }

  onSort() {
    this.orderList = orderBy(
      this.orderList,
      [(order) => order[this.selectedSort]?.toLowerCase()],
      this.sort,
    );
  }

  get filteredOrderList() {
    if (!this.searchString) return [...(this.orderList ?? [])];
    return this.orderList.filter(
      (o) =>
        o.pointernal
          .toLowerCase()
          .includes(this.searchString.trim().toLowerCase()) ||
        o.poexternal
          .toLowerCase()
          .includes(this.searchString.trim().toLowerCase()) ||
        o.cipoStr.includes(this.searchString.trim().toLowerCase()),
    );
  }

  onSetFilter(config: OrderParams) {
    this.orderParams = { ...config };
    this.getOrdersWithPagination();
  }
}
