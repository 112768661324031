import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StoneOpenOrder } from '../models/orders.model';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class StoneReleaseService extends GenericService {
  baseUrl = environment.apiUrl + 'stone-release';

  constructor(http: HttpClient) {
    super(http);
  }
  getStoneReleaseHistory() {
    return this.http.get<{
      data: StoneOpenOrder[];
      customers: Array<{ customerId: number; customerName: string }>;
    }>(`${this.baseUrl}/history`, {
      headers: this.headers,
    });
  }
}
