import { PAGE_NAME } from './pages';
export const STONE_FEATURE_KEY = {
  add: 'STONE_ADD',
  edit: 'STONE_EDIT',
  customImage: 'STONE_CUSTOM_IMAGE',
  inventoryAdjustment: 'STONE_INVENTORY_ADJUSTMENT',
};

export const stonePermission = {
  [PAGE_NAME.stone]: {
    SuperUser: {
      [STONE_FEATURE_KEY.add]: 'access',
      [STONE_FEATURE_KEY.edit]: 'access',
      [STONE_FEATURE_KEY.customImage]: 'access',
      [STONE_FEATURE_KEY.inventoryAdjustment]: 'access',
    },
    User: {
      [STONE_FEATURE_KEY.add]: 'access',
      [STONE_FEATURE_KEY.edit]: 'access',
      [STONE_FEATURE_KEY.customImage]: 'hidden',
      [STONE_FEATURE_KEY.inventoryAdjustment]: 'access',
    },
    Viewer: {
      [STONE_FEATURE_KEY.add]: 'disabled',
      [STONE_FEATURE_KEY.edit]: 'disabled',
      [STONE_FEATURE_KEY.customImage]: 'hidden',
      [STONE_FEATURE_KEY.inventoryAdjustment]: 'hidden',
    },
  },
};
