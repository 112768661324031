<div class="riva-reporting-work-order-movement">
  <div class="tab-container">
    <nz-tabset nzType="card">
      <nz-tab nzTitle="Movement">
        <work-order-moved></work-order-moved>
      </nz-tab>
      <nz-tab nzTitle="Product">
        <work-order-product-search></work-order-product-search>
      </nz-tab>
      <nz-tab nzTitle="Incomplete">
        <work-order-incomplete></work-order-incomplete>
      </nz-tab>
      <nz-tab nzTitle="Employee">
        <work-order-employee></work-order-employee>
      </nz-tab>
      <nz-tab nzTitle="Process Comparison">
        <work-order-process-comparison></work-order-process-comparison>
      </nz-tab>
      <nz-tab nzTitle="Employee Down-Time">
        <work-order-employee-down-time></work-order-employee-down-time>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
