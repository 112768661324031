import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlertService } from 'src/app/custom/_alert';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { Customers } from 'src/app/models/customer';
import { Enamel } from 'src/app/models/enamel';
import { MaterialCode } from 'src/app/models/material-code';
import { OrderDetailsEnamel, OrdersDetails } from 'src/app/models/orders.model';
import { Product, ProductDetail, ProductEnamel } from 'src/app/models/product';
import { ProductSku } from 'src/app/models/sku';
import { CustomerService } from 'src/app/services/customer.service';
import { EnamelService } from 'src/app/services/enamel.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { OrderdetailsService } from 'src/app/services/orderdetails.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';
import { SkuService } from 'src/app/services/sku.service';
import { GenericComponent } from '../../generic/generic.component';
import { ProductOrderChain } from '../../riva-chain/riva-chain-product/model';
import { ProductStone } from '../../riva-gems/riva-gems.model';
import { SharedService } from './../../../services/shared.service';
@Component({
  selector: 'app-new-orderdetails-item',
  templateUrl: './new-orderdetails-item.component.html',
  styleUrls: ['./new-orderdetails-item.component.scss'],
})
export class NewOrderdetailsItemComponent
  extends GenericComponent
  implements OnInit, OnChanges
{
  @Input() orderDetails: OrdersDetails = new OrdersDetails();
  @Input() customerId: number;
  @Input() productList: Product[] = [];
  @Input() customersList: Customers[] = [];
  @Input() materialCodes: MaterialCode[] = [];
  @Output() onCancel = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  productVariations: ProductEnamel[] = [];
  productDetail: ProductDetail = {
    materials: [],
    productSizes: [],
  } as ProductDetail;

  noChain = {
    chainProductsID: -1,
    chainType: false,
    name: 'No Chain',
    size: '',
  };

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    private sharedService: SharedService,
    private orderDetailsService: OrderdetailsService,
    private productService: ProductService,
    private productBomService: ProductBomService,
    private materialCodeService: MaterialCodeService,
    private enamelService: EnamelService,
    private customerService: CustomerService,
    private skuService: SkuService,
  ) {
    super(loadService, alertService);
  }

  products: Product[] = [];
  productMaterialCodes: MaterialCode[] = [];
  materialSizes: string[] = [];
  enamelDetail: OrderDetailsEnamel = {} as OrderDetailsEnamel;
  enamels: Enamel[] = [];

  product: Product = new Product();
  materialCode: MaterialCode = new MaterialCode();
  materialSize: string = '';

  productControl = new FormControl();
  materialCodeControl = new FormControl();
  materialSizeControl = new FormControl();

  filteredProducts: Observable<Product[]>;
  filteredMaterialCodes: Observable<MaterialCode[]>;
  filteredMaterialSizes: Observable<string[]>;

  productStoneVariationControl = new FormControl();
  productStoneVariations: ProductStone[] = [];
  productStoneVariation: ProductStone = {} as ProductStone;
  filteredProductStoneVariation: Observable<ProductStone[]>;

  productChains: ProductOrderChain[] = [];
  productChain: ProductOrderChain = {} as ProductOrderChain;
  productChainControl = new FormControl();
  filteredProductChains: Observable<ProductOrderChain[]>;
  productSkus: ProductSku[] = [];
  selectedProductSku: ProductSku = {} as ProductSku;

  displayFn(item: Product): string {
    return item && item.productName ? item.productName : '';
  }

  displayMatCode(item: MaterialCode): string {
    return item && item.code ? item.code : '';
  }

  displayMatSize(item: string): string {
    return item ? item : '';
  }

  private _filterProduct(name: string): Product[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.products.filter(
        (option) =>
          option.productName.toLowerCase().includes(filterValue) ||
          option.productDesc.toLowerCase().includes(filterValue) ||
          option.sku.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['productName'];
      return this.products.filter(
        (option) =>
          option.productName
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          option.productDesc
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          option.sku.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.products;
  }

  private _filterMatCode(name: string): MaterialCode[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.productMaterialCodes.filter((item) =>
        item?.code?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['code'];
      return this.productMaterialCodes.filter((item) =>
        item?.code?.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.productMaterialCodes;
  }

  private _filterMatSize(name: string): string[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.materialSizes.filter((item) =>
        item.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name;
      return this.materialSizes.filter((item) =>
        item.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.materialSizes;
  }

  ngOnInit(): void {
    forkJoin([
      //this.productService.getList(),
      // this.materialCodeService.getList(),
      this.enamelService.getList(),
      // this.customerService.getList(),
    ])
      .pipe(
        map(([enamels]) => {
          const rivaId =
            this.customersList.find(
              (customer) => customer.customerId.toLowerCase() === 'riva',
            )?.custIdno ?? 0;

          const sortedProducts = orderBy(
            this.productList.map((p) => ({
              ...p,
              sortOrder: p.customerCode === rivaId ? 1 : 0,
              isRivaProduct: p.customerCode === rivaId,
            })),
            ['sortOrder'],
          );

          if (this.customerId === rivaId) {
            this.products = sortedProducts;
          } else {
            this.products = sortedProducts.filter(
              (p) =>
                p.customerCode == this.customerId || p.customerCode == rivaId,
            );
          }
          this.enamels = enamels;
        }),
      )
      .subscribe((response) => {
        this.initiateFilters();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.orderDetails.productsId) {
      this.product = this.productList.find(
        (p) => p.productsId === this.orderDetails.productsId,
      );
      this.enamelDetail.enamelID = this.orderDetails.enamelId;
    }
  }

  disableMaterialCodes: boolean = true;
  disableMaterialSizes: boolean = true;

  initiateFilters() {
    this.filteredProducts = this.productControl.valueChanges.pipe(
      startWith(this.product.productName),
      map((value) => this._filterProduct(value)),
    );
    this.productControl.valueChanges.subscribe((value) => {
      if (!value) {
        this.materialCode = new MaterialCode();
        this.materialSize = '';
        this.filteredMaterialCodes = new Observable<MaterialCode[]>();
        return;
      }
      this.filteredMaterialCodes = this.materialCodeControl.valueChanges.pipe(
        startWith(this.materialCode.code),
        map((value) => this._filterMatCode(value)),
      );
    });
    this.materialCodeControl.valueChanges.subscribe((value) => {
      this.materialSizes = [];
      this.materialSize = '';
      let productInfos = this.product?.productsInfo?.filter(
        (x) => x.materialCodeId == value?.materialCodeId,
      );
      productInfos?.forEach((x) => {
        this.materialSizes.push(x.size);
      });
      this.filteredMaterialSizes = this.materialSizeControl.valueChanges.pipe(
        startWith(this.materialSize),
        map((value) => this._filterMatSize(value)),
      );
    });
  }

  matCodeDisable: boolean = true;
  matSizeDisable: boolean = true;

  productChange(event) {
    let matCodesHolder = this.materialCodes;
    this.productMaterialCodes = [];
    this.orderDetails.productsId = this.product.productsId;
    this.product.productsInfo.forEach((x) => {
      let item = matCodesHolder.find(
        (y) => y.materialCodeId == x.materialCodeId,
      );
      item && this.productMaterialCodes.push(item);
    });
    this.matCodeDisable = false;
  }

  matCodeChange(event) {
    this.matSizeDisable = false;
  }

  matSizeChange(event) {
    this.orderDetails.productsInfoId = this.product.productsInfo.find(
      (x) =>
        x.materialCodeId == this.materialCode.materialCodeId &&
        x.size == this.materialSize,
    ).productsInfoId;
    this.disableSaveButton = false;
  }

  disableSaveButton: boolean = true;

  save() {
    this.loadService.loadContent(true);
    const { productsInfo, ordersDetailsId, ...data } = this.orderDetails;
    const dueDate = data.dueDate
      ? DateTime.fromJSDate(new Date(data.dueDate)).toFormat('yyyy-MM-dd')
      : null;
    const entryDate = data.entryDate
      ? DateTime.fromJSDate(new Date(data.entryDate)).toFormat('yyyy-MM-dd')
      : null;

    this.orderDetailsService
      .addupdate({ ...data, dueDate, entryDate })
      .subscribe((result) => {
        this.sharedService.showGenerateWorkOrderAndByPass = true;
        const allRequest = result.reduce((requests, id) => {
          if (this.enamelDetail?.enamelID) {
            requests.push(
              this.orderDetailsService.setOrderDetailEnamel({
                ...this.enamelDetail,
                orderDetailsID: id,
              }),
            );
          }

          if (this.productStoneVariation?.productsBOMStonesID) {
            requests.push(
              this.orderDetailsService.setOrderDetailsStone({
                ordersDetailsID: id,
                productsBOMStonesID:
                  this.productStoneVariation.productsBOMStonesID,
              }),
            );
          }

          if ((this.productChain?.chainProductsID ?? 0) > 0) {
            requests.push(
              this.orderDetailsService.setOrderDetailsChain({
                ordersDetailsChainID: 0,
                ordersDetailsID: id,
                chainType: this.productChain.chainType,
                chainProductsID: this.productChain.chainProductsID,
              }),
            );
          }

          if (
            !this.selectedProductSku.productsCustSKUsID &&
            this.selectedProductSku.customerSKU
          ) {
            const payload = {
              productsCustSKUsID: 0,
              productsID: this.product.productsId,
              productsMaterialsID: this.orderDetails.materialCodeID ?? 0,
              productsSizesID: this.orderDetails.productSizesID ?? 0,
              productsBOMEnamelID: this.enamelDetail?.enamelID ?? 0,
              productsBOMStonesID:
                this.productStoneVariation?.productsBOMStonesID ?? 0,
              productsBOMChainFinishedID: this.productChain?.chainType
                ? this.productChain.chainProductsID
                : 0,
              productsBOMChainRawID: this.productChain?.chainType
                ? 0
                : this.productChain?.chainProductsID,
              customerSKU: this.selectedProductSku.customerSKU,
            };
            requests.push(this.skuService.setProductSku(payload));
          }

          return requests;
        }, []);

        if (allRequest.length === 0) {
          this.loadService.loadContent(false);
          this.onSuccess.emit();
          return;
        }
        forkJoin(allRequest).subscribe(() => {
          this.loadService.loadContent(false);
          this.onSuccess.emit();
        });
      });
  }

  onCancelEvent() {
    this.onCancel.emit();
  }

  getProductVariation(productId, hasEnamel = false) {
    this.productVariations = [];
    if (!productId) return;
    this.productBomService.getProductEnamel(productId).subscribe((data) => {
      this.productVariations = data.reduce((variations, item) => {
        if (!item.variation) return variations;
        const enamel =
          this.enamels.find((e) => e.enamelId === item.enamelID) ?? {};
        return [...variations, { ...item, ...enamel }];
      }, []);
      if (hasEnamel) return;
      if (data.length === 1) {
        this.enamelDetail.enamelID = data[0].enamelID;
      }
    });
  }
  onProductChange() {
    const isInitialData = this.orderDetails.isInitialData;
    if (!isInitialData) {
      this.orderDetails.materialCodeID = 0;
      this.orderDetails.productSizesID = 0;
      this.enamelDetail = {} as OrderDetailsEnamel;
    }

    this.getProductVariation(this.product.productsId);
    this.getProductDetail(isInitialData);
    this.getProductPictures(this.product.productsId);
    this.getProductStone(this.product.productsId, isInitialData);
    this.getProductChain(this.product.productsId, isInitialData);
    this.getProductSku(this.product.productsId, isInitialData);

    this.orderDetails.isInitialData = false;
  }

  getProductSku(productId, isInitialData) {
    this.productSkus = [];
    if (!productId) return;
    this.skuService.getProductSku(productId).subscribe((data) => {
      this.productSkus = data;
      this.onCheckProductSku();
    });
  }
  getProductPictures(productId) {
    this.productService
      .getProductImageList(productId)
      .subscribe((data = []) => {
        this.product.picPath = data[0]?.fileName;
      });
  }

  getProductDetail(retainValue = false) {
    this.productDetail = {
      materials: [],
      productSizes: [],
    } as ProductDetail;
    if (this.product.productsId) {
      this.productService
        .getProductDetail(this.product.productsId)
        .subscribe((data) => {
          this.productDetail = data;
          if (retainValue) return;
          if (data.materials.length === 1) {
            this.orderDetails.productsMaterialsID =
              data.materials[0].productsMaterialsID;
            this.orderDetails.materialCodeID =
              data.materials[0].materialsCodeID;
          }
          if (data.productSizes.length === 1) {
            this.orderDetails.productSizesID =
              data.productSizes[0].productsSizesID;
          }
        });
    }
  }

  get isFormValid() {
    const isEnamelValid =
      !this.productVariations?.length || this.enamelDetail.enamelID != null;
    return (
      this.orderDetails.productsId &&
      this.orderDetails.qtyordered &&
      this.orderDetails.dueDate &&
      this.orderDetails.materialCodeID &&
      this.orderDetails.productSizesID &&
      isEnamelValid
    );
  }

  get selectedMaterial() {
    return (
      this.productDetail.materials.find(
        (m) => m.materialsCodeID === this.orderDetails.materialCodeID,
      ) ?? {
        material: {
          printColor1: '',
          description: '',
          code: '',
          multiMetalPrimaryMaterial: null,
          multiMetalSecondaryMaterial: null,
        },
      }
    );
  }

  onChangeMaterial() {
    this.orderDetails.materialCodeID = this.productDetail.materials.find(
      (m) => m.productsMaterialsID === this.orderDetails.productsMaterialsID,
    )?.material?.materialCodeId;
  }

  getProductStone(productId, hasStone = false) {
    if (!productId) return;
    this.productBomService.getProductStones(productId).subscribe((data) => {
      this.productStoneVariations = data.filter((stone) => stone.variation);

      this.filteredProductStoneVariation =
        this.productStoneVariationControl.valueChanges.pipe(
          startWith(this.productStoneVariation?.stone),
          map((value) => this._filterProductStoneVariation(value)),
        );

      if (hasStone) {
        this.productStoneVariation = this.productStoneVariations.find(
          (p) => p.productsBOMStonesID === this.orderDetails.stoneId,
        );
      }
    });
  }

  displayProductStone(item: ProductStone): string {
    const labels = [];
    if (item?.stone?.stoneType?.name) labels.push(item.stone.stoneType.name);
    if (item?.stone?.stoneColor?.colorName)
      labels.push(item.stone.stoneColor.colorName);
    if (item?.stone?.stoneSize?.displayText)
      labels.push(item.overrideStoneSize || item.stone.stoneSize.displayText);
    return labels.join(' | ');
  }

  private _filterProductStoneVariation(name: string): ProductStone[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.productStoneVariations.filter(
        (option) =>
          option.stone?.stoneType?.name?.toLowerCase().includes(filterValue) ||
          option.stone?.stoneColor?.colorName
            ?.toLowerCase()
            .includes(filterValue) ||
          option.stone?.stoneSize?.displayText
            ?.toLowerCase()
            .includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['productName'];
      return this.productStoneVariations.filter(
        (option) =>
          option.stone?.stoneType?.name
            .toLowerCase()
            .includes(filterValue?.toLowerCase()) ||
          option.stone?.stoneColor?.colorName
            .toLowerCase()
            .includes(filterValue?.toLowerCase()) ||
          option.stone?.stoneSize?.displayText
            .toLowerCase()
            .includes(filterValue?.toLowerCase()),
      );
    }
    return this.productStoneVariations;
  }

  getProductChain(productId, hasChain = false) {
    forkJoin([
      this.productBomService.getProductChainRaw(productId),
      this.productBomService.getProductChainFinished(productId),
    ]).subscribe(([raw, finished]) => {
      const rawMapped = raw.map((r) => ({
        chainProductsID: r.productsBOMChainRawID,
        chainType: false,
        name: r.chainRaw?.chainStyle?.styleName,
        size: r.lengthInches.toString(),
      }));
      const finishedMapped = finished.map((f) => ({
        chainProductsID: f.productsBOMChainFinishedID,
        chainType: true,
        name: f.name,
        size: f.chainFinishedSizes.size,
      }));
      this.productChains = [...finishedMapped, ...rawMapped];

      this.filteredProductChains = this.productChainControl.valueChanges.pipe(
        startWith(this.productChain.name),
        map((value) => this._filterProductChain(value)),
      );
      if (hasChain) {
        this.productChain = this.productChains.find((c) =>
          this.orderDetails.finishChainId > 0
            ? c.chainProductsID === this.orderDetails.finishChainId
            : c.chainProductsID === this.orderDetails.rawChainId,
        );
        return;
      }
      if (this.productChains.length === 1) {
        this.productChain = this.productChains[0];
      }
    });
  }
  private _filterProductChain(name: string): ProductOrderChain[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.productChains.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue) ||
          option.size?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['name'];
      return this.productChains.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue) ||
          option.size?.toLowerCase().includes(filterValue),
      );
    }
    return this.productChains;
  }
  displayProductChain(item: ProductOrderChain): string {
    const labels = [];
    if (item?.chainType)
      labels.push(item.chainType ? 'Finished' : 'Unfinished');
    if (item?.name) labels.push(item.name);
    if (item?.size) labels.push(item.size);
    return labels.join(' | ');
  }

  onCheckProductSku() {
    this.selectedProductSku =
      this.productSkus.find(
        (sku) =>
          sku.productsMaterialsID === (this.orderDetails.materialCodeID ?? 0) &&
          sku.productsSizesID === (this.orderDetails.productSizesID ?? 0) &&
          sku.productsBOMEnamelID === (this.enamelDetail?.enamelID ?? 0) &&
          sku.productsBOMStonesID ===
            (this.productStoneVariation?.productsBOMStonesID ?? 0) &&
          ((this.productChain?.chainType &&
            sku.productsBOMChainFinishedID ===
              ((this.productChain?.chainProductsID ?? 0) > 0
                ? this.productChain?.chainProductsID
                : 0)) ||
            (!this.productChain?.chainType &&
              sku.productsBOMChainRawID ===
                ((this.productChain?.chainProductsID ?? 0) > 0
                  ? this.productChain?.chainProductsID
                  : 0))),
      ) ?? ({} as ProductSku);
  }

  get disableCustomerSku() {
    const isFormFilled =
      this.orderDetails.materialCodeID > 0 &&
      this.orderDetails.productSizesID > 0 &&
      (this.productVariations.length === 0 || !!this.enamelDetail?.enamelID) &&
      (this.productStoneVariations.length === 0 ||
        !!this.productStoneVariation?.productsBOMStonesID) &&
      (this.productChains.length === 0 ||
        (this.productChain?.chainProductsID ?? 0) > 0);
    return (
      !this.product.productsId ||
      this.selectedProductSku.productsCustSKUsID ||
      !isFormFilled
    );
  }
}
