<mat-dialog-content class="mat-typography" style="min-height: 600px">
  <div class="orders-upload-dialog">
    <input
      type="file"
      #uploadOrderFile
      style="display: none"
      (change)="uploadOrders(uploadOrderFile.files)"
    />
    <div
      class="file-upload-container"
      *ngIf="uploadStatus === 'None' && !isUploading"
    >
      <button
        (click)="uploadOrderFile.click()"
        class="riva-button"
        style="
          width: 300px;
          margin-top: 15px;
          padding: 16px 32px;
          height: 80px;
          font-size: 18px;
          border-radius: 40px;
        "
      >
        Upload File
      </button>
    </div>
    <div
      *ngIf="uploadStatus === 'Success' && !isUploading"
      style="
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      "
    >
      <div>
        <button
          (click)="uploadOrderFile.click()"
          class="riva-button"
          style="width: 300px"
        >
          Re-Upload File
        </button>
      </div>
    </div>
    <div
      style="border: 1px solid #646464"
      *ngIf="uploadStatus === 'Success' && !isUploading"
    >
      <nz-collapse>
        <nz-collapse-panel
          [nzHeader]="importFailedAccordionLabel"
          [nzActive]="hasFailedImports"
        >
          <table mat-table [dataSource]="orderImportsFailed">
            <ng-container matColumnDef="selection">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    width: 35px;
                    padding-top: 6px;
                  "
                >
                  <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="productShortName">
              <th mat-header-cell *matHeaderCellDef>Product</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hasProduct">{{
                  element.productShortName
                }}</span>
                <nz-select
                  style="width: 95%"
                  *ngIf="!element.hasProduct"
                  [(ngModel)]="element.productId"
                  (ngModelChange)="getProductMaterialsAndSizes(element)"
                >
                  <nz-option
                    *ngFor="let p of products"
                    [nzValue]="p.productsId"
                    [nzLabel]="p.productName"
                  ></nz-option>
                </nz-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="karatMetal">
              <th mat-header-cell *matHeaderCellDef>Material</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hasMaterial">{{
                  element.karatMetal
                }}</span>
                <nz-select
                  style="width: 95%"
                  *ngIf="!element.hasMaterial"
                  [(ngModel)]="element.materialCodeId"
                >
                  <nz-option
                    *ngFor="let m of element.materials"
                    [nzValue]="m.materialCodeId"
                    [nzLabel]="m.description"
                  ></nz-option>
                </nz-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef>Size</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hasSize">
                  {{ element.size }}
                </span>
                <nz-select
                  style="width: 95%"
                  *ngIf="!element.hasSize"
                  [(ngModel)]="element.productSizeId"
                >
                  <nz-option
                    *ngFor="let s of element.productSizes"
                    [nzValue]="s.productsSizesId"
                    [nzLabel]="s.size"
                  ></nz-option>
                </nz-select>
              </td>
            </ng-container>
            <ng-container matColumnDef="cipo">
              <th mat-header-cell *matHeaderCellDef>CIPO</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hasCIPO">{{ element.cipo }}</span>
                <input
                  nz-input
                  [(ngModel)]="element.cipo"
                  style="width: 95%"
                  *ngIf="!element.hasCIPO"
                />
              </td>
            </ng-container>
            <ng-container matColumnDef="dueDate">
              <th mat-header-cell *matHeaderCellDef>Due Date</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hasDueDate">
                  {{ element.dueDate | date : 'MM/dd/yyyy' }}
                </span>
                <div
                  *ngIf="!element.hasDueDate"
                  style="
                    display: flex;
                    align-items: center;
                    padding: 4px;
                    height: 32px;
                  "
                >
                  <input
                    matInput
                    [matDatepicker]="datePicker"
                    [(ngModel)]="element.dueDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="datePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #datePicker></mat-datepicker>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="qtyOrdered">
              <th mat-header-cell *matHeaderCellDef>Qty</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hasQty">{{ element.qtyOrdered }}</span>
                <nz-input-number
                  [(ngModel)]="element.qtyOrdered"
                  [nzMin]="1"
                  [nzStep]="1"
                  style="width: 95%"
                  *ngIf="!element.hasQty"
                ></nz-input-number>
              </td>
            </ng-container>
            <ng-container matColumnDef="comments">
              <th mat-header-cell *matHeaderCellDef>Comments</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf="element.hasComments">{{ element.comments }}</span>
                <input
                  nz-input
                  [(ngModel)]="element.comments"
                  style="width: 95%"
                  *ngIf="!element.hasComments"
                />
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <i
                  nz-icon
                  nzType="delete"
                  nzTheme="fill"
                  class="delete-icon"
                ></i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell text-center" colspan="8">No Failed Import</td>
            </tr>
          </table>
        </nz-collapse-panel>
        <nz-collapse-panel
          [nzHeader]="importSuccessAccordionLabel"
          [nzActive]="true"
        >
          <table mat-table [dataSource]="orderImports">
            <ng-container matColumnDef="selection">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    width: 35px;
                    padding-top: 6px;
                  "
                >
                  <mat-checkbox
                    [(ngModel)]="element.selected"
                    [disabled]="true"
                  ></mat-checkbox>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="productShortName">
              <th mat-header-cell *matHeaderCellDef>Product</th>
              <td mat-cell *matCellDef="let element">
                {{ element.productShortName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="karatMetal">
              <th mat-header-cell *matHeaderCellDef>Material</th>
              <td mat-cell *matCellDef="let element">
                {{ element.karatMetal }}
              </td>
            </ng-container>
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef>Size</th>
              <td mat-cell *matCellDef="let element">
                {{ element.size }}
              </td>
            </ng-container>
            <ng-container matColumnDef="cipo">
              <th mat-header-cell *matHeaderCellDef>CIPO</th>
              <td mat-cell *matCellDef="let element">
                {{ element.cipo }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dueDate">
              <th mat-header-cell *matHeaderCellDef>Due Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.dueDate | date : 'MM/dd/yyyy' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="qtyOrdered">
              <th mat-header-cell *matHeaderCellDef>Qty</th>
              <td mat-cell *matCellDef="let element">
                {{ element.qtyOrdered }}
              </td>
            </ng-container>
            <ng-container matColumnDef="comments">
              <th mat-header-cell *matHeaderCellDef>Comments</th>
              <td mat-cell *matCellDef="let element">
                {{ element.comments }}
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element"></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell text-center" colspan="7">
                No Successful Imports
              </td>
            </tr>
          </table>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <ng-container *ngIf="isUploading">
      <div class="scanning-overlay">
        <div class="scanning-message">
          <div
            class="scanning-content"
            [ngClass]="{ 'has-error': uploadStatus === 'Error' }"
          >
            <div class="scanning-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-cloud-upload"
                [ngClass]="{ hidden: uploadStatus === 'Error' }"
                width="70"
                height="70"
                stroke="#ffffff"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"
                />
                <path d="M9 15l3 -3l3 3" />
                <path d="M12 12l0 9" />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-cloud-upload"
                [ngClass]="{ hidden: uploadStatus !== 'Error' }"
                width="70"
                height="70"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ff4500"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"
                />
                <path d="M9 15l3 -3l3 3" />
                <path d="M12 12l0 9" />
              </svg>
            </div>
            <div>
              <div [ngClass]="{ hidden: uploadStatus === 'Error' }">
                Uploading
              </div>
              <div [ngClass]="{ hidden: uploadStatus !== 'Error' }">
                <div class="error-title">Oops! Something Went Wrong</div>
                <p class="error-message">
                  We’re sorry, but we’re experiencing an issue.
                </p>
              </div>
            </div>
            <div [ngClass]="{ hidden: uploadStatus === 'Error' }">
              <svg
                width="70"
                height="15"
                viewBox="0 0 120 30"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
              >
                <circle cx="15" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                  <animate
                    attributeName="r"
                    from="9"
                    to="9"
                    begin="0s"
                    dur="0.8s"
                    values="9;15;9"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="0.5"
                    to="0.5"
                    begin="0s"
                    dur="0.8s"
                    values=".5;1;.5"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
                <circle cx="105" cy="15" r="15">
                  <animate
                    attributeName="r"
                    from="15"
                    to="15"
                    begin="0s"
                    dur="0.8s"
                    values="15;9;15"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="fill-opacity"
                    from="1"
                    to="1"
                    begin="0s"
                    dur="0.8s"
                    values="1;.5;1"
                    calcMode="linear"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div style="display: flex; justify-content: center">
    <div
      style="
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        width: 400px;
      "
    >
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        autofocus
        [disabled]="!isFormValid"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </div>
</mat-dialog-actions>
