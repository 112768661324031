<h3
  mat-dialog-title
  style="display: flex; align-items: center; justify-content: space-between"
>
  <span>Stones Release History</span>
  <input
    nz-input
    placeholder="Search by WKO Number"
    [(ngModel)]="searchQuery"
    (ngModelChange)="onFilter()"
    style="width: 300px"
  />
</h3>
<mat-dialog-content class="mat-typography">
  <div class="stone-release-history-dialog">
    <div style="border: 1px solid #646464">
      <table mat-table [dataSource]="history">
        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let element">{{ element.productName }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneType">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneType }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneCut">
          <th mat-header-cell *matHeaderCellDef>Cut</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneCut }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneClarity">
          <th mat-header-cell *matHeaderCellDef>Clarity</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneClarity }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneColor">
          <th mat-header-cell *matHeaderCellDef>Color</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneColor }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneSize">
          <th mat-header-cell *matHeaderCellDef>Size</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneSize }}</td>
        </ng-container>
        <ng-container matColumnDef="labGrown">
          <th mat-header-cell *matHeaderCellDef>Lab Grown</th>
          <td mat-cell *matCellDef="let element">
            {{ element.labGrown ? 'Yes' : 'No' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="traceable">
          <th mat-header-cell *matHeaderCellDef>Traceable</th>
          <td mat-cell *matCellDef="let element">
            {{ element.traceable ? 'Yes' : 'No' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="qtyMoved">
          <th mat-header-cell *matHeaderCellDef>Qty</th>
          <td mat-cell *matCellDef="let element">
            {{ element.qtyMoved }}
          </td>
        </ng-container>
        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef>Customer</th>
          <td mat-cell *matCellDef="let element">
            {{ element.customerName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="customerProvided">
          <th mat-header-cell *matHeaderCellDef>Customer Provided</th>
          <td mat-cell *matCellDef="let element">
            {{ element.customerProvided ? 'Yes' : 'No' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="stonereleasedate">
          <th mat-header-cell *matHeaderCellDef>Released Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.stonereleasedate | date : 'MM/dd/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="groupHeader">
          <td colspan="12" mat-cell *matCellDef="let groupBy">
            Work Order:
            <strong style="margin-left: 8px">{{ groupBy.label }}</strong>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
          style="background-color: #6f6f6f"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="12">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
    "
  >
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px; width: 250px"
      mat-dialog-close
    >
      Close
    </button>
  </div>
</mat-dialog-actions>
