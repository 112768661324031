<div class="findings-jumpring">
  <div>
    <button
      class="btn btn-warning"
      userPermission
      [elementKey]="featureKey.setting"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        margin-bottom: 32px;
      "
      (click)="onOpen()"
    >
      <i
        nz-icon
        nzType="setting"
        nzTheme="outline"
        style="font-size: 18px; margin-right: 8px"
      ></i>
      Settings
    </button>

    <div class="image-wrapper">
      <div class="image-container">
        <img
          [src]="selectedJumpring.picPath"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
    </div>
    <div>
      <input
        type="file"
        #uploadImage
        (change)="uploadFindingImage(uploadImage.files, uploadImage)"
        style="display: none"
      />
      <fieldset userPermission [elementKey]="featureKey.edit">
        <button
          class="riva-button"
          [disabled]="!selectedJumpring.findingsId"
          (click)="uploadImage.click()"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 0 6px 6px;
          "
        >
          <i
            nz-icon
            nzType="file-image"
            nzTheme="outline"
            style="margin-right: 8px"
          ></i>
          Upload Picture
        </button>
      </fieldset>
    </div>
  </div>
  <div class="findings-jumpring-table-container">
    <div class="findings-jumpring-action">
      <finding-sku-generator-button
        [findings]="jumprings.data"
        [findingsTypeId]="findingsType.JUMPRINGS"
        [findingsId]="selectedJumpring.findingsId"
        [findingSizeId]="selectedJumpring.findingSizesID"
        [materialCodeId]="selectedStock.materialsCodeID"
      ></finding-sku-generator-button>
      <button
        class="riva-button"
        style="height: 45px; width: 300px"
        (click)="onAddNewJumpring()"
        userPermission
        [elementKey]="featureKey.create"
      >
        + New
      </button>
    </div>
    <table
      mat-table
      matSort
      [dataSource]="jumprings"
      matSortActive="findingSizesID"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="picPath">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div
            style="
              width: 70px;
              padding: 5px;
              display: flex;
              justify-content: center;
            "
            *ngIf="element.picPath"
          >
            <img
              style="max-height: 37px; max-width: 60px"
              [src]="element.picPath"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="size_1">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by size_1"
        >
          Wire Diameter
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_1 }}<span *ngIf="element.size_1">mm</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="jumpringSize">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by jumpringSize"
        >
          ID
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.jumpringSize }}
        </td>
      </ng-container>
      <ng-container matColumnDef="size_2">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by size_2"
        >
          ID 1
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_2 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="size_3">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by size_3"
        >
          ID 2
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_3 }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="jumpring-row"
        (click)="onTableRowClick(row)"
        [ngClass]="{
          'is-selected': row.findingsId === selectedJumpring?.findingsId
        }"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="4">No Available Data</td>
      </tr>
    </table>
  </div>
  <div class="findings-jumpring-detail">
    <div style="margin-bottom: 8px; display: flex; justify-content: flex-end">
      <button
        class="riva-button"
        (click)="onStockDialogOpen()"
        [disabled]="!selectedJumpring.findingsId"
        style="width: 50%"
        userPermission
        [elementKey]="featureKey.create"
      >
        New Stock
      </button>
    </div>
    <div class="other-fields">
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Material Code</mat-label>
          <mat-select
            [(ngModel)]="selectedStock.materialsCodeID"
            (ngModelChange)="onChangeMaterialCode()"
          >
            <mat-option
              *ngFor="let stock of stocks"
              [value]="stock.materialsCodeID"
            >
              {{ stock.material.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row-1">
        <mat-form-field appearance="fill">
          <mat-label>QTY in Stock</mat-label>
          <input
            matInput
            min="0"
            type="number"
            [(ngModel)]="selectedStock.qtyInStock"
            style="color: white"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Weight (g)</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="selectedStock.weight_G"
            max="99.99"
            style="color: white"
          />
        </mat-form-field>
      </div>
      <fieldset userPermission [elementKey]="featureKey.edit">
        <button
          class="btn btn-primary"
          style="font-size: 12px; padding: 5px; height: 40px"
          (click)="onSaveStock()"
          [disabled]="isSavingStock || !isFormValid"
        >
          Save
        </button>
      </fieldset>
    </div>
    <div style="margin-top: 16px">
      <button
        class="riva-button"
        (click)="onRoutingDialogOpen()"
        [disabled]="!selectedJumpring.findingsId"
      >
        Findings Routing
      </button>
    </div>
  </div>
</div>
