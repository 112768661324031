<h3 mat-dialog-title>Stones for Work Order {{ props.workOrderId }}</h3>
<mat-dialog-content class="mat-typography">
  <div class="invoicing-detail-dialog">
    <div style="border: 1px solid #646464">
      <table mat-table [dataSource]="props.items">
        <ng-container matColumnDef="picturePath">
          <th mat-header-cell *matHeaderCellDef>Picture</th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                width: 55px;
                padding: 5px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              "
              *ngIf="element.picturePath"
            >
              <img
                style="max-width: 100%; max-height: 100%"
                [src]="element.picturePath"
              />
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="stoneType">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneType }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneCut">
          <th mat-header-cell *matHeaderCellDef>Cut</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneCut }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneClarity">
          <th mat-header-cell *matHeaderCellDef>Clarity</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneClarity }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneColor">
          <th mat-header-cell *matHeaderCellDef>Color</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneColor }}</td>
        </ng-container>
        <ng-container matColumnDef="stoneSize">
          <th mat-header-cell *matHeaderCellDef>Size</th>
          <td mat-cell *matCellDef="let element">{{ element.stoneSize }}</td>
        </ng-container>
        <ng-container matColumnDef="labGrown">
          <th mat-header-cell *matHeaderCellDef>Lab Grown</th>
          <td mat-cell *matCellDef="let element">
            {{ element.labGrown ? 'Yes' : 'No' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="traceable">
          <th mat-header-cell *matHeaderCellDef>Traceable</th>
          <td mat-cell *matCellDef="let element">
            {{ element.traceable ? 'Yes' : 'No' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="stoneQty">
          <th mat-header-cell *matHeaderCellDef>Qty</th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              [(ngModel)]="element.totalStoneQty"
              (ngModelChange)="onQuantityChange(element)"
              nzSize="small"
              style="width: 80px; padding: 8px 0; margin-right: 8px"
            ></nz-input-number>
          </td>
        </ng-container>
        <ng-container matColumnDef="carat">
          <th mat-header-cell *matHeaderCellDef>Carat</th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              *ngIf="element.caratsPerUnit"
              [(ngModel)]="element.carat"
              (ngModelChange)="onCaratChange(element)"
              [nzStep]="1"
              nzSize="small"
              style="width: 80px; padding: 8px 0; margin-right: 8px"
            ></nz-input-number>
            <nz-alert
              *ngIf="!element.caratsPerUnit"
              nzType="warning"
              nzMessage="No carats per unit"
              nzShowIcon
              style="width: 165px"
            ></nz-alert>
          </td>
        </ng-container>
        <ng-container matColumnDef="customerProvided">
          <th mat-header-cell *matHeaderCellDef>Customer Provided</th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              [(ngModel)]="element.customerProvided"
              style="color: white"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="10">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      width: 400px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      autofocus
      (click)="onReleaseStones()"
      [disabled]="!isFormValid"
    >
      Release
    </button>
  </div>
</mat-dialog-actions>
