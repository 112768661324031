import { DateTime } from 'luxon';
import { MaterialDetail, Product, ProductsInfo } from './product';

export class Orders {
  ordersId: number = 0;
  pointernal: string = '';
  poexternal: string = '';
  receivedDate: Date;
  entryDate: Date = new Date();
  customerId: number = 1;
  requiredDate: Date;
  totalShippedDate: Date;
  orderStatusId: number = 1;
  comment: string = '';
  ordersDetails: OrdersDetails[];
  editflag = false;
  orderDetailsFlag = true;
  customerName?: string;
  isAlmostDue?: boolean;
  isAlreadyDue?: boolean;
  status?: string;
  billingAddressID: number;
  shippingAddressID: number;
  ordersType?: number;
  bypassWKOs?: boolean;
}

export interface RivaOrderItem {
  productPic: string;
  productName: string;
  workOrderId: number;
  workOrderClosedDate: string;
  shipmentsID: number;
  orderedQuantity: number;
  shippedQuantity: number;
}
export interface RivaOrders {
  ordersId: number;
  pointernal: string;
  poexternal: string;
  receivedDate: string;
  entryDate: string;
  customerId: number;
  requiredDate: string;
  totalShippedDate: string;
  status: string;
  customer: {
    id: number;
    companyName: string;
  };
  items: RivaOrderItem[];
  cipo: string[];
  cipoStr: string;
  link?: string;
  orderType: number;
  totalWorkOrderCreated: number;
  hasOpenOrder?: boolean;
}

export interface OrderDetailsEnamel {
  enamelID: number;
  orderDetailsEnamelID: number;
  orderDetailsID: number;
  colorHex?: string;
  enamelName?: string;
  ordersDetailsEnamelID?: number;
}

export class OrdersDetails {
  ordersDetailsId?: number = 0;
  ordersId: number = 0;
  productsId: number = 0;
  productsInfoId: number = 0;
  qtyordered: number = 1;
  qtyshipped: number = 0;
  qtyinvoiced: number = 0;
  entryDate: Date = DateTime.local().toJSDate();
  dueDate: Date = new Date(Date.now());
  custAdrsId: number = 0;
  comment: string = '';
  products?: Product = new Product();
  productsInfo?: ProductsInfo = new ProductsInfo();
  orderDetailsEnamel?: OrderDetailsEnamel;
  materialCodeID: number;
  productsMaterialsID?: number;
  size: string;
  productSizesID?: number;
  material?: MaterialDetail;
  productName?: string;
  materialCode: string;
  cipo?: string;
  customized?: boolean;
  chainType?: boolean;
  chainRawLength?: number;
  enamel?: string;
  shipDate?: string;
  bypassWKOs?: boolean;
  isFindings?: boolean;
  workOrder?: {
    workOrderId: number;
    printedDate: string | null;
    dueDate?: string | null;
    releasedDate?: string | null;
    closedDate?: string | null;
  };
  stone?: {
    stoneId: number;
    color: string;
    ordersDetailsStoneId: number;
    type: string;
    size: {
      dimension1: number;
      dimension2: number;
      dimension3: number;
    };
  };
  productMaterial?: {
    materialCode1: string | null;
    materialCode2: string | null;
    materialCodeID1: number;
    materialCodeID2: number;
  };
  primaryMaterialColor?: string | null;
  secondaryMaterialColor?: string | null;
  findingsID?: number;
  findingsSizesID?: number;
  findingsTypesID?: number;
  picPath?: string;
  orderDetailsType?: OrderDetailsType;
  isOpened?: boolean;
  enamelId?: number;
  stoneId?: number;
  rawChainId?: number;
  finishChainId?: number;
  isInitialData?: boolean;
}
export enum OrderDetailsType {
  OrderDetails,
  OrderDetailsAlt,
}

export interface OrderParams {
  SearchQuery?: string;
  CustomerId?: number;
  Status?: string;
  ReceivedFrom?: string;
  ReceivedTo?: string;
  SortBy?: string;
  SortDirection?: string;
  PageNumber?: number;
  PageSize?: number;
  OrderType?: number[];
}

export class OrderStatus {
  ordersStatusId: number;
  status: string;
}

export class OpenOrder {
  ordersDetailsId: number;
  poInternal: string;
  poExternal: string;
  pictureFilename: string;
  picturePath: string;
  workOrdersId: number;
  ordersId: number;
  dueDate: string;
  multiMaterial: boolean;
  materialCode: string;
  materialColor: string;
  materialPrintColor1: string;
  materialPrintColor1Hex: string;
  multiPrimaryMaterialCode: string;
  multiPrimaryMaterialColor: string;
  multiSecondaryMaterialCode: string;
  multiSecondaryMaterialColor: string;
  size: string;
  enamelColor: string;
  stoneType: string;
  backOrder: number;
  customerId: number;
  customerName: string;
  productsId: number;
  type: string;
  link?: string;
  printedDate: string;
  releasedDate: string;
  dateClosed: string;
  productName: string;
}

export interface StoneOpenOrder {
  ordersDetailsId: number;
  poInternal: string;
  poExternal: string;
  pictureFilename: string;
  picturePath: string;
  workOrdersId: number;
  ordersId: number;
  dueDate: string;
  receivedDate: string;
  multiMaterial: boolean;
  materialCode: string;
  materialColor: string;
  materialDescription: string;
  materialPrintColor1: string;
  materialPrintColor2: string;
  multiPrimaryMaterialCode: string;
  multiPrimaryMaterialColor: string;
  multiPrimaryMaterialDescription: string;
  multiPrimaryMaterialPrintColor1: string;
  multiPrimaryMaterialPrintColor2: string;
  multiSecondaryMaterialCode: string;
  multiSecondaryMaterialColor: string;
  multiSecondaryMaterialColorDescription: string;
  multiSecondaryMaterialPrintColor1: string;
  multiSecondaryMaterialPrintColor2: string;
  size: string;
  enamelColor: string;
  stoneType: string;
  backOrder: number;
  customerId: number;
  customerName: string;
  productsId: number;
  productName: string;
  type: string;
  closedDate: string;
  printedDate: string;
  releasedDate: string;
  link?: string;
  materialPrintColor1Hex?: string;
  dateClosed: string;
  stoneCut: string;
  stoneClarity: string;
  stoneColor: string;
  stoneSize: string;
  labGrown: boolean;
  traceable: boolean;
  stonereleasedate: string;
  stoneQty: number;
  workQty: number;
  totalStoneQty: number;
  qtyMoved: number;
}
