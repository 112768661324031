<div class="product-sku-generator">
  <h3 mat-dialog-title>Generate Other Component SKU</h3>
  <mat-dialog-content class="mat-typography product-sku-generator__container">
    <div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Size</mat-label>
        <mat-select [(ngModel)]="skuDetail.otherComponentSizeId">
          <mat-option>-- None --</mat-option>
          <mat-option
            *ngFor="let size of data.sizes"
            [value]="size.otherComponentsSizesID"
          >
            {{ size.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Material</mat-label>
        <mat-select [(ngModel)]="skuDetail.materialCodeId">
          <mat-option>-- None --</mat-option>
          <mat-option
            *ngFor="let mats of data.materials"
            [value]="mats.materialsCodeID"
            class="material-options"
            style="display: flex; align-items: center"
          >
            <div
              style="
                width: 25px;
                height: 18px;
                border: 1px solid white;
                background-color: rgb(0, 0, 0);
                margin-right: 5px;
              "
              [ngStyle]="{
                'background-color': '#' + mats.materialCode?.printColor1
              }"
            ></div>
            <span>{{ mats.materialCode.description }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="generated-sku-container">
      <div class="generated-sku">
        <div
          class="copy-icon"
          (click)="onCopySku()"
          nz-popover
          nzPopoverContent="Copied to Clipboard"
          nzPopoverPlacement="bottomRight"
          nzPopoverTrigger="click"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-copy"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"
            />
            <path
              d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
            />
          </svg>
        </div>
        <p style="line-height: 1.5">{{ getGeneratedProductSku() }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="display: flex; justify-content: center">
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px; width: 100px"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
