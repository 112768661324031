<div class="finding-stock-dialog">
  <h3 mat-dialog-title>New Stock</h3>
  <mat-dialog-content class="mat-typography">
    <div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Material Code</mat-label>
        <mat-select [(ngModel)]="selectedStock.materialsCodeID">
          <mat-option
            *ngFor="let mats of materialCodes"
            [value]="mats.materialCodeId"
          >
            {{ mats.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row-1">
      <mat-form-field appearance="fill">
        <mat-label>QTY in Stock</mat-label>
        <input
          matInput
          min="0"
          type="number"
          [(ngModel)]="selectedStock.qtyInStock"
          style="color: white"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Weight (g)</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="selectedStock.weight_G"
          max="99.99"
          style="color: white"
        />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        [disabled]="isSaving || !isFormValid"
        (click)="onSaveStock()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
