import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import {
  WorkOrderMovementHistory,
  WorkOrderTracking,
} from 'src/app/components/work-order-tracking/model';
import { WorkOrderPrintDialogComponent } from 'src/app/components/work-order-view/work-order-print/work-order-print-dialog.component';
import { ScanService } from 'src/app/services/scan.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { WorkOrderMovementReport } from '../work-order-moved-report';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'work-order-moved',
  templateUrl: './work-order-moved.component.html',
  styleUrls: ['./work-order-moved.component.scss'],
})
export class WorkOrderMovedComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  filters: Filters = {
    dateRangeFrom: DateTime.local().toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.local().toFormat('yyyy-MM-dd'),
  };
  selectedItem: WorkOrderMovementReport = {} as WorkOrderMovementReport;
  displayedColumns = ['picturePath', 'productName', 'workOrdersId'];
  workOrderMovedItems = new MatTableDataSource<WorkOrderMovementReport>([]);
  workOrderMovementHistory: WorkOrderMovementHistory[] = [];
  workOrderTracking: WorkOrderTracking | null = null;
  poLabel = 'PO';
  poValue = '-';

  constructor(
    public dialog: MatDialog,
    private workOrderMovementService: WorkOrderMovementService,
    private scanService: ScanService,
  ) {}

  ngOnInit(): void {
    this.getMovedWorkOrders();
  }

  ngAfterViewInit(): void {
    this.workOrderMovedItems.sort = this.sort;
  }

  onDateRangeChange({ start, end }) {
    this.filters.dateRangeFrom = start;
    this.filters.dateRangeTo = end;
    this.getMovedWorkOrders();
  }

  getMovedWorkOrders() {
    this.workOrderMovementService
      .getWorkOrderMovementReportDateFilter({
        from: this.filters.dateRangeFrom,
        to: this.filters.dateRangeTo,
      })
      .subscribe((data) => {
        this.workOrderMovedItems.data = data;
        this.selectedItem = {} as WorkOrderMovementReport;
        this.workOrderTracking = null;
        this.workOrderMovementHistory = [];
        this.poLabel = 'PO';
        this.poValue = '-';
      });
  }
  onSelectItem(item: WorkOrderMovementReport) {
    this.selectedItem = item;
    this.getWorkOrderDetails(item.workOrdersId);
  }
  getWorkOrderDetails(workOrderId) {
    forkJoin([
      this.scanService.scanWorkOrder(workOrderId),
      this.workOrderMovementService.getWorkOrderMovement(workOrderId),
    ]).subscribe(([data, history]) => {
      this.workOrderTracking = data;
      this.workOrderMovementHistory = orderBy(
        history,
        'workOrdersMovementID',
        'asc',
      );
      if (this.workOrderTracking) {
        const { poExternal, poInternal } = this.workOrderTracking.purchaseOrder;
        const hasExternalAndInternal = poExternal && poInternal;
        const isEqual = poExternal === poInternal;
        this.poLabel =
          hasExternalAndInternal && !isEqual ? 'PO External / Internal' : 'PO';
        this.poValue =
          hasExternalAndInternal && !isEqual
            ? `${poExternal} / ${poInternal}`
            : poExternal || poInternal;
      }
    });
  }
  onPrintWorkOrder() {
    if (this.workOrderTracking.workOrder.workOrdersId <= 0) return;
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: {
          printedDate: '',
          releasedDate: '',
          dateClosed: this.workOrderTracking?.workOrder?.closedDate ?? '',
          workOrdersId: this.workOrderTracking.workOrder.workOrdersId,
        },
      },
    });
  }
}
