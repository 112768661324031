<div class="jumpring-dialog">
  <h3 mat-dialog-title>Chain Style</h3>
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill">
      <mat-label>Style Name</mat-label>
      <input matInput [(ngModel)]="chainStyle.styleName" />
    </mat-form-field>

    <div class="mat-table__container" style="margin-top: 10px">
      <table
        mat-table
        matSort
        [dataSource]="chainStyles"
        matSortActive="chainStylesID"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="styleName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by styleName"
          >
            Style Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.styleName }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="riva-chain-row"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="1">No Available Data</td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        [disabled]="isSaving || !isFormValid"
        (click)="onSaveChainStyle()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
