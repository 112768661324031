import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InvoiceItemType } from 'src/app/components/invoicing-shipping/invoicing/models/invoice';
import {
  WorkOrderMovementHistory,
  WorkOrderTracking,
} from 'src/app/components/work-order-tracking/model';
import { WorkOrderPrintDialogComponent } from 'src/app/components/work-order-view/work-order-print/work-order-print-dialog.component';
import { ScanService } from 'src/app/services/scan.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { ProductLookup } from '../model';
import { WorkOrderMovementReport } from '../work-order-moved-report';

interface ItemModel {
  id: number;
  name: string;
  type: InvoiceItemType;
}

@Component({
  selector: 'work-order-product-search',
  templateUrl: './work-order-product-search.component.html',
  styleUrls: ['./work-order-product-search.component.scss'],
})
export class WorkOrderProductSearchComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  selectedItem: WorkOrderMovementReport = {} as WorkOrderMovementReport;
  displayedColumns = ['picturePath', 'productName', 'workOrdersId'];
  workOrderMovedItems = new MatTableDataSource<WorkOrderMovementReport>([]);
  workOrderMovementHistory: WorkOrderMovementHistory[] = [];
  workOrderTracking: WorkOrderTracking | null = null;
  poLabel = 'PO';
  poValue = '-';
  productLookup: ProductLookup[] = [];
  productLookupControl = new FormControl();
  filteredProductLookup: Observable<ProductLookup[]>;
  selectedProductLookup: ProductLookup = {} as ProductLookup;

  constructor(
    public dialog: MatDialog,
    private workOrderMovementService: WorkOrderMovementService,
    private scanService: ScanService,
  ) {}

  ngOnInit(): void {
    this.workOrderMovementService.getProductLookup().subscribe((products) => {
      this.productLookup = products;
      this.filteredProductLookup = this.productLookupControl.valueChanges.pipe(
        startWith(this.selectedProductLookup.productName),
        map((value) => this._filterProduct(value)),
      );
    });
  }

  ngAfterViewInit(): void {
    this.workOrderMovedItems.sort = this.sort;
  }

  private _filterProduct(name: string): ProductLookup[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.productLookup.filter((option) =>
        option.productName?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['productName'];
      return this.productLookup.filter((option) =>
        option.productName?.toLowerCase().includes(filterValue),
      );
    }
    return this.productLookup;
  }

  getMovedWorkOrders(productsId) {
    this.workOrderMovementService
      .getWorkOrderMovementReportSearch(productsId)
      .subscribe((data) => {
        this.workOrderMovedItems.data = data;
        this.selectedItem = {} as WorkOrderMovementReport;
        this.workOrderTracking = null;
        this.workOrderMovementHistory = [];
        this.poLabel = 'PO';
        this.poValue = '-';
      });
  }
  onSelectProduct(product: string | ProductLookup) {
    if (typeof product === 'string' || (product?.productsId ?? 0) === 0) return;
    this.getMovedWorkOrders(product?.productsId);
  }
  onSelectItem(item: WorkOrderMovementReport) {
    this.selectedItem = item;
    this.getWorkOrderDetails(item.workOrdersId);
  }
  getWorkOrderDetails(workOrderId) {
    forkJoin([
      this.scanService.scanWorkOrder(workOrderId),
      this.workOrderMovementService.getWorkOrderMovement(workOrderId),
    ]).subscribe(([data, history]) => {
      this.workOrderTracking = data;
      this.workOrderMovementHistory = orderBy(
        history,
        'workOrdersMovementID',
        'asc',
      );
      if (this.workOrderTracking) {
        const { poExternal, poInternal } = this.workOrderTracking.purchaseOrder;
        const hasExternalAndInternal = poExternal && poInternal;
        const isEqual = poExternal === poInternal;
        this.poLabel =
          hasExternalAndInternal && !isEqual ? 'PO External / Internal' : 'PO';
        this.poValue =
          hasExternalAndInternal && !isEqual
            ? `${poExternal} / ${poInternal}`
            : poExternal || poInternal;
      }
    });
  }

  displayValue(item: ProductLookup) {
    return item?.productName ?? '';
  }

  onPrintWorkOrder() {
    if (this.workOrderTracking.workOrder.workOrdersId <= 0) return;
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: {
          printedDate: '',
          releasedDate: '',
          dateClosed: this.workOrderTracking?.workOrder?.closedDate ?? '',
          workOrdersId: this.workOrderTracking.workOrder.workOrdersId,
        },
      },
    });
  }
}
