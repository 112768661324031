import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductFindings } from 'src/app/models/findings.model';

@Component({
  selector: 'product-findings-table',
  styleUrls: ['./product-findings-table.component.scss'],
  templateUrl: './product-findings-table.component.html',
})
export class ProductFindingsTableComponent implements OnInit {
  @Input() productFindings: ProductFindings[];
  @Input() editMode: boolean;
  @Output() onDeleteFindings = new EventEmitter<ProductFindings>();

  displayedColumns: string[] = [
    'picPath',
    'name',
    'material',
    'size',
    'qty',
    'comment',
  ];
  action: string[] = ['delete'];

  ngOnInit(): void {
    if (this.onDeleteFindings.observers.length && this.editMode) {
      this.displayedColumns = [...this.displayedColumns, ...this.action];
    }
  }
}
