<h3 mat-dialog-title>Linked Shipments</h3>
<mat-dialog-content class="mat-typography">
  <div class="related-invoices-dialog">
    <div style="border: 1px solid #646464">
      <table
        mat-table
        [dataSource]="relatedShipments"
        matSort
        matSortActive="productName"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="shipmentsID">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by shipmentsID"
          >
            Shipment #
          </th>
          <td mat-cell *matCellDef="let element">
            <a
              class="riva-button riva-button-sm riva-button-link"
              href="/invoicing-and-shipping/shipping/{{ element.shipmentsID }}"
              target="_blank"
              style="font-size: 14px"
            >
              {{ element.shipmentsID }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="shippedDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by shippedDate"
          >
            Shipped Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.shippedDate | date : 'MM/dd/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="qty">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by qty"
          >
            Qty
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.qty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="trackingNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by trackingNumber"
          >
            Tracking #
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.trackingNumber }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="4">No Linked Shipments</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div style="display: flex; justify-content: center">
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px; width: 150px"
      mat-dialog-close
    >
      Close
    </button>
  </div>
</mat-dialog-actions>
