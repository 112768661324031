import { PAGE_NAME } from './pages';

export const PURCHASE_ORDER_FEATURE_KEY = {
  newOrder: 'PURCHASE_ORDER_NEW_ORDER',
};

export const purchaseOrderPermission = {
  [PAGE_NAME.order]: {
    SuperUser: {
      [PURCHASE_ORDER_FEATURE_KEY.newOrder]: 'access',
    },
    User: {
      [PURCHASE_ORDER_FEATURE_KEY.newOrder]: 'access',
    },
    Viewer: {
      [PURCHASE_ORDER_FEATURE_KEY.newOrder]: 'disabled',
    },
  },
};
