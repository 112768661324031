import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash/orderBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderDetailsEnamel } from 'src/app/models/orders.model';
import { environment } from 'src/environments/environment';
import { ProductOrderChainModel } from '../riva-chain/riva-chain-product/model';
import { GenericService } from './../../services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class OrderdetailsService extends GenericService {
  baseUrl = environment.apiUrl + 'orderdetails';
  chainUrl = `${environment.apiUrl}order-details/chain`;

  constructor(http: HttpClient) {
    super(http);
  }

  getList(): Observable<any> {
    return this.http.get(this.baseUrl + '/list', { headers: this.headers });
  }

  addupdate(orderDetails: any): Observable<any> {
    return this.http.post(this.baseUrl + '/addupdate', orderDetails, {
      headers: this.headers,
    });
  }

  deleteOrder(orderDetailsId: number, type = 0): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + orderDetailsId, {
      headers: this.headers,
      params: {
        type,
      },
    });
  }

  clearOrderDetails(orderDetailsId: number) {
    return this.http.delete(this.baseUrl + '/clear/' + orderDetailsId, {
      headers: this.headers,
    });
  }

  getByOrderId(orderid: number): Observable<any> {
    return this.http
      .get(this.baseUrl + '/getbyOrderid/' + orderid, {
        headers: this.headers,
      })
      .pipe(
        map((data: Array<any>) =>
          orderBy(
            data.map(({ enamel, ...orderDetail }) => ({
              ...orderDetail,
              orderDetailsEnamel: {
                enamelID: enamel.enamelId,
                colorHex: enamel.colorHex,
                enamelName: enamel.enamelName,
                ordersDetailsEnamelID: enamel.ordersDetailsEnamelID,
              },
              enamel: enamel.enamelName
                ? `${
                    enamel.pantoneColor ? '[' + enamel.pantoneColor + '] ' : ''
                  }${enamel.enamelName}`
                : '',
              stoneText: `${orderDetail.stone?.type ?? ''}${
                orderDetail.stone?.color ? ` | ${orderDetail.stone?.color}` : ''
              }`,
            })),
            ['ordersDetailsId'],
          ),
        ),
      );
  }

  setOrderDetailEnamel(orderDetailEnamel: Partial<OrderDetailsEnamel>) {
    return this.http.post(
      `${environment.apiUrl}order-details-enamel`,
      orderDetailEnamel,
      { headers: this.headers },
    );
  }

  setOrderDetailsStone(stone: any): Observable<any> {
    return this.http.post(this.baseUrl + '/stones', stone, {
      headers: this.headers,
    });
  }
  setOrderDetailsChain(chain: any): Observable<any> {
    return this.http.post(this.chainUrl, chain, {
      headers: this.headers,
    });
  }
  getOrderDetailsChain(
    orderDetailsId: number,
  ): Observable<ProductOrderChainModel> {
    return this.http.get<ProductOrderChainModel>(
      `${this.chainUrl}/${orderDetailsId}`,
      {
        headers: this.headers,
      },
    );
  }
  deleteOrderDetailsChain(orderDetailsId: number) {
    return this.http.delete(`${this.chainUrl}/${orderDetailsId}`, {
      headers: this.headers,
    });
  }
  bypassWorkOrder(workOrder): Observable<any> {
    return this.http.put(`${this.baseUrl}/bypass-work-order`, workOrder, {
      headers: this.headers,
    });
  }
}
