import { customerPermission } from './customer-permission';
import { enamelPermission } from './enamel-permission';
import { findingsPermission } from './findings-permission';
import { invoicingShippingPermission } from './invoicing-shipping-permission';
import { orderDetailsPermission } from './order-details-permission';
import { otherComponentPermission } from './other-component-permission';
import { productPermission } from './product-permission';
import { purchaseOrderPermission } from './purchase-order-permission';
import { reportingPermission } from './reporting-permission';
import { stoneOpenOrderReportPermission } from './stone-open-order-report-permission';
import { stonePermission } from './stone-permission';
import { supplierPermission } from './supplier-permission';
import { workOrderPermission } from './work-order-permission';

export const USER_PERMISSIONS = {
  ...productPermission,
  ...workOrderPermission,
  ...purchaseOrderPermission,
  ...orderDetailsPermission,
  ...stonePermission,
  ...invoicingShippingPermission,
  ...reportingPermission,
  ...findingsPermission,
  ...enamelPermission,
  ...otherComponentPermission,
  ...supplierPermission,
  ...customerPermission,
  ...stoneOpenOrderReportPermission,
};
