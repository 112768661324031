import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Customers } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'customer-setting-dialog',
  templateUrl: './customer-setting-dialog.component.html',
  styleUrls: ['./customer-setting-dialog.component.scss'],
})
export class CustomerSettingDialogComponent implements OnInit {
  isSaving = false;
  customer: Customers

  constructor(
    public dialogRef: MatDialogRef<CustomerSettingDialogComponent>,
    public dialog: MatDialog,
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA)
    public props: { customer: Customers },
  ) {}

  ngOnInit(): void {
    this.customer = {...this.props.customer}
  }

  onSave() {
    this.isSaving = true;
    const {
      billingAddresses,
      developmentAddresses,
      productionAddresses,
      ...customer
    } = this.customer;

    this.customerService.post(customer, '').subscribe((result) => {
      this.isSaving = false;
      this.dialogRef.close(this.customer);
    });
  }
}
