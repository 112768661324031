<div style="position: relative">
  <div class="invoicing-detail">
    <div class="invoicing-detail__header">
      <div style="display: flex; align-items: center">
        <i
          nz-icon
          nzType="left-circle"
          nzTheme="fill"
          style="margin-right: 20px; cursor: pointer; display: block !important"
          title="Back to Invoicing And Shipping Page"
          (click)="goToInvoicingAndShipping()"
        ></i>
        <span>Invoice Detail</span>
      </div>
      <div>
        <button
          class="btn riva-button scan-work-order"
          (click)="onDownloadInvoice()"
          [disabled]="!invoiceUrl"
          *ngIf="invoiceId"
        >
          <span>Open Invoice</span>
        </button>
        <button
          class="btn riva-button scan-work-order"
          style="margin-left: 10px"
          (click)="onOpenRelatedShipments()"
          *ngIf="invoiceId"
        >
          <span>Linked Shipments</span>
        </button>
      </div>
    </div>
    <div class="invoicing-detail__container">
      <div class="invoicing-detail__info-detail">
        <div class="invoice-detail">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Invoice No</mat-label>
            <input
              matInput
              [ngModel]="invoiceDetail.invoicesID"
              [disabled]="true"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Invoiced Date</mat-label>
            <input
              matInput
              [ngModel]="invoiceDetail.invoicedDate"
              [disabled]="true"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Customer</mat-label>
            <mat-select
              [(ngModel)]="invoiceDetail.custIDNo"
              (ngModelChange)="onSelectCustomer()"
              [disabled]="
                !!invoiceDetail.invoicesID || invoiceDetails.data.length > 0
              "
            >
              <mat-option
                *ngFor="let customer of customers"
                [value]="customer.custIdno"
              >
                {{ customer.companyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- TODO: Delete Code -->
          <!-- <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Customer</mat-label>
            <input
              matInput
              [ngModel]="invoiceDetail.customer.companyName"
              [disabled]="true"
            />
          </mat-form-field> -->
          <mat-form-field appearance="fill">
            <mat-label>Billing Address</mat-label>
            <mat-select
              [(ngModel)]="invoiceDetail.billingAddressId"
              [disabled]="!editMode || !invoiceDetail.custIDNo"
            >
              <mat-option
                *ngFor="let billing of billingAddresses"
                [value]="billing.customersAddressesId"
              >
                {{ billing.fullAddress }}
              </mat-option>
              <mat-option (click)="onAddNewAddress()">
                Add New Address
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="metal-market-details">
            <div class="metal-market-details__row">
              <div>London PM Fix</div>
            </div>
            <div class="metal-market-details__row">
              <div>Date</div>
              <div>{{ selectedMetalMarket.marketDate }}</div>
            </div>
            <div class="metal-market-details__row">
              <div>Silver</div>
              <div>{{ selectedMetalMarket.ldpmSilver | currency }}</div>
            </div>
            <div class="metal-market-details__row">
              <div>Gold</div>
              <div>{{ selectedMetalMarket.ldpmGold | currency }}</div>
            </div>
            <div class="metal-market-details__row">
              <div>Platinum</div>
              <div>{{ selectedMetalMarket.ldpmPlatinum | currency }}</div>
            </div>
            <div class="metal-market-details__row">
              <div>Palladium</div>
              <div>{{ selectedMetalMarket.ldpmPalladium | currency }}</div>
            </div>
          </div>

          <mat-form-field appearance="fill">
            <mat-label>Metal Market Date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [(ngModel)]="invoiceDetail.metalMarket.marketDate"
              (ngModelChange)="onChangeDate($event)"
              [disabled]="true"
              [matDatepickerFilter]="metalFilter"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              [dateClass]="dateClass"
              #picker
              [disabled]="!editMode"
            ></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Internal Comments</mat-label>
            <input
              matInput
              [(ngModel)]="invoiceDetail.internalComment"
              [disabled]="!editMode"
              [maxlength]="500"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>External Comments</mat-label>
            <input
              matInput
              [(ngModel)]="invoiceDetail.externalComment"
              [disabled]="!editMode"
              [maxlength]="250"
            />
          </mat-form-field>
          <div>
            <mat-form-field
              appearance="fill"
              style="width: 100%"
              *ngIf="invoiceDetail.invoicePaid"
            >
              <mat-label>Paid</mat-label>
              <input
                matInput
                [ngModel]="invoiceDetail.invoicePaid"
                [disabled]="true"
              />
            </mat-form-field>
            <button
              class="btn riva-button"
              (click)="onPaidInvoices()"
              *ngIf="!invoiceDetail.invoicePaid"
              userPermission
              [elementKey]="featureKey.markAsPaid"
              [overrideDisable]="!invoiceDetail.invoicesID"
            >
              <span>Paid Invoice</span>
            </button>
          </div>
        </div>
        <div
          *ngIf="!invoiceDetail.invoicesID"
          style="display: grid; align-items: center; gap: 15px"
        >
          <button
            class="btn riva-button scan-work-order"
            (click)="onScanWorkOrder()"
            [overrideDisable]="invoiceDetail.invoicesID"
            userPermission
            [elementKey]="featureKey.create"
          >
            <span>Scan Work Order</span>
          </button>
          <fieldset [disabled]="!invoiceDetail.custIDNo">
            <button
              class="btn riva-button scan-work-order"
              (click)="onAddCharge()"
              userPermission
              [elementKey]="featureKey.create"
            >
              <span>Add Charge</span>
            </button>
          </fieldset>
          <fieldset userPermission [elementKey]="featureKey.create">
            <button
              class="btn btn-success"
              (click)="onSubmitInvoice()"
              [disabled]="!invoiceSubmitValid"
            >
              <span>Submit Invoice</span>
            </button>
          </fieldset>
        </div>
        <div
          *ngIf="invoiceDetail.invoicesID"
          style="display: grid; align-items: center; gap: 15px"
        >
          <fieldset userPermission [elementKey]="featureKey.create">
            <button
              class="btn riva-button scan-work-order"
              (click)="redirectToShipment()"
              [disabled]="!generatedShipmentDetails.length"
            >
              <span>Generate Shipment</span>
            </button>
          </fieldset>
          <div [ngClass]="{ hidden: editMode }">
            <button
              class="btn btn-primary"
              (click)="onEditInvoice()"
              style="text-transform: none"
              userPermission
              [elementKey]="featureKey.edit"
            >
              <span>Edit Invoice</span>
            </button>
          </div>
          <div [ngClass]="{ hidden: !editMode }">
            <button
              class="btn riva-button scan-work-order"
              (click)="onScanWorkOrder()"
              style="margin-bottom: 16px"
            >
              <span>Scan Work Order</span>
            </button>
            <button
              class="btn riva-button scan-work-order"
              (click)="onAddCharge()"
              userPermission
              [elementKey]="featureKey.create"
              style="margin-bottom: 16px"
            >
              <span>Add Charge</span>
            </button>
            <div style="display: grid; grid-template-columns: 1fr 60px">
              <button
                class="btn btn-success"
                (click)="onSubmitInvoice(true)"
                style="text-transform: none; border-radius: 0.25rem 0 0 0.25rem"
                [disabled]="!invoiceSubmitValid"
              >
                <span>Submit Invoice</span>
              </button>
              <button
                class="btn btn-danger"
                (click)="onCancelInvoiceEditing()"
                style="border-radius: 0 0.25rem 0.25rem 0"
              >
                <span nz-icon nzType="close" nzTheme="outline"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <table
        mat-table
        multiTemplateDataRows
        [dataSource]="invoiceDetails"
        matSort
        matSortActive="key"
      >
        <ng-container matColumnDef="expander">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let element"
            class="expander-column"
            (click)="updateExpandedInvoice(element)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-square-plus"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#a762b7"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              [ngClass]="{ hidden: expandedInvoice[element.key] }"
              *ngIf="!element.isCharges"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
              />
              <path d="M9 12l6 0" />
              <path d="M12 9l0 6" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-square-minus"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#a762b7"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              [ngClass]="{ hidden: !expandedInvoice[element.key] }"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
              />
              <path d="M9 12l6 0" />
            </svg>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="productPicPath">
          <th mat-header-cell *matHeaderCellDef>Pic</th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                width: 55px;
                padding: 5px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              "
              *ngIf="element.productPicPath"
            >
              <img
                style="max-width: 100%; max-height: 100%"
                [src]="element.productPicPath"
                onerror="this.src='assets/images/no-image.png'"
              />
              <span class="img-custom" *ngIf="element.customized">CUSTOM</span>
            </div>
            <div
              style="
                width: 55px;
                padding: 5px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              "
              *ngIf="!element.productPicPath"
            >
              <img
                style="max-width: 100%; max-height: 100%"
                src="assets/images/no-image.png"
              />
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="key">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by key"
          >
            Index
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.key + 1 }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="productName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by productName"
          >
            Product Name
          </th>
          <td mat-cell *matCellDef="let element">
            <input
              nz-input
              [(ngModel)]="element.productName"
              *ngIf="editMode && element.isCharges"
            />
            <span *ngIf="!editMode || !element.isCharges">{{
              element.productName
            }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="material">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by material"
          >
            Metal
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="!element.isCharges || !editMode">
              {{ element.material }}
              <span *ngIf="element.productMaterial?.materialCode2"
                >/ {{ element.productMaterial?.materialCode2 }}</span
              >
              <span
                *ngIf="
                  !element.productMaterial?.materialCode2 &&
                  element.materialCode2
                "
                >/ {{ element.materialCode2 }}</span
              >
            </span>
            <nz-select
              [(ngModel)]="element.materialsCodeId"
              *ngIf="element.isCharges && editMode"
              style="width: 120px"
            >
              <nz-option
                *ngFor="let material of materialCodes"
                [nzValue]="material.materialCodeId"
                [nzLabel]="material.description"
              ></nz-option>
            </nz-select>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="size">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by size"
          >
            Size
          </th>
          <td mat-cell *matCellDef="let element">{{ element.size }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="enamel">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by enamel"
          >
            Enamel
          </th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; align-items: center">
              <div
                *ngIf="element.enamelColor"
                style="
                  width: 30px;
                  height: 30px;
                  border: 1px solid black;
                  background-color: rgb(255, 255, 255);
                  margin-right: 10px;
                "
                [ngStyle]="{
                  'background-color': element.enamelColor
                }"
              ></div>
              <span>
                <span *ngIf="element.pantoneColor" style="margin-right: 6px"
                  >[{{ element.pantoneColor }}]</span
                >
                <span>{{ element.enamelName }}</span>
              </span>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="chainRawLength">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by chainRawLength"
          >
            Length
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.chainRawLength }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="weight">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by weight"
          >
            Weight per piece
          </th>
          <td mat-cell *matCellDef="let element">
            <div style="padding: 0 8px">
              <nz-input-number
                [(ngModel)]="element.weight"
                [nzMin]="0.001"
                [nzStep]="1"
                style="width: 100%"
                [ngClass]="{ 'input-disabled': element.hasWeight }"
                [disabled]="
                  element.hasWeight ||
                  !editMode ||
                  !(element.isCharges ? element.materialsCodeId : true)
                "
              ></nz-input-number>
            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="rollingWeightAverage">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by rollingWeightAverage"
          >
            Expected Weight
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              [ngClass]="{ 'alarming-weight': checkWeightAlarming(element) }"
            >
              {{ element.rollingWeightAverage
              }}<span *ngIf="element.rollingWeightAverage">g</span>
            </span>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="stoneType">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by stoneType"
          >
            Stone Type
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.stoneType }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="stoneColor">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by stoneColor"
          >
            Stone Color
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.stoneColor }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="stoneSize">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by stoneSize"
          >
            Stone Size
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.overrideStoneSize || element.stoneSize }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="chain">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by chain"
          >
            Chain
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.chain }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="qty">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by qty"
          >
            Qty
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="item-qty" *ngIf="editMode">
              <nz-input-number
                [(ngModel)]="element.qty"
                [nzMin]="1"
                [nzMax]="element.maxQty"
                [nzStep]="1"
                style="width: 100%"
                *ngIf="!element.isCharges"
              ></nz-input-number>
              <nz-input-number
                [(ngModel)]="element.qty"
                [nzMin]="1"
                [nzStep]="1"
                style="width: 100%"
                *ngIf="element.isCharges"
              ></nz-input-number>
              <span class="max-item" *ngIf="!element.isCharges">{{
                element.maxQty
              }}</span>
            </div>
            <span *ngIf="!editMode">{{ element.qty }}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>{{ totalQty }}</td>
        </ng-container>
        <ng-container matColumnDef="poNumber">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by poNumber"
          >
            PO Number
          </th>
          <td mat-cell *matCellDef="let element">{{ element.poNumber }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="cipo">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by cipo"
          >
            CIPO
          </th>
          <td mat-cell *matCellDef="let element">{{ element.cipo }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="pricePerInch">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by pricePerInch"
          >
            Price per inch
          </th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              [(ngModel)]="element.pricePerInch"
              (ngModelChange)="onPricePerInchChange(element)"
              [nzStep]="1"
              [nzPrecision]="2"
              style="width: 100%"
              [disabled]="!editMode"
              *ngIf="!element.isCharges && element.isUnfinishedChain"
            ></nz-input-number>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by price"
          >
            Price
          </th>
          <td mat-cell *matCellDef="let element">
            <nz-input-number
              [(ngModel)]="element.price"
              (ngModelChange)="onPriceChange(element)"
              [nzStep]="1"
              [nzPrecision]="2"
              style="width: 100%"
              [disabled]="!editMode"
            ></nz-input-number>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="priceExt">
          <th mat-header-cell *matHeaderCellDef>Price Ext.</th>
          <td mat-cell *matCellDef="let element">
            {{ element.qty * element.price | currency }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <div class="shipping-cost">
              <span style="font-size: 14px; margin-bottom: 5px"
                >Shipping cost</span
              >
              <nz-input-number
                [(ngModel)]="invoiceDetail.shippingCost"
                [disabled]="!editMode"
                [nzMin]="0"
                [nzStep]="1"
                [nzPrecision]="2"
                style="width: 150px"
              ></nz-input-number>
            </div>
            <div>
              {{ totalPriceExt | currency }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <i
              nz-icon
              nzType="delete"
              nzTheme="fill"
              class="icon-delete"
              *ngIf="editMode"
              (click)="onRemoveInvoice(element)"
            ></i>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-footer-row
          *matFooterRowDef="displayedColumns"
          [style.display]="invoiceDetails.data.length ? 'table-row' : 'none'"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="21">No Available Data</td>
        </tr>
        <ng-container matColumnDef="expandedDetail">
          <td
            class="expandable-detail"
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div
              class="expandable-element"
              [@detailExpand]="
                expandedInvoice[element.key] ? 'expanded' : 'collapsed'
              "
            >
              <div class="expandable-container">
                <div style="align-self: flex-end; justify-self: center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-square-rounded-plus-filled"
                    width="44"
                    height="44"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#a762b7"
                    fill="#a762b7"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    (click)="onAddCharges(element)"
                    style="cursor: pointer"
                    *ngIf="editMode"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 2l.324 .001l.318 .004l.616 .017l.299 .013l.579 .034l.553 .046c4.785 .464 6.732 2.411 7.196 7.196l.046 .553l.034 .579c.005 .098 .01 .198 .013 .299l.017 .616l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.464 4.785 -2.411 6.732 -7.196 7.196l-.553 .046l-.579 .034c-.098 .005 -.198 .01 -.299 .013l-.616 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.785 -.464 -6.732 -2.411 -7.196 -7.196l-.046 -.553l-.034 -.579a28.058 28.058 0 0 1 -.013 -.299l-.017 -.616c-.003 -.21 -.005 -.424 -.005 -.642l.001 -.324l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.464 -4.785 2.411 -6.732 7.196 -7.196l.553 -.046l.579 -.034c.098 -.005 .198 -.01 .299 -.013l.616 -.017c.21 -.003 .424 -.005 .642 -.005zm0 6a1 1 0 0 0 -1 1v2h-2l-.117 .007a1 1 0 0 0 .117 1.993h2v2l.007 .117a1 1 0 0 0 1.993 -.117v-2h2l.117 -.007a1 1 0 0 0 -.117 -1.993h-2v-2l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                      fill="#a762b7"
                      stroke-width="0"
                    />
                  </svg>
                </div>
                <div style="width: 100%">
                  <div class="container-row container-header">
                    <div class="container-column">Charge Name</div>
                    <!-- <div class="container-column">Qty</div> -->
                    <div class="container-column">Price</div>
                    <div class="container-column">Total</div>
                    <div class="container-column"></div>
                  </div>
                  <div
                    class="container-row"
                    *ngFor="let item of element.invoicesDetailsCharges"
                  >
                    <div class="container-column">
                      <input
                        nz-input
                        [(ngModel)]="item.chargeDescription"
                        *ngIf="editMode"
                      />
                      <span *ngIf="!editMode">{{
                        item.chargeDescription
                      }}</span>
                    </div>
                    <div class="container-column">
                      <div class="container-column">
                        <nz-input-number
                          [(ngModel)]="item.price"
                          [nzStep]="1"
                          style="width: 100%"
                          *ngIf="editMode"
                        ></nz-input-number>
                        <span *ngIf="!editMode">{{
                          item.price | currency
                        }}</span>
                      </div>
                    </div>
                    <div class="container-column">
                      {{ item.price * item.qty | currency }}
                    </div>
                    <div class="container-column">
                      <i
                        nz-icon
                        nzType="delete"
                        nzTheme="fill"
                        class="icon-delete"
                        (click)="onDeleteCharges(element, item.tempUniqueKey)"
                        *ngIf="editMode"
                      ></i>
                    </div>
                  </div>
                  <div
                    class="container-row"
                    *ngIf="!element.invoicesDetailsCharges?.length"
                  >
                    <div
                      class="container-column"
                      style="text-align: center; grid-column: 1 / 5"
                    >
                      No Charges
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr
          class="detail-row"
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
        ></tr>
      </table>
    </div>
  </div>
  <div class="loading-overlay" *ngIf="isLoading">
    <div class="loading-details">
      <div class="loading-spinner">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
        <h3 style="color: white; margin-top: 120px">Loading Details...</h3>
      </div>
    </div>
  </div>
</div>
<swal
  #confirmationDialog
  [title]="confirmation.title"
  [text]="confirmation.description"
  [icon]="confirmation.icon"
  confirmButtonText="Close"
  [showConfirmButton]="confirmation.icon === 'error'"
  [timer]="confirmation.timer"
>
</swal>
