import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Finding } from 'src/app/components/riva-findings/model';
import { ChainService } from 'src/app/services/chain.service';
import { FindingsService } from 'src/app/services/findings.service';
import { ChainFinishBillOfMaterial } from '../models';

@Component({
  templateUrl: './chain-finish-bom-dialog.component.html',
  styleUrls: ['./chain-finish-bom-dialog.component.scss'],
})
export class ChainFinishBomDialogComponent implements OnInit {
  isSaving = false;
  chainFinishBom: ChainFinishBillOfMaterial = {} as ChainFinishBillOfMaterial;
  findingControl = new FormControl();
  finding: Finding = {} as Finding;
  findings: Finding[] = [];
  filteredFindings: Observable<Finding[]>;
  imageFiles: FileList | null = null;

  constructor(
    public dialogRef: MatDialogRef<ChainFinishBomDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public props: { chainFinishedID: number },

    private toastrService: ToastrService,
    private chainService: ChainService,
    private findingsService: FindingsService,
  ) {}

  ngOnInit(): void {
    this.getFindings();
  }

  getFindings() {
    this.findingsService.getFindingList().subscribe((data) => {
      this.findings = data;

      this.filteredFindings = this.findingControl.valueChanges.pipe(
        startWith(this.finding.findingsTypeName),
        map((value) => this._filterFinding(value)),
      );
    });
  }

  private _filterFinding(name: string): Finding[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.findings.filter((option) =>
        option.findingsTypeName.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['findingsTypeName'];
      return this.findings.filter((option) =>
        option.findingsTypeName
          .toLowerCase()
          .includes(filterValue.toLowerCase()),
      );
    }
    return this.findings;
  }

  get isFormValid() {
    return this.finding.findingsId && this.chainFinishBom.qty > 0;
  }

  displayFn(item: Finding): string {
    return item?.findingsTypeName
      ? `${item?.findingsTypeName} (${item?.sizeLabel})`
      : '';
  }

  onSaveChainFinishBom() {
    this.chainFinishBom.chainFinishedID = this.props.chainFinishedID;
    this.chainFinishBom.findingsID = this.finding.findingsId;
    this.isSaving = true;
    this.chainService
      .setChainFinishedBom(this.chainFinishBom)
      .subscribe((id) => {
        this.toastrService.success('Chain BOM successfully saved.');
        this.dialogRef.close(id);
      });
  }
}
