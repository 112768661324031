import { PAGE_NAME } from './pages';
export const REPORTING_FEATURE_KEY = {
  page: 'REPORTING_PAGE',
  edit: 'STONE_EDIT',
  customImage: 'STONE_CUSTOM_IMAGE',
};

export const reportingPermission = {
  [PAGE_NAME.reporting]: {
    SuperUser: {
      [REPORTING_FEATURE_KEY.page]: 'access',
    },
    User: {
      [REPORTING_FEATURE_KEY.page]: 'access',
    },
    Viewer: {
      [REPORTING_FEATURE_KEY.page]: 'hidden',
    },
  },
};
