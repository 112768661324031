import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { map } from 'rxjs/operators';
import { GenericService } from '../../services/generic.service';
import {
  RivaGems,
  StoneClarity,
  StoneColor,
  StoneCut,
  StoneDefaultImage,
  StoneMovementStockTracking,
  StoneSize,
  StoneType,
} from './riva-gems.model';
import {
  getDisplayText,
  setStoneMapper,
  setStoneSizeMapper,
} from './stone.mapper';

@Injectable({
  providedIn: 'root',
})
export class StoneService extends GenericService {
  baseUrl = environment.apiUrl + 'stones';
  stoneMovementUrl = environment.apiUrl + 'stone-movements';

  public reloadStones = (stonesID?: number) => {};
  public reSelectStone = (stonesID?: number) => {};

  constructor(http: HttpClient) {
    super(http);
  }

  getStones(): Observable<RivaGems[]> {
    return this.http
      .get<RivaGems[]>(this.baseUrl, {
        headers: this.headers,
      })
      .pipe(
        map((stones) =>
          stones.map((s) => ({
            ...s,
            type: s.stoneType?.name,
            cut: s.stoneCut?.name,
            clarity: s.stoneClarity?.name,
            color: s.stoneColor?.colorName,
            size: getDisplayText(s.stoneSize),
            sizeD1: s.stoneSize.dimension_1,
            stoneSize: {
              ...s.stoneSize,
              displayText: getDisplayText(s.stoneSize),
            },
            pricePerUnit:
              s.caratsPerUnit *
              (s.stoneTracking.lastPurchasePricePerCarat ?? 0),
            qtyInSTock: s.stoneTracking.qtyInSTock,
          })),
        ),
      );
  }
  setStone(data): Observable<RivaGems> {
    return this.http.post<RivaGems>(this.baseUrl, setStoneMapper(data), {
      headers: this.headers,
    });
  }
  getSizes(): Observable<StoneSize[]> {
    return this.http
      .get<StoneSize[]>(`${this.baseUrl}/sizes`, { headers: this.headers })
      .pipe(
        map((stones) =>
          stones.map((s) => ({ ...s, displayText: getDisplayText(s) })),
        ),
      );
  }
  setStoneSize(data) {
    return this.http.post(`${this.baseUrl}/sizes`, setStoneSizeMapper(data), {
      headers: this.headers,
    });
  }
  getTypes(): Observable<StoneType[]> {
    return this.http.get<StoneType[]>(`${this.baseUrl}/types`, {
      headers: this.headers,
    });
  }
  setStoneType(data) {
    return this.http.post(`${this.baseUrl}/types`, data, {
      headers: this.headers,
    });
  }
  getCuts(): Observable<StoneCut[]> {
    return this.http.get<StoneCut[]>(`${this.baseUrl}/cuts`, {
      headers: this.headers,
    });
  }
  setStoneCut(data) {
    return this.http.post(`${this.baseUrl}/cuts`, data, {
      headers: this.headers,
    });
  }
  getClarities(): Observable<StoneClarity[]> {
    return this.http.get<StoneClarity[]>(`${this.baseUrl}/clarities`, {
      headers: this.headers,
    });
  }
  setStoneClarity(data) {
    return this.http.post(`${this.baseUrl}/clarities`, data, {
      headers: this.headers,
    });
  }
  getColors(): Observable<StoneColor[]> {
    return this.http.get<StoneColor[]>(`${this.baseUrl}/colors`, {
      headers: this.headers,
    });
  }
  setStoneColor(data) {
    return this.http.post(`${this.baseUrl}/colors`, data, {
      headers: this.headers,
    });
  }
  setDefaultPicture(data): Observable<StoneDefaultImage> {
    return this.http.post<StoneDefaultImage>(
      `${this.baseUrl}/default-pictures`,
      data,
      {
        headers: this.headers,
      },
    );
  }
  getStoneDefaultPicture({
    stoneTypesID = 0,
    stoneCutsID = 0,
    stoneColorsID = 0,
  }): Observable<StoneDefaultImage> {
    return this.http.get<StoneDefaultImage>(
      `${this.baseUrl}/default-pictures`,
      {
        headers: this.headers,
        params: { stoneTypesID, stoneCutsID, colorsID: stoneColorsID },
      },
    );
  }
  uploadDefaultPicture(data) {
    return this.http.post(`${this.baseUrl}/upload-default-picture`, data, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
    });
  }
  uploadPicture(data) {
    return this.http.post(`${this.baseUrl}/upload-picture`, data, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
    });
  }
  getStoneMovementStockTracking(stoneId) {
    return this.http.get<StoneMovementStockTracking>(
      `${this.stoneMovementUrl}/stock-tracking/${stoneId}`,
      {
        headers: this.headers,
      },
    );
  }
  setStoneMovementAppends(data) {
    return this.http.post(`${this.stoneMovementUrl}/appends`, data, {
      headers: this.headers,
    });
  }
  setStoneMovement(data) {
    return this.http.post(this.stoneMovementUrl, data, {
      headers: this.headers,
    });
  }
  getStoneExcel() {
    return this.http.get(`${this.baseUrl}/export`, {
      headers: this.headers,
      responseType: 'arraybuffer',
    });
  }
}
