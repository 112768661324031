<div class="bom-findings-post">
  <div>
    <div class="image-wrapper">
      <div class="image-container">
        <img
          [src]="selectedFinding.picPath"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
    </div>
  </div>
  <div class="findings-post-table-container">
    <table
      mat-table
      matSort
      [dataSource]="list"
      matSortActive="findingSizesID"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ columns['name'] }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="size_1">
        <th mat-header-cell *matHeaderCellDef>{{ columns['size_1'] }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_1 }}<span *ngIf="element.size_1">mm</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="size_2">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by size_2"
        >
          {{ columns['size_2'] }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_2 }}<span *ngIf="element.size_2">mm</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="size_3">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by size_3"
        >
          {{ columns['size_3'] }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_3 }}<span *ngIf="element.size_3">mm</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="size_4">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by size_4"
        >
          {{ columns['size_4'] }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_4 }}<span *ngIf="element.size_4">mm</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="customerName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by customerName"
        >
          Customer
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.customerName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="jumpringSize">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by jumpringSize"
        >
          ID
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.jumpringSize }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="post-row"
        (click)="onTableRowClick(row)"
        [ngClass]="{
          'is-selected': row.findingsId === selectedFinding?.findingsId
        }"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="4">No Available Data</td>
      </tr>
    </table>
  </div>
  <div class="findings-post-detail">
    <div class="other-fields">
      <div class="row-1" style="align-items: flex-end">
        <mat-form-field appearance="fill">
          <mat-label>Qty</mat-label>
          <input
            matInput
            type="number"
            style="color: white"
            min="1"
            [(ngModel)]="selectedFinding.qty"
          />
        </mat-form-field>
        <div>
          <div class="override-material">
            <mat-slide-toggle
              [(ngModel)]="isOverride"
              (ngModelChange)="isOverrideChange()"
              style="color: white"
              >Override Material</mat-slide-toggle
            >
          </div>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Material Code</mat-label>
            <mat-select
              [(ngModel)]="selectedFinding.findingsMaterialsID"
              [disabled]="!isOverride"
            >
              <mat-option
                *ngFor="let mat of materialCodes"
                [value]="mat.materialCodeId"
              >
                {{ mat.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Comment</mat-label>
          <textarea
            matInput
            style="height: 42px; color: white"
            [(ngModel)]="selectedFinding.comment"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
