import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import { RivaFindings, Supplier } from 'src/app/models/findings.model';
import { UnitOfMeasure } from 'src/app/models/unit-of-measure';
import { FindingsService } from 'src/app/services/findings.service';

@Component({
  selector: 'findings-list',
  templateUrl: './findings-list.component.html',
  styleUrls: ['./findings-list.component.scss'],
})
export class FindingsListComponent implements OnInit, AfterViewInit {
  @Input() selectedFindings: RivaFindings;
  @Input() editMode: boolean = false;
  @Output() onSelectFindings = new EventEmitter();
  @Output() onSetEditMode = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  findings = new MatTableDataSource<RivaFindings>([]);
  displayedColumns = ['picPath', 'name', 'sku'];
  search = '';

  constructor(private findingsService: FindingsService) {}

  ngOnInit(): void {
    this.getFindings();

    this.findingsService.reloadFindingsList = (findingsId?: number) => {
      this.getFindings(findingsId);
    };
  }
  ngAfterViewInit() {
    this.findings.sort = this.sort;
    this.findings.paginator = this.paginator;
    this.findings.filterPredicate = (
      data: RivaFindings,
      filterValue: string,
    ) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.name.toLowerCase().includes(search?.toLowerCase()) ||
        data.sku?.toLowerCase().includes(search?.toLowerCase()) ||
        data.suppliersName?.toLowerCase().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  getFindings(findingsId?: number) {
    this.findingsService.getFindings().subscribe((data) => {
      this.findings.data = orderBy(
        data,
        (s: RivaFindings) => s.findingsId,
        'desc',
      ).map((findings) => ({
        ...findings,
        suppliersName: findings.supplier?.companyName ?? '',
        unitsOfMeasure: findings.unitsOfMeasure ?? ({} as UnitOfMeasure),
        supplier: findings.supplier ?? ({} as Supplier),
      }));
      const selected = findingsId
        ? this.findings.data.find((f) => f.findingsId === findingsId)
        : this.findings.data[0];

      this.onSelectFindings.emit({ ...(selected ?? {}) });
    });
  }

  onClickNewFinding() {
    this.onSelectFindings.emit({
      unitsOfMeasure: {},
      supplier: {},
      source: false,
      materials: [],
      sizes: [],
    });
    this.onSetEditMode.emit();
  }

  onTableRowClick(row: RivaFindings) {
    if (this.editMode) return;
    this.onSelectFindings.emit({ ...row });
  }

  onFilterChange() {
    this.findings.filter = JSON.stringify({ search: this.search });
  }
}
