import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { lastYearDateFilterPresets, monthDateFilterPresets, thisYearDateFilterPresets } from '../date-filter-presets';

@Component({
  selector: 'riva-reporting-presets',
  templateUrl: './reporting-presets.component.html',
  styleUrls: ['./reporting-presets.component.scss'],
})
export class ReportingPresetsComponent implements OnInit {
  @Output() onChange = new EventEmitter();


  monthFilterPresets = monthDateFilterPresets();
  yearFilterPresets = thisYearDateFilterPresets();
  lastYearFilterPresets = lastYearDateFilterPresets();

  ngOnInit(): void {
   
  }
  onSetDateRange(config) {
    this.onChange.emit(config)
  }
}
