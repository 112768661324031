import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import groupBy from 'lodash/groupBy';
import { forkJoin } from 'rxjs';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductLinkBomGroupByProduct } from '../product-link-bom';

@Component({
  selector: 'product-link-bom-table',
  templateUrl: './product-link-bom-table.component.html',
  styleUrls: ['./product-link-bom-table.component.scss'],
})
export class ProductLinkBomTableComponent implements OnInit, OnChanges {
  @Input() productId: number;
  @Input() isDisplayOnly: boolean = false;

  productLinksBom = new MatTableDataSource<ProductLinkBomGroupByProduct>([]);
  displayedColumns = ['picPath', 'productName', 'sizes', 'delete'];

  constructor(
    private productBomService: ProductBomService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.productId) {
      this.getProductLinkBom(this.productId);
      this.displayedColumns = this.isDisplayOnly
        ? this.displayedColumns.filter((c) => c !== 'delete')
        : this.displayedColumns;
      if (this.isDisplayOnly) {
        this.productBomService.reloadProductLinkBomListDisplay = (productId) =>
          this.getProductLinkBom(productId);
      } else {
        this.productBomService.reloadProductLinkBomList = (productId) =>
          this.getProductLinkBom(productId);
      }
    }
  }

  getProductLinkBom(productId) {
    if (productId) {
      this.productBomService.getProductLinkBom(productId).subscribe((data) => {
        const groupByProduct = groupBy(data, 'productsID_BOM');

        this.productLinksBom.data = Object.keys(groupByProduct).reduce(
          (accum, key) => {
            const productItems = groupByProduct[key];
            const [item] = productItems;
            return [
              ...accum,
              {
                productsID_BOM: item.productsID_BOM,
                productName: item.productName,
                picPath: item.picPath,
                sizes: productItems,
              },
            ];
          },
          [],
        );
      });
    }
  }

  onDeleteProductLink(group: ProductLinkBomGroupByProduct) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product BOM',
        content: 'Are you sure you want to delete this product?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        const request = group.sizes.map((s) =>
          this.productBomService.deleteProductLinkBom(s.productsBOMProductsID),
        );

        forkJoin(request).subscribe(() => {
          this.getProductLinkBom(this.productId);
        });
      });
  }
}
