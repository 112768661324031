import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WorkOrderPrintDialogComponent } from 'src/app/components/work-order-view/work-order-print/work-order-print-dialog.component';
import { User } from 'src/app/core/authentication/user';
import { ScanService } from 'src/app/services/scan.service';
import { UserService } from 'src/app/services/user.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { WorkOrderMovementEmployeeUI } from '../model';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'work-order-employee',
  templateUrl: './work-order-employee.component.html',
  styleUrls: ['./work-order-employee.component.scss'],
})
export class WorkOrderEmployeeComponent implements OnInit, AfterViewInit {
  @ViewChild('employeeSort', { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild('workOrderSort', { read: MatSort, static: true }) sort2: MatSort;

  displayedColumnsForEmployee = ['fullName', 'employeeID'];
  displayedColumnsForWorkOrder = [
    'workOrdersId',
    'picturePath',
    'productName',
    'activityDesc',
    'scanOutTimeRaw',
    'scanInTimeRaw',
    'differenceInMinutes',
  ];
  accounts = new MatTableDataSource<User>([]);
  search = '';
  selectedUser: User | null = null;
  employeeWorkOrderMovements =
    new MatTableDataSource<WorkOrderMovementEmployeeUI>([]);

  constructor(
    public dialog: MatDialog,
    private workOrderMovementService: WorkOrderMovementService,
    private scanService: ScanService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService.getUsers().subscribe((data = []) => {
      this.accounts.data = data.map((account) => ({
        ...account,
        fullName: this.getFullName(account),
      }));
    });
  }

  ngAfterViewInit(): void {
    this.accounts.sort = this.sort1;

    this.accounts.filterPredicate = (data: User, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.fullName
          ?.toString()
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        data.employeeID?.toLowerCase().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  getFullName(account: User) {
    const name = [];
    account.firstName && name.push(account.firstName);
    account.lastName && name.push(account.lastName);
    if (!name.length) {
      name.push(account.userName);
    }
    return name.join(' ');
  }

  onFilterChange() {
    this.accounts.filter = JSON.stringify({ search: this.search });
  }

  onPrintWorkOrder(workOrdersId: number) {
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: {
          printedDate: '',
          releasedDate: '',
          dateClosed: '',
          workOrdersId,
        },
      },
    });
  }
  onSelectUser(user: User) {
    this.selectedUser = user;
    this.getEmployeeWorkOrderMovement(user.usersID);
  }

  getEmployeeWorkOrderMovement(userId: number) {
    this.workOrderMovementService
      .getWorkOrderMovementReportByUser(userId)
      .subscribe((data) => {
        this.employeeWorkOrderMovements.data = data;
        this.employeeWorkOrderMovements.sort = this.sort2;
      });
  }
}
