import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderService } from 'src/app/services/order.service';
import { Invoice } from '../../invoicing-shipping/invoicing/models/invoice';

@Component({
  selector: 'related-invoices-dialog',
  templateUrl: './related-invoices-dialog.component.html',
  styleUrls: ['./related-invoices-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RelatedInvoicesDialogComponent implements OnInit {
  displayedColumns: string[] = ['invoicesID', 'invoicedDate', 'invoicePaid'];
  relatedInvoices: Invoice[] = [];

  constructor(
    private orderService: OrderService,
    public dialogRef: MatDialogRef<RelatedInvoicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { ordersId: number },
  ) {}

  ngOnInit(): void {
    this.orderService
      .getRelatedInvoices(this.props.ordersId)
      .subscribe((invoices) => {
        this.relatedInvoices = invoices;
      });
  }
}
