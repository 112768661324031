import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ProductFindings } from 'src/app/models/findings.model';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';
import {
  ProductChainFinished,
  ProductChainRaw,
} from '../riva-chain/riva-chain-product/model';
import { ProductComponentDialogComponent } from './product-component-dialog/product-component-dialog.component';

@Component({
  selector: 'app-bill-of-material',
  templateUrl: './bill-of-material.component.html',
  styleUrls: ['./bill-of-material.component.scss'],
})
export class BillOfMaterialComponent implements OnInit, OnChanges {
  @Input() productId: number;
  @Input() editMode: boolean;
  productFindings: ProductFindings[] = [];
  productChainUnfinished: ProductChainRaw[] = [];
  productChainFinished: ProductChainFinished[] = [];

  isReloading = false;

  constructor(
    public dialog: MatDialog,
    private productService: ProductService,
    private productBomService: ProductBomService,
    private loadService: LoadService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.loadService.reloadProductFindings = () => this.getProductFindings();
    this.loadService.reloadStoneProduct = () => this.onReloadStones();
    this.loadService.reloadProductChainUnfinished = () =>
      this.getProductChainUnfinished();
    this.loadService.reloadProductChainFinished = () =>
      this.getProductChainFinished();
  }

  ngOnChanges() {
    this.productFindings = [];
    if (this.productId) {
      this.getProductFindings();
      this.getProductChainUnfinished();
      this.getProductChainFinished();
    }
  }

  onDialogOpen() {
    const dialogRef = this.dialog.open(ProductComponentDialogComponent, {
      disableClose: true,
      maxWidth: '1400px',
      width: '100%',
      autoFocus: false,
      data: { productId: this.productId },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.productService.reloadEnamelConstant();
      this.productService.reloadEnamelVariation();
      this.productBomService.reloadProductLinkBomListDisplay(this.productId);
    });
  }

  getProductFindings() {
    this.productBomService
      .getProductFindings(this.productId)
      .subscribe((data) => {
        this.productFindings = data;
      });
  }
  getProductChainUnfinished() {
    this.productBomService
      .getProductChainRaw(this.productId)
      .subscribe((data) => {
        this.productChainUnfinished = data;
      });
  }
  getProductChainFinished() {
    this.productBomService
      .getProductChainFinished(this.productId)
      .subscribe((data) => {
        this.productChainFinished = data;
      });
  }
  onDeleteFindings(findings: ProductFindings) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Findings',
        content: 'Are you sure you want to delete this findings?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productBomService
          .deleteProductFindings(findings.productsBOMFindingsID)
          .subscribe(() => {
            this.loadService.reloadProductFindings();
          });
      });
  }
  onReloadStones() {
    this.isReloading = true;
    setTimeout(() => {
      this.isReloading = false;
    }, 0);
  }
}
