<div class="row">
  <div class="col-2">
    <div class="row" style="position: relative; justify-content: center">
      <img
        class="productimage"
        [src]="selectedChain.picPath"
        onerror="this.src='assets/images/no-image.png'"
      />
    </div>
  </div>
  <div class="col-10">
    <div class="forms-row">
      <div>
        <fieldset [disabled]="isOrderOnline">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Chain</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="selectedChain"
              [formControl]="chainControl"
              [matAutocomplete]="chainAutoComplete"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #chainAutoComplete="matAutocomplete"
              [displayWith]="displayChain"
            >
              <mat-option class="riva-select-header riva-select__stick-header">
                <div class="riva-select">
                  <div class="p5 riva-select__chain-type">Type</div>
                  <div class="p5 riva-select__chain-name">Name</div>
                  <div class="p5 riva-select__chain-sku">Sku</div>
                  <div class="p5 riva-select__chain-name">Description</div>
                </div>
              </mat-option>
              <mat-option class="riva-select-header">
                <div class="riva-select">
                  <div class="p5 riva-select__chain-type">Type</div>
                  <div class="p5 riva-select__chain-name">Name</div>
                  <div class="p5 riva-select__chain-sku">Sku</div>
                  <div class="p5 riva-select__chain-name">Description</div>
                </div>
              </mat-option>
              <mat-option
                *ngFor="let chain of filteredChains | async"
                [value]="chain"
                (click)="onSelectChain(chain)"
                style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
              >
                <div class="country-container riva-select">
                  <div class="p5 riva-select__chain-type">
                    {{ chain.chainType ? 'Finished' : 'Unfinished' }}
                  </div>
                  <div class="p5 riva-select__chain-name">
                    <p>{{ chain.name }}</p>
                  </div>
                  <div class="p5 riva-select__chain-sku">
                    <p>{{ chain.sku }}</p>
                  </div>
                  <div class="p5 riva-select__chain-name">
                    <p>{{ chain.description }}</p>
                  </div>
                </div>
              </mat-option>
              <mat-option *ngIf="!(filteredChains | async)?.length">
                <div class="text-center riva-select__not-found">Not Found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </fieldset>
      </div>
      <div>
        <mat-form-field
          appearance="fill"
          style="width: 100%"
          *ngIf="!!(selectedChain.chainType || orderDetail.chainType)"
        >
          <mat-label>Size</mat-label>
          <mat-select
            [(ngModel)]="orderDetail.productSizesID"
            (ngModelChange)="getChainMaterials()"
            [disabled]="
              isOrderOnline ||
              !(selectedChain.chainType || orderDetail.chainType)
            "
          >
            <mat-option
              *ngFor="let size of chainSizes"
              [value]="size.chainSizeId"
            >
              {{ size.size }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          style="width: 100%"
          *ngIf="!(selectedChain.chainType || orderDetail.chainType)"
        >
          <mat-label>Length</mat-label>
          <input
            matInput
            type="number"
            [disabled]="isOrderOnline"
            [(ngModel)]="orderDetail.chainRawLength"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Material Code</mat-label>
          <mat-select
            [(ngModel)]="orderDetail.materialCodeID"
            [disabled]="isOrderOnline"
            class="material-select"
          >
            <mat-select-trigger>
              <div
                *ngIf="selectedMaterial.printColor1"
                style="
                  width: 20px;
                  height: 10px;
                  border: 1px solid white;
                  background-color: rgb(0, 0, 0);
                  margin-right: 5px;
                "
                [ngStyle]="{
                  'background-color': '#' + selectedMaterial.printColor1
                }"
              ></div>
              {{ selectedMaterial.description }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let mats of chainStocks"
              [value]="mats.materialCodesID"
              class="material-options"
              style="display: flex; align-items: center"
            >
              <div
                style="
                  width: 25px;
                  height: 18px;
                  border: 1px solid white;
                  background-color: rgb(0, 0, 0);
                  margin-right: 5px;
                "
                [ngStyle]="{
                  'background-color': '#' + mats.material?.printColor1
                }"
              ></div>
              <span>{{ mats.material.description }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>CI-PO</mat-label>
          <input
            matInput
            [(ngModel)]="orderDetail.cipo"
            [disabled]="isOrderOnline"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>QTY ORDERED</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="orderDetail.qtyordered"
            [disabled]="isOrderOnline"
          />
        </mat-form-field>
      </div>
      <div></div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%" disabled>
          <mat-label>ENTRY DATE</mat-label>
          <input
            matInput
            [matDatepicker]="entryDate"
            [(ngModel)]="orderDetail.entryDate"
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="entryDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #entryDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>DUE DATE</mat-label>
          <input
            matInput
            [matDatepicker]="dueDate"
            [(ngModel)]="orderDetail.dueDate"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dueDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #dueDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="forms-row">
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>COMMENT</mat-label>
          <textarea
            matInput
            style="height: 60px"
            [(ngModel)]="orderDetail.comment"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="component-materials"></div>
      <div></div>
      <div>
        <mat-slide-toggle
          style="margin-top: 14px"
          [(ngModel)]="orderDetail.customized"
          >Custom</mat-slide-toggle
        >
      </div>
      <div>
        <button
          class="btn btn-outline-success"
          [disabled]="!(isEditable || isOrderOnline) || !isFormValid"
          style="margin-bottom: 10px"
          (click)="onSaveOrderDetail()"
        >
          <i class="fa fa-save"></i> Save
        </button>
        <button
          class="btn btn-outline-info"
          style="margin-bottom: 10px"
          (click)="onCancelEvent()"
          *ngIf="orderDetail.ordersDetailsId === 0"
        >
          Cancel
        </button>
        <ng-container *ngIf="orderDetail.ordersDetailsId > 0">
          <fieldset [disabled]="!(isEditable || isOrderOnline)">
            <button
              class="btn btn-outline-danger"
              (click)="onClear()"
              userPermission
              [elementKey]="orderDetailsFeatureKey.clearDetail"
            >
              <i class="fa fa-close"></i> Clear
            </button>
          </fieldset>
        </ng-container>
      </div>
    </div>
  </div>
</div>
