<div class="product-sku-generator">
  <h3 mat-dialog-title>Generate Findings SKU</h3>
  <mat-dialog-content class="mat-typography product-sku-generator__container">
    <div>
      <!-- <table
        mat-table
        matSort
        [dataSource]="data.findings"
        matSortActive="findingSizesID"
        matSortDirection="desc"
        style="
          margin-bottom: 16px;
          border: 1px solid rgba(255, 255, 255, 0.12);
          width: 100%;
        "
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ columns['name'] }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="size_1">
          <th mat-header-cell *matHeaderCellDef>{{ columns['size_1'] }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.size_1 }}<span *ngIf="element.size_1">mm</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="size_2">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by size_2"
          >
            {{ columns['size_2'] }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.size_2 }}<span *ngIf="element.size_2">mm</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="size_3">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by size_3"
          >
            {{ columns['size_3'] }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.size_3 }}<span *ngIf="element.size_3">mm</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="size_4">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by size_4"
          >
            {{ columns['size_4'] }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.size_4 }}<span *ngIf="element.size_4">mm</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="customerName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by customerName"
          >
            Customer
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.customerName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="jumpringSize">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by jumpringSize"
          >
            ID
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.jumpringSize }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="findings-row"
          (click)="onSelectFinding(row)"
          [ngClass]="{
            'is-selected': row.findingsId === findingSkuDetail?.findingsId
          }"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="6">No Available Data</td>
        </tr>
      </table> -->
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Material Code</mat-label>
        <mat-select [(ngModel)]="findingSkuDetail.materialCodeId">
          <mat-option
            *ngFor="let stock of stocks"
            [value]="stock.material.materialCodeId"
          >
            {{ stock.material.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="generated-sku-container">
      <div class="generated-sku">
        <div
          class="copy-icon"
          (click)="onCopySku()"
          nz-popover
          nzPopoverContent="Copied to Clipboard"
          nzPopoverPlacement="bottomRight"
          nzPopoverTrigger="click"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-copy"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"
            />
            <path
              d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
            />
          </svg>
        </div>
        <p style="line-height: 1.5">{{ getGeneratedSku() }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="display: flex; justify-content: center">
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px; width: 100px"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
