import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Login } from './../../models/login';
import { GenericService } from './../../services/generic.service';

@Injectable()
export class AuthenticationService extends GenericService {
  baseUrl = environment.apiUrl + 'auth';
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  constructor(public http: HttpClient, private router: Router) {
    super(http);
  }

  refreshToken(userId: number, token: string) {
    return this.http.post(
      `${this.baseUrl}/refresh-token`,
      {
        userId,
        refreshToken: token,
      },
      { headers: this.headers },
    );
  }

  login({ userName, password }: Login) {
    return this.http.post(
      `${this.baseUrl}/signin`,
      {
        userName,
        password,
      },
      { headers: this.headers },
    );
  }

  signOut(): void {
    window.localStorage.clear();
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  public get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
}
