import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import { forkJoin } from 'rxjs';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import {
  WorkOrderMovementHistory,
  WorkOrderTracking,
} from 'src/app/components/work-order-tracking/model';
import { WorkOrderPrintDialogComponent } from 'src/app/components/work-order-view/work-order-print/work-order-print-dialog.component';
import { WORK_ORDER_MOVEMENT_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/work-order-movement-permission';
import { ScanService } from 'src/app/services/scan.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { WorkOrderMovementReport } from '../work-order-moved-report';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'work-order-incomplete',
  templateUrl: './work-order-incomplete.component.html',
  styleUrls: ['./work-order-incomplete.component.scss'],
})
export class WorkOrderIncompleteComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  selectedItem: WorkOrderMovementReport = {} as WorkOrderMovementReport;
  displayedColumns = ['picturePath', 'productName', 'workOrdersId', 'action'];
  workOrderMovedItems = new MatTableDataSource<WorkOrderMovementReport>([]);
  workOrderMovementHistory: WorkOrderMovementHistory[] = [];
  workOrderTracking: WorkOrderTracking | null = null;
  poLabel = 'PO';
  poValue = '-';
  featureKey = WORK_ORDER_MOVEMENT_FEATURE_KEY

  constructor(
    public dialog: MatDialog,
    private workOrderMovementService: WorkOrderMovementService,
    private scanService: ScanService,
    private _confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.getMovedWorkOrders();
  }

  ngAfterViewInit(): void {
    this.workOrderMovedItems.sort = this.sort;
  }

  getMovedWorkOrders() {
    this.workOrderMovementService
      .getWorkOrderMovementReportIncomplete()
      .subscribe((data) => {
        this.workOrderMovedItems.data = data;
        this.selectedItem = {} as WorkOrderMovementReport;
        this.workOrderTracking = null;
        this.workOrderMovementHistory = [];
        this.poLabel = 'PO';
        this.poValue = '-';
      });
  }
  onSelectItem(item: WorkOrderMovementReport) {
    this.selectedItem = item;
    this.getWorkOrderDetails(item.workOrdersId);
  }
  getWorkOrderDetails(workOrderId) {
    forkJoin([
      this.scanService.scanWorkOrder(workOrderId),
      this.workOrderMovementService.getWorkOrderMovement(workOrderId),
    ]).subscribe(([data, history]) => {
      this.workOrderTracking = data;
      this.workOrderMovementHistory = orderBy(
        history,
        'workOrdersMovementID',
        'asc',
      );
      if (this.workOrderTracking) {
        const { poExternal, poInternal } = this.workOrderTracking.purchaseOrder;
        const hasExternalAndInternal = poExternal && poInternal;
        const isEqual = poExternal === poInternal;
        this.poLabel =
          hasExternalAndInternal && !isEqual ? 'PO External / Internal' : 'PO';
        this.poValue =
          hasExternalAndInternal && !isEqual
            ? `${poExternal} / ${poInternal}`
            : poExternal || poInternal;
      }
    });
  }
  onPrintWorkOrder() {
    if (this.workOrderTracking.workOrder.workOrdersId <= 0) return;
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: {
          printedDate: '',
          releasedDate: '',
          dateClosed: this.workOrderTracking?.workOrder?.closedDate ?? '',
          workOrdersId: this.workOrderTracking.workOrder.workOrdersId,
        },
      },
    });
  }

  onCancelWorkOrder(workOrdersMovementID: number) {
    this._confirmationService
    .showConfirmation({
      title: 'Cancel Open Work Order',
      content: 'Are you sure you want to cancel open work order?',
      confirmLabel: 'Yes, cancel it.',
    })
    .subscribe((isConfirmed) => {
      if (!isConfirmed) return;
      this.workOrderMovementService.deleteWorkOrderMovement(workOrdersMovementID).subscribe(() => {
        this.getMovedWorkOrders();
      })
    });
  }
}
