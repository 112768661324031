<div class="enamel-variation" *ngIf="productVariations.data?.length">
  <div class="enamel-variation__container">
    <div style="width: 155px">Enamel Variations</div>
    <div class="enamel-variation__color-container">
      <div
        *ngIf="!productVariations.data.length"
        style="
          border: 1px solid #646464;
          padding: 10px;
          width: 150px;
          text-align: center;
        "
      >
        No Variation
      </div>
      <div
        class="enamel-variation__color"
        *ngFor="let item of productVariations.data"
      >
        <div
          style="width: 30px; height: 30px; cursor: pointer"
          [ngStyle]="{ 'background-color': item.colorHex }"
          nz-tooltip
          nzTooltipPlacement="top"
          [nzTooltipTitle]="item.displayText"
        ></div>
      </div>
    </div>
  </div>
</div>
