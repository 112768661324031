import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import orderBy from 'lodash/orderBy';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { LoadService } from './../../..//custom/load-overlay/load-overlay.service';
import { GenericComponent } from './../../../components/generic/generic.component';
import { AlertService } from './../../../custom/_alert';

import { ProductService } from 'src/app/services/product.service';
import { OrderdetailsService } from './../orderdetails.service';

import { DateTime } from 'luxon';
import { ORDER_DETAILS_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/order-details-permission';
import { Customers } from 'src/app/models/customer';
import { Enamel } from 'src/app/models/enamel';
import { MaterialCode } from 'src/app/models/material-code';
import { OrderDetailsEnamel, OrdersDetails } from 'src/app/models/orders.model';
import { Product, ProductDetail, ProductEnamel } from 'src/app/models/product';
import { ProductSku } from 'src/app/models/sku';
import { CustomerService } from 'src/app/services/customer.service';
import { EnamelService } from 'src/app/services/enamel.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { SkuService } from 'src/app/services/sku.service';
import { ProductOrderChain } from '../../riva-chain/riva-chain-product/model';
import { ProductStone } from '../../riva-gems/riva-gems.model';

@Component({
  selector: 'app-orderdetails-item',
  templateUrl: './orderdetails-item.component.html',
  styleUrls: ['./orderdetails-item.component.scss'],
})
export class OrderdetailsItemComponent
  extends GenericComponent
  implements OnInit
{
  @Input() orderDetails: OrdersDetails = new OrdersDetails();
  @Input() customerId: number;
  @Input() isEditable: boolean = true;
  @Input() productList: Product[] = [];
  @Input() customersList: Customers[] = [];
  @Input() materialCodes: MaterialCode[] = [];
  @Input() isOrderOnline: boolean = false;

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    private orderDetailsService: OrderdetailsService,
    private productService: ProductService,
    private productBomService: ProductBomService,
    private enamelService: EnamelService,
    private customerService: CustomerService,
    private skuService: SkuService,
  ) {
    super(loadService, alertService);
  }

  products: Product[] = [];
  materialSizes: string[] = [];

  product: Product = new Product();
  materialCode: MaterialCode = new MaterialCode();
  materialSize: string = '';

  orderDetailsFeatureKey = ORDER_DETAILS_FEATURE_KEY;

  productControl = new FormControl();
  materialCodeControl = new FormControl();
  materialSizeControl = new FormControl();
  productVariations: ProductEnamel[] = [];
  productStoneVariationControl = new FormControl();
  productStoneVariations: ProductStone[] = [];
  productStoneVariation: ProductStone = {} as ProductStone;
  enamelDetail: OrderDetailsEnamel = {} as OrderDetailsEnamel;
  productDetail: ProductDetail = {
    materials: [],
    productSizes: [],
  } as ProductDetail;
  enamels: Enamel[] = [];
  noChain = {
    chainProductsID: -1,
    chainType: false,
    name: 'No Chain',
    size: '',
  };

  productChains: ProductOrderChain[] = [];
  productChain: ProductOrderChain = {} as ProductOrderChain;
  productChainControl = new FormControl();
  filteredProductChains: Observable<ProductOrderChain[]>;
  productSkus: ProductSku[] = [];
  selectedProductSku: ProductSku = {} as ProductSku;

  ngOnInit(): void {
    this.enamelService
      .getList()
      .pipe(
        map((enamels) => {
          this.enamels = enamels;

          const rivaId =
            this.customersList.find(
              (customer) => customer.customerId.toLowerCase() === 'riva',
            )?.custIdno ?? 0;

          const sortedProducts = orderBy(
            this.productList.map((p) => ({
              ...p,
              sortOrder: p.customerCode === rivaId ? 1 : 0,
              isRivaProduct: p.customerCode === rivaId,
            })),
            ['sortOrder'],
          );

          if (this.customerId === rivaId) {
            this.products = sortedProducts;
          } else {
            this.products = sortedProducts.filter(
              (p) =>
                p.customerCode == this.customerId || p.customerCode == rivaId,
            );
          }
        }),
      )
      .subscribe(() => {
        this.enamelDetail =
          this.orderDetails.orderDetailsEnamel ?? ({} as OrderDetailsEnamel);
        if (this.orderDetails.ordersDetailsId !== 0) {
          this.product = this.products.find(
            (x) => x.productsId == this.orderDetails.productsId,
          );
          this.getProductVariation(this.orderDetails.productsId);
          this.getProductDetail(this.orderDetails.productsId);
          this.getProductPictures(this.orderDetails.productsId);
          this.getProductStone(this.orderDetails.productsId);
          this.getProductChain(this.orderDetails.productsId);
          this.getProductSku(this.orderDetails.productsId);
        }
        this.initiateFilters();
      });
  }

  getProductVariation(productId) {
    this.productBomService.getProductEnamel(productId).subscribe((data) => {
      this.productVariations = data.reduce((variations, item) => {
        if (!item.variation) return variations;
        const enamel =
          this.enamels.find((e) => e.enamelId === item.enamelID) ?? {};
        return [...variations, { ...item, ...enamel }];
      }, []);
      if (data.length === 1) {
        this.enamelDetail.enamelID = data[0].enamelID;
      }
    });
  }

  getProductStone(productId) {
    this.productBomService.getProductStones(productId).subscribe((data) => {
      this.productStoneVariations = data.filter((stone) => stone.variation);
      this.productStoneVariation = this.productStoneVariations.find(
        (x) => x.stonesID === this.orderDetails.stone.stoneId,
      );
      this.filteredProductStoneVariation =
        this.productStoneVariationControl.valueChanges.pipe(
          startWith(this.productStoneVariation?.stone),
          map((value) => this._filterProductStoneVariation(value)),
        );
    });
  }

  getProductPictures(productId) {
    this.productService
      .getProductImageList(productId)
      .subscribe((data = []) => {
        this.product.picPath = data[0]?.fileName;
      });
  }

  displayFn(item: Product): string {
    return item && item.productName ? item.productName : '';
  }

  displayProductStone(item: ProductStone): string {
    const labels = [];
    if (item?.stone?.stoneType?.name) labels.push(item.stone.stoneType.name);
    if (item?.stone?.stoneColor?.colorName)
      labels.push(item.stone.stoneColor.colorName);
    if (item?.stone?.stoneSize?.displayText)
      labels.push(item.overrideStoneSize || item.stone.stoneSize.displayText);
    return labels.join(' | ');
  }

  displayMatCode(item: MaterialCode): string {
    return item && item.code ? item.code : '';
  }

  displayMatSize(item: string): string {
    return item ? item : '';
  }

  private _filterProduct(name: string): Product[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.products.filter(
        (option) =>
          option.productName.toLowerCase().includes(filterValue) ||
          option.productDesc.toLowerCase().includes(filterValue) ||
          option.sku.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['productName'];
      return this.products.filter(
        (option) =>
          option.productName
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          option.productDesc
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          option.sku.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.products;
  }

  private _filterProductStoneVariation(name: string): ProductStone[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.split('|')?.[0]?.trim()?.toLowerCase();
      return this.productStoneVariations.filter(
        (option) =>
          option.stone?.stoneType?.name?.toLowerCase().includes(filterValue) ||
          option.stone?.stoneColor?.colorName
            ?.toLowerCase()
            .includes(filterValue) ||
          option.stone?.stoneSize?.displayText
            ?.toLowerCase()
            .includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name?.['stoneType']?.['name'];
      return this.productStoneVariations.filter(
        (option) =>
          option.stone?.stoneType?.name
            ?.toLowerCase()
            .includes(filterValue?.toLowerCase()) ||
          option.stone?.stoneColor?.colorName
            ?.toLowerCase()
            .includes(filterValue?.toLowerCase()) ||
          option.stone?.stoneSize?.displayText
            ?.toLowerCase()
            .includes(filterValue?.toLowerCase()),
      );
    }
    return this.productStoneVariations;
  }

  private _filterMatCode(): MaterialCode[] {
    let pInfo = [];
    let result = this.materialCodes.filter((item) =>
      pInfo.includes(item.materialCodeId),
    );
    return result;
  }

  private _filterMatSize(): string[] {
    let pInfo = [];
    let result = this.materialSizes.filter((item) => pInfo.includes(item));
    return result;
  }

  disableMaterialCodes: boolean = true;
  disableMaterialSizes: boolean = true;

  filteredProducts: Observable<Product[]>;
  filteredProductStoneVariation: Observable<ProductStone[]>;

  filteredMaterialCodes: MaterialCode[] = [];
  filteredMaterialSizes: string[];

  initiateFilters() {
    this.filteredProducts = this.productControl.valueChanges.pipe(
      startWith(this.product.productName),
      map((value) => this._filterProduct(value)),
    );

    this.filteredMaterialSizes = this._filterMatSize();
    this.filteredMaterialCodes = this._filterMatCode();
  }

  save() {
    this.loadService.loadContent(true);
    const dueDate = this.orderDetails.dueDate
      ? DateTime.fromJSDate(new Date(this.orderDetails.dueDate)).toFormat(
          'yyyy-MM-dd',
        )
      : null;
    const entryDate = this.orderDetails.entryDate
      ? DateTime.fromJSDate(new Date(this.orderDetails.entryDate)).toFormat(
          'yyyy-MM-dd',
        )
      : null;

    this.orderDetailsService
      .addupdate({ ...this.orderDetails, dueDate, entryDate })
      .subscribe((result) => {
        const allRequest = result.reduce((requests, id) => {
          if (this.enamelDetail?.enamelID != null) {
            requests.push(
              this.orderDetailsService.setOrderDetailEnamel({
                ...this.enamelDetail,
                orderDetailsID: id,
              }),
            );
          }

          if (this.productStoneVariation?.productsBOMStonesID) {
            requests.push(
              this.orderDetailsService.setOrderDetailsStone({
                ordersDetailsID: id,
                productsBOMStonesID:
                  this.productStoneVariation.productsBOMStonesID,
              }),
            );
          }

          if (this.productChain?.chainProductsID) {
            if (this.productChain?.chainProductsID === -1) {
              requests.push(
                this.orderDetailsService.deleteOrderDetailsChain(id),
              );
            } else {
              requests.push(
                this.orderDetailsService.setOrderDetailsChain({
                  ordersDetailsChainID:
                    this.productChain.ordersDetailsChainID ?? 0,
                  ordersDetailsID: id,
                  chainType: this.productChain.chainType,
                  chainProductsID: this.productChain.chainProductsID,
                }),
              );
            }
          }

          if (
            !this.selectedProductSku.productsCustSKUsID &&
            this.selectedProductSku.customerSKU
          ) {
            const payload = {
              productsCustSKUsID: 0,
              productsID: this.product.productsId,
              productsMaterialsID: this.orderDetails.materialCodeID ?? 0,
              productsSizesID: this.orderDetails.productSizesID ?? 0,
              productsBOMEnamelID: this.enamelDetail?.enamelID ?? 0,
              productsBOMStonesID:
                this.productStoneVariation?.productsBOMStonesID ?? 0,
              productsBOMChainFinishedID: this.productChain?.chainType
                ? this.productChain?.chainProductsID
                : 0,
              productsBOMChainRawID: this.productChain?.chainType
                ? 0
                : this.productChain?.chainProductsID,
              customerSKU: this.selectedProductSku.customerSKU,
            };
            requests.push(this.skuService.setProductSku(payload));
          }

          return requests;
        }, []);

        if (allRequest.length === 0) {
          this.loadService.loadContent(false);
          this.loadService.reloadOrderDetails();
          return;
        }
        forkJoin(allRequest).subscribe(() => {
          this.loadService.loadContent(false);
          this.loadService.reloadOrderDetails();
        });
      });
  }
  onProductChange(product: Product) {
    this.orderDetails.productsId = product?.productsId ?? 0;
    this.getProductVariation(product.productsId);
    this.getProductStone(product.productsId);
    this.getProductDetail(product.productsId);
    this.getProductPictures(product.productsId);
    this.getProductChain(product.productsId);
    this.getProductSku(product.productsId);
  }

  getProductSku(productId) {
    this.productSkus = [];
    this.skuService.getProductSku(productId).subscribe((data) => {
      this.productSkus = data;
      this.onCheckProductSku();
    });
  }

  onCheckProductSku() {
    this.selectedProductSku =
      this.productSkus.find(
        (sku) =>
          sku.productsMaterialsID === (this.orderDetails.materialCodeID ?? 0) &&
          sku.productsSizesID === (this.orderDetails.productSizesID ?? 0) &&
          sku.productsBOMEnamelID === (this.enamelDetail?.enamelID ?? 0) &&
          sku.productsBOMStonesID ===
            (this.productStoneVariation?.productsBOMStonesID ?? 0) &&
          ((this.productChain?.chainType &&
            sku.productsBOMChainFinishedID ===
              ((this.productChain?.chainProductsID ?? 0) > 0
                ? this.productChain?.chainProductsID
                : 0)) ||
            (!this.productChain?.chainType &&
              sku.productsBOMChainRawID ===
                ((this.productChain?.chainProductsID ?? 0) > 0
                  ? this.productChain?.chainProductsID
                  : 0))),
      ) ?? ({} as ProductSku);
  }

  getProductDetail(productId: number) {
    this.productDetail = {
      materials: [],
      productSizes: [],
    } as ProductDetail;
    if (productId) {
      this.productService
        .getProductDetail(this.product.productsId)
        .subscribe((data) => {
          this.productDetail = data;
          this.orderDetails.productsMaterialsID =
            this.orderDetails.productsMaterialsID ??
            this.productDetail.materials.find(
              (m) => m.materialsCodeID === this.orderDetails.materialCodeID,
            )?.productsMaterialsID;
          if (data.materials.length === 1) {
            this.orderDetails.materialCodeID =
              data.materials[0].materialsCodeID;
          }
          if (data.productSizes.length === 1) {
            this.orderDetails.productSizesID =
              data.productSizes[0].productsSizesID;
          }
        });
    }
  }

  get isFormValid() {
    const isEnamelValid =
      !this.productVariations?.length || this.enamelDetail.enamelID != null;
    return (
      this.orderDetails.productsId &&
      this.orderDetails.qtyordered &&
      this.orderDetails.dueDate &&
      this.orderDetails.materialCodeID &&
      this.orderDetails.productSizesID &&
      isEnamelValid
    );
  }

  get selectedMaterial() {
    return (
      this.productDetail.materials.find(
        (m) => m.materialsCodeID === this.orderDetails.materialCodeID,
      ) ?? {
        material: {
          printColor1: '',
          description: '',
          code: '',
          multiMetalPrimaryMaterial: null,
          multiMetalSecondaryMaterial: null,
        },
      }
    );
  }

  onChangeMaterial() {
    this.orderDetails.materialCodeID = this.productDetail.materials.find(
      (m) => m.productsMaterialsID === this.orderDetails.productsMaterialsID,
    )?.material?.materialCodeId;
  }

  getProductChain(productId) {
    forkJoin([
      this.productBomService.getProductChainRaw(productId),
      this.productBomService.getProductChainFinished(productId),
    ]).subscribe(([raw, finished]) => {
      const rawMapped = raw.map((r) => ({
        chainProductsID: r.productsBOMChainRawID,
        chainType: false,
        name: r.chainRaw?.chainStyle?.styleName,
        size: r.lengthInches.toString(),
      }));
      const finishedMapped = finished.map((f) => ({
        chainProductsID: f.productsBOMChainFinishedID,
        chainType: true,
        name: f.name,
        size: f.chainFinishedSizes.size,
      }));
      this.productChains = [...finishedMapped, ...rawMapped];

      if (this.productChains.length > 0) {
        this.orderDetailsService
          .getOrderDetailsChain(this.orderDetails.ordersDetailsId)
          .subscribe((data) => {
            this.productChain = this.productChains.find(
              (p) => p.chainProductsID === data?.chainProductsID,
            );
            this.productChain.ordersDetailsChainID = data?.ordersDetailsChainID;
          });
      }

      this.filteredProductChains = this.productChainControl.valueChanges.pipe(
        startWith(this.productChain.name),
        map((value) => this._filterProductChain(value)),
      );
    });
  }
  private _filterProductChain(name: string): ProductOrderChain[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.productChains.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue) ||
          option.size?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['name'];
      return this.productChains.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue) ||
          option.size?.toLowerCase().includes(filterValue),
      );
    }
    return this.productChains;
  }
  displayProductChain(item: ProductOrderChain): string {
    const labels = [];
    if (item?.chainType)
      labels.push(item.chainType ? 'Finished' : 'Unfinished');
    if (item?.name) labels.push(item.name);
    if (item?.size) labels.push(item.size);
    return labels.join(' | ');
  }

  onClear() {
    this.product = new Product();
    this.orderDetails = {
      ...this.orderDetails,
      productsId: 0,
      materialCodeID: 0,
      productSizesID: 0,
      qtyordered: 1,
      comment: '',
      cipo: '',
      customized: false,
    };
    this.productDetail = {
      materials: [],
      productSizes: [],
    } as ProductDetail;
    this.productVariations = [];
    this.productStoneVariations = [];
    this.productChains = [];
    this.productChain = {} as ProductOrderChain;
    this.productStoneVariation = {} as ProductStone;
    this.enamelDetail = {} as OrderDetailsEnamel;
    this.orderDetailsService
      .clearOrderDetails(this.orderDetails.ordersDetailsId)
      .subscribe();
  }

  get disableCustomerSku() {
    const isFormFilled =
      this.orderDetails.materialCodeID > 0 &&
      this.orderDetails.productSizesID > 0 &&
      (this.productVariations.length === 0 || !!this.enamelDetail?.enamelID) &&
      (this.productStoneVariations.length === 0 ||
        !!this.productStoneVariation?.productsBOMStonesID) &&
      (this.productChains.length === 0 ||
        (this.productChain?.chainProductsID ?? 0) > 0);
    return (
      !this.product.productsId ||
      this.selectedProductSku.productsCustSKUsID ||
      !isFormFilled
    );
  }
}
