<div class="finish-chain-bom-dialog">
  <h3 mat-dialog-title>New Finish Chain BOM</h3>
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Findings</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="finding"
        [formControl]="findingControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option class="riva-select-header riva-select__stick-header">
          <div class="riva-select">
            <div class="p5 riva-select__style-name">Name</div>
            <div class="p5 riva-select__name">Size</div>
          </div>
        </mat-option>
        <mat-option class="riva-select-header">
          <div class="riva-select">
            <div class="p5 riva-select__style-name">Name</div>
            <div class="p5 riva-select__name">Size</div>
          </div>
        </mat-option>
        <mat-option
          *ngFor="let finding of filteredFindings | async"
          [value]="finding"
          style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
        >
          <div class="country-container riva-select">
            <div class="p5 riva-select__style-name">
              {{ finding.findingsTypeName }}
            </div>
            <div class="p5 riva-select__name">{{ finding.sizeLabel }}</div>
          </div>
        </mat-option>
        <mat-option *ngIf="!(filteredFindings | async)?.length">
          <div class="text-center riva-select__not-found">Not Found</div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Qty</mat-label>
      <input matInput type="number" min="1" [(ngModel)]="chainFinishBom.qty" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Comment</mat-label>
      <textarea
        matInput
        [(ngModel)]="chainFinishBom.comment"
        style="height: 80px; color: white"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        [disabled]="isSaving || !isFormValid"
        (click)="onSaveChainFinishBom()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
