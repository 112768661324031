<div class="product-image">
  <button
    class="riva-button make-primary"
    *ngIf="editMode && productImages.length"
    [disabled]="productImages.length <= 1"
    (click)="openReorderDialog()"
  >
    Reorder
  </button>
  <button
    class="riva-button image-delete"
    *ngIf="editMode && productImages.length"
    (click)="onDeleteProductImage()"
  >
    <i nz-icon nzType="delete" nzTheme="fill" style="margin-right: 5px"></i>
    Delete
  </button>

  <div class="empty-image__container" *ngIf="!productImages.length">
    <div class="empty-image">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-photo-off"
        width="70"
        height="70"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="3" y1="3" x2="21" y2="21" />
        <line x1="15" y1="8" x2="15.01" y2="8" />
        <path
          d="M19.121 19.122a3 3 0 0 1 -2.121 .878h-10a3 3 0 0 1 -3 -3v-10c0 -.833 .34 -1.587 .888 -2.131m3.112 -.869h9a3 3 0 0 1 3 3v9"
        />
        <path d="M4 15l4 -4c.928 -.893 2.072 -.893 3 0l5 5" />
        <path d="M16.32 12.34c.577 -.059 1.162 .162 1.68 .66l2 2" />
      </svg>
      <h3 style="color: white; margin-top: 12px">No Pictures Available</h3>
    </div>
  </div>
  <ngx-gallery
    *ngIf="productImages.length"
    [options]="galleryOptions"
    [images]="productImages"
    class="ngx-gallery"
    (change)="onImageChange($event)"
  ></ngx-gallery>

  <div class="loading-image" [ngClass]="{ 'loading-hidden': !isLoading }">
    <div class="loading-spinner">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
      <h2 style="color: white; margin-top: 120px">Loading Pictures...</h2>
    </div>
  </div>
</div>
<swal
  #errorProductImage
  [title]="error.title"
  [text]="error.description"
  [focusConfirm]="false"
  icon="error"
  confirmButtonText="Ok, Got it."
>
</swal>
