import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Enamel } from 'src/app/models/enamel';
import { ProductEnamel } from 'src/app/models/product';
import { EnamelService } from 'src/app/services/enamel.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';
import { SupplierService } from 'src/app/services/supplier.service';
import { StoneProductComponent } from '../../riva-gems/stone-product/stone-product.component';
import { ConfirmationService } from './../../riva-confirmation/riva-confirmation.service';

const PRODUCT_ENAMEL_INDEX = 0;
const PRODUCT_STONES_INDEX = 1;

@Component({
  templateUrl: './enamel-variation-dialog.component.html',
  styleUrls: ['./enamel-variation-dialog.component.scss'],
})
export class EnamelVariationDialogComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'select',
    'color',
    'sku',
    'name',
    'pantoneColor',
    'hardness',
  ];
  variationDisplayedColumns: string[] = [
    'color',
    'sku',
    'name',
    'pantoneColor',
    'hardness',
    'delete',
  ];
  search = '';
  isSaving = false;
  maxVariation = 30;
  isVariationLimit = false;
  enamelControl = new FormControl();
  filteredEnamels: Observable<Enamel[]>;
  selection = new SelectionModel<Enamel>(true, []);
  selectedEnamel: Enamel;
  enamels = new MatTableDataSource<Enamel>([]);
  rawEnamels: Enamel[];
  productVariations = new MatTableDataSource<ProductEnamel>([]);

  selectedIndex = PRODUCT_ENAMEL_INDEX;
  @ViewChild(StoneProductComponent)
  stoneProductComponent: StoneProductComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { productId: number },
    public dialogRef: MatDialogRef<EnamelVariationDialogComponent>,
    private enamelService: EnamelService,
    private supplierService: SupplierService,
    private productBomService: ProductBomService,
    private productService: ProductService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.enamels.filterPredicate = (data: Enamel, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.enamelName
          .toString()
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        data.enamelSku.toLowerCase().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  initiateFilters() {
    this.filteredEnamels = this.enamelControl.valueChanges.pipe(
      startWith(this.selectedEnamel?.enamelName),
      map((value) => this._filterEnamels(value)),
    );
  }

  private _filterEnamels(name: string): Enamel[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.enamels.data.filter(
        (option) =>
          option.enamelName.toLowerCase().includes(filterValue) ||
          option.enamelSku.toLowerCase().includes(filterValue) ||
          option.supplierDisplayName.toLowerCase().includes(filterValue),
      );
    }
    return this.enamels.data;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.enamels.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.enamels.data.forEach((row) => this.selection.select(row));
  }

  onFilterChange() {
    this.enamels.filter = JSON.stringify({ search: this.search });
  }

  onCheckVariation() {
    this.enamels.data = this.rawEnamels.filter((e) => {
      return !this.productVariations.data.some(
        (p) => p.enamelID === e.enamelId,
      );
    });
  }

  onAddSelectedItem() {
    this.productVariations.data = this.selection.selected.reduce(
      (accum, { enamelId, enamelSku, ...selected }) => [
        ...accum,
        {
          ...selected,
          enamelID: enamelId,
          enamelSKU: enamelSku,
          productsID: this.data.productId,
        },
      ],
      [...this.productVariations.data],
    );
    this.selection.clear();
    this.onCheckVariation();
  }

  displayFn(item: Enamel): string {
    return item?.enamelName ?? '';
  }

  onSelectEnamel(enamel: Enamel) {
    if (this.productVariations.data.length >= this.maxVariation) {
      this.isVariationLimit = true;
      return;
    }
    const { enamelId, enamelSku, ...selected } = enamel;
    this.productVariations.data = [
      ...this.productVariations.data,
      {
        ...selected,
        enamelID: enamelId,
        enamelSKU: enamelSku,
        productsID: this.data.productId,
      },
    ];
    this.onCheckVariation();
    this.selectedEnamel = null;
  }

  onDeleteVariation(variation: ProductEnamel) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Enamel Variation',
        content: 'Are you sure you want to delete this variation?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productVariations.data = this.productVariations.data.filter(
          (p) => p.enamelID !== variation.enamelID,
        );
        if (variation.productsBOMEnamelID) {
          this.productBomService
            .deleteProductEnamel(variation.productsBOMEnamelID)
            .subscribe(() => {
              this.productService.reloadEnamelVariation();
            });
        }
        this.onCheckVariation();
        this.initiateFilters();
        this.isVariationLimit = false;
      });
  }

  onSave() {
    switch (this.selectedIndex) {
      case PRODUCT_ENAMEL_INDEX:
        this.onSaveEnamel();
        break;
      case PRODUCT_STONES_INDEX:
        this.stoneProductComponent.onSave();
        break;
      default:
        this.onCloseDialog();
    }
  }

  onSaveEnamel() {
    this.isSaving = true;
    const newProductVariations = this.productVariations.data.reduce(
      (variations, p) => {
        if (p.productsBOMEnamelID) return variations;
        return [
          ...variations,
          {
            enamelID: p.enamelID,
            productsID: this.data.productId,
            variation: true,
          },
        ];
      },
      [],
    );
    if (!newProductVariations.length) {
      this.isSaving = false;
      this.dialogRef.close(false);
      return;
    }
    this.productBomService
      .setProductEnamel(newProductVariations)
      .subscribe(() => {
        this.isSaving = false;
        this.dialogRef.close(true);
      });
  }

  ngAfterViewInit(): void {
    forkJoin([
      this.enamelService.getList(),
      this.supplierService.getList(),
      this.productBomService.getProductEnamel(this.data.productId),
    ])
      .pipe(
        map(([enamelList, supplierList, productVariationList]) => {
          const enamels = enamelList.map((enamel) => {
            const currentSupplier = supplierList.find(
              (s) => s.suppliersId === enamel.supplierId,
            );
            enamel.supplierDisplayName = currentSupplier
              ? `(${currentSupplier.supplierId}) ${currentSupplier.companyName}`
              : '';

            return enamel;
          });

          const productVariations = productVariationList
            .filter((v) => v.variation)
            .map((p) => {
              const enamel =
                enamels.find((e) => e.enamelId === p.enamelID) ?? {};
              return { ...p, ...enamel };
            });

          return { enamels, productVariations };
        }),
      )
      .subscribe(({ enamels, productVariations }) => {
        this.rawEnamels = enamels;
        this.productVariations.data = productVariations;
        this.onCheckVariation();
        this.initiateFilters();
      });
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }
}
