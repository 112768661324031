import { DateTime } from "luxon";

export const thisYearDateFilterPresets = () => {
  const currentDate =  DateTime.local();

  const thisYearStartQ1 = currentDate.startOf("year");
  const thisYearEndQ1 = thisYearStartQ1.plus({ months: 2 }).endOf('month');

  const thisYearStartQ2 = thisYearEndQ1.plus({ days: 1 });
  const thisYearEndQ2 = thisYearStartQ2.plus({ months: 2 }).endOf('month');

  const thisYearStartQ3 = thisYearEndQ2.plus({ days: 1 });
  const thisYearEndQ3 = thisYearStartQ3.plus({ months: 2 }).endOf('month');

  const thisYearStartQ4 = thisYearEndQ3.plus({ days: 1 });
  const thisYearEndQ4 = thisYearStartQ4.plus({ months: 2 }).endOf('month');
 
 
  return [
    {
      label: 'YTD',
      config: {
        from: `${currentDate.year}-01-01`,
        to: currentDate.toFormat('yyyy-MM-dd'),
      },
    },
    {
      label: 'This Year - First Quarter',
      config: {
        from: thisYearStartQ1,
        to: thisYearEndQ1,
      },
    },
    {
      label: 'This Year - Second Quarter',
      config: {
        from: thisYearStartQ2,
        to: thisYearEndQ2,
      },
    },
    {
      label: 'This Year - Third Quarter',
      config: {
        from: thisYearStartQ3,
        to: thisYearEndQ3,
      },
    },
    {
      label: 'This Year - Fourth Quarter',
      config: {
        from: thisYearStartQ4,
        to: thisYearEndQ4,
      },
    },
  ]
}

export const lastYearDateFilterPresets = () => {
  const currentDate =  DateTime.local();
 
  const lastYear = currentDate.minus({ years: 1 });
  const lastYearStartQ1 = lastYear.startOf("year");
  const lastYearEndQ1 = lastYearStartQ1.plus({ months: 2 }).endOf('month');

  const lastYearStartQ2 = lastYearEndQ1.plus({ days: 1 });
  const lastYearEndQ2 = lastYearStartQ2.plus({ months: 2 }).endOf('month');

  const lastYearStartQ3 = lastYearEndQ2.plus({ days: 1 });
  const lastYearEndQ3 = lastYearStartQ3.plus({ months: 2 }).endOf('month');

  const lastYearStartQ4 = lastYearEndQ3.plus({ days: 1 });
  const lastYearEndQ4 = lastYearStartQ4.plus({ months: 2 }).endOf('month');

  return [
    {
      label: 'Last Year',
      config: {
        from: currentDate.minus({ years: 1 }).startOf('year').toFormat('yyyy-MM-dd'),
        to: currentDate.minus({ years: 1 }).endOf('year').toFormat('yyyy-MM-dd'),
      },
    },
    {
      label: 'Last Year - First Quarter',
      config: {
        from: lastYearStartQ1,
        to: lastYearEndQ1,
      },
    },
    {
      label: 'Last Year - Second Quarter',
      config: {
        from: lastYearStartQ2,
        to: lastYearEndQ2,
      },
    },
    {
      label: 'Last Year - Third Quarter',
      config: {
        from: lastYearStartQ3,
        to: lastYearEndQ3,
      },
    },
    {
      label: 'Last Year - Fourth Quarter',
      config: {
        from: lastYearStartQ4,
        to: lastYearEndQ4,
      },
    },
  ]
}


export const monthDateFilterPresets = () => {
  const currentDate =  DateTime.local();

  return [
    {
      label: 'This Month',
      config: {
        from: currentDate.startOf('month').toFormat('yyyy-MM-dd'),
        to: currentDate.endOf('month').toFormat('yyyy-MM-dd'),
      },
    },
    {
      label: 'Last Month',
      config: {
        from: currentDate.minus({ months: 1 }).startOf('month').toFormat('yyyy-MM-dd'),
        to: currentDate.minus({ months: 1 }).endOf('month').toFormat('yyyy-MM-dd'),
      },
    },
    {
      label: '1 Month',
      config: {
        from: currentDate.minus({ months: 1 }).toFormat('yyyy-MM-dd'),
        to: currentDate.toFormat('yyyy-MM-dd'),
      },
    },
    {
      label: '3 Months',
      config: {
        from: currentDate.minus({ months: 3 }).toFormat('yyyy-MM-dd'),
        to: currentDate.toFormat('yyyy-MM-dd'),
      },
    },
    {
      label: '6 Months',
      config: {
        from: currentDate.minus({ months: 6 }).toFormat('yyyy-MM-dd'),
        to: currentDate.toFormat('yyyy-MM-dd'),
      },
    },
    {
      label: '12 Months',
      config: {
        from: currentDate.minus({ months: 12 }).toFormat('yyyy-MM-dd'),
        to: currentDate.toFormat('yyyy-MM-dd'),
      },
    },
  ]
}