import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { AnalyticsClosedItemWeight, AnalyticsService } from 'src/app/services/analytics.service';
import { WorkOrderPrintDialogComponent } from '../../work-order-view/work-order-print/work-order-print-dialog.component';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'item-weights',
  templateUrl: './item-weights.component.html',
  styleUrls: ['./item-weights.component.scss'],
})
export class ItemWeightsComponent implements OnInit {
  // @ViewChild(MatSort) sort: MatSort;
  @ViewChild('itemsSort', { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild('workOrderSort', { read: MatSort, static: true }) sort2: MatSort;

  filters: Filters = {
    dateRangeFrom: DateTime.local().minus({ months: 6 }).toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.local().toFormat('yyyy-MM-dd'),
  };
  items: AnalyticsClosedItemWeight[] = [];
  filteredItems: AnalyticsClosedItemWeight[] = [];
  groupedItems = new MatTableDataSource<Partial<AnalyticsClosedItemWeight>>([]);
  selectedItemWorkOrders = new MatTableDataSource<AnalyticsClosedItemWeight>([]);
  selectedItem: Partial<AnalyticsClosedItemWeight> = {};
  displayedColumns = ['itemName'];
  displayedWorkOrderColumns = ['workOrdersId', 'metalDescription', 'size', 'closedDate', 'closedQty', 'totalClosedWeight', 'individualClosedWeight'];

  searchText = '';

  constructor(
    public dialog: MatDialog,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.analyticsService.getAnalyticsClosedItemWeights().subscribe((data) => {
      this.items = data;
      this.onFilterData();
    });
  }

  onGroupData() {
    const groupedItems = groupBy(this.filteredItems, 'itemId');
    this.groupedItems.data = Object.keys(groupedItems).map((key) => {
      const [firstItem] = groupedItems[key];
      return {
        itemId: parseInt(key),
        itemName: firstItem.itemName,
        picturePath: firstItem.picturePath
      }
    });
    const [firstItem] = orderBy(this.groupedItems.data, 'itemName')

    if (firstItem != null) {
      this.onRowClick(firstItem)
    }
  }

  onFilterData() {
    const startDate = DateTime.fromISO(this.filters.dateRangeFrom);
    const endDate = DateTime.fromISO(this.filters.dateRangeTo).plus({ day: 1 });
    this.filteredItems = this.items.filter((i) => {
      const closedDate = DateTime.fromISO(i.closedDate);
      const inRange = closedDate >= startDate && closedDate <= endDate;
      return inRange && (this.searchText.trim() === '' || i.itemName?.toLocaleLowerCase().includes(this.searchText.trim().toLocaleLowerCase()));
    });
    this.onGroupData();
  }

  ngAfterViewInit() {
    this.groupedItems.sort = this.sort1;
    this.selectedItemWorkOrders.sort = this.sort2;
  }

  onDateRangeChange({ start, end }) {
    this.filters.dateRangeFrom = start;
    this.filters.dateRangeTo = end;
    this.onFilterData();
  }
  onRowClick(row: Partial<AnalyticsClosedItemWeight>) {
    this.selectedItem = row;
    this.getSelectedItemWorkOrderDetails();
  }
  getSelectedItemWorkOrderDetails() {
    this.selectedItemWorkOrders.data = this.filteredItems.filter((i) => i.itemId === this.selectedItem.itemId);
  }
  onPrintWorkOrder(workOrdersId) {
    if (workOrdersId <= 0) return;
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: {
          printedDate: '',
          releasedDate: '',
          dateClosed: '',
          workOrdersId: workOrdersId,
        },
      },
    });
  }
}
