<div class="reporting-presets">
  <button
    [matMenuTriggerFor]="presetMenu"
    class="btn riva-button"
    style="
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    Month Presets
  </button>
  <button
    [matMenuTriggerFor]="yearPresetMenu"
    class="btn riva-button"
    style="
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    This Year Presets
  </button>
  <button
    [matMenuTriggerFor]="lastYearPresetMenu"
    class="btn riva-button"
    style="
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    Last Year Presets
  </button>
</div>

<mat-menu #presetMenu="matMenu">
  <button
    mat-menu-item
    class="presets__menu-item"
    *ngFor="let preset of monthFilterPresets"
    (click)="onSetDateRange(preset.config)"
    style="width: 200px"
  >
    {{ preset.label }}
  </button>
</mat-menu>

<mat-menu #yearPresetMenu="matMenu">
  <button
    mat-menu-item
    class="presets__menu-item"
    *ngFor="let preset of yearFilterPresets"
    (click)="onSetDateRange(preset.config)"
    style="width: 200px"
  >
    {{ preset.label }}
  </button>
</mat-menu>

<mat-menu #lastYearPresetMenu="matMenu">
  <button
    mat-menu-item
    class="presets__menu-item"
    *ngFor="let preset of lastYearFilterPresets"
    (click)="onSetDateRange(preset.config)"
    style="width: 200px"
  >
    {{ preset.label }}
  </button>
</mat-menu>
