<div class="findings-details">
  <div class="findings-details__header">
    <div class="findings-details__image-container">
      <img
        style="max-width: 100%; max-height: 200px"
        [src]="sanitize(findings.picPath)"
      />
      <div *ngIf="editMode">
        <button
          class="btn btn-primary btn-sm"
          (click)="uploadImage.click()"
          [disabled]="isSaving"
        >
          <span>Upload</span>
        </button>
        <input
          type="file"
          #uploadImage
          (change)="onUploadChange(uploadImage.files)"
          style="display: none"
          accept="image/*"
          multiple
        />
      </div>
    </div>
    <button
      *ngIf="findings.findingsId"
      class="btn btn-primary btn-sm edit-findings"
      [ngClass]="{ hidden: editMode }"
      (click)="setEditMode()"
    >
      <span>Edit Finding</span>
    </button>
    <div class="edit-findings" [ngClass]="{ hidden: !editMode }">
      <button
        class="btn btn-success btn-sm"
        (click)="onSaveFindings()"
        style="margin-bottom: 10px"
        [disabled]="isSaving || !isFormValid"
      >
        <span>Save Finding</span>
      </button>
      <button
        class="btn btn-danger btn-sm"
        (click)="onCancel()"
        [disabled]="isSaving"
      >
        <span>Cancel</span>
      </button>
    </div>
  </div>

  <div class="findings-details__column-3">
    <mat-form-field appearance="fill">
      <mat-label>Item Name</mat-label>
      <input matInput [(ngModel)]="findings.name" [disabled]="!editMode" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>SKU</mat-label>
      <input matInput [(ngModel)]="findings.sku" [disabled]="!editMode" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Unit of Measure</mat-label>
      <mat-select
        [(ngModel)]="findings.unitsOfMeasure.unitsOfMeasureId"
        [disabled]="!editMode"
      >
        <mat-option
          *ngFor="let unit of uomOptions$ | async"
          [value]="unit.unitsOfMeasureId"
        >
          {{ unit.uom }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="findings-details__column-1">
    <mat-form-field appearance="fill">
      <mat-label>Comment</mat-label>
      <textarea
        matInput
        [(ngModel)]="findings.commentBox"
        [disabled]="!editMode"
      ></textarea>
    </mat-form-field>
  </div>
  <!-- <div class="findings-details__column-2">
    <mat-form-field appearance="fill">
      <mat-label>Source</mat-label>
      <mat-select
        [(ngModel)]="findings.source"
        [disabled]="!editMode"
        (ngModelChange)="onChangeSource()"
      >
        <mat-option [value]="false">In house</mat-option>
        <mat-option [value]="true">External Supplier</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Supplier</mat-label>
      <mat-select
        [(ngModel)]="findings.supplier.suppliersId"
        [disabled]="!editMode || !findings.source"
      >
        <mat-option
          *ngFor="let supplier of suppliers"
          [value]="supplier.suppliersId"
        >
          {{ supplier.companyName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->
  <div class="findings-details__column-2">
    <div>
      <label>Materials</label>
      <div class="mat-table">
        <div class="mat-row" *ngFor="let material of findings.materials">
          <span>{{ material.description }}</span>
          <i
            nz-icon
            nzType="delete"
            nzTheme="fill"
            *ngIf="editMode && hasDeleteAccess"
            (click)="onDeleteMaterial(material)"
          ></i>
        </div>
      </div>
      <div style="margin-top: 15px" *ngIf="editMode && findings.findingsId">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Material Code</mat-label>
          <mat-select [(ngModel)]="findingMaterialCode">
            <mat-option
              *ngFor="let mats of filteredMaterialCodes"
              [value]="mats.materialCodeId"
            >
              {{ mats.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="riva-button riva-button-sm"
          style="width: 100%"
          [disabled]="!findingMaterialCode"
          (click)="onSaveFindingsMaterial()"
        >
          Add Material
        </button>
      </div>
    </div>
    <div>
      <label>Sizes</label>
      <div class="size-table">
        <div class="mat-row" *ngFor="let size of findings.sizes">
          <span>{{ size.size }}</span>
          <i
            nz-icon
            nzType="delete"
            nzTheme="fill"
            *ngIf="editMode && hasDeleteAccess"
            (click)="onDeleteSize(size)"
          ></i>
        </div>
      </div>
      <div style="margin-top: 15px" *ngIf="editMode && findings.findingsId">
        <div style="position: relative">
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Size</mat-label>
            <input matInput [(ngModel)]="findingsSize" />
          </mat-form-field>
          <mat-error *ngIf="showFindingsSizeError" class="error-message"
            >Size already exist.</mat-error
          >
        </div>
        <button
          class="riva-button riva-button-sm"
          style="width: 100%"
          [disabled]="!findingsSize"
          (click)="onSaveFindingsSize()"
        >
          Add Size
        </button>
      </div>
    </div>
  </div>
</div>
