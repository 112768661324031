<div class="product-chain-unfinished-table" style="border: 1px solid #646464">
  <table mat-table [dataSource]="productChainUnfinished" style="width: 100%">
    <ng-container matColumnDef="picPath">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let element"
        style="width: 80px; padding: 0 !important"
      >
        <div
          style="
            width: 70px;
            padding: 5px;
            display: flex;
            justify-content: center;
          "
          *ngIf="element.chainRaw?.picPath"
        >
          <img
            style="max-height: 37px; max-width: 60px"
            [src]="element.chainRaw?.picPath"
          />
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef>SKU</th>
      <td mat-cell *matCellDef="let element">
        {{ element.chainRaw?.sku }}
      </td>
    </ng-container>
    <ng-container matColumnDef="styleName">
      <th mat-header-cell *matHeaderCellDef>Style Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.chainRaw?.chainStyle?.styleName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="lengthInches">
      <th mat-header-cell *matHeaderCellDef>Length</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lengthInches }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="4">No Available Data</td>
    </tr>
  </table>
</div>
