<h3 mat-dialog-title>Select Work Order Items</h3>
<mat-dialog-content class="mat-typography">
  <div class="invoicing-detail-dialog">
    <div style="border: 1px solid #646464">
      <table mat-table [dataSource]="receivedItems">
        <ng-container matColumnDef="selection">
          <th mat-header-cell *matHeaderCellDef>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <mat-checkbox
                class="example-margin"
                [checked]="isSelectedAll"
                [indeterminate]="isSomeSelected"
                (change)="selectAll($event.checked)"
              >
              </mat-checkbox>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <mat-checkbox
                [(ngModel)]="element.selected"
                (ngModelChange)="onSelect()"
              ></mat-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef>Index</th>
          <td mat-cell *matCellDef="let element">{{ element.index }}</td>
        </ng-container>
        <ng-container matColumnDef="cipo">
          <th mat-header-cell *matHeaderCellDef>CIPO</th>
          <td mat-cell *matCellDef="let element">{{ element.cipo }}</td>
        </ng-container>
        <ng-container matColumnDef="picturePath">
          <th mat-header-cell *matHeaderCellDef>Picture</th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                width: 55px;
                padding: 5px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              "
              *ngIf="element.picturePath"
            >
              <img
                style="max-width: 100%; max-height: 100%"
                [src]="element.picturePath"
              />
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef>SKU</th>
          <td mat-cell *matCellDef="let element">{{ element.sku }}</td>
        </ng-container>
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef>Size</th>
          <td mat-cell *matCellDef="let element">{{ element.size }}</td>
        </ng-container>
        <ng-container matColumnDef="materialCode">
          <th mat-header-cell *matHeaderCellDef>Metal</th>
          <td mat-cell *matCellDef="let element">
            {{ element.materialCode }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dueDate">
          <th mat-header-cell *matHeaderCellDef>Due Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.duaDate | date : 'MM/dd/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="qty">
          <th mat-header-cell *matHeaderCellDef>Qty</th>
          <td mat-cell *matCellDef="let element">
            <div class="item-qty">
              <nz-input-number
                [(ngModel)]="element.qty"
                [nzMin]="1"
                [nzMax]="element.maxQty"
                [nzStep]="1"
                style="width: 100%; padding: 8px 0"
                nzSize="large"
              ></nz-input-number>
              <span class="max-item">{{ element.maxQty }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Weight</th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            style="padding: 8px 0; padding-right: 8px"
          >
            <nz-input-number
              [nzId]="element.weightInputId"
              [nzMin]="0.001"
              [nzMax]="99999.999"
              nzSize="large"
              [(ngModel)]="element.weight"
              (ngModelChange)="checkWeightEntered(element)"
              style="width: 100%; font-size: 22px; padding: 8px 0"
              [nzAutoFocus]="focusItemIndex === i"
              (keyup.enter)="onWeightKeyDown(i)"
            ></nz-input-number>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="8">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      width: 400px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      [disabled]="
        !(isSelectedAll || isSomeSelected) || isReceiving || !isValidSelection
      "
      (click)="onReceived()"
      autofocus
    >
      Receive
    </button>
  </div>
</mat-dialog-actions>
