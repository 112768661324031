import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash/orderBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FindingRouting } from '../components/riva-findings/finding-routing/model';
import {
  Finding,
  FindingsStock,
  FindingsType,
  FindingsTypeSize,
} from './../components/riva-findings/model';
import {
  Findings,
  FindingsMaterial,
  FindingsSize,
  RivaFindings,
  RivaFindingsDto,
} from './../models/findings.model';
import { GenericService } from './generic.service';
import { sizeMapper } from './product-bom.service';

const jumpringSizeMapper = (sizes: FindingsTypeSize) => {
  const { size_2, size_3 } = sizes;
  const jumpringSizes = [];
  if (size_2) jumpringSizes.push(size_2);
  if (size_3) jumpringSizes.push(size_3);

  return orderBy(jumpringSizes, [], ['desc'])
    .map((w) => `${w}mm`)
    .join(' x ');
};

@Injectable({
  providedIn: 'root',
})
export class FindingsService extends GenericService {
  baseUrl = environment.apiUrl + 'findings';
  routingUrl = environment.apiUrl + 'findings-routing';

  public reloadFindingsList = (findingsId?: number) => {};

  constructor(http: HttpClient) {
    super(http);
  }

  getList(): Observable<any> {
    return this.http.get(this.baseUrl + '/list', { headers: this.headers });
  }

  addupdate(findings: Findings): Observable<any> {
    return this.http.post(this.baseUrl + '/addupdate', findings, {
      headers: this.headers,
    });
  }

  delete(findingsID: number): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + findingsID, {
      headers: this.headers,
    });
  }

  uploadImage(object: FormData): Observable<any> {
    return this.http.post(this.baseUrl + '/upload', object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
      responseType: 'text' as 'json',
    });
  }

  lastupdate(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/lastupdate/' + id.toString(), {
      headers: this.headers,
    });
  }

  getFindings(): Observable<RivaFindings[]> {
    return this.http.get<RivaFindings[]>(this.baseUrl, {
      headers: this.headers,
    });
  }

  setFindings(findings: RivaFindingsDto) {
    return this.http.post(this.baseUrl, findings, {
      headers: this.headers,
    });
  }

  setFindingsMaterials(findingsMaterial) {
    return this.http.post(`${this.baseUrl}/materials`, findingsMaterial, {
      headers: this.headers,
    });
  }
  deleteFindingsMaterials(findingsMaterialId) {
    return this.http.delete(`${this.baseUrl}/materials/${findingsMaterialId}`, {
      headers: this.headers,
    });
  }
  getFindingList() {
    return this.http
      .get<Finding[]>(this.baseUrl, {
        headers: this.headers,
      })
      .pipe(
        map((findings) =>
          findings.map((finding) => ({
            ...finding,
            ...finding.sizes,
            jumpringSize: jumpringSizeMapper(finding.sizes),
            sizeLabel: sizeMapper(finding.sizes),
            customerId: finding.customer?.id,
            customerName: finding.customer.companyName ?? 'RIVA Precision MFG',
          })),
        ),
      );
  }
  getFindingsSizes() {
    return this.http.get<FindingsTypeSize[]>(`${this.baseUrl}/sizes`, {
      headers: this.headers,
    });
  }
  setFindingsSizes(findingsSize) {
    return this.http.post(`${this.baseUrl}/sizes`, findingsSize, {
      headers: this.headers,
    });
  }
  setFinding(finding) {
    return this.http.post(this.baseUrl, finding, {
      headers: this.headers,
    });
  }
  getFindingsStocks() {
    return this.http.get<FindingsStock[]>(`${this.baseUrl}/stock`, {
      headers: this.headers,
    });
  }
  getFindingsStockBySizeId(findingsSizeId) {
    return this.http.get<FindingsStock[]>(
      `${this.baseUrl}/stock/${findingsSizeId}`,
      {
        headers: this.headers,
      },
    );
  }
  setFindingsStock(stock) {
    return this.http.post(`${this.baseUrl}/stock`, stock, {
      headers: this.headers,
    });
  }
  deleteFindingsSizes(findingsSizesId) {
    return this.http.delete(`${this.baseUrl}/sizes/${findingsSizesId}`, {
      headers: this.headers,
    });
  }
  orderFindingsMaterialAndSize(findingsList: RivaFindings) {
    const sizes = findingsList.sizes.reduce(
      (accum, size) => {
        const isNumber = !Number.isNaN(Number(size.size));
        if (isNumber) {
          const value = Number(size.size);
          accum.numeric = [...accum.numeric, { ...size, size: value }];
        } else {
          accum.alpha = [...accum.alpha, size];
        }
        return accum;
      },
      {
        alpha: [],
        numeric: [],
      },
    );
    const sortedNumber = orderBy(
      sizes.numeric,
      (s: FindingsSize) => s.size,
      'desc',
    );
    const convertedSizeToString = sortedNumber.map((s) => ({
      ...s,
      size: s.size?.toString(),
    }));
    findingsList.materials = orderBy(
      findingsList.materials,
      (m: FindingsMaterial) => m.description,
      'desc',
    );
    findingsList.sizes = [
      ...convertedSizeToString,
      ...orderBy(sizes.alpha, (s: FindingsSize) => s.size, 'desc'),
    ];
    return findingsList;
  }

  setFindingsRouting(data) {
    return this.http.post(this.routingUrl, data, {
      headers: this.headers,
    });
  }
  getFindingsRoutingByFindingsId(id) {
    return this.http.get<FindingRouting[]>(`${this.routingUrl}/${id}`, {
      headers: this.headers,
    });
  }
  deleteFindingsRouting(id) {
    return this.http.delete(`${this.routingUrl}/${id}`, {
      headers: this.headers,
    });
  }
  getFindingsTypes() {
    return this.http
      .get<FindingsType[]>(`${this.baseUrl}/types`, {
        headers: this.headers,
      })
      .pipe(
        map((data) => {
          return data.reduce<Record<number, FindingsType>>(
            (findingsType, type) => {
              return {
                ...findingsType,
                [type.findingsTypeId]: type,
              };
            },
            {},
          );
        }),
      );
  }
  setFindingsType(type: FindingsType) {
    return this.http.post(`${this.baseUrl}/types`, type, {
      headers: this.headers,
    });
  }
}
