import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Confirmation } from './confirmation';
import { RivaConfirmationComponent } from './riva-confirmation.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(public dialog: MatDialog) {}

  showConfirmation(config: Confirmation) {
    const dialogRef = this.dialog.open(RivaConfirmationComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      data: { ...config },
    });
    return dialogRef.afterClosed();
  }
}
