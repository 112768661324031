export class Customers {
  custIdno: number;
  customerId: string = '';
  companyName: string = '';
  contactName: string = '';
  emailAddress: string = '';
  paymentTerms: string = '';
  leadtimeDays: number;
  addresses: CustomerAddress[];
  picPath: string = '';
  shippingMethod: string = '';
  hwDisclaimFlag?: boolean = false;
  dontAllowMixedInvoices?: boolean = false;
  defaultBillAddress: number;
  defaultDevShipAddress: number;
  defaultProdShipAddress: number;
  shippingAccountNumber: string;
  preferredShipping: string;
  billingAddresses: CustomerAddress[];
  developmentAddresses: CustomerAddress[];
  productionAddresses: CustomerAddress[];
}

export interface CustomerAddress {
  customersAddressesId: number;
  custIdno: number;
  addressType: number;
  addressTypeLabel?: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  phone: string;
  fullAddress?: string;
  tempCustomersAddressesId?: number;
  isDefault?: boolean;
}
