import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { forkJoin } from 'rxjs';
import { WORK_ORDER_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/work-order-permission';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ClosingDetail, WorkOrderUI } from 'src/app/models/work-order';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { ConfirmationService } from '../../riva-confirmation/riva-confirmation.service';

@Component({
  selector: 'work-order-print-dialog',
  templateUrl: './work-order-print-dialog.component.html',
  styleUrls: ['./work-order-print-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkOrderPrintDialogComponent implements OnInit {
  private pdf: PDFDocumentProxy;

  pdfUrl: string;
  isLoading = false;
  isPrintedReport = false;
  closingDetails: ClosingDetail[] = [];
  rawClosingDetails: ClosingDetail[] = [];
  workOrderFeatureKey = WORK_ORDER_FEATURE_KEY;

  selectedWorkOrder: {
    dueDate: string;
    printedDate: string;
    releasedDate: string;
    receivedDate: string;
    workOrderId?: number;
  };
  isEditWeight = false;
  isSavingWeight = false;

  constructor(
    private workOrderService: WorkOrderService,
    private loadService: LoadService,
    private _confirmationService: ConfirmationService,
    public dialogRef: MatDialogRef<WorkOrderPrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { workOrder: WorkOrderUI },
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const { workOrder } = this.props;
    this.selectedWorkOrder = {
      dueDate: workOrder.dueDate
        ? DateTime.fromJSDate(new Date(workOrder.dueDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      printedDate: workOrder.printedDate
        ? DateTime.fromJSDate(new Date(workOrder.printedDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      releasedDate: workOrder.releasedDate
        ? DateTime.fromJSDate(new Date(workOrder.releasedDate)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      receivedDate: workOrder.dateClosed
        ? DateTime.fromJSDate(new Date(workOrder.dateClosed)).toFormat(
            'MM/dd/yyyy',
          )
        : ' ',
      workOrderId:
        this.props.workOrder.workOrdersId ?? this.props.workOrder.workOrderId,
    };
    this.isPrintedReport = !!workOrder.printedDate;
    this.workOrderService
      .getWorkOrderDates(this.selectedWorkOrder.workOrderId)
      .subscribe((workOrderDates) => {
        this.selectedWorkOrder.receivedDate =
          workOrderDates.closedDate != null
            ? DateTime.fromJSDate(new Date(workOrderDates.closedDate)).toFormat(
                'MM/dd/yyyy',
              )
            : ' ';
        this.selectedWorkOrder.printedDate =
          workOrderDates.printedDate != null
            ? DateTime.fromJSDate(
                new Date(workOrderDates.printedDate),
              ).toFormat('MM/dd/yyyy')
            : ' ';
        this.selectedWorkOrder.releasedDate =
          workOrderDates.releasedDate != null
            ? DateTime.fromJSDate(
                new Date(workOrderDates.releasedDate),
              ).toFormat('MM/dd/yyyy')
            : ' ';
        this.selectedWorkOrder.dueDate =
          workOrderDates.dueDate != null
            ? DateTime.fromJSDate(new Date(workOrderDates.dueDate)).toFormat(
                'MM/dd/yyyy',
              )
            : ' ';
      });
    forkJoin([
      this.workOrderService.getClosingDetails(
        this.selectedWorkOrder.workOrderId,
      ),
      this.workOrderService.generateWorkOrderPdf(
        this.selectedWorkOrder.workOrderId,
      ),
    ]).subscribe(([closingDetails, pdfData]) => {
      this.rawClosingDetails = cloneDeep(closingDetails);
      this.closingDetails = cloneDeep(closingDetails);
      this.pdfUrl = URL.createObjectURL(this.dataURItoBlob(pdfData));
      this.isLoading = false;
    });
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], {
      type: mimeString,
    });
    return blob;
  }

  onLoaded(pdf: PDFDocumentProxy) {
    this.pdf = pdf;
  }

  print() {
    this.pdf.getData().then((u8) => {
      let blob = new Blob([u8.buffer], {
        type: 'application/pdf',
      });

      const blobUrl = window.URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();

      if (!this.selectedWorkOrder.workOrderId) return;
      this.workOrderService
        .setWorkOrderPrint(this.selectedWorkOrder.workOrderId)
        .subscribe(() => {
          this.loadService.reloadWorkOrders();
          this.loadService.reloadOrderDetails();
        });
    });
  }

  onSetNotPrinted() {
    if (!this.selectedWorkOrder.workOrderId) return;
    this.workOrderService
      .setWorkOrderNotPrint(this.selectedWorkOrder.workOrderId)
      .subscribe(() => {
        this.loadService.reloadWorkOrders();
        this.loadService.reloadOrderDetails();
        this.isPrintedReport = false;
        this.selectedWorkOrder.printedDate = '';
      });
  }
  onDeleteWorkOrder() {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Work Order',
        content: 'Are you sure you want to delete this work order?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.workOrderService
          .deleteWorkOrder(this.selectedWorkOrder.workOrderId)
          .subscribe(() => {
            this.loadService.reloadWorkOrders();
            this.loadService.reloadOrderDetails();
            this.dialogRef.close();
          });
      });
  }
  onSetEditMode() {
    this.isEditWeight = !this.isEditWeight;
  }
  onCancel() {
    this.closingDetails = cloneDeep(this.rawClosingDetails);
    this.onSetEditMode();
  }
  onSaveDetail() {
    this.isSavingWeight = true;
    const requests = this.closingDetails.map((d) =>
      this.workOrderService.updateClosingDetail(d.workOrdersClosingID, {
        wghtGrms: d.weightGrams,
      }),
    );
    forkJoin(requests).subscribe(() => {
      this.onSetEditMode();
      this.isSavingWeight = false;
    });
  }
}
