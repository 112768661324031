import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash/orderBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProductFindings,
  ProductFindingsDto,
} from 'src/app/models/findings.model';
import { environment } from 'src/environments/environment';
import { ProductLinkBom } from '../components/bill-of-material/product-link-bom/product-link-bom';
import {
  ProductChainFinished,
  ProductChainRaw,
} from '../components/riva-chain/riva-chain-product/model';
import { FindingsTypeSize } from '../components/riva-findings/model';
import {
  ProductStone,
  StoneOverride,
  StoneSize,
} from '../components/riva-gems/riva-gems.model';
import { getDisplayText } from '../components/riva-gems/stone.mapper';
import { ProductsBOM } from '../models/product';
import { RivaGems } from './../components/riva-gems/riva-gems.model';
import { GenericService } from './generic.service';

export const sizeMapper = (sizes: FindingsTypeSize) => {
  const { size_1, size_2, size_3, size_4, size_5, size_6 } = sizes;
  const findingsSizes = [];
  if (size_1) findingsSizes.push(size_1);
  if (size_2) findingsSizes.push(size_2);
  if (size_3) findingsSizes.push(size_3);
  if (size_4) findingsSizes.push(size_4);
  if (size_5) findingsSizes.push(size_5);
  if (size_6) findingsSizes.push(size_6);

  return orderBy(findingsSizes, [], ['desc'])
    .map((w) => `${w}mm`)
    .join(' x ');
};

@Injectable({
  providedIn: 'root',
})
export class ProductBomService extends GenericService {
  baseUrl = environment.apiUrl + 'products/bill-of-materials';
  enamelUrl = `${environment.apiUrl}product-bom/enamel`;
  findingsUrl = `${environment.apiUrl}products-bom-findings`;
  stonesUrl = `${environment.apiUrl}product-bom/stones`;
  stonesOrderDetailsUrl = `${environment.apiUrl}product-bom/order-details/stones`;
  stoneOverrideUrl = `${environment.apiUrl}product-bom/stone-override`;
  chainRawUrl = `${environment.apiUrl}product-bom-chain/raw`;
  chainFinishUrl = `${environment.apiUrl}product-bom-chain/finished`;
  productLinkUrl = `${environment.apiUrl}product-bom/product`;

  public reloadProductLinkBomList = (productId: number) => {};
  public reloadProductLinkBomListDisplay = (productId: number) => {};

  constructor(http: HttpClient) {
    super(http);
  }

  getProductBomList(productsId: number): Observable<any> {
    let url = this.baseUrl + '/' + productsId + '/list';
    return this.http.get<any>(url, { headers: this.headers });
  }

  getTableAList(productId: number) {
    let url = this.baseUrl + '/table-a-list/' + productId;
    return this.http.get<any>(url, { headers: this.headers });
  }

  getTableAOptions() {
    let url = this.baseUrl + '/table-a-options/';
    return this.http.get<any>(url, { headers: this.headers });
  }

  getFabMethods() {
    let url = this.baseUrl + '/fab-methods/';
    return this.http.get<any>(url, { headers: this.headers });
  }

  getTableBList(productId: number) {
    let url = this.baseUrl + '/table-b-list/' + productId;
    return this.http.get<any>(url, { headers: this.headers });
  }

  addUpdate(bom: ProductsBOM): Observable<any> {
    let url = this.baseUrl;
    return this.http.post(url, bom, { headers: this.headers });
  }
  getProductEnamel(productId: number): Observable<any[]> {
    return this.http.get<any[]>(this.enamelUrl, {
      headers: this.headers,
      params: { productId },
    });
  }
  deleteProductEnamel(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.enamelUrl, {
      headers: this.headers,
      params: { id },
    });
  }
  setProductEnamel(data) {
    return this.http.post<any[]>(this.enamelUrl, data, {
      headers: this.headers,
    });
  }
  getProductFindings(productId: number): Observable<ProductFindings[]> {
    return this.http
      .get<ProductFindings[]>(this.findingsUrl, {
        headers: this.headers,
        params: { productId },
      })
      .pipe(
        map((findings) =>
          findings.map((f) => ({
            ...f,
            sizeLabel: sizeMapper(f.size as FindingsTypeSize),
          })),
        ),
      );
  }
  setProductFindings(findings: ProductFindingsDto) {
    return this.http.post(this.findingsUrl, findings, {
      headers: this.headers,
    });
  }
  deleteProductFindings(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.findingsUrl}/${id}`, {
      headers: this.headers,
    });
  }
  getProductStones(productId: number): Observable<ProductStone[]> {
    return this.http
      .get<ProductStone[]>(this.stonesUrl, {
        headers: this.headers,
        params: { productId },
      })
      .pipe(
        map((productStone) =>
          productStone.map((ps) => ({
            ...ps,
            stone: {
              ...(ps.stone ?? ({} as RivaGems)),
              stoneSize: {
                ...(ps.stone.stoneSize ?? ({} as StoneSize)),
                displayText: getDisplayText(ps.stone.stoneSize),
              },
            },
          })),
        ),
      );
  }
  setProductStone(data) {
    return this.http.post(this.stonesUrl, data, {
      headers: this.headers,
    });
  }
  deleteProductStones(productsBOMStonesID: number): Observable<boolean> {
    return this.http.delete<boolean>(this.stonesUrl, {
      headers: this.headers,
      params: { productsBOMStonesID },
    });
  }
  getStoneByOrderDetailsId(orderDetailsId): Observable<ProductStone> {
    return this.http
      .get<ProductStone>(this.stonesOrderDetailsUrl, {
        headers: this.headers,
        params: {
          orderDetailsId,
        },
      })
      .pipe(
        map((productStone = {} as ProductStone) => ({
          ...productStone,
          stone: {
            ...(productStone?.stone ?? ({} as RivaGems)),
            stoneSize: {
              ...(productStone?.stone?.stoneSize ?? ({} as StoneSize)),
              displayText: productStone?.stone?.stoneSize
                ? getDisplayText(productStone?.stone?.stoneSize)
                : '',
            },
          },
        })),
      );
  }
  getStoneOverride(productsBomStoneId: number): Observable<StoneOverride[]> {
    return this.http.get<StoneOverride[]>(this.stoneOverrideUrl, {
      headers: this.headers,
      params: { productsBomStoneId },
    });
  }
  setStoneOverride(data) {
    return this.http.post(this.stoneOverrideUrl, data, {
      headers: this.headers,
    });
  }
  deleteStoneOverride(id) {
    return this.http.delete(`${this.stoneOverrideUrl}/${id}`, {
      headers: this.headers,
    });
  }

  setProductChainRaw(data: ProductChainRaw) {
    return this.http.post(this.chainRawUrl, data, {
      headers: this.headers,
    });
  }
  getProductChainRaw(productId: number) {
    return this.http.get<ProductChainRaw[]>(
      `${this.chainRawUrl}/${productId}`,
      {
        headers: this.headers,
      },
    );
  }
  deleteProductChainRaw(productsBOMChainRawID: number) {
    return this.http.delete(`${this.chainRawUrl}/${productsBOMChainRawID}`, {
      headers: this.headers,
    });
  }
  setProductChainFinished(data: ProductChainFinished) {
    return this.http.post(this.chainFinishUrl, data, {
      headers: this.headers,
    });
  }
  getProductChainFinished(productId: number) {
    return this.http.get<ProductChainFinished[]>(
      `${this.chainFinishUrl}/${productId}`,
      {
        headers: this.headers,
      },
    );
  }
  deleteProductChainFinished(productsBOMChainFinishedID: number) {
    return this.http.delete(
      `${this.chainFinishUrl}/${productsBOMChainFinishedID}`,
      {
        headers: this.headers,
      },
    );
  }

  getProductLinkBom(productId: number) {
    return this.http.get<ProductLinkBom[]>(
      `${this.productLinkUrl}/${productId}`,
      {
        headers: this.headers,
      },
    );
  }
  setProductLinkBom(data: ProductLinkBom) {
    return this.http.post(this.productLinkUrl, data, {
      headers: this.headers,
    });
  }
  deleteProductLinkBom(productsID_BOM: number) {
    return this.http.delete(`${this.productLinkUrl}/${productsID_BOM}`, {
      headers: this.headers,
    });
  }
}
