import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FindingsService } from 'src/app/services/findings.service';
import { FINDINGS_TYPE } from '../../constants';
import { Finding } from '../../model';

@Component({
  templateUrl: './spring-dialog.component.html',
  styleUrls: ['./spring-dialog.component.scss'],
})
export class SpringDialogComponent {
  isSaving = false;
  spring: Finding = {} as Finding;
  comment = '';

  constructor(
    public dialogRef: MatDialogRef<SpringDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { springs: Finding[] },

    private toastrService: ToastrService,
    private findingsService: FindingsService,
  ) {}

  onSaveSpring() {
    const isExist = this.data.springs.some((j) => {
      const { size_1, size_2, size_3, size_4 } = this.spring;
      return (
        j.size_1 === size_1 &&
        j.size_2 === (size_2 ?? 0) &&
        j.size_3 === (size_3 ?? 0) &&
        j.size_4 === (size_4 ?? 0)
      );
    });
    if (isExist) {
      this.toastrService.error('Size already exist.', 'Spring');
      return;
    }
    this.isSaving = true;
    this.findingsService
      .setFindingsSizes({
        ...this.spring,
        findingsTypesID: FINDINGS_TYPE.SPRING,
      })
      .subscribe((id) => {
        this.findingsService
          .setFinding({
            findingsId: 0,
            commentBox: this.comment,
            findingSizesId: id,
          })
          .subscribe(() => {
            this.isSaving = false;
            this.toastrService.success('Successfully saved.', 'Spring Ring');
            this.dialogRef.close(true);
          });
      });
  }

  get isFormValid() {
    const { size_1, size_2, size_3, size_4 } = this.spring;

    return (
      size_1 &&
      size_1 < 100 &&
      size_2 &&
      size_2 < 100 &&
      size_3 &&
      size_3 < 100 &&
      size_4 &&
      size_4 < 100
    );
  }
}
