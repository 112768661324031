import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { ChainService } from 'src/app/services/chain.service';
import { ChainDialogComponent } from '../riva-chain/chain-dialog/chain-dialog.component';

const chainTabSlugByIndex = {
  0: 'chain-raw',
  1: 'chain-finished',
};
const chainTabIndexBySlug = {
  'chain-raw': 0,
  'chain-finished': 1,
};

@Component({
  selector: 'riva-chain-page',
  templateUrl: './riva-chain-page.component.html',
  styleUrls: ['./riva-chain-page.component.scss'],
})
export class RivaChainPageComponent {
  selectedTabIndex = 0;

  constructor(
    public dialog: MatDialog,
    private userPermissionService: UserPermissionService,
    private chainService: ChainService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.chain);

    this.route.params.subscribe((params: { slug: string }) => {
      this.selectedTabIndex = chainTabIndexBySlug[params.slug] ?? 0;
    });
  }

  createNewChainStyle() {
    const dialogRef = this.dialog.open(ChainDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
    });
    dialogRef.afterClosed().subscribe((id) => {
      // this.chainService.reloadChains(id);
      this.chainService.reloadRawChainTypes();
      this.chainService.reloadFinishChainTypes();
    });
  }

  onTabChange(event) {
    const slug = chainTabSlugByIndex[event];
    this.location.replaceState(`items/chain/${slug}`);
  }
}
