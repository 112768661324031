import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { FindingsService } from 'src/app/services/findings.service';
import { FINDINGS_TYPE } from '../constants';
import { Finding, FindingsStock } from '../model';

interface FindingSkuDetail {
  findingsId: number;
  findingSizeId: number;
  materialCodeId: number;
}

@Component({
  templateUrl: './findings-sku-generator-dialog.component.html',
  styleUrls: ['./findings-sku-generator-dialog.component.scss'],
})
export class FindingsSkuGeneratorDialogComponent implements OnInit {
  findingSkuDetail: FindingSkuDetail = {} as FindingSkuDetail;
  findingsByType = new MatTableDataSource<Finding>([]);

  jumpringColumns = {
    size_1: 'Wire Diameter',
    jumpringSize: 'ID',
  };
  postColumns = {
    size_1: 'Length',
    size_2: 'Thickness',
  };
  flyerColumns = {
    size_1: 'Size',
  };
  springColumns = {
    size_1: 'Spring height',
    size_2: 'Wire Thickness',
    size_3: 'Spring Angle',
    size_4: 'Hole Inside Diameter',
  };
  springRingColumns = {
    name: 'Name',
    size_1: 'Size',
  };
  lobsterClawColumns = {
    name: 'Name',
    size_1: 'Size',
  };
  tagColumns = {
    name: 'Name',
    customerName: 'Customer',
    size_1: 'Lengths',
    size_2: 'Width',
    size_3: 'Thickness',
  };

  columns: Record<string, string>; // Key and Label (name: 'Name')
  displayedColumns = [];
  stocks: FindingsStock[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      findings: Finding[];
      findingsTypeId: number;
      findingsId: number;
      findingSizeId?: number;
      materialCodeId?: number;
    },
    public dialogRef: MatDialogRef<FindingsSkuGeneratorDialogComponent>,
    private toastrService: ToastrService,
    private findingsService: FindingsService,
  ) {}

  ngOnInit(): void {
    this.findingSkuDetail.findingsId = this.data.findingsId;
    this.findingSkuDetail.materialCodeId = this.data.materialCodeId;
    this.findingSkuDetail.findingSizeId = this.data.findingSizeId;
    this.getStockBySizeId();
    this.columns = this.getColumns(this.data.findingsTypeId);
    this.displayedColumns = Object.keys(this.columns);
  }

  onSelectFinding(finding: Finding) {
    this.findingSkuDetail.findingsId = finding.findingsId;
    this.findingSkuDetail.findingSizeId = finding.findingSizesID;
    this.findingSkuDetail.materialCodeId = 0;
    this.getStockBySizeId();
  }
  getStockBySizeId() {
    if (!this.findingSkuDetail.findingSizeId) {
      return;
    }
    this.findingsService
      .getFindingsStockBySizeId(this.findingSkuDetail.findingSizeId)
      .subscribe((data) => {
        this.stocks = data ?? [];
      });
  }

  getGeneratedSku() {
    const findingsId = String(this.data.findingsId ?? 0).padStart(5, '0');
    const materialCode = String(
      this.findingSkuDetail.materialCodeId ?? 0,
    ).padStart(3, '0');
    const findingsSizeId = String(
      this.findingSkuDetail.findingSizeId ?? 0,
    ).padStart(3, '0');

    return `FIND-${findingsId}-${findingsSizeId}-${materialCode}`;
  }

  onCopySku() {
    navigator.clipboard.writeText(this.getGeneratedSku());
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }

  getColumns(typeId) {
    switch (typeId) {
      case FINDINGS_TYPE.JUMPRINGS:
        return this.jumpringColumns;
      case FINDINGS_TYPE.POST:
        return this.postColumns;
      case FINDINGS_TYPE.FLYER:
        return this.flyerColumns;
      case FINDINGS_TYPE.SPRING:
        return this.springColumns;
      case FINDINGS_TYPE.SPRING_RING:
        return this.springRingColumns;
      case FINDINGS_TYPE.LOBSTER_CLAW:
        return this.lobsterClawColumns;
      case FINDINGS_TYPE.TAGS:
        return this.tagColumns;
      default:
        return this.jumpringColumns;
    }
  }
}
