import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MaterialCode } from 'src/app/models/material-code';
import { FindingsService } from 'src/app/services/findings.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { FINDING_MAX_WEIGHT } from '../constants';
import { Finding, FindingsStock } from '../model';
import { countDecimalPlaces } from '../util';

@Component({
  templateUrl: './finding-stock-dialog.component.html',
  styleUrls: ['./finding-stock-dialog.component.scss'],
})
export class FindingStockDialogComponent implements OnInit {
  isSaving = false;
  selectedStock: FindingsStock = {} as FindingsStock;
  materialCodes: MaterialCode[] = [];

  constructor(
    public dialogRef: MatDialogRef<FindingStockDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { selectedFinding: Finding; stocks: FindingsStock[] },

    private toastrService: ToastrService,
    private findingsService: FindingsService,
    private materialCodeService: MaterialCodeService,
  ) {}

  ngOnInit(): void {
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject.filter(
        (m) =>
          !m.multiMetal &&
          !this.data.stocks.some((s) => s.materialsCodeID === m.materialCodeId),
      );
    });
  }

  onSaveStock() {
    const {
      findingsStockID,
      findingsSizesID,
      materialsCodeID,
      weight_G,
      qtyInStock,
      suppliersID,
    } = this.selectedStock;
    const stock = {
      findingsStockID,
      weight_G,
      qtyInStock,
      suppliersID,
      materialCodesID: materialsCodeID,
      findingsSizesID:
        findingsSizesID ?? this.data.selectedFinding.findingSizesID,
    };
    this.isSaving = true;
    this.findingsService.setFindingsStock(stock).subscribe(() => {
      this.toastrService.success('Successfully saved.', 'Finding Stock');
      this.isSaving = false;
      this.dialogRef.close(true);
    });
  }

  get isFormValid() {
    return (
      this.selectedStock.materialsCodeID &&
      this.selectedStock.qtyInStock >= 0 &&
      Number.isInteger(this.selectedStock.qtyInStock) &&
      this.selectedStock.weight_G < 100 &&
      countDecimalPlaces(this.selectedStock.weight_G) <= FINDING_MAX_WEIGHT
    );
  }
}
