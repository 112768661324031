import { PAGE_NAME } from './pages';

export const SUPPLIER_FEATURE_KEY = {
  create: 'SUPPLIER_CREATE',
  edit: 'SUPPLIER_EDIT',
};

export const supplierPermission = {
  [PAGE_NAME.supplier]: {
    SuperUser: {
      [SUPPLIER_FEATURE_KEY.create]: 'access',
      [SUPPLIER_FEATURE_KEY.edit]: 'access',
    },
    User: {
      [SUPPLIER_FEATURE_KEY.create]: 'access',
      [SUPPLIER_FEATURE_KEY.edit]: 'access',
    },
    Viewer: {
      [SUPPLIER_FEATURE_KEY.create]: 'disabled',
      [SUPPLIER_FEATURE_KEY.edit]: 'disabled',
    },
  },
};
