<div class="findings-list">
  <div class="findings-list__header">
    <div>
      <mat-form-field
        class="search-input"
        appearance="fill"
        style="width: 400px"
      >
        <mat-label>Search by name, sku or supplier</mat-label>
        <input
          matInput
          [(ngModel)]="search"
          (ngModelChange)="onFilterChange()"
        />
      </mat-form-field>
    </div>
    <div class="new-findings-container">
      <button
        class="btn riva-button"
        (click)="onClickNewFinding()"
        [disabled]="editMode"
        style="width: 300px"
      >
        <span>New Finding</span>
      </button>
    </div>
  </div>
  <div class="findings-table-container">
    <table
      mat-table
      matSort
      [dataSource]="findings"
      matSortActive="findingsId"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="picPath">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div
            style="
              width: 70px;
              padding: 5px;
              display: flex;
              justify-content: center;
            "
            *ngIf="element.picPath"
          >
            <img
              style="max-height: 37px; max-width: 60px"
              [src]="element.picPath"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by name"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sku">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by sku"
        >
          SKU
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.sku }}
        </td>
      </ng-container>
      <ng-container matColumnDef="suppliersName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by suppliersName"
        >
          Supplier
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.suppliersName }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="findings-row"
        [ngClass]="{
          'is-selected': row.findingsId === selectedFindings?.findingsId,
          'disabled-row': editMode
        }"
        (click)="onTableRowClick(row)"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="2">No Available Data</td>
      </tr>
    </table>
  </div>
</div>
