import { Component } from '@angular/core';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'work-order-movement',
  templateUrl: './work-order-movement.component.html',
  styleUrls: ['./work-order-movement.component.scss'],
})
export class WorkOrderMovementComponent {
  constructor(private userPermissionService: UserPermissionService) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.workOrderMovement);
  }
}
