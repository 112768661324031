import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SkuService } from 'src/app/services/sku.service';

@Component({
  selector: 'add-product-by-customer-sku-dialog',
  templateUrl: './add-product-by-customer-sku-dialog.component.html',
  styleUrls: ['./add-product-by-customer-sku-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddProductByCustomerSkuDialogComponent implements OnInit {
  @ViewChild('searchConfirmation')
  public readonly searchConfirmation!: SwalComponent;
  customerSku = '';
  isSearching = false;

  confirmation: {
    icon: string;
    title: string;
    description: string;
    timer?: number;
  } = {
    icon: 'question',
    title: '',
    description: '',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { customerId: number },
    public dialogRef: MatDialogRef<AddProductByCustomerSkuDialogComponent>,
    private skuService: SkuService,
  ) {}

  ngOnInit(): void {}

  onFindProductByCustomerSku() {
    if (!this.customerSku) return;
    this.isSearching = true;
    this.skuService
      .searchSku(this.customerSku, this.data.customerId)
      .subscribe((data) => {
        if (data.length === 0) {
          this.confirmation = {
            title: 'Not Found',
            description: 'Customer SKU not found.',
            icon: 'error',
          };
          setTimeout(() => {
            this.searchConfirmation.fire();
          }, 100);
        } else {
          this.dialogRef.close(data[0]);
        }
        this.isSearching = false;
      });
  }
}
