<h3 mat-dialog-title>Select Work Order Items</h3>
<mat-dialog-content class="mat-typography">
  <div class="shipping-detail-dialog">
    <div style="border: 1px solid #646464">
      <table
        mat-table
        [dataSource]="shipmentItems"
        matSort
        matSortActive="productName"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="selection">
          <th mat-header-cell *matHeaderCellDef>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <mat-checkbox
                class="example-margin"
                [checked]="isSelectedAll"
                [indeterminate]="isSomeSelected"
                (change)="selectAll($event.checked)"
              >
              </mat-checkbox>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <mat-checkbox
                [(ngModel)]="element.selected"
                [disabled]="!element.isClosed"
                (ngModelChange)="onSelect()"
              ></mat-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="productPicPath">
          <th mat-header-cell *matHeaderCellDef>Pic</th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                width: 55px;
                padding: 5px;
                height: 55px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
              "
              *ngIf="element.productPicPath"
            >
              <img
                style="max-width: 100%; max-height: 100%"
                [src]="element.productPicPath"
              />
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef>Index</th>
          <td mat-cell *matCellDef="let element">{{ element.index }}</td>
        </ng-container>
        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef>Product Name</th>
          <td mat-cell *matCellDef="let element">{{ element.productName }}</td>
        </ng-container>
        <ng-container matColumnDef="material">
          <th mat-header-cell *matHeaderCellDef>Metal</th>
          <td mat-cell *matCellDef="let element">
            {{ element.material }}
          </td>
        </ng-container>
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef>Size</th>
          <td mat-cell *matCellDef="let element">{{ element.size }}</td>
        </ng-container>
        <ng-container matColumnDef="qty">
          <th mat-header-cell *matHeaderCellDef>Qty</th>
          <td mat-cell *matCellDef="let element">
            <div class="item-qty">
              <nz-input-number
                [(ngModel)]="element.qty"
                [nzMin]="1"
                [nzMax]="element.maxQty"
                [nzStep]="1"
                [disabled]="!element.isClosed"
                style="width: 100%"
              ></nz-input-number>
              <span class="max-item">{{ element.maxQty }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="cipo">
          <th mat-header-cell *matHeaderCellDef>CIPO</th>
          <td mat-cell *matCellDef="let element">{{ element.cipo }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ 'row-disabled': !row.isClosed }"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="9">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      width: 400px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      [disabled]="!(isSelectedAll || isSomeSelected)"
      (click)="onAddItem()"
      autofocus
    >
      Add
    </button>
  </div>
</mat-dialog-actions>
