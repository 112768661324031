import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Invoice, InvoiceDetail } from './../models/invoice';

@Component({
  selector: 'invoicing-print-dialog',
  templateUrl: './invoicing-print-dialog.component.html',
  styleUrls: ['./invoicing-print-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvoicingPrintDialogComponent implements OnInit {
  totalPriceExt: number;
  hasEnamel: boolean;
  hasCipo: boolean;
  hasSize: boolean;
  isShortPage: boolean;
  invoicePages: Array<InvoiceDetail[]>;
  otherPagesItems: InvoiceDetail[];

  displayedColumns = [
    'productName',
    'poNumber',
    'cipo',
    'uom',
    'material',
    'size',
    'weight',
    'stoneType',
    'enamelName',
    'qty',
    'price',
    'priceExtension',
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InvoicingPrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { detail: Invoice },
  ) {}

  ngOnInit(): void {
    this.totalPriceExt =
      this.props.detail?.invoicesDetails?.reduce(
        (total, i) => total + i.qty * i.price,
        this.props.detail.shippingCost || 0,
      ) ?? 0;
    this.hasEnamel = this.props.detail?.invoicesDetails?.some(
      (i) => i.enamelName,
    );
    this.hasCipo = this.props.detail?.invoicesDetails?.some((i) => i.cipo);
    this.hasSize = this.props.detail?.invoicesDetails?.some(
      (i) => i.size?.trim() !== '-',
    );
    const hasWeight = this.props.detail?.invoicesDetails?.some(
      (i) => i.weight > 0,
    );
    const hasType = this.props.detail?.invoicesDetails?.some(
      (i) => i.stoneType,
    );
    if (!this.hasCipo) {
      this.displayedColumns = this.displayedColumns.filter((c) => c !== 'cipo');
    }
    if (!this.hasEnamel) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'enamelColor',
      );
    }
    if (!this.hasSize) {
      this.displayedColumns = this.displayedColumns.filter((c) => c !== 'size');
    }
    if (!hasWeight) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'weight',
      );
    }
    if (!hasType) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'stoneType',
      );
    }
    this.setUpPagination();
  }

  setUpPagination() {
    const mainPageLimit =
      this.props.detail.invoicesDetails.length > 12 ? 12 : 10;
    const otherPagesLimit = 20;
    this.isShortPage = mainPageLimit === 10;
    let currentPageIndex = 0;
    let currentPageLimit = mainPageLimit;
    this.invoicePages = this.props.detail.invoicesDetails.reduce(
      (pages, invoice) => {
        if ((pages[currentPageIndex]?.length ?? 0) < currentPageLimit) {
          pages[currentPageIndex] = [
            ...(pages[currentPageIndex] ?? []),
            invoice,
          ];
        } else {
          currentPageIndex++;
          currentPageLimit = otherPagesLimit;
          pages[currentPageIndex] = [
            ...(pages[currentPageIndex] ?? []),
            invoice,
          ];
        }
        return pages;
      },
      [],
    );
  }
}
