import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';
import { StoneOpenOrder } from 'src/app/models/orders.model';
import { OrderService } from 'src/app/services/order.service';
import { StoneReleaseService } from 'src/app/services/stone-release.service';

export interface GroupBy {
  releasedDate: string;
  isGroupBy: boolean;
}

@Component({
  selector: 'stone-release-history-dialog',
  templateUrl: './stone-release-history-dialog.component.html',
  styleUrls: ['./stone-release-history-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StoneReleaseHistoryDialogComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'productName',
    'stoneType',
    'stoneCut',
    'stoneClarity',
    'stoneColor',
    'stoneSize',
    'labGrown',
    'traceable',
    'qtyMoved',
    'customerProvided',
    'stonereleasedate',
  ];

  history = new MatTableDataSource<StoneOpenOrder | GroupBy>([]);
  historyRaw: StoneOpenOrder[] = [];
  historyFiltered: StoneOpenOrder[] = [];
  searchQuery = '';

  constructor(
    public dialogRef: MatDialogRef<StoneReleaseHistoryDialogComponent>,
    private orderService: OrderService,
    private stoneReleaseService: StoneReleaseService,
  ) {}

  ngOnInit(): void {
    this.stoneReleaseService.getStoneReleaseHistory().subscribe(({ data }) => {
      this.historyRaw = data;
      this.processReleaseHistory(data);
    });
  }

  onFilter() {
    const data = this.historyRaw.filter((v) =>
      v.workOrdersId?.toString().includes(this.searchQuery.toLowerCase()),
    );
    this.processReleaseHistory(data);
  }

  processReleaseHistory(data) {
    const releaseDateGroup = data.reduce((accum, d) => {
      const releasedDate = DateTime.fromISO(d.stonereleasedate).toFormat(
        'yyyy-MM-dd',
      );
      const isExist = accum.some((x) => x.workOrdersId === d.workOrdersId);
      if (isExist) return accum;
      return [
        ...accum,
        {
          releasedDate,
          workOrdersId: d.workOrdersId,
        },
      ];
    }, []);
    const group = groupBy(data, 'workOrdersId');
    this.history.data = releaseDateGroup.reduce((historyData, g) => {
      return [
        ...historyData,
        { label: g.workOrdersId, isGroupBy: true },
        ...group[g.workOrdersId],
      ];
    }, []);
  }

  // ngAfterViewInit(): void {
  //   this.history.filterPredicate = (data: User, filterValue: string) => {
  //     const { search } = JSON.parse(filterValue) ?? {};
  //     const searchFilter =
  //       data.fullName
  //         ?.toString()
  //         .toLowerCase()
  //         .includes(search?.toLowerCase()) ||
  //       data.userName?.toLowerCase().includes(search?.toLowerCase());
  //     return searchFilter;
  //   };
  // }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }
}
