<div class="reset-password-dialog">
  <h3 mat-dialog-title>Reset Password</h3>
  <mat-dialog-content class="mat-typography">
    <div>
      <mat-form-field class="account-input" appearance="fill">
        <mat-label>New Password</mat-label>
        <input matInput [(ngModel)]="newPassword" type="password" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="account-input" appearance="fill">
        <mat-label>Confirm New Password</mat-label>
        <input matInput [(ngModel)]="confirmNewPassword" type="password" />
      </mat-form-field>
    </div>
    <mat-error *ngIf="hasPasswordError" class="password-error"
      >Password didn't match.</mat-error
    >
  </mat-dialog-content>

  <mat-dialog-actions
    style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px"
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      [disabled]="disabledSaveButton"
      (click)="onResetPassword()"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
