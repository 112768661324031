import { HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import { Product } from 'src/app/models/product';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';
import { SharedService } from 'src/app/services/shared.service';
import { OrderdetailsService } from '../orderdetails.service';
import { OrderImport } from './order-import';

@Component({
  selector: 'order-upload-dialog',
  templateUrl: './order-upload-dialog.component.html',
  styleUrls: ['./order-upload-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderUploadDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'selection',
    'productShortName',
    'karatMetal',
    'size',
    'cipo',
    'qtyOrdered',
    'dueDate',
    'comments',
  ];
  orderImports: OrderImport[] = [];
  orderImportsFailed: OrderImport[] = [];
  hasFailedImports = false;
  isUploading = false;
  isSubmitting = false;
  uploadStatus: 'None' | 'Error' | 'Success' = 'None';
  importFailedAccordionLabel = 'Failed Imports';
  importSuccessAccordionLabel = 'Successful Imports';
  products: Product[];

  @ViewChild('uploadOrderFile') fileUploader: ElementRef;

  constructor(
    private orderService: OrderService,
    private sharedService: SharedService,
    private orderDetailsService: OrderdetailsService,
    private productService: ProductService,
    public dialogRef: MatDialogRef<OrderUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: {
      ordersId: number;
      customerId: number;
    },
  ) {}

  ngOnInit(): void {}

  uploadOrders(files) {
    if (files.length === 0) {
      return;
    }
    this.isUploading = true;
    const formData = new FormData();

    formData.append('file[]', files[0]);

    this.orderService
      .uploadOrders(formData, {
        customerId: this.props.customerId,
      })
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            console.log(
              'Uploading: ' +
                Math.round((100 * event.loaded) / event.total) +
                '%',
            );
          } else if (event.type === HttpEventType.Response) {
            const orderImports = event.body as OrderImport[];
            this.processOrderImports(orderImports);

            this.fileUploader.nativeElement.value = null;

            if (
              orderImports.length &&
              orderImports.some((o) => o.productId === 0)
            ) {
              this.getProductByCustomer();
            } else {
              this.isUploading = false;
              this.uploadStatus = 'Success';
            }
          }
        },
        () => {
          this.uploadStatus = 'Error';
          this.fileUploader.nativeElement.value = null;
        },
      );
  }

  processOrderImports(imports: OrderImport[]) {
    this.orderImports = imports
      .filter(
        (i) =>
          (i.productId ?? 0) > 0 &&
          (i.materialCodeId ?? 0) > 0 &&
          (i.productSizeId ?? 0) > 0 &&
          (i.qtyOrdered ?? 0) > 0,
      )
      .map((s) => ({
        ...s,
        selected: true,
      }));
    this.orderImportsFailed = imports
      .filter(
        (i) =>
          (i.productId ?? 0) === 0 ||
          (i.materialCodeId ?? 0) === 0 ||
          (i.productSizeId ?? 0) === 0 ||
          (i.qtyOrdered ?? 0) === 0,
      )
      .map((f) => ({
        ...f,
        selected: true,
        hasMaterial: f.materialCodeId > 0,
        hasSize: f.productSizeId > 0,
        hasProduct: f.productId > 0,
        hasCIPO: (f.cipo ?? '') !== '',
        hasQty: f.qtyOrdered > 0,
        hasComments: f.comments != '',
        hasDueDate: (f.dueDate ?? '') !== '',
      }));
    this.hasFailedImports = this.orderImportsFailed.length > 0;
    this.importFailedAccordionLabel = `Failed Imports (${
      this.orderImportsFailed.length ?? 0
    })`;
    this.importSuccessAccordionLabel = `Successful Imports (${
      this.orderImports.length ?? 0
    })`;
  }

  get isFormValid() {
    const imports = [...this.orderImports, ...this.orderImportsFailed].filter(
      (i) => i.selected,
    );
    return (
      imports.length > 0 &&
      !imports.some(
        (o) =>
          (o.productId ?? 0) === 0 ||
          (o.materialCodeId ?? 0) === 0 ||
          (o.productSizeId ?? 0) === 0 ||
          (o.qtyOrdered ?? 0) === 0,
      )
    );
  }

  onSubmit() {
    const imports = [...this.orderImports, ...this.orderImportsFailed].filter(
      (i) => i.selected,
    );
    const requests = imports.map((i) => {
      const order = {
        ordersDetailsId: 0,
        ordersId: this.props.ordersId,
        productsId: i.productId,
        materialCodeID: i.materialCodeId,
        productSizesID: i.productSizeId,
        qtyordered: i.qtyOrdered,
        dueDate: i.dueDate
          ? DateTime.fromJSDate(new Date(i.dueDate)).toFormat('yyyy-MM-dd')
          : null,
        custAdrsId: 0,
        comment: i.comments ?? '',
        cipo: i.cipo,
        customized: false,
      };
      return this.orderDetailsService.addupdate(order);
    });
    this.isSubmitting = true;
    forkJoin(requests).subscribe(() => {
      this.sharedService.showGenerateWorkOrderAndByPass = true;
      this.isSubmitting = false;
      this.dialogRef.close(true);
    });
  }

  getProductByCustomer() {
    this.productService
      .getSearchList({
        searchString: '',
        customerCode: this.props.customerId,
        jewelryType: 0,
        productTypeID: 1,
      })
      .subscribe((response) => {
        this.products = JSON.parse(response);
        this.isUploading = false;
        this.uploadStatus = 'Success';
      });
  }

  getProductMaterialsAndSizes(row: OrderImport) {
    if (row.productId > 0) {
      this.productService.getProductDetail(row.productId).subscribe((data) => {
        row.materials =
          data?.materials.map((m) => ({
            materialCodeId: m.materialsCodeID,
            code: m.material.code,
            description: m.material.description,
          })) ?? [];
        row.productSizes =
          data?.productSizes.map((s) => ({
            productsSizesId: s.productsSizesID,
            size: s.size,
          })) ?? [];
      });
    }
  }
}
