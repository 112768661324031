export enum Customer {
  HOF = 1,
  HOFFullfilment = 2,
  Ringware = 3,
  Mizuki = 4,
  BaubleBar = 5,
}

export enum CustomerCode {
  RGW = 1,
  RIVA = 2,
  TRY = 3,
}
