<div class="set-permission-dialog">
  <h3
    style="display: flex; align-items: center; justify-content: space-between"
    mat-dialog-title
  >
    <span>User Permissions</span>
    <div style="display: flex; align-items: center; font-size: 14px">
      <div style="margin-right: 15px">Administrator</div>
      <div>
        <nz-switch
          [(ngModel)]="data.isAdmin"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
          [disabled]="true"
        ></nz-switch>
        <ng-template #checkedTemplate
          ><i nz-icon nzType="check"></i
        ></ng-template>
        <ng-template #unCheckedTemplate
          ><i nz-icon nzType="close"></i
        ></ng-template>
      </div>
    </div>
  </h3>
  <mat-dialog-content class="mat-typography" style="min-height: 400px">
    <ul
      class="menu-items"
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzSelectable]="false"
    >
      <ng-container *ngFor="let menu of menuPermission">
        <li
          *ngIf="menu.subMenu?.length"
          nz-submenu
          [nzTitle]="menu.name"
          [nzIcon]="menu.icon"
          [nzOpen]="true"
        >
          <ul>
            <li
              *ngFor="let subMenu of menu.subMenu"
              nz-menu-item
              style="display: flex; justify-content: space-between"
            >
              <a class="menu-link">
                {{ subMenu.name }}
              </a>
              <div
                *ngIf="subMenu.permission != null"
                style="display: flex; align-items: center; gap: 16px"
              >
                <div
                  *ngIf="!subMenu.hidden"
                  style="display: flex; align-items: center; font-size: 14px"
                >
                  <div style="margin-right: 15px">Default Page</div>
                  <div>
                    <nz-switch
                      [ngModel]="subMenu.isDefaultPage"
                      (click)="onSetDefaultPage(subMenu.url)"
                      [disabled]="subMenu.permission.accessLevel === 'Disabled'"
                      nzSize="small"
                    ></nz-switch>
                  </div>
                </div>

                <nz-select
                  [(ngModel)]="subMenu.permission.accessLevel"
                  [disabled]="data.isAdmin"
                  style="width: 150px"
                >
                  <nz-option
                    *ngFor="let accessLevel of accessLevels"
                    [nzValue]="accessLevel.value"
                    [nzLabel]="accessLevel.label"
                  ></nz-option>
                </nz-select>
              </div>
            </li>
          </ul>
        </li>
        <li nz-menu-item *ngIf="!menu.subMenu" nzSelected="false">
          <i nz-icon [nzType]="menu.icon"></i>
          <span
            style="width: 95%; display: flex; justify-content: space-between"
          >
            <span *ngIf="!menu.url">{{ menu.name }}</span>
            <a *ngIf="menu.url" class="menu-link">
              {{ menu.name }}
            </a>
            <div
              *ngIf="menu.url && menu.permission != null"
              style="display: flex; align-items: center; gap: 16px"
            >
              <div
                *ngIf="!menu.hidden"
                style="display: flex; align-items: center; font-size: 14px"
              >
                <div style="margin-right: 15px">Default Page</div>
                <div>
                  <nz-switch
                    [ngModel]="menu.isDefaultPage"
                    (click)="onSetDefaultPage(menu.url)"
                    [disabled]="menu.permission.accessLevel === 'Disabled'"
                    nzSize="small"
                  ></nz-switch>
                </div>
              </div>
              <nz-select
                [(ngModel)]="menu.permission.accessLevel"
                [disabled]="data.isAdmin"
                style="width: 150px"
              >
                <nz-option
                  *ngFor="let accessLevel of accessLevels"
                  [nzValue]="accessLevel.value"
                  [nzLabel]="accessLevel.label"
                ></nz-option>
              </nz-select>
            </div>
          </span>
        </li>
      </ng-container>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px"
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onSavePermission()"
      [disabled]="isSaving"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
