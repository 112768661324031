<h3 mat-dialog-title>Customer Address</h3>

<mat-dialog-content class="mat-typography">
  <div class="customer-address-dialog">
    <div class="two-column">
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Address 1</mat-label>
        <input matInput [(ngModel)]="customerAddress.address1" />
      </mat-form-field>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Address 2</mat-label>
        <input matInput [(ngModel)]="customerAddress.address2" />
      </mat-form-field>
    </div>
    <div class="three-column">
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>City</mat-label>
        <input matInput [(ngModel)]="customerAddress.city" />
      </mat-form-field>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Region</mat-label>
        <input matInput [(ngModel)]="customerAddress.region" />
      </mat-form-field>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Postal Code</mat-label>
        <input matInput [(ngModel)]="customerAddress.postalCode" />
      </mat-form-field>
    </div>
    <div class="two-column">
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Country</mat-label>
        <input matInput [(ngModel)]="customerAddress.country" />
      </mat-form-field>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput [(ngModel)]="customerAddress.phone" />
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px"
>
  <button
    class="btn btn-default"
    mat-dialog-close
    style="font-size: 12px; padding: 5px; height: 40px; color: white"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    style="font-size: 12px; padding: 5px; height: 40px"
    [disabled]="isSaving || !isFormValid"
    (click)="onSaveAddress()"
  >
    Save
  </button>
</mat-dialog-actions>
