import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { OrderDetailAlt } from 'src/app/components/riva-chain/riva-chain-finish/models';
import { FINDINGS_TYPE } from 'src/app/components/riva-findings/constants';
import { Finding } from 'src/app/components/riva-findings/model';
import { OrdersDetails } from 'src/app/models/orders.model';
import { ChainService } from 'src/app/services/chain.service';
import { FindingsService } from 'src/app/services/findings.service';

const getCurrentIndex = (type: number) => {
  switch (type) {
    case FINDINGS_TYPE.JUMPRINGS:
      return 0;
    case FINDINGS_TYPE.POST:
      return 1;
    case FINDINGS_TYPE.FLYER:
      return 2;
    case FINDINGS_TYPE.SPRING:
      return 3;
    case FINDINGS_TYPE.SPRING_RING:
      return 4;
    case FINDINGS_TYPE.LOBSTER_CLAW:
      return 5;
    case FINDINGS_TYPE.TAGS:
      return 6;
    default:
      return 0;
  }
};
@Component({
  selector: 'riva-findings-order-add',
  templateUrl: './riva-findings-order-add.component.html',
  styleUrls: ['./riva-findings-order-add.component.scss'],
})
export class RivaFindingOrderAddComponent implements OnInit {
  @Input() orderDetail: Partial<OrdersDetails>;
  @Input() isEditable: boolean = true;
  @Input() isOrderOnline: boolean = false;
  @Output() onCancel = new EventEmitter();
  @Output() onSuccess = new EventEmitter();

  findings: Finding[];
  selectedTabIndex = 0;
  isSaving = false;
  findingsType = FINDINGS_TYPE;
  jumpringColumns = {
    size_1: 'Wire Diameter',
    jumpringSize: 'ID',
  };
  postColumns = {
    size_1: 'Length',
    size_2: 'Thickness',
  };
  flyerColumns = {
    size_1: 'Size',
  };
  springColumns = {
    size_1: 'Spring height',
    size_2: 'Wire Thickness',
    size_3: 'Spring Angle',
    size_4: 'Hole Inside Diameter',
  };
  springRingColumns = {
    name: 'Name',
    size_1: 'Size',
  };
  lobsterClawColumns = {
    name: 'Name',
    size_1: 'Size',
  };
  tagColumns = {
    name: 'Name',
    customerName: 'Customer',
    size_1: 'Lengths',
    size_2: 'Width',
    size_3: 'Thickness',
  };
  constructor(
    private findingsService: FindingsService,
    private chainService: ChainService,
  ) {}

  ngOnInit(): void {
    this.findingsService.getFindingList().subscribe((data) => {
      this.findings = data ?? [];
    });
    this.selectedTabIndex = getCurrentIndex(this.orderDetail.findingsTypesID);
  }

  onSaveOrder() {
    const order: OrderDetailAlt = {
      orderDetailsAltID: this.orderDetail.ordersDetailsId ?? 0,
      ordersID: this.orderDetail.ordersId,
      chain_FinishedID: 0,
      chain_FinishedSizesID: 0,
      chain_RawID: 0,
      chain_RawSizesID: 0,
      materialCodeID: this.orderDetail.materialCodeID,
      qtyOrdered: this.orderDetail.qtyordered,
      dueDate: this.orderDetail.dueDate
        ? DateTime.fromJSDate(new Date(this.orderDetail.dueDate)).toFormat(
            'yyyy-MM-dd',
          )
        : null,
      comment: this.orderDetail.comment,
      cipo: this.orderDetail.cipo,
      customized: this.orderDetail.customized,
      chainRawLength: 0,
      findingsID: this.orderDetail.findingsID,
      findingsSizesID: this.orderDetail.findingsSizesID,
      findingsTypesID: this.orderDetail.findingsTypesID,
    };
    this.isSaving = true;
    this.chainService.setChainOrderDetail(order).subscribe(() => {
      this.isSaving = true;
      this.onSuccess.emit();
    });
  }

  getColumns(typeId) {
    switch (typeId) {
      case FINDINGS_TYPE.JUMPRINGS:
        return this.jumpringColumns;
      case FINDINGS_TYPE.POST:
        return this.postColumns;
      case FINDINGS_TYPE.FLYER:
        return this.flyerColumns;
      case FINDINGS_TYPE.SPRING:
        return this.springColumns;
      case FINDINGS_TYPE.SPRING_RING:
        return this.springRingColumns;
      case FINDINGS_TYPE.LOBSTER_CLAW:
        return this.lobsterClawColumns;
      case FINDINGS_TYPE.TAGS:
        return this.tagColumns;
      default:
        return this.jumpringColumns;
    }
  }
}
