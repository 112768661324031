import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerAddress } from 'src/app/models/customer';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

const formatAddress = (address: CustomerAddress) => {
  const address1 = address.address1 ? `${address.address1} ` : '';
  const address2 = address.address2 ? `${address.address2} ` : '';
  const city = address.city ? `${address.city}, ` : '';
  const region = address.region ? `${address.region} ` : '';
  const postalCode = address.postalCode ? `${address.postalCode}, ` : '';
  const country = address.country ? `${address.country}` : '';

  return `${address1}${address2}${city}${region}${postalCode}${country}`;
};

@Injectable({
  providedIn: 'root',
})
export class CustomerAddressService extends GenericService {
  baseUrl = `${environment.apiUrl}customer-address`;

  constructor(http: HttpClient) {
    super(http);
  }

  setCustomerAddress(address: CustomerAddress) {
    return this.http.post<CustomerAddress>(this.baseUrl, address, {
      headers: this.headers,
    });
  }

  setCustomerAddresses(custIdNo: number, addresses: CustomerAddress[]) {
    const request = addresses.reduce((requests, address) => {
      address.custIdno = custIdNo;
      return [...requests, this.setCustomerAddress(address)];
    }, []);
    return forkJoin(request);
  }

  setAddressDefault(data) {
    return this.http.put(`${this.baseUrl}/default`, data, {
      headers: this.headers,
    });
  }

  getCustomerAddress(customerId) {
    return this.http
      .get<CustomerAddress[]>(this.baseUrl, {
        headers: this.headers,
        params: {
          customerId,
        },
      })
      .pipe(
        map((addresses) =>
          addresses.map((address) => ({
            ...address,
            fullAddress: formatAddress(address),
          })),
        ),
      );
  }
}
