import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ProductLookup,
  WorkOrderMovementEmployee,
  WorkOrderMovementEmployeeUI,
} from '../components/reporting/work-order-movement/model';
import {
  WorkOrderMovementActivitySummary,
  WorkOrderMovementProductActivity,
  WorkOrderMovementReport,
} from '../components/reporting/work-order-movement/work-order-moved-report';
import {
  EmployeeDownTime,
  WorkOrderMovementHistory,
} from '../components/work-order-tracking/model';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderMovementService extends GenericService {
  baseUrl = environment.apiUrl + 'work-order-movement';
  pdMovementUrl = environment.apiUrl + 'pd-work-movement';
  dailyMetricsUrl = environment.apiUrl + 'daily-metrics';

  constructor(http: HttpClient) {
    super(http);
  }
  getWorkOrderMovement(workOrderId) {
    return this.http.get<WorkOrderMovementHistory[]>(
      `${this.baseUrl}/${workOrderId}`,
      {
        headers: this.headers,
      },
    );
  }
  setWorkOrderMovement(data) {
    return this.http.post(this.baseUrl, data, {
      headers: this.headers,
    });
  }
  getWorkOrderMovementInProgressByUser(userId) {
    return this.http.get<number>(`${this.baseUrl}/inprogress/${userId}`, {
      headers: this.headers,
    });
  }
  getWorkOrderMovementReportDateFilter(params) {
    return this.http.get<WorkOrderMovementReport[]>(
      `${this.baseUrl}/report-date-filter`,
      {
        headers: this.headers,
        params,
      },
    );
  }
  getWorkOrderMovementReportSearch(productsId: number) {
    return this.http.get<WorkOrderMovementReport[]>(
      `${this.baseUrl}/report-search`,
      {
        headers: this.headers,
        params: {
          productsId,
        },
      },
    );
  }
  getWorkOrderMovementProductActivity(productId: number, type: number) {
    return this.http.get<WorkOrderMovementProductActivity[]>(
      `${this.baseUrl}/product-activity`,
      {
        headers: this.headers,
        params: {
          productId,
          type,
        },
      },
    );
  }
  getWorkOrderMovementActivitySummary(
    routingCodesId: number,
    productId: number,
    type: number,
  ) {
    return this.http.get<WorkOrderMovementActivitySummary[]>(
      `${this.baseUrl}/activity-summary`,
      {
        headers: this.headers,
        params: {
          routingCodesId,
          productId,
          type,
        },
      },
    );
  }
  getWorkOrderMovementActivitySummaryMedian(
    routingCodesId: number,
    productId: number,
    type: number,
  ) {
    return this.http.get<number>(`${this.baseUrl}/activity-summary-median`, {
      headers: this.headers,
      params: {
        routingCodesId,
        productId,
        type,
      },
    });
  }

  getProductLookup() {
    return this.http.get<ProductLookup[]>(`${this.baseUrl}/lookup-product`, {
      headers: this.headers,
    });
  }
  getWorkOrderMovementReportIncomplete() {
    return this.http.get<WorkOrderMovementReport[]>(
      `${this.baseUrl}/report-incomplete`,
      {
        headers: this.headers,
      },
    );
  }
  getWorkOrderMovementReportByUserV1(
    userId: number,
  ): Observable<WorkOrderMovementEmployeeUI[]> {
    return this.http
      .get<WorkOrderMovementEmployee[]>(
        `${this.baseUrl}/report-by-user/${userId}`,
        {
          headers: this.headers,
        },
      )
      .pipe(
        map((data) =>
          data.reduce((accum, d) => {
            return [
              ...accum,
              ...d.movementsHistory?.map((h) => ({
                workOrdersId: d.workOrdersId,
                productName: d.productName,
                picturePath: d.picturePath,
                scanOutTime: h.scanOutTime,
                scanInTime: h.scanInTime,
                scanOutTimeRaw: h.scanOutTimeRaw,
                scanInTimeRaw: h.scanInTimeRaw,
                differenceInMinutes: h.differenceInMinutes,
                activityCode: h.routingCode.activityCode,
                activityDesc: h.routingCode.activityDesc,
              })),
            ];
          }, []),
        ),
      );
  }
  getWorkOrderMovementReportByUser(
    userId: number,
  ): Observable<WorkOrderMovementEmployeeUI[]> {
    return this.http.get<WorkOrderMovementEmployeeUI[]>(
      `${this.baseUrl}/v2/report-by-user/${userId}`,
      {
        headers: this.headers,
      },
    );
  }

  getEmployeeDownTime(usersId) {
    return this.http.get<EmployeeDownTime[]>(
      `${this.baseUrl}/employee-downtime`,
      {
        headers: this.headers,
        params: {
          usersId,
        },
      },
    );
  }

  getPdWorkOrderMovement(jiraId) {
    return this.http.get<WorkOrderMovementHistory[]>(`${this.pdMovementUrl}/${jiraId}`, {
      headers: this.headers,
    });
  }
  setPdWorkOrderMovement(data) {
    return this.http.post(this.pdMovementUrl, data, {
      headers: this.headers,
    });
  }
  getEmployeeDownTimeReport(usersid: number[], start: string, end: string) {
    return this.http
      .get(`${this.dailyMetricsUrl}/employee-downtime`, {
        headers: this.headers,
        params: { usersid, start, end },
        responseType: 'arraybuffer',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            const decodedError = new TextDecoder('utf-8').decode(error.error);
            let employees = [];
            try {
              employees = JSON.parse(decodedError);
            } catch (e) {
              employees = [];
            }
            return throwError(employees);
          }
          return throwError([]);
        }),
      );
  }

  getDailyMetricsSummaryReport(start: string, end: string) {
    return this.http
      .get(`${this.dailyMetricsUrl}/summary`, {
        headers: this.headers,
        params: { start, end },
        responseType: 'arraybuffer',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            const decodedError = new TextDecoder('utf-8').decode(error.error);
            let data = {};
            try {
              data = JSON.parse(decodedError);
            } catch (e) {
              data = {};
            }
            return throwError(data);
          }
          return throwError([]);
        }),
      );
  }
  deleteWorkOrderMovement(id) {
    return this.http.delete(`${this.baseUrl}/${id}`, {
      headers: this.headers,
    });
  }
}
