import { StoneSize } from './riva-gems.model';

export const getDisplayText = ({
  dimension_1,
  dimension_2,
  dimension_3,
}: StoneSize) => {
  const dimensions = [];
  if (dimension_1) dimensions.push(`${dimension_1}mm`);
  if (dimension_2) dimensions.push(`${dimension_2}mm`);
  if (dimension_3) dimensions.push(`${dimension_3}mm`);
  return dimensions.join(' x ');
};

export const setStoneMapper = ({
  stonesID,
  stoneTypesID,
  stoneCutsID,
  stoneClaritiesID,
  stoneSizesID,
  stoneColorsID,
  caratsPerUnit,
  supplierID,
  stockQTY,
  pricePerUnit,
  labGrown,
  traceable,
}) => ({
  stonesID,
  stoneTypesID,
  stoneCutsID,
  stoneClaritiesID,
  stoneSizesID,
  stoneColorsID,
  caratsPerUnit,
  stockQTY,
  pricePerUnit,
  suppliersID: supplierID,
  labGrown,
  traceable,
});

export const setStoneSizeMapper = ({
  stoneSizesID,
  dimension_1,
  dimension_2,
  dimension_3,
}) => ({
  stoneSizesID,
  dimension_1,
  dimension_2,
  dimension_3,
});
