<div class="stone-image-container">
  <mat-dialog-content class="mat-typography">
    <div class="stone-image-grid">
      <div style="position: relative">
        <div class="image-container">
          <div *ngIf="!currentImage">No Image</div>
          <img
            *ngIf="currentImage"
            style="max-width: 100%; max-height: 200px"
            [src]="currentImage"
          />
        </div>

        <button
          class="riva-button riva-button-sm btn-upload"
          (click)="uploadImage.click()"
          [disabled]="!isValid"
        >
          Upload
        </button>
        <input
          type="file"
          #uploadImage
          (change)="onUploadChange(uploadImage.files)"
          style="display: none"
          accept="image/*"
        />
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Type</mat-label>
          <mat-select
            [(ngModel)]="stoneDefaultImageConfig.stoneTypesID"
            (ngModelChange)="onGetImage()"
          >
            <mat-option
              *ngFor="let type of stoneTypes"
              [value]="type.stoneTypesID"
            >
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Cut</mat-label>
          <mat-select
            [(ngModel)]="stoneDefaultImageConfig.stoneCutsID"
            (ngModelChange)="onGetImage()"
          >
            <mat-option *ngFor="let cut of stoneCuts" [value]="cut.stoneCutsID">
              {{ cut.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Color</mat-label>
          <mat-select
            [(ngModel)]="stoneDefaultImageConfig.stoneColorsID"
            (ngModelChange)="onGetImage()"
          >
            <mat-option [value]="">None</mat-option>
            <mat-option
              *ngFor="let color of stoneColors"
              [value]="color.stoneColorsID"
            >
              {{ color.colorName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <!-- <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button> -->
      <button
        class="btn btn-primary"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px"
      >
        Close
      </button>
    </div>
  </mat-dialog-actions>
</div>
