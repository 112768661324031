<div class="bom-findings-jumpring">
  <div>
    <div class="image-wrapper">
      <div class="image-container">
        <img
          [src]="selectedJumpring.picPath"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
    </div>
  </div>
  <div class="findings-jumpring-table-container">
    <table
      mat-table
      matSort
      [dataSource]="jumprings"
      matSortActive="findingSizesID"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="picPath">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div
            style="
              width: 70px;
              padding: 5px;
              display: flex;
              justify-content: center;
            "
            *ngIf="element.picPath"
          >
            <img
              style="max-height: 37px; max-width: 60px"
              [src]="element.picPath"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="size_1">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by size_1"
        >
          Wire Diameter
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_1 }}<span *ngIf="element.size_1">mm</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="jumpringSize">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by jumpringSize"
        >
          ID
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.jumpringSize }}
        </td>
      </ng-container>
      <ng-container matColumnDef="size_2">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by size_2"
        >
          ID 1
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_2 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="size_3">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by size_3"
        >
          ID 2
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size_3 }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="jumpring-row"
        (click)="onTableRowClick(row)"
        [ngClass]="{
          'is-selected': row.findingsId === selectedJumpring?.findingsId
        }"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="4">No Available Data</td>
      </tr>
    </table>
  </div>
  <div class="findings-jumpring-detail">
    <div class="other-fields">
      <!-- <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Material Code</mat-label>
          <mat-select
            [(ngModel)]="selectedStock.materialsCodeID"
            (ngModelChange)="onChangeMaterialCode()"
          >
            <mat-option
              *ngFor="let mats of materialCodes"
              [value]="mats.materialCodeId"
            >
              {{ mats.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row-1">
        <mat-form-field appearance="fill">
          <mat-label>QTY in Stock</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="selectedStock.qtyInStock"
            [disabled]="true"
            style="color: white"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Weight (g)</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="selectedStock.weight_G"
            [disabled]="true"
            max="99.99"
            style="color: white"
          />
        </mat-form-field>
      </div> -->
      <div class="row-1" style="align-items: flex-end">
        <mat-form-field appearance="fill">
          <mat-label>Qty</mat-label>
          <input
            matInput
            type="number"
            style="color: white"
            min="1"
            [(ngModel)]="selectedJumpring.qty"
          />
        </mat-form-field>
        <div>
          <div class="override-material">
            <mat-slide-toggle
              [(ngModel)]="isOverride"
              (ngModelChange)="isOverrideChange()"
              style="color: white"
              >Override Material</mat-slide-toggle
            >
          </div>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Material Code</mat-label>
            <mat-select
              [(ngModel)]="selectedJumpring.findingsMaterialsID"
              [disabled]="!isOverride"
            >
              <mat-option
                *ngFor="let mat of materialCodes"
                [value]="mat.materialCodeId"
              >
                {{ mat.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Comment</mat-label>
          <textarea
            matInput
            style="height: 42px; color: white"
            [(ngModel)]="selectedJumpring.comment"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
