<mat-dialog-content
  class="invoice-report invoice-report__container mat-typography"
>
  <button class="close-button" mat-dialog-close>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-x"
      width="20"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#ffffff"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  </button>
  <div class="invoice-report__canvas">
    <div class="invoice-report__canvas-header">
      <div class="invoice-report__canvas-header-title"></div>
    </div>

    <div id="print-section">
      <div
        class="print-canvas-container"
        *ngFor="let pageItems of invoicePages; let pageNumber = index"
      >
        <div class="print-page short-pager">
          <div class="invoice-header">
            <div
              class="invoice-address-container"
              [ngClass]="{ 'is-header': pageNumber === 0 }"
            >
              <ng-container *ngIf="pageNumber === 0">
                <div class="address-container">
                  <div class="invoice-address">
                    <div>Riva Precision Manufacturing</div>
                    <div>140 58th ST. Unit 8B</div>
                    <div>BROOKLYN, NY 11220</div>
                    <div>(718) 361-3100</div>
                    <div>Accounting@rivaprecision.com</div>
                  </div>
                  <div class="invoice-address">
                    <div style="font-weight: bold">BILL TO</div>
                    <div>{{ props.detail.customer.companyName }}</div>
                    <div *ngIf="props.detail.billingAddress?.address1">
                      {{ props.detail.billingAddress.address1 }}
                    </div>
                    <div *ngIf="props.detail.billingAddress?.address2">
                      {{ props.detail.billingAddress.address2 }}
                    </div>
                    <div
                      *ngIf="
                        props.detail.billingAddress?.city ||
                        props.detail.billingAddress?.region ||
                        props.detail.billingAddress?.postalCode
                      "
                    >
                      <span *ngIf="props.detail.billingAddress.city"
                        >{{ props.detail.billingAddress.city }},</span
                      >
                      <span *ngIf="props.detail.billingAddress.region"
                        >{{ props.detail.billingAddress.region }}
                      </span>
                      <span *ngIf="props.detail.billingAddress.postalCode">{{
                        props.detail.billingAddress.postalCode
                      }}</span>
                    </div>
                    <div *ngIf="props.detail.billingAddress?.country">
                      {{ props.detail.billingAddress.country }}
                    </div>
                  </div>
                </div>
                <div
                  class="metal-market"
                  *ngIf="props.detail.metalMarket?.metalMarketID"
                >
                  <table>
                    <tr style="border-top: 1px solid #68516d; padding-top: 8px">
                      <th
                        colspan="2"
                        style="padding-bottom: 8px; font-size: 16px"
                      >
                        London PM Fix
                      </th>
                    </tr>
                    <tr>
                      <th style="padding-bottom: 8px">Date</th>
                      <td style="padding-bottom: 8px">
                        {{
                          props.detail.metalMarket?.marketDate
                            | date : 'MM/dd/yyyy'
                        }}
                      </td>
                    </tr>
                    <tr class="tr-metal">
                      <th>Silver</th>
                      <td>
                        {{
                          props.detail.metalMarket?.ldpmSilver ?? 0 | currency
                        }}
                      </td>
                    </tr>
                    <tr class="tr-metal">
                      <th>Gold</th>
                      <td>
                        {{ props.detail.metalMarket?.ldpmGold ?? 0 | currency }}
                      </td>
                    </tr>
                    <tr class="tr-metal">
                      <th>Platinum</th>
                      <td>
                        {{
                          props.detail.metalMarket?.ldpmPlatinum ?? 0 | currency
                        }}
                      </td>
                    </tr>
                    <tr class="tr-metal">
                      <th>Palladium</th>
                      <td>
                        {{
                          props.detail.metalMarket?.ldpmPalladium ?? 0
                            | currency
                        }}
                      </td>
                    </tr>
                  </table>
                </div>
              </ng-container>
            </div>
            <div class="invoice-detail-box-container">
              <div class="invoice-riva-logo">
                <img src="assets/images/riva-logo.png" />
              </div>
              <ng-container *ngIf="pageNumber === 0">
                <div class="invoice-detail-box">
                  INVOICE
                  {{ props.detail.invoicesID | number : '6.0-0' | noComma }}
                </div>
                <div class="invoice-detail-box sub-detail">
                  DATE {{ props.detail.invoicedDate | date : 'MM/dd/yyyy' }}
                </div>
                <div class="invoice-detail-box"></div>
              </ng-container>
            </div>
          </div>
          <div class="invoice-print-detail" *ngIf="pageNumber === 0">
            <div class="po-number">
              <div style="font-size: 14px">P.O. NUMBER</div>
              <div style="font-weight: bold; margin-top: 5px">
                {{ props.detail.poNumber.join(', ') }}
              </div>
            </div>
          </div>

          <table mat-table [dataSource]="pageItems" style="width: 100%">
            <ng-container matColumnDef="productName">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.productName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="poNumber">
              <th mat-header-cell *matHeaderCellDef>PO Number</th>
              <td mat-cell *matCellDef="let element">
                {{ element.poNumber }}
              </td>
            </ng-container>
            <ng-container matColumnDef="cipo">
              <th mat-header-cell *matHeaderCellDef>CIPO</th>
              <td mat-cell *matCellDef="let element">
                {{ element.cipo }}
              </td>
            </ng-container>
            <ng-container matColumnDef="uom">
              <th mat-header-cell *matHeaderCellDef>UOM</th>
              <td mat-cell *matCellDef="let element">
                {{ element.uom }}
              </td>
            </ng-container>
            <ng-container matColumnDef="material">
              <th mat-header-cell *matHeaderCellDef>Metal</th>
              <td mat-cell *matCellDef="let element">
                {{ element.material }}
              </td>
            </ng-container>
            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef>Size</th>
              <td mat-cell *matCellDef="let element">
                {{ element.size }}
              </td>
            </ng-container>
            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef>Weight</th>
              <td mat-cell *matCellDef="let element">
                {{ element.weight || '' }}<span *ngIf="element.weight">g</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="stoneType">
              <th mat-header-cell *matHeaderCellDef>Stone</th>
              <td mat-cell *matCellDef="let element">
                {{ element.stoneType }}
              </td>
            </ng-container>
            <ng-container matColumnDef="stoneColor">
              <th mat-header-cell *matHeaderCellDef>Stone Color</th>
              <td mat-cell *matCellDef="let element">
                {{ element.stoneColor }}
              </td>
            </ng-container>
            <ng-container matColumnDef="stoneSize">
              <th mat-header-cell *matHeaderCellDef>Stone Size</th>
              <td mat-cell *matCellDef="let element">
                {{ element.stoneSize }}
              </td>
            </ng-container>
            <ng-container matColumnDef="enamelName">
              <th mat-header-cell *matHeaderCellDef>Enamel Color</th>
              <td mat-cell *matCellDef="let element">
                <div style="display: flex; align-items: center">
                  <div
                    style="
                      width: 30px;
                      height: 20px;
                      border: 1px solid black;
                      background-color: rgb(255, 255, 255);
                      margin-right: 10px;
                    "
                    [ngStyle]="{
                      'background-color': element.enamelColor
                    }"
                  ></div>
                  <div>{{ element.enamelName }}</div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="qty">
              <th mat-header-cell *matHeaderCellDef>QTY</th>
              <td mat-cell *matCellDef="let element">
                {{ element.qty }}
              </td>
            </ng-container>
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>Price</th>
              <td mat-cell *matCellDef="let element">
                {{ element.price | currency }}
              </td>
            </ng-container>
            <ng-container matColumnDef="priceExtension">
              <th mat-header-cell *matHeaderCellDef>Price EXT</th>
              <td mat-cell *matCellDef="let element">
                {{ element.priceExtension | currency }}
              </td>
            </ng-container>
            <tr
              class="invoice-print-row"
              mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              class="invoice-print-row"
              mat-row
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell text-center" colspan="14">
                No Available Data
              </td>
            </tr>
          </table>
          <div
            class="invoice-comment"
            *ngIf="pageNumber === 0 && props.detail.externalComment"
          >
            {{ props.detail.externalComment }}
          </div>
          <div
            class="invoice-detail-footer"
            *ngIf="pageNumber + 1 === invoicePages.length"
          >
            <div class="cost-box">
              <div
                class="invoice-detail-shipping-box"
                *ngIf="props.detail.shippingCost"
              >
                <div>Shipping Cost</div>
                <div style="padding-right: 30px">
                  {{ props.detail.shippingCost | currency }}
                </div>
              </div>
              <div class="invoice-detail-box">
                <div>TOTAL DUE</div>
                <div class="total-due">{{ totalPriceExt | currency }}</div>
              </div>
            </div>
          </div>
          <div
            class="page-footer"
            [ngClass]="{ 'short-page-footer': isShortPage }"
          >
            <span class="page-number"
              >{{ pageNumber + 1 }} / {{ invoicePages.length }}</span
            >
            Our Mission is to honor God through our Service, Process, and
            Product Excellence.
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
