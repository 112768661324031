<div
  class="riva-text-avatar"
  [ngStyle]="{
    'background-color': randomColor,
    'width.px': size,
    'height.px': size
  }"
>
  {{ initial }}
</div>
