import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { Product, ProductSizes } from 'src/app/models/product';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';
import {
  ProductLinkBom,
  ProductLinkBomGroupByProduct,
  ProductSizeBom,
} from '../product-link-bom';

@Component({
  selector: 'product-link-bom-form',
  templateUrl: './product-link-bom-form.component.html',
  styleUrls: ['./product-link-bom-form.component.scss'],
})
export class ProductLinkBomFormComponent implements OnInit, OnChanges {
  @Input() productId: number;

  products: Product[] = [];
  productSizes: ProductSizes[] = [];
  productBomSizes: ProductSizeBom[] = [];
  product: Product = new Product();
  productControl = new FormControl();
  filteredProducts: Observable<Product[]>;
  productLinksBom = new MatTableDataSource<ProductLinkBomGroupByProduct>([]);

  displayedColumns = ['picPath', 'productName', 'delete'];
  displayedSizesColumns = ['size', 'productsSizesID_Primary', 'qty'];

  productLinkBom: ProductLinkBom = {} as ProductLinkBom;
  isSaving = false;

  constructor(
    private productBomService: ProductBomService,
    private productService: ProductService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.productService.getList().subscribe((products) => {
      this.products = products;
      this.initiateFilter();
    });
  }
  ngOnChanges(): void {
    if (this.productId) {
      this.productLinkBom.productsID_Primary = this.productId;
      this.productService
        .getProduct(this.productId)
        .subscribe(({ responseObject }) => {
          this.productSizes = responseObject.productSizes;
          if (this.productSizes.length === 1) {
            this.productLinkBom.productsSizesID_Primary =
              this.productSizes[0]?.productsSizesID;
          }
        });
      // this.getProductLinkBom(this.productId);
    }
  }

  // getProductLinkBom(productId) {
  //   if (productId) {
  //     this.productBomService.getProductLinkBom(productId).subscribe((data) => {
  //       const groupByProduct = groupBy(data, 'productsID_BOM');

  //       this.productLinksBom.data = Object.keys(groupByProduct).reduce(
  //         (accum, key) => {
  //           const productItems = groupByProduct[key];
  //           const [item] = productItems;
  //           return [
  //             ...accum,
  //             {
  //               productsID_BOM: item.productsID_BOM,
  //               productName: item.productName,
  //               picPath: item.picPath,
  //               sizes: productItems,
  //             },
  //           ];
  //         },
  //         [],
  //       );
  //     });
  //   }
  // }

  initiateFilter() {
    this.filteredProducts = this.productControl.valueChanges.pipe(
      startWith(this.product?.productName ?? ''),
      map((value) => this._filterProduct(value)),
    );
  }

  private _filterProduct(name: string): Product[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.products.filter(
        (option) =>
          option.productName.toLowerCase().includes(filterValue) ||
          option.productDesc.toLowerCase().includes(filterValue) ||
          option.sku.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['productName'];
      return this.products.filter(
        (option) =>
          option.productName
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          option.productDesc
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          option.sku.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.products;
  }

  onProductChange() {
    this.productLinkBom.productsSizesID_BOM = 0;
    if (this.product.productsId == null) return;
    this.productLinkBom.productsID_BOM = this.product.productsId;
    this.productService
      .getProduct(this.productLinkBom.productsID_BOM)
      .subscribe(({ responseObject }) => {
        this.productBomSizes = responseObject.productSizes;
        if (this.productBomSizes.length === 1) {
          this.productLinkBom.productsSizesID_BOM =
            this.productBomSizes[0]?.productsSizesID;
        }
      });
  }
  productChange(event) {}

  displayFn(item: Product): string {
    return item && item.productName ? item.productName : '';
  }

  addProductBom() {
    this.isSaving = true;
  }

  onSave() {
    this.isSaving = true;
    const request = this.productBomSizes.reduce((accum, size) => {
      if (size.productsSizesID_Primary == null || (size.qty ?? 0) === 0) return accum;
      return [
        ...accum,
        this.productBomService.setProductLinkBom({
          productsBOMProductsID: 0,
          productsID_Primary: this.productId,
          productsID_BOM: size.productsID,
          productsSizesID_Primary: size.productsSizesID_Primary,
          productsSizesID_BOM: size.productsSizesID,
          qty: size.qty,
        }),
      ];
    }, []);
    forkJoin(request).subscribe(
      () => {
        this.isSaving = false;
        this.productBomSizes = [];
        this.product = null;
        this.productBomService.reloadProductLinkBomList(this.productId);
        this.initiateFilter();
      },
      () => {
        this.isSaving = false;
      },
    );
  }

  onDeleteProductLink(group: ProductLinkBomGroupByProduct) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product BOM',
        content: 'Are you sure you want to delete this product?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        const request = group.sizes.map((s) =>
          this.productBomService.deleteProductLinkBom(s.productsBOMProductsID),
        );

        forkJoin(request).subscribe(() => {
          this.productBomService.reloadProductLinkBomList(this.productId);
        });
      });
  }

  get isFormValid() {
    const bomSizes = this.productBomSizes.filter((s) => s.productsSizesID_Primary != null || s.qty > 0);
    if (bomSizes.length === 0) return false;
    return bomSizes.every(
      (s) => s.productsSizesID_Primary != null && s.qty > 0,
    );
  }
}
