import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { alphabet } from 'src/app/utilities/alphabet-characters';
import { ShipmentLookupItem } from '../../models/shipment-lookup-item';

@Component({
  selector: 'shipping-detail-dialog',
  templateUrl: './shipping-detail-dialog.component.html',
  styleUrls: ['./shipping-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShippingDetailDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'selection',
    'index',
    'productPicPath',
    'productName',
    'material',
    'size',
    'qty',
    'cipo',
  ];
  shipmentItems: ShipmentLookupItem[] = [];
  isSelectedAll = true;
  isSomeSelected = false;

  constructor(
    public dialogRef: MatDialogRef<ShippingDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { items: ShipmentLookupItem[] },
  ) {}

  ngOnInit(): void {
    this.shipmentItems = this.props.items.map((item, index) => ({
      ...item,
      maxQty: item.qty,
      index: alphabet[index] ?? `A${alphabet[index - 25]}`,
    }));
    this.selectAll(true);
  }

  onSelect() {
    const selected = this.shipmentItems.filter((s) => s.selected);
    this.isSelectedAll = selected.length === this.shipmentItems.length;
    this.isSomeSelected = selected.length > 0 && !this.isSelectedAll;
  }

  selectAll(checked) {
    this.shipmentItems = this.shipmentItems.map((s) => ({
      ...s,
      selected: s.isClosed ? checked : false,
    }));
    this.onSelect();
  }

  onAddItem() {
    this.dialogRef.close(this.shipmentItems.filter((s) => s.selected));
  }
}
