import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ProductService } from 'src/app/services/product.service';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { OtherComponentsService } from 'src/app/services/other-components.service';
import { OtherComponent, OtherComponentRouting } from '../model';
import { OtherComponentsRoutingComponent } from './other-components-routing.component';

@Component({
  selector: 'other-components-routing-copy-dialog',
  templateUrl: './other-components-routing-copy-dialog.component.html',
  styleUrls: ['./other-components-routing-copy-dialog.component.scss'],
})
export class OtherComponentsRoutingCopyDialogComponent implements OnInit {
  @ViewChild(OtherComponentsRoutingComponent)
  productRoutingComponent: OtherComponentsRoutingComponent;

  otherComponentsId: number = 0;
  routings: OtherComponentRouting[];
  otherComponents: OtherComponent[] = [];
  otherComponent: OtherComponent = {} as OtherComponent;
  filteredOtherComponents: Observable<OtherComponent[]>;

  otherComponentControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<OtherComponentsRoutingCopyDialogComponent>,
    public loadService: LoadService,
    public productService: ProductService,
    public otherComponentsService: OtherComponentsService,
  ) {}

  ngOnInit(): void {
    this.getOtherComponents();
  }
  initializeFilter() {
    this.filteredOtherComponents = this.otherComponentControl.valueChanges.pipe(
      startWith(this.otherComponent.longName),
      map((value) => this._filterOtherComponent(value)),
    );
  }
  getOtherComponents() {
    this.otherComponentsService.getOtherComponents().subscribe((data) => {
      this.otherComponents = data;
      this.initializeFilter();
    });
  }

  private _filterOtherComponent(name: string): OtherComponent[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.otherComponents.filter((option) =>
        option.longName.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['longName'];
      return this.otherComponents.filter((option) =>
        option.longName.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.otherComponents;
  }

  displayFn(item: OtherComponent): string {
    return item?.longName ?? '';
  }

  onRoutingChange($events) {
    this.routings = $events;
  }

  onConfirm() {
    this.dialogRef.close(this.routings);
  }
}
