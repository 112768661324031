<div class="accounts-table-user">
  <div class="accounts-table-user__avatar">
    <riva-text-avatar [text]="account.fullName"></riva-text-avatar>
  </div>
  <div class="accounts-table-user__info">
    <div class="accounts-table-user__fullname">
      {{ account.fullName }}
    </div>
    <div class="accounts-table-user__username">
      @{{ account.userName?.toLowerCase() || account.firstName?.toLowerCase() }}
    </div>
  </div>
</div>
