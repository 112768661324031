import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CustomerAddressDialogComponent } from 'src/app/components/customer-page/customer-address-dialog/customer-address-dialog.component';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { WorkOrderType } from 'src/app/components/work-order-view/work-order-type';
import { INVOICING_SHIPPING_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/invoicing-shipping-permission';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { CustomerAddress } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { ShipmentService } from 'src/app/services/shipments.service';
import { WorkOrdersReleaseComponent } from '../../../work-order-view/work-orders-release.component';
import { InvoiceDetail } from '../../invoicing/models/invoice';
import { Shipment, ShipmentDetail } from '../models/shipment';
import { ShipmentLookupItem } from '../models/shipment-lookup-item';
import { ShippingDetailDialogComponent } from './shipping-detail-dialog/shipping-detail-dialog.component';
import { ShippingRelatedInvoiceDialogComponent } from './shipping-related-invoice-dialog/shipping-related-invoice-dialog.component';

@Component({
  selector: 'shipping-detail',
  templateUrl: './shipping-detail.component.html',
  styleUrls: ['./shipping-detail.component.scss'],
})
export class ShippingDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmationDialog')
  public readonly confirmationDialog!: SwalComponent;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'productPicPath',
    'productName',
    'material',
    'size',
    'qty',
    'cipo',
    'action',
  ];
  confirmation: {
    icon: string;
    title: string;
    description: string;
    timer?: number;
  } = {
    icon: 'question',
    title: '',
    description: '',
  };
  shipmentDetail: Shipment = {
    shipDate: '',
    customer: {},
    shipmentsDetails: [],
  } as Shipment;
  shipmentDetails = new MatTableDataSource<ShipmentDetail>([]);
  shippingAddresses: CustomerAddress[] = [];

  generatedInvoiceDetails: Array<Partial<InvoiceDetail>> = [];
  featureKey = INVOICING_SHIPPING_FEATURE_KEY;
  workOrderType: WorkOrderType;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private shipmentService: ShipmentService,
    private customerService: CustomerService,
    private userPermissionService: UserPermissionService,
    private _confirmationService: ConfirmationService,
  ) {
    this.userPermissionService.checkPagePermission(
      PAGE_NAME.invoicingAndShipping,
    );
    this.route.params.subscribe((params: Params) => {
      this.shipmentDetail.shipmentsID = +params.shippingId ?? 0;
      if (this.shipmentDetail.shipmentsID === 0) {
        const generatedShipment = sessionStorage.getItem(
          'invoicing-generated-shipment',
        );
        const customerDetail = sessionStorage.getItem(
          'invoicing-generated-customer',
        );
        if (generatedShipment) {
          const data = JSON.parse(generatedShipment);
          this.shipmentDetails.data = data.map((item) => ({
            ...item,
            shipmentsDetailsAltID: item.orderDetailsAltID,
          }));

          sessionStorage.removeItem('invoicing-generated-shipment');
        }

        if (customerDetail) {
          const detail = JSON.parse(customerDetail);
          this.getCustomer(detail.customerId, detail.addressId);
          sessionStorage.removeItem('invoicing-generated-customer');
        }
        return;
      }

      this.shipmentService
        .getShipmentById(this.shipmentDetail.shipmentsID)
        .subscribe((shipment) => {
          this.shipmentDetail =
            shipment ??
            ({
              shipmentsID: 0,
              customer: {},
              shipmentsDetails: [],
            } as Shipment);
          this.shipmentDetails.data = [...this.shipmentDetail.shipmentsDetails];
          this.getCustomer(this.shipmentDetail.custIDNo);
        });

      this.shipmentService
        .generateInvoice(this.shipmentDetail.shipmentsID)
        .subscribe((data) => {
          this.generatedInvoiceDetails = data;
        });
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.shipmentDetails.sort = this.sort;
  }

  goToInvoicingAndShipping() {
    this.router.navigate(['invoicing-and-shipping/shipping']);
  }

  onScanWorkOrder() {
    const dialogRef = this.dialog.open(WorkOrdersReleaseComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      data: {
        workOrders: [],
        action: 'shipped',
      },
    });
    dialogRef.afterClosed().subscribe(({ code, workOrderType }) => {
      this.workOrderType = workOrderType;
      if (code) {
        this.shipmentService
          .getLookUpItems(code, workOrderType)
          .subscribe((items) => {
            const customerId =
              this.shipmentDetail.custIDNo ??
              this.shipmentDetails.data?.[0]?.customerId ??
              0;
            const hasOtherCustomer =
              customerId && items.some((i) => i.customerId !== customerId);
            if (hasOtherCustomer) {
              this.confirmation = {
                title: 'Invalid Customer',
                description: `Work order number ${code} is coming from a different customer.`,
                icon: 'error',
              };
              setTimeout(() => {
                this.confirmationDialog.fire();
              }, 100);
              return;
            }

            const mappedItems = items.reduce((accum, item) => {
              const invoicedItem = this.shipmentDetails.data.find(
                (i) => i.ordersDetailsID === item.ordersDetailsID,
              );
              item.isClosed = item.totalClosedQty > 0;
              if (invoicedItem == null) return [...accum, item];
              item.qty = item.qty - invoicedItem.qty;
              if (item.qty > 0) return [...accum, item];
              return accum;
            }, []);

            if (mappedItems.length === 0) {
              this.confirmation = {
                title: 'Work Order Items',
                description: `Work order number ${code} is already shipped.`,
                icon: 'error',
              };
              setTimeout(() => {
                this.confirmationDialog.fire();
              }, 100);
              return;
            }

            this.openWorkOrders(mappedItems);
          });
      }
    });
  }

  openWorkOrders(items: ShipmentLookupItem[]) {
    const dialogRef = this.dialog.open(ShippingDetailDialogComponent, {
      disableClose: true,
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
      data: {
        items,
      },
    });
    dialogRef.afterClosed().subscribe((selectedItems) => {
      if (!selectedItems) return;
      const [item] = selectedItems;
      this.getCustomer(item.customerId);
      this.shipmentDetails.data = this.shipmentDetails.data.map((invoice) => {
        const itemQty =
          selectedItems.find(
            (s) => s.ordersDetailsID === invoice.ordersDetailsID,
          )?.qty ?? 0;
        return {
          ...invoice,
          qty: itemQty + invoice.qty,
        };
      });
      this.shipmentDetails.data = selectedItems.reduce(
        (items, s) => {
          const isExist = items.some(
            (i) => i.ordersDetailsID === s.ordersDetailsID,
          );
          if (isExist) return items;
          return [
            ...items,
            {
              ...s,
              shipmentsDetailsAltID:
                this.workOrderType !== WorkOrderType.Product
                  ? s.ordersDetailsID
                  : 0,
            },
          ];
        },
        [...this.shipmentDetails.data],
      );
    });
  }

  getCustomer(customerId, defaultCustomersAddressesId = null) {
    this.customerService.getCustomer(customerId).subscribe((data) => {
      this.shipmentDetail.custIDNo = data.custIdno;
      this.shipmentDetail.customer = {
        id: data.custIdno,
        companyName: data.companyName,
      };
      this.shippingAddresses = (data.addresses ?? []).filter(
        (a) => a.addressType === 0 || a.addressType === 2,
      );
      const [firstAddress] = this.shippingAddresses;
      if (this.shipmentDetail.shipmentsID) return;
      this.shipmentDetail.shippingAddressID =
        defaultCustomersAddressesId ??
        data.defaultProdShipAddress ??
        firstAddress?.customersAddressesId ??
        0;
    });
  }

  onSubmitShipment() {
    const data = this.shipmentDetails.data.reduce((shipments, s) => {
      if (s.shipmentsID) return shipments;
      return [
        ...shipments,
        {
          orderDetailsId: s.shipmentsDetailsAltID ? 0 : s.ordersDetailsID,
          orderDetailsAltId: s.shipmentsDetailsAltID
            ? s.shipmentsDetailsAltID ?? s.ordersDetailsID
            : 0,
          qty: s.qty,
          invoicesDetailsChargesId: s.invoicesDetailsChargesId ?? 0,
        },
      ];
    }, []);

    const items = {
      customerId: this.shipmentDetail.custIDNo,
      shipmentsId: this.shipmentDetail.shipmentsID,
      tracking: this.shipmentDetail.tracking,
      comment: this.shipmentDetail.comment,
      shippingAddressID: this.shipmentDetail.shippingAddressID,
      lineItems: data,
    };

    this.shipmentService.setShipments(items).subscribe((data) => {
      this.router.navigate([`invoicing-and-shipping/shipping/${data}`]);
    });
  }

  get shipmentSubmitValid() {
    return (
      !this.shipmentDetail.shipmentsID &&
      this.shipmentDetails.data.some((s) => !s.shipmentsDetailsID)
    );
  }

  onRemoveShipment(shipment: ShipmentDetail) {
    this._confirmationService
      .showConfirmation({
        title: 'Remove order?',
        content: 'Are you sure you want to remove this item?',
        confirmLabel: 'Yes',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.shipmentDetails.data = this.shipmentDetails.data.filter(
          (i) => i.ordersDetailsID !== shipment.ordersDetailsID,
        );
      });
  }

  redirectToInvoicing() {
    sessionStorage.setItem(
      'shipping-generated-invoice',
      JSON.stringify(this.generatedInvoiceDetails),
    );
    sessionStorage.setItem(
      'shipping-generated-customer',
      JSON.stringify({
        customerId: this.shipmentDetail.custIDNo,
        addressId: this.shipmentDetail.shippingAddressID,
      }),
    );
    this.router.navigate(['invoicing-and-shipping/invoicing/0']);
  }

  onOpenRelatedInvoices() {
    this.dialog.open(ShippingRelatedInvoiceDialogComponent, {
      disableClose: true,
      minWidth: '800px',
      autoFocus: false,
      data: {
        shipmentId: this.shipmentDetail.shipmentsID,
      },
    });
  }

  onAddNewAddress() {
    const dialogRef = this.dialog.open(CustomerAddressDialogComponent, {
      disableClose: true,
      maxWidth: '700px',
      width: '100%',
      autoFocus: false,
      data: {
        customerId: this.shipmentDetail.custIDNo,
        addressType: 0,
      },
    });
    dialogRef.afterClosed().subscribe(({ customersAddressesId }) => {
      customersAddressesId &&
        this.getCustomer(this.shipmentDetail.custIDNo, customersAddressesId);
    });
  }
}
