import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ChainService } from 'src/app/services/chain.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ChainRaw } from '../../riva-chain/models';
import { ProductChainRaw } from '../model';

@Component({
  selector: 'riva-chain-unfinished-product',
  templateUrl: './riva-chain-unfinished-product.component.html',
  styleUrls: ['./riva-chain-unfinished-product.component.scss'],
})
export class RivaChainUnfinishedProductComponent implements OnInit {
  @Input() productId: number;

  displayedColumns = ['picPath', 'styleName', 'sku'];
  displayedProductColumns = [
    'picPath',
    'styleName',
    'sku',
    'lengthInches',
    'action',
  ];

  chains = new MatTableDataSource<ChainRaw>([]);
  @ViewChild(MatSort) sort: MatSort;
  productChains: ProductChainRaw[] = [];
  selectedChain: ChainRaw = {} as ChainRaw;
  length: number = 0;
  isSaving = false;
  showSizeError = false;
  searchText = '';

  constructor(
    private chainService: ChainService,
    private productBomService: ProductBomService,
    private loadService: LoadService,
    private toastrService: ToastrService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.getChains();
    this.getProductChains();
  }

  getChains() {
    this.chainService.getChainRaws().subscribe((data) => {
      this.chains.data = data;
      this.chains.sort = this.sort;
      this.chains.filterPredicate = (data: ChainRaw, filterValue: string) => {
        const { search } = JSON.parse(filterValue) ?? {};
        const searchFilter =
          data.styleName
            ?.toString()
            .toLowerCase()
            .includes(search?.toLowerCase()) ||
          data.sku?.toLowerCase().includes(search?.toLowerCase());
        return searchFilter;
      };
    });
  }
  onSearchChain() {
    this.chains.filter = JSON.stringify({
      search: this.searchText,
    });
  }
  getProductChains() {
    this.productBomService
      .getProductChainRaw(this.productId)
      .subscribe((data) => {
        this.productChains = data;
      });
  }

  onSelectChain(chain: ChainRaw) {
    this.selectedChain = { ...chain };
  }
  onDeleteChain(chain: ProductChainRaw) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Chain',
        content: 'Are you sure you want to delete this chain?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productBomService
          .deleteProductChainRaw(chain.productsBOMChainRawID)
          .subscribe(() => {
            this.getProductChains();
            this.loadService.reloadProductChainUnfinished();
            this.toastrService.success(
              'Chain successfully deleted',
              'Chain Unfinished',
            );
          });
      });
  }

  onSaveChainRaw() {
    const isExist = this.productChains.some(
      (p) =>
        p.chainRawID === this.selectedChain.chainRawID &&
        p.lengthInches === this.length,
    );
    if (isExist) {
      this.showSizeError = true;
      return;
    }
    this.showSizeError = false;
    this.productBomService
      .setProductChainRaw({
        productsBOMChainRawID: 0,
        productsID: this.productId,
        chainRawID: this.selectedChain.chainRawID,
        lengthInches: this.length,
        variation: true,
      })
      .subscribe(() => {
        this.getProductChains();
        this.loadService.reloadProductChainUnfinished();
        this.length = 0;
        this.toastrService.success('Successfully added.', 'Chain Unfinished');
      });
  }

  get isFormValid() {
    return this.selectedChain.chainRawID && this.length > 0;
  }
}
