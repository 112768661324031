<div class="riva-reporting">
  <div class="customer-section">
    <button
      class="riva-button"
      (click)="onFilterAllCustomer()"
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 26px;
      "
    >
      <span>All Customers</span>
      <span>{{ totalOrderForCustomer | number : '1.0' : 'en-US' }}</span>
    </button>
    <div class="table-container">
      <table
        mat-table
        matSort
        [dataSource]="customersTotalOrders"
        matSortActive="totalQty"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="customer">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by customer"
          >
            Customer
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.customer }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalQty">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by totalQty"
          >
            Total
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalQty | number : '1.0' : 'en-US' }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="gems-row"
          (click)="onTableRowClick(row)"
          [ngClass]="{
            'is-selected': row.customerId === filters?.customerId
          }"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="2">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
  <div>
    <div class="riva-reporting__filter">
      <div class="filters">
        <riva-date-range-picker
          [startDate]="filters.dateRangeFrom"
          [endDate]="filters.dateRangeTo"
          (onChange)="onDateRangeChange($event)"
        ></riva-date-range-picker>
        <riva-reporting-presets
          (onChange)="onSetDateRange($event)"
        ></riva-reporting-presets>
      </div>
      <div class="view">
        <mat-button-toggle-group
          class="view-type-toggle"
          [(ngModel)]="viewType"
          (ngModelChange)="onFilter()"
        >
          <mat-button-toggle value="monthly" style="height: 55px; width: 50%"
            >Monthly</mat-button-toggle
          >
          <mat-button-toggle value="weekly" style="height: 55px; width: 50%"
            >Weekly</mat-button-toggle
          >
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="graph-section">
      <div class="graph">
        <h1 style="color: white; margin: 0">Total Order</h1>
        <canvas
          baseChart
          class="chart"
          [data]="lineChartData"
          [options]="lineChartOptions"
          [type]="lineChartType"
        ></canvas>
        <p style="color: white; margin: 0; margin-top: 10px; font-size: 16px">
          Data is pulled from the date orders are placed
        </p>
      </div>
    </div>
  </div>
</div>
