<div class="riva-chain-finish-list">
  <div class="chain-finish-detail">
    <div>
      <div class="riva-chain-finish-list__header">
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px">
          <mat-form-field
            class="search-input"
            appearance="fill"
            style="width: 400px"
          >
            <mat-label>Search</mat-label>
            <input
              matInput
              [(ngModel)]="search"
              (ngModelChange)="onFilterChange()"
              style="color: white"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Chain Style</mat-label>
            <mat-select
              [(ngModel)]="filterChainStyleID"
              (ngModelChange)="onFilterChange()"
            >
              <mat-option
                *ngFor="let style of chainStyles"
                [value]="style.chainStylesID"
              >
                {{ style.styleName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="action-buttons">
          <button
            class="btn btn-success"
            style="height: 45px; margin-right: 8px"
            (click)="onOpenGenerateSkuDialog()"
          >
            Generate SKU
          </button>
          <button
            type="button"
            class="riva-button"
            style="width: 250px; justify-self: flex-end"
            (click)="createNewChainFinish()"
          >
            New Chain
          </button>
        </div>
      </div>
      <div class="mat-table__container">
        <table
          mat-table
          matSort
          [dataSource]="chainFinishes"
          matSortActive="chainFinishID"
          matSortDirection="desc"
        >
          <ng-container matColumnDef="picPath">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let element"
              style="width: 80px; padding: 0 !important"
            >
              <div
                style="
                  width: 70px;
                  padding: 5px;
                  display: flex;
                  justify-content: center;
                "
              >
                <img
                  style="max-height: 37px; max-width: 60px"
                  [src]="element.picPath || element.chainRaw.picPath"
                  onerror="this.src='assets/images/no-image.png'"
                />
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by name"
            >
              Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by description"
              style="border-right: 2px solid white"
            >
              Description
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              style="border-right: 3px solid white"
            >
              {{ element.description }}
            </td>
          </ng-container>
          <ng-container matColumnDef="sku">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by sku"
            >
              SKU
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.sku }}
            </td>
          </ng-container>
          <ng-container matColumnDef="styleName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by styleName"
            >
              Style Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.styleName }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="riva-chain-row"
            [ngClass]="{
              'is-selected':
                row.chainFinishedID === selectedChainFinish?.chainFinishedID
            }"
            (click)="onSelectChain(row)"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="5">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <div>
        <div>
          <input
            type="file"
            #uploadImage
            (change)="onUploadImage(uploadImage.files)"
            style="display: none"
          />
          <button
            class="riva-button"
            (click)="uploadImage.click()"
            [disabled]="!editMode || !selectedChainFinish.chainFinishedID"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px 6px 0 0;
            "
          >
            <i
              nz-icon
              nzType="file-image"
              nzTheme="outline"
              style="margin-right: 8px"
            ></i>
            Upload Picture
          </button>
        </div>
        <div class="image-wrapper">
          <div class="image-container">
            <img
              [src]="
                selectedChainFinish?.picPath ||
                selectedChainFinish?.chainRaw?.picPath
              "
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="chain-finish-other">
    <div class="other-group-one">
      <div class="editable-fields">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input
            matInput
            [(ngModel)]="selectedChainFinish.name"
            style="color: white"
            [disabled]="!editMode"
            (blur)="onSaveFinishDetail()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            [(ngModel)]="selectedChainFinish.description"
            (blur)="onSaveFinishDetail()"
            style="height: 80px; color: white"
            [disabled]="!editMode"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="size-and-material">
        <div>
          <div>
            <label style="color: white">Materials</label>
            <div class="size-table">
              <div
                *ngFor="let item of chainFinishMaterials"
                class="mat-row"
                [ngClass]="{
                  'mat-size':
                    selectedChainFinishMaterial.chainFinishedMaterialsID ===
                    item.chainFinishedMaterialsID
                }"
                (click)="onSelectMaterial(item)"
              >
                <div style="display: flex; align-items: center">
                  <div
                    style="
                      width: 25px;
                      height: 18px;
                      border: 1px solid white;
                      background-color: rgb(0, 0, 0);
                      margin-right: 5px;
                    "
                    [ngStyle]="{
                      'background-color': '#' + item.material?.printColor1
                    }"
                  ></div>
                  {{ item.material?.description }}
                </div>
              </div>
            </div>
            <div style="margin-top: 10px" *ngIf="editMode">
              <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Material Code</mat-label>
                <mat-select [(ngModel)]="selectedMaterialCode">
                  <mat-option
                    *ngFor="let mats of filteredMaterialCodes"
                    [value]="mats.materialCodeId"
                  >
                    {{ mats.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button
                class="riva-button riva-button-sm"
                style="width: 100%; margin-top: 10px"
                [disabled]="
                  !selectedMaterialCode ||
                  !this.selectedChainFinish.chainFinishedID ||
                  isSavingMaterial
                "
                (click)="onSaveMaterial()"
              >
                Add Material
              </button>
            </div>
          </div>
        </div>
        <div>
          <label style="color: white">Sizes</label>
          <div class="size-table">
            <div *ngFor="let item of chainFinishSizes">
              <div
                [ngClass]="{
                  'mat-size':
                    selectedChainFinishSize.chainFinishedSizesID ===
                    item.chainFinishedSizesID
                }"
                (click)="onSelectSize(item)"
                class="size-row"
              >
                <span>{{ item.size }}</span>
                <i
                  nz-icon
                  nzType="delete"
                  nzTheme="fill"
                  class="icon-delete"
                  *ngIf="false"
                ></i>
              </div>
            </div>
          </div>
          <div *ngIf="editMode" style="margin-top: 10px">
            <div style="position: relative">
              <mat-form-field style="width: 100%" appearance="fill">
                <mat-label>Size</mat-label>
                <input matInput [(ngModel)]="newSize" style="color: white" />
              </mat-form-field>
              <mat-error *ngIf="showSizeError" class="error-message"
                >Size already exist.</mat-error
              >
            </div>
            <button
              class="riva-button riva-button-sm"
              style="width: 100%; margin-top: 10px"
              [disabled]="
                !newSize ||
                !selectedChainFinish.chainFinishedID ||
                isSavingNewSize
              "
              (click)="onSaveChainSize()"
            >
              Add Size
            </button>
          </div>
        </div>
        <div class="stock-fields">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>QTY in Stock</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="selectedChainFinishStock.qtyInStock"
              style="color: white"
              [disabled]="!editMode"
            />
          </mat-form-field>
          <!-- <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Weight (g)</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="selectedChainFinishStock.chainTotalWeight"
              max="9999.99"
              style="color: white"
              [disabled]="!editMode"
            />
          </mat-form-field> -->
          <button
            *ngIf="editMode"
            class="btn btn-primary"
            style="font-size: 12px; padding: 5px; height: 40px"
            (click)="onSaveStock()"
            [disabled]="isSavingStock || !isStockFormValid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="chain-action-button">
      <button
        type="button"
        class="riva-button"
        style="width: 100%; justify-self: flex-end"
        (click)="onSetEditMode()"
        [ngClass]="{ hidden: editMode }"
      >
        Edit
      </button>
      <button
        type="button"
        class="btn btn-success"
        style="width: 100%; justify-self: flex-end; text-transform: unset"
        (click)="onSetEditMode()"
        [ngClass]="{ hidden: !editMode }"
      >
        Save
      </button>
      <button
        type="button"
        class="riva-button"
        style="width: 100%; justify-self: flex-end"
        (click)="onRoutingDialogOpen()"
      >
        Routing
      </button>
    </div>
  </div>

  <div class="bill-of-materials">
    <div class="bill-of-materials__header">
      <div class="bill-of-materials__title">Bill of Materials</div>
      <div style="display: flex; align-items: center; gap: 20px">
        <mat-form-field appearance="fill" style="margin-top: 1.34375em">
          <mat-label>Finished Length in Inches</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="selectedChainFinish.findingsLength"
            min="0"
            max="9.99"
            style="color: white"
            [disabled]="!editMode"
            (blur)="onSaveFinishDetail()"
          />
        </mat-form-field>
        <button
          type="button"
          class="riva-button"
          style="width: 250px; justify-self: flex-end"
          (click)="createNewChainFinishBom()"
          [disabled]="!selectedChainFinish.chainFinishedID"
        >
          New Material
        </button>
      </div>
    </div>
    <table
      mat-table
      matSort
      [dataSource]="billOfMaterials"
      matSortActive="chainFinishedBOMID"
      matSortDirection="desc"
      style="margin-top: 10px"
    >
      <ng-container matColumnDef="picPath">
        <th mat-header-cell *matHeaderCellDef></th>
        <td
          mat-cell
          *matCellDef="let element"
          style="width: 80px; padding: 0 !important"
        >
          <div
            style="
              width: 70px;
              padding: 5px;
              display: flex;
              justify-content: center;
            "
          >
            <img
              style="max-height: 37px; max-width: 60px"
              [src]="element.findings.picPath"
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by name"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="size">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by size"
        >
          Size
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size }}
        </td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by qty"
        >
          Qty
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.qty }}
        </td>
      </ng-container>
      <ng-container matColumnDef="comment">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by comment"
        >
          Comment
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.comment }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsForBOM; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumnsForBOM"
        class="riva-chain-row"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="5">No Available Data</td>
      </tr>
    </table>
  </div>
</div>
