import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { map, switchMap } from 'rxjs/operators';
import { MetalMarket } from 'src/app/models/metal-market';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class MetalMarketService extends GenericService {
  baseUrl = environment.apiUrl + 'metal-market';

  constructor(http: HttpClient) {
    super(http);
  }

  getMetalMarketDates() {
    return this.http
      .get<MetalMarket[]>(`${this.baseUrl}/dates`, {
        headers: this.headers,
      })
      .pipe(
        map((list) => {
          return list.map((value) => ({
            ...value,
            marketDate: DateTime.fromJSDate(
              new Date(value.marketDate),
            ).toFormat('yyyy-MM-dd'),
          }));
        }),
      );
  }

  getLatestMetalMarket() {
    return this.getMetalMarketDates().pipe(
      switchMap((dates = []) => {
        const [latest] = orderBy(dates, ['metalMarketID'], ['desc']);
        return this.getMetalMarketById(latest.metalMarketID);
      }),
    );
  }

  getMetalMarketById(metalMarketId) {
    return this.http
      .get<MetalMarket>(this.baseUrl, {
        headers: this.headers,
        params: {
          metalMarketId,
        },
      })
      .pipe(
        map((data) => ({
          ...data,
          marketDate: DateTime.fromJSDate(new Date(data.marketDate)).toFormat(
            'MM/dd/yyyy',
          ),
          enteredDate: DateTime.fromJSDate(new Date(data.enteredDate)).toFormat(
            'yyyy-MM-dd',
          ),
        })),
      );
  }

  getMetalMarket(entryDate) {
    return this.http
      .get<MetalMarket>(this.baseUrl, {
        headers: this.headers,
        params: {
          entryDate,
        },
      })
      .pipe(
        map((metalMarket) => {
          return {
            ...metalMarket,
            marketDate: DateTime.fromJSDate(
              new Date(metalMarket.marketDate),
            ).toFormat('yyyy-MM-dd'),
            enteredDate: DateTime.fromJSDate(
              new Date(metalMarket.enteredDate),
            ).toFormat('yyyy-MM-dd'),
          };
        }),
      );
  }
}
