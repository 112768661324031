import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerAddress } from 'src/app/models/customer';
import { environment } from 'src/environments/environment';
import { Customers } from './../models/customer';
import { GenericService } from './generic.service';

export const formatAddress = (address: CustomerAddress) => {
  const address1 = address.address1 ? `${address.address1} ` : '';
  const address2 = address.address2 ? `${address.address2} ` : '';
  const city = address.city ? `${address.city}, ` : '';
  const region = address.region ? `${address.region} ` : '';
  const postalCode = address.postalCode ? `${address.postalCode}, ` : '';
  const country = address.country ? `${address.country}` : '';

  return `${address1}${address2}${city}${region}${postalCode}${country}`;
};

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends GenericService {
  baseUrl = environment.apiUrl + 'customer';

  constructor(http: HttpClient) {
    super(http);
  }

  addupdate(customer: Customers): Observable<any> {
    return this.http.post(this.baseUrl + '/addupdate', customer, {
      headers: this.headers,
    });
  }

  getCustomer(customerId) {
    return this.http
      .get<Customers>(`${this.baseUrl}/${customerId}`, {
        headers: this.headers,
      })
      .pipe(
        map((customer) => ({
          ...customer,
          addresses: customer.addresses.map((address) => ({
            ...address,
            fullAddress: formatAddress(address),
          })),
        })),
      );
  }
}
