import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ProductsRouting } from 'src/app/models/product';
import { ChainService } from 'src/app/services/chain.service';
import { ProductService } from 'src/app/services/product.service';
import { ChainCommon } from '../riva-chain/models';
import { ChainRoutingComponent } from './chain-routing.component';

@Component({
  selector: 'chain-routing-copy-dialog',
  templateUrl: './chain-routing-copy-dialog.component.html',
  styleUrls: ['./chain-routing-copy-dialog.component.scss'],
})
export class ChainRoutingCopyDialogComponent implements OnInit {
  @ViewChild(ChainRoutingComponent)
  productRoutingComponent: ChainRoutingComponent;

  chainId: number = 0;
  filteredChains: Observable<ChainCommon[]>;
  chainControl = new FormControl();
  chains: ChainCommon[] = [];
  chain: ChainCommon = {} as ChainCommon;
  routings: ProductsRouting[];

  constructor(
    public dialogRef: MatDialogRef<ChainRoutingCopyDialogComponent>,
    public loadService: LoadService,
    public productService: ProductService,
    public chainService: ChainService,

    @Inject(MAT_DIALOG_DATA)
    public props: { chainType: number },
  ) {}

  ngOnInit(): void {
    this.getChains();
  }
  initializeFilter() {
    this.filteredChains = this.chainControl.valueChanges.pipe(
      startWith(this.chain.name),
      map((value) => this._filterChain(value)),
    );
  }
  getChains() {
    if (this.props.chainType) {
      this.chainService.getChainFinished().subscribe((data) => {
        this.chains = data.map((d) => ({
          chainId: d.chainFinishedID,
          chainType: 1,
          name: d.name,
          sku: d.sku,
          style: d.chainRaw.styleName,
        }));
        this.initializeFilter();
      });
    } else {
      this.chainService.getChainRaws().subscribe((data) => {
        this.chains = data.map((d) => ({
          chainId: d.chainRawID,
          chainType: 0,
          name: d.sku,
          sku: d.sku,
          style: d.styleName,
        }));
        this.initializeFilter();
      });
    }
  }

  private _filterChain(name: string): ChainCommon[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.chains.filter((option) =>
        option.name.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['name'];
      return this.chains.filter((option) =>
        option.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.chains;
  }

  displayFn(item: ChainCommon): string {
    return item?.name ?? '';
  }

  onRoutingChange($events) {
    this.routings = $events;
  }

  onConfirm() {
    this.dialogRef.close(this.routings);
  }
}
