import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PRODUCT_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/product-permission';
import { Enamel } from 'src/app/models/enamel';
import { ProductEnamel } from 'src/app/models/product';
import { EnamelService } from 'src/app/services/enamel.service';
import { getTypeLabel } from 'src/app/services/generic.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductComponentDialogComponent } from '../product-component-dialog/product-component-dialog.component';
import { ConfirmationService } from './../../riva-confirmation/riva-confirmation.service';

@Component({
  selector: 'product-enamel',
  templateUrl: './product-enamel.component.html',
  styleUrls: ['./product-enamel.component.scss'],
})
export class ProductEnamelComponent implements OnInit {
  @Input() productId: number;

  productFeatureKey = PRODUCT_FEATURE_KEY;
  displayedColumns: string[] = [
    'variation',
    'color',
    'name',
    'pantoneColor',
    'hardness',
    'delete',
  ];
  search = '';
  isEnamelSaving = false;
  maxVariation = 30;
  isVariationLimit = false;
  enamelControl = new FormControl();
  filteredEnamels: Observable<Enamel[]>;
  selection = new SelectionModel<Enamel>(true, []);
  selectedEnamel: Enamel;
  enamels = new MatTableDataSource<Enamel>([]);
  rawEnamels: Enamel[];
  productEnamels = new MatTableDataSource<ProductEnamel>([]);
  getTypeLabel = getTypeLabel;

  constructor(
    public dialogRef: MatDialogRef<ProductComponentDialogComponent>,
    private enamelService: EnamelService,
    private productBomService: ProductBomService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.enamels.filterPredicate = (data: Enamel, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.enamelName
          .toString()
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        data.enamelSku.toLowerCase().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  initiateFilters() {
    this.filteredEnamels = this.enamelControl.valueChanges.pipe(
      startWith(this.selectedEnamel?.enamelName),
      map((value) => this._filterEnamels(value)),
    );
  }

  private _filterEnamels(name: string): Enamel[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.enamels.data.filter(
        (option) =>
          option.enamelName?.toLowerCase().includes(filterValue) ||
          option.enamelSku?.toLowerCase().includes(filterValue) ||
          option.pantoneColor?.toLowerCase().includes(filterValue),
      );
    }
    return this.enamels.data;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.enamels.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.enamels.data.forEach((row) => this.selection.select(row));
  }

  onFilterChange() {
    this.enamels.filter = JSON.stringify({ search: this.search });
  }

  onCheckVariation() {
    this.enamels.data = this.rawEnamels.filter((e) => {
      return !this.productEnamels.data.some((p) => p.enamelID === e.enamelId);
    });
  }

  onAddSelectedItem() {
    this.productEnamels.data = this.selection.selected.reduce(
      (accum, { enamelId, enamelSku, ...selected }) => [
        ...accum,
        {
          ...selected,
          enamelID: enamelId,
          enamelSKU: enamelSku,
          productsID: this.productId,
        },
      ],
      [...this.productEnamels.data],
    );
    this.selection.clear();
    this.onCheckVariation();
  }

  displayFn(item: Enamel): string {
    return item?.enamelName ?? '';
  }

  onSelectEnamel(enamel: Enamel) {
    if (this.productEnamels.data.length >= this.maxVariation) {
      this.isVariationLimit = true;
      return;
    }
    const { enamelId, ...selected } = enamel;
    this.productEnamels.data = [
      ...this.productEnamels.data,
      {
        ...selected,
        enamelID: enamelId,
        productsID: this.productId,
      },
    ];
    this.onCheckVariation();
    this.selectedEnamel = null;
  }

  onDeleteEnamel(enamel: ProductEnamel) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Enamel',
        content: 'Are you sure you want to delete this enamel?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productEnamels.data = this.productEnamels.data.filter(
          (p) => p.enamelID !== enamel.enamelID,
        );
        if (enamel.productsBOMEnamelID) {
          this.productBomService
            .deleteProductEnamel(enamel.productsBOMEnamelID)
            .subscribe();
        }
        this.onCheckVariation();
        this.initiateFilters();
        this.isVariationLimit = false;
      });
  }

  onSave() {
    this.isEnamelSaving = true;
    const newProductEnamels = this.productEnamels.data.reduce(
      (variations, p) => [
        ...variations,
        {
          productsBOMEnamelID: p.productsBOMEnamelID ?? 0,
          enamelID: p.enamelID,
          productsID: this.productId,
          variation: p.variation,
        },
      ],
      [],
    );
    if (!newProductEnamels.length) {
      this.isEnamelSaving = false;
      this.dialogRef.close();
      return;
    }
    this.productBomService.setProductEnamel(newProductEnamels).subscribe(() => {
      this.isEnamelSaving = false;
      this.dialogRef.close();
    });
  }

  ngAfterViewInit(): void {
    forkJoin([
      this.enamelService.getList(),
      this.productBomService.getProductEnamel(this.productId),
    ])
      .pipe(
        map(([enamels, productEnamelList]) => {
          const productEnamels = productEnamelList.map((p) => {
            const enamel = enamels.find((e) => e.enamelId === p.enamelID) ?? {};
            return { ...enamel, ...p };
          });
          return { enamels, productEnamels };
        }),
      )
      .subscribe(({ enamels, productEnamels }) => {
        this.rawEnamels = enamels;
        this.productEnamels.data = productEnamels;
        this.onCheckVariation();
        this.initiateFilters();
      });
  }
}
