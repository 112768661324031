import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import {
  StoneColor,
  StoneCut,
  StoneDefaultImage,
  StoneType,
} from '../../riva-gems.model';
import { StoneService } from '../../stone.service';

@Component({
  templateUrl: './stone-images-dialog.component.html',
  styleUrls: ['./stone-images-dialog.component.scss'],
})
export class StoneImagesDialogComponent implements OnInit {
  stoneTypes: StoneType[];
  stoneCuts: StoneCut[];
  stoneColors: StoneColor[];
  stoneDefaultImages: StoneDefaultImage[];
  imageFiles: FileList | null = null;
  stoneDefaultImageConfig: StoneDefaultImage = {
    stonePicsDefaultID: 0,
    stoneTypesID: 0,
    stoneCutsID: 0,
    stoneColorsID: 0,
    picturePath: '',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { productId: number },
    public dialogRef: MatDialogRef<StoneImagesDialogComponent>,
    private stoneService: StoneService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.stoneService.getTypes(),
      this.stoneService.getCuts(),
      this.stoneService.getColors(),
    ]).subscribe(([types, cuts, colors]) => {
      this.stoneTypes = types;
      this.stoneCuts = cuts;
      this.stoneColors = colors;
    });
  }

  onGetImage() {
    if (!this.isValid) return;
    this.stoneService
      .getStoneDefaultPicture(this.stoneDefaultImageConfig)
      .subscribe((data) => {
        if (data.stonePicsDefaultID > 0) {
          this.stoneDefaultImageConfig.picturePath = data.picturePath;
        } else {
          this.stoneDefaultImageConfig.picturePath = '';
        }
      });
  }

  onUploadChange(files: FileList) {
    this.imageFiles = files;
    this.stoneDefaultImageConfig.picturePath = URL.createObjectURL(files[0]);
    this.uploadImage();
  }

  uploadImage() {
    const formData = new FormData();
    for (let i = 0; i < this.imageFiles.length; i++) {
      formData.append('file[]', this.imageFiles[i]);
    }

    const { stoneTypesID, stoneCutsID, stoneColorsID } =
      this.stoneDefaultImageConfig;

    formData.append('stoneTypesID', stoneTypesID.toString());
    formData.append('stoneCutsID', stoneCutsID.toString());
    formData.append('stoneColorsID', stoneColorsID?.toString());
    this.stoneService.uploadDefaultPicture(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' + Math.round((100 * event.loaded) / event.total) + '%',
        );
      } else if (event.type === HttpEventType.ResponseHeader) {
      }
    });
  }

  get currentImage() {
    return this.stoneDefaultImageConfig.picturePath
      ? this.sanitizer.bypassSecurityTrustUrl(
          this.stoneDefaultImageConfig.picturePath,
        )
      : '';
  }
  get isValid() {
    return (
      this.stoneDefaultImageConfig.stoneTypesID &&
      this.stoneDefaultImageConfig.stoneCutsID
    );
  }
}
