import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/core/authentication/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './accounts-table-dialog.component.html',
  styleUrls: ['./accounts-table-dialog.component.scss'],
})
export class AccountsTableDialogComponent implements OnInit {
  accounts: User[] = [];
  isSaving: boolean = false;
  usernameExist: boolean = false;
  usedEmployeeId = new Set();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User = {} as User,
    public dialogRef: MatDialogRef<AccountsTableDialogComponent>,
    private userService: UserService,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.getAccounts();
    if ((this.data.usersID ?? 0) === 0) {
      this.data.employeeID = this.generateUniqueRivaEmployeeId();
    }
  }
  getAccounts() {
    this.userService.getUsers().subscribe((data = []) => {
      this.accounts = data;
      const usedIds = data.reduce((list, e) => {
        if (e.employeeID?.indexOf('RIVA') >= 0) {
          return [...list, Number.parseInt(e.employeeID.replace('RIVA', ''))];
        }
      }, []);
      this.usedEmployeeId = new Set(usedIds);
    });
  }
  checkUsername() {
    this.usernameExist = this.accounts.some(
      (account) =>
        account.userName?.toLocaleLowerCase() ===
          this.data?.userName.toLowerCase() &&
        account.usersID !== this.data.usersID,
    );
  }

  get disabledSaveButton() {
    return (
      this.usernameExist ||
      this.isSaving ||
      (!this.data.noLoginUser &&
        (!this.data.userName ||
          (this.data.usersID ? false : !this.data.password)))
    );
  }

  onSaveAccount() {
    this.isSaving = true;
    if (this.data.usersID > 0) {
      const { password, ...data } = this.data;
      this.userService
        .updateUser({
          ...data,
          tokenTimeout:
            this.data.tokenTimeout >= 0 ? this.data.tokenTimeout : 0,
        })
        .subscribe(
          () => {
            this.dialogRef.close(true);
            this.isSaving = false;
          },
          () => {
            this._snackBar.open(
              'Error encountered when updating account',
              'Dismiss',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              },
            );
            this.isSaving = false;
          },
        );
    } else {
      this.userService
        .setUser({
          ...this.data,
          userName: this.data.noLoginUser ? '' : this.data.userName,
          password: this.data.noLoginUser ? '' : this.data.password,
          tokenTimeout:
            this.data.tokenTimeout >= 0 ? this.data.tokenTimeout : 0,
        })
        .subscribe(
          () => {
            this.dialogRef.close(true);
            this.isSaving = false;
          },
          () => {
            this._snackBar.open(
              'Error encountered when adding new account',
              'Dismiss',
              {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              },
            );
          },
        );
    }
  }

  generateUniqueNumber(min, max) {
    let number;
    do {
      number = Math.floor(Math.random() * (max - min + 1)) + min;
    } while (this.usedEmployeeId.has(number));

    this.usedEmployeeId.add(number);
    return number;
  }

  padNumber(num, width) {
    return String(num).padStart(width, '0');
  }

  generateUniqueRivaEmployeeId() {
    const min = 0;
    const max = 9999999;
    const uniqueNumber = this.generateUniqueNumber(min, max);
    const paddedNumber = this.padNumber(uniqueNumber, 7);

    return `RIVA${paddedNumber}`;
  }

  onChangeNoLoginUser() {
    this.data.userName = '';
    this.data.password = '';
    this.usernameExist = false;
  }
  setAllowedMultipleWorkOrder() {
    this.data.allowedMultipleWorkOrder = !this.data.allowedMultipleWorkOrder;
  }
}
