import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { WorkOrderUI } from 'src/app/models/work-order';
import { WorkOrderService } from 'src/app/services/work-order.service';

const getOrderType = (orderType: number) => {
  switch (orderType) {
    case 1:
      return 'Internal';
    case 2:
      return 'Online';
    case 3:
      return 'Repair';
    default:
      return 'Standard';
  }
};

@Component({
  selector: 'dialog-work-orders-release',
  templateUrl: './work-orders-release.component.html',
  styleUrls: ['./work-orders-release.component.scss'],
})
export class WorkOrdersReleaseComponent {
  @ViewChild('errorWorkOrder')
  public readonly errorWorkOrder!: SwalComponent;

  code: string = '';
  isScanning = false;
  error: { title: string; description: string } = {
    title: '',
    description: '',
  };

  constructor(
    private workOrderService: WorkOrderService,
    public dialogRef: MatDialogRef<WorkOrdersReleaseComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { workOrders: WorkOrderUI[]; action: string; title?: string },
  ) {}

  onScanning($event) {
    if (this.isScanning) return;
    const code = parseInt($event.key);
    if ($event.key === 'Enter') {
      this.onCheckWorkOrder();
    } else if (!Number.isNaN(code)) {
      this.code = `${this.code}${$event.key}`;
    }
  }

  showErrorMessage() {
    setTimeout(() => {
      this.errorWorkOrder.fire();
    }, 100);
  }

  onCheckShipping() {
    this.workOrderService
      .validateWorkOrderForShipment(Number(this.code))
      .subscribe(
        (data) => {
          if (!(data.closed || data.partiallyClose)) {
            this.error = {
              title: 'Unclosed Work Order',
              description: `Work order number ${this.code} is not yet closed.`,
            };
            this.showErrorMessage();
            this.code = '';
            return;
          } else if (data.orderType === 1 || data.orderType === 3) {
            this.error = {
              title: 'Invalid work order for shipping',
              description: `Unable to ship this work order number ${
                this.code
              }. Order type is ${getOrderType(data.orderType)}`,
            };
            this.showErrorMessage();
            this.code = '';
            return;
          }
          this.dialogRef.close({
            code: this.code,
            workOrderType: data.workOrderType,
          });
        },
        () => {
          this.error = {
            title: 'Data Not Found',
            description: `Work order number ${this.code} not found.`,
          };
          this.showErrorMessage();
          this.code = '';
        },
      );
  }
  onCheckInvoicing() {
    this.workOrderService
      .validateWorkOrderForInvoices(Number(this.code))
      .subscribe(
        (data) => {
          if (!(data.closed || data.partiallyClose)) {
            this.error = {
              title: 'Unclosed Work Order',
              description: `Work order number ${this.code} is not yet closed.`,
            };
            this.showErrorMessage();
            this.code = '';
            return;
          } else if (
            data.orderType === 1 ||
            data.orderType === 2 ||
            data.orderType === 3
          ) {
            this.error = {
              title: 'Invalid work order for invoicing',
              description: `Unable to invoice this work order number ${
                this.code
              }. Order type is ${getOrderType(data.orderType)}`,
            };
            this.showErrorMessage();
            this.code = '';
            return;
          }
          this.dialogRef.close({
            code: this.code,
            workOrderType: data.workOrderType,
          });
        },
        () => {
          this.error = {
            title: 'Data Not Found',
            description: `Work order number ${this.code} not found.`,
          };
          this.showErrorMessage();
          this.code = '';
        },
      );
  }

  onCheckWorkOrder() {
    if (this.data.action === 'get-work-order-id') {
      this.dialogRef.close(this.code);
      return;
    }
    if (this.data.action === 'shipped') {
      this.onCheckShipping();
      return;
    }
    if (this.data.action === 'invoiced') {
      this.onCheckInvoicing();
      return;
    }
    const scannedWorkOrder = this.data.workOrders.find(
      (wo) => wo.workOrdersId === Number(this.code),
    );
    if (!scannedWorkOrder) {
      this.error = {
        title: 'Data Not Found',
        description: `Work order number ${this.code} not found.`,
      };
      this.showErrorMessage();
      this.code = '';
      return;
    }
    if (this.data.action === 'release' && !scannedWorkOrder.printedDate) {
      this.error = {
        title: 'Unprinted Work Order',
        description: `Work order number ${this.code} needs to be printed before releasing.`,
      };
      this.showErrorMessage();
      this.code = '';
      return;
    }
    if (this.data.action === 'release' && scannedWorkOrder.releasedDate) {
      this.error = {
        title: 'Released Work Order',
        description: `Work order number ${this.code} already released.`,
      };
      this.showErrorMessage();
      this.code = '';
      return;
    }
    if (this.data.action === 'close' && !scannedWorkOrder.releasedDate) {
      this.error = {
        title: 'Unreleased Work Order',
        description: `Work order number ${this.code} needs to be released before receiving.`,
      };
      this.showErrorMessage();
      this.code = '';
      return;
    }
    this.dialogRef.close(this.code);
  }
}
