<div class="riva-reporting-item-weights">
  <div class="filters">
    <riva-date-range-picker
      [startDate]="filters.dateRangeFrom"
      [endDate]="filters.dateRangeTo"
      (onChange)="onDateRangeChange($event)"
    ></riva-date-range-picker>
    <mat-form-field class="search-input" appearance="fill">
      <mat-label>Search product</mat-label>
      <input
        matInput
        [(ngModel)]="searchText"
        (ngModelChange)="onFilterData()"
        style="color: white"
      />
    </mat-form-field>
  </div>
  <div class="report-details">
    <div class="item-section">
      <div class="table-container">
        <table
          mat-table
          matSort
          #items
          #itemsSort="matSort"
          [dataSource]="groupedItems"
          matSortActive="itemName"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="itemName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by itemName"
            >
              Item
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="product-row">
                <div
                  style="
                    width: 100px;
                    padding: 5px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                  "
                >
                  <img
                    style="max-width: 100%; max-height: 100%"
                    [src]="element.picturePath"
                    onerror="this.src='assets/images/no-image.png'"
                  />
                </div>
                <div>{{ element.itemName }}</div>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"
            [ngClass]="{
              'is-selected': row.itemId === selectedItem.itemId
            }"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="1">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="item-section">
      <div class="table-container">
        <table
          mat-table
          matSort
          #workOrder
          #workOrderSort="matSort"
          [dataSource]="selectedItemWorkOrders"
          matSortActive="workOrderId"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="workOrdersId">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by workOrdersId"
            >
              Work Order Number
            </th>
            <td mat-cell *matCellDef="let element">
              <a
                *ngIf="element.workOrdersId"
                class="link"
                (click)="onPrintWorkOrder(element.workOrdersId)"
                >{{ element.workOrdersId }}</a
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="metalDescription">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by metalDescription"
            >
              Metal
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.metalDescription }}
            </td>
          </ng-container>
          <ng-container matColumnDef="size">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by size"
            >
              Size
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.size }}
            </td>
          </ng-container>
          <ng-container matColumnDef="closedDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by closedDate"
            >
              Closed Date
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.closedDate | date : 'MM/dd/yyyy' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="closedQty">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by closedQty"
            >
              Closed QTY
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.closedQty }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalClosedWeight">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by totalClosedWeight"
            >
              Total Closed Weight
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.totalClosedWeight }}<span>g</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="individualClosedWeight">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by individualClosedWeight"
            >
              Individual Closed Weight
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.individualClosedWeight }}<span>g</span>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedWorkOrderColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedWorkOrderColumns"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="7">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
