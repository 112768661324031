<h3 mat-dialog-title>Sub Item</h3>

<mat-dialog-content class="mat-typography">
  <div class="sub-item-dialog__column">
    <div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Sub item</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="subItem"
          [formControl]="subItemControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="displayFn"
        >
          <mat-option class="riva-select-header riva-select__stick-header">
            <div class="riva-select">
              <div class="p5 riva-select__name">Name</div>
              <div class="p5 riva-select__description">Description</div>
            </div>
          </mat-option>
          <mat-option class="riva-select-header">
            <div class="riva-select">
              <div class="p5 riva-select__name">Name</div>
              <div class="p5 riva-select__description">Description</div>
            </div>
          </mat-option>
          <mat-option
            *ngFor="let item of filteredSubItems$ | async"
            [value]="item"
            style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
          >
            <div class="country-container riva-select">
              <div class="p5 riva-select__name">
                {{ item.name }}
              </div>
              <div class="p5 riva-select__description">
                {{ item.description }}
              </div>
            </div>
          </mat-option>
          <mat-option *ngIf="!(filteredSubItems$ | async)?.length">
            <div class="text-center riva-select__not-found">Not Found</div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Notes</mat-label>
        <textarea matInput [(ngModel)]="data.notes" rows="4"></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Quantity</mat-label>
        <input matInput type="number" [(ngModel)]="data.qty" min="0" />
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  style="
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 3px;
  "
>
  <button
    class="btn btn-default"
    autofocus
    mat-dialog-close
    style="font-size: 12px; padding: 5px; height: 40px; color: white"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    style="font-size: 12px; padding: 5px; height: 40px"
    (click)="onSave()"
    [disabled]="isSaving"
  >
    Save
  </button>
</mat-dialog-actions>
