import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { OrphanWorkOrderDialogComponent } from '../work-order-view/orphan-work-order-dialog/orphan-work-order-dialog.component';
import { WorkOrdersReleaseComponent } from '../work-order-view/work-orders-release.component';

@Component({
  selector: 'invoicing-shipping',
  templateUrl: './invoicing-shipping.component.html',
  styleUrls: ['./invoicing-shipping.component.scss'],
})
export class InvoicingShippingComponent {
  @ViewChild('confirmationWorkOrder')
  public readonly confirmationWorkOrder!: SwalComponent;
  selectedTabIndex = 0;
  confirmation: {
    icon: string;
    title: string;
    description: string;
    timer?: number;
  } = {
    icon: 'question',
    title: '',
    description: '',
  };

  constructor(
    public dialog: MatDialog,
    public workOrderService: WorkOrderService,
    private userPermissionService: UserPermissionService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.userPermissionService.checkPagePermission(
      PAGE_NAME.invoicingAndShipping,
    );
    this.route.params.subscribe((params: { slug: string }) => {
      this.selectedTabIndex = params.slug === 'shipping' ? 1 : 0;
    });
  }

  onTabChange(event) {
    const slug = event === 0 ? 'invoicing' : 'shipping';
    this.location.replaceState(`invoicing-and-shipping/${slug}`);
  }

  onScanWorkOrder(action: string) {
    const dialogRef = this.dialog.open(WorkOrdersReleaseComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      data: {
        workOrders: [],
        action,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (action === 'shipped') {
          this.workOrderService.setWorkOrderShipments(result).subscribe(
            () => {
              this.confirmation = {
                title: 'Work Order Shipped',
                description: `Successfully shipped work order number '${result}'`,
                icon: 'success',
                timer: 1000,
              };
              setTimeout(() => {
                this.confirmationWorkOrder.fire();
              }, 100);
            },
            () => {
              this.confirmation = {
                title: 'Shipping Failed',
                description: `Unable to ship work order number '${result}'. Please contact the administration`,
                icon: 'error',
              };
              setTimeout(() => {
                this.confirmationWorkOrder.fire();
              }, 100);
            },
          );
        }
        if (action === 'invoiced') {
          this.workOrderService.setWorkOrderInvoices(result).subscribe(
            () => {
              this.confirmation = {
                title: 'Work Order Invoiced',
                description: `Successfully invoiced work order number '${result}'`,
                icon: 'success',
                timer: 1000,
              };
              setTimeout(() => {
                this.confirmationWorkOrder.fire();
              }, 100);
            },
            () => {
              this.confirmation = {
                title: 'Invoicing Failed',
                description: `Unable to invoice work order number '${result}'. Please contact the administration`,
                icon: 'error',
              };
              setTimeout(() => {
                this.confirmationWorkOrder.fire();
              }, 100);
            },
          );
        }
      }
    });
  }

  onOpenOrphanWorkOrderDialog() {
    this.dialog.open(OrphanWorkOrderDialogComponent, {
      disableClose: true,
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
    });
  }
}
