<div class="product-finding-table" style="border: 1px solid #646464">
  <table mat-table [dataSource]="productFindings" style="width: 100%">
    <ng-container matColumnDef="picPath">
      <th mat-header-cell *matHeaderCellDef></th>
      <td
        mat-cell
        *matCellDef="let element"
        style="width: 80px; padding: 0 !important"
      >
        <div
          style="
            width: 70px;
            padding: 5px;
            display: flex;
            justify-content: center;
          "
          *ngIf="element.findings?.picPath"
        >
          <img
            style="max-height: 37px; max-width: 60px"
            [src]="element.findings?.picPath"
          />
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.findings.name || element.size.findingsTypeName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef>SKU</th>
      <td mat-cell *matCellDef="let element">{{ element.findings.sku }}</td>
    </ng-container>
    <ng-container matColumnDef="findingType">
      <th mat-header-cell *matHeaderCellDef>Finding Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.size.findingsTypeName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="material">
      <th mat-header-cell *matHeaderCellDef>Material</th>
      <td mat-cell *matCellDef="let element">
        {{ element.material?.description }}
      </td>
    </ng-container>
    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef>Size</th>
      <td mat-cell *matCellDef="let element">{{ element.sizeLabel }}</td>
    </ng-container>
    <ng-container matColumnDef="qty">
      <th mat-header-cell *matHeaderCellDef>Qty</th>
      <td mat-cell *matCellDef="let element">{{ element.qty }}</td>
    </ng-container>
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>Comment</th>
      <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <i
          nz-icon
          nzType="delete"
          nzTheme="fill"
          class="icon-delete"
          (click)="onDeleteFindings.emit(element)"
        ></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="6">No Available Data</td>
    </tr>
  </table>
</div>
