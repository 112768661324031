import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ProductSizes } from 'src/app/models/product';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';
import {
  ProductStone,
  RivaGems,
  StoneClarity,
  StoneColor,
  StoneCut,
  StoneType,
} from '../riva-gems.model';
import { StoneService } from '../stone.service';
import { StoneOverrideSizeDialogComponent } from './stone-override-size-dialog/stone-override-size-dialog.component';
import { StoneOverrideSizeMultipleDialogComponent } from './stone-override-size-multiple-dialog/stone-override-size-multiple-dialog.component';

@Component({
  selector: 'stone-product',
  templateUrl: './stone-product.component.html',
  styleUrls: ['./stone-product.component.scss'],
})
export class StoneProductComponent implements OnInit, OnChanges {
  @Input() productId: number;
  @Input() isVariation?: boolean;
  @Input() tableOnly: boolean = false;
  @Input() editMode: boolean = false;
  @Input() tableTitle: string = '';
  @Output() onCloseDialog = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  stones = new MatTableDataSource<RivaGems>([]);

  selectedStone: RivaGems;
  isStoneSaving = false;

  stoneTypes: StoneType[] = [
    {
      stoneTypesID: 0,
      name: 'All',
    },
  ];
  stoneCuts: StoneCut[] = [
    {
      stoneCutsID: 0,
      name: 'All',
      custom: false,
    },
  ];
  stoneClarities: StoneClarity[] = [
    {
      stoneClaritiesID: 0,
      name: 'All',
    },
  ];
  stoneColors: StoneColor[] = [
    {
      stoneColorsID: 0,
      colorName: 'All',
    },
  ];
  filter = {
    stoneTypesID: 0,
    stoneCutsID: 0,
    stoneClaritiesID: 0,
    stoneColorsID: 0,
    labGrown: 0,
    traceable: 0,
  };
  displayedColumns: string[] = [
    'pic',
    'type',
    'cut',
    'clarity',
    'color',
    'size',
    'labGrown',
    'traceable',
    'customerProvided',
    'qty',
  ];

  displayedStoneColumns: string[] = [
    'pic',
    'type',
    'cut',
    'clarity',
    'color',
    'size',
    'labGrown',
    'traceable',
    'add',
  ];
  stoneControl = new FormControl();
  filteredStones: Observable<RivaGems[]>;
  productStones: ProductStone[] = [];
  productSizes: ProductSizes[] = [];
  searchText = '';

  constructor(
    public dialog: MatDialog,
    private stoneService: StoneService,
    private productBomService: ProductBomService,
    private productService: ProductService,
    private loadService: LoadService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.displayedColumns = this.tableOnly
      ? !this.isVariation
        ? [...this.displayedColumns, 'override']
        : this.displayedColumns
      : ['variation', ...this.displayedColumns, 'delete'];
    if (!this.tableOnly) {
      forkJoin([
        this.stoneService.getTypes(),
        this.stoneService.getCuts(),
        this.stoneService.getClarities(),
        this.stoneService.getColors(),
      ]).subscribe(([types, cuts, clarities, colors]) => {
        this.stoneTypes = [...this.stoneTypes, ...types];
        this.stoneCuts = [...this.stoneCuts, ...cuts];
        this.stoneClarities = [...this.stoneClarities, ...clarities];
        this.stoneColors = [...this.stoneColors, ...colors];
      });
      this.getStones();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productId?.currentValue === changes.productId?.previousValue)
      return;
    if (this.productId) {
      this.getProductStones();
    }
  }
  ngAfterViewInit() {
    this.stones.sort = this.sort;
    this.stones.paginator = this.paginator;
    this.stones.filterPredicate = (s: RivaGems, filterValue: string) => {
      const {
        stoneTypesID,
        stoneCutsID,
        stoneClaritiesID,
        stoneColorsID,
        labGrown,
        traceable,
      } = JSON.parse(filterValue);
      const labGrownFilter =
        labGrown === -1 || s.labGrown === (labGrown > 0 ? true : false);
      const traceableFilter =
        traceable === -1 || s.traceable === (traceable > 0 ? true : false);
      return (
        (stoneTypesID === 0 || s.stoneTypesID === stoneTypesID) &&
        (stoneCutsID === 0 || s.stoneCutsID === stoneCutsID) &&
        (stoneClaritiesID === 0 || s.stoneClaritiesID === stoneClaritiesID) &&
        (stoneColorsID === 0 || s.stoneColorsID === stoneColorsID) &&
        !this.productStones.some((p) => p.stonesID === s.stonesID) &&
        labGrownFilter &&
        traceableFilter &&
        (!this.searchText ||
          s.stoneSize.displayText
            .toLowerCase()
            .includes(this.searchText.toLowerCase()))
      );
    };
    this.stones.sortingDataAccessor = (
      data: any,
      sortHeaderId: string,
    ): any => {
      if (sortHeaderId === 'size') {
        return (
          (data.stoneSize?.dimension_1 ?? 0) * 1000 +
          (data.stoneSize?.dimension_2 ?? 0) * 100 +
          (data.stoneSize?.dimension_3 ?? 0) * 1
        );
      } else if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId]?.toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };
  }

  getProductDetails() {
    this.productService
      .getProduct(this.productId)
      .subscribe(({ responseObject = {} }) => {
        this.productSizes = responseObject.productSizes ?? [];
      });
  }

  onSetOverride(stone: ProductStone) {
    stone.isOverride = !stone.isOverride;
    if (!stone.isOverride) {
      stone.overrideStoneSize = null;
    }
  }

  initiateFilters() {
    this.filteredStones = this.stoneControl.valueChanges.pipe(
      startWith(this.selectedStone?.stoneType.name),
      map((value) => this._filterStones(value)),
    );
  }

  private _filterStones(name: string): RivaGems[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return (
        this.stones.data.filter(
          (option) =>
            option.stoneType?.name?.toLowerCase().includes(filterValue) ||
            option.stoneClarity?.name?.toLowerCase().includes(filterValue) ||
            option.stoneCut?.name?.toLowerCase().includes(filterValue) ||
            option.stoneColor?.colorName?.toLowerCase().includes(filterValue) ||
            option.stoneSize.displayText?.toLowerCase().includes(filterValue),
        ) ?? []
      );
    }
    return this.stones.data ?? [];
  }

  getStones() {
    this.stoneService.getStones().subscribe((stones) => {
      this.stones.data = stones;
      this.onFilter();
    });
  }

  getProductStones() {
    this.productBomService
      .getProductStones(this.productId)
      .subscribe((productStones) => {
        if (this.isVariation == null) {
          this.productStones = productStones;
        } else {
          this.productStones = productStones.filter(
            (s) => s.variation === this.isVariation,
          );
        }
        this.onFilter();
      });
  }

  displayFn(item: RivaGems): string {
    return item?.stoneType?.name ?? '';
  }

  onFilter() {
    const {
      stoneTypesID,
      stoneCutsID,
      stoneClaritiesID,
      stoneColorsID,
      labGrown,
      traceable,
    } = this.filter;
    this.stones.filter = JSON.stringify({
      stoneTypesID,
      stoneCutsID,
      stoneClaritiesID,
      stoneColorsID,
      labGrown,
      traceable,
    });
  }
  onSelectStone(stone: RivaGems) {
    const newProductStone = {
      productsBOMStonesID: 0,
      productsID: this.productId,
      stonesID: stone.stonesID,
      qty: 1,
      variation: this.isVariation,
      stone,
      customerProvided: false,
    };
    this.productStones = [newProductStone, ...this.productStones];
    this.onFilter();
  }
  onDeleteStone(stone: ProductStone) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Stone',
        content: 'Are you sure you want to delete this stone?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productStones = this.productStones.filter(
          (p) => p.stonesID !== stone.stonesID,
        );
        if (stone.productsBOMStonesID) {
          this.productBomService
            .deleteProductStones(stone.productsBOMStonesID)
            .subscribe(() => {
              this.loadService.reloadStoneProduct();
            });
        }
        this.onFilter();
      });
  }
  onSave() {
    if (this.isInvalid) return;
    this.isStoneSaving = true;
    const newProductStones = this.productStones.reduce((stones, stone) => {
      const {
        productsBOMStonesID,
        productsID,
        stonesID,
        qty,
        variation,
        customerProvided,
      } = stone;
      return [
        ...stones,
        {
          productsBOMStonesID,
          productsID,
          stonesID,
          qty,
          variation,
          customerProvided,
        },
      ];
    }, []);
    if (!newProductStones.length) {
      this.isStoneSaving = false;
      this.onCloseDialog.emit();
      return;
    }
    this.productBomService.setProductStone(newProductStones).subscribe(() => {
      const hasNewVariation = newProductStones.some(
        (s) => !s.productsBOMStonesID && s.variation,
      );
      const variationOverride =
        this.productStones.find(
          (s) =>
            s.productsBOMStonesID &&
            s.variation &&
            (s.overrides?.length ?? 0) > 0,
        )?.overrides ?? [];
      if (hasNewVariation && variationOverride.length > 0) {
        this.productBomService
          .getProductStones(this.productId)
          .subscribe((productStones) => {
            const stones = productStones.filter(
              (s) => s.variation && (s.overrides?.length ?? 0) === 0,
            );
            const requestsPayload = stones.reduce((accum, s) => {
              const overrideData = variationOverride.map((o) => ({
                productsBOMStonesQTYsID: 0,
                productsBOMStonesID: s.productsBOMStonesID,
                productsSizesID: o.productsSizesID,
                size: o.size,
                qty: o.qty || '',
              }));
              return [...accum, ...overrideData];
            }, []);
            if (requestsPayload.length > 0) {
              this.productBomService
                .setStoneOverride(requestsPayload)
                .subscribe(() => {
                  this.isStoneSaving = false;
                  this.loadService.reloadStoneProduct();
                  this.onCloseDialog.emit();
                });
            } else {
              this.isStoneSaving = false;
              this.loadService.reloadStoneProduct();
              this.onCloseDialog.emit();
            }
          });
      } else {
        this.isStoneSaving = false;
        this.loadService.reloadStoneProduct();
        this.onCloseDialog.emit();
      }
    });
  }

  get isInvalid() {
    return (
      this.productStones.length > 0 && this.productStones.some((s) => !s.qty)
    );
  }

  onOverrideStoneSize(stone: RivaGems) {
    const dialogRef = this.dialog.open(StoneOverrideSizeDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
      data: {
        productId: this.productId,
        stone,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((isReloadStones: boolean) => {
      if (isReloadStones) {
        this.loadService.reloadStoneProduct();
      }
    });
  }

  onOverrideMultipleStoneSize() {
    const dialogRef = this.dialog.open(
      StoneOverrideSizeMultipleDialogComponent,
      {
        disableClose: true,
        maxWidth: '500px',
        width: '100%',
        data: {
          productId: this.productId,
          stones: [...this.productStones],
        },
        autoFocus: false,
      },
    );
    dialogRef.afterClosed().subscribe((isReloadStones: boolean) => {
      if (isReloadStones) {
        this.loadService.reloadStoneProduct();
      }
    });
  }
}
