import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ProductRoutingCodes } from 'src/app/models/product';
import { ChainService } from 'src/app/services/chain.service';
import { RoutingCodeService } from 'src/app/services/routing-code.service';
import { ConfirmationService } from '../../riva-confirmation/riva-confirmation.service';
import { ChainRouting } from '../riva-chain/models';
import { ChainRoutingCopyDialogComponent } from './chain-routing-copy-dialog.component';
import { ChainRoutingDialogComponent } from './chain-routing-dialog.component';

@Component({
  selector: 'chain-routing',
  templateUrl: './chain-routing.component.html',
  styleUrls: ['./chain-routing.component.scss'],
})
export class ChainRoutingComponent implements OnChanges {
  @Input() chainId: number;
  @Input() chainType: number;
  @Input() readonly?: boolean;
  @Output() onRoutingChange = new EventEmitter<ChainRouting[]>();
  @ViewChild('table') table: MatTable<string>;
  routingDisplayColumn: string[] = [
    'draggable',
    'activityCode',
    'department',
    'activityDescription',
    'standardTime',
    'comment',
    'delete',
  ];
  productRoutings: ChainRouting[] = [];
  productRoutingsForDelete: ChainRouting[] = [];
  productRoutingCodes: ProductRoutingCodes[] = [];
  rawProductRoutingCodes: ProductRoutingCodes[] = [];
  filteredProductRoutingCodes: Observable<ProductRoutingCodes[]>;
  productRoutingControl = new FormControl();
  selectedProductRouting: ProductRoutingCodes;
  isSaving = false;
  dragEnabled = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChainRoutingDialogComponent>,
    private routingCodeService: RoutingCodeService,
    private chainService: ChainService,
    private loadService: LoadService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadChainRoutings();
  }

  loadChainRoutings() {
    if (!this.chainId) {
      this.productRoutings = [];
      this.productRoutingCodes = [];
      this.rawProductRoutingCodes = [];
      this.onRoutingChange.emit(this.productRoutings);
      return;
    }
    forkJoin([
      this.routingCodeService.getAll(),
      this.chainService.getChainRoutings(this.chainId, this.chainType),
    ]).subscribe(([productRoutingCodes, chainRoutings]) => {
      this.productRoutingCodes = [...productRoutingCodes];
      this.rawProductRoutingCodes = [...productRoutingCodes];
      this.productRoutings = chainRoutings;
      this.onRoutingChange.emit(this.productRoutings);
      this.initiateFilters();
    });
  }

  onCheckProductRoutingCode() {
    this.productRoutingCodes = this.rawProductRoutingCodes.filter(
      (productRoutingCode) => {
        return !this.productRoutings.some(
          (productRouting) =>
            productRouting.routingCode?.routingCodesId ===
            productRoutingCode.routingCodesId,
        );
      },
    );
  }

  displayFn(item: ProductRoutingCodes): string {
    return item?.activityCode ?? '';
  }

  onSelectProductRoutingCode(productRoutingCode: ProductRoutingCodes) {
    const productRouting: ChainRouting = {
      chainId: this.chainId,
      chainType: this.chainType,
      routingCodesId: productRoutingCode.routingCodesId,
      chainRoutingId: 0,
      standardTime: 0,
      comment: '',
      routingCode: productRoutingCode,
      tempRoutingId: +new Date(),
      routingId: 0,
    };
    this.productRoutings = [...this.productRoutings, productRouting];

    this.selectedProductRouting = null;
  }

  initiateFilters() {
    this.filteredProductRoutingCodes =
      this.productRoutingControl.valueChanges.pipe(
        startWith(this.selectedProductRouting?.activityCode),
        map((value) => this._filterProductRoutingCode(value)),
      );
  }

  private _filterProductRoutingCode(name: string): ProductRoutingCodes[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.productRoutingCodes.filter(
        (option) =>
          option.activityCode.toLowerCase().includes(filterValue) ||
          option.department.toLowerCase().includes(filterValue) ||
          option.activityDesc.toLowerCase().includes(filterValue),
      );
    }
    return this.productRoutingCodes;
  }

  dropTable(event: CdkDragDrop<string[]>) {
    const prevIndex = this.productRoutings.findIndex(
      (d) => d === event.item.data,
    );
    moveItemInArray(this.productRoutings, prevIndex, event.currentIndex);
  }

  onSaveProductRouting() {
    if (!this.productRoutings?.length) {
      this.dialogRef.close();
      return;
    }
    const data = this.productRoutings.map(({ routingCode, ...p }, index) => ({
      ...p,
      routingOrder: index + 1,
      chainRoutingId: p.routingId ?? 0,
    }));
    this.loadService.isSavingChainRouting = true;
    this.chainService.setChainRouting(data).subscribe(
      () => {
        this.loadService.isSavingChainRouting = false;
        this.dialogRef.close();
      },
      () => {
        this.loadService.isSavingChainRouting = false;
      },
    );
  }
  onDeleteProductRouting(productRouting: ChainRouting) {
    this.confirmationService
      .showConfirmation({
        content: `Continue to delete ${productRouting.routingCode.activityCode} from Chain Routing?`,
        title: 'Delete chain routing?',
        confirmLabel: 'Continue',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (productRouting.routingId) {
            this.productRoutings = this.productRoutings.filter(
              (p) => p.routingId !== productRouting.routingId,
            );
            this.chainService
              .deleteChainRouting(productRouting.routingId)
              .subscribe();
          } else {
            this.productRoutings = this.productRoutings.filter(
              (p) => p.tempRoutingId !== productRouting.tempRoutingId,
            );
          }
        }
      });
  }
  onCopyRoutingOpen() {
    const dialogRef = this.dialog.open(ChainRoutingCopyDialogComponent, {
      disableClose: true,
      maxWidth: '1000px',
      width: '100%',
      autoFocus: false,
      data: {
        chainType: this.chainType,
      },
    });
    dialogRef.afterClosed().subscribe((newRoutings) => {
      if (!newRoutings) return;
      this.productRoutings = newRoutings.map((r, index) => ({
        ...r,
        productsRoutingId: 0,
        tempRoutingId: +new Date() + index,
        chainId: this.chainId,
        chainType: this.chainType,
      }));
    });
  }
}
