<div class="product-findings">
  <div class="tab-container" style="margin-top: 30px">
    <nz-tabset nzType="card" [nzSelectedIndex]="selectedTabIndex">
      <nz-tab nzTitle="Jumprings">
        <bom-finding-jumpring
          [findings]="findingsList"
          [selectedJumpring]="selectedFinding"
          (onSelectFinding)="onSelectFinding($event)"
        ></bom-finding-jumpring>
      </nz-tab>
      <nz-tab nzTitle="Posts">
        <bom-finding-post
          [findings]="findingsList"
          [selectedPost]="selectedFinding"
          (onSelectFinding)="onSelectFinding($event)"
        ></bom-finding-post>
      </nz-tab>
      <nz-tab nzTitle="Flyers">
        <bom-findings
          [findings]="findingsList"
          [selectedFinding]="selectedFinding"
          [findingsType]="findingsType.FLYER"
          (onSelectFinding)="onSelectFinding($event)"
        ></bom-findings>
      </nz-tab>
      <nz-tab nzTitle="Springs">
        <bom-findings
          [findings]="findingsList"
          [selectedFinding]="selectedFinding"
          [findingsType]="findingsType.SPRING"
          (onSelectFinding)="onSelectFinding($event)"
        ></bom-findings>
      </nz-tab>
      <nz-tab nzTitle="Springrings">
        <bom-findings
          [findings]="findingsList"
          [selectedFinding]="selectedFinding"
          [findingsType]="findingsType.SPRING_RING"
          (onSelectFinding)="onSelectFinding($event)"
        ></bom-findings>
      </nz-tab>
      <nz-tab nzTitle="Lobster Claw">
        <bom-findings
          [findings]="findingsList"
          [selectedFinding]="selectedFinding"
          [findingsType]="findingsType.LOBSTER_CLAW"
          (onSelectFinding)="onSelectFinding($event)"
        ></bom-findings>
      </nz-tab>
      <nz-tab nzTitle="Tags">
        <bom-findings
          [findings]="findingsList"
          [selectedFinding]="selectedFinding"
          [findingsType]="findingsType.TAGS"
          (onSelectFinding)="onSelectFinding($event)"
        ></bom-findings>
      </nz-tab>
    </nz-tabset>
  </div>
  <!-- <div class="product-findings__findings-header">
    <div class="product-findings__row-1">
      <div class="findings__search">
        <mat-form-field class="search-input" appearance="fill">
          <mat-label>Search finding</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="selectedFindings"
            (ngModelChange)="onFindingsChange()"
            [formControl]="findingsControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="displayFn"
          >
            <mat-option class="riva-select-header riva-select__stick-header">
              <div class="riva-select">
                <div class="p5 riva-select__sku">SKU</div>
                <div class="p5 riva-select__name">Name</div>
              </div>
            </mat-option>
            <mat-option class="riva-select-header">
              <div class="riva-select">
                <div class="p5 riva-select__sku">SKU</div>
                <div class="p5 riva-select__name">Name</div>
              </div>
            </mat-option>
            <mat-option
              *ngFor="let findings of filteredFindings | async"
              [value]="findings"
              style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
            >
              <div class="country-container riva-select">
                <div class="p5 riva-select__sku">{{ findings.sku }}</div>
                <div class="p5 riva-select__name">
                  {{ findings.name }}
                </div>
              </div>
            </mat-option>
            <mat-option *ngIf="!(filteredFindings | async)?.length">
              <div class="text-center riva-select__not-found">Not Found</div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Size</mat-label>
        <mat-select [(ngModel)]="selectedSize">
          <mat-option
            *ngFor="let size of selectedFindings?.sizes"
            [value]="size"
          >
            {{ size.size }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Qty</mat-label>
        <input
          matInput
          [(ngModel)]="qty"
          type="number"
          style="color: white"
          min="1"
        />
      </mat-form-field>
    </div>
    <div class="product-findings__row-2">
      <div>
        <div class="override-material">
          <mat-slide-toggle
            [(ngModel)]="isOverride"
            (ngModelChange)="isOverrideChange()"
            >Override Material</mat-slide-toggle
          >
        </div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Material Code</mat-label>
          <mat-select [(ngModel)]="selectedMaterial" [disabled]="!isOverride">
            <mat-option
              *ngFor="let mat of selectedFindings?.materials"
              [value]="mat"
            >
              {{ mat.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>Comment</mat-label>
        <textarea
          matInput
          [(ngModel)]="comment"
          style="height: 42px; color: white"
        ></textarea>
      </mat-form-field>
      <button
        class="riva-button"
        style="min-width: 150px; width: 150px"
        [disabled]="!isFormValid"
        (click)="addProductFindings()"
      >
        Add
      </button>
    </div>
  </div> -->
</div>
