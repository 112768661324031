<h3 mat-dialog-title>Finding Routing</h3>

<mat-dialog-content class="mat-typography">
  <finding-routing
    [findingsID]="props.findingId"
    [readonly]="!props.editMode"
  ></finding-routing>
</mat-dialog-content>

<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      width: 400px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <fieldset userPermission [elementKey]="featureKey.edit">
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        (click)="onSave()"
        [disabled]="loadService.isSavingFindingRouting || !props.editMode"
      >
        Save
      </button>
    </fieldset>
  </div>
</mat-dialog-actions>
