import { Component, HostListener, OnInit } from '@angular/core';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-work-order-label-printing',
  templateUrl: './work-order-label-printing.component.html',
  styleUrls: ['./work-order-label-printing.component.scss'],
  animations: [fadeAnimation],
})
export class WorkOrderLabelPrintingComponent implements OnInit {
  isScanning = false;
  hasError = false;
  successPrinting = false;
  code = '';
  workOrderId: number;

  constructor(
    private userPermissionService: UserPermissionService,
    private printService: PrintService,
  ) {
    this.userPermissionService.checkPagePermission(
      PAGE_NAME.workOrderLabelPrinting,
    );
  }

  ngOnInit(): void {}

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (this.isScanning) return;
    const isAlphanumeric = /^[a-zA-Z0-9-]$/.test(event.key);
    const isValidKey = event.key === 'Enter' || isAlphanumeric;
    if (!isValidKey) return;

    if (event.key === 'Enter') {
      this.processingRequest();
    } else {
      this.code = `${this.code}${event.key}`;
    }
  }

  processingRequest() {
    if (!isNaN(Number(this.code)) && !this.workOrderId) {
      const workOrderId = parseInt(this.code);
      this.onScanWorkOrder(workOrderId);
    }
  }

  onScanWorkOrder(workOrderId) {
    this.isScanning =  true;
    this.printService.printLabel(workOrderId).subscribe((data) => {
      this.successPrinting = true;
      setTimeout(() => {
        this.code = '';
        this.isScanning = false;
        this.workOrderId = 0;
        this.successPrinting = false;
      }, 1200);
    }, () => {
      this.hasError = true;
    })
  }

  onCloseErrorMessage() {
    this.code = '';
    this.isScanning = false;
    this.workOrderId = 0;
  }
}
