<div class="riva-reporting-work-order-moved">
  <div class="selection-section">
    <div class="selection-filter">
      <mat-form-field appearance="fill" style="width: 100%; color: white">
        <mat-label>Search products</mat-label>
        <input
          type="text"
          matInput
          [ngModel]="selectedProductLookup"
          (ngModelChange)="onSelectProduct($event)"
          [formControl]="productLookupControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="displayValue"
        >
          <mat-option
            *ngFor="let option of filteredProductLookup | async"
            [value]="option"
          >
            {{ option.productName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Operation</mat-label>
        <mat-select
          [(ngModel)]="selectedRoutingCodeId"
          (ngModelChange)="getActivitySummary()"
        >
          <mat-option
            *ngFor="let activity of productActivity"
            [value]="activity.routingCodesId"
            >{{ activity.activityDesc }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <div class="median-time">
        <h2 style="color: white">Median Time:</h2>
        <h2 class="median-time-value">{{ selectedMedianTime || 0 }}</h2>
      </div>
    </div>
    <div class="table-container">
      <table
        mat-table
        matSort
        [dataSource]="activitySummary"
        matSortActive="workOrdersId"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="workOrdersId">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by workOrdersId"
          >
            Work Order #
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.workOrdersId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="activityDesc">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by activityDesc"
          >
            Operation you selected
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.activityDesc }}
          </td>
        </ng-container>
        <ng-container matColumnDef="employeeName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by employeeName"
          >
            Employee
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.employeeName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="scanInTime">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by scanInTime"
          >
            Check In
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.scanInTimeString }}
          </td>
        </ng-container>
        <ng-container matColumnDef="operationResult">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by operationResult"
          >
            (Time of operations / work order QTY)
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.operationResult }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="gems-row"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="5">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
</div>
