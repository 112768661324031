<div class="stone-inventory-adjustment-dialog">
  <h3 mat-dialog-title>Stone Inventory Adjustment</h3>
  <mat-dialog-content
    class="mat-typography stone-inventory-adjustment-dialog__container"
  >
    <div>
      <mat-button-toggle-group
        class="view-type-toggle"
        [(ngModel)]="inventoryAdjustment.appendType"
        (ngModelChange)="onChangeAppendType()"
      >
        <mat-button-toggle [value]="false" style="height: 55px; width: 50%"
          >Inventory Adjust</mat-button-toggle
        >
        <mat-button-toggle [value]="true" style="height: 55px; width: 50%"
          >Stone Purchase</mat-button-toggle
        >
      </mat-button-toggle-group>
      <div class="column-2">
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Qty</mat-label>
          <input
            matInput
            appNumberOnly
            [allowNegative]="true"
            [minValue]="qtyMinValue"
            [maxValue]="qtyMaxValue"
            [(ngModel)]="inventoryAdjustment.qtyMoved"
            (ngModelChange)="onUpdateQty()"
            [disabled]="inventoryAdjustment.inventoryValue === 'carats'"
          />
        </mat-form-field>
        <div style="position: relative">
          <mat-form-field
            class="search-input"
            appearance="fill"
            style="width: 100%"
          >
            <mat-label>Carats</mat-label>
            <input
              matInput
              type="number"
              min="-999.9999"
              max="999.9999"
              [(ngModel)]="inventoryAdjustment.caratsMoved"
              (ngModelChange)="onUpdateCarats()"
              [disabled]="
                !data.stone.caratsPerUnit ||
                inventoryAdjustment.inventoryValue === 'qty'
              "
            />
          </mat-form-field>

          <div
            style="position: absolute; right: 12px; top: 16px; cursor: pointer"
            title="No carat per unit available"
            *ngIf="!data.stone.caratsPerUnit"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-alert-triangle"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ff9300"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 9v4" />
              <path
                d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"
              />
              <path d="M12 16h.01" />
            </svg>
          </div>
        </div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Provided By</mat-label>
          <mat-select
            [(ngModel)]="inventoryAdjustment.customerProvided"
            [disabled]="inventoryAdjustment.appendType"
          >
            <mat-option [value]="0"> None </mat-option>
            <mat-option
              *ngFor="let customer of customersList"
              [value]="customer.custIdno"
            >
              {{ customer.companyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Date</mat-label>
          <input
            matInput
            [matDatepicker]="datePicker"
            [(ngModel)]="inventoryAdjustment.movementDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Supplier</mat-label>
          <mat-select
            [(ngModel)]="inventoryAdjustment.suppliersID"
            [disabled]="!inventoryAdjustment.appendType"
          >
            <mat-option
              *ngFor="let supplier of suppliers"
              [value]="supplier.suppliersId"
            >
              {{ supplier.companyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="search-input"
          appearance="fill"
          style="width: 100%"
        >
          <mat-label>Price per carat</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="inventoryAdjustment.pricePerCarat"
            [disabled]="!inventoryAdjustment.appendType"
          />
        </mat-form-field>
      </div>
      <mat-form-field
        class="search-input"
        appearance="fill"
        style="width: 100%"
      >
        <mat-label>Comments</mat-label>
        <textarea
          matInput
          [(ngModel)]="inventoryAdjustment.reason"
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>
    <!-- <div class="stone-inventory-container">
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Qty</mat-label>
          <input
            matInput
            appNumberOnly
            [allowNegative]="true"
            [minValue]="qtyMinValue"
            [maxValue]="qtyMaxValue"
            [(ngModel)]="inventoryAdjustment.qtyMoved"
            (ngModelChange)="onUpdateQty()"
            [disabled]="inventoryAdjustment.inventoryValue === 'carats'"
          />
        </mat-form-field>
        <mat-form-field
          class="search-input"
          appearance="fill"
          style="width: 100%"
        >
          <mat-label>Carats</mat-label>
          <input
            matInput
            type="number"
            min="-999.9999"
            max="999.9999"
            [(ngModel)]="inventoryAdjustment.caratsMoved"
            (ngModelChange)="onUpdateCarats()"
            [disabled]="inventoryAdjustment.inventoryValue === 'qty'"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Customer Provider</mat-label>
          <mat-select [(ngModel)]="inventoryAdjustment.customerProvided">
            <mat-option [value]="0"> None </mat-option>
            <mat-option
              *ngFor="let customer of customersList"
              [value]="customer.custIdno"
            >
              {{ customer.companyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-button-toggle-group
          class="view-type-toggle"
          [(ngModel)]="inventoryAdjustment.appendType"
        >
          <mat-button-toggle [value]="false" style="height: 55px; width: 50%"
            >Inventory Adjust</mat-button-toggle
          >
          <mat-button-toggle [value]="true" style="height: 55px; width: 50%"
            >Stone Purchase</mat-button-toggle
          >
        </mat-button-toggle-group>
        <div class="purchase-details" *ngIf="inventoryAdjustment.appendType">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Supplier</mat-label>
            <mat-select [(ngModel)]="inventoryAdjustment.suppliersID">
              <mat-option
                *ngFor="let supplier of suppliers"
                [value]="supplier.suppliersId"
              >
                {{ supplier.companyName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="search-input"
            appearance="fill"
            style="width: 100%"
          >
            <mat-label>Price per carat</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="inventoryAdjustment.pricePerCarat"
            />
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            style="width: 100%; grid-column: 1 / span 2"
          >
            <mat-label>Date</mat-label>
            <input
              matInput
              [matDatepicker]="datePicker"
              [(ngModel)]="inventoryAdjustment.movementDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-form-field
          class="search-input"
          appearance="fill"
          style="width: 100%"
        >
          <mat-label>Reason</mat-label>
          <textarea
            matInput
            [(ngModel)]="inventoryAdjustment.reason"
            rows="4"
          ></textarea>
        </mat-form-field>
      </div>
    </div> -->
  </mat-dialog-content>

  <mat-dialog-actions
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 10px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onSave()"
      [disabled]="isSaving || !isFormValid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
