import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PRODUCT_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/product-permission';
import { ProductDetail } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { SubItemService } from 'src/app/services/sub-item.service';
import { RivaStlViewerDialogComponent } from '../riva-stl-viewer/riva-stl-viewer-dialog.component';
import { SubItem } from './../../models/sub-item.model';
import { SubItemsConfirmationComponent } from './sub-items-confirmation.component';
import { SubItemsDialogComponent } from './sub-items-dialog.component';

@Component({
  selector: 'app-sub-items',
  templateUrl: './sub-items.component.html',
  styleUrls: ['./sub-items.component.scss'],
})
export class SubItemsComponent implements OnChanges, AfterViewInit {
  @Input() productId: number;
  @Input() editMode: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  subItems = new MatTableDataSource<SubItem>([]);
  productFeatureKey = PRODUCT_FEATURE_KEY;
  productDetail: ProductDetail = {
    materials: [],
    productSizes: [],
  } as ProductDetail;
  search = '';

  displayedColumns: string[];

  constructor(
    private subItemService: SubItemService,
    private productService: ProductService,
    public dialog: MatDialog,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.subItems.data = [];
    this.productDetail = {
      materials: [],
      productSizes: [],
    } as ProductDetail;
    if (this.productId) {
      this.getSubItems();
      this.getProductDetail();
    }
    const dataColumn = [
      'picPath',
      'name',
      'description',
      'uom',
      'batchQTY',
      'moldingOptionText',
    ];
    const actionColumn = ['edit', 'delete'];
    this.displayedColumns = this.editMode
      ? [...dataColumn, ...actionColumn]
      : dataColumn;
  }
  ngAfterViewInit() {
    this.subItems.sort = this.sort;
    this.subItems.paginator = this.paginator;
    this.subItems.filterPredicate = (data: SubItem, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.name.toString().toLowerCase().includes(search?.toLowerCase()) ||
        data.description.toLowerCase().includes(search?.toLowerCase()) ||
        data.uom.toString().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  getSubItems() {
    this.subItemService.getSubItems(this.productId).subscribe((data) => {
      this.subItems.data = data?.map((item) => ({
        ...item,
        subItemsInfo: item.subItemsInfo.length
          ? item.subItemsInfo?.map((subItemInfo) => ({
              ...subItemInfo,
              size: subItemInfo.productsSizeID === 0 ? 'All' : subItemInfo.size,
              mfgMethodDetails:
                subItemInfo?.mfgMethod === 3
                  ? ''
                  : subItemInfo?.mfgMethodDetails,
              stlPath:
                subItemInfo?.mfgMethod === 3 &&
                subItemInfo?.mfgMethodDetails != null &&
                subItemInfo?.mfgMethodDetails != 'null'
                  ? encodeURI(subItemInfo?.mfgMethodDetails)
                  : '',
            }))
          : null,
      }));
    });
  }

  onDialogOpen(
    subItem: Partial<SubItem> = {
      subItemsID: 0,
      productsID: this.productId,
      batchQTY: 1,
    },
  ) {
    const dialogRef = this.dialog.open(SubItemsDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
      data: {
        sizes: this.productDetail.productSizes,
        subItem: { ...subItem },
      },
    });
    dialogRef.afterClosed().subscribe((result: SubItem) => {
      if (result.subItemsID) {
        this.getSubItems();
      }
    });
  }

  onDelete(id: number) {
    const dialogRef = this.dialog.open(SubItemsConfirmationComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
    });
    dialogRef.afterClosed().subscribe((isDelete: boolean) => {
      if (isDelete) {
        this.subItemService.deleteSubItem(id).subscribe((result) => {
          result && this.getSubItems();
        });
      }
    });
  }

  onFilterChange() {
    this.subItems.filter = JSON.stringify({ search: this.search });
  }
  onViewStlFile(subItem, subItemInfo) {
    this.dialog.open(RivaStlViewerDialogComponent, {
      disableClose: true,
      maxWidth: '1000px',
      width: '100%',
      data: {
        subItemsInfoID: subItemInfo.subItemsInfoID,
        stlFileName: `${subItem.name
          ?.split(' ')
          .join('-')
          .toLowerCase()}-size-${subItemInfo.size}`,
      },
    });
  }
  getProductDetail() {
    this.productDetail = {
      materials: [],
      productSizes: [],
    } as ProductDetail;
    this.productService.getProductDetail(this.productId).subscribe((data) => {
      this.productDetail = data;
    });
  }
}
