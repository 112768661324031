<div
  class="stone-product stone-product__container"
  *ngIf="!tableOnly || productStones.length > 0"
>
  <div
    *ngIf="tableTitle"
    style="
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <span>{{ tableTitle }}</span>
    <button
      class="btn-riva btn-warning"
      style="font-size: 10px; width: 130px"
      (click)="onOverrideMultipleStoneSize()"
      *ngIf="tableOnly && editMode && isVariation"
    >
      Per Size Override
    </button>
  </div>

  <div class="stone-table" [ngClass]="{ 'one-column': tableOnly }">
    <div class="filter-form-container" *ngIf="!tableOnly">
      <h4 class="filter-form-title">Filters</h4>
      <div class="filter-forms">
        <mat-form-field
          class="search-input"
          appearance="fill"
          style="color: white"
        >
          <mat-label>Search by size</mat-label>
          <input
            matInput
            [(ngModel)]="searchText"
            (ngModelChange)="onFilter()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Filter by type</mat-label>
          <mat-select
            [(ngModel)]="filter.stoneTypesID"
            (ngModelChange)="onFilter()"
          >
            <mat-option
              *ngFor="let type of stoneTypes"
              [value]="type.stoneTypesID"
            >
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Filter by cut</mat-label>
          <mat-select
            [(ngModel)]="filter.stoneCutsID"
            (ngModelChange)="onFilter()"
          >
            <mat-option *ngFor="let cut of stoneCuts" [value]="cut.stoneCutsID">
              {{ cut.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Filter by clarity</mat-label>
          <mat-select
            [(ngModel)]="filter.stoneClaritiesID"
            (ngModelChange)="onFilter()"
          >
            <mat-option
              *ngFor="let clarity of stoneClarities"
              [value]="clarity.stoneClaritiesID"
            >
              {{ clarity.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Filter by color</mat-label>
          <mat-select
            [(ngModel)]="filter.stoneColorsID"
            (ngModelChange)="onFilter()"
          >
            <mat-option
              *ngFor="let color of stoneColors"
              [value]="color.stoneColorsID"
            >
              {{ color.colorName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Filter by lab grown</mat-label>
          <mat-select
            [(ngModel)]="filter.labGrown"
            (ngModelChange)="onFilter()"
          >
            <mat-option [value]="1"> Yes </mat-option>
            <mat-option [value]="0"> No </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Filter by traceable</mat-label>
          <mat-select
            [(ngModel)]="filter.traceable"
            (ngModelChange)="onFilter()"
          >
            <mat-option [value]="1"> Yes </mat-option>
            <mat-option [value]="0"> No </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div>
      <div class="product-stone__stone-header" *ngIf="!tableOnly">
        <div
          [ngClass]="{ 'display-only': tableOnly }"
          style="border: 1px solid #646464"
        >
          <mat-paginator
            [pageSizeOptions]="[6, 10, 25, 50, 100]"
            showFirstLastButtons
            style="
              border-bottom: 1px solid rgba(255, 255, 255, 0.12);
              border-top: 1px solid rgba(255, 255, 255, 0.12);
            "
          ></mat-paginator>
          <table
            mat-table
            [dataSource]="stones"
            matSort
            matSortActive="stonesID"
            matSortDirection="desc"
          >
            <ng-container matColumnDef="pic">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div
                  style="
                    width: 40px;
                    padding: 5px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                  "
                  *ngIf="element.picturePath"
                >
                  <img
                    style="max-width: 100%; max-height: 100%"
                    [src]="element.picturePath"
                  />
                  <span
                    class="img-custom-work-order work-order-table"
                    *ngIf="element.customized"
                    >CUSTOM</span
                  >
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="desc"
                sortActionDescription="Sort by type"
              >
                Type
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.stoneType?.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="cut">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="asc"
                sortActionDescription="Sort by cut"
              >
                Cut
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.stoneCut?.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="clarity">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="asc"
                sortActionDescription="Sort by clarity"
              >
                Clarity
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.stoneClarity?.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="color">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="asc"
                sortActionDescription="Sort by color"
              >
                Color
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.stoneColor?.colorName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="size">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="asc"
                sortActionDescription="Sort by sizeD1"
              >
                Size
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.stoneSize?.displayText }}
              </td>
            </ng-container>
            <ng-container matColumnDef="labGrown">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="asc"
                sortActionDescription="Sort by labGrown"
              >
                Lab Grown
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.labGrown ? 'Yes' : 'No' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="traceable">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="asc"
                sortActionDescription="Sort by traceable"
              >
                Traceable
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.traceable ? 'Yes' : 'No' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="add">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-circle-plus"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="#896691"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style="cursor: pointer"
                  (click)="onSelectStone(element)"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="9" y1="12" x2="15" y2="12" />
                  <line x1="12" y1="9" x2="12" y2="15" />
                </svg>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedStoneColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedStoneColumns"
            ></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell text-center" colspan="10">
                No Available Data
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div
        [ngClass]="{ 'display-only': tableOnly }"
        style="border: 1px solid #646464; margin-top: 10px"
      >
        <table mat-table [dataSource]="productStones">
          <ng-container matColumnDef="variation">
            <th mat-header-cell *matHeaderCellDef>Variation</th>
            <td mat-cell *matCellDef="let element">
              <mat-slide-toggle
                [(ngModel)]="element.variation"
              ></mat-slide-toggle>
            </td>
          </ng-container>
          <ng-container matColumnDef="pic">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <div
                style="
                  width: 45px;
                  padding: 5px;
                  height: 45px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                "
                *ngIf="element.stone.picturePath"
              >
                <img
                  style="max-width: 100%; max-height: 100%"
                  [src]="element.stone.picturePath"
                />
                <span
                  class="img-custom-work-order work-order-table"
                  *ngIf="element.customized"
                  >CUSTOM</span
                >
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stone.stoneType.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="cut">
            <th mat-header-cell *matHeaderCellDef>Cut</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stone.stoneCut.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="clarity">
            <th mat-header-cell *matHeaderCellDef>Clarity</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stone.stoneClarity.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef>Color</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stone.stoneColor.colorName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef>Size</th>
            <td mat-cell *matCellDef="let element">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                {{ element.stone.stoneSize.displayText }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="qty">
            <th mat-header-cell *matHeaderCellDef>Qty</th>
            <td mat-cell *matCellDef="let element">
              <nz-input-number
                [(ngModel)]="element.qty"
                [nzMin]="1"
                [nzStep]="1"
                style="width: 100%"
                *ngIf="!element.productsBOMStonesID"
              ></nz-input-number>
              <div
                class="override-size-container"
                *ngIf="element.productsBOMStonesID"
              >
                <div class="override-size">DEFAULT</div>
                <div *ngIf="tableOnly">{{ element.qty }}</div>
                <nz-input-number
                  [(ngModel)]="element.qty"
                  [nzMin]="1"
                  [nzStep]="1"
                  style="width: 100%"
                  nzSize="small"
                  *ngIf="!tableOnly"
                ></nz-input-number>
              </div>
              <div
                class="override-size-container"
                *ngFor="let item of element.overrides"
              >
                <div class="override-size">Size: {{ item.size }}</div>
                <div>{{ item.qty }}</div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <i
                nz-icon
                nzType="delete"
                nzTheme="fill"
                class="delete-icon"
                (click)="onDeleteStone(element)"
              ></i>
            </td>
          </ng-container>
          <ng-container matColumnDef="override">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button
                class="btn-riva btn-riva-sm btn-warning"
                style="font-size: 10px; width: 130px"
                (click)="onOverrideStoneSize(element)"
                *ngIf="tableOnly && editMode"
              >
                Per Size Override
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="labGrown">
            <th mat-header-cell *matHeaderCellDef>Lab Grown</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stone.labGrown ? 'Yes' : 'No' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="traceable">
            <th mat-header-cell *matHeaderCellDef>Traceable</th>
            <td mat-cell *matCellDef="let element">
              {{ element.stone.traceable ? 'Yes' : 'No' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="customerProvided">
            <th mat-header-cell *matHeaderCellDef>Customer Provided</th>
            <td mat-cell *matCellDef="let element">
              <mat-slide-toggle
                [(ngModel)]="element.customerProvided"
                *ngIf="!tableOnly"
              ></mat-slide-toggle>
              <span *ngIf="tableOnly">
                {{ element.customerProvided ? 'Yes' : 'No' }}
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="10">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
