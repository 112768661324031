import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { INVOICING_SHIPPING_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/invoicing-shipping-permission';
import { Customers } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import {
  InvoiceService,
  invoiceMapper,
} from 'src/app/services/invoice.service';
import { ConfirmationService } from './../../riva-confirmation/riva-confirmation.service';
import { Invoice } from './models/invoice';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'invoicing',
  templateUrl: './invoicing.component.html',
  styleUrls: ['./invoicing.component.scss'],
})
export class InvoicingComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  invoices = new MatTableDataSource<Invoice>([]);
  selectedCustomer = 0;
  customers: Customers[];
  filteredCustomers: Customers[];
  customerFilterCtrl = new FormControl();
  featureKey = INVOICING_SHIPPING_FEATURE_KEY;
  searchQueryChange: Subject<string> = new Subject<string>();

  displayedColumns: string[] = [
    'id',
    'invoicedDate',
    'customer',
    'qty',
    'totalDue',
    'invoicePaid',
  ];
  searchQuery = '';
  pagination = {
    totalCount: 0,
  };
  invoiceParams = {
    SortBy: 'invoicesID',
    SortDirection: 'desc',
    PageNumber: 1,
    PageSize: 25,
    SearchQuery: '',
    CustomerId: 0,
  };
  @ViewChild('paginator') paginator;

  filters: Filters = {
    dateRangeFrom: DateTime.local().toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.local().toFormat('yyyy-MM-dd'),
  };
  isExportingFile = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private invoiceService: InvoiceService,
    private customerService: CustomerService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.getInvoices(false);
    this.customerService.getList().subscribe((customers) => {
      this.customers = orderBy(customers, 'companyName');
      this.filteredCustomers = [...this.customers];
      this.customerFilterCtrl.valueChanges.subscribe(() => {
        this.filterCustomers();
      });
    });
    this.searchQueryChange
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((searchQuery) => {
        this.invoiceParams.SearchQuery = searchQuery;
        this.getInvoices();
      });
  }

  getInvoices(resetPage = true) {
    if (resetPage) {
      this.paginator._pageIndex = 0;
      this.invoiceParams.PageNumber = 1;
    }
    this.invoiceService
      .getPagedInvoices(this.invoiceParams)
      .subscribe(({ body, headers }) => {
        const paginationData = headers.get('X-Pagination');
        this.pagination =
          paginationData != null
            ? JSON.parse(paginationData)
            : { totalCount: 0 };
        this.invoices.data = body.map(invoiceMapper);
      });
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe((sort) => {
      this.invoiceParams.SortBy = sort.active;
      this.invoiceParams.SortDirection = sort.direction;
      this.getInvoices();
    });
    this.invoices.sort = this.sort;
    this.invoices.filterPredicate = (data: Invoice, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.invoicesID?.toString().includes(search?.toLowerCase()) ||
        data.invoicedDate
          ?.toLocaleLowerCase()
          .includes(search?.toLowerCase()) ||
        data.customer?.companyName
          ?.toLocaleLowerCase()
          .includes(search?.toLowerCase());
      return this.selectedCustomer > 0
        ? data.custIDNo === this.selectedCustomer && searchFilter
        : searchFilter;
    };
  }

  goToInvoice(invoiceId = 0) {
    this.router.navigate([`invoicing-and-shipping/invoicing/${invoiceId}`]);
  }

  onSearch() {
    this.searchQueryChange.next(this.searchQuery);
  }

  filterCustomers() {
    this.filteredCustomers = orderBy(
      this.customers.filter((customer) =>
        customer.companyName
          ?.toLocaleLowerCase()
          .includes(this.customerFilterCtrl.value?.trim()),
      ),
      'companyName',
    );
  }

  onPaidInvoices(invoice: Invoice) {
    this._confirmationService
      .showConfirmation({
        title: 'Paid invoice?',
        content: 'Has this invoice already been paid?',
        confirmLabel: 'Yes',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        const data = {
          invoicesID: invoice.invoicesID,
          metalMarketID: invoice.metalMarket?.metalMarketID ?? 0,
          invoicePaid: DateTime.local(),
          shippingCost: invoice.shippingCost,
          internalComment: invoice.internalComment,
          externalComment: invoice.externalComment,
        };
        this.invoiceService.updateInvoices(data).subscribe(() => {
          this.getInvoices();
        });
      });
  }
  onPageChanged(event: PageEvent) {
    this.invoiceParams.PageNumber = event.pageIndex + 1;
    this.getInvoices(false);
  }
  onDateRangeChange({ start, end }) {
    this.filters.dateRangeFrom = start;
    this.filters.dateRangeTo = end;
  }
  onDownloadExcelFile() {
    this.isExportingFile = true;
    this.invoiceService
      .getInvoicesExcel({
        from: this.filters.dateRangeFrom,
        to: this.filters.dateRangeTo,
      })
      .subscribe((response) => {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Invoices (${this.filters.dateRangeFrom
          .split('-')
          .join('')} - ${this.filters.dateRangeTo.split('-').join('')}).xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.isExportingFile = false;
      });
  }
}
