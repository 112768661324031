<div class="shipping-detail">
  <div class="shipping-detail__header">
    <div style="display: flex; align-items: center">
      <i
        nz-icon
        nzType="left-circle"
        nzTheme="fill"
        style="margin-right: 20px; cursor: pointer; display: block !important"
        title="Back to Invoicing And Shipping Page"
        (click)="goToInvoicingAndShipping()"
      ></i>
      <span>Shipping Detail</span>
    </div>
    <button
      class="btn riva-button scan-work-order"
      style="margin-left: 10px"
      (click)="onOpenRelatedInvoices()"
      *ngIf="shipmentDetail.shipmentsID"
    >
      <span>Linked Invoices</span>
    </button>
  </div>
  <div class="shipping-detail__container">
    <div class="shipping-detail__info-detail">
      <div class="shipment-detail">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Shipment No</mat-label>
          <input
            matInput
            [ngModel]="shipmentDetail.shipmentsID"
            [disabled]="true"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Shipped Date</mat-label>
          <input
            matInput
            [ngModel]="shipmentDetail.shipDate"
            [disabled]="true"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Customer</mat-label>
          <input
            matInput
            [ngModel]="shipmentDetail.customer.companyName"
            [disabled]="true"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Shipping Address</mat-label>
          <mat-select
            [(ngModel)]="shipmentDetail.shippingAddressID"
            [disabled]="
              !!shipmentDetail.shipmentsID || !shipmentDetail.custIDNo
            "
          >
            <mat-option
              *ngFor="let shipping of shippingAddresses"
              [value]="shipping.customersAddressesId"
            >
              {{ shipping.fullAddress }}
            </mat-option>
            <mat-option (click)="onAddNewAddress()">
              Add New Address
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Tracking #</mat-label>
          <input
            matInput
            [(ngModel)]="shipmentDetail.tracking"
            [disabled]="shipmentDetail.shipmentsID"
          />
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          style="width: 100%; grid-column: 2 / span 3"
        >
          <mat-label>Comments</mat-label>
          <input
            matInput
            [(ngModel)]="shipmentDetail.comment"
            [disabled]="shipmentDetail.shipmentsID"
          />
        </mat-form-field>
      </div>
      <div
        *ngIf="!shipmentDetail.shipmentsID"
        style="display: grid; align-items: center; gap: 15px"
      >
        <button
          class="btn riva-button scan-work-order"
          (click)="onScanWorkOrder()"
          [disabled]="shipmentDetail.shipmentsID"
          userPermission
          [elementKey]="featureKey.create"
        >
          <span>Scan Work Order</span>
        </button>
        <fieldset userPermission [elementKey]="featureKey.create">
          <button
            class="btn btn-success"
            (click)="onSubmitShipment()"
            [disabled]="!shipmentSubmitValid"
          >
            <span>Submit Shipment</span>
          </button>
        </fieldset>
      </div>
      <div
        *ngIf="shipmentDetail.shipmentsID"
        style="display: grid; align-items: center; gap: 15px"
      >
        <fieldset userPermission [elementKey]="featureKey.create">
          <button
            class="btn riva-button scan-work-order"
            (click)="redirectToInvoicing()"
            [disabled]="!generatedInvoiceDetails.length"
          >
            <span>Generate Invoice</span>
          </button>
        </fieldset>
      </div>
    </div>
    <table
      mat-table
      [dataSource]="shipmentDetails"
      matSort
      matSortActive="productName"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="productPicPath">
        <th mat-header-cell *matHeaderCellDef>Pic</th>
        <td mat-cell *matCellDef="let element">
          <div
            style="
              width: 55px;
              padding: 5px;
              height: 55px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            "
            *ngIf="element.productPicPath"
          >
            <img
              style="max-width: 100%; max-height: 100%"
              [src]="element.productPicPath"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="productName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by productName"
        >
          Product Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.productName }}</td>
      </ng-container>
      <ng-container matColumnDef="material">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by material"
        >
          Metal
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.material }}
        </td>
      </ng-container>
      <ng-container matColumnDef="size">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by size"
        >
          Size
        </th>
        <td mat-cell *matCellDef="let element">{{ element.size }}</td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by qty"
        >
          Qty
        </th>
        <td mat-cell *matCellDef="let element">{{ element.qty }}</td>
      </ng-container>
      <ng-container matColumnDef="cipo">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by cipo"
        >
          CIPO
        </th>
        <td mat-cell *matCellDef="let element">{{ element.cipo }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <i
            nz-icon
            nzType="delete"
            nzTheme="fill"
            class="icon-delete"
            *ngIf="!shipmentDetail.shipmentsID"
            (click)="onRemoveShipment(element)"
          ></i>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="7">No Available Data</td>
      </tr>
    </table>
  </div>
</div>
<swal
  #confirmationDialog
  [title]="confirmation.title"
  [text]="confirmation.description"
  [icon]="confirmation.icon"
  confirmButtonText="Close"
  [showConfirmButton]="confirmation.icon === 'error'"
  [timer]="confirmation.timer"
>
</swal>
