<div class="stone-size-dialog">
  <h3 mat-dialog-title>Stone Sizes</h3>
  <mat-dialog-content class="mat-typography">
    <div class="size-forms">
      <mat-form-field appearance="fill">
        <mat-label>Dimension 1</mat-label>
        <input matInput type="number" [(ngModel)]="stoneSize.dimension_1" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Dimension 2</mat-label>
        <input matInput type="number" [(ngModel)]="stoneSize.dimension_2" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Dimension 3</mat-label>
        <input matInput type="number" [(ngModel)]="stoneSize.dimension_3" />
      </mat-form-field>
      <button
        class="btn btn-success"
        [disabled]="
          !stoneSize.dimension_1 &&
          !stoneSize.dimension_2 &&
          !stoneSize.dimension_3
        "
        (click)="addDimension()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-plus"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </button>
    </div>
    <table mat-table [dataSource]="stoneSizes">
      <ng-container matColumnDef="displayText">
        <th mat-header-cell *matHeaderCellDef>Dimensions</th>
        <td mat-cell *matCellDef="let element">{{ element.displayText }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="1">No Available Size</td>
      </tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        (click)="onSave()"
        [disabled]="isSaving"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
