import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class PrintService extends GenericService {
  baseUrl = environment.apiUrl + 'print';

  constructor(http: HttpClient) {
    super(http);
  }
  printLabel(workOrderId: number) {
    return this.http
      .get(`${this.baseUrl}/label/${workOrderId}`, {
        headers: this.headers,
      })
  }
}
