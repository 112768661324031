import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import orderBy from 'lodash/orderBy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProductMaterial,
  ProductSizes,
  ProductWeight,
} from 'src/app/models/product';
import { environment } from 'src/environments/environment';
import {
  GroupItemRequest,
  Product,
  ProductDetail,
  ProductImage,
  ProductVariation,
  ProductsStoreInfo,
  Productstoreflag,
} from '../models/product';
import { ProductDetails } from '../models/product-stock';
import { ProductSearch } from '../models/request/product-search-request';
import { BillOfMaterialSubItem } from './../models/bill-material-sub-item';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends GenericService {
  baseUrl = environment.apiUrl + 'products';
  reloadEnamelVariation = () => {};
  reloadEnamelConstant = () => {};
  reloadProductImages = () => {};

  constructor(http: HttpClient) {
    super(http);
  }

  // Please don't remove
  getList(): Observable<any> {
    let url = this.baseUrl + '/detail-list';
    return this.http.get<any>(url, { headers: this.headers });
  }

  getSearchList(searchObject: ProductSearch): Observable<any> {
    let url = this.baseUrl + '/list';
    return this.http.post<any>(url, searchObject, {
      headers: this.headers,
      responseType: 'text' as 'json',
    });
  }

  getProduct(productsId: number): Observable<any> {
    return this.get(productsId, '');
  }

  viewSubassembly(subassemblyId: number): Observable<any> {
    let url = this.baseUrl + '/bom/' + subassemblyId;
    return this.http.get<any>(url, { headers: this.headers });
  }

  getProductDetails(
    id: number,
    uom: number,
    matId: number,
    size: number,
  ): Observable<any> {
    let url =
      this.baseUrl +
      '/details/' +
      id +
      '?uom=' +
      uom +
      '&matId=' +
      matId +
      '&size=' +
      size;
    return this.http.get<any>(url, { headers: this.headers });
  }

  getProductStatuses(): Observable<any> {
    return this.http.get(this.baseUrl + '/productstatuses', {
      headers: this.headers,
    });
  }

  getProductImages(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/upload/' + id + '/list', {
      headers: this.headers,
    });
  }

  saveProduct(object: Product): Observable<any> {
    return this.post(object, '');
  }

  updateProduct(object: Product) {
    return this.put(object, '');
  }

  deleteProduct(productsId: number): Observable<any> {
    return this.post(productsId, '/DeleteProduct/' + productsId.toString());
  }

  updatePrimaryImage(object: Product) {
    return this.put(object, '/upload/make-primary');
  }

  deleteImage(object: Product) {
    return this.put(object, '/upload/delete-image');
  }

  updateProductDetails(productStock: ProductDetails): Observable<any> {
    let url = this.baseUrl + '/details';
    return this.http.put<any>(url, productStock, { headers: this.headers });
  }

  uploadImage(object: FormData): Observable<any> {
    return this.http.post(this.baseUrl + '/images/upload', object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
    });
  }

  getProductStoreFlag(flagId: number): Observable<any> {
    return this.get(flagId, '/getproductstoreflag');
  }

  saveProductStoreFlag(object: Productstoreflag): Observable<any> {
    return this.post(object, '/inputproductflag');
  }

  getProductGroupItems(groupId: number): Observable<any> {
    let url = environment.apiUrl + 'productsstoreinfo/product-group/' + groupId;
    return this.http.get(url, { headers: this.headers });
  }

  getNotInGroup(): Observable<any> {
    let url = environment.apiUrl + 'productsstoreinfo/not-in-group';
    return this.http.get(url, { headers: this.headers });
  }

  createGroup(productId: number): Observable<any> {
    let url = environment.apiUrl + 'productsstoreinfo/create-group/';
    return this.http.post(url, productId, { headers: this.headers });
  }

  addToGroup(storeInfo: ProductsStoreInfo): Observable<any> {
    let url = environment.apiUrl + 'productsstoreinfo/add-to-group/';
    return this.http.post(url, storeInfo, { headers: this.headers });
  }

  updateGroupItems(groupItems: GroupItemRequest): Observable<any> {
    let url = environment.apiUrl + 'productsstoreinfo/update-group-items/';
    return this.http.post(url, groupItems, { headers: this.headers });
  }

  reorderGroupItem(
    productId: number,
    groupId: number,
    groupLevel: number,
  ): Observable<any> {
    let url =
      environment.apiUrl +
      'productsstoreinfo/reorder-item/' +
      productId +
      '/' +
      groupId +
      '/' +
      groupLevel;
    return this.http.get(url, { headers: this.headers });
  }

  removeFromGroup(productId: number): Observable<any> {
    let url =
      environment.apiUrl + 'productsstoreinfo/remove-from-group/' + productId;
    return this.http.delete(url, { headers: this.headers });
  }

  removeAllFromGroup(productId: number): Observable<any> {
    let url =
      environment.apiUrl +
      'productsstoreinfo/remove-all-from-group/' +
      productId;
    return this.http.delete(url, { headers: this.headers });
  }

  lastupdate(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/lastupdate/' + id.toString(), {
      headers: this.headers,
    });
  }

  getProductBillOfMaterial(
    productId: number,
  ): Observable<BillOfMaterialSubItem[]> {
    return this.http
      .get<any>(`${environment.apiUrl}product-bom`, {
        headers: this.headers,
        params: { productId },
      })
      .pipe(
        map((data) =>
          data.map(({ produtsBOMID, ...item }) => ({
            ...item,
            productsBOMID: produtsBOMID,
          })),
        ),
      );
  }

  setProductBillOfMaterial(
    data: Partial<BillOfMaterialSubItem>,
  ): Observable<number> {
    return this.http.post<any>(`${environment.apiUrl}product-bom`, data, {
      headers: this.headers,
    });
  }

  deleteProductBillOfMaterial(id): Observable<number> {
    return this.http.delete<any>(`${environment.apiUrl}product-bom/${id}`, {
      headers: this.headers,
    });
  }

  getProductVariations(productId) {
    return this.http.get<ProductVariation[]>(
      `${environment.apiUrl}product-variations/enamels`,
      {
        headers: this.headers,
        params: {
          productId,
        },
      },
    );
  }

  setProductVariations(data) {
    return this.http.post(
      `${environment.apiUrl}product-variations/enamels`,
      data,
      {
        headers: this.headers,
      },
    );
  }

  deleteProductVariations(productEnamelId) {
    return this.http.delete(
      `${environment.apiUrl}product-variations/enamels/${productEnamelId}`,
      {
        headers: this.headers,
      },
    );
  }

  getProductDetail(productId): Observable<ProductDetail> {
    return this.http
      .get<any>(`${this.baseUrl}/${productId}`, {
        headers: this.headers,
      })
      .pipe(
        map(({ responseObject: data }) => {
          const sizes = data.productSizes.reduce(
            (accum, size) => {
              const isNumber = !Number.isNaN(Number(size.size));
              if (isNumber) {
                const value = Number(size.size);
                accum.numeric = [...accum.numeric, { ...size, size: value }];
              } else {
                accum.alpha = [...accum.alpha, size];
              }
              return accum;
            },
            {
              alpha: [],
              numeric: [],
            },
          );
          const sortedNumber = orderBy(
            sizes.numeric,
            (s: ProductSizes) => s.size,
            'desc',
          );
          const convertedSizeToString = sortedNumber.map((s) => ({
            ...s,
            size: s.size?.toString(),
          }));
          return {
            materials: orderBy(
              data.materials,
              (d: ProductMaterial) => d.material.code,
              'desc',
            ),
            productSizes: [
              ...convertedSizeToString,
              ...orderBy(sizes.alpha, (s: ProductSizes) => s.size, 'desc'),
            ],
          };
        }),
      );
  }

  setProductMaterial(data) {
    return this.http.post(`${environment.apiUrl}product-materials`, data, {
      headers: this.headers,
    });
  }
  deleteProductMaterial(id) {
    return this.http.delete(`${environment.apiUrl}product-materials/${id}`, {
      headers: this.headers,
    });
  }
  setProductSize(data) {
    return this.http.post(`${environment.apiUrl}product-sizes`, data, {
      headers: this.headers,
    });
  }
  deleteProductSize(id) {
    return this.http.delete(`${environment.apiUrl}product-sizes/${id}`, {
      headers: this.headers,
    });
  }
  getProductImageList(id: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/images/${id}`, {
      headers: this.headers,
    });
  }
  setProductImages(productId, images: ProductImage[]) {
    return this.http.put(`${this.baseUrl}/images/${productId}`, images, {
      headers: this.headers,
    });
  }
  deleteProductImages(productsPicsID) {
    return this.http.delete(`${this.baseUrl}/images/${productsPicsID}`, {
      headers: this.headers,
    });
  }

  getProductWeight(productId) {
    return this.http.get<ProductWeight[]>(
      `${environment.apiUrl}products-weight`,
      {
        headers: this.headers,
        params: {
          productId,
        },
      },
    );
  }

  setProductWeight(data) {
    return this.http.post(`${environment.apiUrl}products-weight`, data, {
      headers: this.headers,
    });
  }

  getProductWorkOrderLimit(productId) {
    return this.http.get<{ workOrderLimit: number }>(
      `${this.baseUrl}/wko-limit/${productId}`,
      {
        headers: this.headers,
      },
    );
  }

  updateProductWorkOrderLimit(data) {
    return this.http.put(`${this.baseUrl}/wko-limit`, data, {
      headers: this.headers,
    });
  }
}
