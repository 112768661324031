import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GenericComponent } from '../generic/generic.component';

import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import orderBy from 'lodash/orderBy';
import { Observable, Subject, forkJoin } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { PRODUCT_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/product-permission';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { AlertService } from 'src/app/custom/_alert';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { Customers } from 'src/app/models/customer';
import { CustomerCode } from 'src/app/models/enum/customer.enum';
import { JewelryType } from 'src/app/models/jewelry-type';
import { MaterialCode } from 'src/app/models/material-code';
import { MetalGrain } from 'src/app/models/metal-grain';
import {
  Product,
  ProductDetail,
  ProductHistory,
  ProductMaterial,
  ProductSizes,
  ProductStatus,
  ProductWeight,
  ProductsInfo,
  ProductsRouting,
  ProductsStoreInfo,
  RoutingCodes,
} from 'src/app/models/product';
import { ProductDetails } from 'src/app/models/product-stock';
import { ProductSearch } from 'src/app/models/request/product-search-request';
import { UnitOfMeasure } from 'src/app/models/unit-of-measure';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CustomerService } from 'src/app/services/customer.service';
import { HistoryService } from 'src/app/services/history.service';
import { JewelryTypeService } from 'src/app/services/jewelry-type.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { ProductService } from 'src/app/services/product.service';
import { UnitOfMeasureService } from 'src/app/services/unit-of-measurement.service';
import { Historylogs } from '../../models/historylogs.model';
import { ProductsRoutingService } from '../../services/products-routing.service';
import { InvoiceItemType } from '../invoicing-shipping/invoicing/models/invoice';
import { ProductRoutingDialogComponent } from '../product-routing/product-routing-dialog.component';
import { ConfirmationService } from '../riva-confirmation/riva-confirmation.service';
import { EnamelVariationDialogComponent } from './enamel-variation-dialog/enamel-variation-dialog.component';
import { ProductMaterialDialogComponent } from './product-material-dialog/product-material-dialog.component';
import { ProductSettingDialogComponent } from './product-setting-dialog/product-setting-dialog.component';
import { ProductSkuGeneratorDialogComponent } from './product-sku-generator-dialog/product-sku-generator-dialog.component';

export const ProductTypeMapping = [
  { value: 1, text: 'Products' },
  { value: 2, text: 'Subassemblies' },
];

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
}

@Component({
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.scss'],
})
export class ProductsTableComponent extends GenericComponent implements OnInit {
  productFeatureKey = PRODUCT_FEATURE_KEY;
  customers: Customers[];
  filteredCustomers: Customers[];
  statuses: ProductStatus[];
  jewelryTypes: JewelryType[];
  filteredJewelryTypes: JewelryType[];
  materialCodes: MaterialCode[];
  filteredMaterialCodes: MaterialCode[];
  routingCodes: RoutingCodes[];
  customerFilterCtrl = new FormControl();
  jewelryTypeFilterCtrl = new FormControl();

  tableCollapsed = false;

  uom: UnitOfMeasure[];
  error = {
    sku: false,
    name: false,
  };

  products: Product[];
  filteredProducts: Product[];
  product: Product = new Product();

  productTypes: { value: number; text: string }[];
  newMode = false;
  indexMax = 0;
  index = 0;
  updateFlag = false;

  customerCodes = CustomerCode;

  searchString = '';
  selectedProductType = 1;
  selectedCustomer = 0;
  selectedJewelryType = 0;

  selectedUOM = 1;
  highlightSize: boolean = false;
  highlightMats: boolean = false;
  showProductSizeError: boolean = false;

  sorted: Product[];
  sortColumn = [
    { column: 'productsId', sort: '' },
    { column: 'sku', sort: '' },
    { column: 'customerSku', sort: '' },
    { column: 'productTypeName', sort: '' },
    { column: 'productName', sort: '' },
    { column: 'customerCode', sort: '' },
    { column: 'jewelryTypeName', sort: '' },
  ];

  showEditModeSizes = false;

  lastUpdated = new Historylogs();

  productLastHistory: ProductHistory = {} as ProductHistory;

  productsStoreInfo = new ProductsStoreInfo();
  productsInfo: ProductsInfo[] = [];
  prodMatList: number[];
  prodSizeList: string[];

  selectedProductsInfo = new ProductsInfo();
  newProductsInfo = new ProductsInfo();
  selectedMaterialCode: number = -1;
  selectedMaterial: ProductMaterial = {} as ProductMaterial;
  selectedProductSize: ProductSizes = {} as ProductSizes;
  selectedWeight: ProductWeight = {} as ProductWeight;
  productWeights: ProductWeight[] = [];
  selectedSize = '';
  modalMaterialCode = 0;
  modalSize = '';

  productMaterialCode = 0;
  productMetalGrain = null;
  productSize = '';

  productsRouting: ProductsRouting[] = [];
  notification = null;
  routingForms: FormArray = this.fb.array([]);
  productDetail: ProductDetail = {
    materials: [],
    productSizes: [],
  } as ProductDetail;
  allCustomer: Partial<Customers> = {
    custIdno: 0,
    companyName: 'All',
  };
  selectedCustomerObj: Customers = {
    custIdno: 0,
    companyName: 'All',
  } as Customers;
  private debouncer = new Subject<void>();

  @ViewChild('uploadImage') imageUploader: ElementRef;

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    public dialog: MatDialog,
    private productService: ProductService,
    private historyService: HistoryService,
    private customerService: CustomerService,
    private materialCodeService: MaterialCodeService,
    private uomService: UnitOfMeasureService,
    private jewelryTypeService: JewelryTypeService,
    private userPermissionService: UserPermissionService,
    private analyticsService: AnalyticsService,
    private productsRoutingService: ProductsRoutingService,
    private tokenStorageService: TokenStorageService,
    private modalService: NgbModal,
    private _confirmationService: ConfirmationService,
    private _sanitizer: DomSanitizer,
    private _date: DatePipe,
    private fb: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super(loadService, alertService);
    this.productTypes = ProductTypeMapping;
    this.userPermissionService.checkPagePermission(PAGE_NAME.product);

    this.debouncer.pipe(debounceTime(500)).subscribe(() => {
      this.scrollToClass('row-data-active');
      this.getProductLocal(this.product);
    });
  }

  tableActive = false;

  scrollToClass(className: string) {
    const element = this.productTable.nativeElement;
    const elementWithClass = element.querySelector(`.${className}`);

    if (elementWithClass) {
      element.scrollTop = elementWithClass.offsetTop - 70;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.tableActive) {
      let currentIndex = this.products.indexOf(this.product);
      if (currentIndex < this.products.length - 1 && currentIndex >= 0) {
        if (event.keyCode == KEY_CODE.UP_ARROW && currentIndex !== 0) {
          currentIndex -= 1;
          this.product = { ...this.products[currentIndex] };
        } else if (
          event.keyCode == KEY_CODE.DOWN_ARROW &&
          currentIndex < this.products.length
        ) {
          currentIndex += 1;
          this.product = { ...this.products[currentIndex] };
        }
        this.debouncer.next();
      }
    }
  }

  get totalProducts() {
    if (this.products !== undefined) {
      return this.products.length;
    }
    return 0;
  }

  ngOnInit(): void {
    this.load();
  }

  filterCustomers() {
    const customers = this.customers.filter(
      (customer) =>
        customer.customerId
          ?.toLocaleLowerCase()
          .includes(this.customerFilterCtrl.value?.trim()) ||
        customer.companyName
          ?.toLocaleLowerCase()
          .includes(this.customerFilterCtrl.value?.trim()),
    );
    this.filteredCustomers = orderBy(customers, 'companyName');
  }
  onCustomerChange(customer: Customers) {
    if (customer == null) {
      this.selectedCustomerObj = this.allCustomer as Customers;
    }
    this.selectedCustomer = customer?.custIdno ?? 0;
    this.searchPanelChange();
  }

  customerDisplay(item: Customers): string {
    return item?.companyName ?? '';
  }

  filterJewelryTypes() {
    this.filteredJewelryTypes = this.jewelryTypes.filter((type) =>
      type.type
        ?.toLowerCase()
        ?.includes(this.jewelryTypeFilterCtrl.value?.trim()),
    );
  }

  filteredMetalGrain: Observable<MetalGrain[]>;
  metalGrainControl = new FormControl();
  selectedMetalGrain: MetalGrain = new MetalGrain();
  _metalGrains: MetalGrain[] = [];
  metalGrainsDropdown: MetalGrain[] = [];

  load() {
    forkJoin([
      this.customerService.getList(),
      this.materialCodeService.getList(),
      this.productService.getProductStatuses(),
      this.uomService.getList(),
      this.jewelryTypeService.getList(),
      this.productsRoutingService.getProductRoutingCodes(),
    ])
      .pipe(
        map(
          ([
            customers,
            materialCodes,
            productStatuses,
            unitOfMeasurements,
            jewelryTypes,
            routingCodes,
          ]) => {
            this.customers = orderBy(customers, 'companyName');
            this.filteredCustomers = [...this.customers];
            this.materialCodes = materialCodes.responseObject;
            this.statuses = productStatuses.responseObject;
            this.uom = unitOfMeasurements.responseObject;
            this.jewelryTypes = jewelryTypes.responseObject;
            this.filteredJewelryTypes = [...this.jewelryTypes];
            this.routingCodes = routingCodes;
            return {
              customers,
              materialCodes,
              productStatuses,
              unitOfMeasurements,
              routingCodes,
            };
          },
        ),
      )
      .subscribe(
        (response) => {
          const defaultProductId = localStorage.getItem('selected-product-id');
          this.getProductSearch(
            defaultProductId ? +defaultProductId : undefined,
          );
          localStorage.removeItem('selected-product-id');
          this.customerFilterCtrl.valueChanges.subscribe(() => {
            this.filterCustomers();
          });
          this.jewelryTypeFilterCtrl.valueChanges.subscribe(() => {
            this.filterJewelryTypes();
          });
        },
        (error) => {
          this.alertService.error(error.statusText);
          this.loadService.loadContent(false);
        },
      );
  }

  closeResult = '';

  editMaterial: MaterialCode;

  modalOpen(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;

          this.AddNewProductInfo();
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  transform(value: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }

  AddNewProductInfo() {
    this.newProductsInfo = new ProductsInfo();
    this.newProductsInfo.productsInfoId = 0;
    this.newProductsInfo.productsId = this.product.productsId;
    this.newProductsInfo.size = this.modalSize.toString();
    this.newProductsInfo.materialCodeId = this.modalMaterialCode;
    this.newProductsInfo.priceRetail = 0;
    this.newProductsInfo.priceWhls = 0;
    this.newProductsInfo.stockQty = 0;
    this.newProductsInfo.weight = 0;
    this.newProductsInfo.metalGrainsId = 0;

    let index = this.productsInfo.findIndex(
      (d) =>
        d.materialCodeId.toString() == this.modalMaterialCode.toString() &&
        d.size == this.modalSize.toString(),
    );

    if (index == -1) {
      this.productsInfo.push(this.newProductsInfo);
    } else {
      this.alertService.error('Existing Material and size combination exist!');
    }

    this.getProdMatList();
  }

  createMaterialOpen(content) {
    this.editMaterial = new MaterialCode();

    this.modalOpen(content);
  }

  editMaterialOpen(content, materialId: number) {
    let materialToEdit = this.materialCodes.find(
      (m) => m.materialCodeId == materialId,
    );
    this.editMaterial = new MaterialCode();
    this.editMaterial.materialCodeId = materialToEdit.materialCodeId;
    this.editMaterial.code = materialToEdit.code;
    this.editMaterial.color = materialToEdit.color;
    this.editMaterial.karat = materialToEdit.karat;
    this.editMaterial.description = materialToEdit.description;

    this.modalOpen(content);
  }

  editSize: string = '0';

  editSizeOpen(content) {
    this.editSize = this.selectedProductsInfo.size;

    this.modalOpen(content);
  }

  newSize: ProductDetails = new ProductDetails();

  createSizeOpen(content) {
    this.newSize.matID = this.selectedProductsInfo.materialCodeId;

    this.newSize.productID = this.product.productsId;
    this.modalOpen(content);
  }

  createMatSizeOpen(content) {
    this.refreshRoutingList(this.product.productsId);

    this.modalOpen(content);
  }

  closeMatSizeOpen(content) {
    this.modalOpen(content);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  materialUpdate() {
    this.materialCodeService.update(this.editMaterial).subscribe(
      (result) => {
        let updatedMaterial = result.responseObject;
        let index = this.materialCodes.indexOf(
          this.materialCodes.find(
            (m) => m.materialCodeId == updatedMaterial.materialCodeId,
          ),
        );
        this.materialCodes[index] = updatedMaterial;
        this.alertService.success(
          'Updated material:' + updatedMaterial.description,
        );
      },
      (error) => {
        this.alertService.error(error.statusText);
      },
    );
  }

  materialDelete() {
    this.materialCodeService.delete(this.editMaterial.materialCodeId).subscribe(
      (result) => {
        this.materialCodes = this.materialCodes.filter(
          (m) => m.materialCodeId != this.editMaterial.materialCodeId,
        );
        this.alertService.success('Material deleted');
      },
      (error) => {
        this.alertService.error(error.statusText);
      },
    );
  }

  applyColorBand(product: Product) {
    let index = this.products.indexOf(product);
    if (index % 2 == 0 || index == 0) {
      return false;
    }
    return true;
  }

  searchPanelChange() {
    this.index = 1;
    this.alertService.clear();
    this.updateFlag = false;
    this.getProductSearch();
  }

  productImageList = [];
  imageListSize = 0;
  imageIndex = 0;

  nextImage() {
    this.imageIndex++;
    this.product.picPath = this.productImageList[this.imageIndex];
  }

  previousImage() {
    if (this.imageIndex !== 0) {
      this.imageIndex--;
      this.product.picPath = this.productImageList[this.imageIndex];
    }
  }

  loadProductImages() {
    this.productService.getProductImages(this.product.productsId).subscribe(
      (imageList) => {
        this.productImageList = [];
        this.productImageList = imageList.responseObject;
        this.imageListSize = this.productImageList.length - 1;
        this.imageIndex = this.productImageList.findIndex(
          (x) => x === this.product.picPath,
        );
      },
      (error) => {
        this.productImageList = [];
        this.imageListSize = 0;
        this.imageIndex = 0;
      },
    );
  }

  viewSubassembly(subProductsId: number) {
    this.productService.viewSubassembly(subProductsId).subscribe((result) => {
      this.products = result.responseObject;
      let product = this.products.find((p) => p.productsId === subProductsId);
      this.selectedProductType = product.productsTypeId;
      this.getProductLocal(product);
      this.getFilteredProducts();
    });
  }

  getProductLocal(product: Product) {
    this.selectedProductSize = {} as ProductSizes;
    this.selectedMaterial = {} as ProductMaterial;
    this.selectedWeight = {} as ProductWeight;
    this.product = { ...product };
    this.product.customerSku = '';

    this.productsStoreInfo = new ProductsStoreInfo();

    this.productsStoreInfo.productsId = this.product.productsId;

    if (this.product.productsStoreInfo !== undefined) {
      if (this.product.productsStoreInfo[0] !== undefined) {
        this.productsStoreInfo = this.product.productsStoreInfo[0];
      }
    }

    this.productsInfo = [];

    if (this.product.productsInfo?.length > 0) {
      this.productsInfo = this.product.productsInfo;
    }

    // routing
    this.refreshRoutingList(this.product.productsId);
    this.productsRouting = [];
    if (this.product.productsRouting.length > 0) {
      this.productsRouting = this.product.productsRouting;
    }

    // Resets selected productInfo
    this.selectedMaterialCode = 0;
    this.selectedSize = '';

    this.getProdMatList();

    this.loadProductImages();

    if (this.product.picPath === null) {
      this.product.picPath = '../../../assets/images/no-image.png';
    }

    this.getHistoryLog(this.product.productsId);
    this.getProductDetail();
    this.getProductWeight();
  }

  getProductDetail() {
    this.productDetail = {
      materials: [],
      productSizes: [],
    } as ProductDetail;
    if (this.product.productsId) {
      this.productService
        .getProductDetail(this.product.productsId)
        .subscribe((data) => {
          this.productDetail = data;
          this.filteredMaterialCodes = this.materialCodes.filter(
            (code) =>
              !this.productDetail.materials.some(
                (p) => p.materialsCodeID === code.materialCodeId,
              ) && !code.multiMetal,
          );
        });
    }
  }

  timer;

  searchTimeout() {
    this.products = this.allProducts.filter(
      (p) =>
        p.sku.toLowerCase().includes(this.searchString.toLowerCase()) ||
        p.customerSku.toLowerCase().includes(this.searchString.toLowerCase()) ||
        p.productName.toLowerCase().includes(this.searchString.toLowerCase()),
    );
    this.getFilteredProducts();
  }

  @ViewChild('productTable') private productTable: ElementRef;

  scrollToBottom() {
    this.productTable.nativeElement.scrollTop =
      this.productTable.nativeElement.scrollHeight;
  }

  scrollToTop() {
    this.productTable.nativeElement.scrollTop = 0;
  }

  allProducts: Product[] = [];

  get ProductPicPath() {
    if (this.product == undefined || this.product == null)
      this.product = new Product();

    return this._sanitizer.bypassSecurityTrustUrl(
      this.product.picPath ||
        `${this.document.location.origin}/assets/images/no-image.png`,
    );
  }

  getProductSearch(selectedProduct?: number) {
    let searchObject = new ProductSearch();
    // this.sortColumn.forEach((s) => {
    //   s.sort = '';
    // });

    if (this.product == undefined || this.product == null)
      this.product = new Product();

    this.product.customerCode = 0;
    if (this.selectedCustomer != 0) {
      this.product.customerCode = this.customers.find(
        (c) => c.custIdno == this.selectedCustomer,
      ).custIdno;
    }

    if (this.updateFlag == true) {
      this.selectedProductType = this.product.productsTypeId;
    }

    searchObject.customerCode = this.product.customerCode;
    searchObject.jewelryType = this.selectedJewelryType;
    searchObject.productTypeID = this.selectedProductType;

    this.index = 0;
    this.indexMax = 0;

    this.productService.getSearchList(searchObject).subscribe(
      (response) => {
        this.products = JSON.parse(response);
        this.allProducts = JSON.parse(response);

        if ((this.allProducts.length = 0)) {
          return;
        }

        this.products.forEach((item) => {
          item = this.mapTypeNames(item);
        });

        this.products.sort((a, b) => {
          if (a.productsId > b.productsId) {
            return -1;
          }

          if (a.productsId < b.productsId) {
            return 1;
          }

          return 0;
        });

        if (this.products.length !== 0) {
          this.indexMax = this.products.length - 1;

          if (selectedProduct == undefined) {
            this.product = { ...this.products[this.index] };
          } else {
            const currentProduct = this.products.find(
              (p) => p.productsId === selectedProduct,
            );
            this.product = { ...(currentProduct ?? this.products[this.index]) };
          }

          this.refreshRoutingList(this.product.productsId);
          if (this.product.productsRouting.length > 0) {
            this.productsRouting = this.product.productsRouting;
          }

          this.loadProductImages();

          if (this.newMode) {
            this.product = {
              ...(this.products.find((p) => p.productsId === selectedProduct) ??
                ({} as Product)),
            };
            this.editMode = false;
            this.scrollToTop();
          } else if (selectedProduct !== undefined) {
            this.product = {
              ...(this.products.find((p) => p.productsId === selectedProduct) ??
                ({} as Product)),
            };
          }

          if (this.product == undefined) this.product = { ...this.products[0] };

          this.getHistoryLog(this.product.productsId);
          this.getProductWeight();
        } else {
          this.product = new Product();
          this.alertService.error('No records found.');
          this.loadService.loadContent(false);
        }

        if (this.product) {
          this.product.customerSku = '';
        }
        if (
          this.product.picPath === undefined ||
          this.product.picPath === null
        ) {
          this.product.picPath = '../../../assets/images/no-image.png';
        }

        if (
          this.product.productsInfo != undefined &&
          this.product.productsInfo.length > 0
        ) {
          this.productsInfo = this.product.productsInfo;
          this.getProdMatList();
        }
        this.getProductDetail();
        this.loadService.loadContent(false);
        this.getFilteredProducts();
      },
      (error) => {
        this.product = new Product();
        this.loadService.loadContent(false);
        this.alertService.error('Internal error.');
      },
    );
  }

  mapTypeNames(product: Product) {
    product.productTypeName = this.productTypes.find(
      (pt) => pt.value === product.productsTypeId,
    )?.text;

    if (product.jewelryType != null) {
      product.jewelryTypeName = this.jewelryTypes.find(
        (jt) => jt.jewelryTypeId === product.jewelryType,
      )?.type;
    }

    return product;
  }

  newSave() {
    this.productService.saveProduct(this.product).subscribe(
      (response) => {
        this.product = { ...response.responseObject };
        this.allProducts = [this.product, ...this.allProducts];
        this.productDetail.materials = [];
        this.productDetail.productSizes = [];
        this.editMode = false;
        this.alertService.success('Product details saved.');
        this.getProductSearch(this.product.productsId);
        this.getProductLocal(this.product);
      },
      (error) => {
        this.alertService.error(
          'Error: Unable to save Product. ' + error.error.errorText,
        );
      },
    );
  }

  save() {
    if (
      this.checkStringIfEmpty(this.product.sku) ||
      this.checkStringIfEmpty(this.product.productName)
    ) {
      this.error.sku = this.checkStringIfEmpty(this.product.sku);
      this.error.name = this.checkStringIfEmpty(this.product.productName);
    } else {
      if (this.product.productsId) {
        this.update();
      } else {
        this.newSave();
      }
      this.error = {
        sku: false,
        name: false,
      };
      this.getHistoryLog(this.product.productsId);
    }
  }

  updatePrimaryImage() {
    this.loadService.loadContent(true);
    this.productService.updatePrimaryImage(this.product).subscribe(
      (result) => {
        this.loadService.loadContent(false);
      },
      (error) => {
        this.alertService.error(
          'Error: Unable to update primary image. ' + error.error.errorText,
        );
        this.loadService.loadContent(false);
      },
    );
  }

  deleteImage() {
    this.loadService.loadContent(true);
    this.productService.deleteImage(this.product).subscribe(
      (result) => {
        this.loadProductImages();
        this.product.picPath = result.responseObject.picPath;
        this.loadService.loadContent(false);
      },
      (error) => {
        this.alertService.error(
          'Error: Unable to delete image. ' + error.error.errorText,
        );
        this.loadService.loadContent(false);
      },
    );
  }

  setMetalGrainToProductInfo() {
    this.product.productsInfo.find(
      (x) => x.productsInfoId == this.selectedProductsInfo.productsInfoId,
    ).metalGrainsId = this.selectedMetalGrain.metalGrainsId;
  }

  update() {
    this.product.createdBy = this.tokenStorageService.currentUser?.userName;

    this.product.productsStoreInfo = [];
    this.productsStoreInfo.productsId = this.product.productsId;
    this.product.productsStoreInfo.push(this.productsStoreInfo);

    this.updateProductInfoList();

    this.productService.updateProduct(this.product).subscribe(
      (response) => {
        this.alertService.clear();
        this.editMode = false;
        this.alertService.success('Product details UPDATED.');

        this.updateFlag = true;
        this.getProductSearch(this.product.productsId);

        this.getProductLocal(this.product);
      },
      (error) => {
        this.editMode = false;
        this.alertService.error(
          error.statusText +
            '. ' +
            (error.error.errorText !== undefined ? error.error.errorText : ''),
        );
        this.loadService.loadContent(false);
      },
    );
  }

  toggleEdit() {
    this.editMode = !this.editMode;
    this.showEditModeSizes = false;
    if (this.editMode) {
      this.metalGrainControl.enable();
    } else {
      this.metalGrainControl.disable();
    }

    this.refreshRoutingList(this.product.productsId);
    if (
      this.product.productsInfo != undefined &&
      this.product.productsInfo.length > 0
    ) {
      this.productsInfo = this.product.productsInfo;
      this.getProdMatList();
    }

    if (this.editMode === false || this.newMode) {
      this.error.sku = false;
      this.error.name = false;
      this.newMode = false;
    }
  }

  toggleCancel() {
    if (!this.product.productsId) {
      this.product = { ...this.products[0] };
    } else {
      this.product = {
        ...this.products.find((p) => p.productsId === this.product.productsId),
      };
    }
    this.toggleEdit();
    this.onSelectProductWeight();
  }

  productMaterialCodes = [];

  isProductMaterials(matCodeId: number) {
    var materials = this.productMaterialCodes.filter(
      (mat) => mat.materialCodeId === matCodeId,
    );
    if (materials.length > 0) {
      return true;
    }
    return false;
  }

  highlightSizes(materialCodeId: number) {
    if (this.productsInfo.length > 0 && this.selectedSize != '') {
      var sizes = this.productsInfo.find((x) => x.size === this.selectedSize);

      if (sizes !== undefined) {
        this.highlightSize = true;
        return true;
      }
    }
    return false;
  }

  highlightMaterials(size: string) {
    if (this.selectedMaterialCode !== 0) {
      var materials = this.productsInfo.filter(
        (info) => info.materialCodeId == this.selectedMaterialCode,
      );

      if (materials !== undefined) {
        var filtered = materials.find((mat) => mat.size == size);

        if (filtered !== undefined) {
          this.highlightMats = true;

          return true;
        }
      }
    }

    return false;
  }

  matHasSize(item) {
    let materials = this.productsInfo.filter(
      (x) => x.size == this.selectedSize,
    );
    let matHasSize = materials.find((x) => x.materialCodeId == item);
    if (matHasSize !== undefined) {
      return true;
    }
    return false;
  }

  sizeInMat(size: string) {
    let material = this.productsInfo.find(
      (x) => x.materialCodeId == this.selectedMaterialCode && x.size == size,
    );
    if (material != undefined) {
      return true;
    }
    return false;
  }

  newProduct() {
    this.product = new Product();
    this.product.customerCode = this.customers[0]?.custIdno ?? 0;
    this.productsStoreInfo = new ProductsStoreInfo();
    this.selectedProductsInfo = new ProductsInfo();

    this.productsInfo = [];
    this.productImageList = [];

    this.product.sku = '';
    this.product.productName = '';

    this.product.productsTypeId = this.selectedProductType;
    this.product.createdBy = this.tokenStorageService.currentUser?.userName;

    this.productDetail = {
      materials: [],
      productSizes: [],
    } as ProductDetail;

    this.getProdMatList();

    this.newMode = true;
    this.editMode = true;
  }

  onSort(column: string) {
    if (
      this.checkStringIfEmpty(
        this.sortColumn.find((s) => s.column === column).sort,
      ) ||
      this.sortColumn.find((s) => s.column === column).sort !== 'asc'
    ) {
      this.sortColumn.forEach((s) => {
        s.sort = '';
      });
      this.sortColumn.find((s) => s.column === column).sort = 'asc';
      this.filteredProducts.sort((a, b) => {
        if (a[column] > b[column]) {
          return 1;
        }
        if (a[column] < b[column]) {
          return -1;
        }
        return 0;
      });
    } else {
      this.sortColumn.forEach((s) => {
        s.sort = '';
      });
      this.sortColumn.find((s) => s.column === column).sort = 'desc';
      this.filteredProducts.sort((a, b) => {
        if (a[column] < b[column]) {
          return 1;
        }
        if (a[column] > b[column]) {
          return -1;
        }
        return 0;
      });
    }
  }

  sortClass(column) {
    if (this.sortColumn.find((s) => s.column === column).sort === 'asc') {
      return 'fa-arrow-up';
    } else if (
      this.sortColumn.find((s) => s.column === column).sort === 'desc'
    ) {
      return 'fa-arrow-down';
    }
    return '';
  }

  uploadProductImage(files) {
    if (files.length === 0) {
      return;
    }

    this.loadService.loadContent(true);

    const formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append('file[]', files[i]);
    }

    formData.append('productId', this.product.productsId.toString());

    this.productService.uploadImage(formData).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log(
            'Uploading: ' +
              Math.round((100 * event.loaded) / event.total) +
              '%',
          );
        } else if (event.type === HttpEventType.Response) {
          this.productService.reloadProductImages();
          this.loadService.loadContent(false);
        }
        this.imageUploader.nativeElement.value = null;
      },
      (error) => {
        this.alertService.error(error.error);
        this.loadService.loadContent(false);
        this.imageUploader.nativeElement.value = null;
      },
    );
  }

  getMaterialDesc(matId: number): string {
    return this.materialCodes.filter((x) => x.materialCodeId == matId)?.[0]
      ?.description;
  }

  showMetalGrainsDropdown: boolean = false;

  getSelectedProductsInfo() {
    this.metalGrainsDropdown = this._metalGrains.filter(
      (x) => x.materialCode == this.selectedMaterialCode,
    );
    this.selectedProductsInfo = new ProductsInfo();

    if (this.selectedMaterialCode != 0 && this.selectedSize != '') {
      this.selectedProductsInfo = this.productsInfo.filter(
        (x) =>
          x.materialCodeId == this.selectedMaterialCode &&
          x.size == this.selectedSize,
      )[0];
      if (this.selectedProductsInfo != undefined) {
        if (
          this.selectedProductsInfo.metalGrainsId != null &&
          this.selectedProductsInfo.metalGrainsId != 0
        ) {
          this.showMetalGrainsDropdown = true;
          this.selectedMetalGrain =
            this._metalGrains.find(
              (x) => x.metalGrainsId == this.selectedProductsInfo.metalGrainsId,
            ) ?? new MetalGrain();
        } else {
          this.showMetalGrainsDropdown = false;
          this.selectedMetalGrain = new MetalGrain();
        }
      } else {
        this.showMetalGrainsDropdown = false;
        this.selectedMetalGrain = new MetalGrain();
        this.selectedSize = this.productsInfo.filter(
          (x) => x.materialCodeId == this.selectedMaterialCode,
        )[0].size;
        this.getSelectedProductsInfo();
      }

      if (this.selectedProductsInfo == undefined) {
        this.selectedProductsInfo = new ProductsInfo();
      }
    } else {
      this.showMetalGrainsDropdown = false;
      this.selectedMetalGrain = new MetalGrain();
    }
  }

  updateProductInfoList() {
    if (this.productsInfo.length > 0) {
      let index = this.productsInfo.findIndex(
        (d) => d.productsInfoId === this.selectedProductsInfo.productsInfoId,
      );

      if (index != -1) {
        this.productsInfo.splice(index, 1);
        this.selectedProductsInfo.metalGrainsId =
          this.selectedMetalGrain.metalGrainsId;
        this.productsInfo.push(this.selectedProductsInfo);
      }

      this.product.productsInfo = this.productsInfo;
    }

    if (this.productsRouting.length > 0) {
      this.product.productsRouting = [];
      this.product.productsRouting = this.productsRouting;
    }
  }

  getProdMatList() {
    if (this.productsInfo.length > 0) {
      this.prodMatList = this.productsInfo
        .map((x) => parseInt(x.materialCodeId.toString()))
        .filter((value, index, self) => self.indexOf(value) === index);

      this.prodSizeList = this.productsInfo
        .map((x) => x.size)
        .filter((value, index, self) => self.indexOf(value) === index);

      if (this.prodMatList.length > 0 && this.prodSizeList.length > 0) {
        this.highlightMaterials(this.selectedSize);
        this.highlightSizes(this.selectedMaterialCode);

        this.getSelectedProductsInfo();
      }
    } else {
      this.selectedSize = '';
      this.selectedMaterialCode = 0;
      this.selectedProductsInfo = new ProductsInfo();
      this.prodMatList = [];
      this.prodSizeList = [];
    }
  }

  deleteProduct() {
    this.productService.deleteProduct(this.product.productsId).subscribe(
      (res) => {
        res;
      },
      (err) => {},
    );

    this.product = new Product();
  }

  getHistoryLog(id: number) {
    if (!id) return;
    this.productLastHistory = {} as ProductHistory;
    this.historyService.getProductHistory(id).subscribe((data) => {
      this.productLastHistory = data;
    });
  }

  formatDate(iDate: Date) {
    return this._date.transform(iDate, 'MM.dd.yyyy HH:mm');
  }

  get EnableUpdateRouting() {
    return this.routingForms.value.length == 0;
  }

  updateProductRouting() {
    for (var i = 0; i < this.routingForms.controls.length; i++) {
      let fg = this.routingForms.controls[i];

      this.updateRouting(fg, i);
    }

    this.showNotification('update');
  }

  addnewrouting() {
    let prodRouting = new ProductsRouting();

    prodRouting.productsRoutingId = 0;
    prodRouting.productsId = this.product.productsId;

    prodRouting.routingCodesId = 1;
    prodRouting.standardTime = 0;
    prodRouting.comment = '';

    this.routingForms.push(
      this.fb.group({
        productsRoutingId: [prodRouting.productsRoutingId],
        productsId: [prodRouting.productsId],
        routingCodesId: [prodRouting.routingCodesId],
        standardTime: [prodRouting.standardTime],
        comment: [prodRouting.comment],
      }),
    );
  }

  deleteRouting(productsRoutingId, i) {
    if (productsRoutingId == 0) this.routingForms.removeAt(i);
    else if (confirm('Are you sure to delete this record ?'))
      this.productsRoutingService.delete(productsRoutingId).subscribe((res) => {
        this.refreshRoutingList(this.product.productsId);
        this.routingForms.removeAt(i);
        this.showNotification('delete');
      });
  }

  updateRouting(fg: AbstractControl, i) {
    this.productsRoutingService.addupdate(fg.value).subscribe(
      (res) => {
        fg.patchValue({ productsRoutingId: res.productsRoutingId });
      },
      (err) => {},
    );
  }

  refreshRoutingList(productId: number) {
    this.productsRoutingService.getRoutingListByProduct(productId).subscribe(
      (res) => {
        this.productsRouting = res;

        this.routingForms = this.fb.array([]);
        (this.productsRouting as []).forEach((routing: ProductsRouting) => {
          this.routingForms.push(
            this.fb.group({
              productsRoutingId: [routing.productsRoutingId],
              productsId: [routing.productsId],
              routingCodesId: [routing.routingCodesId],
              standardTime: [routing.standardTime],
              comment: [routing.comment],
            }),
          );
        });
      },
      (err) => {},
    );
  }

  showNotification(category) {
    switch (category) {
      case 'insert':
        this.notification = { class: 'text-success', message: 'saved!' };
        break;
      case 'update':
        this.notification = { class: 'text-primary', message: 'updated!' };
        break;
      case 'delete':
        this.notification = { class: 'text-danger', message: 'deleted!' };
        break;

      default:
        break;
    }
    setTimeout(() => {
      this.notification = null;
    }, 3000);
  }

  getRoutingDescription(id: number) {
    return this.routingCodes.filter((x) => x.routingCodesId == id)[0]
      .activityDesc;
  }

  getProductName(id) {
    let productname = '';
    let product = this.allProducts.filter((x) => (x.productsId = id))[0];
    if (product != undefined) {
      productname = product.productName;
    }

    return productname;
  }

  onVariationDialogOpen() {
    const dialogRef = this.dialog.open(EnamelVariationDialogComponent, {
      disableClose: true,
      maxWidth: '1200px',
      width: '100%',
      data: {
        productId: this.product.productsId,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: boolean | null) => {
      if (result) {
        this.productService.reloadEnamelVariation();
      }
    });
  }

  onProductSettingDialogOpen() {
    this.dialog.open(ProductSettingDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
      data: {
        productId: this.product.productsId,
        editMode: this.editMode,
      },
      autoFocus: false,
    });
  }
  onOpenGenerateSkuDialog() {
    this.dialog.open(ProductSkuGeneratorDialogComponent, {
      disableClose: true,
      maxWidth: '800px',
      width: '100%',
      data: {
        productId: this.product.productsId,
        materialCodeId: this.selectedMaterial?.materialsCodeID ?? 0,
        productSizeId: this.selectedProductSize?.productsSizesID ?? 0,
      },
      autoFocus: false,
    });
  }

  onSaveProductMaterial() {
    const data = {
      productsID: this.product.productsId,
      materialsCodeID: this.productMaterialCode,
      metalGrainsID: this.productMetalGrain,
    };
    this.productService.setProductMaterial(data).subscribe(() => {
      this.productMaterialCode = 0;
      this.getProductDetail();
    });
  }

  onSaveProductSize() {
    this.showProductSizeError = false;
    const isExist = this.productDetail.productSizes.some(
      (size) => size.size == this.productSize.trim(),
    );
    if (isExist) {
      this.showProductSizeError = true;
      return;
    }
    const data = {
      productsID: this.product.productsId,
      size: this.productSize,
    };
    this.productService.setProductSize(data).subscribe(() => {
      this.productSize = '';
      this.getProductDetail();
    });
  }

  onMaterialCodeChange() {
    this.productMetalGrain = null;
  }

  get metalGrainList() {
    return this._metalGrains.filter(
      (x) => x.materialCode == this.productMaterialCode,
    );
  }

  onProductRoutingDialogOpen() {
    this.dialog.open(ProductRoutingDialogComponent, {
      disableClose: true,
      maxWidth: '1400px',
      width: '100%',
      autoFocus: false,
      data: {
        productId: this.product.productsId,
        editMode: this.editMode,
      },
    });
  }

  onDeleteProductMaterial(id, label) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Material',
        content: `Are you sure you want to delete ${label}?`,
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productService.deleteProductMaterial(id).subscribe(() => {
          this.productDetail.materials = this.productDetail.materials.filter(
            (p) => p.productsMaterialsID !== id,
          );
        });
      });
  }

  onDeleteProductSize(id, size) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Size',
        content: `Are you sure you want to delete ${size}?`,
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productService.deleteProductSize(id).subscribe(() => {
          this.productDetail.productSizes =
            this.productDetail.productSizes.filter(
              (p) => p.productsSizesID !== id,
            );
        });
      });
  }

  onSelectMaterialCode(item: ProductMaterial) {
    this.selectedMaterial = item;
    this.onSelectProductWeight();
  }
  onSelectSize(item: ProductSizes) {
    this.selectedProductSize = item;
    this.onSelectProductWeight();
  }
  onSelectProductWeight() {
    this.selectedWeight = {} as ProductWeight;

    if (
      this.selectedMaterial.materialsCodeID &&
      this.selectedProductSize.productsSizesID
    ) {
      this.analyticsService
        .getRollingWeightAverage({
          type: InvoiceItemType.Product,
          itemId: this.product.productsId,
          materialCodeId: this.selectedMaterial.materialsCodeID,
          sizeId: this.selectedProductSize.productsSizesID,
        })
        .subscribe((data) => {
          this.selectedWeight.avgWeightGrams = data ?? 0;
        });
      // const weight = this.productWeights.find(
      //   (w) =>
      //     w.materialCodesID === this.selectedMaterial.materialsCodeID &&
      //     w.productsSizesID === this.selectedProductSize.productsSizesID,
      // ) ?? {
      //   productsWeightsID: 0,
      //   productsID: this.product.productsId,
      //   materialCodesID: this.selectedMaterial.materialsCodeID,
      //   productsSizesID: this.selectedProductSize.productsSizesID,
      //   avgWeightGrams: 0,
      // };
      // this.selectedWeight = { ...weight };
    }
  }

  getProductWeight() {
    if (!this.product.productsId) return;
    this.productService
      .getProductWeight(this.product.productsId)
      .subscribe((data) => {
        this.productWeights = data;
        this.onSelectProductWeight();
      });
  }

  onSaveProductWeight() {
    if (
      this.selectedWeight.materialCodesID &&
      this.selectedWeight.productsSizesID
    ) {
      this.productService
        .setProductWeight(this.selectedWeight)
        .subscribe(() => {
          this.getProductWeight();
        });
    }
  }

  onOpenMultiMaterial(item: ProductMaterial | null = null) {
    const ref = this.dialog.open(ProductMaterialDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
      autoFocus: false,
      data: {
        productId: this.product.productsId,
        editMode: this.editMode,
        materialCodes: this.materialCodes,
        productMaterial: item,
      },
    });
    ref.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getProductDetail();
      }
    });
  }

  getFilteredProducts() {
    this.filteredProducts = this.products.filter((p) => {
      return (
        p.productName
          .toLowerCase()
          .includes(this.searchString?.toLowerCase()) ||
        p.productDesc
          .toLowerCase()
          .includes(this.searchString?.toLowerCase()) ||
        p.sku.toLowerCase().includes(this.searchString?.toLowerCase()) ||
        p.customerCodeNavigation?.customerId
          ?.toLowerCase()
          .includes(this.searchString?.toLowerCase()) ||
        p.jewelryTypeName
          ?.toLowerCase()
          .includes(this.searchString?.toLowerCase()) ||
        p.productsId
          ?.toString()
          ?.toLowerCase()
          .includes(this.searchString?.toLowerCase())
      );
    });
    const column = this.sortColumn.find((c) => c.sort !== '');
    if (column != null) {
      this.filteredProducts = orderBy(
        this.filteredProducts,
        [column.column],
        [column.sort],
      );
    }
  }
}
