<div class="gems-details">
  <div class="gems-forms">
    <div style="height: 100%; position: relative">
      <div userPermission [elementKey]="stoneFeatureKey.customImage">
        <button
          nz-button
          nzType="primary"
          (click)="uploadImage.click()"
          style="border-radius: 5px 5px 0 0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-cloud-upload"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            style="margin-right: 5px"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"
            />
            <polyline points="9 15 12 12 15 15" />
            <line x1="12" y1="12" x2="12" y2="21" />
          </svg>
          Custom
        </button>
        <input
          type="file"
          #uploadImage
          (change)="onUploadChange(uploadImage.files)"
          style="display: none"
          accept="image/*"
        />
        <nz-button-group class="image-uploader" *ngIf="editMode">
          <button nz-button nzType="primary" (click)="onOpenStoneImageDialog()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-settings"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              style="margin-right: 5px"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
              />
              <circle cx="12" cy="12" r="3" />
            </svg>
            Defaults
          </button>
        </nz-button-group>
      </div>
      <div class="image-container">
        <img
          *ngIf="!currentImage"
          style="max-width: 100%; max-height: 200px"
          src="assets/images/no-image.png"
        />
        <img
          *ngIf="currentImage"
          style="max-width: 100%; max-height: 200px"
          [src]="currentImage"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
    </div>
    <div class="gems-forms-fields">
      <div class="gem-field">
        <div class="gem-field__title">Type</div>
        <div class="gem-field__value">
          {{ selectedGem.stoneType?.name || '–' }}
        </div>
      </div>
      <div class="gem-field">
        <div class="gem-field__title">Cut</div>
        <div class="gem-field__value">
          {{ selectedGem.stoneCut?.name || '–' }}
        </div>
      </div>
      <div class="gem-field">
        <div class="gem-field__title">Clarity</div>
        <div class="gem-field__value">
          {{ selectedGem.stoneClarity?.name || '–' }}
        </div>
      </div>
      <div class="gem-field">
        <div class="gem-field__title">Color</div>
        <div class="gem-field__value">
          {{ selectedGem.stoneColor?.colorName || '–' }}
        </div>
      </div>
      <div
        class="gem-field"
        (dblclick)="updateStone()"
        style="cursor: pointer"
        title="Double click to update"
      >
        <div class="gem-field__title">Carat Per Stone</div>
        <div class="gem-field__value">
          {{ selectedGem.caratsPerUnit || '–' }}
        </div>
      </div>
      <!-- <mat-form-field
        appearance="fill"
        style="width: 100%; margin-bottom: -16px !important"
      >
        <mat-label>Carat Per Stone</mat-label>
        <input
          matInput
          type="number"
          min="0"
          max="999.99"
          step="1"
          [(ngModel)]="selectedGem.caratsPerUnit"
          [readonly]="!editMode"
        />
      </mat-form-field> -->
      <div style="padding-top: 16px">
        <mat-slide-toggle
          [(ngModel)]="selectedGem.labGrown"
          style="color: white"
          [disabled]="!editMode"
          >Lab Grown</mat-slide-toggle
        >
      </div>
      <div class="gem-field" style="grid-column: 1 / span 2">
        <div class="gem-field__title">Size</div>
        <div class="gem-field__value size-container">
          <div class="size-value" *ngIf="selectedGem.stoneSize.dimension_1">
            {{ selectedGem.stoneSize.dimension_1 }}mm
          </div>
          <div class="size-separator" *ngIf="selectedGem.stoneSize.dimension_2">
            X
          </div>
          <div class="size-value" *ngIf="selectedGem.stoneSize.dimension_2">
            {{ selectedGem.stoneSize.dimension_2 }}mm
          </div>
          <div class="size-separator" *ngIf="selectedGem.stoneSize.dimension_3">
            X
          </div>
          <div class="size-value" *ngIf="selectedGem.stoneSize.dimension_3">
            {{ selectedGem.stoneSize.dimension_3 }}mm
          </div>
        </div>
      </div>
      <div style="padding-top: 16px">
        <mat-slide-toggle
          [(ngModel)]="selectedGem.traceable"
          style="color: white"
          [disabled]="!editMode"
          >Traceable</mat-slide-toggle
        >
      </div>
      <!-- <mat-form-field appearance="fill" style="width: 100% !important">
        <mat-label>Qty in Stock</mat-label>
        <input
          matInput
          type="number"
          min="0"
          step="1"
          [(ngModel)]="selectedGem.stockQTY"
          [readonly]="!editMode"
        />
      </mat-form-field> -->
    </div>
  </div>
  <div class="gems-actions">
    <div class="other-fields">
      <div>
        <div class="gem-field">
          <div class="gem-field__title">Carats in Stock</div>
          <div class="gem-field__value">
            {{ stoneStockTracking?.caratsInStock || '–' }}
          </div>
        </div>
        <div class="gem-field">
          <div class="gem-field__title">Qty in stock</div>
          <div class="gem-field__value">
            {{ stoneStockTracking?.qtyInSTock || '–' }}
          </div>
        </div>
        <div class="gem-field">
          <div class="gem-field__title">Price per stone</div>
          <div class="gem-field__value">
            <span
              *ngIf="
                stoneStockTracking?.lastPurchasePricePerCarat *
                selectedGem.caratsPerUnit
              "
            >
              {{
                stoneStockTracking?.lastPurchasePricePerCarat *
                  selectedGem.caratsPerUnit | currency
              }}
            </span>
            <span
              *ngIf="
                !(
                  stoneStockTracking?.lastPurchasePricePerCarat *
                  selectedGem.caratsPerUnit
                )
              "
            >
              –
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="gem-field">
          <div class="gem-field__title">Last Purchased Supplier</div>
          <div class="gem-field__value">
            {{ stoneStockTracking?.lastPurchaseSupplier || '–' }}
          </div>
        </div>
        <div class="gem-field">
          <div class="gem-field__title">Last Purchase Price Per Carat</div>
          <div class="gem-field__value">
            <span *ngIf="stoneStockTracking?.lastPurchasePricePerCarat">{{
              stoneStockTracking?.lastPurchasePricePerCarat | currency
            }}</span>
            <span *ngIf="!stoneStockTracking?.lastPurchasePricePerCarat"
              >–</span
            >
          </div>
        </div>
        <div class="gem-field">
          <div class="gem-field__title">Last Purchase Date</div>
          <div class="gem-field__value">
            {{
              (stoneStockTracking?.lastPurchaseDate | date : 'MM/dd/yyyy') ||
                '–'
            }}
          </div>
        </div>
      </div>
      <!-- <mat-form-field appearance="fill" style="width: 100%; margin-top: 15px">
        <mat-label>PPU</mat-label>
        <input
          matInput
          type="number"
          min="0"
          step="1"
          [(ngModel)]="selectedGem.pricePerUnit"
          [readonly]="!editMode"
        />
      </mat-form-field> -->
      <!-- <div class="gem-field">
        <div class="gem-field__title">PPU</div>
        <div class="gem-field__value">
          {{ selectedGem.pricePerUnit || '–' }}
        </div>
      </div> -->
    </div>
    <div class="new-buttons">
      <button
        class="riva-button"
        style="margin-bottom: 10px"
        (click)="newGem()"
        userPermission
        [elementKey]="stoneFeatureKey.add"
        [disabled]="editMode"
        style="justify-self: flex-end"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circle-plus"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="9" y1="12" x2="15" y2="12" />
          <line x1="12" y1="9" x2="12" y2="15" />
        </svg>
        New Stone
      </button>
      <button
        class="riva-button"
        style="margin-bottom: 10px"
        (click)="onOpenStoneImageDialog()"
        style="justify-self: flex-end"
        userPermission
        [elementKey]="stoneFeatureKey.customImage"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-settings"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          style="margin-right: 5px"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
          />
          <circle cx="12" cy="12" r="3" />
        </svg>
        Photo Defaults
      </button>
      <button
        class="riva-button"
        style="margin-bottom: 10px"
        (click)="createOppositeLabVersion()"
        style="justify-self: flex-end"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-copy"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"
          />
          <path
            d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"
          />
        </svg>
        {{ selectedGem.labGrown ? 'Make Nat Version' : 'Make Lab Version' }}
      </button>

      <button
        class="riva-button"
        style="margin-bottom: 10px"
        style="justify-self: flex-end"
        userPermission
        [elementKey]="stoneFeatureKey.inventoryAdjustment"
        (click)="onStoneInventoryAdjustmentDialogOpen()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-adjustments"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
          <path d="M6 4v4" />
          <path d="M6 12v8" />
          <path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
          <path d="M12 4v10" />
          <path d="M12 18v2" />
          <path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
          <path d="M18 4v1" />
          <path d="M18 9v11" />
        </svg>
        Inventory Adjustment
      </button>
      <!-- <div [ngClass]="{ hidden: editMode }">
        <button
          class="riva-button"
          style="margin-bottom: 10px"
          userPermission
          [elementKey]="stoneFeatureKey.edit"
          (click)="onEdit()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-edit-circle"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z"
            />
            <path d="M16 5l3 3" />
            <path
              d="M9 7.07a7.002 7.002 0 0 0 1 13.93a7.002 7.002 0 0 0 6.929 -5.999"
            />
          </svg>
        </button>
      </div> -->
      <!-- <div [ngClass]="{ hidden: !editMode }">
        <div style="display: flex; align-items: center">
          <button
            class="btn btn-success"
            style="
              margin-bottom: 10px;
              width: 150px;
              border-radius: 4px 0 0 5px;
            "
            (click)="onSaveStone()"
          >
            Update
          </button>
          <button
            class="btn btn-danger"
            style="margin-bottom: 10px; width: 65px; border-radius: 0 5px 5px 0"
            (click)="onCancel()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-x"
              width="35"
              height="35"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
      </div> -->
    </div>
  </div>
</div>

<mat-menu #settingMenu="matMenu">
  <button mat-menu-item class="gem-setting__menu-item">New type</button>
  <button mat-menu-item class="gem-setting__menu-item">New cut</button>
  <button mat-menu-item class="gem-setting__menu-item">New clarity</button>
  <button mat-menu-item class="gem-setting__menu-item" (click)="newSize()">
    New size
  </button>
</mat-menu>

<swal
  #confirmationDuplicateStone
  title="Duplicate Stone"
  text="Stone already exists. You can't create duplicate stones."
  icon="error"
  confirmButtonText="Close"
  [showConfirmButton]="true"
>
</swal>
