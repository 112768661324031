import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ChainRoutingComponent } from './chain-routing.component';

@Component({
  selector: 'chain-routing-dialog',
  templateUrl: './chain-routing-dialog.component.html',
})
export class ChainRoutingDialogComponent implements OnInit {
  @ViewChild(ChainRoutingComponent)
  chainRoutingComponent: ChainRoutingComponent;

  constructor(
    public dialogRef: MatDialogRef<ChainRoutingDialogComponent>,
    public loadService: LoadService,
    @Inject(MAT_DIALOG_DATA)
    public props: { chainId: number; chainType: number; editMode: boolean },
  ) {}

  ngOnInit(): void {}

  onSave() {
    this.chainRoutingComponent.onSaveProductRouting();
  }
}
