import { PAGE_NAME } from './pages';

export const FINDINGS_FEATURE_KEY = {
  create: 'FINDINGS_CREATE',
  edit: 'FINDINGS_EDIT',
  setting: 'FINDINGS_SETTING',
  addRouting: 'FINDINGS_ADD_ROUTING',
};

export const findingsPermission = {
  [PAGE_NAME.finding]: {
    SuperUser: {
      [FINDINGS_FEATURE_KEY.create]: 'access',
      [FINDINGS_FEATURE_KEY.setting]: 'access',
      [FINDINGS_FEATURE_KEY.edit]: 'access',
      [FINDINGS_FEATURE_KEY.addRouting]: 'access',
    },
    User: {
      [FINDINGS_FEATURE_KEY.create]: 'access',
      [FINDINGS_FEATURE_KEY.setting]: 'access',
      [FINDINGS_FEATURE_KEY.edit]: 'access',
      [FINDINGS_FEATURE_KEY.addRouting]: 'access',
    },
    Viewer: {
      [FINDINGS_FEATURE_KEY.create]: 'disabled',
      [FINDINGS_FEATURE_KEY.setting]: 'disabled',
      [FINDINGS_FEATURE_KEY.edit]: 'disabled',
      [FINDINGS_FEATURE_KEY.addRouting]: 'hidden',
    },
  },
};
