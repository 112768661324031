import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserPermissionDirective } from 'src/app/core/user-permission/user-permission.directive';
import { AutofocusDirective } from '../custom/directives/autofocus.directive';
import { NumberOnlyDirective } from '../custom/directives/number-only.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    UserPermissionDirective,
    AutofocusDirective,
    NumberOnlyDirective,
  ],
  exports: [UserPermissionDirective, AutofocusDirective, NumberOnlyDirective],
})
export class SharedModule {}
