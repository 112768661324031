<h3 mat-dialog-title>Add Product by Customer SKU</h3>
<mat-dialog-content class="mat-typography">
  <div>
    <mat-form-field class="account-input" appearance="fill" style="width: 100%">
      <mat-label>Customer SKU</mat-label>
      <input
        matInput
        [(ngModel)]="customerSku"
        (keydown.enter)="onFindProductByCustomerSku()"
      />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions style="display: flex; justify-content: center">
  <div
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      width: 350px;
    "
  >
    <button
      class="btn btn-danger"
      style="font-size: 12px; padding: 5px; height: 40px"
      mat-dialog-close
    >
      Close
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onFindProductByCustomerSku()"
      [disabled]="isSearching || !customerSku"
    >
      Find Product
    </button>
  </div>
</mat-dialog-actions>

<swal
  #searchConfirmation
  [title]="confirmation.title"
  [text]="confirmation.description"
  [icon]="confirmation.icon"
  confirmButtonText="Okay"
  [showConfirmButton]="confirmation.icon === 'error'"
  [timer]="confirmation.timer"
>
</swal>
