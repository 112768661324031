import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FindingsService } from 'src/app/services/findings.service';
import { FINDINGS_TYPE } from '../../constants';
import { Finding } from '../../model';

@Component({
  templateUrl: './flyer-dialog.component.html',
  styleUrls: ['./flyer-dialog.component.scss'],
})
export class FlyerDialogComponent {
  isSaving = false;
  flyer: Finding = {} as Finding;

  constructor(
    public dialogRef: MatDialogRef<FlyerDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { flyers: Finding[] },

    private toastrService: ToastrService,
    private findingsService: FindingsService,
  ) {}

  onSaveflyer() {
    const isExist = this.data.flyers.some((j) => {
      const { size_1 } = this.flyer;
      return j.size_1 === size_1;
    });
    if (isExist) {
      this.toastrService.error('Size already exist.', 'Flyer');
      return;
    }
    this.isSaving = true;
    this.findingsService
      .setFindingsSizes({
        ...this.flyer,
        findingsTypesID: FINDINGS_TYPE.FLYER,
      })
      .subscribe((id) => {
        this.findingsService
          .setFinding({
            findingsId: 0,
            commentBox: '',
            findingSizesId: id,
          })
          .subscribe(() => {
            this.isSaving = false;
            this.toastrService.success('Successfully saved.', 'Flyer');
            this.dialogRef.close(true);
          });
      });
  }

  get isFormValid() {
    const { size_1 } = this.flyer;

    return size_1 && size_1 < 100;
  }
}
