import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';
import { UserPermissionService } from './user-permission.service';

@Directive({
  selector: '[userPermission]',
})
export class UserPermissionDirective implements OnInit, OnDestroy {
  @Input('elementKey') elementKey: string;
  @Input('disabled') disabled?: boolean;
  @Input('overrideDisable') overrideDisable?: boolean;
  @Input('hidden') hidden?: boolean;

  hasAccess: boolean = true;

  intervalId;

  constructor(
    private elementRef: ElementRef,
    private userPermissionService: UserPermissionService,
    private tokenStorageService: TokenStorageService,
  ) {}

  ngOnInit() {
    this.elementRef.nativeElement.disabled = true;
    this.checkAccess();

    fromEvent(this.elementRef.nativeElement, 'click', {
      capture: true,
    }).subscribe((e: MouseEvent) => {
      if (!this.hasAccess) {
        e.stopImmediatePropagation();
      }
    });
  }
  checkAccess() {
    const user = this.tokenStorageService.getUser();
    if (
      user.isAdmin ||
      this.userPermissionService.userFeaturesPermission != null
    ) {
      this.checkPermissions(this.userPermissionService.userFeaturesPermission);
    } else {
      this.intervalId = setInterval(() => {
        this.checkPermissions(
          this.userPermissionService.userFeaturesPermission,
        );
      }, 200);
    }
  }
  checkPermissions(permissions) {
    const user = this.tokenStorageService.getUser();
    const control = permissions?.[this.elementKey];

    if (user.isAdmin || control === 'access') {
      this.elementRef.nativeElement.style.display = 'block';
      this.elementRef.nativeElement.disabled = this.overrideDisable ?? false;
      this.hasAccess = true;
    } else if (control === 'hidden') {
      const isHidden = this.hidden ?? true;
      this.elementRef.nativeElement.style.display = isHidden ? 'none' : 'block';
      this.hasAccess = false;
    } else {
      this.elementRef.nativeElement.disabled = true;
      this.hasAccess = false;
    }
    if (permissions != null || user.isAdmin) {
      this.onDestroyIntervals();
    }
  }
  ngOnDestroy() {
    this.onDestroyIntervals();
  }

  onDestroyIntervals() {
    if (!this.intervalId) return;
    for (let i = 1; i <= this.intervalId; i++) {
      clearInterval(i);
    }
  }
}
