import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartConfiguration, ChartType } from 'chart.js';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { Customers } from 'src/app/models/customer';
import { AnalyticsCustomer, AnalyticsService } from 'src/app/services/analytics.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Invoice } from '../invoicing-shipping/invoicing/models/invoice';

interface CustomerTotalBilled {
  customerId: number;
  customer: string;
  totalBilled: number;
}
interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
  customerId?: number;
}
@Component({
  selector: 'riva-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
})
export class ReportingComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  lineChartData: ChartConfiguration['data'];
  lineChartDataForQty: ChartConfiguration['data'];

  displayedColumns = [
    'customer',
    'totalBilled',
  ];

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      y: {
        position: 'left',
        grid: {
          color: '#68516d',
        },
        ticks: {
          color: '#68516d',
        },
      },
    },

    plugins: {
      legend: { display: true },
    },
  };
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  customers: Customers[];
  customersTotalBilled = new MatTableDataSource<CustomerTotalBilled>([]);
  invoices: Invoice[];
  customerInvoices: AnalyticsCustomer[];
  selectedCustomer = new FormControl([]);
  viewType: 'monthly' | 'weekly' = 'monthly'
  totalBilledForCustomer = 0;
  
  filters: Filters = {
    dateRangeFrom: DateTime.local().minus({ months: 9 }).toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.local().toFormat('yyyy-MM-dd'),
    customerId: 0,
  };
  invoiceParams = {
    SortBy: 'invoicesID',
    SortDirection: 'desc',
    PageNumber: 1,
    PageSize: 25,
    SearchQuery: '',
    CustomerId: 0,
  };

  constructor(
    private customerService: CustomerService,
    private analyticsService: AnalyticsService,
    private userPermissionService: UserPermissionService
  ) {
    this.userPermissionService.checkPagePermission(
      PAGE_NAME.reporting,
    );
  }

  ngOnInit(): void {
    this.customerService.getList().subscribe((customers) => {
      this.customers = orderBy(customers, 'companyName');
    });
    this.getInvoices();
  }
  ngAfterViewInit() {
    this.customersTotalBilled.sort = this.sort;
  }

  onFilter() {
    const customerIds = this.filters.customerId > 0 ? [this.filters.customerId] : [];
    const startDate = DateTime.fromISO(this.filters.dateRangeFrom);
    const endDate = DateTime.fromISO(this.filters.dateRangeTo).plus({ day: 1 });
    const filteredDataByInvoicedDate = this.customerInvoices.reduce((data, customer) => {
      const invoices = customer.invoices.filter((i) => {
        const invoicedDate = DateTime.fromISO(i.invoicedDate);
        const inRange = invoicedDate >= startDate && invoicedDate <= endDate;
        return inRange;
      });
      return [...data, {...customer, invoices }]
    }, []);
    this.customersTotalBilled.data = filteredDataByInvoicedDate.reduce((list, customer) => {
      return [...list, { customer: customer.customer, customerId: customer.custIdNo, totalBilled: customer.invoices.reduce((total, i) => total + i.totalDue, 0) }]
    }, [])
    this.totalBilledForCustomer = this.customersTotalBilled.data.reduce((total, customer) => total + customer.totalBilled, 0)
    const filteredData = filteredDataByInvoicedDate.filter((customer) => customerIds.length === 0 || customerIds.includes(customer.custIdNo))
    this.viewType === 'monthly' ? this.parseDataForTotalBilledGraphPerMonth(filteredData) : this.parseDataForTotalBilledGraphPerWeek(filteredData, startDate, endDate);
  }

  onDateRangeChange({ start, end }) {
    this.filters.dateRangeFrom = start;
    this.filters.dateRangeTo = end;
    this.onFilter();
  }

  getInvoices() {
    this.analyticsService.getAnalyticsInvoices().subscribe((data) => {
      this.customerInvoices = data;
      this.onFilter()
    });
  }

  parseDataForTotalBilledGraphPerWeek(data, startDate, endDate) {
    const allInvoices = orderBy(data.reduce((accum, d) => {
      return [...accum, ...d.invoices]
    }, []), 'invoicedDate');
    const weekGroups = this.getWeeksInRange(startDate, endDate)
    allInvoices.forEach((i) => {
      const currentGroupIndex = weekGroups.findIndex((g) => {
        const invoicedDate = DateTime.fromISO(i.invoicedDate.split('T')[0]);
        const inRange = invoicedDate >= g.startDate && invoicedDate <= g.endDate;
        return inRange;
      })
      if (currentGroupIndex >= 0) {
        const group = weekGroups[currentGroupIndex]
        group.total = group.total + i.totalDue;
        weekGroups[currentGroupIndex] = group;
      }
    })
    let weekData = weekGroups.map((g) => g.total)
    let weekLabel = weekGroups.map((g) => g.label)

    this.lineChartData = {
      datasets: [
        {
          data: weekData,
          label: 'Total Billed Per Week',
          backgroundColor: 'transparent',
          borderColor: '#9854a7',
          pointBackgroundColor: '#9854a7',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
        }
      ],
      labels: weekLabel,
    };
  }

  parseDataForTotalBilledGraphPerMonth(data) {
    const allInvoices = orderBy(data.reduce((accum, d) => {
      return [...accum, ...d.invoices]
    }, []), 'invoicedDate');
    const groupedInvoices = allInvoices.map((i) => {
      const invoicedDate = new Date(i.invoicedDate)
      const month = invoicedDate.toLocaleString('default', { month: 'long' });
      const year = invoicedDate.getFullYear();
      return {...i, group: `${invoicedDate.getMonth() + 1}-${month} ${year}`}
    });
    const monthRange = this.getMonthsInDateRange(this.filters.dateRangeFrom, this.filters.dateRangeTo);
    const group = groupBy(groupedInvoices, (i) => i.group);
    let labels = Object.keys(monthRange).map((m) => m.split('-')[1]);
    const totalBilledPerMonth = Object.keys(group).reduce((totalPerMonth, key) => {
      totalPerMonth[key] = group[key].reduce((total, x) => total + x.totalDue, 0);
      return totalPerMonth
    }, monthRange);
    let values = Object.values(totalBilledPerMonth) as number[]

    this.lineChartData = {
      datasets: [
        {
          data: values,
          label: 'Total Billed Per Month',
          backgroundColor: 'transparent',
          borderColor: '#9854a7',
          pointBackgroundColor: '#9854a7',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
        }
      ],
      labels,
    };
  }
  
  parseDataForTotalQtyGraph(data) {
    const allInvoices = orderBy(data.reduce((accum, d) => {
      return [...accum, ...d.invoices]
    }, []), 'invoicedDate');
    const groupedInvoices = allInvoices.map((i) => {
      const invoicedDate = new Date(i.invoicedDate)
      const month = invoicedDate.toLocaleString('default', { month: 'long' });
      const year = invoicedDate.getFullYear();
      return {...i, group: `${invoicedDate.getMonth()}-${month} ${year}`}
    });
    const group = groupBy(groupedInvoices, (i) => i.group);
    const labels = Object.keys(group).map((m) => m.split('-')[1]);

    const datasets = data.reduce((accum, d) => {
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16,
      )}`;
      const value = d.invoices.reduce(
        (accum, invoice) => {
          const invoicedDate = new Date(invoice.invoicedDate)
          const month = invoicedDate.toLocaleString('default', { month: 'long' });
          const year = invoicedDate.getFullYear();
          const group = `${month} ${year}`

          const monthIndex = labels.findIndex((g) => g === group);
          accum[monthIndex] = accum[monthIndex] + invoice.qtyTotal;
          return accum;
        },
        labels.map(() => 0),
      );
      const item = {
        data: value,
        label: d.customer,
        backgroundColor: 'transparent',
        borderColor: randomColor,
        pointBackgroundColor: randomColor,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      };
      return [...accum, item];
    }, []);
    this.lineChartDataForQty = {
      datasets,
      labels,
    };
  }

  onTableRowClick(row) {
    this.filters.customerId = row.customerId;
    this.onFilter()
  }
  onFilterAllCustomer() {
    this.filters.customerId = 0;
    this.onFilter()
  }
  onRangeChange(months?: number){
    if (months == null) {
      this.filters.dateRangeFrom = `${DateTime.local().year}-01-01`
      this.filters.dateRangeTo =  DateTime.local().toFormat('yyyy-MM-dd');
    } else {
      this.filters.dateRangeFrom = DateTime.local().minus({ months }).toFormat('yyyy-MM-dd');
      this.filters.dateRangeTo =  DateTime.local().toFormat('yyyy-MM-dd');
    }
    this.onFilter();
  }
  onSetDateRange(config) {
    this.filters.dateRangeFrom = config.from;
    this.filters.dateRangeTo = config.to;
    this.onFilter();
  }
  getMonthsInDateRange(startDate, endDate) {
    let start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);
    const months = {};
  
    while (start <= end) {
      const monthName = start.toFormat('MMMM yyyy');
      const key = `${start.month}-${monthName}`
      months[key] = 0;
      start = start.plus({ months: 1 });
    }
  
    return months;
  }
  getWeeksInRange(startDate, endDate) {
    const weeks = [];

    let currentWeekStart = startDate;
  
    while (currentWeekStart <= endDate) {
      const currentWeekEnd = currentWeekStart.plus({ days: 6 });

      const startDay = currentWeekStart.toFormat("d")
      const startMonth = currentWeekStart.toFormat("LLL")
      const startYear = currentWeekStart.toFormat("yyyy")

      const endDay = currentWeekEnd.toFormat("d")
      const endMonth = currentWeekEnd.toFormat("LLL")
      const endYear = currentWeekEnd.toFormat("yyyy")

      const weekRange = `${startMonth} ${startDay}${startYear !== endYear ? `, ${startYear}` : ''}-${startMonth!==endMonth? `${endMonth} `: ''}${endDay}, ${endYear}`;
      const range = {
        label: weekRange,
        startDate: currentWeekStart,
        endDate: currentWeekEnd,
        total: 0
      }
      weeks.push(range);
      currentWeekStart = currentWeekEnd.plus({ days: 1 });
    }
    return weeks;
  }
}
