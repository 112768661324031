<div class="customer-page__container">
  <h2 class="customer-page__header">Customers</h2>
  <div class="customer-page__table-header">
    <button
      class="btn riva-button"
      style="
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
      (click)="onOpenDialog()"
      userPermission
      [elementKey]="featureKey.create"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-plus"
        width="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#FFFFFF"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        style="margin-right: 3px"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="12" y1="5" x2="12" y2="19" />
        <line x1="5" y1="12" x2="19" y2="12" />
      </svg>
      <span>New Customer</span>
    </button>
    <div class="customer-page__search">
      <mat-form-field class="search-input" appearance="fill">
        <mat-label>Search by company, contact or email</mat-label>
        <input
          matInput
          [(ngModel)]="search"
          (ngModelChange)="onFilterChange()"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="customer-page__table-container">
    <table
      mat-table
      [dataSource]="customers"
      matSort
      matSortActive="custIdno"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="picPath">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div
            style="
              width: 70px;
              padding: 5px;
              display: flex;
              justify-content: center;
            "
            *ngIf="element.picPath"
          >
            <img
              style="max-height: 37px; max-width: 60px"
              [src]="element.picPath"
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
          <div
            style="
              width: 70px;
              padding: 5px;
              display: flex;
              justify-content: center;
            "
            *ngIf="!element.picPath"
          >
            <img
              style="max-height: 37px; max-width: 60px"
              src="assets/images/no-image.png"
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="customerId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by customerId"
        >
          Customer ID
        </th>
        <td mat-cell *matCellDef="let element">{{ element.customerId }}</td>
      </ng-container>
      <ng-container matColumnDef="companyName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by companyName"
        >
          Company
        </th>
        <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
      </ng-container>
      <ng-container matColumnDef="contactName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by contactName"
        >
          Contact
        </th>
        <td mat-cell *matCellDef="let element">{{ element.contactName }}</td>
      </ng-container>
      <ng-container matColumnDef="emailAddress">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by emailAddress"
        >
          Email
        </th>
        <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
      </ng-container>
      <ng-container matColumnDef="leadtimeDays">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by leadtimeDays"
        >
          Leadtime
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.leadtimeDays }}
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentTerms">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by paymentTerms"
        >
          Payment Terms
        </th>
        <td mat-cell *matCellDef="let element">{{ element.paymentTerms }}</td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            class="riva-button riva-button-sm"
            (click)="onOpenDialog(element)"
            userPermission
            [elementKey]="featureKey.edit"
          >
            Edit
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="10">No Available Data</td>
      </tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10]"
      showFirstLastButtons
      hidePageSize
    ></mat-paginator>
  </div>
</div>
