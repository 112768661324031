import { Component, OnInit } from '@angular/core';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnInit {
  constructor(private userPermissionService: UserPermissionService) {
    this.userPermissionService.checkPagePermission(null);
  }

  ngOnInit(): void {}
}
