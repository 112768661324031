import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EmployeeDownTime } from 'src/app/components/work-order-tracking/model';
import { User } from 'src/app/core/authentication/user';
import { UserService } from 'src/app/services/user.service';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'work-order-employee-down-time',
  templateUrl: './work-order-employee-down-time.component.html',
  styleUrls: ['./work-order-employee-down-time.component.scss'],
})
export class WorkOrderEmployeeDownTimeComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('employeeSort', { read: MatSort, static: true }) sort1: MatSort;
  @ViewChild('workOrderSort', { read: MatSort, static: true }) sort2: MatSort;

  displayedColumnsForEmployee = ['fullName', 'employeeID'];
  displayedColumnsForWorkOrder = [
    'scanInTime',
    'totalMinutesActive',
    'workDayDifference',
  ];
  accounts = new MatTableDataSource<User>([]);
  search = '';
  selectedUser: User | null = null;
  employeeDownTime = new MatTableDataSource<EmployeeDownTime>([]);

  constructor(
    public dialog: MatDialog,
    private workOrderMovementService: WorkOrderMovementService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService.getUsers().subscribe((data = []) => {
      this.accounts.data = data
        .filter((a) => a.employeeID)
        .map((account) => ({
          ...account,
          fullName: this.getFullName(account),
        }));
    });
  }

  ngAfterViewInit(): void {
    this.accounts.sort = this.sort1;

    this.accounts.filterPredicate = (data: User, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.fullName
          ?.toString()
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        data.employeeID?.toLowerCase().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  getFullName(account: User) {
    const name = [];
    account.firstName && name.push(account.firstName);
    account.lastName && name.push(account.lastName);
    if (!name.length) {
      name.push(account.userName);
    }
    return name.join(' ');
  }

  onFilterChange() {
    this.accounts.filter = JSON.stringify({ search: this.search });
  }

  onSelectUser(user: User) {
    this.selectedUser = user;
    this.getEmployeeDownTime(user.usersID);
  }

  getEmployeeDownTime(userId: number) {
    this.workOrderMovementService
      .getEmployeeDownTime(userId)
      .subscribe((data) => {
        this.employeeDownTime.data = data;
        this.employeeDownTime.sort = this.sort2;
      });
  }
}
