import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FINDINGS_TYPE } from 'src/app/components/riva-findings/constants';
import { Finding, FindingsStock } from 'src/app/components/riva-findings/model';
import { FindingsMaterial } from 'src/app/models/findings.model';
import { MaterialCode } from 'src/app/models/material-code';
import { FindingsService } from 'src/app/services/findings.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';

@Component({
  selector: 'bom-finding-jumpring',
  templateUrl: './bom-jumpring.component.html',
  styleUrls: ['./bom-jumpring.component.scss'],
})
export class BomJumpringComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() findings: Finding[];
  @Input() selectedJumpring: Finding;
  @Output() onReloadList = new EventEmitter();
  @Output() onSelectFinding = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // selectedJumpring: Finding = {} as Finding;
  selectedStock: FindingsStock = {} as FindingsStock;
  stocks: FindingsStock[] = [];
  jumprings = new MatTableDataSource<Finding>([]);
  materialCodes: MaterialCode[] = [];
  displayedColumns = ['size_1', 'jumpringSize'];
  isOverride = false;
  selectedMaterial: FindingsMaterial;
  materials = [];

  constructor(
    private findingsService: FindingsService,
    private materialCodeService: MaterialCodeService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject;
    });
  }
  ngOnChanges(): void {
    this.jumprings.data = this.findings.filter(
      (f) => f.findingsTypesID === FINDINGS_TYPE.JUMPRINGS,
    );
    // this.getStockBySizeId();
    // this.selectedJumpring =
    //   this.jumprings.data?.[this.jumprings.data?.length - 1] ?? ({} as Finding);
    // this.getStockBySizeId();
    // this.onSelectFinding.emit(this.selectedJumpring);
  }
  ngAfterViewInit() {
    this.jumprings.sort = this.sort;
    this.jumprings.paginator = this.paginator;
  }
  getStockBySizeId() {
    if (!this.selectedJumpring.findingSizesID) {
      this.selectedStock = {} as FindingsStock;
      return;
    }
    this.findingsService
      .getFindingsStockBySizeId(this.selectedJumpring.findingSizesID)
      .subscribe((data) => {
        this.stocks = data ?? [];
        const [stock = {} as FindingsStock] = data ?? [];
        this.selectedStock = this.selectedStock.materialsCodeID
          ? {
              ...(data.find(
                (s) => s.materialsCodeID === this.selectedStock.materialsCodeID,
              ) ?? ({} as FindingsStock)),
            }
          : { ...stock };
      });
  }

  onTableRowClick(selected: Finding) {
    // this.selectedJumpring = selected;
    this.onSelectFinding.emit(selected);
  }

  onChangeMaterialCode() {
    const stock = this.stocks.find(
      (s) => s.materialsCodeID === this.selectedStock.materialsCodeID,
    );
    this.selectedStock = stock
      ? { ...stock }
      : ({
          materialsCodeID: this.selectedStock.materialsCodeID,
        } as FindingsStock);
  }

  isOverrideChange() {
    if (!this.isOverride) {
      this.selectedJumpring.findingsMaterialsID = null;
    }
  }
}
