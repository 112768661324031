import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';
import {
  RivaFindings,
  RivaFindingsDto,
  Supplier,
} from 'src/app/models/findings.model';
import { MaterialCode } from 'src/app/models/material-code';
import { Suppliers } from 'src/app/models/suppliers.model';
import { UnitOfMeasure } from 'src/app/models/unit-of-measure';
import { FindingsService } from 'src/app/services/findings.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { SupplierService } from 'src/app/services/supplier.service';
import { UnitOfMeasureService } from 'src/app/services/unit-of-measurement.service';
import { ConfirmationService } from '../../riva-confirmation/riva-confirmation.service';

@Component({
  selector: 'findings-details',
  templateUrl: './findings-details.component.html',
  styleUrls: ['./findings-details.component.scss'],
})
export class FindingsDetailsComponent implements OnInit {
  @Input() findings: RivaFindings = {} as RivaFindings;
  @Input() editMode: boolean = false;
  @Output() onSetEditMode = new EventEmitter();
  @Output() callback = new EventEmitter();

  uomOptions$: Observable<UnitOfMeasure[]>;
  suppliers: Suppliers[] = [];
  materialCodes: MaterialCode[] = [];
  imageFiles: FileList | null = null;
  isSaving: boolean = false;
  findingMaterialCode: number;
  findingsSize: string;
  showFindingsSizeError = false;
  hasDeleteAccess = false;

  constructor(
    private findingsService: FindingsService,
    private uomService: UnitOfMeasureService,
    private supplierService: SupplierService,
    private materialCodeService: MaterialCodeService,
    private confirmationService: ConfirmationService,
    private tokenStorageService: TokenStorageService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.uomOptions$ = this.uomService
      .getList()
      .pipe(map((data) => data.responseObject));
    this.getSuppliers();
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject;
    });
  }

  get filteredMaterialCodes() {
    return this.materialCodes?.filter(
      (c) =>
        !this.findings?.materials?.some(
          (m) => m.materialCodeId === c.materialCodeId,
        ),
    );
  }

  getSuppliers() {
    this.supplierService.getList().subscribe((result) => {
      this.suppliers = result;
    });
  }

  setEditMode() {
    this.onSetEditMode.emit();
  }
  onCancel() {
    this.callback.emit();
  }
  onChangeSource() {
    if (this.findings.source) {
      this.findings.supplier = this.suppliers?.[0];
    } else {
      this.findings.supplier = {} as Supplier;
    }
  }

  saveFindings() {
    const findings: RivaFindingsDto = {
      findingsId: this.findings.findingsId,
      name: this.findings.name,
      sku: this.findings.sku,
      suppliersId: this.findings.supplier.suppliersId,
      commentBox: this.findings.commentBox,
      unitsOfMeasureId: this.findings.unitsOfMeasure.unitsOfMeasureId,
      picPath: this.findings.picPath ?? 'assets/images/no-image.png',
      createdBy:
        this.findings.createdBy ??
        this.tokenStorageService.currentUser?.userName ??
        '',
      source: this.findings.source,
    };
    return this.findingsService.setFindings(findings);
  }

  onSaveFindings() {
    this.isSaving = true;
    this.saveFindings().subscribe((data: number) => {
      this.findings.findingsId = data;
      if (this.findings.findingsId && this.imageFiles?.length) {
        this.uploadImage();
        return;
      }
      this.callback.emit();
      this.isSaving = false;
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(
      url ?? 'https://test.rivapm.com/assets/images/no-image.png',
    );
  }

  onClickUpload(uploadImage) {
    uploadImage?.click();
  }

  onUploadChange(files: FileList) {
    this.imageFiles = files;
    this.findings.picPath = URL.createObjectURL(files[0]);
  }

  uploadImage() {
    const formData = new FormData();

    for (let i = 0; i < this.imageFiles.length; i++) {
      formData.append('file[]', this.imageFiles[i]);
    }

    formData.append('findingsId', this.findings.findingsId.toString());
    formData.append('sku', this.findings.sku);

    this.findingsService.uploadImage(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' + Math.round((100 * event.loaded) / event.total) + '%',
        );
      } else if (event.type === HttpEventType.ResponseHeader) {
        this.findings.picPath = event.url;
        this.callback.emit();
        this.isSaving = false;
      }
    });
  }

  onSaveFindingsSize() {
    this.showFindingsSizeError = false;
    const isExist = this.findings.sizes.some(
      (size) => size.size == this.findingsSize.trim(),
    );
    if (isExist) {
      this.showFindingsSizeError = true;
      return;
    }
    const data = {
      findingSizesID: 0,
      findingsID: this.findings.findingsId,
      size: this.findingsSize,
    };
    this.findingsService
      .setFindingsSizes(data)
      .subscribe((findingSizesID: number) => {
        data.findingSizesID = findingSizesID;
        const findings = { ...this.findings };
        findings.sizes.push(data);
        this.findings =
          this.findingsService.orderFindingsMaterialAndSize(findings);
        this.findingsSize = '';
      });
  }

  onSaveFindingsMaterial() {
    const data = {
      findingsMaterialsCodeId: 0,
      findingsID: this.findings.findingsId,
      materialsCodeID: this.findingMaterialCode,
    };
    this.findingsService
      .setFindingsMaterials(data)
      .subscribe((findingsMaterialsCodeId: number) => {
        const { materialCodeId, code, karat, color, description } =
          this.materialCodes.find(
            (c) => c.materialCodeId === this.findingMaterialCode,
          ) ?? {};
        const findingsMaterialCode = {
          materialCodeId,
          code,
          karat,
          color,
          description,
          findingsMaterialsCodeId,
        };
        const findings = { ...this.findings };
        findings.materials.push(findingsMaterialCode);
        this.findings =
          this.findingsService.orderFindingsMaterialAndSize(findings);
        this.findingMaterialCode = null;
      });
  }

  onDeleteMaterial(material) {
    this.confirmationService
      .showConfirmation({
        content: `Continue to delete ${material.description} from findings materials?`,
        title: 'Delete Material?',
        confirmLabel: 'Continue',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.findingsService
            .deleteFindingsMaterials(material.findingsMaterialsCodeId)
            .subscribe();
          this.findings.materials = this.findings.materials.filter(
            (m) =>
              m.findingsMaterialsCodeId !== material.findingsMaterialsCodeId,
          );
        }
      });
  }

  onDeleteSize(size) {
    this.confirmationService
      .showConfirmation({
        content: `Continue to delete ${size.size} from findings sizes?`,
        title: 'Delete Size?',
        confirmLabel: 'Continue',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.findingsService
            .deleteFindingsSizes(size.findingSizesID)
            .subscribe();
          this.findings.sizes = this.findings.sizes.filter(
            (s) => s.findingSizesID !== size.findingSizesID,
          );
        }
      });
  }

  get isFormValid() {
    return (
      this.findings.name &&
      this.findings.sku &&
      this.findings.unitsOfMeasure.unitsOfMeasureId
    );
  }
}
