<div class="riva-gems">
  <div class="riva-gems-item" style="margin-bottom: 15px">
    <gems-details
      [selectedGem]="selectedGem"
      [gems]="gems"
      [editMode]="editMode"
      (onSelectGem)="onSelectGem($event)"
      (onSetEditMode)="onSetEditMode($event)"
    ></gems-details>
  </div>
  <div class="riva-gems-item">
    <gems-list
      [editMode]="editMode"
      [selectedGem]="selectedGem"
      [list]="gems"
      (onSelectGem)="onSelectGem($event)"
      (onSetEditMode)="onSetEditMode($event)"
    ></gems-list>
  </div>
</div>
