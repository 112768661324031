<div class="open-order-report">
  <div class="open-order-action">
    <div class="open-order-filter">
      <mat-form-field
        class="search-input"
        appearance="fill"
        style="width: 300px"
      >
        <mat-label>Search by WKO Number</mat-label>
        <input
          matInput
          [(ngModel)]="searchQuery"
          (ngModelChange)="onFilter()"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Filter by customer</mat-label>
        <mat-select
          [(ngModel)]="filter.CustomerId"
          (ngModelChange)="getOpenOrders()"
        >
          <mat-option [value]="0" selected>ALL</mat-option>
          <mat-option
            *ngFor="let customer of customerList"
            [value]="customer.customerId"
          >
            {{ customer.customerName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Filter by stones released</mat-label>
        <mat-select
          [(ngModel)]="filter.StoneReleased"
          (ngModelChange)="getOpenOrders()"
        >
          <mat-option [value]="0" selected>ALL</mat-option>
          <mat-option [value]="1" selected>Stone Released</mat-option>
          <mat-option [value]="2" selected>Stone Unreleased</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Sort by</mat-label>
        <mat-select [(ngModel)]="sortBy" (ngModelChange)="getOpenOrders()">
          <mat-option value="customername">Customer</mat-option>
          <mat-option value="duedate">Due Date</mat-option>
        </mat-select>
      </mat-form-field>
      <div style="padding-top: 10px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-sort-ascending"
          width="35"
          height="35"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          *ngIf="sortDirection === 'asc'"
          (click)="onSortChange()"
          style="cursor: pointer"
          nzTooltipTitle="Ascending"
          nzTooltipPlacement="bottom"
          nz-tooltip
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="4" y1="6" x2="11" y2="6" />
          <line x1="4" y1="12" x2="11" y2="12" />
          <line x1="4" y1="18" x2="13" y2="18" />
          <polyline points="15 9 18 6 21 9" />
          <line x1="18" y1="6" x2="18" y2="18" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="sortDirection === 'desc'"
          class="icon icon-tabler icon-tabler-sort-descending"
          width="35"
          height="35"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          (click)="onSortChange()"
          style="cursor: pointer"
          nzTooltipTitle="Descending"
          nzTooltipPlacement="bottom"
          nz-tooltip
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1="4" y1="6" x2="13" y2="6" />
          <line x1="4" y1="12" x2="11" y2="12" />
          <line x1="4" y1="18" x2="11" y2="18" />
          <polyline points="15 15 18 18 21 15" />
          <line x1="18" y1="6" x2="18" y2="18" />
        </svg>
      </div>
    </div>
    <div class="action-buttons">
      <button
        type="button"
        class="btn btn-success"
        style="width: 180px"
        (click)="onScanWorkOrder()"
        userPermission
        [elementKey]="featureKey.release"
      >
        Release
      </button>
      <button class="riva-button" style="width: 180px" (click)="onPrint()">
        Print
      </button>
      <button
        class="btn btn-warning"
        style="width: 180px"
        (click)="onOpenHistoryDialog()"
      >
        Release History
      </button>
    </div>
  </div>
  <div class="loading-orders" [ngClass]="{ 'loading-hidden': !isLoading }">
    <div class="loading-spinner">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
      <h2 style="color: white; margin-top: 120px">Loading Orders...</h2>
    </div>
  </div>
  <div style="margin-top: 16px" [ngClass]="{ hidden: isLoading }">
    <div class="no-data-available" *ngIf="openOrders.length === 0">
      No Data Available
    </div>
    <div class="open-order-canvas">
      <div class="open-order-container" id="print-section">
        <div class="open-order-item" *ngFor="let item of openOrders">
          <div>
            <div class="image-container">
              <img
                style="max-width: 100%; max-height: 100%"
                [src]="item.picturePath"
                onerror="this.src = 'assets/images/no-image.png'"
              />
            </div>
          </div>
          <div class="open-order-details">
            <div class="open-order-summary">
              <div>Name:</div>
              <div>{{ item.productName }}</div>
            </div>
            <div class="open-order-items">
              <table>
                <tr>
                  <th class="td-workorder">Work Order #</th>
                  <th class="td-type">Type</th>
                  <th class="td-cut">Cut</th>
                  <th class="td-clarity">Clarity</th>
                  <th class="td-color">Color</th>
                  <th class="td-size">Size</th>
                  <th class="td-lab-grown">Lab Grown</th>
                  <th class="td-traceable">Traceable</th>
                  <th class="td-customer-provided">Customer Provided</th>
                  <th class="td-qty">Total Qty</th>
                  <th class="td-released-date">Released Date</th>
                </tr>
                <ng-container
                  *ngFor="let orderItems of item.items; let parentIndex = index"
                >
                  <tr
                    *ngFor="let order of orderItems; let i = index"
                    [ngClass]="{
                      'item-separator':
                        i === orderItems.length - 1 &&
                        parentIndex !== item.items.length - 1 &&
                        orderItems.length > 1
                    }"
                  >
                    <td>
                      <a
                        *ngIf="i === 0"
                        class="link"
                        (click)="onPrintWorkOrder(order)"
                        >{{ order.workOrdersId }}</a
                      >
                    </td>
                    <td>
                      {{ order.stoneType }}
                    </td>
                    <td>{{ order.stoneCut }}</td>
                    <td>{{ order.stoneClarity }}</td>
                    <td>{{ order.stoneColor }}</td>
                    <td>{{ order.stoneSize }}</td>
                    <td>{{ order.labGrown ? 'Yes' : 'No' }}</td>
                    <td>{{ order.traceable ? 'Yes' : 'No' }}</td>
                    <td>{{ order.customerProvided ? 'Yes' : 'No' }}</td>
                    <td>{{ order.totalStoneQty }}</td>
                    <td>{{ order.stonereleasedate | date : 'MM/dd/yyyy' }}</td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isScanning">
  <div class="stone-release-scanning-overlay" @fadeAnimation>
    <div class="scanning-message">
      <div class="scanning-content" [ngClass]="{ 'has-error': hasError }">
        <div class="scanning-icon">
          <svg
            [ngClass]="{ hidden: hasError }"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-qrcode"
            width="70"
            height="70"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 17l0 .01" />
            <path
              d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 7l0 .01" />
            <path
              d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M17 7l0 .01" />
            <path d="M14 14l3 0" />
            <path d="M20 14l0 .01" />
            <path d="M14 14l0 3" />
            <path d="M14 20l3 0" />
            <path d="M17 17l3 0" />
            <path d="M20 17l0 3" />
          </svg>

          <svg
            [ngClass]="{ hidden: !hasError }"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-qrcode-off"
            width="70"
            height="70"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ff4500"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M8 4h1a1 1 0 0 1 1 1v1m-.297 3.711a1 1 0 0 1 -.703 .289h-4a1 1 0 0 1 -1 -1v-4c0 -.275 .11 -.524 .29 -.705"
            />
            <path d="M7 17v.01" />
            <path
              d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 7v.01" />
            <path
              d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M17 7v.01" />
            <path d="M20 14v.01" />
            <path d="M14 14v3" />
            <path d="M14 20h3" />
            <path d="M3 3l18 18" />
          </svg>
        </div>
        <div>
          <div [ngClass]="{ hidden: hasError }">Scanning</div>
          <div [ngClass]="{ hidden: !hasError }">
            <div class="error-title">Oops! Something Went Wrong</div>
            <p class="error-message">
              We’re sorry, but we’re experiencing an issue. Please verify that
              the work order number is correct.
            </p>
            <button
              id="error-close-button"
              class="riva-button"
              style="width: 150px"
              (click)="onCloseErrorMessage()"
            >
              Close
            </button>
          </div>
        </div>
        <div [ngClass]="{ hidden: hasError }">
          <svg
            width="70"
            height="15"
            viewBox="0 0 120 30"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <circle cx="15" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
              <animate
                attributeName="r"
                from="9"
                to="9"
                begin="0s"
                dur="0.8s"
                values="9;15;9"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="0.5"
                to="0.5"
                begin="0s"
                dur="0.8s"
                values=".5;1;.5"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="105" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<swal
  #errorWorkOrder
  [title]="error.title"
  [text]="error.description"
  [focusConfirm]="false"
  icon="error"
  confirmButtonText="Ok, Got it."
>
</swal>

<swal
  #successRelease
  title="Stone Release"
  text="Stone has been successfully released."
  icon="success"
  [showConfirmButton]="false"
  [timer]="1000"
>
</swal>
