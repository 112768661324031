import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { INVOICING_SHIPPING_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/invoicing-shipping-permission';
import { Customers } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { ShipmentService } from 'src/app/services/shipments.service';
import { Shipment } from './models/shipment';

@Component({
  selector: 'shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss'],
})
export class ShippingComponent {
  displayedColumns: string[] = [
    'id',
    'qty',
    'customer',
    'shipDate',
    'tracking',
  ];
  @ViewChild(MatSort) sort: MatSort;

  shipments = new MatTableDataSource<Shipment>([]);
  searchQuery = '';
  selectedCustomer = 0;
  customers: Customers[];
  filteredCustomers: Customers[];
  customerFilterCtrl = new FormControl();
  featureKey = INVOICING_SHIPPING_FEATURE_KEY;

  pagination = {
    totalCount: 0,
  };
  shipmentParams = {
    SortBy: 'invoicesID',
    SortDirection: 'desc',
    PageNumber: 1,
    PageSize: 25,
    SearchQuery: '',
    CustomerId: 0,
  };
  searchQueryChange: Subject<string> = new Subject<string>();
  @ViewChild('paginator') paginator;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private shipmentService: ShipmentService,
    private customerService: CustomerService,
  ) {}

  ngOnInit(): void {
    this.getShipments(false);
    this.customerService.getList().subscribe((customers) => {
      this.customers = orderBy(customers, 'companyName');
      this.filteredCustomers = [...this.customers];
      this.customerFilterCtrl.valueChanges.subscribe(() => {
        this.filterCustomers();
      });
    });
    this.searchQueryChange
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((searchQuery) => {
        this.shipmentParams.SearchQuery = searchQuery;
        this.getShipments();
      });
  }

  getShipments(resetPage = true) {
    if (resetPage) {
      this.paginator._pageIndex = 0;
      this.shipmentParams.PageNumber = 1;
    }
    this.shipmentService
      .getPagedShipments(this.shipmentParams)
      .subscribe(({ body, headers }) => {
        const paginationData = headers.get('X-Pagination');
        this.pagination =
          paginationData != null
            ? JSON.parse(paginationData)
            : { totalCount: 0 };
        this.shipments.data = body.map((i) => ({
          ...i,
          link: `/invoicing-and-shipping/shipping/${i.shipmentsID}`,
          shipDate: i.shipDate
            ? DateTime.fromJSDate(new Date(i.shipDate)).toFormat(
                'MM/dd/yyyy hh:mm a',
              )
            : '',
        }));
      });
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe((sort) => {
      this.shipmentParams.SortBy = sort.active;
      this.shipmentParams.SortDirection = sort.direction;
      this.getShipments();
    });
    this.shipments.sort = this.sort;
    this.shipments.filterPredicate = (data: Shipment, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.shipmentsID?.toString().includes(search?.toLowerCase()) ||
        data.shipDate?.toLocaleLowerCase().includes(search?.toLowerCase()) ||
        data.customer?.companyName
          ?.toLocaleLowerCase()
          .includes(search?.toLowerCase());
      return this.selectedCustomer > 0
        ? data.custIDNo === this.selectedCustomer && searchFilter
        : searchFilter;
    };
  }

  filterCustomers() {
    this.filteredCustomers = orderBy(
      this.customers.filter((customer) =>
        customer.companyName
          ?.toLocaleLowerCase()
          .includes(this.customerFilterCtrl.value?.trim()),
      ),
      'companyName',
    );
  }

  onSearch() {
    this.searchQueryChange.next(this.searchQuery);
  }

  goToShipping(shipmentsID = 0) {
    this.router.navigate([`invoicing-and-shipping/shipping/${shipmentsID}`]);
  }

  onPageChanged(event: PageEvent) {
    this.shipmentParams.PageNumber = event.pageIndex + 1;
    this.getShipments(false);
  }
}
