import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { ReceivingItem } from './receiving-item';

@Component({
  selector: 'receiving-item-dialog',
  templateUrl: './receiving-item-dialog.component.html',
  styleUrls: ['./receiving-item-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReceivingItemDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'selection',
    'index',
    'cipo',
    'picturePath',
    'sku',
    'materialCode',
    'size',
    'weight',
    'qty',
  ];
  receivedItems: ReceivingItem[] = [];
  isSelectedAll = true;
  isSomeSelected = false;
  isReceiving = false;
  requiredWeight = true;
  focusItemIndex = 0;

  constructor(
    public dialogRef: MatDialogRef<ReceivingItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { items: ReceivingItem[]; workOrderId: number },

    private workOrderService: WorkOrderService,
  ) {}

  ngOnInit(): void {
    this.receivedItems = this.props.items.map((item, index) => ({
      ...item,
      isFullyReceived: item.qty === item.qtyClose,
      maxQty: item.qty - item.qtyClose,
      qty: item.qty - item.qtyClose,
      weightInputId: `weightInput${index}`,
    }));
    this.selectAll(true);
  }

  onSelect() {
    const selected = this.receivedItems.filter((i) => i.selected);
    this.isSelectedAll = selected.length === this.receivedItems.length;
    this.isSomeSelected = selected.length > 0 && !this.isSelectedAll;
  }

  selectAll(checked) {
    this.receivedItems = this.receivedItems.map((i) => ({
      ...i,
      selected: checked,
    }));
    this.onSelect();
  }

  onWeightKeyDown(currentIndex) {
    const nextIndex = currentIndex + 1;
    if (this.receivedItems[nextIndex] !== undefined) {
      document.getElementById(`weightInput${nextIndex}`).focus();
    }
    const invalidForm =
      !(this.isSelectedAll || this.isSomeSelected) ||
      this.isReceiving ||
      !this.isValidSelection;
    if (!invalidForm) {
      this.onReceived();
    }
  }

  onReceived() {
    const [workOrderItem] = this.receivedItems ?? [];
    const items = this.receivedItems
      .filter((i) => i.selected)
      .map((i) => ({
        workOrdersDetailsID: i.workOrderDetailsID,
        qtyToClose: i.qty,
        wghtGrms: i.weight ?? 0,
      }));

    this.isReceiving = true;
    this.workOrderService
      .receiveWorkOrderItems({
        workOrdersID: workOrderItem.workOrdersID,
        items,
      })
      .subscribe(
        () => {
          this.dialogRef.close({
            isSuccessful: true,
            received: items.length,
            total: this.receivedItems.length,
          });
        },
        () => {
          this.dialogRef.close({ hasError: true });
        },
      );
  }
  checkWeightEntered(item: ReceivingItem) {
    if (item.weight === +this.props.workOrderId) {
      item.weight = null;
    }
  }
  get isValidSelection() {
    return !this.receivedItems.some(
      (i) =>
        i.selected &&
        ((i.weight || 0) === 0 || i.weight === +this.props.workOrderId),
    );
  }
}
