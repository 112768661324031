import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { Customers } from 'src/app/models/customer';
import { Suppliers } from 'src/app/models/suppliers.model';
import { CustomerService } from 'src/app/services/customer.service';
import { SupplierService } from 'src/app/services/supplier.service';
import { RivaGems, StoneMovementAppend } from '../../riva-gems.model';
import { StoneService } from '../../stone.service';

@Component({
  templateUrl: './stone-inventory-adjustment-dialog.component.html',
  styleUrls: ['./stone-inventory-adjustment-dialog.component.scss'],
})
export class StoneInventoryAdjustmentDialogComponent implements OnInit {
  isSaving = false;
  suppliers: Suppliers[] = [];
  inventoryAdjustment: Partial<StoneMovementAppend> = {
    inventoryValue: '',
    appendType: false,
    customerProvided: 0,
    movementDate: DateTime.now().toFormat('yyyy-MM-dd'),
  };
  qtyMinValue = 0;
  qtyMaxValue = 0;
  customersList: Customers[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { stone: RivaGems },
    public dialogRef: MatDialogRef<StoneInventoryAdjustmentDialogComponent>,
    private supplierService: SupplierService,
    private stoneService: StoneService,
    private customerService: CustomerService,
  ) {}

  ngOnInit(): void {
    this.qtyMaxValue = parseInt(`${999.9999 / this.data.stone.caratsPerUnit}`);
    this.qtyMinValue = -this.qtyMaxValue;

    this.supplierService.getList().subscribe((result) => {
      this.suppliers = result;
    });

    this.customerService.getList().subscribe((data) => {
      this.customersList = orderBy(data, 'companyName');
    });
  }

  onSave() {
    this.isSaving = true;
    const {
      appendType,
      inventoryValue,
      qtyMoved,
      caratsMoved,
      suppliersID,
      pricePerCarat,
      customerProvided = 0,
      reason,
      movementDate,
    } = this.inventoryAdjustment;

    this.stoneService
      .setStoneMovementAppends({
        stonesID: this.data.stone.stonesID,
        appendType,
        reason,
        customerProvided: appendType ? null : customerProvided,
        qtyMoved: inventoryValue === 'qty' ? qtyMoved : 0,
        caratsMoved: inventoryValue === 'carats' ? caratsMoved : 0,
        suppliersID: appendType ? suppliersID : null,
        pricePerCarat: appendType ? pricePerCarat : null,
        movementDate: DateTime.fromJSDate(new Date(movementDate)).toFormat(
          'yyyy-MM-dd',
        ),
      })
      .subscribe(() => {
        this.stoneService.reloadStones(this.data.stone.stonesID);
        this.dialogRef.close(false);
      });
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }
  onUpdateQty() {
    this.inventoryAdjustment.inventoryValue =
      parseInt(`${this.inventoryAdjustment.qtyMoved || 0}`) === 0 ? '' : 'qty';
    this.inventoryAdjustment.caratsMoved = parseFloat(
      (
        this.inventoryAdjustment.qtyMoved * this.data.stone.caratsPerUnit
      ).toFixed(4),
    );
  }
  onUpdateCarats() {
    this.inventoryAdjustment.inventoryValue =
      (this.inventoryAdjustment.caratsMoved ?? 0) === 0 ? '' : 'carats';
    this.inventoryAdjustment.qtyMoved = Math.ceil(
      this.inventoryAdjustment.caratsMoved / this.data.stone.caratsPerUnit,
    );
  }
  onChangeAppendType() {
    if (this.inventoryAdjustment.appendType) {
      this.inventoryAdjustment.customerProvided = 0;
    } else {
      this.inventoryAdjustment.suppliersID = 0;
      this.inventoryAdjustment.pricePerCarat = null;
    }
  }

  get isFormValid() {
    return (
      this.inventoryAdjustment.inventoryValue !== '' &&
      ((!this.data.stone.caratsPerUnit &&
        Math.abs(this.inventoryAdjustment.qtyMoved) > 0) ||
        (this.inventoryAdjustment.qtyMoved <= this.qtyMaxValue &&
          this.inventoryAdjustment.caratsMoved <= 999.9999 &&
          this.inventoryAdjustment.caratsMoved >= -999.9999 &&
          this.inventoryAdjustment.caratsMoved >= this.qtyMinValue))
    );
  }
}
