import {
  CdkDragEnter,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ProductImage } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';

@Component({
  templateUrl: './product-image-order-dialog.component.html',
  styleUrls: ['./product-image-order-dialog.component.scss'],
})
export class ProductImageOrderDialogComponent implements OnInit {
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  @ViewChild('errorProductImage')
  public readonly errorProductImage!: SwalComponent;

  images: ProductImage[];
  isSaving = false;
  error = {
    title: '',
    description: '',
  };

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public activeContainer;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public props: { productId: number; productImages: ProductImage[] },
    public dialogRef: MatDialogRef<ProductImageOrderDialogComponent>,
    public productService: ProductService,
  ) {
    this.target = null;
    this.source = null;
  }

  ngOnInit(): void {
    this.images = [...(this.props.productImages ?? [])];
  }

  onReorder() {
    const imageReorder = this.images.map((p, i) => ({
      ...p,
      picsOrder: i + 1,
    }));

    this.isSaving = true;
    this.productService
      .setProductImages(this.props.productId, imageReorder)
      .subscribe(
        () => {
          this.dialogRef.close([...this.images]);
          this.isSaving = false;
        },
        () => {
          this.error = {
            title: 'Product Picture',
            description:
              'There is an error while sorting order of pictures. Please try again later.',
          };
          setTimeout(() => {
            this.errorProductImage.fire();
          }, 100);
          this.isSaving = false;
        },
      );
  }

  public itemTrackBy(item) {
    return item.productsPicsID;
  }

  ngAfterViewInit() {
    const phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }

  dropListDropped() {
    if (!this.target) {
      return;
    }

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex],
    );

    this.target = null;
    this.source = null;
    this.activeContainer = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.images, this.sourceIndex, this.targetIndex);
    }
  }

  cdkDropListEntered(e: CdkDragEnter) {
    const drag = e.item;
    const drop = e.container;

    if (drop === this.placeholder) {
      return true;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf(
      dropElement.parentElement.children,
      this.source ? phElement : sourceElement,
    );
    const dropIndex = __indexOf(
      dropElement.parentElement.children,
      dropElement,
    );

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = dropElement.clientWidth / 2 + 'px';
      phElement.style.height = dropElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(
      phElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement,
    );

    requestAnimationFrame(() => {
      this.placeholder._dropListRef.enter(
        drag._dragRef,
        drag.element.nativeElement.offsetLeft,
        drag.element.nativeElement.offsetTop,
      );
    });
  }
}
function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}
