import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FINDINGS_TYPE } from 'src/app/components/riva-findings/constants';
import { Finding, FindingsStock } from 'src/app/components/riva-findings/model';
import { FindingsMaterial } from 'src/app/models/findings.model';
import { MaterialCode } from 'src/app/models/material-code';
import { FindingsService } from 'src/app/services/findings.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';

@Component({
  selector: 'bom-findings',
  templateUrl: './bom-findings.component.html',
  styleUrls: ['./bom-findings.component.scss'],
})
export class BomFindingsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() findings: Finding[];
  @Input() selectedFinding: Finding;
  @Input() findingsType: number;
  @Output() onReloadList = new EventEmitter();
  @Output() onSelectFinding = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selectedStock: FindingsStock = {} as FindingsStock;
  stocks: FindingsStock[] = [];
  list = new MatTableDataSource<Finding>([]);
  materialCodes: MaterialCode[] = [];
  displayedColumns = [];
  isOverride = false;
  selectedMaterial: FindingsMaterial;
  materials = [];
  columns: Record<string, string>; // Key and Label (name: 'Name')

  jumpringColumns = {
    size_1: 'Wire Diameter',
    jumpringSize: 'ID',
  };
  postColumns = {
    size_1: 'Length',
    size_2: 'Thickness',
  };
  flyerColumns = {
    size_1: 'Size',
  };
  springColumns = {
    size_1: 'Spring height',
    size_2: 'Wire Thickness',
    size_3: 'Spring Angle',
    size_4: 'Hole Inside Diameter',
  };
  springRingColumns = {
    name: 'Name',
    size_1: 'Size',
  };
  lobsterClawColumns = {
    name: 'Name',
    size_1: 'Size',
  };
  tagColumns = {
    name: 'Name',
    customerName: 'Customer',
    size_1: 'Lengths',
    size_2: 'Width',
    size_3: 'Thickness',
  };

  constructor(
    private findingsService: FindingsService,
    private materialCodeService: MaterialCodeService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.columns = this.getColumns(this.findingsType);
    this.displayedColumns = Object.keys(this.columns);
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject;
    });
  }
  ngOnChanges(): void {
    this.list.data = this.findings.filter(
      (f) => f.findingsTypesID === this.findingsType,
    );
  }
  ngAfterViewInit() {
    this.list.sort = this.sort;
    this.list.paginator = this.paginator;
  }
  getStockBySizeId() {
    if (!this.selectedFinding.findingSizesID) {
      this.selectedStock = {} as FindingsStock;
      return;
    }
    this.findingsService
      .getFindingsStockBySizeId(this.selectedFinding.findingSizesID)
      .subscribe((data) => {
        this.stocks = data ?? [];
        const [stock = {} as FindingsStock] = data ?? [];
        this.selectedStock = this.selectedStock.materialsCodeID
          ? {
              ...(data.find(
                (s) => s.materialsCodeID === this.selectedStock.materialsCodeID,
              ) ?? ({} as FindingsStock)),
            }
          : { ...stock };
      });
  }

  onTableRowClick(selected: Finding) {
    this.onSelectFinding.emit(selected);
  }

  onChangeMaterialCode() {
    const stock = this.stocks.find(
      (s) => s.materialsCodeID === this.selectedStock.materialsCodeID,
    );
    this.selectedStock = stock
      ? { ...stock }
      : ({
          materialsCodeID: this.selectedStock.materialsCodeID,
        } as FindingsStock);
  }

  isOverrideChange() {
    if (!this.isOverride) {
      this.selectedFinding.findingsMaterialsID = null;
    }
  }

  getColumns(typeId) {
    switch (typeId) {
      case FINDINGS_TYPE.JUMPRINGS:
        return this.jumpringColumns;
      case FINDINGS_TYPE.POST:
        return this.postColumns;
      case FINDINGS_TYPE.FLYER:
        return this.flyerColumns;
      case FINDINGS_TYPE.SPRING:
        return this.springColumns;
      case FINDINGS_TYPE.SPRING_RING:
        return this.springRingColumns;
      case FINDINGS_TYPE.LOBSTER_CLAW:
        return this.lobsterClawColumns;
      case FINDINGS_TYPE.TAGS:
        return this.tagColumns;
      default:
        return this.jumpringColumns;
    }
  }
}
