import { PAGE_NAME } from './pages';

export const ENAMEL_FEATURE_KEY = {
  create: 'ENAMEL_CREATE',
  edit: 'ENAMEL_EDIT',
};

export const enamelPermission = {
  [PAGE_NAME.enamel]: {
    SuperUser: {
      [ENAMEL_FEATURE_KEY.create]: 'access',
      [ENAMEL_FEATURE_KEY.edit]: 'access',
    },
    User: {
      [ENAMEL_FEATURE_KEY.create]: 'access',
      [ENAMEL_FEATURE_KEY.edit]: 'access',
    },
    Viewer: {
      [ENAMEL_FEATURE_KEY.create]: 'disabled',
      [ENAMEL_FEATURE_KEY.edit]: 'disabled',
    },
  },
};
