import { PAGE_NAME } from './pages';

export const STONE_OPEN_ORDER_FEATURE_KEY = {
  release: 'STONE_OPEN_ORDER_RELEASE',
};

export const stoneOpenOrderReportPermission = {
  [PAGE_NAME.stoneOpenOrderReport]: {
    SuperUser: {
      [STONE_OPEN_ORDER_FEATURE_KEY.release]: 'access',
    },
    User: {
      [STONE_OPEN_ORDER_FEATURE_KEY.release]: 'access',
    },
    Viewer: {
      [STONE_OPEN_ORDER_FEATURE_KEY.release]: 'disabled',
    },
  },
};
