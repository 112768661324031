import { PAGE_NAME } from './pages';
export const WORK_ORDER_FEATURE_KEY = {
  release: 'WORK_ORDER_RELEASE',
  receive: 'WORK_ORDER_RECEIVE',
  print: 'WORK_ORDER_PRINT',
  notPrinted: 'WORK_ORDER_NOT_PRINTED',
  delete: 'WORK_ORDER_DELETE',
  weightEdit: 'WORK_ORDER_WEIGHT_EDIT'
};

export const workOrderPermission = {
  [PAGE_NAME.workOrder]: {
    SuperUser: {
      [WORK_ORDER_FEATURE_KEY.release]: 'access',
      [WORK_ORDER_FEATURE_KEY.receive]: 'access',
      [WORK_ORDER_FEATURE_KEY.print]: 'access',
      [WORK_ORDER_FEATURE_KEY.notPrinted]: 'access',
      [WORK_ORDER_FEATURE_KEY.delete]: 'access',
      [WORK_ORDER_FEATURE_KEY.weightEdit]: 'access',
    },
    User: {
      [WORK_ORDER_FEATURE_KEY.release]: 'access',
      [WORK_ORDER_FEATURE_KEY.receive]: 'access',
      [WORK_ORDER_FEATURE_KEY.print]: 'access',
      [WORK_ORDER_FEATURE_KEY.notPrinted]: 'hidden',
      [WORK_ORDER_FEATURE_KEY.delete]: 'hidden',
      [WORK_ORDER_FEATURE_KEY.weightEdit]: 'hidden',
    },
    Viewer: {
      [WORK_ORDER_FEATURE_KEY.release]: 'disabled',
      [WORK_ORDER_FEATURE_KEY.receive]: 'disabled',
      [WORK_ORDER_FEATURE_KEY.print]: 'disabled',
      [WORK_ORDER_FEATURE_KEY.notPrinted]: 'hidden',
      [WORK_ORDER_FEATURE_KEY.delete]: 'hidden',
      [WORK_ORDER_FEATURE_KEY.weightEdit]: 'hidden',
    },
  },
};
