<div class="riva-stl-viewer">
  <mat-dialog-content class="mat-typography riva-stl-viewer__modal-container">
    <button class="close-button" mat-dialog-close>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-x"
        width="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>
    <div class="loader" *ngIf="isLoading">
      <div class="text-center">
        <svg
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 0 0"
          xml:space="preserve"
        >
          <path
            fill="#fff"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
        <h3>Loading STL...</h3>
      </div>
    </div>
    <div class="stl-viewer" *ngIf="!isLoading">
      <stl-model-viewer
        [stlModels]="stlUrls"
        [centered]="true"
        [light]="light"
        [material]="material"
        [scene]="scene"
      ></stl-model-viewer>
      <a
        class="riva-button"
        (click)="downloadStl()"
        style="
          margin: 15px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          position: absolute;
          bottom: 5px;
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-cloud-download"
          width="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          style="margin-right: 10px"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"
          />
          <line x1="12" y1="13" x2="12" y2="22" />
          <polyline points="9 19 12 22 15 19" />
        </svg>
        Download STL File
      </a>
    </div>

    <!-- <div class="riva-stl-viewer__download">
      <p>
        STL viewer is not working at the moment. Click Download button to
        download the file.
      </p>
      <a
        class="riva-button"
        href="{{ data[0] }}"
        style="
          margin: 15px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-cloud-download"
          width="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          style="margin-right: 10px"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"
          />
          <line x1="12" y1="13" x2="12" y2="22" />
          <polyline points="9 19 12 22 15 19" />
        </svg>
        Download STL File
      </a>
    </div> -->
  </mat-dialog-content>
</div>
