import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import {
  ProductStone,
  StoneOverride,
} from 'src/app/components/riva-gems/riva-gems.model';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';

interface OverrideValue {
  productSizeId: number;
  qty: number;
  size: string;
  tempId?: number;
}

@Component({
  templateUrl: './stone-override-size-dialog.component.html',
  styleUrls: ['./stone-override-size-dialog.component.scss'],
})
export class StoneOverrideSizeDialogComponent implements OnInit {
  displayColumns = ['size', 'qty'];
  isSaving = false;
  overrideSizes: StoneOverride[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { productId: number; stone: ProductStone },
    public dialogRef: MatDialogRef<StoneOverrideSizeDialogComponent>,
    private productBomService: ProductBomService,
    private productService: ProductService,
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.productBomService.getStoneOverride(
        this.data.stone.productsBOMStonesID,
      ),
      this.productService.getProduct(this.data.productId),
    ]).subscribe(([overrides, product]) => {
      const { productSizes } = product?.responseObject ?? {};
      this.overrideSizes = productSizes?.map((p) => {
        const overrideValue =
          overrides.find((o) => o.productsSizesID === p.productsSizesID) ??
          ({} as StoneOverride);
        return {
          ...overrideValue,
          productsBOMStonesID: this.data.stone.productsBOMStonesID,
          productsSizesID: p.productsSizesID,
          size: p.size,
          qty: overrideValue.qty || '',
        };
      });
    });
  }

  onOverrideSize() {
    const data = this.overrideSizes.reduce(
      (overrides, overrideSize) => {
        if (overrideSize.qty) {
          overrides.insert = [...overrides.insert, overrideSize];
        } else if (overrideSize.productsBOMStonesQTYsID && !overrideSize.qty) {
          overrides.delete = [...overrides.delete, overrideSize];
        }
        return overrides;
      },
      {
        delete: [] as StoneOverride[],
        insert: [] as StoneOverride[],
      },
    );
    if (data.insert.length === 0 && data.delete.length === 0) {
      this.dialogRef.close();
      return;
    }
    this.isSaving = true;
    const insertRequests =
      data.insert.length > 0
        ? [this.productBomService.setStoneOverride(data.insert)]
        : [];
    const deleteRequests = data.delete.map((d) =>
      this.productBomService.deleteStoneOverride(d.productsBOMStonesQTYsID),
    );
    forkJoin([...insertRequests, ...deleteRequests]).subscribe(() => {
      this.isSaving = false;
      this.dialogRef.close(true);
    });
  }
}
