import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/core/authentication/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
})
export class ResetPasswordDialogComponent {
  newPassword: string;
  confirmNewPassword: string;
  hasPasswordError: boolean;
  isSaving: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User = {} as User,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    private userService: UserService,
  ) {}

  get disabledSaveButton() {
    return this.isSaving || !this.newPassword || !this.confirmNewPassword;
  }

  onResetPassword() {
    this.hasPasswordError = false;
    if (this.newPassword !== this.confirmNewPassword) {
      this.hasPasswordError = true;
      return;
    }
    this.isSaving = true;
    if (this.data.usersID > 0) {
      this.data.password = this.newPassword;
      this.userService
        .resetUserPassword(this.data.usersID, this.newPassword)
        .subscribe(() => {
          this.dialogRef.close(true);
          this.isSaving = false;
        });
    }
  }
}
