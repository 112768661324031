import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { AlertService } from 'src/app/custom/_alert';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { DEFAULT_ROUTE_PAGE } from 'src/app/custom/riva-side-nav/menu';
import { Login } from 'src/app/models/login';
import { UserService } from 'src/app/services/user.service';
import { GenericComponent } from '../generic/generic.component';

@Component({
  selector: 'riva-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends GenericComponent implements OnInit {
  public login = new Login();
  public error = { UserName: false, Password: false };
  public returnUrl: string;

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    public authenticationService: AuthenticationService,
    public tokenStorageService: TokenStorageService,
    public userService: UserService,
    public userPermissionService: UserPermissionService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(loadService, alertService);
    if (tokenStorageService.currentUser?.usersID) {
      this.router.navigate(['/main/ordersview']);
    }
  }

  ngOnInit(): void {
    this.loadService.load(false);
    this.loadService.loadContent(false);
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'] || '/main/ordersview';
  }

  loginUser() {
    if (
      !this.checkStringIfEmpty(this.login.userName) &&
      !this.checkStringIfEmpty(this.login.password)
    ) {
      this.loadService.load(true);
      this.error.UserName = false;
      this.error.Password = false;
      let defaultPage = DEFAULT_ROUTE_PAGE;

      this.authenticationService
        .login(this.login)
        .pipe(
          switchMap((data: { accessToken: string; refreshToken: string }) => {
            this.tokenStorageService.saveToken(data.accessToken);
            this.tokenStorageService.saveRefreshToken(data.refreshToken);
            const user: any = jwt_decode(data.accessToken);
            return this.userService.getUser(user.id);
          }),
          switchMap((user) => {
            defaultPage = user.defaultUrl ?? DEFAULT_ROUTE_PAGE;
            this.tokenStorageService.saveUser(user);
            return this.userPermissionService.getCurrentUserPermissions(
              user.usersID,
            );
          }),
        )
        .subscribe(
          () => {
            this.authenticationService.loggedIn.next(true);
            this.loadService.load(false);
            this.loadService.reloadNavigation();
            this.router.navigate([
              this.route.snapshot.queryParams['returnUrl'] || defaultPage,
            ]);
          },
          (error) => {
            this.alertService.error(error.error);
            this.loadService.load(false);
          },
        );
    } else {
      this.error.UserName = this.checkStringIfEmpty(this.login.userName);
      this.error.Password = this.checkStringIfEmpty(this.login.password);
      this.alertService.error(
        'All fields are required, please check for errors.',
      );
    }
  }
}
