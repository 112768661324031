<div class="product-setting-dialog">
  <h3 mat-dialog-title>{{ data.findingsType.findingsTypeName }} Settings</h3>
  <mat-dialog-content class="mat-typography product-setting-dialog__container">
    <mat-form-field class="search-input" appearance="fill" style="width: 100%">
      <mat-label>Work Order Limit</mat-label>
      <input
        matInput
        type="number"
        min="0"
        [(ngModel)]="data.findingsType.workOrderLimit"
      />
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 10px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onSave()"
      [disabled]="isSaving"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
