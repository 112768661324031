<div class="stone-size-dialog">
  <h3 mat-dialog-title>Other Component Size</h3>
  <mat-dialog-content class="mat-typography">
    <div class="size-forms">
      <mat-form-field appearance="fill">
        <mat-label>Dimension 1</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="otherComponentSize.dimension_1"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Dimension 2</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="otherComponentSize.dimension_2"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Dimension 3</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="otherComponentSize.dimension_3"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Dimension 4</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="otherComponentSize.dimension_4"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Dimension 5</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="otherComponentSize.dimension_5"
        />
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        (click)="onSave()"
        [disabled]="!isFormValid || isSaving"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
