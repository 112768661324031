<h2 mat-dialog-title>Generate Work Order</h2>
<mat-dialog-content class="mat-typography">
  <p>Click continue to proceed on generating work orders</p>
</mat-dialog-content>
<mat-dialog-actions
  style="display: grid; grid-template-columns: repeat(2, 1fr)"
>
  <div></div>
  <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px">
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      style="font-size: 12px; padding: 5px; height: 40px"
    >
      Continue
    </button>
  </div>
</mat-dialog-actions>
