import { AlertService } from 'src/app/custom/_alert';
import { BomItemType } from 'src/app/models/enum/bom-item-type.enum';
import { LoadService } from '../../custom/load-overlay/load-overlay.service';

export abstract class GenericComponent {
  searchString = '';
  public editMode: boolean = false;
  autosaveCounter: number = 0;
  showAutosaveCounter: boolean = false;
  isAuthorized: boolean = false; // for read/write page permission

  constructor(
    public loadService: LoadService,
    public alertService: AlertService,
  ) {}

  pagePermissionError() {
    this.alertService.error('Error: Action is unauthorized for this user.');
  }

  checkStringIfEmpty(value: string): boolean {
    return value === undefined || value === '';
  }

  rowColor(itemType: number) {
    if (itemType == BomItemType.Subassemblies) {
      return 'row-item-sa';
    } else if (itemType == BomItemType.Chain) {
      return 'row-item-chain';
    } else if (itemType == BomItemType.Enamel) {
      return 'row-item-enamel';
    } else if (itemType == BomItemType.Findings) {
      return 'row-item-finding';
    } else if (itemType == BomItemType.Gems) {
      return 'row-item-gem';
    } else if (itemType == BomItemType.ManufacturedMats) {
      return 'row-item-mm';
    } else if (itemType == BomItemType.MetalGrains) {
      return 'row-item-mg';
    } else if (itemType == BomItemType.Nonprecious) {
      return 'row-item-np';
    }
  }
}

export class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  }
}
