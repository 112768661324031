<h3 mat-dialog-title>Orphan Work Orders</h3>
<mat-dialog-content class="mat-typography">
  <div class="invoicing-detail-dialog">
    <div style="border: 1px solid #646464">
      <table
        mat-table
        [dataSource]="workOrders"
        matSort
        matSortActive="workOrdersId"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="workOrdersId">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by workOrdersId"
          >
            WKO #
          </th>
          <td mat-cell *matCellDef="let element">
            <button
              class="btn-riva btn-riva-sm"
              (click)="onOpenWorkOrder(element)"
              style="font-size: 14px"
            >
              {{ element.workOrdersId }}
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by sku"
          >
            Item SKU
          </th>
          <td mat-cell *matCellDef="let element">{{ element.sku }}</td>
        </ng-container>
        <ng-container matColumnDef="totalQty">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by totalQty"
          >
            QTY Total
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalQty }}
          </td>
        </ng-container>
        <ng-container matColumnDef="unShippedQty">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by unShippedQty"
          >
            Shipped
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="
                element.unShippedQty > 0 &&
                element.totalQty !== element.unShippedQty
              "
              >{{ element.totalQty - element.unShippedQty }}</span
            >
            <svg
              *ngIf="element.totalQty === element.unShippedQty"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-circle-x"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ff2825"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <path d="M10 10l4 4m0 -4l-4 4" />
            </svg>
            <svg
              *ngIf="element.unShippedQty === 0"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-circle-check"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#00b341"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </td>
        </ng-container>
        <ng-container matColumnDef="unInvoicedQty">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by unInvoicedQty"
          >
            Invoiced
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="
                element.unInvoicedQty > 0 &&
                element.totalQty !== element.unInvoicedQty
              "
              >{{ element.totalQty - element.unInvoicedQty }}</span
            >
            <svg
              *ngIf="element.totalQty === element.unInvoicedQty"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-circle-x"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ff2825"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <path d="M10 10l4 4m0 -4l-4 4" />
            </svg>
            <svg
              *ngIf="element.unInvoicedQty === 0"
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-circle-check"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#00b341"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="9" />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </td>
        </ng-container>
        <ng-container matColumnDef="closedDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by closedDate"
          >
            Close Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.closedDate | date : 'MM/dd/yyyy' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="6">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <button
    class="btn btn-primary"
    style="font-size: 12px; padding: 5px; height: 40px; width: 130px"
    mat-dialog-close
  >
    Close
  </button>
</mat-dialog-actions>
