<div class="riva-reporting-work-order-moved">
  <div class="riva-reporting">
    <div class="selection-section">
      <mat-form-field
        class="search-input"
        appearance="fill"
        style="color: white; width: 100%"
      >
        <mat-label>Search...</mat-label>
        <input
          matInput
          [(ngModel)]="search"
          (ngModelChange)="onFilterChange()"
        />
      </mat-form-field>
      <div class="table-container">
        <table
          mat-table
          matSort
          #employee
          #employeeSort="matSort"
          [dataSource]="accounts"
          matSortActive="fullName"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="fullName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by fullName"
            >
              Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.fullName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="employeeID">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by employeeID"
            >
              Employee ID
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.employeeID || '–' }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsForEmployee; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsForEmployee"
            class="gems-row"
            [ngClass]="{
              'is-selected': row.usersID === selectedUser?.usersID
            }"
            (click)="onSelectUser(row)"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="2">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="details-container">
      <div>
        <div class="work-order-details">
          <div
            class="field"
            style="
              margin-bottom: 16px;
              font-size: 26px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 100%;
            "
          >
            {{ selectedUser?.fullName }}
          </div>
          <div class="details-table-section">
            <div class="table-container">
              <table
                mat-table
                matSort
                #workOrder
                #workOrderSort="matSort"
                [dataSource]="employeeWorkOrderMovements"
                matSortActive="workOrdersId"
                matSortDirection="asc"
              >
                <ng-container matColumnDef="picturePath">
                  <th mat-header-cell *matHeaderCellDef>Pic</th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      style="
                        width: 50px;
                        padding: 5px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                      "
                    >
                      <img
                        style="max-width: 100%; max-height: 100%"
                        [src]="element.picturePath"
                        onerror="this.src='assets/images/no-image.png'"
                      />
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="workOrdersId">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by workOrdersId"
                  >
                    WKO#
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div
                      (click)="onPrintWorkOrder(element.workOrdersId)"
                      style="
                        cursor: pointer;
                        color: #007bff;
                        text-decoration: none;
                      "
                    >
                      {{ element.workOrdersId }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="productName">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by productName"
                  >
                    Product Name
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.productName }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="activityDesc">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by activityDesc"
                  >
                    Routing
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.activityDesc }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="scanOutTimeRaw">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by scanOutTimeRaw"
                  >
                    Scan Out
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.scanOutTime }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="scanInTimeRaw">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by scanInTimeRaw"
                  >
                    Scan In
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.scanInTime }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="differenceInMinutes">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    start="desc"
                    sortActionDescription="Sort by differenceInMinutes"
                  >
                    Total Time
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.differenceInMinutes ?? 0 }}
                    {{ element.differenceInMinutes === 1 ? 'min' : 'mins' }}
                  </td>
                </ng-container>
                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsForWorkOrder; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsForWorkOrder"
                  class="gems-row"
                ></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell text-center" colspan="7">
                    No Available Data
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
