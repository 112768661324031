export enum BomItemType {
  Products = 1,
  Subassemblies = 2,
  Chain = 3,
  Enamel = 4,
  Findings = 5,
  Gems = 6,
  ManufacturedMats = 7,
  MetalGrains = 8,
  Nonprecious = 9,
}
