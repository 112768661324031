import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RivaGems } from '../../riva-gems.model';
import { StoneService } from '../../stone.service';

@Component({
  templateUrl: './stone-updater-dialog.component.html',
  styleUrls: ['./stone-updater-dialog.component.scss'],
})
export class StoneUpdaterDialogComponent implements OnInit {
  isSaving = false;
  stone: RivaGems = {} as RivaGems;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { stone: RivaGems },
    public dialogRef: MatDialogRef<StoneUpdaterDialogComponent>,
    private stoneService: StoneService,
  ) {}

  ngOnInit(): void {
    this.stone = { ...this.data.stone };
  }

  onSave() {
    this.isSaving = true;
    this.stoneService.setStone(this.stone).subscribe(({ stonesID }) => {
      this.stoneService.reloadStones(stonesID);
      this.isSaving = false;
      this.dialogRef.close(true);
    });
  }
}
