import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { interval, Subscription } from 'rxjs';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';

const formatCountdown = (seconds) => {
  var minutes = Math.floor(seconds / 60) ?? 0;
  var remainingSeconds = seconds % 60;
  const minutesLabel = minutes > 0 ? `${minutes} minutes and ` : '';
  return `${minutesLabel}${remainingSeconds} seconds`;
};

@Component({
  selector: 'riva-timeout',
  templateUrl: './riva-timeout.component.html',
  styleUrls: ['./riva-timeout.component.scss'],
  animations: [fadeAnimation],
})
export class RivaTimeoutComponent implements OnInit, OnDestroy {
  idleState = 'NOT_STARTED';
  countdown?: number = null;
  lastPing?: Date = null;
  isRefreshing = false;
  remainingTimeSeconds: number;
  remainingTimeLabel: string;
  private countdownSubscription: Subscription = new Subscription();

  constructor(
    private tokenStorageService: TokenStorageService,
    private authenticationService: AuthenticationService,
    private idle: Idle,
    keepalive: Keepalive,
    cd: ChangeDetectorRef,
  ) {
    // set idle parameters
    idle.setIdle(120 * 60); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(1); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      console.log(`${this.idleState} ${new Date()}`);
      this.countdown = null;
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      console.log(`TIMED_OUT ${new Date()}`);
      this.startCountdown();
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe((seconds) => {
      this.countdown = seconds;
      // this.remainingTime = formatCountdown(seconds);
    });

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => (this.lastPing = new Date())); // do something when it pings
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = null;
    this.lastPing = null;
    this.countdownSubscription.unsubscribe();
    this.resetCountdown();
  }

  ngOnInit(): void {
    this.countdownSubscription.unsubscribe();
    this.resetCountdown();
    this.authenticationService.isLoggedIn.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        if ((this.tokenStorageService.currentUser.tokenTimeout || 0) > 0) {
          this.idle.setIdle(
            this.tokenStorageService.currentUser.tokenTimeout * 60,
          );
        }
        this.reset();
      }
    });
  }

  private startCountdown(): void {
    const source = interval(1000);
    this.countdownSubscription = source.subscribe(() => {
      if (this.remainingTimeSeconds > 0) {
        this.remainingTimeLabel = formatCountdown(this.remainingTimeSeconds);
        this.remainingTimeSeconds--;
      } else {
        this.logout();
      }
    });
  }

  logout() {
    this.idleState = 'NOT_IDLE';
    this.countdown = null;
    this.lastPing = null;
    this.countdownSubscription.unsubscribe();
    this.resetCountdown();
    this.authenticationService.signOut();
  }

  resetCountdown() {
    this.remainingTimeSeconds = 15 * 60;
    this.remainingTimeLabel = formatCountdown(this.remainingTimeSeconds);
  }

  refreshToken() {
    this.countdownSubscription.unsubscribe();
    const token = this.tokenStorageService.getRefreshToken();
    const user = this.tokenStorageService.currentUser;
    if (user.usersID && token) this.isRefreshing = true;
    return this.authenticationService
      .refreshToken(user.usersID, token)
      .subscribe(
        (newToken: { accessToken: string; refreshToken: string }) => {
          this.resetCountdown();
          this.isRefreshing = false;
          this.tokenStorageService.saveToken(newToken.accessToken);
          this.tokenStorageService.saveRefreshToken(newToken.refreshToken);
          this.reset();
        },
        () => {
          this.resetCountdown();
          this.isRefreshing = false;
          this.reset();
          this.authenticationService.signOut();
        },
      );
  }

  ngOnDestroy(): void {
    this.countdownSubscription.unsubscribe();
  }
}
