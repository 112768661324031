<div class="product-image-order-dialog">
  <h3 mat-dialog-title>Product Pictures</h3>
  <mat-dialog-content class="mat-typography">
    <div class="item-container" cdkDropListGroup>
      <div
        cdkDropList
        (cdkDropListDropped)="dropListDropped()"
        (cdkDropListEntered)="cdkDropListEntered($event)"
      ></div>
      <div
        cdkDropList
        *ngFor="let image of images; trackBy: itemTrackBy"
        (cdkDropListDropped)="dropListDropped()"
        (cdkDropListEntered)="cdkDropListEntered($event)"
      >
        <div cdkDrag class="image-box">
          <img
            [src]="image.fileName"
            style="max-width: 150px; max-height: 150px"
          />
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="display: flex; justify-content: center">
    <div style="width: 300px; display: flex; align-items: center">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        (click)="onReorder()"
      >
        Reorder
      </button>
    </div>
  </mat-dialog-actions>
</div>

<swal
  #errorProductImage
  [title]="error.title"
  [text]="error.description"
  [focusConfirm]="false"
  icon="error"
  confirmButtonText="Ok, Got it."
>
</swal>
