<div class="orders-view">
  <div class="orders-view__filter">
    <mat-form-field class="search-input" appearance="fill">
      <mat-label>Search by RIVA PO or Customer PO or CI-PO</mat-label>
      <input
        matInput
        [ngModel]="orderParams.SearchQuery"
        (ngModelChange)="onSearch($event)"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Filter by customer</mat-label>
      <mat-select
        [(ngModel)]="orderParams.CustomerId"
        (ngModelChange)="getOrdersWithPagination()"
      >
        <mat-option [value]="0" selected>ALL</mat-option>
        <mat-option
          *ngFor="let customer of customersList"
          [value]="customer.custIdno"
        >
          {{ customer.companyName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Filter by status</mat-label>
      <mat-select
        [(ngModel)]="orderParams.Status"
        (ngModelChange)="getOrdersWithPagination()"
      >
        <mat-option value="all" selected>All</mat-option>
        <mat-option value="open">Open</mat-option>
        <mat-option value="staged">Staged</mat-option>
        <mat-option value="shipped">Shipped</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Filter by order type</mat-label>
      <mat-select
        [formControl]="orderTypeControl"
        multiple
        (selectionChange)="onSelectionChange()"
      >
        <mat-option [value]="0" selected>All</mat-option>
        <mat-option [value]="1">Standard</mat-option>
        <mat-option [value]="2">Internal</mat-option>
        <mat-option [value]="3">Online</mat-option>
        <mat-option [value]="4">Repair</mat-option>
      </mat-select>
    </mat-form-field>
    <riva-date-range-picker
      [startDate]="orderParams.ReceivedFrom"
      [endDate]="orderParams.ReceivedTo"
      (onChange)="onDateRangeChange($event)"
    ></riva-date-range-picker>
    <mat-form-field appearance="fill">
      <mat-label>Sort by</mat-label>
      <mat-select
        [(ngModel)]="orderParams.SortBy"
        (ngModelChange)="getOrdersWithPagination()"
      >
        <mat-option value="rivapo">RIVA PO</mat-option>
        <mat-option value="customerpo">Customer PO</mat-option>
        <mat-option value="receiveddate">Received Date</mat-option>
        <mat-option value="duedate">Due Date</mat-option>
      </mat-select>
    </mat-form-field>
    <div style="padding-top: 10px">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-sort-ascending"
        width="35"
        height="35"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        *ngIf="orderParams.SortDirection === 'asc'"
        (click)="onSortChange()"
        style="cursor: pointer"
        nzTooltipTitle="Ascending"
        nzTooltipPlacement="bottom"
        nz-tooltip
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="4" y1="6" x2="11" y2="6" />
        <line x1="4" y1="12" x2="11" y2="12" />
        <line x1="4" y1="18" x2="13" y2="18" />
        <polyline points="15 9 18 6 21 9" />
        <line x1="18" y1="6" x2="18" y2="18" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        *ngIf="orderParams.SortDirection === 'desc'"
        class="icon icon-tabler icon-tabler-sort-descending"
        width="35"
        height="35"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        (click)="onSortChange()"
        style="cursor: pointer"
        nzTooltipTitle="Descending"
        nzTooltipPlacement="bottom"
        nz-tooltip
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="4" y1="6" x2="13" y2="6" />
        <line x1="4" y1="12" x2="11" y2="12" />
        <line x1="4" y1="18" x2="11" y2="18" />
        <polyline points="15 15 18 18 21 15" />
        <line x1="18" y1="6" x2="18" y2="18" />
      </svg>
    </div>
    <button
      [matMenuTriggerFor]="presetMenu"
      class="btn riva-button"
      style="
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-settings"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
        />
        <circle cx="12" cy="12" r="3" />
      </svg>
      <span style="padding-left: 5px">Presets</span>
    </button>
    <mat-paginator
      [length]="pagination.totalCount"
      (page)="onPageChanged($event)"
      [pageSizeOptions]="[20]"
      showFirstLastButtons
      hidePageSize
      #paginator
    ></mat-paginator>
    <button
      class="btn riva-button"
      (click)="gotoOrders(0)"
      userPermission
      [elementKey]="purchaseOrderFeatureKey.newOrder"
      style="
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-plus"
        width="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#FFFFFF"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        style="margin-right: 3px"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="12" y1="5" x2="12" y2="19" />
        <line x1="5" y1="12" x2="19" y2="12" />
      </svg>
      <span>Order</span>
    </button>
    <button
      class="btn riva-button"
      userPermission
      [elementKey]="purchaseOrderFeatureKey.newOrder"
      [matMenuTriggerFor]="orderMenu"
      style="
        width: 100%;
        min-width: unset;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 8px;
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-plus"
        width="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#FFFFFF"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        style="margin-right: 3px"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="12" y1="5" x2="12" y2="19" />
        <line x1="5" y1="12" x2="19" y2="12" />
      </svg>
    </button>
  </div>
  <div class="orders-view__container">
    <div [ngClass]="{ hidden: isLoading }">
      <div class="orders-view__items">
        <div
          class="orders-view__item"
          *ngFor="let order of filteredOrderList; let i = index"
          [ngClass]="{
            'order-almost-due': order.isAlmostDue,
            'order-already-due': order.isAlreadyDue,
            'order-staged': order.status?.toLowerCase() === 'staged',
            'order-shipped': order.status?.toLowerCase() === 'shipped'
          }"
        >
          <div class="orders-view__item-customer">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>CUSTOMER</mat-label>
              <input
                matInput
                [(ngModel)]="order.customerName"
                [disabled]="!editMode"
              />
            </mat-form-field>
            <a
              class="riva-button riva-button-sm riva-button-blue"
              [ngClass]="{
                'has-opened-order': order.hasOpenOrder
              }"
              (click)="gotoOrders(order.ordersId)"
              [routerLink]="order.link"
            >
              Details
            </a>
          </div>
          <div class="orders-view__item-detail">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>RIVA PO</mat-label>
              <input
                matInput
                [(ngModel)]="order.pointernal"
                [disabled]="!editMode"
              />
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Customer PO</mat-label>
              <input
                matInput
                [(ngModel)]="order.poexternal"
                [disabled]="!editMode"
              />
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>RECEIVED DATE</mat-label>
              <input
                matInput
                [(ngModel)]="order.receivedDate"
                [disabled]="!editMode"
              />
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Due Date</mat-label>
              <input
                matInput
                [(ngModel)]="order.requiredDate"
                [disabled]="!editMode"
              />
            </mat-form-field>
          </div>
          <div class="orders-view__item-image">
            <ngx-slick-carousel
              class="carousel"
              #slickModal="slick-carousel"
              [config]="slideConfig"
            >
              <div
                ngxSlickItem
                *ngFor="let orderdetail of order.items; let imageIndex = index"
                class="slide orders-view__image-container"
              >
                <span class="order-cipo" *ngIf="orderdetail.cipo">{{
                  orderdetail.cipo
                }}</span>
                <img
                  src="{{
                    orderdetail.productPic || 'assets/images/no-image.png'
                  }}"
                  title="{{ orderdetail.productName }}"
                  alt=""
                />
                <span class="image-name" *ngIf="orderdetail.productName">{{
                  orderdetail.productName
                }}</span>
                <span class="image-item"
                  >{{ imageIndex + 1 }}/{{ order.items.length }}</span
                >
              </div>
            </ngx-slick-carousel>
          </div>
          <div
            class="badge-triangle"
            [ngClass]="{
              internal: order.orderType === 1,
              online: order.orderType === 2,
              repair: order.orderType === 3
            }"
          ></div>

          <div
            *ngIf="
              order.orderType === 1 ||
              order.orderType === 2 ||
              order.orderType === 3
            "
            class="badge-triangle__label"
          >
            <span *ngIf="order.orderType === 1">Internal</span>
            <span *ngIf="order.orderType === 2">Online</span>
            <span *ngIf="order.orderType === 3">Repair</span>
          </div>
        </div>
      </div>
      <div *ngIf="!filteredOrderList.length" style="padding: 10px">
        <div class="orders-view__item-empty">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-file-report"
            width="100"
            height="100"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="17" cy="17" r="4" />
            <path d="M17 13v4h4" />
            <path d="M12 3v4a1 1 0 0 0 1 1h4" />
            <path
              d="M11.5 21h-6.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v2m0 3v4"
            />
          </svg>
          <h4>No Data</h4>
        </div>
      </div>
    </div>
    <div class="loading-orders" [ngClass]="{ 'loading-hidden': !isLoading }">
      <div class="loading-spinner">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
        <h4 style="color: white; margin-top: 120px">Loading Orders...</h4>
      </div>
    </div>
  </div>
</div>
<mat-menu #presetMenu="matMenu">
  <button
    mat-menu-item
    class="presets__menu-item"
    *ngFor="let present of orderPresets"
    (click)="onSetFilter(present.config)"
  >
    {{ present.label }}
  </button>
</mat-menu>

<mat-menu #orderMenu="matMenu">
  <button mat-menu-item (click)="gotoOrders(-1)" style="text-align: left">
    Internal Order
  </button>
  <button mat-menu-item (click)="gotoOrders(-2)" style="text-align: left">
    Repair Order
  </button>
</mat-menu>
