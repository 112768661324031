<h2 class="qr-scanner-title" mat-dialog-title>
  Scan {{ data.title ?? 'Work Order' }} QR Code
</h2>
<mat-dialog-content
  class="mat-typography"
  tabindex="0"
  (keydown)="onScanning($event)"
  style="outline: none; cursor: default"
>
  <div>
    <div class="qr-code-scanner">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-qrcode"
        width="60%"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <rect x="4" y="4" width="6" height="6" rx="1" />
        <line x1="7" y1="17" x2="7" y2="17.01" />
        <rect x="14" y="4" width="6" height="6" rx="1" />
        <line x1="7" y1="7" x2="7" y2="7.01" />
        <rect x="4" y="14" width="6" height="6" rx="1" />
        <line x1="17" y1="7" x2="17" y2="7.01" />
        <line x1="14" y1="14" x2="17" y2="14" />
        <line x1="20" y1="14" x2="20" y2="14.01" />
        <line x1="14" y1="14" x2="14" y2="17" />
        <line x1="14" y1="20" x2="17" y2="20" />
        <line x1="17" y1="17" x2="20" y2="17" />
        <line x1="20" y1="17" x2="20" y2="20" />
      </svg>
      <svg
        version="1.1"
        id="L6"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 100 100"
        xml:space="preserve"
        style="position: absolute"
        *ngIf="isScanning"
      >
        <rect
          fill="none"
          stroke="#fff"
          stroke-width="4"
          x="25"
          y="25"
          width="50"
          height="50"
        >
          <animateTransform
            attributeName="transform"
            dur="0.5s"
            from="0 50 50"
            to="180 50 50"
            type="rotate"
            id="strokeBox"
            attributeType="XML"
            begin="rectBox.end"
          />
        </rect>
        <rect x="27" y="27" fill="#fff" width="46" height="50">
          <animate
            attributeName="height"
            dur="1.3s"
            attributeType="XML"
            from="50"
            to="0"
            id="rectBox"
            fill="freeze"
            begin="0s;strokeBox.end"
          />
        </rect>
      </svg>
    </div>
    <div class="scanning-text" *ngIf="isScanning"><span>Scanning...</span></div>
  </div>
  <button
    class="btn btn-primary"
    style="
      font-size: 12px;
      padding: 5px;
      height: 40px;
      width: 100%;
      margin-top: 25px;
    "
    mat-dialog-close
  >
    Cancel
  </button>
</mat-dialog-content>

<swal
  #errorWorkOrder
  [title]="error.title"
  [text]="error.description"
  [focusConfirm]="false"
  icon="error"
  confirmButtonText="Ok, Got it."
>
</swal>
