import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductService } from 'src/app/services/product.service';

@Component({
  templateUrl: './product-setting-dialog.component.html',
  styleUrls: ['./product-setting-dialog.component.scss'],
})
export class ProductSettingDialogComponent implements OnInit {
  isSaving = false;
  productSetting = {
    workOrderLimit: 0,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { productId: number; editMode: boolean },
    public dialogRef: MatDialogRef<ProductSettingDialogComponent>,
    private productsService: ProductService,
  ) {}

  ngOnInit(): void {
    this.productsService
      .getProductWorkOrderLimit(this.data.productId)
      .subscribe(({ workOrderLimit }) => {
        this.productSetting.workOrderLimit = workOrderLimit ?? 0;
      });
  }

  onSave() {
    this.isSaving = true;
    this.productsService
      .updateProductWorkOrderLimit({
        productId: this.data.productId,
        workOrderLimit: this.productSetting.workOrderLimit,
      })
      .subscribe(() => {
        this.onCloseDialog();
      });
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }
}
