import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { RoutingCodes } from 'src/app/models/product';
import { RoutingCodeService } from 'src/app/services/routing-code.service';
import { ConfirmationService } from '../../riva-confirmation/riva-confirmation.service';
import { OtherComponentRouting } from '../model';
import { OtherComponentsService } from './../../../services/other-components.service';
import { OtherComponentsRoutingCopyDialogComponent } from './other-components-routing-copy-dialog.component';
import { OtherComponentsRoutingDialogComponent } from './other-components-routing-dialog.component';

@Component({
  selector: 'other-components-routing',
  templateUrl: './other-components-routing.component.html',
  styleUrls: ['./other-components-routing.component.scss'],
})
export class OtherComponentsRoutingComponent implements OnChanges {
  @Input() otherComponentsId: number;
  @Input() readonly?: boolean;
  @Output() onRoutingChange = new EventEmitter<OtherComponentRouting[]>();
  @ViewChild('table') table: MatTable<string>;
  routingDisplayColumn: string[] = [
    'draggable',
    'activityCode',
    'department',
    'activityDescription',
    'standardTime',
    'comment',
    'delete',
  ];
  routings: OtherComponentRouting[] = [];
  routingsForDelete: OtherComponentRouting[] = [];
  routingCodes: RoutingCodes[] = [];
  rawRoutingCodes: RoutingCodes[] = [];
  filteredRoutingCodes: Observable<RoutingCodes[]>;
  routingControl = new FormControl();
  selectedRouting: RoutingCodes;
  isSaving = false;
  dragEnabled = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OtherComponentsRoutingDialogComponent>,
    private routingCodeService: RoutingCodeService,
    private otherComponentsService: OtherComponentsService,
    private loadService: LoadService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadRoutings();
  }

  loadRoutings() {
    if (!this.otherComponentsId) {
      this.routings = [];
      this.routingCodes = [];
      this.rawRoutingCodes = [];
      this.onRoutingChange.emit(this.routings);
      return;
    }
    forkJoin([
      this.routingCodeService.getAll(),
      this.otherComponentsService.getOtherComponentsRoutings(
        this.otherComponentsId,
      ),
    ]).subscribe(([routingCodes, chainRoutings]) => {
      this.routingCodes = [...routingCodes];
      this.rawRoutingCodes = [...routingCodes];
      this.routings = chainRoutings;
      this.onRoutingChange.emit(this.routings);
      this.initiateFilters();
    });
  }

  onCheckRoutingCode() {
    this.routingCodes = this.rawRoutingCodes.filter((routingCode) => {
      return !this.routings.some(
        (productRouting) =>
          productRouting.routingCode?.routingCodesId ===
          routingCode.routingCodesId,
      );
    });
  }

  displayFn(item: RoutingCodes): string {
    return item?.activityCode ?? '';
  }

  onSelectRoutingCode(routingCode: RoutingCodes) {
    const routing: OtherComponentRouting = {
      otherComponentsRoutingID: 0,
      otherComponentsID: this.otherComponentsId,
      routingCodesID: routingCode.routingCodesId,
      standardTime: 0,
      comment: '',
      routingCode: routingCode,
      tempRoutingId: +new Date(),
      routingId: 0,
    };
    this.routings = [...this.routings, routing];
    this.selectedRouting = null;
  }

  initiateFilters() {
    this.filteredRoutingCodes = this.routingControl.valueChanges.pipe(
      startWith(this.selectedRouting?.activityCode),
      map((value) => this._filterRoutingCode(value)),
    );
  }

  private _filterRoutingCode(name: string): RoutingCodes[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.routingCodes.filter(
        (option) =>
          option.activityCode.toLowerCase().includes(filterValue) ||
          option.department.toLowerCase().includes(filterValue) ||
          option.activityDesc.toLowerCase().includes(filterValue),
      );
    }
    return this.routingCodes;
  }

  dropTable(event: CdkDragDrop<string[]>) {
    const prevIndex = this.routings.findIndex((d) => d === event.item.data);
    moveItemInArray(this.routings, prevIndex, event.currentIndex);
  }

  onSaveRouting() {
    if (!this.routings?.length) {
      this.dialogRef.close();
      return;
    }
    const data = this.routings.map(({ routingCode, ...p }, index) => ({
      ...p,
      routingOrder: index + 1,
    }));
    this.loadService.isSavingOtherComponentRouting = true;
    this.otherComponentsService.setOtherComponentsRoutings(data).subscribe(
      () => {
        this.loadService.isSavingOtherComponentRouting = false;
        this.dialogRef.close();
      },
      () => {
        this.loadService.isSavingOtherComponentRouting = false;
      },
    );
  }
  onDeleteRouting(routing: OtherComponentRouting) {
    this.confirmationService
      .showConfirmation({
        content: `Continue to delete ${routing.routingCode.activityCode} from Other Component Routing?`,
        title: 'Delete routing?',
        confirmLabel: 'Continue',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (routing.otherComponentsRoutingID) {
            this.routings = this.routings.filter(
              (p) =>
                p.otherComponentsRoutingID !== routing.otherComponentsRoutingID,
            );
            this.otherComponentsService
              .deleteOtherComponentsRoutings(routing.otherComponentsRoutingID)
              .subscribe();
          } else {
            this.routings = this.routings.filter(
              (p) => p.tempRoutingId !== routing.tempRoutingId,
            );
          }
        }
      });
  }
  onCopyRoutingOpen() {
    const dialogRef = this.dialog.open(
      OtherComponentsRoutingCopyDialogComponent,
      {
        disableClose: true,
        maxWidth: '1000px',
        width: '100%',
        autoFocus: false,
      },
    );
    dialogRef.afterClosed().subscribe((newRoutings) => {
      if (!newRoutings) return;
      this.routings = newRoutings.map((r, index) => ({
        ...r,
        otherComponentsRoutingID: 0,
        otherComponentsID: this.otherComponentsId,
        tempRoutingId: +new Date() + index,
      }));
    });
  }
}
