<div class="other-components-container">
  <div class="other-component-table">
    <div>
      <div
        style="display: grid; grid-template-columns: 400px 300px 1fr; gap: 16px"
      >
        <mat-form-field
          class="search-input"
          appearance="fill"
          style="width: 400px"
        >
          <mat-label>Search</mat-label>
          <input
            matInput
            style="color: white"
            [(ngModel)]="search"
            (ngModelChange)="onFilterChange()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Filter by type</mat-label>
          <mat-select
            [(ngModel)]="otherComponentsTypeID"
            (ngModelChange)="onFilterChange()"
          >
            <mat-option [value]="0"> All </mat-option>
            <mat-option
              *ngFor="let type of otherComponentTypes"
              [value]="type.otherComponentsTypeID"
            >
              {{ type.type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div style="display: flex; justify-content: flex-end; gap: 8px">
          <button
            class="btn btn-success"
            [disabled]="!selectedOtherComponent.otherComponentsID"
            style="width: 180px"
            (click)="onOpenGenerateSkuDialog()"
          >
            SKUs
          </button>
          <fieldset userPermission [elementKey]="featureKey.create">
            <button
              class="riva-button"
              style="width: 180px"
              (click)="onCreateNew()"
              [disabled]="editMode"
            >
              + New
            </button>
          </fieldset>
        </div>
      </div>
      <div class="mat-table__container">
        <table
          mat-table
          matSort
          [dataSource]="otherComponents"
          matSortActive="otherComponentsID"
          matSortDirection="desc"
        >
          <ng-container matColumnDef="longName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by longName"
            >
              Long Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.longName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="shortName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by shortName"
            >
              Short Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.shortName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by description"
            >
              Description
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.description }}
            </td>
          </ng-container>
          <ng-container matColumnDef="uom">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by uom"
            >
              UOM
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.uom }}
            </td>
          </ng-container>
          <ng-container matColumnDef="typeDisplay">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by typeDisplay"
            >
              Type
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.typeDisplay }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="riva-chain-row"
            [ngClass]="{
              'is-selected':
                selectedOtherComponent.otherComponentsID ===
                row.otherComponentsID
            }"
            (click)="onRowClick(row)"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="6">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <div>
        <input
          type="file"
          #uploadImage
          (change)="uploadOtherComponentImage(uploadImage.files)"
          style="display: none"
        />
        <button
          class="riva-button"
          (click)="uploadImage.click()"
          [disabled]="!editMode || !selectedOtherComponent.otherComponentsID"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px 6px 0 0;
          "
        >
          <i
            nz-icon
            nzType="file-image"
            nzTheme="outline"
            style="margin-right: 8px"
          ></i>
          Upload Picture
        </button>
      </div>
      <div class="image-wrapper">
        <div class="image-container">
          <img
            [src]="selectedOtherComponent.picPath"
            onerror="this.src='assets/images/no-image.png'"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="other-component-details">
    <div class="other-group-one">
      <div class="editable-fields">
        <div
          style="
            grid-column: 1 / span 2;
            color: white;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 8px;
          "
        >
          <mat-slide-toggle
            [(ngModel)]="selectedOtherComponent.inHouse"
            [disabled]="!editMode"
            >In-House</mat-slide-toggle
          >
        </div>
        <mat-form-field appearance="fill">
          <mat-label>Long name</mat-label>
          <input
            matInput
            [disabled]="!editMode"
            style="color: white"
            [(ngModel)]="selectedOtherComponent.longName"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Short name</mat-label>
          <input
            matInput
            [disabled]="!editMode"
            style="color: white"
            [(ngModel)]="selectedOtherComponent.shortName"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>UOM</mat-label>
          <mat-select
            (ngModelChange)="onFilterChange()"
            [disabled]="!editMode"
            [(ngModel)]="selectedOtherComponent.unitsOfMeasureID"
          >
            <mat-option
              *ngFor="let unitOfMeasure of unitOfMeasures"
              [value]="unitOfMeasure.unitsOfMeasureId"
            >
              {{ unitOfMeasure.uom }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Type</mat-label>
          <mat-select
            [(ngModel)]="selectedOtherComponent.otherComponentsTypeID"
            (ngModelChange)="onFilterChange()"
            [disabled]="!editMode"
          >
            <mat-option
              *ngFor="let type of otherComponentTypes"
              [value]="type.otherComponentsTypeID"
            >
              {{ type.type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="grid-column: 1 / span 2">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            style="height: 42px; color: white"
            [(ngModel)]="selectedOtherComponent.description"
            [disabled]="!editMode"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="size-and-material">
        <div>
          <label style="color: white">Materials</label>
          <div class="size-table">
            <div *ngFor="let item of otherComponentMaterials" class="mat-row">
              <div style="display: flex; align-items: center">
                <div
                  style="
                    width: 25px;
                    height: 18px;
                    border: 1px solid white;
                    background-color: rgb(0, 0, 0);
                    margin-right: 5px;
                  "
                  [ngStyle]="{
                    'background-color': '#' + item.materialCode?.printColor1
                  }"
                ></div>
                {{ item.materialCode?.description }}
              </div>
              <i
                nz-icon
                nzType="delete"
                nzTheme="fill"
                class="icon-delete"
                *ngIf="editMode"
                (click)="onDeleteMaterial(item)"
              ></i>
            </div>
          </div>
          <div
            style="margin-top: 10px"
            *ngIf="editMode && selectedOtherComponent.otherComponentsID > 0"
          >
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Material Code</mat-label>
              <mat-select [(ngModel)]="selectedMaterialCode">
                <mat-option
                  *ngFor="let mats of filteredMaterialCodes"
                  [value]="mats.materialCodeId"
                >
                  {{ mats.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              class="riva-button riva-button-sm"
              style="width: 100%"
              [disabled]="
                !selectedMaterialCode ||
                !selectedOtherComponent.otherComponentsID ||
                isSavingMaterials
              "
              (click)="onSaveMaterial()"
            >
              Add Material
            </button>
          </div>
        </div>
        <div class="stock-fields">
          <label style="color: white">Sizes</label>
          <div class="size-table">
            <div *ngFor="let item of otherComponentSizes">
              <div class="size-row">
                <span>{{ item.label }}</span>
                <i
                  nz-icon
                  nzType="delete"
                  nzTheme="fill"
                  class="icon-delete"
                  *ngIf="editMode"
                  (click)="onDeleteSize(item)"
                ></i>
              </div>
            </div>
          </div>

          <div
            *ngIf="editMode && selectedOtherComponent.otherComponentsID > 0"
            style="margin-top: 15px"
          >
            <button
              class="riva-button riva-button-sm"
              (click)="onSizeDialogOpen()"
              style="width: 100%"
              [disabled]="!selectedOtherComponent.otherComponentsID"
            >
              Add Size
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="chain-action-button">
      <div
        style="width: 100%; justify-self: flex-end"
        [ngClass]="{ hidden: editMode }"
      >
        <fieldset userPermission [elementKey]="featureKey.edit">
          <button type="button" class="riva-button" (click)="onSetEditMode()">
            Edit
          </button>
        </fieldset>
      </div>
      <div [ngClass]="{ hidden: !editMode }">
        <div style="display: grid; grid-template-columns: 1fr 60px">
          <button
            class="btn btn-success"
            (click)="onSave()"
            style="text-transform: none; border-radius: 0.25rem 0 0 0.25rem"
            [disabled]="isSaving || !formValid || isUploadingImage"
          >
            <span>Save</span>
          </button>
          <button
            class="btn btn-danger"
            (click)="onCancelEdit()"
            style="border-radius: 0 0.25rem 0.25rem 0"
          >
            <span nz-icon nzType="close" nzTheme="outline"></span>
          </button>
        </div>
      </div>

      <button
        type="button"
        class="riva-button"
        style="width: 100%; justify-self: flex-end"
        (click)="onRoutingDialogOpen()"
        [disabled]="!selectedOtherComponent.otherComponentsID"
      >
        Routing
      </button>
    </div>
  </div>
</div>
