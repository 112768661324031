import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { SUPPLIER_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/supplier-permission';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { Suppliers } from 'src/app/models/suppliers.model';
import { SupplierService } from 'src/app/services/supplier.service';
import { SupplierDialogComponent } from './supplier-dialog/supplier-dialog.component';

@Component({
  selector: 'app-supplier-page',
  templateUrl: './supplier-page.component.html',
  styleUrls: ['./supplier-page.component.scss'],
})
export class SupplierPageComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  suppliers = new MatTableDataSource<Suppliers>([]);
  search = '';
  featureKey = SUPPLIER_FEATURE_KEY;

  displayedColumns: string[] = [
    'picPath',
    'supplier',
    'company',
    'contact',
    'email',
    'shippingMethod',
    'leadTime',
    'paymentTerms',
    'edit',
  ];

  constructor(
    private supplierService: SupplierService,
    private userPermissionService: UserPermissionService,
    public dialog: MatDialog,
  ) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.supplier);
  }

  ngOnInit(): void {
    this.getSuppliers();
  }

  ngAfterViewInit() {
    this.suppliers.sort = this.sort;
    this.suppliers.paginator = this.paginator;
    this.suppliers.filterPredicate = (data: Suppliers, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.companyName
          ?.toString()
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        data.contactName?.toLowerCase().includes(search?.toLowerCase()) ||
        data.emailAddress?.toString().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  getSuppliers() {
    this.supplierService.getList().subscribe((result) => {
      this.suppliers.data = result;
    });
  }

  onFilterChange() {
    this.suppliers.filter = JSON.stringify({ search: this.search });
  }

  onOpenDialog(supplier = new Suppliers()) {
    const dialogRef = this.dialog.open(SupplierDialogComponent, {
      disableClose: true,
      maxWidth: '600px',
      width: '100%',
      autoFocus: false,
      data: { supplier },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getSuppliers();
      }
    });
  }
}
