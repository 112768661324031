import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialCode } from 'src/app/models/material-code';
import { ProductMaterial } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';

@Component({
  templateUrl: './product-material-dialog.component.html',
  styleUrls: ['./product-material-dialog.component.scss'],
})
export class ProductMaterialDialogComponent implements OnInit {
  isSaving = false;
  productMaterial: Partial<ProductMaterial>;
  materialCodes: MaterialCode[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productId: number;
      editMode: boolean;
      materialCodes: MaterialCode[];
      productMaterial: ProductMaterial | null;
    },
    public dialogRef: MatDialogRef<ProductMaterialDialogComponent>,
    private productService: ProductService,
  ) {}

  ngOnInit(): void {
    if (this.data.productMaterial != null) {
      this.productMaterial = { ...this.data.productMaterial };
    } else {
      this.productMaterial = {
        percentage1: 0,
        percentage2: 0,
      };
    }
    this.materialCodes = this.data.materialCodes.filter((m) => m.multiMetal);
  }

  onSave() {
    this.isSaving = true;
    const data = {
      productsID: this.data.productId,
      materialsCodeID: this.productMaterial.materialsCodeID,
      percentage1: this.productMaterial.percentage1 ?? 0,
      percentage2: this.productMaterial.percentage2 ?? 0,
    };
    this.productService.setProductMaterial(data).subscribe(() => {
      this.onCloseDialog(true);
    });
  }

  onCloseDialog(reload = false) {
    this.dialogRef.close(reload);
  }
  get isFormValid() {
    const total =
      this.productMaterial.percentage1 + this.productMaterial.percentage2;
    return total === 100;
  }
}
