import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { SubItem, SubItemInfo } from 'src/app/models/sub-item.model';

import { HttpEventType } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';
import { ProductSizes } from 'src/app/models/product';
import { UnitOfMeasure } from 'src/app/models/unit-of-measure';
import { SubItemService } from 'src/app/services/sub-item.service';
import { UnitOfMeasureService } from 'src/app/services/unit-of-measurement.service';
import { RivaStlViewerDialogComponent } from '../riva-stl-viewer/riva-stl-viewer-dialog.component';

@Component({
  selector: 'sub-items-dialog',
  templateUrl: './sub-items-dialog.component.html',
  styleUrls: ['./sub-items.component.scss'],
})
export class SubItemsDialogComponent implements OnInit {
  isSaving: boolean = false;
  imageFiles: FileList | null = null;
  uomOptions$: Observable<UnitOfMeasure[]>;
  data: SubItem;
  selectedSubItemIndex: number | null;
  selectedSize: number;
  hasStlFile: boolean = false;
  prodSizeList: string[];
  isUploadingImage = false;
  isUploadingStl = false;
  moldingOptions = [
    {
      value: 1,
      label: 'Casting - Wax Injection',
    },
    {
      value: 2,
      label: 'Casting - Plastic Injection',
    },
    {
      value: 3,
      label: 'Casting - 3D Printed',
    },
    {
      value: 4,
      label: 'Stamping',
    },
    {
      value: 5,
      label: 'CNC Milling',
    },
    {
      value: 6,
      label: 'Tool & Die',
    },
  ];
  @ViewChild('errorSubItem')
  public readonly errorSubItem!: SwalComponent;

  code: string = '';
  isScanning = false;
  error: { title: string; description: string } = {
    title: '',
    description: '',
  };
  stlUploadingProgress = 0;
  stlUploadStatus: 'active' | 'exception' = 'active';

  constructor(
    private tokenStorageService: TokenStorageService,
    private subItemService: SubItemService,
    private uomService: UnitOfMeasureService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<SubItemsDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public props: { subItem: SubItem; sizes: ProductSizes },
  ) {}

  ngOnInit(): void {
    this.data = this.props.subItem;
    this.selectedSubItemIndex = this.data.subItemsInfo?.length ? 0 : null;
    this.selectedSize = this.data.subItemsInfo?.length
      ? this.data.subItemsInfo[0].productsSizeID
      : null;
    this.uomOptions$ = this.uomService
      .getList()
      .pipe(map((data) => data.responseObject));
  }

  onChangeSize() {
    this.selectedSubItemIndex =
      this.data.subItemsInfo?.findIndex(
        (item) => item.productsSizeID === this.selectedSize,
      ) ?? -1;
    if (this.selectedSubItemIndex < 0) {
      const newSubItem: SubItemInfo = {
        subItemsInfoID: 0,
        productsSizeID: this.selectedSize,
        qty: 1,
      };
      this.data.subItemsInfo = [...(this.data.subItemsInfo ?? []), newSubItem];
      this.selectedSubItemIndex = this.data.subItemsInfo.length - 1;
    }
  }

  onUploadChange(files: FileList) {
    this.imageFiles = files;
    this.data.picPath = URL.createObjectURL(files[0]);
  }
  onUploadStlChange(files: FileList) {
    if (this.selectedSubItemIndex < 0) return;
    this.data.subItemsInfo[this.selectedSubItemIndex].stlFile = files;
    this.data.subItemsInfo[this.selectedSubItemIndex].stlPath =
      URL.createObjectURL(files[0]);
    this.data.subItemsInfo[this.selectedSubItemIndex].tempStlPath =
      URL.createObjectURL(files[0]);
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  uploadImage(subItemId) {
    if (!subItemId || !this.imageFiles.length) return;
    const formData = new FormData();

    for (let i = 0; i < this.imageFiles.length; i++) {
      formData.append('file[]', this.imageFiles[i]);
    }

    formData.append('subItemID', subItemId);
    this.isUploadingImage = true;
    this.subItemService.upload(formData).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log(
            'Uploading: ' +
              Math.round((100 * event.loaded) / event.total) +
              '%',
          );
        } else if (event.type === HttpEventType.ResponseHeader) {
          this.data.picPath = event.url;
          if (!this.isUploadingStl) {
            this.dialogRef.close(this.data);
            this.isSaving = false;
          }
          this.isUploadingImage = false;
        }
      },
      ({ error }) => {
        this.error = {
          title: 'Upload Image Failed',
          description: `Error: ${error ?? 'Unknown error'}`,
        };
        this.showErrorMessage();
      },
    );
  }

  uploadStl(subItemInfoID, stlFile) {
    if (!subItemInfoID || !stlFile.length) return;
    this.hasStlFile = true;
    const formData = new FormData();

    for (let i = 0; i < stlFile.length; i++) {
      formData.append('file[]', stlFile[i]);
    }

    formData.append('subItemInfoID', subItemInfoID);
    this.isUploadingStl = true;
    this.subItemService.uploadStl(formData).subscribe(
      (event) => {
        console.log(event.type);
        if (event.type === HttpEventType.UploadProgress) {
          this.stlUploadingProgress = Math.round(
            (100 * event.loaded) / event.total,
          );
          console.log(
            'Uploading: ' +
              Math.round((100 * event.loaded) / event.total) +
              '%',
          );
        } else if (event.type === HttpEventType.ResponseHeader) {
          if (!this.isUploadingImage) {
            this.isUploadingStl && this.dialogRef.close(this.data);
            this.isSaving = false;
          }
          this.isUploadingStl = false;
        }
      },
      () => {
        this.stlUploadStatus = 'exception';
        setTimeout(() => {
          this.isSaving = false;
          this.isUploadingStl = false;
        }, 4000);
      },
    );
  }

  onSave() {
    this.isSaving = true;
    if (!this.data.subItemsID) {
      this.data.createdBy =
        this.tokenStorageService.currentUser?.userName ?? '';
      this.data.createdDate = formatDate(
        new Date(),
        'yyyy-MM-ddThh:mm:ssZZZZZ',
        'en',
      );
    }

    this.data.subItemsInfo =
      this.data.subItemsInfo?.reduce((items, subItem) => {
        if (!subItem.mfgMethod) return items;
        return [
          ...items,
          {
            ...subItem,
            mfgMethodDetails:
              subItem.mfgMethod === 3 ? '' : subItem.mfgMethodDetails,
          },
        ];
      }, []) ?? null;

    this.subItemService
      .setSubItem({ ...this.data, picPath: '' })
      .subscribe((data) => {
        this.data.subItemsID = data.subItemsID;
        this.data.subItemsInfo =
          this.data.subItemsInfo?.map((subItem) => {
            const subItemsInfoID =
              data.subItemsInfo.find(
                (s) => s.productsSizeID === subItem.productsSizeID,
              )?.subItemsInfoID ?? 0;
            return {
              ...subItem,
              subItemsInfoID,
            };
          }) ?? null;
        if (this.imageFiles?.length) {
          this.uploadImage(this.data.subItemsID);
        }
        this.data.subItemsInfo?.forEach((info) => {
          if (info.stlFile?.length) {
            this.uploadStl(info.subItemsInfoID, info.stlFile);
          }
        });

        if (!this.imageFiles?.length && !this.hasStlFile) {
          this.dialogRef.close(this.data);
          this.isSaving = false;
        }
      });
  }

  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString }); //data:application/vnd.ms-pki.stl
    return blob;
  }

  onViewStlFile() {
    this.dialog.open(RivaStlViewerDialogComponent, {
      disableClose: true,
      maxWidth: '1000px',
      width: '100%',
      data: {
        subItemsInfoID:
          this.data.subItemsInfo[this.selectedSubItemIndex].subItemsInfoID,
        stlUrl: this.data.subItemsInfo[this.selectedSubItemIndex].tempStlPath,
        stlFileName: `${this.data.name
          ?.split(' ')
          .join('-')
          .toLowerCase()}-size-${
          this.data.subItemsInfo[this.selectedSubItemIndex].size
        }`,
      },
    });
  }

  showErrorMessage() {
    setTimeout(() => {
      this.errorSubItem.fire();
    }, 100);
  }
}
