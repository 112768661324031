import { HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { OTHER_COMPONENT_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/other-component-permission';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { MaterialCode } from 'src/app/models/material-code';
import { UnitOfMeasure } from 'src/app/models/unit-of-measure';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { OtherComponentsService } from 'src/app/services/other-components.service';
import { UnitOfMeasureService } from 'src/app/services/unit-of-measurement.service';
import { ConfirmationService } from '../riva-confirmation/riva-confirmation.service';
import {
  OtherComponent,
  OtherComponentMaterial,
  OtherComponentSize,
  OtherComponentType,
} from './model';
import { OtherComponentsRoutingDialogComponent } from './other-components-routing/other-components-routing-dialog.component';
import { OtherComponentsSizeDialogComponent } from './other-components-size-dialog/other-components-size-dialog.component';
import { OtherComponentsSkuGeneratorDialogComponent } from './other-components-sku-generator-dialog/other-components-sku-generator-dialog.component';

@Component({
  selector: 'app-other-components',
  templateUrl: './other-components.component.html',
  styleUrls: ['./other-components.component.scss'],
})
export class OtherComponentsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'longName',
    'shortName',
    'description',
    'uom',
    'typeDisplay',
  ];
  otherComponents = new MatTableDataSource<OtherComponent>([]);
  selectedOtherComponent: OtherComponent = {} as OtherComponent;
  otherComponentMaterials: OtherComponentMaterial[] = [];
  otherComponentTypes: OtherComponentType[] = [];
  otherComponentSizes: OtherComponentSize[] = [];
  unitOfMeasures: UnitOfMeasure[] = [];
  filteredMaterialCodes: MaterialCode[] = [];
  materialCodes: MaterialCode[] = [];

  editMode = false;
  isSaving = false;
  isSavingMaterials = false;
  isUploadingImage = false;
  search = '';
  otherComponentsTypeID = 0;
  selectedMaterialCode: number = 0;
  featureKey = OTHER_COMPONENT_FEATURE_KEY;

  constructor(
    private otherComponentsService: OtherComponentsService,
    private uomService: UnitOfMeasureService,
    private materialCodeService: MaterialCodeService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private _confirmationService: ConfirmationService,
    private userPermissionService: UserPermissionService
  ) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.otherComponents);
  }

  ngOnInit(): void {
    this.otherComponentsService.getOtherComponentsTypes().subscribe((data) => {
      this.otherComponentTypes = data;
    });
    this.getOtherComponents();
    this.uomService.getList().subscribe(({ responseObject }) => {
      this.unitOfMeasures = responseObject;
    });
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject.filter((m) => !m.multiMetal);
    });
  }

  ngAfterViewInit() {
    this.otherComponents.sort = this.sort;
    this.otherComponents.filterPredicate = (
      data: OtherComponent,
      filterValue: string,
    ) => {
      const { search = '', otherComponentsTypeID } =
        JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.longName?.toLowerCase().includes(search?.toLowerCase()) ||
        data.shortName?.toLowerCase().includes(search?.toLowerCase()) ||
        data.uom?.toLowerCase().includes(search?.toLowerCase()) ||
        data.typeDisplay?.toLowerCase().includes(search?.toLowerCase());
      return (
        searchFilter &&
        (otherComponentsTypeID === 0 ||
          data.types.otherComponentsTypeID === otherComponentsTypeID)
      );
    };
  }

  getOtherComponents(otherComponentsID = 0) {
    this.otherComponentsService.getOtherComponents().subscribe((data = []) => {
      this.otherComponents.data = data;
      const selected =
        otherComponentsID > 0
          ? data.find((o) => o.otherComponentsID === otherComponentsID)
          : data[data.length - 1];
      this.selectedOtherComponent = {
        ...(selected ?? ({} as OtherComponent)),
      };
      this.getOtherComponentMaterials(
        this.selectedOtherComponent.otherComponentsID,
      );
      this.getOtherComponentSizes(
        this.selectedOtherComponent.otherComponentsID,
      );
    });
  }

  getOtherComponentMaterials(otherComponentsID = 0) {
    this.otherComponentMaterials = [];
    if (otherComponentsID === 0) return;

    this.otherComponentsService
      .getOtherComponentsMaterials(otherComponentsID)
      .subscribe((data) => {
        this.otherComponentMaterials = data;

        this.filteredMaterialCodes = this.materialCodes.filter(
          (m) => !data.some((c) => c.materialsCodeID === m.materialCodeId),
        );
      });
  }

  getOtherComponentSizes(otherComponentsID = 0) {
    this.otherComponentSizes = [];
    if (otherComponentsID === 0) return;
    this.otherComponentsService
      .getOtherComponentsSizes(otherComponentsID)
      .subscribe((data) => {
        this.otherComponentSizes = data;
      });
  }

  onSaveMaterial() {
    this.isSavingMaterials = true;
    this.otherComponentsService
      .setOtherComponentMaterial({
        otherComponentsID: this.selectedOtherComponent.otherComponentsID,
        materialsCodeID: [this.selectedMaterialCode],
      })
      .subscribe(() => {
        this.selectedMaterialCode = 0;
        this.isSavingMaterials = false;
        this.getOtherComponentMaterials(
          this.selectedOtherComponent.otherComponentsID,
        );
      });
  }

  onFilterChange() {
    this.otherComponents.filter = JSON.stringify({
      search: this.search,
      otherComponentsTypeID: this.otherComponentsTypeID,
    });
  }

  onRowClick(row: OtherComponent) {
    this.selectedOtherComponent = { ...row };
    this.getOtherComponentMaterials(
      this.selectedOtherComponent.otherComponentsID,
    );
    this.getOtherComponentSizes(this.selectedOtherComponent.otherComponentsID);
  }

  onCreateNew() {
    this.editMode = true;
    this.selectedOtherComponent = {
      otherComponentsID: 0,
      longName: '',
      shortName: '',
      description: '',
      unitsOfMeasureID: 0,
      picPath: '',
      inHouse: false,
      otherComponentsTypeID: 0,
    };
    this.otherComponentMaterials = [];
    this.otherComponentSizes = [];
  }

  onSave() {
    this.isSaving = true;
    this.otherComponentsService
      .setOtherComponent({
        otherComponentsID: this.selectedOtherComponent.otherComponentsID ?? 0,
        longName: this.selectedOtherComponent.longName,
        shortName: this.selectedOtherComponent.shortName,
        description: this.selectedOtherComponent.description,
        unitsOfMeasureID: this.selectedOtherComponent.unitsOfMeasureID,
        inHouse: this.selectedOtherComponent.inHouse,
        otherComponentsTypeID:
          this.selectedOtherComponent.otherComponentsTypeID,
      })
      .subscribe(() => {
        this.editMode = false;
        this.isSaving = false;
        this.getOtherComponents(this.selectedOtherComponent.otherComponentsID);
      });
  }

  get formValid() {
    return (
      this.selectedOtherComponent.longName &&
      this.selectedOtherComponent.shortName &&
      this.selectedOtherComponent.unitsOfMeasureID &&
      this.selectedOtherComponent.otherComponentsTypeID
    );
  }

  onCancelEdit() {
    this.editMode = false;
    this.selectedOtherComponent = {
      ...(this.otherComponents.data.find(
        (o) =>
          o.otherComponentsID === this.selectedOtherComponent.otherComponentsID,
      ) ??
        this.otherComponents.data[this.otherComponents.data.length - 1] ??
        ({} as OtherComponent)),
    };
    this.getOtherComponentMaterials(
      this.selectedOtherComponent.otherComponentsID,
    );
    this.getOtherComponentSizes(this.selectedOtherComponent.otherComponentsID);
  }

  onSetEditMode() {
    this.editMode = !this.editMode;
  }

  uploadOtherComponentImage(files: FileList) {
    if (files.length === 0) return;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file[]', files[i]);
    }

    formData.append(
      'otherComponentId',
      this.selectedOtherComponent.otherComponentsID.toString(),
    );

    this.isUploadingImage = true;
    this.otherComponentsService.uploadImage(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' + Math.round((100 * event.loaded) / event.total) + '%',
        );
      } else if (event.type === HttpEventType.Response) {
        console.log(event);
        this.selectedOtherComponent.picPath = event.body;
        this.otherComponents.data = this.otherComponents.data.map((c) => ({
          ...c,
          picPath:
            this.selectedOtherComponent.otherComponentsID ===
            c.otherComponentsID
              ? event.body
              : c.picPath,
        }));
        this.toastrService.success('Successfully uploaded.');
        this.isUploadingImage = false;
      }
    });
  }
  onRoutingDialogOpen() {
    this.dialog.open(OtherComponentsRoutingDialogComponent, {
      disableClose: true,
      maxWidth: '1400px',
      width: '100%',
      autoFocus: false,
      data: {
        otherComponentsId: this.selectedOtherComponent.otherComponentsID,
        editMode: this.editMode,
      },
    });
  }
  onSizeDialogOpen() {
    const ref = this.dialog.open(OtherComponentsSizeDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
      autoFocus: false,
      data: {
        otherComponentsId: this.selectedOtherComponent.otherComponentsID,
      },
    });
    ref.afterClosed().subscribe((reload) => {
      reload &&
        this.getOtherComponentSizes(
          this.selectedOtherComponent.otherComponentsID,
        );
    });
  }
  onDeleteMaterial(material: OtherComponentMaterial) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Material',
        content: 'Are you sure you want to delete this material?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.otherComponentsService
            .deleteOtherComponentMaterial(material.otherComponentsMaterialsID)
            .subscribe(() => {
              this.getOtherComponentMaterials(
                this.selectedOtherComponent.otherComponentsID,
              );
            });
        }
      });
  }

  onDeleteSize(size: OtherComponentSize) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Size',
        content: 'Are you sure you want to delete this size?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.otherComponentsService
            .deleteOtherComponentsSize(size.otherComponentsSizesID)
            .subscribe(() => {
              this.getOtherComponentSizes(
                this.selectedOtherComponent.otherComponentsID,
              );
            });
        }
      });
  }

  onOpenGenerateSkuDialog() {
    this.dialog.open(OtherComponentsSkuGeneratorDialogComponent, {
      disableClose: true,
      maxWidth: '800px',
      width: '100%',
      data: {
        otherComponentId: this.selectedOtherComponent.otherComponentsID,
        materials: this.otherComponentMaterials,
        sizes: this.otherComponentSizes,
      },
      autoFocus: false,
    });
  }
}
