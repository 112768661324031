import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubItem } from '../models/sub-item.model';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class SubItemService extends GenericService {
  baseUrl = environment.apiUrl + 'subitems';

  constructor(http: HttpClient) {
    super(http);
  }

  getSubItems(productId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl, {
      headers: this.headers,
      params: { productId },
    });
  }
  getSubItem(id: number): Observable<SubItem> {
    return this.http.get<SubItem>(`${this.baseUrl}/${id}`, {
      headers: this.headers,
    });
  }
  setSubItem(subItem: SubItem): Observable<SubItem> {
    return this.http.post<SubItem>(this.baseUrl, subItem, {
      headers: this.headers,
    });
  }
  deleteSubItem(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/${id}`, {
      headers: this.headers,
    });
  }
  upload(form): Observable<any> {
    return this.http.post(`${this.baseUrl}/upload`, form, {
      headers: this.customHeader,
      responseType: 'text',
      reportProgress: true,
      observe: 'events',
    });
  }
  uploadStl(form): Observable<any> {
    return this.http.post(`${this.baseUrl}/upload-stl`, form, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
    });
  }
  getSubItemsStlImage(subItemsInfoID: number): Observable<string> {
    return this.http.get(`${this.baseUrl}/stl-image`, {
      headers: this.headers,
      responseType: 'text',
      params: { subItemsInfoID },
    });
  }
}
