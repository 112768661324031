import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkOrderTracking } from '../components/work-order-tracking/model';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class ScanService extends GenericService {
  baseUrl = environment.apiUrl + 'scan';

  constructor(http: HttpClient) {
    super(http);
  }
  scanWorkOrder(workOrderId: number): Observable<WorkOrderTracking> {
    return this.http
      .get<WorkOrderTracking>(`${this.baseUrl}/${workOrderId}`, {
        headers: this.headers,
      })
      .pipe(
        map((data) => ({
          ...data,
          totalQty: data.workOrderDetails.reduce(
            (total, w) => total + w.qty,
            0,
          ),
        })),
      );
  }
}
