import { PAGE_NAME } from './pages';
export const PRODUCT_FEATURE_KEY = {
  newProductButton: 'PRODUCT_NEW_BUTTON',
  editProductButton: 'PRODUCT_EDIT_BUTTON',
  deleteNonCustom: 'PRODUCT_DELETE_NON_CUSTOM',
  deleteCustom: 'PRODUCT_DELETE_CUSTOM',
  deleteMaterial: 'PRODUCT_DELETE_MATERIAL',
  deleteSize: 'PRODUCT_DELETE_SIZES',
  viewProductRouting: 'PRODUCT_VIEW_PRODUCT_ROUTING',
  addProductRouting: 'PRODUCT_ADD_PRODUCT_ROUTING',
  editProductRouting: 'PRODUCT_EDIT_PRODUCT_ROUTING',
  deleteProductRouting: 'PRODUCT_DELETE_PRODUCT_ROUTING',
  productSetting: 'PRODUCT_SETTINGS',
};

export const productPermission = {
  [PAGE_NAME.product]: {
    SuperUser: {
      [PRODUCT_FEATURE_KEY.newProductButton]: 'access',
      [PRODUCT_FEATURE_KEY.editProductButton]: 'access',
      [PRODUCT_FEATURE_KEY.deleteNonCustom]: 'access',
      [PRODUCT_FEATURE_KEY.deleteCustom]: 'access',
      [PRODUCT_FEATURE_KEY.deleteMaterial]: 'access',
      [PRODUCT_FEATURE_KEY.deleteSize]: 'access',
      [PRODUCT_FEATURE_KEY.viewProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.addProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.editProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.deleteProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.productSetting]: 'access',
    },
    User: {
      [PRODUCT_FEATURE_KEY.newProductButton]: 'access',
      [PRODUCT_FEATURE_KEY.editProductButton]: 'access',
      [PRODUCT_FEATURE_KEY.deleteNonCustom]: 'access',
      [PRODUCT_FEATURE_KEY.deleteCustom]: 'access',
      [PRODUCT_FEATURE_KEY.deleteMaterial]: 'hidden',
      [PRODUCT_FEATURE_KEY.deleteSize]: 'hidden',
      [PRODUCT_FEATURE_KEY.viewProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.addProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.editProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.deleteProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.productSetting]: 'hidden',
    },
    Viewer: {
      [PRODUCT_FEATURE_KEY.viewProductRouting]: 'access',
      [PRODUCT_FEATURE_KEY.addProductRouting]: 'disabled',
      [PRODUCT_FEATURE_KEY.editProductRouting]: 'hidden',
      [PRODUCT_FEATURE_KEY.deleteProductRouting]: 'hidden',
      [PRODUCT_FEATURE_KEY.productSetting]: 'hidden',
    },
  },
};
