<div class="chain-raw-dialog">
  <h3 mat-dialog-title>New Chain</h3>
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Chain Style</mat-label>
      <mat-select [(ngModel)]="chainRaw.chainStyleID">
        <mat-option
          *ngFor="let style of chainStyles"
          [value]="style.chainStylesID"
        >
          {{ style.styleName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Sku</mat-label>
      <input matInput [(ngModel)]="chainRaw.sku" />
    </mat-form-field>
    <div class="column-two">
      <mat-form-field appearance="fill">
        <mat-label>Link Dim 1 (mm)</mat-label>
        <input
          matInput
          [(ngModel)]="chainRaw.linkDim1"
          (ngModelChange)="onConvertMmToInches('linkDim1', 'linkDim1Inch')"
          type="number"
          max="999.99"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Link Dim 1 (in)</mat-label>
        <input
          matInput
          [(ngModel)]="chainRaw.linkDim1Inch"
          (ngModelChange)="onConvertInchesToMm('linkDim1Inch', 'linkDim1')"
          type="number"
          max="999.99"
        />
      </mat-form-field>
    </div>
    <div class="column-two">
      <mat-form-field appearance="fill">
        <mat-label>Link Dim 2 (mm)</mat-label>
        <input
          matInput
          [(ngModel)]="chainRaw.linkDim2"
          (ngModelChange)="onConvertMmToInches('linkDim2', 'linkDim2Inch')"
          type="number"
          max="999.99"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Link Dim 2 (in)</mat-label>
        <input
          matInput
          [(ngModel)]="chainRaw.linkDim2Inch"
          (ngModelChange)="onConvertInchesToMm('linkDim2Inch', 'linkDim2')"
          type="number"
          max="999.99"
        />
      </mat-form-field>
    </div>
    <div class="column-two">
      <mat-form-field appearance="fill">
        <mat-label>Wire Size (mm)</mat-label>
        <input
          matInput
          [(ngModel)]="chainRaw.wireSize"
          (ngModelChange)="onConvertMmToInches('wireSize', 'wireSizeInch')"
          type="number"
          max="999.99"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Wire Size (in)</mat-label>
        <input
          matInput
          [(ngModel)]="chainRaw.wireSizeInch"
          (ngModelChange)="onConvertInchesToMm('wireSizeInch', 'wireSize')"
          type="number"
          max="999.99"
        />
      </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        [(ngModel)]="chainRaw.description"
        style="height: 42px; color: white"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        [disabled]="isSaving || !isFormValid"
        (click)="onSaveChainRawSize()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
