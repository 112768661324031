export class ProductDetails {
  productID: number;
  matID: number = 0;
  size: number = 0;
  uom: number = 1;
  qtyStock: number = 0;
  wtgr: number = 0;
  whlsprice: number = 0;
  msrp: number = 0;
  heightMm: number = 0;
  widthMm: number = 0;
  lengthMm: number = 0;
}
