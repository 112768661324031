import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WorkOrderUI } from 'src/app/models/work-order';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { WorkOrderPrintDialogComponent } from '../work-order-print/work-order-print-dialog.component';
import { OrphanWorkOrder } from './orphan-work-order';

@Component({
  selector: 'orphan-work-order-dialog',
  templateUrl: './orphan-work-order-dialog.component.html',
  styleUrls: ['./orphan-work-order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrphanWorkOrderDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'workOrdersId',
    'sku',
    'totalQty',
    'unShippedQty',
    'unInvoicedQty',
    'closedDate',
  ];

  workOrders = new MatTableDataSource<OrphanWorkOrder>([]);
  allWorkOrders: WorkOrderUI[];

  constructor(
    public dialogRef: MatDialogRef<OrphanWorkOrderDialogComponent>,
    public dialog: MatDialog,
    private workOrderService: WorkOrderService,
  ) {}

  ngOnInit(): void {
    this.workOrderService.getOrphanedWorkOrder().subscribe((data) => {
      this.workOrders.data = data;
    });
    this.workOrderService.getWorkOrders().subscribe((data) => {
      this.allWorkOrders = data;
    });
  }
  ngAfterViewInit(): void {
    this.workOrders.sort = this.sort;
  }
  onOpenWorkOrder(selectedWorkOrder: OrphanWorkOrder) {
    const workOrder: Partial<WorkOrderUI> = {
      workOrdersId: selectedWorkOrder.workOrdersId,
      dueDate: new Date(selectedWorkOrder.dueDate),
      releasedDate: new Date(selectedWorkOrder.releasedDate),
      printedDate: new Date(selectedWorkOrder.printedDate),
      dateClosed: new Date(selectedWorkOrder.closedDate),
    };
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder,
      },
    });
  }
}
