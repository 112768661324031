<div class="stone-override-size-dialog">
  <h3 mat-dialog-title>Override Stone Size</h3>
  <mat-dialog-content class="mat-typography">
    <table mat-table [dataSource]="overrideSizes">
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let element">{{ element.size }}</td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef>Qty</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.qty"
            [nzMin]="1"
            [nzStep]="1"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="2">No Available Size</td>
      </tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        [disabled]="isSaving || !overrideSizes.length"
        (click)="onOverrideSize()"
      >
        Override
      </button>
    </div>
  </mat-dialog-actions>
</div>
