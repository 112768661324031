import { PAGE_NAME } from './pages';

export const INVOICING_SHIPPING_FEATURE_KEY = {
  create: 'INVOICING_SHIPPING_CREATE',
  edit: 'INVOICING_SHIPPING_EDIT',
  markAsPaid: 'INVOICING_SHIPPING_PAID',
};

export const invoicingShippingPermission = {
  [PAGE_NAME.invoicingAndShipping]: {
    SuperUser: {
      [INVOICING_SHIPPING_FEATURE_KEY.create]: 'access',
      [INVOICING_SHIPPING_FEATURE_KEY.markAsPaid]: 'access',
      [INVOICING_SHIPPING_FEATURE_KEY.edit]: 'access',
    },
    User: {
      [INVOICING_SHIPPING_FEATURE_KEY.create]: 'access',
      [INVOICING_SHIPPING_FEATURE_KEY.markAsPaid]: 'access',
      [INVOICING_SHIPPING_FEATURE_KEY.edit]: 'hidden',
    },
    Viewer: {
      [INVOICING_SHIPPING_FEATURE_KEY.create]: 'disabled',
      [INVOICING_SHIPPING_FEATURE_KEY.edit]: 'hidden',
    },
  },
};
