<div class="product-link-bom-form">
  <div>
    <mat-form-field appearance="fill" style="width: 100%; color: white">
      <mat-label>Product</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="product"
        [formControl]="productControl"
        [matAutocomplete]="auto"
        (ngModelChange)="onProductChange()"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="productChange($event)"
      >
        <mat-option class="riva-select-header riva-select__stick-header">
          <div class="riva-select">
            <div class="p5 riva-select__customer-code">CUST</div>
            <div class="p5 riva-select__name">Product Name</div>
            <div class="p5 riva-select__sku">Master SKU</div>
            <div class="p5 riva-select__description">Description</div>
          </div>
        </mat-option>
        <mat-option class="riva-select-header">
          <div class="riva-select">
            <div class="p5 riva-select__customer-code">CUST</div>
            <div class="p5 riva-select__name">Product Name</div>
            <div class="p5 riva-select__sku">Master SKU</div>
            <div class="p5 riva-select__description">Description</div>
          </div>
        </mat-option>
        <mat-option
          *ngFor="let product of filteredProducts | async"
          [value]="product"
          style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
          [ngStyle]="
            product.isRivaProduct && {
              'background-color': 'rgb(133 124 64)'
            }
          "
        >
          <div class="country-container riva-select">
            <div class="p5 riva-select__customer-code">
              {{ product.customerCodeNavigation?.customerId }}
            </div>
            <div class="p5 riva-select__name">
              <p>
                {{ product.productName }}
              </p>
            </div>
            <div class="p5 riva-select__sku">
              <p>{{ product.sku }}</p>
            </div>
            <div class="p5 riva-select__description">
              <p>{{ product.productDesc }}</p>
            </div>
          </div>
        </mat-option>
        <mat-option *ngIf="!(filteredProducts | async)?.length">
          <div class="text-center riva-select__not-found">Not Found</div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <table mat-table [dataSource]="productBomSizes">
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let element">{{ element.size }}</td>
      </ng-container>
      <ng-container matColumnDef="productsSizesID_Primary">
        <th mat-header-cell *matHeaderCellDef>Primary Size</th>
        <td mat-cell *matCellDef="let element" style="padding-right: 16px">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select primary size"
            [(ngModel)]="element.productsSizesID_Primary"
            style="width: 100%; color: black !important"
          >
            <nz-option nzLabel="All" [nzValue]="0"></nz-option>
            <nz-option
              *ngFor="let size of productSizes"
              [nzLabel]="size.size"
              [nzValue]="size.productsSizesID"
            ></nz-option>
          </nz-select>
        </td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef>Qty</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.qty"
            [nzMin]="1"
            [nzStep]="1"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedSizesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedSizesColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="3">No Available Data</td>
      </tr>
    </table>

    <button
      class="riva-button"
      (click)="onSave()"
      [disabled]="isSaving || !isFormValid"
      style="margin-top: 16px"
    >
      Add
    </button>
  </div>
  <div class="product-bom">
    <h3 style="color: white">Bill of Materials</h3>
    <product-link-bom-table [productId]="productId"></product-link-bom-table>
  </div>
</div>
