import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Confirmation } from './confirmation';

@Component({
  selector: 'riva-confirmation',
  templateUrl: './riva-confirmation.component.html',
})
export class RivaConfirmationComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Confirmation) {}
}
