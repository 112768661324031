import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Suppliers } from 'src/app/models/suppliers.model';
import { SupplierService } from 'src/app/services/supplier.service';
import { Guid } from '../../generic/generic.component';

@Component({
  selector: 'supplier-dialog',
  templateUrl: './supplier-dialog.component.html',
  styleUrls: ['./supplier-dialog.component.scss'],
})
export class SupplierDialogComponent implements OnInit {
  supplier: Suppliers;
  imageFiles: FileList | null = null;
  isSaving: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SupplierDialogComponent>,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private supplierService: SupplierService,
    @Inject(MAT_DIALOG_DATA)
    public props: { supplier: Suppliers },
  ) {}

  ngOnInit(): void {
    this.supplier = { ...this.props.supplier };
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onUploadChange(files: FileList) {
    this.imageFiles = files;
    this.supplier.picPath = URL.createObjectURL(files[0]);
  }

  uploadImage() {
    const formData = new FormData();
    for (var i = 0; i < this.imageFiles.length; i++) {
      formData.append('file[]', this.imageFiles[i]);
    }
    const fileId = Guid.newGuid();
    formData.append('id', fileId);
    this.isSaving = true;
    this.supplierService.uploadTempPhoto(formData).subscribe((response) => {
      if (response.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' +
            Math.round((100 * response.loaded) / response.total) +
            '%',
        );
      } else if (response.type === HttpEventType.Response) {
        this.supplierService
          .moveTemp(this.supplier.suppliersId, fileId)
          .subscribe((upload) => {
            this.supplier.picPath = upload.responseObject;
            this.supplierService.post(this.supplier, '').subscribe((result) => {
              this.isSaving = false;
              this.dialogRef.close(true);
            });
          });
      }
    });
  }

  uploadPicture() {
    const formData = new FormData();
    for (var i = 0; i < this.imageFiles.length; i++) {
      formData.append('file[]', this.imageFiles[i]);
    }

    formData.append('supplierId', this.supplier.suppliersId.toString());
    this.isSaving = true;
    this.supplierService.uploadPicture(formData).subscribe((response) => {
      if (response.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' +
            Math.round((100 * response.loaded) / response.total) +
            '%',
        );
      } else if (response.type === HttpEventType.Response) {
        this.isSaving = false;
        this.dialogRef.close(true);
      }
    });
  }

  onSave() {
    this.isSaving = true;
    this.supplierService.post(this.supplier, '').subscribe((result) => {
      this.supplier.suppliersId = result.suppliersId;
      if (this.imageFiles?.length) {
        this.uploadPicture();
        return;
      }
      this.isSaving = false;
      this.dialogRef.close(true);
    });
  }
}
