import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OtherComponentMaterial, OtherComponentSize } from '../model';

interface SkuDetail {
  materialCodeId: number;
  otherComponentSizeId: number;
}

@Component({
  templateUrl: './other-components-sku-generator-dialog.component.html',
  styleUrls: ['./other-components-sku-generator-dialog.component.scss'],
})
export class OtherComponentsSkuGeneratorDialogComponent implements OnInit {
  skuDetail: SkuDetail = {} as SkuDetail;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      otherComponentId: number;
      materials: OtherComponentMaterial[];
      sizes: OtherComponentSize[];
    },
    public dialogRef: MatDialogRef<OtherComponentsSkuGeneratorDialogComponent>,
  ) {}

  ngOnInit(): void {}

  getGeneratedProductSku() {
    const otherComponentId = String(this.data.otherComponentId ?? 0).padStart(
      6,
      '0',
    );
    const sizeId = String(this.skuDetail.otherComponentSizeId ?? 0).padStart(
      5,
      '0',
    );
    const materialCodeId = String(this.skuDetail.materialCodeId ?? 0).padStart(
      3,
      '0',
    );

    return `OTHERCOMP-${otherComponentId}-${sizeId}-${materialCodeId}`;
  }

  onCopySku() {
    navigator.clipboard.writeText(this.getGeneratedProductSku());
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }
}
