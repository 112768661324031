import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubItemService } from 'src/app/services/sub-item.service';
import * as THREE from 'three';

interface RivaStlViewerDialogComponentProps {
  subItemsInfoID?: number;
  stlUrl?: string;
  stlFileName?: string;
}
@Component({
  templateUrl: './riva-stl-viewer-dialog.component.html',
  styleUrls: ['./riva-stl-viewer-dialog.component.scss'],
})
export class RivaStlViewerDialogComponent implements OnInit {
  stlUrls: string[] = [];
  isLoading = false;
  light: THREE.Light = new THREE.PointLight(0xffffff);
  material: THREE.Material = new THREE.MeshPhongMaterial({
    color: 0xb993c1,
    shininess: 1000,
    specular: 0xffffff,
  });
  scene: THREE.Scene = new THREE.Scene();
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public props: RivaStlViewerDialogComponentProps = {},
    public dialogRef: MatDialogRef<RivaStlViewerDialogComponent>,
    public subItemService: SubItemService,
  ) {}

  ngOnInit(): void {
    this.scene.background = new THREE.Color(0xefeceb);
    if (this.props.stlUrl) {
      this.stlUrls = [this.props.stlUrl];
    } else if (this.props.subItemsInfoID) {
      this.getStlImageUrl(this.props.subItemsInfoID);
    }
  }
  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], {
      type: mimeString,
    });
    return blob;
  }
  getStlImageUrl(id) {
    this.isLoading = true;
    this.subItemService.getSubItemsStlImage(id).subscribe((data) => {
      const stlUrl = URL.createObjectURL(this.dataURItoBlob(data));
      this.stlUrls = [stlUrl];
      this.isLoading = false;
    });
  }
  downloadStl() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = this.stlUrls[0];
    a.download = `${this.props.stlFileName}.stl` || 'riva-stl-file.stl';
    a.click();
  }
}
