export const countDecimalPlaces = (number) => {
  if (typeof number !== 'number') {
    throw new Error('Input is not a valid number');
  }

  const numberString = number.toString();
  if (numberString.indexOf('.') === -1) {
    return 0;
  }

  const decimalPart = numberString.split('.')[1];
  return decimalPart.length;
};
