import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CUSTOMER_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/customer-permission';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { Customers } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerDialogComponent } from './customer-dialog/customer-dialog.component';

@Component({
  selector: 'app-customer-page',
  templateUrl: './customer-page.component.html',
  styleUrls: ['./customer-page.component.scss'],
})
export class CustomerPageComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  customers = new MatTableDataSource<Customers>([]);
  search = '';
  featureKey = CUSTOMER_FEATURE_KEY;

  displayedColumns: string[] = [
    'picPath',
    'customerId',
    'companyName',
    'contactName',
    'emailAddress',
    'leadtimeDays',
    'paymentTerms',
    'edit',
  ];

  constructor(
    private customerService: CustomerService,
    private userPermissionService: UserPermissionService,
    public dialog: MatDialog,
  ) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.customer);
  }

  ngOnInit(): void {
    this.getCustomers();
  }

  ngAfterViewInit() {
    this.customers.sort = this.sort;
    this.customers.paginator = this.paginator;
    this.customers.filterPredicate = (data: Customers, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.companyName
          ?.toString()
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        data.contactName?.toLowerCase().includes(search?.toLowerCase()) ||
        data.emailAddress?.toString().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  getCustomers() {
    this.customerService.getList().subscribe((result) => {
      this.customers.data = result.map((c) => ({
        ...c,
        shippingMethod: c.addresses[0]?.shippingMethod,
      }));
    });
  }

  onFilterChange() {
    this.customers.filter = JSON.stringify({ search: this.search });
  }

  onOpenDialog(customer = new Customers()) {
    const dialogRef = this.dialog.open(CustomerDialogComponent, {
      disableClose: true,
      maxWidth: '900px',
      width: '100%',
      autoFocus: false,
      data: { customer },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getCustomers();
      }
    });
  }
}
