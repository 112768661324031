import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InvoiceItemType } from 'src/app/components/invoicing-shipping/invoicing/models/invoice';
import { WorkOrderPrintDialogComponent } from 'src/app/components/work-order-view/work-order-print/work-order-print-dialog.component';
import { WorkOrderMovementService } from 'src/app/services/work-order-movement.service';
import { ProductLookup } from '../model';
import {
  WorkOrderMovementActivitySummary,
  WorkOrderMovementProductActivity,
} from '../work-order-moved-report';

interface ItemModel {
  id: number;
  name: string;
  type: InvoiceItemType;
}

@Component({
  selector: 'work-order-process-comparison',
  templateUrl: './work-order-process-comparison.component.html',
  styleUrls: ['./work-order-process-comparison.component.scss'],
})
export class WorkOrderProcessComparisonComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'workOrdersId',
    'activityDesc',
    'employeeName',
    'scanInTime',
    'operationResult',
  ];
  productActivity: WorkOrderMovementProductActivity[] = [];
  activitySummary = new MatTableDataSource<WorkOrderMovementActivitySummary>(
    [],
  );
  productLookup: ProductLookup[] = [];
  productLookupControl = new FormControl();
  filteredProductLookup: Observable<ProductLookup[]>;
  selectedProductLookup: ProductLookup = {} as ProductLookup;
  selectedRoutingCodeId: number;
  selectedProduct: { productId: number; productType: number } = {} as {
    productId: number;
    productType: number;
  };
  selectedMedianTime: number = 0;

  constructor(
    public dialog: MatDialog,
    private workOrderMovementService: WorkOrderMovementService,
  ) {}

  ngOnInit(): void {
    this.workOrderMovementService.getProductLookup().subscribe((products) => {
      this.productLookup = products;
      this.filteredProductLookup = this.productLookupControl.valueChanges.pipe(
        startWith(this.selectedProductLookup.productName),
        map((value) => this._filterProduct(value)),
      );
    });
  }

  ngAfterViewInit(): void {
    this.activitySummary.sort = this.sort;
  }

  private _filterProduct(name: string): ProductLookup[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.productLookup.filter((option) =>
        option.productName?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['productName'];
      return this.productLookup.filter((option) =>
        option.productName?.toLowerCase().includes(filterValue),
      );
    }
    return this.productLookup;
  }

  getProductActivity(productsId, type) {
    this.selectedProduct.productId = productsId;
    this.selectedProduct.productType = type;
    this.selectedRoutingCodeId = 0;
    this.workOrderMovementService
      .getWorkOrderMovementProductActivity(productsId, type)
      .subscribe((data) => {
        this.productActivity = data;
      });
  }

  getActivitySummary() {
    this.workOrderMovementService
      .getWorkOrderMovementActivitySummary(
        this.selectedRoutingCodeId,
        this.selectedProduct.productId,
        this.selectedProduct.productType,
      )
      .subscribe((data) => {
        this.activitySummary.data = data;
      });
    this.workOrderMovementService
      .getWorkOrderMovementActivitySummaryMedian(
        this.selectedRoutingCodeId,
        this.selectedProduct.productId,
        this.selectedProduct.productType,
      )
      .subscribe((data) => {
        this.selectedMedianTime = data || 0;
      });
  }
  onSelectProduct(product: string | ProductLookup) {
    if (typeof product === 'string' || (product?.productsId ?? 0) === 0) return;
    this.getProductActivity(product?.productsId, product?.itemType);
  }

  displayValue(item: ProductLookup) {
    return item?.productName ?? '';
  }

  onPrintWorkOrder(workOrderId) {
    if (!workOrderId) return;
    this.dialog.open(WorkOrderPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'work-order-print-dialog-container',
      data: {
        workOrder: {
          printedDate: '',
          releasedDate: '',
          dateClosed: '',
          workOrdersId: workOrderId,
        },
      },
    });
  }
}
