import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/core/authentication/user';
import { UserPermission } from 'src/app/core/user-permission/user-permission';
import { PAGES } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './user-permission-dialog.component.html',
  styleUrls: ['./user-permission-dialog.component.scss'],
})
export class UserPermissionDialogComponent implements OnInit {
  displayedColumns = ['page', 'permission'];
  userPermissions: UserPermission[] = [];
  accessLevels = [
    {
      value: 'SuperUser',
      label: 'Super User',
    },
    {
      value: 'User',
      label: 'User',
    },
    {
      value: 'Viewer',
      label: 'Viewer',
    },
    {
      value: 'Disabled',
      label: 'Disabled',
    },
  ];
  isSaving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User = {} as User,
    public dialogRef: MatDialogRef<UserPermissionDialogComponent>,
    private userPermissionService: UserPermissionService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userPermissionService
      .getUserPermissions(this.data.usersID)
      .subscribe((data) => {
        this.userPermissions = PAGES.map((page) => {
          const permission = data?.[page.pageName];
          return {
            usersPagesPermissionsID: 0,
            usersID: this.data.usersID,
            accessLevel: '',
            ...page,
            ...(permission ?? {}),
          };
        });
      });
  }

  onSavePermission() {
    this.isSaving = true;
    const permissions = this.userPermissions.filter((u) => !!u.accessLevel);
    this.userPermissionService.setUserPermissions(permissions).subscribe(() => {
      this.dialogRef.close();
      this.isSaving = false;
    });
  }
}
