<div class="product-sku-generator">
  <h3 mat-dialog-title>Generate Product SKU</h3>
  <mat-dialog-content class="mat-typography product-sku-generator__container">
    <div>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Material</mat-label>
        <mat-select
          [(ngModel)]="productSkuDetail.materialCodeId"
          (ngModelChange)="getSelectedSku()"
        >
          <mat-option>-- None --</mat-option>
          <mat-option
            *ngFor="let mats of productDetail.materials"
            [value]="mats.materialsCodeID"
            class="material-options"
            style="display: flex; align-items: center"
          >
            <div
              style="
                width: 25px;
                height: 18px;
                border: 1px solid white;
                background-color: rgb(0, 0, 0);
                margin-right: 5px;
              "
              [ngStyle]="{
                'background-color': '#' + mats.material?.printColor1
              }"
            ></div>
            <span>{{ mats.material.description }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Size</mat-label>
        <mat-select
          [(ngModel)]="productSkuDetail.productSizeId"
          (ngModelChange)="getSelectedSku()"
        >
          <mat-option>-- None --</mat-option>
          <mat-option
            *ngFor="let size of productDetail.productSizes"
            [value]="size.productsSizesID"
          >
            {{ size.size }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Enamel Variation</mat-label>
        <mat-select
          [(ngModel)]="productSkuDetail.enamelId"
          (ngModelChange)="getSelectedSku()"
        >
          <mat-option>-- None --</mat-option>
          <mat-option
            *ngFor="let item of productEnamelVariations"
            [value]="item.enamelID"
          >
            <div style="display: flex; align-items: center">
              <div
                style="width: 15px; height: 15px; margin-right: 3px"
                [ngStyle]="{ 'background-color': item.colorHex }"
              ></div>
              {{ item.enamelName }} ({{ item.enamelSku }})
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Stone Variation</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="productStoneVariation"
          (ngModelChange)="getSelectedSku()"
          [formControl]="productStoneVariationControl"
          [matAutocomplete]="stoneVariation"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #stoneVariation="matAutocomplete"
          [displayWith]="displayProductStone"
        >
          <mat-option class="riva-select-header riva-select__stick-header">
            <div class="riva-select">
              <div class="p5 riva-select__type">Type</div>
              <div class="p5 riva-select__color">Color</div>
              <div class="p5 riva-select__size">Size</div>
              <div class="p5 riva-select__size">Clarity</div>
              <div class="p5 riva-select__labGrown">Lab Grown</div>
              <div class="p5 riva-select__labGrown">Traceable</div>
            </div>
          </mat-option>
          <mat-option class="riva-select-header">
            <div class="riva-select">
              <div class="p5 riva-select__type">Type</div>
              <div class="p5 riva-select__color">Color</div>
              <div class="p5 riva-select__size">Size</div>
              <div class="p5 riva-select__size">Clarity</div>
              <div class="p5 riva-select__labGrown">Traceable</div>
            </div>
          </mat-option>
          <mat-option
            [value]="{}"
            style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
          >
            <div class="riva-select">
              <div class="p5 riva-select__type">-- None --</div>
              <div class="p5 riva-select__color">&nbsp;</div>
              <div class="p5 riva-select__size">&nbsp;</div>
              <div class="p5 riva-select__size">&nbsp;</div>
              <div class="p5 riva-select__labGrown">&nbsp;</div>
              <div class="p5 riva-select__labGrown">&nbsp;</div>
            </div>
          </mat-option>
          <mat-option
            *ngFor="let variation of filteredProductStoneVariation | async"
            [value]="variation"
            style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
          >
            <div class="country-container riva-select">
              <div class="p5 riva-select__type">
                {{ variation.stone?.stoneType?.name }}
              </div>
              <div class="p5 riva-select__color">
                {{ variation.stone?.stoneColor?.colorName ?? '-' }}
              </div>
              <div class="p5 riva-select__size">
                {{
                  variation.overrideStoneSize ||
                    (variation.stone?.stoneSize?.displayText ?? '-')
                }}
              </div>
              <div class="p5 riva-select__size">
                {{ variation.stone?.stoneClarity?.name }}
              </div>
              <div class="p5 riva-select__labGrown">
                {{ variation.stone?.labGrown ? 'Yes' : 'No' }}
              </div>
              <div class="p5 riva-select__labGrown">
                {{ variation.stone?.traceable ? 'Yes' : 'No' }}
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Chain</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="productChain"
          (ngModelChange)="getSelectedSku()"
          [formControl]="productChainControl"
          [matAutocomplete]="productChainAutoComplete"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #productChainAutoComplete="matAutocomplete"
          [displayWith]="displayProductChain"
        >
          <mat-option class="riva-select-header riva-select__stick-header">
            <div class="riva-select">
              <div class="p5 riva-select__type">Type</div>
              <div class="p5 riva-select__color">Name</div>
              <div class="p5 riva-select__size">Size/Length</div>
            </div>
          </mat-option>
          <mat-option class="riva-select-header">
            <div class="riva-select">
              <div class="p5 riva-select__type">Type</div>
              <div class="p5 riva-select__color">Name</div>
              <div class="p5 riva-select__size">Size/Length</div>
            </div>
          </mat-option>
          <mat-option
            [value]="{}"
            style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
          >
            <div class="riva-select">
              <div class="p5 riva-select__type">-- None --</div>
              <div class="p5 riva-select__color">&nbsp;</div>
              <div class="p5 riva-select__size">&nbsp;</div>
            </div>
          </mat-option>
          <mat-option
            *ngFor="let chain of filteredProductChains | async"
            [value]="chain"
            style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
          >
            <div class="country-container riva-select">
              <div class="p5 riva-select__type">
                {{ chain.chainType ? 'Finished' : 'Unfinished' }}
              </div>
              <div class="p5 riva-select__color">
                {{ chain.name }}
              </div>
              <div class="p5 riva-select__size">
                {{ chain.size }}
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="generated-sku-container">
      <div class="generated-sku">
        <div
          class="copy-icon"
          (click)="onCopySku()"
          nz-popover
          nzPopoverContent="Copied to Clipboard"
          nzPopoverPlacement="bottomRight"
          nzPopoverTrigger="click"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-copy"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"
            />
            <path
              d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
            />
          </svg>
        </div>
        <p style="line-height: 1.5">{{ getGeneratedProductSku() }}</p>
      </div>
      <div>
        <mat-form-field
          class="search-input"
          appearance="fill"
          style="width: 100%"
          floatLabel="always"
        >
          <mat-label>Customer SKU</mat-label>
          <input
            matInput
            [(ngModel)]="selectedProductSku.customerSKU"
            [placeholder]="placeholderCustomerSku"
          />
        </mat-form-field>
        <button
          class="btn riva-button"
          (click)="saveProductSku()"
          [disabled]="!selectedProductSku.customerSKU"
        >
          <span>Save SKU</span>
        </button>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="display: flex; justify-content: center">
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px; width: 100px"
      mat-dialog-close
    >
      Close
    </button>
  </mat-dialog-actions>
</div>
