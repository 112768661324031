import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'riva-date-range-picker',
  templateUrl: './riva-date-range-picker.component.html',
  styleUrls: ['./riva-date-range-picker.component.scss'],
})
export class RivaDateRangePickerComponent implements OnChanges {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() isSinglePicker: boolean;
  @Input() singlePickerLabel: string = 'Date';
  @Input() startDateLabel: string = 'Start Date';
  @Input() endDateLabel: string = 'End Date';
  @Output() onChange = new EventEmitter();

  start: Date;
  end: Date;
  invalidDate: boolean = false;

  ngOnChanges() {
    this.start = DateTime.fromISO(this.startDate).toJSDate();
    this.end = DateTime.fromISO(this.endDate).toJSDate();
  }

  onDateRangeChange() {
    if (this.isSinglePicker && this.start != null) {
      this.onChange.emit({
        start: DateTime.fromJSDate(this.start).toFormat('yyyy-MM-dd'),
        end: DateTime.fromJSDate(this.start).toFormat('yyyy-MM-dd'),
      });
    } else if (this.isSinglePicker && this.start == null) {
      this.onChange.emit({
        start: null,
        end: null,
      });
    } else if (this.start <= this.end) {
      this.onChange.emit({
        start: DateTime.fromJSDate(this.start).toFormat('yyyy-MM-dd'),
        end: DateTime.fromJSDate(this.end).toFormat('yyyy-MM-dd'),
      });
    }
  }

  get isInvalid() {
    if (this.isSinglePicker) return false;
    return this.start > this.end;
  }
}
