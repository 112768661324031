import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ChainService } from 'src/app/services/chain.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ChainFinish, ChainFinishSize } from '../../riva-chain-finish/models';
import { ProductChainFinished } from '../model';

@Component({
  selector: 'riva-chain-finished-product',
  templateUrl: './riva-chain-finished-product.component.html',
  styleUrls: ['./riva-chain-finished-product.component.scss'],
})
export class RivaChainFinishedProductComponent implements OnInit {
  @Input() productId: number;

  displayedColumns = ['picPath', 'name', 'styleName', 'sku'];
  displayedProductColumns = [
    'picPath',
    'name',
    'styleName',
    'sku',
    'chainFinishedSizesID',
    'action',
  ];
  displayedSizeColumns = ['size'];

  chains = new MatTableDataSource<ChainFinish>([]);
  @ViewChild(MatSort) sort: MatSort;
  productChains: ProductChainFinished[] = [];
  selectedChain: ChainFinish = {} as ChainFinish;
  chainFinishSizes: ChainFinishSize[] = [];
  selectedChainFinishSize: ChainFinishSize = {} as ChainFinishSize;

  isSaving = false;
  showSizeError = false;
  searchText = '';

  constructor(
    private chainService: ChainService,
    private productBomService: ProductBomService,
    private loadService: LoadService,
    private toastrService: ToastrService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.getChains();
    this.getProductChains();
  }

  getChains() {
    this.chainService.getChainFinished().subscribe((data) => {
      this.chains.data = data;
      this.chains.sort = this.sort;
      this.chains.filterPredicate = (
        data: ChainFinish,
        filterValue: string,
      ) => {
        const { search } = JSON.parse(filterValue) ?? {};
        const searchFilter =
          data.name?.toString().toLowerCase().includes(search?.toLowerCase()) ||
          data.styleName
            ?.toString()
            .toLowerCase()
            .includes(search?.toLowerCase()) ||
          data.sku?.toLowerCase().includes(search?.toLowerCase());
        return searchFilter;
      };
    });
  }
  getChainSizes(chainFinishedID) {
    this.selectedChainFinishSize = {} as ChainFinishSize;
    this.chainService
      .getChainFinishedSizes(chainFinishedID)
      .subscribe((data) => {
        this.chainFinishSizes = data;
        this.onCheckChainSizes();
      });
  }

  onSearchChain() {
    this.chains.filter = JSON.stringify({
      search: this.searchText,
    });
  }

  onCheckChainSizes() {
    this.chainFinishSizes = this.chainFinishSizes.reduce((sizes, current) => {
      if (
        this.productChains.some(
          (p) => p.chainFinishedSizesID === current.chainFinishedSizesID,
        )
      )
        return sizes;
      return [...sizes, current];
    }, []);
  }

  getProductChains() {
    this.productBomService
      .getProductChainFinished(this.productId)
      .subscribe((data) => {
        this.productChains = data;
        if (this.selectedChain.chainFinishedID) {
          this.getChainSizes(this.selectedChain.chainFinishedID);
          return;
        }
        this.onCheckChainSizes();
      });
  }

  onSelectChain(chain: ChainFinish) {
    this.selectedChain = { ...chain };
    this.getChainSizes(chain.chainFinishedID);
  }
  onSelectChainFinishSize(size: ChainFinishSize) {
    this.selectedChainFinishSize = { ...size };
  }

  onSaveChainFinish() {
    this.isSaving = true;
    this.productBomService
      .setProductChainFinished({
        productsBOMChainFinishedID: 0,
        productsID: this.productId,
        chainFinishedID: this.selectedChain.chainFinishedID,
        chainFinishedSizesID: this.selectedChainFinishSize.chainFinishedSizesID,
        variation: true,
      })
      .subscribe(() => {
        this.isSaving = false;
        this.selectedChainFinishSize = {} as ChainFinishSize;
        this.getProductChains();
        this.loadService.reloadProductChainFinished();
        this.toastrService.success('Successfully added.', 'Chain Finished');
      });
  }
  onDeleteChain(chain: ProductChainFinished) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Chain',
        content: 'Are you sure you want to delete this chain?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productBomService
          .deleteProductChainFinished(chain.productsBOMChainFinishedID)
          .subscribe(() => {
            this.getProductChains();
            this.loadService.reloadProductChainFinished();
            this.toastrService.success(
              'Chain successfully deleted',
              'Chain Finished',
            );
          });
      });
  }

  get isFormValid() {
    return (
      this.selectedChain.chainFinishedID &&
      this.selectedChainFinishSize.chainFinishedSizesID > 0
    );
  }
}
