<div class="work-order-tracking">
  <div class="qr-code-scanner-container">
    <div>
      <div class="scan-label">Scan Work Order</div>
      <div class="sub-label">Package Label Printing</div>
      <div class="qr-code-scanner">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-qrcode"
          width="70%"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="6" height="6" rx="1" />
          <line x1="7" y1="17" x2="7" y2="17.01" />
          <rect x="14" y="4" width="6" height="6" rx="1" />
          <line x1="7" y1="7" x2="7" y2="7.01" />
          <rect x="4" y="14" width="6" height="6" rx="1" />
          <line x1="17" y1="7" x2="17" y2="7.01" />
          <line x1="14" y1="14" x2="17" y2="14" />
          <line x1="20" y1="14" x2="20" y2="14.01" />
          <line x1="14" y1="14" x2="14" y2="17" />
          <line x1="14" y1="20" x2="17" y2="20" />
          <line x1="17" y1="17" x2="20" y2="17" />
          <line x1="20" y1="17" x2="20" y2="20" />
        </svg>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isScanning">
  <div class="stone-release-scanning-overlay" @fadeAnimation>
    <div class="scanning-message">
      <div class="scanning-content" [ngClass]="{ 'has-error': hasError }">
        <div class="scanning-icon">
          <svg
            *ngIf="!hasError && !successPrinting"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-qrcode"
            width="70"
            height="70"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 17l0 .01" />
            <path
              d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 7l0 .01" />
            <path
              d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M17 7l0 .01" />
            <path d="M14 14l3 0" />
            <path d="M20 14l0 .01" />
            <path d="M14 14l0 3" />
            <path d="M14 20l3 0" />
            <path d="M17 17l3 0" />
            <path d="M20 17l0 3" />
          </svg>

          <svg
            *ngIf="hasError"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-qrcode-off"
            width="70"
            height="70"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ff4500"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M8 4h1a1 1 0 0 1 1 1v1m-.297 3.711a1 1 0 0 1 -.703 .289h-4a1 1 0 0 1 -1 -1v-4c0 -.275 .11 -.524 .29 -.705"
            />
            <path d="M7 17v.01" />
            <path
              d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 7v.01" />
            <path
              d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M17 7v.01" />
            <path d="M20 14v.01" />
            <path d="M14 14v3" />
            <path d="M14 20h3" />
            <path d="M3 3l18 18" />
          </svg>

          <svg
            *ngIf="successPrinting"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-circle-check-filled"
            width="70"
            height="70"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#00b341"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
              stroke-width="0"
              fill="#00b341"
            />
          </svg>
        </div>
        <div>
          <div *ngIf="!hasError && !successPrinting">Scanning</div>
          <div *ngIf="hasError">
            <div class="error-title">Oops! Something Went Wrong</div>
            <p class="error-message">Unable to print labels.</p>
            <button
              class="riva-button"
              style="width: 150px"
              (click)="onCloseErrorMessage()"
            >
              Close
            </button>
          </div>
          <div *ngIf="successPrinting">
            <div style="color: #00b341">
              <span>Successfully moved to printing.</span>
            </div>
          </div>
        </div>
        <div *ngIf="!hasError && !successPrinting">
          <svg
            width="70"
            height="15"
            viewBox="0 0 120 30"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <circle cx="15" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
              <animate
                attributeName="r"
                from="9"
                to="9"
                begin="0s"
                dur="0.8s"
                values="9;15;9"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="0.5"
                to="0.5"
                begin="0s"
                dur="0.8s"
                values=".5;1;.5"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="105" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </div>
      </div>
    </div>
  </div>
</ng-container>
