import { Component, OnInit } from '@angular/core';
import orderBy from 'lodash/orderBy';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { RivaGems } from './riva-gems.model';
import { StoneService } from './stone.service';

@Component({
  selector: 'riva-gems',
  templateUrl: './riva-gems.component.html',
  styleUrls: ['./riva-gems.component.scss'],
})
export class RivaGemsComponent implements OnInit {
  selectedGem: RivaGems = {
    stonesID: 0,
    stoneType: {},
    stoneCut: {},
    stoneClarity: {},
    stoneSize: {},
    supplier: {},
  } as RivaGems;
  editMode = false;
  gems: RivaGems[] = [];

  constructor(private userPermissionService: UserPermissionService, private stoneService: StoneService) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.stone);
  }

  ngOnInit(): void {
    this.getStones();
    this.stoneService.reloadStones = (id) => {
      this.getStones(id);
    };
  }

  onSelectGem(selectedGem) {
    this.selectedGem = { ...selectedGem };
  }
  onSetEditMode(value = !this.editMode) {
    this.editMode = value;
  }

  getStones(id = 0) {
    this.stoneService.getStones().subscribe((stones) => {
      this.gems = orderBy(stones, ['stonesID'], ['desc']);
      if (stones.length > 0) {
        const selected =
          id > 0
            ? this.gems.find((s) => s.stonesID === id)
            : this.gems[0];
          this.selectedGem = { ...selected };
      }
    });
  }
}
