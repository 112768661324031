<nz-layout *ngIf="isLoggedIn$ | async as isLoggedIn; else loggedOut">
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
    <div class="riva-logo-container">
      <img class="riva-logo" src="../../../assets/images/riva-logo.svg" />
    </div>
    <ul class="menu-items" nz-menu nzTheme="dark" nzMode="inline">
      <ng-container *ngFor="let menu of menuWithPermission">
        <li
          *ngIf="menu.subMenu?.length && !menu.hidden"
          nz-submenu
          [nzTitle]="menu.name"
          [nzIcon]="menu.icon"
        >
          <ul>
            <ng-container *ngFor="let subMenu of menu.subMenu">
              <li
                nz-menu-item
                [nzMatchRouter]="subMenu.url"
                *ngIf="!subMenu.hidden"
              >
                <a class="menu-link" [routerLink]="subMenu.url">
                  {{ subMenu.name }}
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
        <li
          nz-menu-item
          *ngIf="!menu.subMenu && !menu.hidden"
          [routerLink]="menu.url"
          [nzMatchRouter]="!!menu.url"
        >
          <i nz-icon [nzType]="menu.icon"></i>
          <span>
            <span *ngIf="!menu.url">{{ menu.name }}</span>
            <a *ngIf="menu.url" class="menu-link" [routerLink]="menu.url">
              {{ menu.name }}
            </a>
          </span>
        </li>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="collapse-icon" [ngClass]="{ 'un-collapsed': !isCollapsed }">
        <i
          class="trigger"
          nz-icon
          [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          (click)="isCollapsed = !isCollapsed"
        ></i>
      </div>
      <div class="header-container">
        <div
          class="menu-metal-market"
          nz-popover
          nzPopoverTitle="London PM Fix"
          [nzPopoverContent]="contentTemplate"
          [nzPopoverTrigger]="popoverTrigger"
          nzPopoverPlacement="bottom"
        >
          <svg
            *ngIf="isLoadingMetalMarket"
            style="position: absolute; top: 20px; right: 2px"
            width="25"
            height="25"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="8.042%"
                y1="0%"
                x2="65.682%"
                y2="23.865%"
                id="a"
              >
                <stop stop-color="#68516d" stop-opacity="0" offset="0%" />
                <stop
                  stop-color="#68516d"
                  stop-opacity=".631"
                  offset="63.146%"
                />
                <stop stop-color="#68516d" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)">
                <path
                  d="M36 18c0-9.94-8.06-18-18-18"
                  id="Oval-2"
                  stroke="url(#a)"
                  stroke-width="2"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="0.9s"
                    repeatCount="indefinite"
                  />
                </path>
                <circle fill="#68516d" cx="36" cy="18" r="1">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="0.9s"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
            </g>
          </svg>

          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 64.000000 64.000000"
            preserveAspectRatio="xMidYMid meet"
            [ngStyle]="{ opacity: isLoadingMetalMarket ? 0.2 : 1 }"
          >
            <g
              transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M20 612 c0 -11 124 -132 135 -132 5 0 19 10 32 22 l24 22 74 -74 75
-74 32 31 33 31 30 -19 c17 -11 44 -18 62 -17 24 2 40 -5 63 -27 37 -35 54
-25 18 11 l-26 26 24 36 c13 19 24 46 24 59 0 56 -56 113 -110 113 -63 0 -117
-65 -106 -130 5 -31 2 -41 -19 -60 l-26 -24 -74 74 -75 74 -28 -27 -28 -27
-59 60 c-56 57 -75 70 -75 52z m547 -36 c36 -31 39 -95 5 -128 -33 -34 -97
-31 -128 5 -33 39 -31 81 5 118 37 36 79 38 118 5z"
              />
              <path
                d="M490 556 c-15 -28 -8 -56 15 -56 8 0 15 -4 15 -10 0 -5 -9 -10 -20
-10 -24 0 -25 -6 -4 -27 14 -14 16 -14 29 3 21 27 18 55 -5 61 -11 3 -20 9
-20 14 0 5 9 9 20 9 24 0 25 6 6 26 -19 19 -20 18 -36 -10z"
              />
              <path
                d="M144 290 c-16 -46 -21 -50 -50 -50 -29 0 -33 -4 -53 -56 -25 -66 -25
-64 -6 -64 19 0 19 -8 0 -53 -8 -20 -15 -39 -15 -42 0 -3 135 -5 300 -5 165 0
300 2 300 5 0 3 -7 22 -15 42 -19 45 -19 53 0 53 19 0 19 -2 -6 64 -20 52 -24
56 -53 56 -29 0 -34 4 -50 50 l-18 50 -158 0 -158 0 -18 -50z m186 25 c0 -3
-7 -21 -14 -40 l-15 -35 -75 0 c-42 0 -76 2 -76 5 0 3 7 21 14 40 l15 35 75 0
c42 0 76 -2 76 -5z m146 -30 c7 -19 14 -37 14 -40 0 -3 -36 -5 -80 -5 -44 0
-80 2 -80 5 0 3 7 21 14 40 14 33 17 35 66 35 49 0 52 -2 66 -35z m-246 -70
c0 -3 -7 -21 -14 -40 l-15 -35 -75 0 c-42 0 -76 2 -76 5 0 3 7 21 14 40 l15
35 75 0 c42 0 76 -2 76 -5z m146 -30 c7 -19 14 -37 14 -40 0 -3 -36 -5 -80 -5
-44 0 -80 2 -80 5 0 3 7 21 14 40 14 33 17 35 66 35 49 0 52 -2 66 -35z m48
13 c-7 -26 -12 -33 -18 -27 -2 2 -7 14 -10 27 -6 18 -4 22 14 22 18 0 20 -4
14 -22z m152 -13 c7 -19 14 -37 14 -40 0 -3 -36 -5 -80 -5 -44 0 -80 2 -80 5
0 3 7 21 14 40 14 33 17 35 66 35 49 0 52 -2 66 -35z m-346 -70 c0 -3 -7 -21
-14 -40 l-15 -35 -75 0 c-42 0 -76 2 -76 5 0 3 7 21 14 40 l15 35 75 0 c42 0
76 -2 76 -5z m146 -30 c7 -19 14 -37 14 -40 0 -3 -36 -5 -80 -5 -44 0 -80 2
-80 5 0 3 7 21 14 40 14 33 17 35 66 35 49 0 52 -2 66 -35z m48 13 c-7 -26
-12 -33 -18 -27 -2 2 -7 14 -10 27 -6 18 -4 22 14 22 18 0 20 -4 14 -22z m152
-13 c7 -19 14 -37 14 -40 0 -3 -36 -5 -80 -5 -44 0 -80 2 -80 5 0 3 7 21 14
40 14 33 17 35 66 35 49 0 52 -2 66 -35z"
              />
            </g>
          </svg>
        </div>
        <div class="user" *ngIf="tokenStorageService.currentUser.userName">
          <riva-text-avatar
            [text]="tokenStorageService.currentUser.userName"
          ></riva-text-avatar>
          <span class="user__name">{{
            tokenStorageService.currentUser.userName
          }}</span>
        </div>
        <button
          class="antd-logout-button"
          nz-button
          nzType="primary"
          (click)="logout()"
        >
          <i nz-icon nzType="logout"></i>
          <span class="logout">Logout</span>
        </button>
      </div>
    </nz-header>
    <nz-content>
      <riva-load-content-overlay></riva-load-content-overlay>
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</nz-layout>
<riva-timeout></riva-timeout>
<ng-template #loggedOut>
  <div class="content-panel">
    <riva-login></riva-login>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <div class="metal-market-details">
    <div class="metal-market-details__row">
      <div>Date</div>
      <div>{{ metalMarket.marketDate }}</div>
    </div>
    <div class="metal-market-details__row">
      <div>Silver</div>
      <div>{{ metalMarket.ldpmSilver | currency }}</div>
    </div>
    <div class="metal-market-details__row">
      <div>Gold</div>
      <div>{{ metalMarket.ldpmGold | currency }}</div>
    </div>
    <div class="metal-market-details__row">
      <div>Platinum</div>
      <div>{{ metalMarket.ldpmPlatinum | currency }}</div>
    </div>
    <div class="metal-market-details__row">
      <div>Palladium</div>
      <div>{{ metalMarket.ldpmPalladium | currency }}</div>
    </div>
  </div>
</ng-template>
