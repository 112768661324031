<div class="riva-reporting-work-order-moved">
  <div class="riva-reporting">
    <div class="selection-section">
      <div class="table-container">
        <table
          mat-table
          matSort
          [dataSource]="workOrderMovedItems"
          matSortActive="workOrdersId"
          matSortDirection="desc"
        >
          <ng-container matColumnDef="picturePath">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by picturePath"
            >
              Pic
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                style="
                  width: 80px;
                  padding: 5px;
                  height: 80px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                "
              >
                <img
                  style="max-width: 100%; max-height: 100%"
                  [src]="element.picturePath"
                  onerror="this.src='assets/images/no-image.png'"
                />
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="workOrdersId">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by workOrdersId"
            >
              WKO#
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.workOrdersId }}
            </td>
          </ng-container>
          <ng-container matColumnDef="productName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by productName"
            >
              Product Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.productName }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="gems-row"
            [ngClass]="{
              'is-selected': row.workOrdersId === selectedItem.workOrdersId
            }"
            (click)="onSelectItem(row)"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="3">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="details-container">
      <div *ngIf="workOrderTracking != null">
        <div>
          <div
            *ngIf="
              !workOrderTracking?.primaryMaterial ||
              !workOrderTracking?.secondaryMaterial
            "
          >
            <div
              class="material"
              [ngStyle]="{
                'background-color':
                  '#' + workOrderTracking?.material?.printColor1
              }"
            >
              {{ workOrderTracking?.material?.description }}
            </div>
          </div>
          <div
            *ngIf="
              workOrderTracking?.primaryMaterial &&
              workOrderTracking?.secondaryMaterial
            "
          >
            <div class="multi-material">
              <div
                class="material"
                [ngStyle]="{
                  'background-color':
                    '#' + workOrderTracking?.primaryMaterial?.printColor1
                }"
              >
                {{ workOrderTracking?.primaryMaterial?.code }}
              </div>
              <div
                class="material"
                [ngStyle]="{
                  'background-color':
                    '#' + workOrderTracking?.secondaryMaterial?.printColor1
                }"
              >
                {{ workOrderTracking?.secondaryMaterial?.code }}
              </div>
            </div>
          </div>
        </div>
        <div class="work-order-details">
          <div>
            <div class="image-container">
              <img
                *ngIf="selectedItem.picturePath"
                class="item-image"
                [src]="selectedItem.picturePath"
                onerror="this.src='assets/images/no-image.png'"
              />
              <img
                *ngIf="!selectedItem.picturePath"
                class="item-image"
                src="assets/images/no-image.png"
              />
            </div>
            <div
              (click)="onPrintWorkOrder()"
              class="field"
              style="
                margin-bottom: 16px;
                font-size: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #007bff;
                text-decoration: none;
                background-color: transparent;
              "
            >
              WKO# {{ selectedItem.workOrdersId }}
            </div>
            <div>
              <div class="wko-detail">
                <div class="label">Total QTY</div>
                <div class="value">
                  {{ workOrderTracking?.totalQty ?? '-' }}
                </div>
              </div>
              <div class="wko-detail">
                <div class="label">SKU</div>
                <div class="value">
                  {{ workOrderTracking?.product?.sku ?? '-' }}
                </div>
              </div>
              <div class="wko-detail">
                <div class="label">{{ poLabel }}</div>
                <div class="value">{{ poValue }}</div>
              </div>
              <div class="wko-detail">
                <div class="label">Due Date</div>
                <div class="value">
                  <span
                    *ngIf="workOrderTracking?.purchaseOrder?.requiredDate"
                    >{{
                      workOrderTracking?.purchaseOrder?.requiredDate
                        | date : 'MM/dd/yyyy'
                    }}</span
                  >
                  <span *ngIf="!workOrderTracking?.purchaseOrder?.requiredDate"
                    >-</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="field"
              style="
                margin-bottom: 16px;
                font-size: 26px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              "
            >
              {{ selectedItem.productName }}
            </div>
            <div>
              <div class="movement-history">
                <h2>Movement History</h2>
                <table>
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Routing</th>
                      <th>Scan Out</th>
                      <th>Scan In</th>
                      <th>Total Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of workOrderMovementHistory">
                      <th>{{ item.userName }}</th>
                      <td>
                        {{ item.routingCode.activityDesc }}
                      </td>
                      <td>{{ item.scanOutTime }}</td>
                      <td>{{ item.scanInTime }}</td>
                      <td>
                        {{ item.differenceInMinutes ?? 0 }}
                        {{ item.differenceInMinutes === 1 ? 'min' : 'mins' }}
                      </td>
                    </tr>
                    <tr *ngIf="workOrderMovementHistory.length === 0">
                      <td colspan="5" style="text-align: center">
                        No Movement Available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty-selection" *ngIf="workOrderTracking == null">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            width="300"
            height="300"
            viewBox="0 0 647.63626 632.17383"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#f2f2f2"
            />
            <path
              d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#ffffff"
            />
            <path
              d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#b9b9b9"
            />
            <circle cx="190.15351" cy="24.95465" r="20" fill="#b9b9b9" />
            <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff" />
            <path
              d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#e6e6e6"
            />
            <path
              d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#ffffff"
            />
            <path
              d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
              transform="translate(-276.18187 -133.91309)"
              fill="#b9b9b9"
            />
            <circle cx="433.63626" cy="105.17383" r="20" fill="#b9b9b9" />
            <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff" />
          </svg>
          <div class="no-data">No Data Available</div>
        </div>
      </div>
    </div>
  </div>
</div>
