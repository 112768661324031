import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { AlertService } from 'src/app/custom/_alert';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { DEFAULT_ROUTE_PAGE } from 'src/app/custom/riva-side-nav/menu';
import { UserService } from 'src/app/services/user.service';
import { GenericComponent } from '../generic/generic.component';
import { User } from './../../core/authentication/user';
import { AccountsTableDialogComponent } from './accounts-table-dialog/accounts-table-dialog.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { UserPermissionNavigationDialogComponent } from './user-permission-navigation-dialog/user-permission-navigation-dialog.component';

@Component({
  templateUrl: './accounts-table.component.html',
  styleUrls: ['./accounts-table.component.scss'],
})
export class AccountsTableComponent
  extends GenericComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  accounts = new MatTableDataSource<User>([]);
  search = '';
  selectedUserId: number | null = null;
  user: User;

  displayedColumns: string[] = [
    'fullName',
    'employeeID',
    'dateCreated',
    'lastLogin',
    'actions',
  ];

  constructor(
    loadService: LoadService,
    alertService: AlertService,
    private userService: UserService,
    private userPermissionService: UserPermissionService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {
    super(loadService, alertService);
    this.loadService.load(false);
    this.userPermissionService.checkPagePermission(PAGE_NAME.account);
  }

  ngOnInit(): void {
    this.getAccounts();
  }

  ngAfterViewInit() {
    this.accounts.sort = this.sort;
    this.accounts.paginator = this.paginator;
    this.accounts.filterPredicate = (data: User, filterValue: string) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.fullName
          ?.toString()
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        data.userName?.toLowerCase().includes(search?.toLowerCase());
      return searchFilter;
    };
  }

  getAccounts() {
    this.userService.getUsers().subscribe(
      (data = []) => {
        this.accounts.data = data.map((account) => ({
          ...account,
          fullName: this.getFullName(account),
          noLoginUser: !account.userName,
          defaultUrl: account.defaultUrl ?? DEFAULT_ROUTE_PAGE,
        }));
      },
      (err) => {
        this._snackBar.open(
          'Error encountered while retrieving the accounts',
          'Dismiss',
          {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          },
        );
      },
    );
  }

  getFullName(account: User) {
    const name = [];
    account.firstName && name.push(account.firstName);
    account.lastName && name.push(account.lastName);
    if (!name.length) {
      name.push(account.userName);
    }
    return name.join(' ');
  }

  onFilterChange() {
    this.accounts.filter = JSON.stringify({ search: this.search });
  }
  onSelectAccount(loginId, user) {
    this.selectedUserId = loginId;
    this.user = user;
  }
  resetPassword() {
    const selectedAccount =
      this.accounts.data.find(
        (account) => account.usersID === this.selectedUserId,
      ) ?? {};

    this.dialog.open(ResetPasswordDialogComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      data: { ...selectedAccount },
    });
  }
  setPermissions() {
    const selectedAccount =
      this.accounts.data.find(
        (account) => account.usersID === this.selectedUserId,
      ) ?? {};

    const dialogRef = this.dialog.open(UserPermissionNavigationDialogComponent, {
      disableClose: true,
      maxWidth: '650px',
      width: '100%',
      data: { ...selectedAccount },
    });
    dialogRef.afterClosed().subscribe((success) => {
      if (success) {
        this.getAccounts();
      }
    });
  }
  showAccountDialog(isNew = true) {
    let selectedAccount = {};
    if (!isNew) {
      selectedAccount =
        this.accounts.data.find(
          (account) => account.usersID === this.selectedUserId,
        ) ?? {};
    }

    const dialogRef = this.dialog.open(AccountsTableDialogComponent, {
      disableClose: true,
      maxWidth: '450px',
      width: '100%',
      data: { ...selectedAccount },
    });
    dialogRef.afterClosed().subscribe((success) => {
      if (success) {
        this.getAccounts();
      }
    });
  }
}
