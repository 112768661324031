import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BillOfMaterialSubItem } from 'src/app/models/bill-material-sub-item';
import { ProductService } from 'src/app/services/product.service';
import { BillOfMaterialSubItemConfirmationComponent } from './bill-of-material-sub-item-confirmation.component';
import { BillOfMaterialSubItemDialogComponent } from './bill-of-material-sub-item-dialog..component';

@Component({
  selector: 'app-bill-of-material-sub-item',
  templateUrl: './bill-of-material-sub-item.component.html',
  styleUrls: ['./bill-of-material-sub-item.component.scss'],
})
export class BillOfMaterialSubItemComponent implements OnChanges {
  @Input() productId: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  subItems = new MatTableDataSource<BillOfMaterialSubItem>([]);
  search = '';

  displayedColumns: string[] = [
    'picPath',
    'subItemName',
    'subItemDescription',
    'notes',
    'qty',
    'edit',
    'delete',
  ];

  constructor(
    private _productService: ProductService,
    public dialog: MatDialog,
  ) {}

  ngAfterViewInit() {
    this.subItems.sort = this.sort;
    this.subItems.paginator = this.paginator;
    this.loadBillOfMaterialItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadBillOfMaterialItems();
  }

  loadBillOfMaterialItems() {
    if (!this.productId) return;
    this._productService
      .getProductBillOfMaterial(this.productId)
      .subscribe((data) => {
        this.subItems.data = data;
      });
  }

  onDialogOpen(subItem?: BillOfMaterialSubItem) {
    const dialogRef = this.dialog.open(BillOfMaterialSubItemDialogComponent, {
      disableClose: true,
      maxWidth: '450px',
      width: '100%',
      autoFocus: false,
      data: { ...subItem, productId: this.productId },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.loadBillOfMaterialItems();
      }
    });
  }

  onDelete(id: number) {
    const dialogRef = this.dialog.open(
      BillOfMaterialSubItemConfirmationComponent,
      {
        disableClose: true,
        maxWidth: '350px',
        width: '100%',
      },
    );
    dialogRef.afterClosed().subscribe((isDelete: boolean) => {
      if (isDelete) {
        this._productService.deleteProductBillOfMaterial(id).subscribe(() => {
          this.loadBillOfMaterialItems();
        });
      }
    });
  }
}
