<div class="finish-chain-dialog">
  <h3 mat-dialog-title>New Finish Chain</h3>
  <mat-dialog-content class="mat-typography">
    <div class="finish-chain-dialog__container">
      <div>
        <div>
          <input
            type="file"
            #uploadImage
            (change)="onUploadChange(uploadImage.files)"
            style="display: none"
          />
          <button
            class="riva-button"
            (click)="uploadImage.click()"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px 6px 0 0;
            "
          >
            <i
              nz-icon
              nzType="file-image"
              nzTheme="outline"
              style="margin-right: 8px"
            ></i>
            Upload Picture
          </button>
        </div>
        <div class="image-wrapper">
          <div class="image-container">
            <img
              [src]="sanitize(chainFinish.picPath)"
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
        </div>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Raw Chain</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="chainRaw"
            [formControl]="chainRawControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="displayFn"
          >
            <mat-option class="riva-select-header riva-select__stick-header">
              <div class="riva-select">
                <div class="p5 riva-select__name">SKU</div>
                <div class="p5 riva-select__style-name">Style Name</div>
              </div>
            </mat-option>
            <mat-option class="riva-select-header">
              <div class="riva-select">
                <div class="p5 riva-select__name">SKU</div>
                <div class="p5 riva-select__style-name">Style Name</div>
              </div>
            </mat-option>
            <mat-option
              *ngFor="let chainRaw of filteredChainRaws | async"
              [value]="chainRaw"
              style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
            >
              <div class="country-container riva-select">
                <div class="p5 riva-select__name">{{ chainRaw.sku }}</div>
                <div class="p5 riva-select__style-name">
                  {{ chainRaw.styleName }}
                </div>
              </div>
            </mat-option>
            <mat-option *ngIf="!(filteredChainRaws | async)?.length">
              <div class="text-center riva-select__not-found">Not Found</div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="chainFinish.name" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            [(ngModel)]="chainFinish.description"
            style="height: 80px; color: white"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        [disabled]="isSaving || !isFormValid"
        (click)="onSaveChainFinish()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
