import { MaterialCode } from './material-code';

export class Product {
  productsId: number;
  productName: string = '';
  sku: string = '';
  customerSku: string = '';
  productsTypeId: number = 1;
  productDesc: string = '';
  customerCode: number = 5;
  commentBox: string = '';
  status: number = 1;
  uom: number = 1;
  picPath: string = '';
  jewelryType: number = 1;
  createdBy: string = '';
  createdDate: Date;
  firstProductionDate: Date;
  productTypeName: string = '';
  jewelryTypeName: string = '';
  productsDmns: ProductsDMNS[];
  productsBom: ProductsBOM[];
  productsInfo: ProductsInfo[];
  productsStoreInfo: ProductsStoreInfo[];
  productstoreflag: Productstoreflag[];
  productsPrices: any[];
  productsStock: any[];
  productsWtg: any[];
  storePrices: any[];
  inStore: boolean;
  customerCodeNavigation: customerCodeNavigation;
  productsRouting: ProductsRouting[];
  sortOrder?: number;
  isRivaProduct?: boolean;
}

export class ProductStatus {
  statusId: number;
  status: string;
}

export class ProductsBOM {
  productsBomid: number;
  bomItemTypeId: number;
  productsId: number;
  subItemId: number = 0;
  itemQty: number;
  sizeLocked: boolean;
  custProvided: string = '';
  notes: string = '';
  productsInfoId: number = 0;
  subItemInfoId: number = 0;
}

export class ProductsBOMResponse {
  productsBomid: number;
  productsId: number;
  subProductsId: number;
  qty: number;
  notes: string;
  // Extra field for display purpose
  productName: string;
}

export class ProductsDMNS {
  productsDmnsId: number;
  productsId: number;
  heightMm: number = 0;
  widthMm: number = 0;
  lengthMm: number = 0;
}

export class Productstoreflag {
  productStoreFlagId: number;
  productsId: number;
  storeFlag: Boolean;
  products: any;
}

export class ProductDetailResponse {
  productsId: number;
  productName: string;
  productsTypeId: number;
  productDesc: string;
  commentBox: string;
  picPath: string;
  jewelryType: number;
  inStore: boolean;
  productDimensions: Dimension = new Dimension();
  materials: Material[];
}

export class Dimension {
  heightMm: number = 0;
  widthMm: number = 0;
  lengthMm: number = 0;
}

export class Material {
  materialCodeId: number;
  code: string;
  karat: string;
  color: string;
  description: string;
  sizeRetailPrices: SizeRetailPrice[] = [];
}

export class SizeRetailPrice {
  size: number;
  price: number;
  weight: number;
}

export class ProductsInfo {
  productsInfoId: number;
  productsId: number;
  materialCodeId: number;
  size: string;
  weight: number;
  stockQty: number;
  priceWhls: number;
  priceRetail: number;
  metalGrainsId: number;
  customerSKU?: string;
}

export class ProductsStoreInfo {
  productsStoreInfoId: number;
  productsId: number;
  productsDesc: string;
  storeFlag: boolean;
  collectionsId: number;
  productsGroupId: number;
  productsGroupLevel: number;
  height: number;
  width: number;
  length: number;
}

export class GroupItem {
  productsId: number;
  productsGroupId: number;
  productsGroupLevel: number;
}

export class GroupItemRequest {
  groupItems: GroupItem[] = [];
}

export class customerCodeNavigation {
  custIdno: number;
  customerId: string;
  companyName: string;
  contactName: string;
  emailAddress: string;
  address1: string;
  address2: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  phone: string;
  fax: string;
  shippingMethod: string;
  paymentTerms: string;
}
export class ProductRoutingCodes {
  activityCode: string;
  activityDesc: string;
  department: string;
  routingCodesId: number;
}
export class ProductsRouting {
  productsRoutingId: number;
  productsId: number;
  routingCodesId: number;
  standardTime: number = 0;
  actualTimeS: number = 0;
  comment: string;
  routingOrder?: number;
  routingCode?: ProductRoutingCodes;
  tempRoutingId?: number;
}

export class RoutingCodes {
  routingCodesId: number;
  activityCode: string;
  department: string;
  activityDesc: string;
}

export interface ProductVariation {
  productEnamelsID?: number;
  productsID: number;
  enamelsID: number;
  enamelSKU: string;
  enamelName: string;
  pantoneColor: string;
  hardness: boolean;
  colorHex: string;
  supplierId?: number;
  supplierDisplayName?: string;
  displayText?: string;
}

export interface ProductEnamel {
  productsBOMEnamelID?: number;
  productsID: number;
  enamelID: number;
  enamelSKU?: string;
  enamelName?: string;
  pantoneColor?: string;
  hardness?: boolean;
  colorHex?: string;
  displayText?: string;
  variation?: boolean;
}

export interface WorkOrderEnamel {
  enamelName: string;
  colorHex: string;
  enamelSKU: string;
}

export interface MaterialDetail {
  materialCodeId: number;
  code: string;
  karat: string;
  color: string;
  description: string;
  printColor1: string;
  printColor2: string;
  multiMetal?: boolean;
  multiMetalPrimary?: number;
  multiMetalPrimaryMaterial: MaterialCode | null;
  multiMetalSecondary?: number;
  multiMetalSecondaryMaterial?: MaterialCode | null;
}
export interface ProductMaterial {
  productsMaterialsID: number;
  productsID: number;
  materialsCodeID: number;
  metalGrainsID: number;
  material: MaterialDetail;
  material2?: MaterialDetail | null;
  materialsCodesID2?: number;
  percentage1?: number;
  percentage2?: number;
  multiMetal?: boolean;
  multiMetalPrimary?: number;
  multiMetalPrimaryMaterial: MaterialCode | null;
  multiMetalSecondary?: number;
  multiMetalSecondaryMaterial?: MaterialCode | null;
}

export interface ProductSizes {
  productsSizesID: number;
  productsID: number;
  size: string;
}
export interface ProductDetail {
  materials: ProductMaterial[];
  productSizes: ProductSizes[];
}

export interface ProductImage {
  productsPicsID: number;
  picsOrder: number;
  fileName?: string;
  small?: string;
  medium?: string;
  big?: string;
}

export interface ProductHistory {
  lastUpdated: string;
  lastUpdatedBy: string;
}

export interface ProductWeight {
  productsWeightsID: number;
  productsID: number;
  productsSizesID: number;
  materialCodesID?: number;
  avgWeightGrams: number;
  productsMaterialsID?: number;
}
