import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Finding } from 'src/app/components/riva-findings/model';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import {
  FindingsMaterial,
  FindingsSize,
  ProductFindings,
  RivaFindings,
} from 'src/app/models/findings.model';
import { FindingsService } from 'src/app/services/findings.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { Guid } from '../../generic/generic.component';
import { FINDINGS_TYPE } from '../../riva-findings/constants';
import { ProductComponentDialogComponent } from '../product-component-dialog/product-component-dialog.component';
import { ConfirmationService } from './../../riva-confirmation/riva-confirmation.service';

@Component({
  selector: 'product-findings',
  templateUrl: './product-findings.component.html',
  styleUrls: ['./product-findings.component.scss'],
})
export class ProductFindingsComponent implements OnInit {
  @Input() productId: number;

  displayedColumns: string[] = [
    'name',
    'sku',
    'material',
    'size',
    'qty',
    'comment',
    'delete',
  ];
  search = '';
  isFindingsSaving = false;
  maxVariation = 30;
  isVariationLimit = false;
  findingsControl = new FormControl();
  filteredFindings: Observable<RivaFindings[]>;
  selection = new SelectionModel<RivaFindings>(true, []);
  selectedFindings: RivaFindings;
  selectedMaterial: FindingsMaterial;
  selectedSize: FindingsSize;
  comment = '';
  qty = 1;
  findings = new MatTableDataSource<RivaFindings>([]);
  findingsList: Finding[] = [];
  rawFindings: RivaFindings[];
  productFindings = new MatTableDataSource<ProductFindings>([]);
  isOverride = false;
  selectedFinding: Finding = {} as Finding;

  selectedTabIndex = 0;
  findingsType = FINDINGS_TYPE;

  constructor(
    public dialogRef: MatDialogRef<ProductComponentDialogComponent>,
    private findingsService: FindingsService,
    private productBomService: ProductBomService,
    private loadService: LoadService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.findings.filterPredicate = (
      data: RivaFindings,
      filterValue: string,
    ) => {
      const { search } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.name.toLowerCase().includes(search?.toLowerCase()) ||
        data.sku.toLowerCase().includes(search?.toLowerCase());
      return searchFilter;
    };
    this.getFindings();
    this.getProductFindings();
    this.getFindingsSize();
  }

  getFindings() {
    this.findingsService.getFindings().subscribe((findings) => {
      this.findings.data = findings;
      this.initiateFilters();
    });
  }

  getFindingsSize() {
    this.findingsService.getFindingList().subscribe((data) => {
      this.findingsList = data ?? [];
    });
  }

  getProductFindings() {
    this.productBomService
      .getProductFindings(this.productId)
      .subscribe((data) => {
        this.productFindings.data = data;
      });
  }

  initiateFilters() {
    this.filteredFindings = this.findingsControl.valueChanges.pipe(
      startWith(this.selectedFindings?.name),
      map((value) => this._filterFindings(value)),
    );
  }

  private _filterFindings(name: string): RivaFindings[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.findings.data.filter(
        (option) =>
          option.name.toLowerCase().includes(filterValue) ||
          option.sku.toLowerCase().includes(filterValue),
      );
    }
    return this.findings.data;
  }

  onFilterChange() {
    this.findings.filter = JSON.stringify({ search: this.search });
  }

  onCheckFindings() {
    this.findings.data = this.rawFindings.filter((f) => {
      return !this.productFindings.data.some(
        (p) => p.findings.findingsId === f.findingsId,
      );
    });
  }

  displayFn(item: RivaFindings): string {
    return item?.name ?? '';
  }

  onFindingsChange() {
    this.selectedMaterial = null;
    this.selectedSize = null;
  }

  addProductFindings() {
    const newProductFindings: ProductFindings = {
      tempProductsBOMFindingsID: Guid.newGuid(),
      findings: this.selectedFindings,
      productsBOMFindingsID: 0,
      productsID: this.productId,
      findingsID: this.selectedFindings.findingsId,
      findingsSizesID: this.selectedSize.findingSizesID,
      findingsMaterialsID:
        this.selectedMaterial?.findingsMaterialsCodeId ?? null,
      qty: this.qty,
      comment: this.comment,
      size: this.selectedSize.size,
      material: this.selectedMaterial,
    };

    this.productFindings.data = [
      ...this.productFindings.data,
      newProductFindings,
    ];
    this.selectedFindings = null;
    this.selectedMaterial = null;
    this.selectedSize = null;
    this.isOverride = false;
    this.qty = 1;
    this.comment = '';
  }

  onDeleteFindings(findings: ProductFindings) {
    this._confirmationService
      .showConfirmation({
        title: 'Delete Product Findings',
        content: 'Are you sure you want to delete this findings?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.productFindings.data = this.productFindings.data.filter((p) =>
          findings.productsBOMFindingsID
            ? p.productsBOMFindingsID !== findings.productsBOMFindingsID
            : p.tempProductsBOMFindingsID !==
              findings.tempProductsBOMFindingsID,
        );
        if (findings.productsBOMFindingsID) {
          this.productBomService
            .deleteProductFindings(findings.productsBOMFindingsID)
            .subscribe(() => {
              this.loadService.reloadProductFindings();
            });
        }
        this.initiateFilters();
      });
  }

  onSave() {
    this.isFindingsSaving = true;
    const newProductFindings = {
      productsBOMFindingsID: 0,
      productsID: this.productId,
      findingsID: this.selectedFinding.findingsId,
      findingsSizesID: this.selectedFinding.findingSizesID,
      findingsMaterialsID: this.selectedFinding.findingsMaterialsID,
      qty: this.selectedFinding.qty,
      comment: this.selectedFinding.comment,
    };
    this.productBomService
      .setProductFindings(newProductFindings)
      .subscribe(() => {
        this.loadService.reloadProductFindings();
        this.isFindingsSaving = false;
        this.dialogRef.close();
      });
  }

  onSelectFinding($event) {
    this.selectedFinding = $event;
  }

  //TODO: Deprecated
  onSaveOrig() {
    const productFindings = this.productFindings.data.reduce(
      (findings$, findings) => {
        if (findings.productsBOMFindingsID) return findings$;
        const newProductFindings = {
          productsBOMFindingsID: 0,
          productsID: this.productId,
          findingsID: findings.findingsID,
          findingsSizesID: findings.findingsSizesID,
          findingsMaterialsID: findings.findingsMaterialsID,
          qty: findings.qty,
          comment: findings.comment,
        };
        return [
          ...findings$,
          this.productBomService.setProductFindings(newProductFindings),
        ];
      },
      [],
    );
    if (!productFindings.length) {
      this.isFindingsSaving = false;
      this.dialogRef.close();
      return;
    }
    this.isFindingsSaving = true;
    forkJoin(productFindings).subscribe(() => {
      this.loadService.reloadProductFindings();
      this.isFindingsSaving = false;
      this.dialogRef.close();
    });
  }

  isOverrideChange() {
    if (!this.isOverride) {
      this.selectedMaterial = null;
    }
  }

  get isFormValid() {
    return (
      this.selectedFindings?.findingsId &&
      this.selectedSize?.findingSizesID &&
      this.qty &&
      (!this.isOverride || this.selectedMaterial?.findingsMaterialsCodeId)
    );
  }
}
