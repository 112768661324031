<div class="orders-page">
  <!-- <div class="orders-page__header">
    <div class="header-title">
      <i
        nz-icon
        nzType="left-circle"
        nzTheme="fill"
        style="margin-right: 20px; cursor: pointer"
        title="Back to Orders"
        (click)="gotoOrdersView()"
      ></i>
      <span>Order Details</span>
    </div>
    <div style="display: flex; align-items: center; gap: 16px">
      <button
        class="riva-button"
        style="width: 250px"
        *ngIf="selectedOrderId"
        (click)="onOpenRelatedInvoices()"
      >
        Related Invoices
      </button>
    </div>
  </div> -->
  <div class="orders-page__details">
    <div class="orders-page__panel customer-details">
      <div class="orders-page__header">
        <div class="header-title">
          <i
            nz-icon
            nzType="left-circle"
            nzTheme="fill"
            style="margin-right: 20px; cursor: pointer"
            title="Back to Orders"
            (click)="gotoOrdersView()"
          ></i>
          <span>Order Details</span>
        </div>
      </div>
      <div>
        <fieldset
          [disabled]="
            !editMode || !hasPermission(orderDetailsFeatureKey.customerField)
          "
        >
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Customer</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="selectedCustomer"
              [formControl]="customerControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option class="riva-select-header riva-select__stick-header">
                <div class="riva-select">
                  <div class="p5 riva-select__customer-id">ID</div>
                  <div class="p5 riva-select__name">Name</div>
                </div>
              </mat-option>
              <mat-option class="riva-select-header">
                <div class="riva-select">
                  <div class="p5 riva-select__customer-id">ID</div>
                  <div class="p5 riva-select__name">Name</div>
                </div>
              </mat-option>
              <mat-option
                *ngFor="let customer of filteredCustomers | async"
                [value]="customer"
                style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
                (click)="onCustomerChange(customer)"
              >
                <div class="country-container riva-select">
                  <div class="p5 riva-select__customer-id">
                    {{ customer.customerId }}
                  </div>
                  <div class="p5 riva-select__name">
                    {{ customer.companyName }}
                  </div>
                </div>
              </mat-option>
              <mat-option *ngIf="!(filteredCustomers | async)?.length">
                <div class="text-center riva-select__not-found">Not Found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </fieldset>
        <div class="po-fields">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>PO INTERNAL</mat-label>
            <input
              matInput
              [(ngModel)]="selectedOrder.pointernal"
              [disabled]="
                !editMode ||
                !hasPermission(orderDetailsFeatureKey.internalPoField)
              "
            />
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>PO EXTERNAL</mat-label>
            <input
              matInput
              [(ngModel)]="selectedOrder.poexternal"
              [disabled]="
                !editMode ||
                !hasPermission(orderDetailsFeatureKey.externalPoField)
              "
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="orders-page__panel">
      <div class="order-dates-comment">
        <div class="order-details">
          <mat-form-field
            appearance="outline"
            class="fieldsize"
            style="width: 100%"
          >
            <mat-label>RECEIVED DATE</mat-label>
            <input
              matInput
              [matDatepicker]="picker1"
              [(ngModel)]="selectedOrder.receivedDate"
              [disabled]="
                !editMode ||
                !hasPermission(orderDetailsFeatureKey.receivedDateField)
              "
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="fieldsize"
            style="width: 100%"
          >
            <mat-label>ENTRY DATE</mat-label>
            <input
              matInput
              [matDatepicker]="picker2"
              [(ngModel)]="selectedOrder.entryDate"
              [disabled]="
                !editMode ||
                !hasPermission(orderDetailsFeatureKey.entryDateField)
              "
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="fieldsize"
            style="width: 100%"
          >
            <mat-label>DUE DATE</mat-label>
            <input
              matInput
              [matDatepicker]="picker3"
              [(ngModel)]="selectedOrder.requiredDate"
              [disabled]="
                !editMode || !hasPermission(orderDetailsFeatureKey.dueDateField)
              "
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker3"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="order-comments">
          <span
            style="position: absolute; right: 8px; top: 6px; font-size: 14px"
            >{{ selectedOrder.comment?.length ?? 0 }}/1000</span
          >
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>COMMENTS</mat-label>
            <textarea
              matInput
              [(ngModel)]="selectedOrder.comment"
              [disabled]="
                !editMode || !hasPermission(orderDetailsFeatureKey.commentField)
              "
              rows="8"
              [maxlength]="1000"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      class="orders-page__panel"
      style="display: flex; flex-direction: column; gap: 16px"
    >
      <button
        class="riva-button"
        *ngIf="selectedOrderId"
        (click)="onOpenRelatedInvoices()"
      >
        Related Invoices
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="editOrder()"
        *ngIf="!editMode && selectedOrder.ordersType !== 2"
        userPermission
        [elementKey]="orderDetailsFeatureKey.saveButton"
      >
        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
        Edit
      </button>
      <fieldset [disabled]="!isFormValid">
        <button
          type="button"
          class="btn btn-success"
          (click)="saveOrder()"
          *ngIf="editMode"
          userPermission
          [elementKey]="orderDetailsFeatureKey.saveButton"
        >
          <i class="fa fa-floppy-o" aria-hidden="true"></i>
          Save
        </button>
      </fieldset>
      <button
        type="button"
        class="btn btn-danger"
        (click)="cancelChange()"
        *ngIf="selectedOrderId && editMode"
      >
        <i class="fa fa-window-close" aria-hidden="true"></i>
        Cancel
      </button>
    </div>
  </div>
  <div
    class="orders-page__panel"
    *ngIf="selectedOrderId"
    style="margin-top: 15px; padding-bottom: 15px"
  >
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
      "
    >
      <div>
        <button
          class="btn btn-primary"
          style="width: 250px"
          (click)="onOpenUploadDialog()"
          *ngIf="hasUploadOrderAccess"
        >
          Upload Orders
        </button>
      </div>
      <div style="display: flex; justify-content: flex-end; gap: 16px">
        <button
          [matMenuTriggerFor]="specialProcedureMenu"
          class="btn btn-warning"
          style="
            width: 190px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: unset;
          "
          userPermission
          [elementKey]="orderDetailsFeatureKey.byPassWorkOrder"
          *ngIf="hasItems && selectedOrder.ordersType !== 2"
        >
          Special Procedures
        </button>
        <button
          *ngIf="sharedService.showGenerateWorkOrderAndByPass"
          (click)="generateWorkOrders()"
          type="button"
          class="btn btn-success"
          style="width: 300px"
          userPermission
          [elementKey]="orderDetailsFeatureKey.generateWorkOrder"
        >
          Generate Work Orders
        </button>
      </div>
    </div>
    <ng-container *ngIf="!isLoadingRequiredData">
      <orderdetails-list
        [_ordersId]="this.selectedOrderId"
        [_customerId]="this.selectedOrder.customerId"
        [dueDate]="this.selectedOrder.requiredDate"
        [ordersType]="this.selectedOrder.ordersType"
        [productList]="productList"
        [customersList]="customersList"
        [materialCodes]="materialCodes"
        (onLoad)="onListLoad($event)"
      ></orderdetails-list>
    </ng-container>
  </div>
</div>

<mat-menu
  style="max-width: 400px"
  #specialProcedureMenu="matMenu"
  xPosition="before"
>
  <button
    mat-menu-item
    style="font-size: 14px; text-transform: unset; text-align: left"
    (click)="onByPassWorkOrder(true)"
    [disabled]="!sharedService.showGenerateWorkOrderAndByPass"
  >
    Invoice All Directly and Skip Work Orders
  </button>
  <button
    mat-menu-item
    style="font-size: 14px; text-transform: unset; text-align: left"
    (click)="onByPassWorkOrder(false)"
    [disabled]="!hasUnInvoiced"
  >
    Pre-Invoice
  </button>
</mat-menu>

<ng-template #productDetail let-modal>
  <div class="report-popup">
    <div class="modal-header row">
      <h5 class="modal-title col-10" id="modal-basic-title">
        Edit - Product, Material and Sizes
      </h5>
    </div>
    <div class="modal-body">
      <div class="create-form">
        <div class="row">
          <div class="col" style="padding: 0; padding-top: 15px">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>PRODUCT</mat-label>
                  <select
                    matNativeControl
                    required
                    (change)="getProductInfoByProduct($event.target.value)"
                    [(ngModel)]="dialogProductId"
                  >
                    <option
                      *ngFor="let item of productPerCustomer"
                      [value]="item.productsId"
                    >
                      {{ item.productName }}
                    </option>
                  </select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <mat-form-field appearance="fill" style="width: 100%">
                  <mat-label>MATERIAL AND SIZE</mat-label>
                  <select
                    matNativeControl
                    required
                    (change)="
                      setSelectedDialogProductsInfo($event.target.value)
                    "
                    [(ngModel)]="dialogProductsInfoId"
                    [disabled]="
                      productsInfo.length == 0 || dialogProductId == null
                    "
                  >
                    <option
                      *ngFor="let item of productsInfo"
                      [value]="item.productsInfoId"
                    >
                      {{ getProductInfoDesc(item.materialCodeId, item.size) }}
                    </option>
                  </select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <button
                  class="btn btn-confirm"
                  (click)="saveDetailsFromDiaog(); modal.dismiss('Cross click')"
                  [disabled]="dialogProductsInfoId == 0"
                >
                  SAVE
                </button>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-confirm"
                  (click)="modal.dismiss('Cross click')"
                >
                  CANCEL
                </button>
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
