import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoneProductComponent } from '../../riva-gems/stone-product/stone-product.component';
import { ProductEnamelComponent } from '../product-enamel/product-enamel.component';
import { ProductFindingsComponent } from '../product-findings/product-findings.component';

const PRODUCT_ENAMEL_INDEX = 0;
const PRODUCT_FINDINGS_INDEX = 1;
const PRODUCT_STONES_INDEX = 2;

@Component({
  selector: 'product-component-dialog',
  templateUrl: './product-component-dialog.component.html',
  styleUrls: ['./product-component-dialog.component.scss'],
})
export class ProductComponentDialogComponent implements OnInit {
  @ViewChild(ProductEnamelComponent)
  productEnamelComponent: ProductEnamelComponent;
  @ViewChild(ProductFindingsComponent)
  productFindingsComponent: ProductFindingsComponent;
  @ViewChild(StoneProductComponent)
  stoneProductComponent: StoneProductComponent;
  selectedIndex = PRODUCT_ENAMEL_INDEX;

  constructor(
    public dialogRef: MatDialogRef<ProductComponentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { productId: number },
  ) {}

  ngOnInit(): void {}

  get isSaveEnabled() {
    switch (this.selectedIndex) {
      case PRODUCT_ENAMEL_INDEX:
        return !this.productEnamelComponent?.isEnamelSaving;
      case PRODUCT_FINDINGS_INDEX:
        return !this.productFindingsComponent?.isFindingsSaving;
      case PRODUCT_STONES_INDEX:
        return !this.stoneProductComponent?.isStoneSaving;
      default:
        return false;
    }
  }

  onSave() {
    switch (this.selectedIndex) {
      case PRODUCT_ENAMEL_INDEX:
        this.productEnamelComponent.onSave();
        break;
      case PRODUCT_FINDINGS_INDEX:
        this.productFindingsComponent.onSave();
        break;
      case PRODUCT_STONES_INDEX:
        this.stoneProductComponent.onSave();
        break;
    }
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
}
