import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'riva-text-avatar',
  templateUrl: './riva-text-avatar.component.html',
  styleUrls: ['./riva-text-avatar.component.scss'],
})
export class RivaTextAvatarComponent implements OnInit {
  @Input() text: string;
  @Input() size?: number = 35;
  randomColor: string = '#68516d';
  initial: string;

  constructor() {}

  ngOnInit(): void {
    this.randomColor = this.getRandomDarkColor();
    this.initial = this.getInitial();
  }

  getRandomDarkColor() {
    let color = '#';
    for (var i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
    }
    return color;
  }

  getInitial() {
    return this.text
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  }
}
