<div class="stone-dialog">
  <h3 mat-dialog-title>Update stone</h3>
  <mat-dialog-content class="mat-typography" style="height: 80px">
    <mat-form-field
      appearance="fill"
      style="width: 100%; margin-bottom: -16px !important"
    >
      <mat-label>Carat Per Stone</mat-label>
      <input
        matInput
        type="number"
        min="0"
        max="999.99"
        step="1"
        [(ngModel)]="stone.caratsPerUnit"
      />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        (click)="onSave()"
        [disabled]="isSaving || !stone.caratsPerUnit"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
