import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SubItem } from 'src/app/models/sub-item.model';
import { UnitOfMeasure } from 'src/app/models/unit-of-measure';
import { ProductService } from 'src/app/services/product.service';
import { SubItemService } from 'src/app/services/sub-item.service';
import { BillOfMaterialSubItem } from './../../models/bill-material-sub-item';

@Component({
  selector: 'bill-of-material-sub-item-dialog',
  templateUrl: './bill-of-material-sub-item-dialog.component.html',
  styleUrls: ['./bill-of-material-sub-item.component.scss'],
})
export class BillOfMaterialSubItemDialogComponent implements OnInit {
  isSaving: boolean = false;
  imageFiles: FileList | null = null;
  uomOptions$: Observable<UnitOfMeasure[]>;
  subItem: SubItem = {} as SubItem;
  subItems: SubItem[] = [];
  filteredSubItems$: Observable<SubItem[]>;
  subItemControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<BillOfMaterialSubItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: BillOfMaterialSubItem = {} as BillOfMaterialSubItem,
    public subItemService: SubItemService,
    public productService: ProductService,
  ) {}

  ngOnInit(): void {}

  initializeFilters() {
    this.filteredSubItems$ = this.subItemControl.valueChanges.pipe(
      startWith(this.subItem.name),
      map((value) => this._filterSubItems(value)),
    );
  }

  displayFn(item: SubItem): string {
    return item?.name ?? '';
  }

  private _filterSubItems(name: string): SubItem[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.subItems.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue) ||
          option.description?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['name'];
      return this.subItems.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue.toLowerCase()) ||
          option.description?.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return this.subItems;
  }

  onSave() {
    this.isSaving = true;
    const { productsBOMID, productId, notes, qty } = this.data;
    const data = {
      notes,
      qty,
      productsId: productId,
      productsBOMID: productsBOMID ?? 0,
      subItemId: this.subItem?.subItemsID,
      custProvided: false,
    };
    this.productService.setProductBillOfMaterial(data).subscribe(() => {
      this.isSaving = false;
      this.dialogRef.close(true);
    });
  }
}
