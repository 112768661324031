<h3 mat-dialog-title>Related Invoices</h3>
<mat-dialog-content class="mat-typography">
  <div class="related-invoices-dialog">
    <div style="border: 1px solid #646464">
      <table
        mat-table
        [dataSource]="relatedInvoices"
        matSort
        matSortActive="productName"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="invoicesID">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by invoicesID"
          >
            Invoice #
          </th>
          <td mat-cell *matCellDef="let element">
            <a
              class="riva-button riva-button-sm riva-button-link"
              href="/invoicing-and-shipping/invoicing/{{ element.invoicesID }}"
              target="_blank"
              style="font-size: 14px"
            >
              {{ element.invoicesID }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="invoicedDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by invoicedDate"
          >
            Invoiced Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.invoicedDate | date: 'MM/dd/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="custIDNo">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by custIDNo"
          >
            Customer
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.customer.companyName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="qtyTotal">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by qtyTotal"
          >
            Qty
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.qtyTotal }}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalPrice">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by totalPrice"
          >
            Total Billed
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.totalPrice | currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="invoicePaid">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by invoicePaid"
          >
            Paid Date
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.invoicePaid">{{
              element.invoicePaid | date: 'MM/dd/yyyy'
            }}</span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="6">No Related Invoices</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div style="display: flex; justify-content: center">
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px; width: 150px"
      mat-dialog-close
    >
      Close
    </button>
  </div>
</mat-dialog-actions>
