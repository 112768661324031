<h3 mat-dialog-title>Copy From Other Component Routing</h3>

<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Search Other Component</mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="otherComponent"
      [formControl]="otherComponentControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      [displayWith]="displayFn"
    >
      <mat-option class="riva-select-header riva-select__stick-header">
        <div class="riva-select">
          <div class="p5 riva-select__name">Long Name</div>
          <div class="p5 riva-select__sku">Short Name</div>
          <div class="p5 riva-select__description">Description</div>
        </div>
      </mat-option>
      <mat-option class="riva-select-header">
        <div class="riva-select">
          <div class="p5 riva-select__name">Long Name</div>
          <div class="p5 riva-select__sku">Short Name</div>
          <div class="p5 riva-select__description">Description</div>
        </div>
      </mat-option>
      <mat-option
        *ngFor="let item of filteredOtherComponents | async"
        [value]="item"
        style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
      >
        <div class="country-container riva-select">
          <div class="p5 riva-select__name">
            {{ item.longName }}
          </div>
          <div class="p5 riva-select__sku">{{ item.shortName }}</div>
          <div class="p5 riva-select__description">
            {{ item.description }}
          </div>
        </div>
      </mat-option>
      <mat-option *ngIf="!(filteredOtherComponents | async)?.length">
        <div class="text-center riva-select__not-found">Not Found</div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <other-components-routing
    [otherComponentsId]="otherComponent.otherComponentsID"
    [readonly]="true"
    (onRoutingChange)="onRoutingChange($event)"
  ></other-components-routing>
</mat-dialog-content>

<mat-dialog-actions
  style="display: flex; align-items: center; justify-content: center"
>
  <div
    style="
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
      width: 400px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      [disabled]="!routings.length"
      (click)="onConfirm()"
    >
      Copy Routing
    </button>
  </div>
</mat-dialog-actions>
