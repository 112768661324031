<div class="riva-chain-finished-product">
  <mat-form-field
    class="search-input"
    appearance="fill"
    style="width: 400px; color: white"
  >
    <mat-label>Search chain</mat-label>
    <input
      matInput
      [(ngModel)]="searchText"
      (ngModelChange)="onSearchChain()"
    />
  </mat-form-field>
  <div class="table-container">
    <div class="mat-table__container" style="height: 310px; max-height: 310px">
      <table
        mat-table
        matSort
        [dataSource]="chains"
        matSortActive="name"
        matSortDirection="asc"
      >
        <ng-container matColumnDef="picPath">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                width: 70px;
                padding: 5px;
                display: flex;
                justify-content: center;
              "
              *ngIf="element.picPath"
            >
              <img
                style="max-height: 37px; max-width: 60px"
                [src]="element.picPath"
              />
            </div>
            <div
              style="
                width: 70px;
                padding: 5px;
                display: flex;
                justify-content: center;
              "
              *ngIf="!element.picPath"
            >
              <img
                style="max-height: 37px; max-width: 60px"
                src="assets/images/no-image.png"
              />
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by name"
          >
            Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by sku"
          >
            SKU
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.chainRaw.sku }}
          </td>
        </ng-container>
        <ng-container matColumnDef="styleName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by styleName"
          >
            Style Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.styleName }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="clickable-row"
          [ngClass]="{
            'is-selected':
              row.chainFinishedID === selectedChain?.chainFinishedID
          }"
          (click)="onSelectChain(row)"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="4">No Available Data</td>
        </tr>
      </table>
    </div>
    <div>
      <div
        class="mat-table__container"
        style="height: 250px; max-height: 250px"
      >
        <table
          mat-table
          matSort
          [dataSource]="chainFinishSizes"
          matSortActive="size"
          matSortDirection="asc"
        >
          <ng-container matColumnDef="size">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by size"
            >
              Size
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.size }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedSizeColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedSizeColumns"
            class="clickable-row"
            [ngClass]="{
              'is-selected':
                row.chainFinishedSizesID ===
                selectedChainFinishSize?.chainFinishedSizesID
            }"
            (click)="onSelectChainFinishSize(row)"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="1">No Available Size</td>
          </tr>
        </table>
      </div>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px; margin-top: 20px"
        [disabled]="isSaving || !isFormValid"
        (click)="onSaveChainFinish()"
      >
        Add
      </button>
    </div>
  </div>
  <div style="margin-top: 20px">
    <div class="mat-table__container" style="height: 250px; max-height: 250px">
      <table
        mat-table
        matSort
        [dataSource]="productChains"
        matSortActive="productsBOMChainRawID"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="picPath">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div
              style="
                width: 70px;
                padding: 5px;
                display: flex;
                justify-content: center;
              "
              *ngIf="element.chainRaw.picPath"
            >
              <img
                style="max-height: 37px; max-width: 60px"
                [src]="element.chainRaw.picPath"
              />
            </div>
            <div
              style="
                width: 70px;
                padding: 5px;
                display: flex;
                justify-content: center;
              "
              *ngIf="!element.chainRaw.picPath"
            >
              <img
                style="max-height: 37px; max-width: 60px"
                src="assets/images/no-image.png"
              />
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by name"
            >
              Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="asc"
            sortActionDescription="Sort by sku"
          >
            SKU
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.chainRaw.sku }}
          </td>
        </ng-container>
        <ng-container matColumnDef="styleName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by styleName"
          >
            Style Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.chainRaw?.chainStyle?.styleName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="chainFinishedSizesID">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            start="desc"
            sortActionDescription="Sort by chainFinishedSizesID"
          >
            Size
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.chainFinishedSizes.size }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <i
              nz-icon
              nzType="delete"
              nzTheme="fill"
              class="icon-delete"
              (click)="onDeleteChain(element)"
            ></i>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedProductColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedProductColumns"
          class="clickable-row"
        ></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="5">No Available Data</td>
        </tr>
      </table>
    </div>
  </div>
</div>
