import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ProductRoutingCodes, ProductsRouting } from 'src/app/models/product';
import { ProductsRoutingService } from 'src/app/services/products-routing.service';
import { ConfirmationService } from './../riva-confirmation/riva-confirmation.service';
import { ProductRoutingCopyDialogComponent } from './product-routing-copy-dialog.component';
import { ProductRoutingDialogComponent } from './product-routing-dialog.component';

@Component({
  selector: 'product-routing',
  templateUrl: './product-routing.component.html',
  styleUrls: ['./product-routing.component.scss'],
})
export class ProductRoutingComponent implements OnChanges {
  @Input() productId: number;
  @Input() readonly?: boolean;
  @Output() onRoutingChange = new EventEmitter<ProductsRouting[]>();
  @ViewChild('table') table: MatTable<string>;
  routingDisplayColumn: string[] = [
    'draggable',
    'activityCode',
    'department',
    'activityDescription',
    'standardTime',
    'comment',
    'delete',
  ];
  productRoutings: ProductsRouting[] = [];
  productRoutingsForDelete: ProductsRouting[] = [];
  productRoutingCodes: ProductRoutingCodes[] = [];
  rawProductRoutingCodes: ProductRoutingCodes[] = [];
  filteredProductRoutingCodes: Observable<ProductRoutingCodes[]>;
  productRoutingControl = new FormControl();
  selectedProductRouting: ProductRoutingCodes;
  isSaving = false;
  dragEnabled = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProductRoutingDialogComponent>,
    private productsRoutingService: ProductsRoutingService,
    private confirmationService: ConfirmationService,
    private loadService: LoadService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadProductRoutings();
  }

  loadProductRoutings() {
    if (!this.productId) {
      this.productRoutings = [];
      this.productRoutingCodes = [];
      this.rawProductRoutingCodes = [];
      this.onRoutingChange.emit(this.productRoutings);
      return;
    }
    forkJoin([
      this.productsRoutingService.getProductRoutings(this.productId),
      this.productsRoutingService.getProductRoutingCodes(),
    ]).subscribe(([productRoutings, productRoutingCodes]) => {
      this.productRoutings = productRoutings;
      this.productRoutingCodes = [...productRoutingCodes];
      this.rawProductRoutingCodes = [...productRoutingCodes];
      this.onRoutingChange.emit(this.productRoutings);
      this.initiateFilters();
    });
  }

  onCheckProductRoutingCode() {
    this.productRoutingCodes = this.rawProductRoutingCodes.filter(
      (productRoutingCode) => {
        return !this.productRoutings.some(
          (productRouting) =>
            productRouting.routingCode?.routingCodesId ===
            productRoutingCode.routingCodesId,
        );
      },
    );
  }

  displayFn(item: ProductRoutingCodes): string {
    return item?.activityCode ?? '';
  }

  onSelectProductRoutingCode(productRoutingCode: ProductRoutingCodes) {
    const productRouting = {
      productsId: this.productId,
      routingCodesId: productRoutingCode.routingCodesId,
      productsRoutingId: 0,
      standardTime: 0,
      actualTimeS: 0,
      comment: '',
      routingCode: productRoutingCode,
      tempRoutingId: +new Date(),
    };
    this.productRoutings = [...this.productRoutings, productRouting];

    this.selectedProductRouting = null;
  }

  initiateFilters() {
    this.filteredProductRoutingCodes =
      this.productRoutingControl.valueChanges.pipe(
        startWith(this.selectedProductRouting?.activityCode),
        map((value) => this._filterProductRoutingCode(value)),
      );
  }

  private _filterProductRoutingCode(name: string): ProductRoutingCodes[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name.toLowerCase();
      return this.productRoutingCodes.filter(
        (option) =>
          option.activityCode.toLowerCase().includes(filterValue) ||
          option.department.toLowerCase().includes(filterValue) ||
          option.activityDesc.toLowerCase().includes(filterValue),
      );
    }
    return this.productRoutingCodes;
  }

  dropTable(event: CdkDragDrop<string[]>) {
    const prevIndex = this.productRoutings.findIndex(
      (d) => d === event.item.data,
    );
    moveItemInArray(this.productRoutings, prevIndex, event.currentIndex);
    // this.table.renderRows();
  }

  onSaveProductRouting() {
    if (!this.productRoutings?.length) {
      this.dialogRef.close();
      return;
    }
    const data = this.productRoutings.map(({ routingCode, ...p }, index) => ({
      ...p,
      routingOrder: index + 1,
    }));
    this.loadService.isSavingProductRouting = true;

    this.productsRoutingService.setProductRouting(data).subscribe(
      () => {
        this.loadService.isSavingProductRouting = false;
        this.dialogRef.close();
      },
      () => {
        this.loadService.isSavingProductRouting = false;
      },
    );
  }
  onDeleteProductRouting(productRouting: ProductsRouting) {
    this.confirmationService
      .showConfirmation({
        content: `Continue to delete ${productRouting.routingCode.activityCode} from Product Routing?`,
        title: 'Delete product routing?',
        confirmLabel: 'Continue',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (productRouting.productsRoutingId) {
            this.productRoutings = this.productRoutings.filter(
              (p) => p.productsRoutingId !== productRouting.productsRoutingId,
            );
            this.productsRoutingService
              .deleteProductRouting(productRouting.productsRoutingId)
              .subscribe();
          } else {
            this.productRoutings = this.productRoutings.filter(
              (p) => p.tempRoutingId !== productRouting.tempRoutingId,
            );
          }
        }
      });
  }
  onCopyRoutingOpen() {
    const dialogRef = this.dialog.open(ProductRoutingCopyDialogComponent, {
      disableClose: true,
      maxWidth: '1000px',
      width: '100%',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((newRoutings) => {
      if (!newRoutings) return;
      this.productRoutings = newRoutings.map((r, index) => ({
        ...r,
        productsRoutingId: 0,
        tempRoutingId: +new Date() + index,
        productsId: this.productId,
      }));
    });
  }
}
