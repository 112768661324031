<div class="accounts-table">
  <div class="accounts-table__heading">
    <div class="accounts-table__new-account">
      <button class="btn riva-button" (click)="showAccountDialog()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-user-plus"
          width="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          style="margin-right: 10px"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="9" cy="7" r="4" />
          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
          <path d="M16 11h6m-3 -3v6" />
        </svg>
        <span>New Account</span>
      </button>
    </div>
    <div class="accounts-table__search">
      <mat-form-field class="search-input" appearance="fill">
        <mat-label>Search by name or username</mat-label>
        <input
          matInput
          [(ngModel)]="search"
          (ngModelChange)="onFilterChange()"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="accounts-table__table-container">
    <table
      mat-table
      [dataSource]="accounts"
      matSort
      matSortActive="fullName"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="fullName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by fullName"
        >
          User
        </th>
        <td mat-cell *matCellDef="let element">
          <accounts-table-user [account]="element"></accounts-table-user>
        </td>
      </ng-container>
      <ng-container matColumnDef="employeeID">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by employeeID"
        >
          Employee ID
        </th>
        <td mat-cell *matCellDef="let element" class="size-13">
          {{ element.employeeID || '–' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateCreated">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by created"
        >
          Date Created
        </th>
        <td mat-cell *matCellDef="let element" class="size-13">
          <div *ngIf="!element.created">–</div>
          <div *ngIf="element.created" class="accounts-table__date-container">
            <div class="accounts-table__date">
              {{ element.created | date : 'MMMM dd, yyyy' }}
            </div>
            <div class="accounts-table__time">
              at {{ element.created | date : 'hh:mm a' }}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="lastLogin">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by lastLogin"
        >
          Last Login Date
        </th>
        <td mat-cell *matCellDef="let element" class="size-13">
          <div *ngIf="!element.lastLogin">–</div>
          <div *ngIf="element.lastLogin" class="accounts-table__date-container">
            <div class="accounts-table__date">
              {{ element.lastLogin | date : 'MMMM dd, yyyy' }}
            </div>
            <div class="accounts-table__time">
              at {{ element.lastLogin | date : 'hh:mm a' }}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <button
            class="btn-riva btn-riva-sm"
            [matMenuTriggerFor]="menu"
            (click)="onSelectAccount(element.usersID, element)"
          >
            Actions
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="8">No Available Data</td>
      </tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[50]"
      showFirstLastButtons
      hidePageSize
    ></mat-paginator>
  </div>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      class="accounts-table__menu-item"
      (click)="showAccountDialog(false)"
    >
      Edit Account
    </button>
    <button
      mat-menu-item
      class="accounts-table__menu-item"
      (click)="setPermissions()"
      *ngIf="!user?.noLoginUser"
    >
      Set Permissions
    </button>
    <button
      mat-menu-item
      class="accounts-table__menu-item"
      (click)="resetPassword()"
      *ngIf="!user?.noLoginUser"
    >
      Reset Password
    </button>
  </mat-menu>
</div>
