import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';
import { PAGES } from 'src/app/core/user-permission/user-permission-rules/pages';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { environment } from '../../../environments/environment';
import { GenericService } from '../../services/generic.service';
import { UserPermission } from './user-permission';
import { USER_PERMISSIONS } from './user-permission-rules';

@Injectable()
export class UserPermissionService extends GenericService {
  baseUrl = environment.apiUrl + 'user-permissions';
  userFeaturesPermission = null;
  userPagePermission = null;
  constructor(
    public http: HttpClient,
    public router: Router,
    public loadService: LoadService,
    public tokenStorageService: TokenStorageService,
    public authenticationService: AuthenticationService,
  ) {
    super(http);
  }

  getCurrentUserPermissions(userId: number): Observable<any> {
    return this.http
      .get<UserPermission[]>(`${this.baseUrl}/${userId}`, {
        headers: this.headers,
      })
      .pipe(
        map((userPermissions: UserPermission[]) => {
          this.userPagePermission = userPermissions.reduce(
            (normalized, pagePermission) => {
              const permission = PAGES.find(
                (p) => p.pageName === pagePermission.pageName,
              );
              if (!permission) return normalized;
              const { customAccessLevel, disabled } = permission;
              return {
                ...normalized,
                [pagePermission.pageName]: {
                  ...pagePermission,
                  disabled,
                  hasCustomAccess:
                    !customAccessLevel?.length ||
                    customAccessLevel?.includes(pagePermission.accessLevel),
                },
              };
            },
            {},
          );
          this.userFeaturesPermission = userPermissions.reduce(
            (permissions, userPermission) => {
              const pagePermissions =
                USER_PERMISSIONS[userPermission.pageName]?.[
                  userPermission.accessLevel
                ] ?? {};
              return { ...permissions, ...pagePermissions };
            },
            {},
          );
          this.loadService.reloadNavigation();
          return userPermissions;
        }),
      );
  }

  getUserPermissions(userId: number): Observable<any> {
    return this.http
      .get<UserPermission[]>(`${this.baseUrl}/${userId}`, {
        headers: this.headers,
      })
      .pipe(
        map((userPermissions: UserPermission[]) => {
          return userPermissions.reduce((normalized, pagePermission) => {
            return {
              ...normalized,
              [pagePermission.pageName]: pagePermission,
            };
          }, {});
        }),
      );
  }

  setUserPermissions(permissions: UserPermission[]) {
    return this.http.post(this.baseUrl, permissions, { headers: this.headers });
  }

  checkPagePermission(pageName) {
    const { usersID, isAdmin } = this.tokenStorageService.currentUser;
    if (!usersID) {
      this.authenticationService.signOut();
      return;
    }
    if (isAdmin) return;
    if (!this.userPagePermission) {
      this.loadService.load(true);
      this.getCurrentUserPermissions(usersID).subscribe(() => {
        this.checkDisabledPermission(pageName);
        this.loadService.load(false);
      });
      return;
    }
    this.checkDisabledPermission(pageName);
  }

  checkDisabledPermission(pageName) {
    const { accessLevel, disabled, hasCustomAccess } =
      this.userPagePermission?.[pageName] ?? {
        accessLevel: 'Disabled',
        disabled: false,
      };
    const hasAccess =
      disabled == null || (accessLevel !== 'Disabled' && hasCustomAccess);

    if (!hasAccess) {
      this.router.navigate(['/forbidden']);
    }
  }

  hasAccess(key) {
    const user = this.tokenStorageService.getUser();
    return user.isAdmin || this.userFeaturesPermission?.[key] === 'access';
  }
}
