import { Component, Input } from '@angular/core';

@Component({
  selector: 'riva-chain-product-container',
  templateUrl: './riva-chain-product-container.component.html',
  styleUrls: ['./riva-chain-product-container.component.scss'],
})
export class RivaChainProductContainerComponent {
  @Input() productId: number;
}
