<h3
  mat-dialog-title
  style="color: #856404; font-size: 20px; display: flex; align-items: center"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-alert-triangle"
    width="50"
    height="50"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="#856404"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
    style="margin-right: 10px"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 9v2m0 4v.01" />
    <path
      d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
    />
  </svg>
  Create new stone options?
</h3>
<mat-dialog-content class="mat-typography" style="font-size: 16px">
  <div style="margin-bottom: 20px">
    Are you sure you want to create the following variants?
  </div>
  <div *ngFor="let item of data" style="margin-bottom: 8px">{{ item }}</div>
</mat-dialog-content>

<mat-dialog-actions
  style="
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 15px;
  "
>
  <button
    class="btn btn-default"
    mat-dialog-close
    style="font-size: 12px; padding: 5px; height: 40px; color: #856404"
  >
    Cancel
  </button>
  <button
    class="btn btn-warning"
    style="font-size: 12px; padding: 5px; height: 40px; color: white"
    [mat-dialog-close]="true"
    autofocus
  >
    Yes
  </button>
</mat-dialog-actions>
