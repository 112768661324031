<h3 mat-dialog-title>Supplier</h3>

<!-- suppliersId: number = 0;
  supplierId: string = '';
  companyName: string = '';
  contactName: string = '';
  emailAddress: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  region: string = '';
  postalCode: string = '';
  country: string = '';
  phone: string = '';
  fax: string = '';
  shippingMethod: string = '';
  leadtimeDays: number= 0;
  paymentTerms: string = '';
  picPath: string = ''; -->

<mat-dialog-content class="mat-typography">
  <div class="supplier-dialog">
    <div class="two-column">
      <div>
        <div class="supplier-dialog__image-file">
          <div
            *ngIf="!supplier.picPath"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 70px;
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-photo"
              width="44"
              height="44"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="15" y1="8" x2="15.01" y2="8" />
              <rect x="4" y="4" width="16" height="16" rx="3" />
              <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
              <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
            </svg>
            No Image
          </div>
          <div
            class="supplier-dialog__image-container"
            *ngIf="supplier.picPath"
          >
            <img
              style="max-width: 100%; max-height: 100%"
              [src]="sanitize(supplier.picPath)"
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
          <input
            type="file"
            #uploadImage
            style="display: none"
            accept="image/*"
            multiple
            (change)="onUploadChange(uploadImage.files)"
          />
          <button
            class="riva-button"
            (click)="uploadImage.click()"
            style="display: flex; align-items: center; justify-content: center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-upload"
              width="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              style="margin-right: 10px"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
              <polyline points="7 9 12 4 17 9" />
              <line x1="12" y1="4" x2="12" y2="16" />
            </svg>
            Upload
          </button>
        </div>
      </div>
      <div>
        <div>
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Supplier ID</mat-label>
            <input matInput [(ngModel)]="supplier.supplierId" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Company Name</mat-label>
            <input matInput [(ngModel)]="supplier.companyName" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Contact Name</mat-label>
            <input matInput [(ngModel)]="supplier.contactName" />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Email Address</mat-label>
            <input matInput [(ngModel)]="supplier.emailAddress" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="three-column">
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Shipping Method</mat-label>
          <input matInput [(ngModel)]="supplier.shippingMethod" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Lead Time (Days)</mat-label>
          <input matInput [(ngModel)]="supplier.leadtimeDays" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Payment Terms</mat-label>
          <input matInput [(ngModel)]="supplier.paymentTerms" />
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Address 1</mat-label>
        <input matInput [(ngModel)]="supplier.address1" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field style="width: 100%" appearance="fill">
        <mat-label>Address 2</mat-label>
        <input matInput [(ngModel)]="supplier.address2" />
      </mat-form-field>
    </div>
    <div class="two-column">
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>City</mat-label>
          <input matInput [(ngModel)]="supplier.city" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Region</mat-label>
          <input matInput [(ngModel)]="supplier.region" />
        </mat-form-field>
      </div>
    </div>
    <div class="two-column">
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Postal Code</mat-label>
          <input matInput [(ngModel)]="supplier.postalCode" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Country</mat-label>
          <input matInput [(ngModel)]="supplier.country" />
        </mat-form-field>
      </div>
    </div>
    <div class="two-column">
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Phone</mat-label>
          <input matInput [(ngModel)]="supplier.phone" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label>Fax</mat-label>
          <input matInput [(ngModel)]="supplier.fax" />
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px"
>
  <button
    class="btn btn-default"
    mat-dialog-close
    style="font-size: 12px; padding: 5px; height: 40px; color: white"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    style="font-size: 12px; padding: 5px; height: 40px"
    [disabled]="isSaving"
    (click)="onSave()"
  >
    Save
  </button>
</mat-dialog-actions>
