import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductSku } from '../models/sku';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class SkuService extends GenericService {
  baseUrl = environment.apiUrl + 'sku';

  constructor(http: HttpClient) {
    super(http);
  }

  getProductSku(productId: number): Observable<ProductSku[]> {
    return this.http.get<ProductSku[]>(`${this.baseUrl}/product/${productId}`, {
      headers: this.headers,
    });
  }
  setProductSku(payload: ProductSku): Observable<any> {
    return this.http.post(`${this.baseUrl}/product`, payload, {
      headers: this.headers,
    });
  }

  searchSku(customerSku: string, customerId: number): Observable<ProductSku[]> {
    return this.http.get<ProductSku[]>(`${this.baseUrl}/search`, {
      headers: this.headers,
      params: {
        customerSku,
        customerId,
      },
    });
  }
}
