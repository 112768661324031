import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Customers } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { FindingsService } from 'src/app/services/findings.service';
import { FINDINGS_TYPE } from '../../constants';
import { Finding } from '../../model';

@Component({
  templateUrl: './tags-dialog.component.html',
  styleUrls: ['./tags-dialog.component.scss'],
})
export class TagsDialogComponent implements OnInit {
  isSaving = false;
  tag: Finding = {} as Finding;
  customers: Customers[] = [];
  tagName = '';

  constructor(
    public dialogRef: MatDialogRef<TagsDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { tags: Finding[] },

    private toastrService: ToastrService,
    private findingsService: FindingsService,
    private customerService: CustomerService,
  ) {}

  ngOnInit(): void {
    this.customerService.getList().subscribe((result) => {
      this.customers = result;
      this.tag.customerId =
        this.customers.find((c) => c.customerId.toLowerCase() === 'riva')
          ?.custIdno ?? 0;
    });
  }

  onSaveFinding() {
    // const isExist = this.data.tags.some((j) => {
    //   const { size_1, size_2, size_3 } = this.tag;
    //   return (
    //     j.size_1 === size_1 &&
    //     j.size_2 === (size_2 ?? 0) &&
    //     j.size_3 === (size_3 ?? 0)
    //   );
    // });
    // if (isExist) {
    //   this.toastrService.error('Size already exist.', 'Tags');
    //   return;
    // }
    this.isSaving = true;
    this.findingsService
      .setFindingsSizes({
        ...this.tag,
        findingsTypesID: FINDINGS_TYPE.TAGS,
      })
      .subscribe((id) => {
        this.findingsService
          .setFinding({
            findingsId: 0,
            commentBox: '',
            findingSizesId: id,
            customerId: this.tag.customerId,
            name: this.tagName,
          })
          .subscribe(() => {
            this.isSaving = false;
            this.toastrService.success('Successfully saved.', 'Tags');
            this.dialogRef.close(true);
          });
      });
  }

  get isFormValid() {
    const { size_1, size_2, size_3 } = this.tag;
    const scenario1 =
      this.tagName &&
      size_1 &&
      size_1 < 100 &&
      size_2 &&
      size_2 < 100 &&
      size_3 &&
      size_3 < 100;
    return scenario1;
  }
}
