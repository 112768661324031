import { HttpEventType } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { STONE_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/stone-permission';
import { Suppliers } from 'src/app/models/suppliers.model';
import { ConfirmationService } from '../../riva-confirmation/riva-confirmation.service';
import { StoneDialogComponent } from '../dialogs/stone-dialog/stone-dialog.component';
import { StoneImagesDialogComponent } from '../dialogs/stone-images-dialog/stone-images-dialog.component';
import { StoneInventoryAdjustmentDialogComponent } from '../dialogs/stone-inventory-adjustment-dialog/stone-inventory-adjustment-dialog.component';
import { StoneSizeDialogComponent } from '../dialogs/stone-size-dialog/stone-size-dialog.component';
import { StoneUpdaterDialogComponent } from '../dialogs/stone-updater-dialog/stone-updater-dialog.component';
import {
  RivaGems,
  StoneClarity,
  StoneMovementStockTracking,
  StoneSize,
  StoneType,
} from '../riva-gems.model';
import { StoneService } from '../stone.service';
import { StoneCut } from './../riva-gems.model';

@Component({
  selector: 'gems-details',
  templateUrl: './gems-details.component.html',
  styleUrls: ['./gems-details.component.scss'],
})
export class GemsDetailsComponent implements OnInit, OnChanges {
  @Input() selectedGem: RivaGems;
  @Input() gems: RivaGems[];
  @Input() editMode: boolean = false;
  @Output() onSetEditMode = new EventEmitter();
  @Output() onSelectGem = new EventEmitter();

  @ViewChild('confirmationDuplicateStone')
  public readonly confirmationDuplicateStone!: SwalComponent;

  stoneSizes: StoneSize[];
  stoneTypes: StoneType[];
  stoneCuts: StoneCut[];
  stoneClarities: StoneClarity[];
  suppliers: Suppliers[];
  imageFiles: FileList | null = null;
  stoneFeatureKey = STONE_FEATURE_KEY;
  stoneStockTracking: StoneMovementStockTracking;

  constructor(
    public dialog: MatDialog,
    private stoneService: StoneService,
    private sanitizer: DomSanitizer,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {}
  ngOnChanges(): void {
    if (this.selectedGem.stonesID) {
      this.stoneStockTracking = { ...this.selectedGem.stoneTracking };
    }
  }

  getStoneMovementDetails() {
    this.stoneService
      .getStoneMovementStockTracking(this.selectedGem.stonesID)
      .subscribe((detail) => {
        this.stoneStockTracking = detail;
      });
  }

  onSaveStone() {
    this.stoneService.setStone(this.selectedGem).subscribe(({ stonesID }) => {
      this.stoneService.reloadStones(stonesID);
      this.onSetEditMode.emit(false);
    });
  }
  onEdit() {
    this.onSetEditMode.emit(true);
  }
  onCancel() {
    this.stoneService.reSelectStone(this.selectedGem.stonesID);
    this.onSetEditMode.emit(false);
  }
  newGem() {
    const dialogRef = this.dialog.open(StoneDialogComponent, {
      disableClose: true,
      maxWidth: '1000px',
      width: '100%',
      data: {},
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {});
  }

  updateStone() {
    const dialogRef = this.dialog.open(StoneUpdaterDialogComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      data: {
        stone: this.selectedGem,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {});
  }

  onOpenStoneImageDialog() {
    const dialogRef = this.dialog.open(StoneImagesDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
      data: {},
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      this.stoneService.reloadStones(this.selectedGem.stonesID);
    });
  }

  uploadImage() {
    const formData = new FormData();
    for (let i = 0; i < this.imageFiles.length; i++) {
      formData.append('file[]', this.imageFiles[i]);
    }
    formData.append('stoneId', this.selectedGem.stonesID.toString());
    this.stoneService.uploadPicture(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' + Math.round((100 * event.loaded) / event.total) + '%',
        );
      } else if (event.type === HttpEventType.ResponseHeader) {
        this.stoneService.reloadStones(this.selectedGem.stonesID);
      }
    });
  }

  newSize() {
    const dialogRef = this.dialog.open(StoneSizeDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
      data: {},
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.stoneService.getSizes().subscribe((sizes) => {
          this.stoneSizes = sizes;
        });
      }
    });
  }

  onUploadChange(files: FileList) {
    this.imageFiles = files;
    this.selectedGem.picturePath = URL.createObjectURL(files[0]);
    this.uploadImage();
  }
  get currentImage() {
    return this.selectedGem.picturePath
      ? this.sanitizer.bypassSecurityTrustUrl(this.selectedGem.picturePath)
      : '';
  }

  createOppositeLabVersion() {
    const {
      stoneTypesID,
      stoneCutsID,
      stoneClaritiesID,
      stoneSizesID,
      stoneColorsID,
      avgCarat,
      labGrown,
      traceable,
      caratsPerUnit,
      stockQTY,
    } = this.selectedGem;
    this._confirmationService
      .showConfirmation({
        title: 'Copy a stone?',
        content: `Are you sure you want to create a copy of this stone with a ${
          labGrown ? 'nat version' : 'lab version'
        }?`,
        confirmLabel: labGrown ? 'Make Nat Version' : 'Make Lab Version',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        const isExist = this.gems.some(
          (gem) =>
            gem.stoneTypesID === stoneTypesID &&
            gem.stoneCutsID === stoneCutsID &&
            gem.stoneClaritiesID === stoneClaritiesID &&
            gem.stoneSizesID === stoneSizesID &&
            gem.stoneColorsID === stoneColorsID &&
            gem.avgCarat === avgCarat &&
            gem.labGrown === !labGrown &&
            gem.traceable === traceable,
        );
        if (isExist) {
          this.confirmationDuplicateStone.fire();
          return;
        }
        this.stoneService
          .setStone({
            stoneTypesID,
            stoneCutsID,
            stoneClaritiesID,
            stoneSizesID,
            stoneColorsID,
            caratsPerUnit,
            stockQTY,
            labGrown: !labGrown,
            traceable,
          })
          .subscribe(({ stonesID }) => {
            this.stoneService.reloadStones(stonesID);
          });
      });
  }

  onStoneInventoryAdjustmentDialogOpen() {
    if (!this.selectedGem.stonesID) return;
    this.dialog.open(StoneInventoryAdjustmentDialogComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
      data: {
        stone: this.selectedGem,
      },
      autoFocus: false,
    });
  }
}
