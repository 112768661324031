import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FindingsSkuGeneratorDialogComponent } from '../findings-sku-generator-dialog/findings-sku-generator-dialog.component';
import { Finding } from '../model';

interface FindingSkuDetail {
  findingsId: number;
  findingSizeId: number;
  materialCodeId: number;
}

@Component({
  selector: 'finding-sku-generator-button',
  templateUrl: './findings-sku-generator-button.component.html',
})
export class FindingsSkuGeneratorButtonComponent implements OnInit {
  @Input() findings: Finding[];
  @Input() findingsTypeId: number;
  @Input() findingsId?: number;
  @Input() findingSizeId?: number;
  @Input() materialCodeId?: number;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  onOpenGenerateSkuDialog() {
    this.dialog.open(FindingsSkuGeneratorDialogComponent, {
      disableClose: true,
      maxWidth: '800px',
      width: '100%',
      data: {
        findings: this.findings,
        findingsTypeId: this.findingsTypeId,
        findingsId: this.findingsId,
        findingSizeId: this.findingSizeId,
        materialCodeId: this.materialCodeId,
      },
      autoFocus: false,
    });
  }
}
