import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  OtherComponent,
  OtherComponentMaterial,
  OtherComponentRouting,
  OtherComponentSize,
  OtherComponentType,
} from '../components/other-components/model';
import { OrderDetailAlt } from '../components/riva-chain/riva-chain-finish/models';
import { GenericService } from './generic.service';

export const getSizeLabel = ({
  dimension_1,
  dimension_2,
  dimension_3,
  dimension_4,
  dimension_5,
}: OtherComponentSize) => {
  const dimensions = [];
  if (dimension_1) dimensions.push(`${dimension_1}mm`);
  if (dimension_2) dimensions.push(`${dimension_2}mm`);
  if (dimension_3) dimensions.push(`${dimension_3}mm`);
  if (dimension_4) dimensions.push(`${dimension_4}mm`);
  if (dimension_5) dimensions.push(`${dimension_5}mm`);
  return dimensions.join(' x ');
};

@Injectable({
  providedIn: 'root',
})
export class OtherComponentsService extends GenericService {
  baseUrl = `${environment.apiUrl}other-components`;
  typeUrl = `${environment.apiUrl}other-components/types`;
  materialUrl = `${environment.apiUrl}other-components/materials`;
  routingUrl = `${environment.apiUrl}other-components/routing`;
  sizeUrl = `${environment.apiUrl}other-components/sizes`;
  orderDetailUrl = `${environment.apiUrl}order-details-alt`;

  constructor(http: HttpClient) {
    super(http);
  }

  getOtherComponents(typeId = 0) {
    return this.http
      .get<OtherComponent[]>(this.baseUrl, {
        headers: this.headers,
        params: {
          typeId,
        },
      })
      .pipe(
        map((data) =>
          data.map((d) => ({
            ...d,
            uom: d.unitOfMeasure?.uom,
            typeDisplay: d.types?.type,
          })),
        ),
      );
  }
  setOtherComponent(data) {
    return this.http.post(this.baseUrl, data, { headers: this.headers });
  }

  getOtherComponentsTypes() {
    return this.http.get<OtherComponentType[]>(this.typeUrl, {
      headers: this.headers,
    });
  }

  getOtherComponentsMaterials(otherComponentId: number) {
    return this.http.get<OtherComponentMaterial[]>(
      `${this.materialUrl}/${otherComponentId}`,
      {
        headers: this.headers,
      },
    );
  }

  setOtherComponentMaterial(data) {
    return this.http.post(this.materialUrl, data, { headers: this.headers });
  }

  deleteOtherComponentMaterial(otherComponentsMaterialsID) {
    return this.http.delete(
      `${this.materialUrl}/${otherComponentsMaterialsID}`,
      {
        headers: this.headers,
      },
    );
  }

  uploadImage(object: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/upload`, object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
      responseType: 'text' as 'json',
    });
  }

  getOtherComponentsRoutings(otherComponentId: number) {
    return this.http.get<OtherComponentRouting[]>(
      `${this.routingUrl}/${otherComponentId}`,
      {
        headers: this.headers,
      },
    );
  }
  setOtherComponentsRoutings(data) {
    return this.http.post(this.routingUrl, data, {
      headers: this.headers,
    });
  }
  deleteOtherComponentsRoutings(otherComponentsRoutingId) {
    return this.http.delete(`${this.routingUrl}/${otherComponentsRoutingId}`, {
      headers: this.headers,
    });
  }
  getOtherComponentsSizes(otherComponentId) {
    return this.http
      .get<OtherComponentSize[]>(`${this.sizeUrl}/${otherComponentId}`, {
        headers: this.headers,
      })
      .pipe(
        map((sizes) =>
          sizes.map((size) => ({
            ...size,
            label: getSizeLabel(size),
          })),
        ),
      );
  }
  setOtherComponentsSizes(data) {
    return this.http.post(this.sizeUrl, data, {
      headers: this.headers,
    });
  }
  deleteOtherComponentsSize(otherComponentsSizesID) {
    return this.http.delete(`${this.sizeUrl}/${otherComponentsSizesID}`, {
      headers: this.headers,
    });
  }
  setOrderDetail(order: OrderDetailAlt) {
    return this.http.post(this.orderDetailUrl, order, {
      headers: this.headers,
    });
  }
}
