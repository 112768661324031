import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  state(
    '*',
    style({
      opacity: 1,
    }),
  ),
  transition('void <=> *', [animate('0.2s ease-in-out')]),
]);
