import { PAGE_NAME } from './pages';

export const OTHER_COMPONENT_FEATURE_KEY = {
  create: 'OTHER_COMPONENT_CREATE',
  edit: 'OTHER_COMPONENT_EDIT',
};

export const otherComponentPermission = {
  [PAGE_NAME.otherComponents]: {
    SuperUser: {
      [OTHER_COMPONENT_FEATURE_KEY.create]: 'access',
      [OTHER_COMPONENT_FEATURE_KEY.edit]: 'access',
    },
    User: {
      [OTHER_COMPONENT_FEATURE_KEY.create]: 'access',
      [OTHER_COMPONENT_FEATURE_KEY.edit]: 'access',
    },
    Viewer: {
      [OTHER_COMPONENT_FEATURE_KEY.create]: 'disabled',
      [OTHER_COMPONENT_FEATURE_KEY.edit]: 'disabled',
    },
  },
};
