<div id="main">
  <div class="row" style="margin: 20px">
    <div class="col-8">
      <div class="row">
        <div class="col">
          <span style="color: white"
            >LAST UPDATE BY : {{ lastUpdated.username }} &#47; DATE :
            {{ formatDate(lastUpdated.date) }}</span
          >
        </div>
      </div>

      <div class="row search-panel">
        <div class="col-6">
          <input
            [(ngModel)]="searchString"
            (keyup)="searchTimeout()"
            class="form-control search-text search-field"
            type="text"
            placeholder="SEARCH"
            [disabled]="editMode"
          />
        </div>

        <div class="col-1" style="color: white; line-height: 50px">
          Items: {{ totalFindings }}
        </div>
      </div>

      <div class="table-row row">
        <div
          id="findingsTable"
          #productTable
          class="table-container"
          [ngClass]="{ 'table-collapsed': tableCollapsed }"
          (clickOutside)="tableActive = false"
          (click)="tableActive = true"
        >
          <div class="overlay"></div>
          <table class="table">
            <thead style="height: 40px !important">
              <tr class="col">
                <th scope="col" (click)="onSort('manufacturedMaterialsId')">
                  <i
                    class="fa"
                    [ngClass]="sortClass('manufacturedMaterialsId')"
                  ></i>
                  ID
                </th>
                <th scope="col" (click)="onSort('name')">
                  <i class="fa" [ngClass]="sortClass('name')"></i> NAME
                </th>
                <th scope="col" (click)="onSort('sku')">
                  <i class="fa" [ngClass]="sortClass('sku')"></i> SKU
                </th>
                <th scope="col" (click)="onSort('suppliersId')">
                  <i class="fa" [ngClass]="sortClass('suppliersId')"></i>
                  SUPPLIER
                </th>
              </tr>
            </thead>
            <tbody *ngIf="tableCollapsed === false && !editMode">
              <tr
                *ngFor="let item of manufacturedMats"
                class="row-data"
                [ngClass]="{
                  'row-data-active':
                    item.manufacturedMaterialsId ===
                    manufacturedMat.manufacturedMaterialsId,
                  'color-band': applyColorBand(item)
                }"
                (click)="getManufacturedMat(item)"
              >
                <td scope="row">{{ item.manufacturedMaterialsId }}</td>
                <td class="sku" scope="row">{{ item.name }}</td>
                <td scope="row">{{ item.sku }}</td>
                <td class="sku" scope="row">
                  {{ getSupplierName(item.suppliersId) }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="tableCollapsed === false && editMode">
              <tr
                *ngFor="let item of manufacturedMats"
                class="row-data"
                [ngClass]="{
                  'row-data-active':
                    item.manufacturedMaterialsId ===
                    manufacturedMat.manufacturedMaterialsId
                }"
              >
                <td scope="row">{{ item.manufacturedMaterialsId }}</td>
                <td class="sku" scope="row">{{ item.name }}</td>
                <td scope="row">{{ item.sku }}</td>
                <td class="sku" scope="row">
                  {{ getSupplierName(item.suppliersId) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="form-row">
        <div class="col-6">
          <div class="form-row">
            <div class="col">
              <div class="card text-white bg-dark mb-3 border-light">
                <div class="card-header border-light text-center">
                  ITEM NAME &#47; SKU
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="riva-input form-control"
                          [(ngModel)]="manufacturedMat.name"
                          placeholder="NAME"
                          [disabled]="!editMode"
                        />
                        <div class="tool-tip">
                          <div class="arrow-down"></div>
                          NAME
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <div class="col-sm-12">
                        <input
                          type="text"
                          class="riva-input form-control"
                          [(ngModel)]="manufacturedMat.sku"
                          placeholder="SKU"
                          [disabled]="!editMode"
                        />
                        <div class="tool-tip">
                          <div class="arrow-down"></div>
                          SKU
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="card text-white bg-dark mb-3 border-light">
                <div class="card-header border-light text-center">
                  SUPPLIER &#47; UNIT OF MEASURE
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <div class="col-sm-12">
                        <select
                          [(ngModel)]="manufacturedMat.suppliersId"
                          class="riva-input custom-select"
                          [(value)]="manufacturedMat.suppliersId"
                          [disabled]="!editMode"
                        >
                          <option
                            *ngFor="let sups of suppliers"
                            [value]="sups.suppliersId"
                          >
                            {{ sups.supplierId }}
                          </option>
                        </select>

                        <div class="tool-tip">
                          <div class="arrow-down"></div>
                          SUPPLIER CODE
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <div class="col-sm-12">
                        <select
                          [(ngModel)]="this.manufacturedMat.unitsOfMeasureId"
                          class="riva-input custom-select"
                          [(value)]="selectedUOM"
                          [disabled]="!editMode"
                        >
                          <option
                            *ngFor="let unit of uom"
                            [value]="unit.unitsOfMeasureId"
                          >
                            {{ unit.uom }}
                          </option>
                        </select>
                        <div class="tool-tip">
                          <div class="arrow-down"></div>
                          UOM
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="card text-white bg-dark mb-3 border-light">
                <div class="card-header border-light text-center">COMMENTS</div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md">
                      <div class="col-sm-12">
                        <textarea
                          id="descbox"
                          [(ngModel)]="manufacturedMat.commentBox"
                          [disabled]="!editMode"
                        ></textarea>

                        <div class="tool-tip">
                          <div class="arrow-down"></div>
                          COMMENT
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group row mat-size-container">
            <div class="col-sm-4">
              <label>Materials</label>
              <div class="mat-table">
                <div
                  class="mat-row"
                  *ngFor="let item of MatList"
                  [ngClass]="{
                    'mat-size': selectedMaterialCode == item,
                    'selected-mat-size': highlightSize
                  }"
                  (click)="
                    selectedMaterialCode = item;
                    getSelectedManufacturedMatsInfo();
                    highlightSizes(item)
                  "
                >
                  <span *ngIf="editMode">*</span>{{ getMaterialDesc(item) }}
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <label>Sizes</label>

              <div class="size-table">
                <div
                  class="size-row"
                  *ngFor="let item of SizeList"
                  [ngClass]="{
                    'mat-size': selectedSize == item,
                    'selected-mat-size': highlightMats
                  }"
                  (click)="
                    selectedSize = item;
                    getSelectedManufacturedMatsInfo();
                    highlightMaterials(item)
                  "
                >
                  {{ item }}
                </div>
              </div>

              <div
                *ngIf="editMode"
                class="product-detail-button-container mt-1 row"
              >
                <button
                  class="btn-confirm col-12"
                  (click)="createMatSizeOpen(materialCreate)"
                >
                  Add Materials
                </button>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-4">
                  <label>WGT (GT)</label>
                </div>
                <div class="col-sm-8">
                  <input
                    type="text"
                    [(ngModel)]="selectedManufacturedMatsInfo.weight"
                    class="riva-input form-control"
                    placeholder=""
                    [disabled]="
                      !editMode ||
                      selectedManufacturedMatsInfo.manufacturedMaterialsInfoId ==
                        undefined
                    "
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <label>QTY</label>
                </div>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="riva-input form-control"
                    placeholder=""
                    [(ngModel)]="selectedManufacturedMatsInfo.stockQty"
                    [disabled]="
                      !editMode ||
                      selectedManufacturedMatsInfo.manufacturedMaterialsInfoId ==
                        undefined
                    "
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <label>SUP SKU</label>
                </div>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="riva-input form-control"
                    placeholder=""
                    [(ngModel)]="selectedManufacturedMatsInfo.supplierSku"
                    [disabled]="
                      !editMode ||
                      selectedManufacturedMatsInfo.manufacturedMaterialsInfoId ==
                        undefined
                    "
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <label>PRICE_$</label>
                </div>
                <div class="col-sm-8">
                  <input
                    type="number"
                    class="riva-input form-control"
                    placeholder=""
                    [(ngModel)]="selectedManufacturedMatsInfo.supplierPrice"
                    [disabled]="
                      !editMode ||
                      selectedManufacturedMatsInfo.manufacturedMaterialsInfoId ==
                        undefined
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 form-group-sm p-5">
      <!-- <div class="form-row text-center">
          <img src="{{this.manufacturedMat.picPath}}" width="230" height="230" class="mx-auto d-block">
        </div> -->

      <div class="form-row">
        <div class="form-group col-md">
          <div class="form-group product-image-container">
            <img src="{{ manufacturedMat.picPath }}" />
            <input
              type="file"
              #uploadImage
              (change)="uploadTemp(uploadImage.files)"
              style="display: none"
            />
            <button
              *ngIf="newMode || editMode"
              class="btn-confirm btn-upload-photo"
              (click)="uploadImage.click()"
            >
              UPLOAD
            </button>
          </div>
        </div>
      </div>

      <div class="form-row mt-1">
        <div class="col-md-6">
          <button
            *ngIf="!editMode"
            class="btn-confirm"
            (click)="toggleEdit()"
            [disabled]="editMode"
          >
            EDIT
          </button>
          <button *ngIf="editMode" class="btn-confirm" (click)="toggleCancel()">
            CANCEL
          </button>
        </div>
        <div class="col-md-6">
          <button class="btn-confirm" (click)="save()" [disabled]="!editMode">
            SAVE
          </button>
        </div>
      </div>

      <div class="form-row mt-1">
        <div class="col-md-12">
          <button
            class="btn-confirm"
            (click)="newProduct()"
            [disabled]="editMode || newMode"
          >
            + New
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template #materialCreate let-modal>
    <div class="product-detail-popup">
      <div class="modal-header row">
        <h5 class="modal-title col-10" id="modal-basic-title">
          Create Material and Sizes
        </h5>
        <button
          type="button"
          class="close col-2"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <div class="input-group">
            <label>Material Code</label>

            <select
              [(ngModel)]="modalMaterialCode"
              class="custom-select search-field"
            >
              <option
                *ngFor="let mats of materialCodes"
                [value]="mats.materialCodeId"
              >
                {{ mats.description }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <label>SIZE</label>
            <input
              [(ngModel)]="modalSize"
              type="text"
              class="riva-input form-control"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-confirm"
          (click)="modal.close('Save click')"
        >
          Save
        </button>
      </div>
    </div>
  </ng-template>
</div>
