<h3 mat-dialog-title>Components</h3>

<mat-dialog-content class="mat-typography product-component">
  <div class="card-container">
    <nz-tabset nzType="card" [(nzSelectedIndex)]="selectedIndex">
      <nz-tab nzTitle="Enamel">
        <div class="product-component__tab-content">
          <product-enamel [productId]="props.productId"></product-enamel>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Findings">
        <div class="product-component__tab-content">
          <product-findings [productId]="props.productId"></product-findings>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Stones">
        <div class="product-component__tab-content">
          <stone-product
            [productId]="props.productId"
            (onCloseDialog)="onCloseDialog()"
          ></stone-product>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Chains">
        <div class="product-component__tab-content">
          <riva-chain-product-container
            [productId]="props.productId"
          ></riva-chain-product-container>
        </div>
      </nz-tab>
      <nz-tab nzTitle="Product">
        <div class="product-component__tab-content">
          <product-link-bom [productId]="props.productId"></product-link-bom>
        </div>
      </nz-tab>
    </nz-tabset>
  </div>
</mat-dialog-content>

<mat-dialog-actions style="display: flex; justify-content: center">
  <div
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      width: 350px;
    "
  >
    <button
      class="btn btn-danger"
      style="font-size: 12px; padding: 5px; height: 40px"
      mat-dialog-close
    >
      Close
    </button>
    <button
      class="btn btn-success"
      style="font-size: 12px; padding: 5px; height: 40px"
      [disabled]="!isSaveEnabled"
      (click)="onSave()"
    >
      Save
    </button>
  </div>
</mat-dialog-actions>
