<div class="product-material-dialog">
  <h3 mat-dialog-title>Multi Material Variant</h3>
  <mat-dialog-content class="mat-typography product-setting-dialog__container">
    <div class="dialog-form">
      <mat-form-field
        appearance="fill"
        style="width: 100%; grid-column: 1 / span 2"
      >
        <mat-label>Material</mat-label>
        <mat-select
          [(ngModel)]="productMaterial.materialsCodeID"
          [disabled]="!data.editMode"
        >
          <mat-option
            *ngFor="let mats of materialCodes"
            [value]="mats.materialCodeId"
          >
            {{ mats.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="search-input"
        appearance="fill"
        style="width: 100%"
      >
        <mat-label>Percentage</mat-label>
        <input
          matInput
          type="number"
          min="0"
          [(ngModel)]="productMaterial.percentage1"
          [disabled]="!data.editMode"
        />
      </mat-form-field>
      <mat-form-field
        class="search-input"
        appearance="fill"
        style="width: 100%"
      >
        <mat-label>Percentage</mat-label>
        <input
          matInput
          type="number"
          min="0"
          [(ngModel)]="productMaterial.percentage2"
          [disabled]="!data.editMode"
        />
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    style="
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 10px;
    "
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onSave()"
      [disabled]="isSaving || !data.editMode || !isFormValid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
