import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { NzTabsModule } from 'ng-zorro-antd/tabs';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListComponent } from './list/list.component';

import { SharedModule } from 'src/app/shared modules/shared.module';
import { AppMaterialModule } from './../../shared modules/app-material/app-material.module';
import { NewOrderdetailsItemComponent } from './new-orderdetails-item/new-orderdetails-item.component';
import { OrderdetailsItemComponent } from './orderdetails-item/orderdetails-item.component';
import { RivaChainOrderComponent } from './riva-chain-order/riva-chain-order.component';
import { RivaFindingOrderAddItemComponent } from './riva-findings-order/riva-findings-order-add-item/riva-findings-order-add-item.component';
import { RivaFindingOrderAddComponent } from './riva-findings-order/riva-findings-order-add/riva-findings-order-add.component';
import { RivaOtherComponentOrderComponent } from './riva-other-component-order/riva-other-component-order.component';

@NgModule({
  declarations: [
    ListComponent,
    OrderdetailsItemComponent,
    NewOrderdetailsItemComponent,
    RivaChainOrderComponent,
    RivaFindingOrderAddComponent,
    RivaFindingOrderAddItemComponent,
    RivaOtherComponentOrderComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NzTabsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ListComponent],
})
export class OrderdetailsModule {}
