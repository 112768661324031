<div class="product-routing product-routing__container">
  <div class="product-routing__header" *ngIf="!readonly">
    <mat-form-field class="search-input" appearance="fill" style="width: 400px">
      <mat-label>Search product routing code to add</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="selectedProductRouting"
        [formControl]="productRoutingControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayFn"
      >
        <mat-option class="riva-select-header riva-select__stick-header">
          <div class="riva-select">
            <div class="p5 riva-select__code">Activity Code</div>
            <div class="p5 riva-select__department">Department</div>
            <div class="p5 riva-select__description">Description</div>
          </div>
        </mat-option>
        <mat-option class="riva-select-header">
          <div class="riva-select">
            <div class="p5 riva-select__code">Activity Code</div>
            <div class="p5 riva-select__department">Department</div>
            <div class="p5 riva-select__description">Description</div>
          </div>
        </mat-option>
        <mat-option
          *ngFor="let productRoutingCode of filteredProductRoutingCodes | async"
          style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
          (click)="onSelectProductRoutingCode(productRoutingCode)"
        >
          <div class="riva-select">
            <div class="p5 riva-select__code">
              {{ productRoutingCode.activityCode }}
            </div>
            <div class="p5 riva-select__department">
              {{ productRoutingCode.department }}
            </div>
            <div class="p5 riva-select__description">
              {{ productRoutingCode.activityDesc }}
            </div>
          </div>
        </mat-option>
        <mat-option *ngIf="!(filteredProductRoutingCodes | async)?.length">
          <div class="text-center riva-select__not-found">Not Found</div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div>
      <button type="button" class="riva-button" (click)="onCopyRoutingOpen()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-copy"
          width="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          style="margin-right: 3px"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="8" y="8" width="12" height="12" rx="2" />
          <path
            d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"
          />
        </svg>
        Copy From
      </button>
    </div>
  </div>

  <div class="container-row routing-header">
    <div class="container-column"></div>
    <div class="container-column">Activity Code</div>
    <div class="container-column">Department</div>
    <div class="container-column">Description</div>
    <div class="container-column">Standard Time</div>
    <div class="container-column">Comment</div>
    <div class="container-column"></div>
  </div>
  <div class="table-container" cdkScrollable>
    <div
      cdkDropList
      [cdkDropListData]="productRoutings"
      (cdkDropListDropped)="dropTable($event)"
    >
      <div class="container-row" *ngFor="let routing of productRoutings">
        <div class="container-column flex-center">
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
            style="width: 25px; cursor: move"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
            cdkDrag
            cdkDragHandle
            cdkDragRootElement=".container-row"
            [cdkDragData]="routing"
            cdkDragLockAxis="y"
            (cdkDropListDropped)="dropTable($event)"
            *ngIf="!readonly"
          >
            <path
              fill="white"
              d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            ></path>
          </svg>
        </div>
        <div class="container-column flex-middle">
          {{ routing.routingCode?.activityCode }}
        </div>
        <div class="container-column flex-middle">
          {{ routing.routingCode?.department }}
        </div>
        <div class="container-column flex-middle">
          {{ routing.routingCode?.activityDesc }}
        </div>
        <div
          class="container-column"
          style="display: flex; align-items: center"
        >
          <span *ngIf="readonly">{{ routing.standardTime }}</span>
          <nz-input-number
            [(ngModel)]="routing.standardTime"
            [nzMin]="0"
            [nzStep]="1"
            style="width: 100%"
            *ngIf="!readonly"
          ></nz-input-number>
        </div>
        <div
          class="container-column"
          style="display: flex; align-items: center"
        >
          <span *ngIf="readonly">{{ routing.comment }}</span>
          <input nz-input [(ngModel)]="routing.comment" *ngIf="!readonly" />
        </div>
        <div class="container-column flex-center">
          <i
            nz-icon
            nzType="delete"
            nzTheme="fill"
            class="variation-delete"
            (click)="onDeleteProductRouting(routing)"
            *ngIf="!readonly"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div class="container-empty" *ngIf="!productRoutings.length">No Data</div>
</div>
