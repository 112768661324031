import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ChainService } from 'src/app/services/chain.service';
import { OrderChainSize, OrderChainStock } from '../models';

interface ChainFinishedSkuDetail {
  chainId: number;
  materialCodeId: number;
  chainSizeId: number;
}

@Component({
  templateUrl: './chain-finished-sku-generator-dialog.component.html',
  styleUrls: ['./chain-finished-sku-generator-dialog.component.scss'],
})
export class ChainFinishedSkuGeneratorDialogComponent implements OnInit {
  chainSkuDetail: ChainFinishedSkuDetail = {} as ChainFinishedSkuDetail;
  chainStocks: OrderChainStock[] = [];
  chainSizes: OrderChainSize[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      chainId: number;
      materialCodeId?: number;
      chainSizeId?: number;
    },
    public dialogRef: MatDialogRef<ChainFinishedSkuGeneratorDialogComponent>,
    private chainService: ChainService,
  ) {}

  ngOnInit(): void {
    this.chainSkuDetail.chainId = this.data.chainId;
    this.chainSkuDetail.materialCodeId = this.data.materialCodeId;
    this.chainSkuDetail.chainSizeId = this.data.chainSizeId;
    this.getChainMaterials();
    this.getChainSizes();
  }

  getChainMaterials() {
    this.chainService
      .getChainFinishedMaterials(this.chainSkuDetail.chainId)
      .pipe(
        map((mats) =>
          mats.map((mat) => ({
            chainFinishedStockID: mat.chainFinishedMaterialsID,
            materialCodesID: mat.materialsCodeID,
            material: mat.material,
          })),
        ),
      )
      .subscribe((data = []) => {
        this.chainStocks = data;
        if (data.length === 1) {
          this.chainSkuDetail.materialCodeId = data[0].materialCodesID;
        }
      });
  }

  getChainSizes() {
    this.chainService
      .getChainFinishedSizes(this.chainSkuDetail.chainId)
      .pipe(
        map((sizes) =>
          sizes.map((size) => ({
            chainSizeId: size.chainFinishedSizesID,
            size: size.size,
          })),
        ),
      )
      .subscribe((data = []) => {
        this.chainSizes = data;
        if (data.length === 1) {
          this.chainSkuDetail.chainSizeId = data[0].chainSizeId;
        }
      });
  }

  getGeneratedSku() {
    const chainId = String(this.data.chainId ?? 0).padStart(7, '0');
    const materialCode = String(
      this.chainSkuDetail.materialCodeId ?? 0,
    ).padStart(3, '0');
    const sizeId = String(this.chainSkuDetail.chainSizeId ?? 0).padStart(
      8,
      '0',
    );

    return `CHNF-${chainId}-${materialCode}-${sizeId}`;
  }

  onCopySku() {
    navigator.clipboard.writeText(this.getGeneratedSku());
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }
}
