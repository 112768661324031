<div class="customer-dialog-header">
  <h3 mat-dialog-title style="margin-bottom: 0">Customer Settings</h3>
</div>
<mat-dialog-content class="mat-typography">
  <div class="customer-setting-dialog">
    <mat-slide-toggle
      style="margin-top: 14px"
      [(ngModel)]="customer.hwDisclaimFlag"
      >Harry Winston Disclaimer</mat-slide-toggle
    >
    <mat-slide-toggle
      style="margin-top: 14px"
      [(ngModel)]="customer.dontAllowMixedInvoices"
      >Don’t Allow Mixed Invoices</mat-slide-toggle
    >
  </div>
</mat-dialog-content>

<mat-dialog-actions
  style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px"
>
  <button
    class="btn btn-default"
    mat-dialog-close
    style="font-size: 12px; padding: 5px; height: 40px; color: white"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    style="font-size: 12px; padding: 5px; height: 40px"
    [disabled]="isSaving"
    (click)="onSave()"
  >
    Save
  </button>
</mat-dialog-actions>
