import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FindingsService } from 'src/app/services/findings.service';
import { FINDINGS_TYPE } from '../../constants';
import { Finding } from '../../model';

@Component({
  templateUrl: './lobster-claw-dialog.component.html',
  styleUrls: ['./lobster-claw-dialog.component.scss'],
})
export class LobsterClawDialogComponent {
  isSaving = false;
  lobsterClaw: Finding = {} as Finding;
  lobsterClawName = '';

  constructor(
    public dialogRef: MatDialogRef<LobsterClawDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { lobsterClaws: Finding[] },

    private toastrService: ToastrService,
    private findingsService: FindingsService,
  ) {}

  onSaveLobsterClaw() {
    this.isSaving = true;
    this.findingsService
      .setFindingsSizes({
        ...this.lobsterClaw,
        findingsTypesID: FINDINGS_TYPE.LOBSTER_CLAW,
      })
      .subscribe((id) => {
        this.findingsService
          .setFinding({
            findingsId: 0,
            commentBox: '',
            findingSizesId: id,
            name: this.lobsterClawName,
          })
          .subscribe(() => {
            this.isSaving = false;
            this.toastrService.success('Successfully saved.', 'Lobster Claw');
            this.dialogRef.close(true);
          });
      });
  }

  get isFormValid() {
    const { size_1 } = this.lobsterClaw;

    return this.lobsterClawName && size_1 && size_1 < 100;
  }
}
