<div class="spring-dialog">
  <h3 mat-dialog-title>New Size</h3>
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill">
      <mat-label>Spring height</mat-label>
      <input matInput type="number" [(ngModel)]="spring.size_1" max="99.99" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Wire Thickness</mat-label>
      <input matInput type="number" [(ngModel)]="spring.size_2" max="99.99" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Spring Angle</mat-label>
      <input matInput type="number" [(ngModel)]="spring.size_3" max="99.99" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Hole Inside Diameter</mat-label>
      <input matInput type="number" [(ngModel)]="spring.size_4" max="99.99" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Comment</mat-label>
      <textarea
        matInput
        [(ngModel)]="comment"
        style="height: 42px; color: white"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        [disabled]="isSaving || !isFormValid"
        (click)="onSaveSpring()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
