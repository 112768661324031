import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { FindingsService } from 'src/app/services/findings.service';
import { FindingSettingDialogComponent } from './finding-setting-dialog/finding-setting-dialog.component';
import { Finding, FindingsStock, FindingsType } from './model';

const findingsTabSlugByIndex = {
  0: 'jumprings',
  1: 'posts',
  2: 'flyers',
  3: 'springs',
  4: 'spring-rings',
  5: 'lobster-claws',
  6: 'tags',
};
const findingsTabIndexBySlug = {
  jumprings: 0,
  posts: 1,
  flyers: 2,
  springs: 3,
  'spring-rings': 4,
  'lobster-claws': 5,
  tags: 6,
};

@Component({
  selector: 'riva-findings',
  templateUrl: './riva-findings.component.html',
  styleUrls: ['./riva-findings.component.scss'],
})
export class RivaFindingsComponent implements OnInit {
  selectedTabIndex = 0;
  findings: Finding[] = [];
  findingsStocks: FindingsStock[] = [];
  findingsTypes: Record<number, FindingsType> = {} as Record<
    number,
    FindingsType
  >;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private userPermissionService: UserPermissionService,
    private findingsService: FindingsService,
    public dialog: MatDialog,
  ) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.finding);

    this.route.params.subscribe((params: { slug: string }) => {
      this.selectedTabIndex = findingsTabIndexBySlug[params.slug] ?? 0;
    });
  }

  ngOnInit(): void {
    this.getFindingsSize();
    this.getFindingsTypes();
  }

  getFindingsTypes() {
    this.findingsService.getFindingsTypes().subscribe((record) => {
      this.findingsTypes = record;
    });
  }

  getFindingsSize() {
    this.findingsService.getFindingList().subscribe((data) => {
      this.findings = data ?? [];
    });
  }
  getFindingsStock() {
    this.findingsService.getFindingsStocks().subscribe((data) => {
      this.findingsStocks = data ?? [];
    });
  }

  onTabChange(event) {
    const slug = findingsTabSlugByIndex[event];
    this.location.replaceState(`items/findings/${slug}`);
  }

  onOpenSetting(typeId) {
    const findingsType = this.findingsTypes[typeId];
    const ref = this.dialog.open(FindingSettingDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
      data: {
        findingsType,
      },
      autoFocus: false,
    });
    ref.afterClosed().subscribe((reload) => {
      reload && this.getFindingsTypes();
    });
  }
}
