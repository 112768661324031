import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProductDetail, ProductEnamel } from 'src/app/models/product';
import { ProductSku } from 'src/app/models/sku';
import { EnamelService } from 'src/app/services/enamel.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { ProductService } from 'src/app/services/product.service';
import { SkuService } from 'src/app/services/sku.service';
import { ProductOrderChain } from '../../riva-chain/riva-chain-product/model';
import { ProductStone } from '../../riva-gems/riva-gems.model';

interface ProductSkuDetail {
  productId: number;
  materialCodeId: number;
  productSizeId: number;
  enamelId?: number;
  stoneId?: number;
  chainTypeId?: number;
  chainId?: number;
}

@Component({
  templateUrl: './product-sku-generator-dialog.component.html',
  styleUrls: ['./product-sku-generator-dialog.component.scss'],
})
export class ProductSkuGeneratorDialogComponent implements OnInit {
  productSkuDetail: ProductSkuDetail = {} as ProductSkuDetail;
  productDetail: ProductDetail = {
    materials: [],
    productSizes: [],
  } as ProductDetail;
  productEnamelVariations: ProductEnamel[] = [];
  productStoneVariationControl = new FormControl();
  productStoneVariations: ProductStone[] = [];
  productStoneVariation: ProductStone = {} as ProductStone;
  filteredProductStoneVariation: Observable<ProductStone[]>;

  productChains: ProductOrderChain[] = [];
  productChain: ProductOrderChain = {} as ProductOrderChain;
  productChainControl = new FormControl();
  filteredProductChains: Observable<ProductOrderChain[]>;
  placeholderCustomerSku = '';
  productSkus: ProductSku[] = [];
  selectedProductSku: ProductSku = {} as ProductSku;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productId: number;
      materialCodeId?: number;
      productSizeId?: number;
    },
    public dialogRef: MatDialogRef<ProductSkuGeneratorDialogComponent>,
    private productService: ProductService,
    private productBomService: ProductBomService,
    private enamelService: EnamelService,
    private skuService: SkuService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.productSkuDetail.materialCodeId = this.data.materialCodeId;
    this.productSkuDetail.productSizeId = this.data.productSizeId;
    this.productService
      .getProductDetail(this.data.productId)
      .subscribe((data) => {
        this.productDetail = data;
        if (data.materials.length === 1) {
          this.productSkuDetail.materialCodeId =
            data.materials[0]?.materialsCodeID;
        }
        if (data.productSizes.length === 1) {
          this.productSkuDetail.productSizeId =
            data.productSizes[0]?.productsSizesID;
        }
        setTimeout(() => {
          this.getProductSku();
        }, 1000);
      });
    this.getProductEnamelVariations(this.data.productId);
    this.getProductStone(this.data.productId);
    this.getProductChain(this.data.productId);
  }

  getProductSku() {
    this.skuService.getProductSku(this.data.productId).subscribe((data) => {
      this.productSkus = data;
      this.getSelectedSku();
    });
  }

  getSelectedSku() {
    this.placeholderCustomerSku = '';
    this.selectedProductSku =
      this.productSkus.find(
        (sku) =>
          sku.productsMaterialsID ===
            (this.productSkuDetail.materialCodeId ?? 0) &&
          sku.productsSizesID === (this.productSkuDetail.productSizeId ?? 0) &&
          sku.productsBOMEnamelID === (this.productSkuDetail.enamelId ?? 0) &&
          sku.productsBOMStonesID ===
            (this.productStoneVariation.productsBOMStonesID ?? 0) &&
          ((this.productChain.chainType &&
            sku.productsBOMChainFinishedID ===
              (this.productChain.chainProductsID ?? 0)) ||
            (!this.productChain.chainType &&
              sku.productsBOMChainRawID ===
                (this.productChain.chainProductsID ?? 0))),
      ) ?? ({} as ProductSku);
    if (this.selectedProductSku?.productsCustSKUsID == null) {
      this.getCustomerSkuPlaceholder();
    }
  }

  getCustomerSkuPlaceholder() {
    let productSkus = this.productSkus;
    let skus = productSkus.filter(
      (sku) =>
        sku.productsMaterialsID === (this.productSkuDetail.materialCodeId ?? 0),
    );
    if (skus.length > 0) {
      productSkus = skus;
    } else {
      productSkus = productSkus.filter((sku) => sku.productsMaterialsID === 0);
    }
    if (productSkus.length === 0) {
      return;
    }

    skus = productSkus.filter(
      (sku) =>
        sku.productsSizesID === (this.productSkuDetail.productSizeId ?? 0),
    );
    if (skus.length > 0) {
      productSkus = skus;
    } else {
      productSkus = productSkus.filter((sku) => sku.productsSizesID === 0);
    }
    if (productSkus.length === 0) {
      return;
    }

    skus = productSkus.filter(
      (sku) =>
        sku.productsBOMEnamelID === (this.productSkuDetail.enamelId ?? 0),
    );
    if (skus.length > 0) {
      productSkus = skus;
    } else {
      productSkus = productSkus.filter((sku) => sku.productsBOMEnamelID === 0);
    }
    if (productSkus.length === 0) {
      return;
    }

    skus = productSkus.filter(
      (sku) =>
        sku.productsBOMStonesID ===
        (this.productStoneVariation.productsBOMStonesID ?? 0),
    );
    if (skus.length > 0) {
      productSkus = skus;
    } else {
      productSkus = productSkus.filter((sku) => sku.productsBOMStonesID === 0);
    }
    if (productSkus.length === 0) {
      return;
    }

    skus = productSkus.filter(
      (sku) =>
        (this.productChain.chainType &&
          sku.productsBOMChainFinishedID ===
            (this.productChain.chainProductsID ?? 0)) ||
        (!this.productChain.chainType &&
          sku.productsBOMChainRawID ===
            (this.productChain.chainProductsID ?? 0)),
    );
    if (skus.length > 0) {
      productSkus = skus;
    } else {
      productSkus = productSkus.filter(
        (sku) =>
          (this.productChain.chainType &&
            sku.productsBOMChainFinishedID === 0) ||
          (!this.productChain.chainType && sku.productsBOMChainRawID === 0),
      );
    }
    if (productSkus.length === 0) {
      return;
    }
    this.placeholderCustomerSku = productSkus?.[0]?.customerSKU;
  }

  getProductEnamelVariations(productId) {
    forkJoin([
      this.enamelService.getList(),
      this.productBomService.getProductEnamel(productId),
    ]).subscribe(([enamels, productEnamels]) => {
      this.productEnamelVariations = productEnamels.reduce(
        (variations, item) => {
          if (!item.variation) return variations;
          const enamel =
            enamels.find((e) => e.enamelId === item.enamelID) ?? {};
          return [...variations, { ...item, ...enamel }];
        },
        [],
      );

      if (this.productEnamelVariations.length === 1) {
        this.productSkuDetail.enamelId =
          this.productEnamelVariations[0].enamelID;
      }
    });
  }

  getProductStone(productId) {
    this.productBomService.getProductStones(productId).subscribe((data) => {
      this.productStoneVariations = data.filter((stone) => stone.variation);

      if (this.productStoneVariations.length === 1) {
        this.productStoneVariation = this.productStoneVariations[0];
      }
      this.filteredProductStoneVariation =
        this.productStoneVariationControl.valueChanges.pipe(
          startWith(this.productStoneVariation?.stone),
          map((value) => this._filterProductStoneVariation(value)),
        );
    });
  }

  displayProductStone(item: ProductStone): string {
    const labels = [];
    if (item?.stone?.stoneType?.name) labels.push(item.stone.stoneType.name);
    if (item?.stone?.stoneColor?.colorName)
      labels.push(item.stone.stoneColor.colorName);
    if (item?.stone?.stoneSize?.displayText)
      labels.push(item.overrideStoneSize || item.stone.stoneSize.displayText);
    return labels.join(' | ');
  }

  private _filterProductStoneVariation(name: string): ProductStone[] {
    if (name && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.productStoneVariations.filter(
        (option) =>
          option.stone?.stoneType?.name?.toLowerCase().includes(filterValue) ||
          option.stone?.stoneColor?.colorName
            ?.toLowerCase()
            .includes(filterValue) ||
          option.stone?.stoneSize?.displayText
            ?.toLowerCase()
            .includes(filterValue),
      );
    } else if (typeof name === 'object' && name['productName']) {
      const filterValue: string = name['productName'];
      return this.productStoneVariations.filter(
        (option) =>
          option.stone?.stoneType?.name
            .toLowerCase()
            .includes(filterValue?.toLowerCase()) ||
          option.stone?.stoneColor?.colorName
            .toLowerCase()
            .includes(filterValue?.toLowerCase()) ||
          option.stone?.stoneSize?.displayText
            .toLowerCase()
            .includes(filterValue?.toLowerCase()),
      );
    }
    return this.productStoneVariations;
  }

  displayProductChain(item: ProductOrderChain): string {
    if (!item?.chainProductsID) return '';
    const labels = [];
    labels.push(item.chainType ? 'Finished' : 'Unfinished');
    if (item?.name) labels.push(item.name);
    if (item?.size) labels.push(item.size);
    return labels.join(' | ');
  }

  getProductChain(productId) {
    forkJoin([
      this.productBomService.getProductChainRaw(productId),
      this.productBomService.getProductChainFinished(productId),
    ]).subscribe(([raw, finished]) => {
      const rawMapped = raw.map((r) => ({
        chainProductsID: r.productsBOMChainRawID,
        chainType: false,
        name: r.chainRaw?.chainStyle?.styleName,
        size: r.lengthInches.toString(),
      }));
      const finishedMapped = finished.map((f) => ({
        chainProductsID: f.productsBOMChainFinishedID,
        chainType: true,
        name: f.chainRaw?.chainStyle?.styleName,
        size: f.chainFinishedSizes.size,
      }));
      this.productChains = [...finishedMapped, ...rawMapped];

      this.filteredProductChains = this.productChainControl.valueChanges.pipe(
        startWith(this.productChain.name),
        map((value) => this._filterProductChain(value)),
      );
      if (this.productChains.length === 1) {
        this.productChain = this.productChains[0];
      }
    });
  }
  private _filterProductChain(name: string): ProductOrderChain[] {
    if (name && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.productChains.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue) ||
          option.size?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object' && name['name']) {
      const filterValue: string = name['name'];
      return this.productChains.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue) ||
          option.size?.toLowerCase().includes(filterValue),
      );
    }
    return this.productChains;
  }

  getGeneratedProductSku() {
    const productId = String(this.data.productId ?? 0).padStart(7, '0');
    const materialCode = String(
      this.productSkuDetail.materialCodeId ?? 0,
    ).padStart(3, '0');
    const productSizeId = String(
      this.productSkuDetail.productSizeId ?? 0,
    ).padStart(8, '0');
    const enamelId = String(this.productSkuDetail.enamelId ?? 0).padStart(
      4,
      '0',
    );
    const productStoneId = String(
      this.productStoneVariation.productsBOMStonesID ?? 0,
    ).padStart(8, '0');
    const productChainId = String(
      this.productChain.chainProductsID ?? 0,
    ).padStart(8, '0');

    return `PROD-${productId}-${materialCode}-${productSizeId}-${enamelId}-${productStoneId}-${
      this.productChain.chainType ? '1' : '0'
    }${productChainId}`;
  }

  onCopySku() {
    navigator.clipboard.writeText(this.getGeneratedProductSku());
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }

  saveProductSku() {
    const payload = {
      productsCustSKUsID: this.selectedProductSku?.productsCustSKUsID ?? 0,
      productsID: this.data.productId,
      productsMaterialsID: this.productSkuDetail.materialCodeId ?? 0,
      productsSizesID: this.productSkuDetail.productSizeId ?? 0,
      productsBOMEnamelID: this.productSkuDetail.enamelId ?? 0,
      productsBOMStonesID: this.productStoneVariation.productsBOMStonesID ?? 0,
      productsBOMChainFinishedID: this.productChain.chainType
        ? this.productChain.chainProductsID
        : 0,
      productsBOMChainRawID: this.productChain.chainType
        ? 0
        : this.productChain.chainProductsID,
      customerSKU: this.selectedProductSku.customerSKU,
    };
    this.skuService.setProductSku(payload).subscribe(() => {
      this.toastrService.success('Successfully saved.', 'Customer SKU');
      this.getProductSku();
    });
  }
}
