import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxGalleryAnimation, NgxGalleryImageSize } from '@kolkov/ngx-gallery';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ProductImage } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';
import { ProductImageOrderDialogComponent } from '../product-image-order-dialog/product-image-order-dialog.component';
import { ConfirmationService } from './../../riva-confirmation/riva-confirmation.service';

@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss'],
})
export class ProductImageComponent implements OnInit, OnChanges {
  @Input() editMode: boolean;
  @Input() productId: number;

  @ViewChild('errorProductImage')
  public readonly errorProductImage!: SwalComponent;

  isLoading = false;
  currentImage: { index: number; image: ProductImage | null };
  error = {
    title: '',
    description: '',
  };
  productImages: ProductImage[] = [];
  galleryOptions = [
    {
      width: '100%',
      height: '400px',
      thumbnailsColumns: 4,
      imageAnimation: NgxGalleryAnimation.Slide,
      arrowPrevIcon: 'fa fa-chevron-circle-left',
      arrowNextIcon: 'fa fa-chevron-circle-right',
      preview: true,
      previewCloseOnClick: true,
      previewCloseOnEsc: true,
      previewZoom: true,
      previewZoomMax: 10,
      previewZoomStep: 1,
      previewKeyboardNavigation: true,
      previewDownload: true,
      imageSize: NgxGalleryImageSize.Contain,
    },
  ];

  constructor(
    public dialog: MatDialog,
    private productService: ProductService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.productService.reloadProductImages = () => {
      this.getProductImages();
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productId?.currentValue === changes.productId?.previousValue)
      return;
    if (!this.productId) {
      this.productImages = [];
      return;
    }
    this.getProductImages();
  }

  getProductImages() {
    this.isLoading = true;
    this.productService
      .getProductImageList(this.productId)
      .subscribe((data) => {
        this.productImages = data.map(({ fileName, ...info }) => ({
          ...info,
          fileName,
          small: fileName,
          medium: fileName,
          big: fileName,
        }));
        this.currentImage = { index: 0, image: this.productImages[0] };
        this.isLoading = false;
      });
  }
  onImageChange($event) {
    this.currentImage = $event;
  }
  onMakePrimaryPicture() {
    this.productImages = [
      this.currentImage.image,
      ...this.productImages.filter(
        (p) => p.productsPicsID !== this.currentImage.image.productsPicsID,
      ),
    ];
    this.currentImage = { index: 0, image: this.productImages[0] };
    const imageReorder = this.productImages.map((p, i) => ({
      ...p,
      picsOrder: i + 1,
    }));
    this.productService
      .setProductImages(this.productId, imageReorder)
      .subscribe(
        () => {},
        () => {
          this.error = {
            title: 'Product Picture',
            description:
              'There is an error while making this picture as primary. Please try again later.',
          };
          this.showErrorMessage();
          this.getProductImages();
        },
      );
  }
  showErrorMessage() {
    setTimeout(() => {
      this.errorProductImage.fire();
    }, 100);
  }
  onDeleteProductImage() {
    this.confirmationService
      .showConfirmation({
        title: 'Delete Picture',
        content: 'Are you sure you want to delete this picture?',
        confirmLabel: 'Delete',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          const id = this.currentImage.image?.productsPicsID;
          if (!id) return;
          this.productImages = this.productImages.filter(
            (p) => p.productsPicsID !== id,
          );
          this.currentImage = { index: 0, image: this.productImages[0] };
          this.productService.deleteProductImages(id).subscribe(
            () => {},
            () => {
              this.error = {
                title: 'Product Picture',
                description: 'There is an error while deleting this picture.',
              };
              this.showErrorMessage();
              this.getProductImages();
            },
          );
        }
      });
  }

  openReorderDialog() {
    const dialogRef = this.dialog.open(ProductImageOrderDialogComponent, {
      disableClose: true,
      maxWidth: '550px',
      width: '100%',
      data: {
        productId: this.productId,
        productImages: this.productImages,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((newProductImages: ProductImage[]) => {
      if (newProductImages && newProductImages.length) {
        this.productImages = newProductImages;
      }
    });
  }
}
