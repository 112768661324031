import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ChainService } from 'src/app/services/chain.service';
import { OrderChainStock } from '../../riva-chain-finish/models';

interface ChainUnfinishedSkuDetail {
  chainId: number;
  materialCodeId: number;
}

@Component({
  templateUrl: './chain-unfinished-sku-generator-dialog.component.html',
  styleUrls: ['./chain-unfinished-sku-generator-dialog.component.scss'],
})
export class ChainUnfinishedSkuGeneratorDialogComponent implements OnInit {
  chainSkuDetail: ChainUnfinishedSkuDetail = {} as ChainUnfinishedSkuDetail;
  chainStocks: OrderChainStock[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      chainId: number;
      materialCodeId?: number;
    },
    public dialogRef: MatDialogRef<ChainUnfinishedSkuGeneratorDialogComponent>,
    private chainService: ChainService,
  ) {}

  ngOnInit(): void {
    this.chainSkuDetail.chainId = this.data.chainId;
    this.chainSkuDetail.materialCodeId = this.data.materialCodeId;
    this.getChainStock();
  }

  getChainStock() {
    this.chainService
      .getChainRawStock(this.data.chainId)
      .pipe(
        map((stock) =>
          stock.map((stock) => ({
            chainFinishedStockID: stock.chainRawStockID,
            materialCodesID: stock.materialCodesID,
            material: stock.material,
          })),
        ),
      )
      .subscribe((data = []) => {
        this.chainStocks = data;
        if (data.length === 1) {
          this.chainSkuDetail.materialCodeId = data[0].materialCodesID;
        }
      });
  }

  getGeneratedSku() {
    const chainId = String(this.data.chainId ?? 0).padStart(7, '0');
    const materialCode = String(
      this.chainSkuDetail.materialCodeId ?? 0,
    ).padStart(3, '0');

    return `CHNR-${chainId}-${materialCode}`;
  }

  onCopySku() {
    navigator.clipboard.writeText(this.getGeneratedSku());
  }

  onCloseDialog() {
    this.dialogRef.close(false);
  }
}
