<!-- Editable table -->
<div id="divMain">
  <div style="padding: 10px">
    <div class="card text-white bg-dark mb-3 border-light">
      <div class="card-header border-light text-center">
        GEM - TYPES &#47; CUTS &#47; SIZES &#47; PRICE
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-2">
            <!--first box-->
            <div class="row">
              <div class="col-3"></div>

              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <div class="table-container">
                      <table class="table">
                        <thead style="height: 40px !important">
                          <tr class="col">
                            <th scope="col"><i class="fa"></i>TYPES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let item of gemTypes"
                            class="row-data"
                            [ngClass]="{
                              'row-data-active':
                                item.gemTypesId === selectedType.gemTypesId
                            }"
                            (click)="getSelectedType(item); getHighlightedCut()"
                          >
                            <td scope="row">{{ item.colorClarity }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div class="row">
              <div class="col-3"></div>

              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <div class="table-container">
                      <table class="table">
                        <thead style="height: 40px !important">
                          <tr class="col">
                            <th scope="col"><i class="fa"></i>CUTS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let item of gemCuts"
                            class="row-data"
                            [ngClass]="{
                              'row-data-active':
                                item.gemCutsId === selectedCut.gemCutsId,
                              'selected-cut': getHighlightedCutbyCut(item)
                            }"
                            (click)="getSelectedCut(item)"
                          >
                            <td scope="row">{{ item.cutName }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div class="row">
              <div class="col-3"></div>

              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <div class="table-container">
                      <table class="table">
                        <thead style="height: 40px !important">
                          <tr class="col">
                            <th scope="col"><i class="fa"></i>SIZES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let item of gemSizes"
                            class="row-data"
                            [ngClass]="{
                              'row-data-active':
                                item.gemSizesId === selectedSize.gemSizesId,
                              'selected-cut': getHighlightedSizebyCut(item)
                            }"
                            (click)="getSelectedSize(item)"
                          >
                            <td scope="row">{{ item.gemSizeDesc }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-3"></div>
              <div class="col-9">
                <div *ngIf="isAuthorized" class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button
                      id="btnaddsize"
                      class="btn btn-outline-secondary"
                      type="button"
                      (click)="addsize()"
                    >
                      Add Size
                    </button>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="new size"
                    aria-label=""
                    aria-describedby="basic-addon1"
                    [(ngModel)]="newSize"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="row ml-2">
              <div class="col-8">
                <div class="row pricelabel">
                  <div class="form-group row">
                    <label for="supplier" class="col-sm-3 col-form-label"
                      >Supplier</label
                    >
                    <label for="supplier" class="col-sm-1 col-form-label"
                      >:</label
                    >
                    <div class="col-sm-6">
                      <select
                        [(ngModel)]="selectedInventory.suppliersId"
                        class="form-control col-8"
                        [(value)]="selectedInventory.suppliersId"
                        id="supplier"
                        [disabled]="!isAuthorized"
                      >
                        <option
                          *ngFor="let sups of suppliers"
                          [value]="sups.suppliersId"
                        >
                          {{ sups.supplierId }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="priceperkarat" class="col-sm-3 col-form-label"
                      >Price Per Karat</label
                    >
                    <label for="priceperkarat" class="col-sm-1 col-form-label"
                      >:</label
                    >
                    <div class="col-sm-6">
                      <input
                        type="number"
                        class="form-control col-8"
                        id="priceperkarat"
                        placeholder="Price Per Karat"
                        [(ngModel)]="selectedInventory.pricePerCarat"
                        [disabled]="!isAuthorized"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="priceperunit" class="col-sm-3 col-form-label"
                      >Price Per Unit</label
                    >
                    <label for="priceperunit" class="col-sm-1 col-form-label"
                      >:</label
                    >

                    <div class="col-sm-6">
                      <input
                        type="number"
                        class="form-control col-8"
                        id="priceperunit"
                        placeholder="Price Per Unit"
                        [(ngModel)]="selectedInventory.pricePerUnit"
                        [disabled]="!isAuthorized"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="qtyinstock" class="col-sm-3 col-form-label"
                      >Qty in Stock</label
                    >
                    <label for="qtyinstock" class="col-sm-1 col-form-label"
                      >:</label
                    >
                    <div class="col-sm-6">
                      <input
                        type="number"
                        class="form-control col-8"
                        id="qtyinstock"
                        placeholder="Qty in Stock"
                        [(ngModel)]="selectedInventory.stockQty"
                        [disabled]="!isAuthorized"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="weight" class="col-sm-3 col-form-label"
                      >Weight</label
                    >
                    <label for="weight" class="col-sm-1 col-form-label"
                      >:</label
                    >
                    <div class="col-sm-6">
                      <input
                        type="number"
                        class="form-control col-8"
                        id="weight"
                        placeholder="Weight"
                        [(ngModel)]="selectedInventory.caratWeight"
                        [disabled]="!isAuthorized"
                      />
                    </div>
                  </div>

                  <div
                    class="form-group row d-flex justify-content-center text-center"
                  >
                    <div class="col-sm-6">
                      <button
                        type="button"
                        class="btn btn-info col-8"
                        (click)="saveInventory()"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--maindiv-->
