import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvoiceService } from 'src/app/services/invoice.service';
import { RelatedShipment } from './../../models/related-shipment';

@Component({
  selector: 'related-shipment-dialog',
  templateUrl: './related-shipment-dialog.component.html',
  styleUrls: ['./related-shipment-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RelatedShipmentDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'shipmentsID',
    'shippedDate',
    'qty',
    'trackingNumber',
  ];
  relatedShipments: RelatedShipment[] = [];

  constructor(
    private invoiceService: InvoiceService,
    public dialogRef: MatDialogRef<RelatedShipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { invoiceId: number },
  ) {}

  ngOnInit(): void {
    this.invoiceService
      .getRelatedShipments(this.props.invoiceId)
      .subscribe((relatedShipments) => {
        this.relatedShipments = relatedShipments;
      });
  }
}
