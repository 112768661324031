<div class="gems-list">
  <div class="filter-forms">
    <mat-form-field class="search-input" appearance="fill" style="color: white">
      <mat-label>Search by size</mat-label>
      <input matInput [(ngModel)]="searchText" (ngModelChange)="onFilter()" />
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Filter by type</mat-label>
      <mat-select
        [(ngModel)]="filter.stoneTypesID"
        (ngModelChange)="onFilter()"
      >
        <mat-option *ngFor="let type of stoneTypes" [value]="type.stoneTypesID">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Filter by cut</mat-label>
      <mat-select [(ngModel)]="filter.stoneCutsID" (ngModelChange)="onFilter()">
        <mat-option *ngFor="let cut of stoneCuts" [value]="cut.stoneCutsID">
          {{ cut.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Filter by clarity</mat-label>
      <mat-select
        [(ngModel)]="filter.stoneClaritiesID"
        (ngModelChange)="onFilter()"
      >
        <mat-option
          *ngFor="let clarity of stoneClarities"
          [value]="clarity.stoneClaritiesID"
        >
          {{ clarity.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Filter by color</mat-label>
      <mat-select
        [(ngModel)]="filter.stoneColorsID"
        (ngModelChange)="onFilter()"
      >
        <mat-option
          *ngFor="let color of stoneColors"
          [value]="color.stoneColorsID"
        >
          {{ color.colorName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Filter by lab grown</mat-label>
      <mat-select [(ngModel)]="filter.labGrown" (ngModelChange)="onFilter()">
        <mat-option [value]="-1"> All </mat-option>
        <mat-option [value]="1"> Yes </mat-option>
        <mat-option [value]="0"> No </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Filter by traceable</mat-label>
      <mat-select [(ngModel)]="filter.traceable" (ngModelChange)="onFilter()">
        <mat-option [value]="-1"> All </mat-option>
        <mat-option [value]="1"> Yes </mat-option>
        <mat-option [value]="0"> No </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="filter.hideOutOfStock"
      (ngModelChange)="onFilter()"
      style="margin-top: 14px; color: white"
      >Hide Zero Inventory</mat-slide-toggle
    >
  </div>
  <div class="table-header-container">
    <button
      class="btn riva-button"
      [disabled]="isExportingFile"
      (click)="onDownloadExcelFile()"
    >
      <span>Export to Excel</span>
    </button>
    <mat-paginator
      [pageSizeOptions]="[10, 25, 50, 100]"
      showFirstLastButtons
      style="
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      "
    ></mat-paginator>
  </div>
  <div class="gems-table-container">
    <table
      mat-table
      matSort
      [dataSource]="gems"
      matSortActive="stonesID"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="pic">
        <th mat-header-cell *matHeaderCellDef>Pic</th>
        <td mat-cell *matCellDef="let element">
          <div
            style="
              width: 50px;
              padding: 5px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            "
            *ngIf="element.picturePath"
          >
            <img
              style="max-width: 100%; max-height: 100%"
              [src]="element.picturePath"
              onerror="this.src='assets/images/no-image.png'"
            />
            <span
              class="img-custom-work-order work-order-table"
              *ngIf="element.customized"
              >CUSTOM</span
            >
          </div>
          <div
            style="
              width: 50px;
              padding: 5px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            "
            *ngIf="!element.picturePath"
          >
            <img
              style="max-width: 100%; max-height: 100%"
              src="assets/images/no-image.png"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by type"
        >
          Type
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.type ?? element.stoneType?.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cut">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by cut"
        >
          Cut
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.cut ?? element.stoneCut?.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="clarity">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by clarity"
        >
          Clarity
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.clarity ?? element.stoneClarity?.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="color">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by color"
        >
          Color
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.color ?? element.stoneColor?.colorName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sizeD1">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by sizeD1"
        >
          Size
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.size ?? element.stoneSize.displayText }}
        </td>
      </ng-container>
      <ng-container matColumnDef="caratsPerUnit">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by caratsPerUnit"
        >
          Carat Per Stone
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.caratsPerUnit }}
        </td>
      </ng-container>
      <ng-container matColumnDef="pricePerUnit">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by pricePerUnit"
        >
          PPU
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.pricePerUnit | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="qtyInSTock">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by qtyInSTock"
        >
          Qty
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.qtyInSTock }}
        </td>
      </ng-container>
      <ng-container matColumnDef="labGrown">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by labGrown"
        >
          Lab Grown
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.labGrown ? 'Yes' : 'No' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="traceable">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="asc"
          sortActionDescription="Sort by traceable"
        >
          Traceable
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.traceable ? 'Yes' : 'No' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="gems-row"
        (click)="onTableRowClick(row)"
        [ngClass]="{
          'is-selected': row.stonesID === selectedGem?.stonesID,
          'disabled-row': editMode
        }"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="11">No Available Data</td>
      </tr>
    </table>
  </div>
</div>
