import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { FindingsService } from 'src/app/services/findings.service';
import { FINDINGS_TYPE } from '../../constants';
import { Finding } from '../../model';

@Component({
  templateUrl: './jumpring-dialog.component.html',
  styleUrls: ['./jumpring-dialog.component.scss'],
})
export class JumpringDialogComponent {
  isSaving = false;
  jumpring: Finding = {} as Finding;

  constructor(
    public dialogRef: MatDialogRef<JumpringDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { jumprings: Finding[] },

    private toastrService: ToastrService,
    private findingsService: FindingsService,
  ) {}

  onSaveJumpring() {
    const isExist = this.data.jumprings.some((j) => {
      const { size_1, size_2, size_3 } = this.jumpring;
      return (
        j.size_1 === size_1 &&
        j.size_2 === (size_2 ?? 0) &&
        j.size_3 === (size_3 ?? 0)
      );
    });
    if (isExist) {
      this.toastrService.error('Size already exist.', 'Jumpring');
      return;
    }
    this.isSaving = true;
    this.findingsService
      .setFindingsSizes({
        ...this.jumpring,
        findingsTypesID: FINDINGS_TYPE.JUMPRINGS,
      })
      .subscribe((id) => {
        this.findingsService
          .setFinding({
            findingsId: 0,
            commentBox: '',
            findingSizesId: id,
          })
          .subscribe(() => {
            this.isSaving = false;
            this.toastrService.success('Successfully saved.', 'Jumpring');
            this.dialogRef.close(true);
          });
      });
  }

  get isFormValid() {
    const { size_1, size_2, size_3 } = this.jumpring;
    const scenario1 =
      size_1 &&
      size_1 < 100 &&
      size_2 &&
      size_2 < 100 &&
      size_3 &&
      size_3 < 100;

    const scenario2 =
      size_1 && size_1 < 100 && size_2 && size_2 < 100 && !size_3;

    const scenario3 = size_1 && size_1 < 100 && !size_2 && !size_3;
    return scenario1 || scenario2 || scenario3;
  }
}
