<div class="bill-of-material-sub-items">
  <div class="bill-of-material-sub-items__table-header">
    <button
      type="button"
      class="btn btn-success btn-new-item"
      (click)="onDialogOpen()"
      [disabled]="!productId"
    >
      New Sub Item
    </button>
  </div>
  <div class="bill-of-material-sub-items__table">
    <table
      mat-table
      [dataSource]="subItems"
      matSort
      matSortActive="productsBOMID"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="picPath">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div style="width: 70px; padding: 5px" *ngIf="element.picPath">
            <img style="width: 100%" [src]="element.picPath" />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="subItemName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by subItemName"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.subItemName }}</td>
      </ng-container>
      <ng-container matColumnDef="subItemDescription">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by subItemDescription"
        >
          Description
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.subItemDescription }}
        </td>
      </ng-container>
      <ng-container matColumnDef="notes">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by notes"
        >
          Notes
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.notes }}
        </td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by qty"
        >
          Quantity
        </th>
        <td mat-cell *matCellDef="let element">{{ element.qty }}</td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button class="btn-riva" (click)="onDialogOpen(element)">Edit</button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button class="btn-riva" (click)="onDelete(element.productsBOMID)">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="8">No Available Data</td>
      </tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10]"
      showFirstLastButtons
      hidePageSize
    ></mat-paginator>
  </div>
</div>
