<div class="enamel-variation" *ngIf="productEnamels?.length">
  <div class="enamel-variation__container">
    <div style="width: 155px">Enamel Constants</div>
    <div class="enamel-variation__color-container">
      <div class="enamel-variation__color" *ngFor="let item of productEnamels">
        <div
          style="width: 30px; height: 30px; cursor: pointer"
          [ngStyle]="{ 'background-color': item.colorHex }"
          nz-tooltip
          nzTooltipPlacement="top"
          [nzTooltipTitle]="item.displayText"
        ></div>
      </div>
    </div>
  </div>
</div>
