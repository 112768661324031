<div class="riva-date-range-picker-container">
  <div
    class="riva-date-range-picker"
    [ngClass]="{ 'single-picker': isSinglePicker }"
  >
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>{{
        isSinglePicker ? singlePickerLabel : startDateLabel
      }}</mat-label>
      <input
        matInput
        [matDatepicker]="startDatePicker"
        [(ngModel)]="start"
        (ngModelChange)="onDateRangeChange()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field
      appearance="fill"
      style="width: 100%"
      *ngIf="!isSinglePicker"
    >
      <mat-label>{{ endDateLabel }}</mat-label>
      <input
        matInput
        [matDatepicker]="endDatePicker"
        [(ngModel)]="end"
        [min]="start"
        (ngModelChange)="onDateRangeChange()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="endDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <div *ngIf="isInvalid" class="invalid-date-range">Invalid date range</div>
</div>
