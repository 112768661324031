import { HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import { ToastrService } from 'ngx-toastr';
import { MaterialCode } from 'src/app/models/material-code';
import { ChainService } from 'src/app/services/chain.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { ChainRoutingDialogComponent } from '../riva-chain-routing/chain-routing-dialog.component';
import { ChainRawDialogComponent } from './chain-raw-dialog/chain-raw-dialog.component';
import { ChainUnfinishedSkuGeneratorDialogComponent } from './chain-unfinished-sku-generator-dialog/chain-unfinished-sku-generator-dialog.component';
import { ChainRaw, ChainRawStock, ChainStyle } from './models';

@Component({
  selector: 'riva-chain',
  templateUrl: './riva-chain.component.html',
  styleUrls: ['./riva-chain.component.scss'],
})
export class RivaChainComponent implements OnInit, AfterViewInit {
  constructor(
    private chainService: ChainService,
    private materialCodeService: MaterialCodeService,
    private toastrService: ToastrService,
    public dialog: MatDialog,
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  chains = new MatTableDataSource<ChainRaw>([]);
  selectedChain: ChainRaw = {} as ChainRaw;
  imageFiles: FileList | null = null;
  displayedColumns = [
    'sku',
    'styleName',
    'linkDim1',
    'linkDim2',
    'wireSize',
    'description',
  ];
  editMode = false;
  search = '';
  materialCodes: MaterialCode[] = [];
  filteredMaterialCodes: MaterialCode[] = [];
  selectedMaterialCode: number = 0;
  chainStocks: ChainRawStock[] = [];
  selectedChainStock: ChainRawStock = {} as ChainRawStock;
  chainStyles: ChainStyle[] = [];
  filterChainStyleID = 0;

  isStockSaving = false;

  ngOnInit(): void {
    const chainId = localStorage.getItem('selected-chain-raw-id');
    this.getChains(chainId ? +chainId : null);
    localStorage.removeItem('selected-chain-raw-id');
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject.filter((m) => !m.multiMetal);
    });
    this.chainService.reloadRawChainTypes = () => {
      this.getChainStyles();
    };
    this.getChainStyles();
  }

  getChainStyles() {
    this.chainService.getChainStyles().subscribe((data) => {
      this.chainStyles = [
        {
          chainStylesID: 0,
          styleName: 'All',
        },
        ...data,
      ];
    });
  }

  ngAfterViewInit() {
    this.chains.sort = this.sort;
    this.chains.filterPredicate = (data: ChainRaw, filterValue: string) => {
      const { search = '', chainStyleId } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.sku?.toLowerCase().includes(search?.toLowerCase()) ||
        data.chainStyle?.styleName
          ?.toLowerCase()
          .includes(search?.toLowerCase());
      return (
        searchFilter &&
        (chainStyleId === 0 || data.chainStyleID === chainStyleId)
      );
    };
  }

  getChains(id = 0) {
    this.chainService.getChainRaws().subscribe((data = []) => {
      this.chains.data = data;
      if (id > 0 && data.length > 0) {
        this.selectedChain = data.find((d) => d.chainRawID === id);
      } else {
        this.selectedChain = data[0] ?? ({} as ChainRaw);
      }
      this.getChainStocks(this.selectedChain.chainRawID);
    });
  }

  getChainStocks(chainRawId) {
    this.chainService.getChainRawStock(chainRawId).subscribe((data = []) => {
      this.chainStocks = orderBy(
        data,
        (d: ChainRawStock) => d.material.code,
        'desc',
      );
      this.filteredMaterialCodes = this.materialCodes.filter(
        (m) => !data.some((c) => c.materialCodesID === m.materialCodeId),
      );
    });
  }

  onSelectChain(chain: ChainRaw) {
    this.selectedChain = { ...chain };
    this.selectedChainStock = {} as ChainRawStock;
    this.getChainStocks(this.selectedChain.chainRawID);
  }

  uploadChainRawImage(files: FileList) {
    if (files.length === 0) return;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file[]', files[i]);
    }

    formData.append('chainRawId', this.selectedChain.chainRawID.toString());

    this.chainService.chainRawUploadImage(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' + Math.round((100 * event.loaded) / event.total) + '%',
        );
      } else if (event.type === HttpEventType.Response) {
        this.selectedChain.picPath = event.body;
        this.chains.data = this.chains.data.map((c) => ({
          ...c,
          picPath:
            this.selectedChain.chainRawID === c.chainRawID
              ? event.body
              : c.picPath,
        }));
        // this.getChains(this.selectedChain.chainRawID);
        this.toastrService.success('Successfully uploaded.');
      }
    });
  }

  createNewChain() {
    const ref = this.dialog.open(ChainRawDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
    });
    ref.afterClosed().subscribe((data) => {
      this.getChains(data);
    });
  }
  onFilterChange() {
    this.chains.filter = JSON.stringify({
      search: this.search,
      chainStyleId: this.filterChainStyleID,
    });
  }
  onSetEditMode() {
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.getChains(this.selectedChain.chainRawID);
    }
  }
  onUpdateSelectedChain() {
    this.chainService.setChainRaw(this.selectedChain).subscribe();
  }
  onSelectStock(stock) {
    this.selectedChainStock = { ...stock };
  }
  onSaveChainStock(isNew = false) {
    const {
      chainRawStockID,
      chainRawID,
      materialCodesID,
      weightPerInchG = 0,
      qtyInStockInch = 0,
    } = this.selectedChainStock;
    const stock = {
      chainRawStockID: isNew ? 0 : chainRawStockID,
      materialCodesID: isNew ? this.selectedMaterialCode : materialCodesID,
      weightPerInchG: isNew ? 0 : weightPerInchG,
      qtyInStockInch: isNew ? 0 : qtyInStockInch,
      chainRawID: chainRawID ?? this.selectedChain.chainRawID,
    };
    this.isStockSaving = true;
    this.selectedMaterialCode = 0;
    this.chainService.setChainRawStock(stock).subscribe(() => {
      this.toastrService.success('Successfully saved.', 'Chain Stock');
      this.isStockSaving = false;
      this.getChainStocks(this.selectedChain.chainRawID);
    });
  }

  get isStockFormValid() {
    return (
      this.selectedChainStock.qtyInStockInch &&
      Number.isInteger(this.selectedChainStock.qtyInStockInch) &&
      this.selectedChainStock.weightPerInchG < 1000
    );
  }

  onRoutingDialogOpen() {
    this.dialog.open(ChainRoutingDialogComponent, {
      disableClose: true,
      maxWidth: '1400px',
      width: '100%',
      autoFocus: false,
      data: {
        chainId: this.selectedChain.chainRawID,
        chainType: 0,
        editMode: this.editMode,
      },
    });
  }

  onOpenGenerateSkuDialog() {
    this.dialog.open(ChainUnfinishedSkuGeneratorDialogComponent, {
      disableClose: true,
      maxWidth: '450px',
      width: '100%',
      data: {
        chainId: this.selectedChain.chainRawID,
        materialCodeId: this.selectedChainStock.materialCodesID,
      },
      autoFocus: false,
    });
  }
}
