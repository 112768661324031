import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OtherComponentsService } from 'src/app/services/other-components.service';
import { OtherComponentSize } from '../model';

@Component({
  templateUrl: './other-components-size-dialog.component.html',
  styleUrls: ['./other-components-size-dialog.component.scss'],
})
export class OtherComponentsSizeDialogComponent {
  isSaving = false;
  otherComponentSize: OtherComponentSize = {} as OtherComponentSize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { otherComponentsId: number },
    public dialogRef: MatDialogRef<OtherComponentsSizeDialogComponent>,
    private otherComponentsService: OtherComponentsService,
  ) {}

  onSave() {
    this.isSaving = true;
    this.otherComponentSize.otherComponentsID = this.data.otherComponentsId;
    this.otherComponentsService
      .setOtherComponentsSizes(this.otherComponentSize)
      .subscribe(() => {
        this.isSaving = false;
        this.dialogRef.close(true);
      });
  }

  get isFormValid() {
    const scene1 =
      this.otherComponentSize.dimension_1 > 0 &&
      !this.otherComponentSize.dimension_2 &&
      !this.otherComponentSize.dimension_3 &&
      !this.otherComponentSize.dimension_4 &&
      !this.otherComponentSize.dimension_5;
    const scene2 =
      this.otherComponentSize.dimension_1 > 0 &&
      this.otherComponentSize.dimension_2 > 0 &&
      !this.otherComponentSize.dimension_3 &&
      !this.otherComponentSize.dimension_4 &&
      !this.otherComponentSize.dimension_5;
    const scene3 =
      this.otherComponentSize.dimension_1 > 0 &&
      this.otherComponentSize.dimension_2 > 0 &&
      this.otherComponentSize.dimension_3 > 0 &&
      !this.otherComponentSize.dimension_4 &&
      !this.otherComponentSize.dimension_5;
    const scene4 =
      this.otherComponentSize.dimension_1 > 0 &&
      this.otherComponentSize.dimension_2 > 0 &&
      this.otherComponentSize.dimension_3 > 0 &&
      this.otherComponentSize.dimension_4 > 0 &&
      !this.otherComponentSize.dimension_5;
    const scene5 =
      this.otherComponentSize.dimension_1 > 0 &&
      this.otherComponentSize.dimension_2 > 0 &&
      this.otherComponentSize.dimension_3 > 0 &&
      this.otherComponentSize.dimension_4 > 0 &&
      this.otherComponentSize.dimension_5 > 0;
    return scene1 || scene2 || scene3 || scene4 || scene5;
  }
}
