import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { ProductRoutingComponent } from './product-routing.component';

@Component({
  selector: 'product-routing-dialog',
  templateUrl: './product-routing-dialog.component.html',
})
export class ProductRoutingDialogComponent implements OnInit {
  @ViewChild(ProductRoutingComponent)
  productRoutingComponent: ProductRoutingComponent;

  constructor(
    public dialogRef: MatDialogRef<ProductRoutingDialogComponent>,
    public loadService: LoadService,
    @Inject(MAT_DIALOG_DATA)
    public props: { productId: number; editMode: boolean },
  ) {}

  ngOnInit(): void {}

  onSave() {
    this.productRoutingComponent.onSaveProductRouting();
  }
}
