<div class="riva-findings-order-add-item">
  <div style="width: 100%; display: flex; justify-content: center">
    <div class="image-container">
      <img
        [src]="orderDetail.picPath"
        onerror="this.src='assets/images/no-image.png'"
      />
    </div>
  </div>
  <div>
    <div class="finding-section">
      <div class="table-container">
        <table
          mat-table
          matSort
          [dataSource]="findingsByType"
          matSortActive="findingSizesID"
          matSortDirection="desc"
          style="
            margin-bottom: 16px;
            border: 1px solid rgba(255, 255, 255, 0.12);
          "
        >
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by name"
            >
              {{ columns['name'] }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="size_1">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by size_1"
            >
              {{ columns['size_1'] }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.size_1 }}<span *ngIf="element.size_1">mm</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="size_2">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by size_2"
            >
              {{ columns['size_2'] }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.size_2 }}<span *ngIf="element.size_2">mm</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="size_3">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by size_3"
            >
              {{ columns['size_3'] }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.size_3 }}<span *ngIf="element.size_3">mm</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="size_4">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by size_4"
            >
              {{ columns['size_4'] }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.size_4 }}<span *ngIf="element.size_4">mm</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="customerName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by customerName"
            >
              Customer
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by customerName"
            >
              {{ element.customerName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="jumpringSize">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="desc"
              sortActionDescription="Sort by jumpringSize"
            >
              ID
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.jumpringSize }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="jumpring-row"
            (click)="onSelectFinding(row)"
            [ngClass]="{
              'is-selected': row.findingsId === orderDetail?.findingsID
            }"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="6">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Comment</mat-label>
      <textarea
        matInput
        style="height: 42px; color: white"
        [(ngModel)]="orderDetail.comment"
        [disabled]="!(isEditable || isOrderOnline)"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="findings-stock">
    <div class="fields-one">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Material Code</mat-label>
        <mat-select
          [(ngModel)]="orderDetail.materialCodeID"
          [disabled]="!isEditable || isOrderOnline"
        >
          <mat-option
            *ngFor="let stock of stocks"
            [value]="stock.material.materialCodeId"
          >
            {{ stock.material.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>CI-PO</mat-label>
        <input
          matInput
          [(ngModel)]="orderDetail.cipo"
          [disabled]="!isEditable || isOrderOnline"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Qty</mat-label>
        <input
          matInput
          type="number"
          style="color: white"
          min="1"
          [(ngModel)]="orderDetail.qtyordered"
          [disabled]="!isEditable || isOrderOnline"
        />
      </mat-form-field>
    </div>
    <div class="fields-two">
      <mat-form-field appearance="fill" style="width: 100%" disabled>
        <mat-label>ENTRY DATE</mat-label>
        <input
          matInput
          [matDatepicker]="entryDate"
          [(ngModel)]="orderDetail.entryDate"
          disabled
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="entryDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #entryDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>DUE DATE</mat-label>
        <input
          matInput
          [matDatepicker]="dueDate"
          [(ngModel)]="orderDetail.dueDate"
          required
          [disabled]="!(isEditable || isOrderOnline)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="dueDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #dueDate></mat-datepicker>
      </mat-form-field>
      <div></div>
      <div class="tab-action">
        <button
          class="btn btn-outline-success"
          [disabled]="
            !isFormValid || isSaving || !(isEditable || isOrderOnline)
          "
          (click)="onSaveOrder.emit()"
          style="margin-bottom: 16px"
        >
          <i class="fa fa-save"></i> Save
        </button>
        <button class="btn btn-outline-info" (click)="onCancel.emit()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
