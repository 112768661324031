import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { forkJoin } from 'rxjs';
import { CustomerAddressDialogComponent } from 'src/app/components/customer-page/customer-address-dialog/customer-address-dialog.component';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import {
  ProductStone,
  RivaGems,
} from 'src/app/components/riva-gems/riva-gems.model';
import { WorkOrderType } from 'src/app/components/work-order-view/work-order-type';
import { INVOICING_SHIPPING_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/invoicing-shipping-permission';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { CustomerAddress, Customers } from 'src/app/models/customer';
import { MaterialCode } from 'src/app/models/material-code';
import { MetalMarket } from 'src/app/models/metal-market';
import {
  CustomerService,
  formatAddress,
} from 'src/app/services/customer.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import { WorkOrdersReleaseComponent } from '../../../work-order-view/work-orders-release.component';
import { ShipmentDetail } from '../../shipping/models/shipment';
import { InvoicingPrintDialogComponent } from '../invoicing-print-dialog/invoicing-print-dialog.component';
import { Invoice, InvoiceDetail } from '../models/invoice';
import { InvoicingLookupItem } from '../models/invoicing-lookup-item';
import { MetalMarketService } from './../../../../services/metal-market.service';
import { InvoicingDetailDialogComponent } from './invoicing-detail-dialog/invoicing-detail-dialog.component';
import { RelatedShipmentDialogComponent } from './related-shipment-dialog/related-shipment-dialog.component';

const productColumns = [
  'expander',
  'productPicPath',
  'key',
  'productName',
  'material',
  'size',
  'weight',
  'rollingWeightAverage',
  'enamel',
  'chainRawLength',
  'stoneType',
  'stoneColor',
  'stoneSize',
  'chain',
  'qty',
  'poNumber',
  'cipo',
  'pricePerInch',
  'price',
  'priceExt',
  'action',
];
@Component({
  selector: 'invoicing-detail',
  templateUrl: './invoicing-detail.component.html',
  styleUrls: ['./invoicing-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class InvoicingDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmationDialog')
  public readonly confirmationDialog!: SwalComponent;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [...productColumns];
  confirmation: {
    icon: string;
    title: string;
    description: string;
    timer?: number;
  } = {
    icon: 'question',
    title: '',
    description: '',
  };
  invoiceId = 0;
  invoiceDetail: Invoice = {
    invoicedDate: '',
    customer: {},
    metalMarket: {},
    invoicesDetails: [],
    shippingCost: 0,
  } as Invoice;
  customers: Customers[];
  filteredCustomers: Customers[];
  metalMarkets: MetalMarket[];
  selectedMetalMarket: MetalMarket = {} as MetalMarket;
  customerFilterCtrl = new FormControl();
  invoiceDetails = new MatTableDataSource<InvoiceDetail>([]);
  expandedElement: InvoiceDetail | null;
  expandedInvoice: Record<number, boolean> = {};
  billingAddresses: CustomerAddress[] = [];
  invoiceUrl = '';
  featureKey = INVOICING_SHIPPING_FEATURE_KEY;
  workOrderType: WorkOrderType;
  hasStoneType = false;
  hasStoneColor = false;
  hasStoneSize = false;
  hasStoneChain = false;
  hasLength = false;
  hasEnamel = false;
  hasPricePerInch = false;
  byPassWorkOrders = false;
  generatedShipmentDetails: Array<Partial<ShipmentDetail>> = [];
  isLoading = false;
  editMode = true;
  dontAllowMixedInvoices = false;
  materialCodes: MaterialCode[];

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view === 'month') {
      const currentDate = DateTime.fromJSDate(cellDate).toFormat('yyyy-MM-dd');
      const hasMetalMarketValue = this.metalMarkets?.some(
        (m) => m.marketDate === currentDate,
      );
      return hasMetalMarketValue ? 'has-metal-market-value' : '';
    }
    return '';
  };

  metalFilter = (cellDate: Date | null): boolean => {
    const currentDate = DateTime.fromJSDate(cellDate).toFormat('yyyy-MM-dd');
    return this.metalMarkets?.some((m) => m.marketDate === currentDate);
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private invoiceService: InvoiceService,
    private customerService: CustomerService,
    private metalMarketService: MetalMarketService,
    private userPermissionService: UserPermissionService,
    private productBomService: ProductBomService,
    private materialCodeService: MaterialCodeService,
    private _confirmationService: ConfirmationService,
  ) {
    this.userPermissionService.checkPagePermission(
      PAGE_NAME.invoicingAndShipping,
    );
    this.route.params.subscribe((params: Params) => {
      this.invoiceId = +params.invoiceId ?? 0;
      this.invoiceDetail.invoicesID = this.invoiceId;
      this.editMode = this.invoiceId <= 0;
      this.getInvoices();
      if (this.invoiceId > 0) {
        this.invoiceService
          .getShipmentByInvoiceId(this.invoiceId)
          .subscribe((data) => {
            this.generatedShipmentDetails = data;
          });
      }
    });
  }

  ngOnInit(): void {
    this.metalMarketService.getMetalMarketDates().subscribe((data = []) => {
      this.metalMarkets = data;
      if (!this.invoiceDetail.metalMarket?.marketDate) {
        const metalMarket = data[data.length - 1];
        this.invoiceDetail.metalMarket = {
          marketDate: new Date(metalMarket?.marketDate + 'T00:00:00'),
          metalMarketID: metalMarket?.metalMarketID,
        };
        this.onGetSelectedMetalMarket(metalMarket?.metalMarketID);
      }
    });
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject;
    });
    this.customerService.getList().subscribe((customers) => {
      this.customers = orderBy(
        customers.map((customer) => ({
          ...customer,
          addresses: customer.addresses.map((address) => ({
            ...address,
            fullAddress: formatAddress(address),
          })),
        })),
        'companyName',
      );
      this.customerFilterCtrl.valueChanges.subscribe(() => {
        this.filterCustomers();
      });
    });
  }

  onSelectCustomer() {
    const customer = this.customers.find(
      (c) => c.custIdno === this.invoiceDetail.custIDNo,
    );
    if (customer == null) return;
    this.invoiceDetail.custIDNo = customer.custIdno;
    this.invoiceDetail.customer = {
      id: customer.custIdno,
      companyName: customer.companyName,
    };
    this.billingAddresses = (customer.addresses ?? []).filter(
      (a) => a.addressType === 1,
    );
    const [firstAddress] = this.billingAddresses;

    if (this.invoiceDetail.invoicesID) return;
    this.invoiceDetail.billingAddressId =
      customer.defaultBillAddress ?? firstAddress?.customersAddressesId ?? 0;
  }

  ngAfterViewInit() {
    this.invoiceDetails.sort = this.sort;
  }

  redirectToShipment() {
    sessionStorage.setItem(
      'invoicing-generated-shipment',
      JSON.stringify(this.generatedShipmentDetails),
    );
    sessionStorage.setItem(
      'invoicing-generated-customer',
      JSON.stringify({
        customerId: this.invoiceDetail.custIDNo,
        addressId: this.invoiceDetail.billingAddressId,
      }),
    );
    this.router.navigate(['invoicing-and-shipping/shipping/0']);
  }

  getInvoices() {
    this.displayedColumns = [...productColumns];
    if (this.invoiceId === 0) {
      const generatedInvoice = sessionStorage.getItem(
        'shipping-generated-invoice',
      );
      const customerDetail = sessionStorage.getItem(
        'shipping-generated-customer',
      );
      if (generatedInvoice) {
        const data = JSON.parse(generatedInvoice);
        this.invoiceDetails.data = data.map((d) => ({
          ...d,
          maxQty: d.qty,
        }));
        sessionStorage.removeItem('shipping-generated-invoice');
      }
      if (customerDetail) {
        const detail = JSON.parse(customerDetail);
        this.getCustomer(detail.customerId, detail.addressId);
        sessionStorage.removeItem('shipping-generated-customer');
      }
      const byPassWorkOrderOrdersId = sessionStorage.getItem(
        'orderDetailByPassWorkOrderOrdersId',
      );
      this.byPassWorkOrders =
        sessionStorage.getItem('bypassWorkOrder') === 'yes';
      if (byPassWorkOrderOrdersId) {
        this.getOrders(Number.parseInt(byPassWorkOrderOrdersId));
      }
      return;
    }
    this.displayedColumns = this.displayedColumns.filter(
      (c) => c !== 'expander',
    );
    this.isLoading = this.invoiceId > 0;
    this.invoiceService.getInvoiceById(this.invoiceId).subscribe((invoice) => {
      this.invoiceDetail =
        invoice ??
        ({ invoicesID: 0, customer: {}, invoicesDetails: [] } as Invoice);
      this.invoiceDetail.invoicePaid = this.invoiceDetail.invoicePaid
        ? DateTime.fromJSDate(
            new Date(this.invoiceDetail.invoicePaid),
          ).toFormat('MM/dd/yyyy')
        : '';
      const charges =
        invoice.invoicesDetailsCharges?.map((charges, index) => ({
          invoicesDetailsChargesID: charges.invoicesDetailsChargesID,
          key: this.invoiceDetail.invoicesDetails.length + index,
          invoicesDetailsID: charges.invoicesDetailsID,
          orderDetailsAltID: 0,
          invoicesID: charges.invoicesID,
          qty: charges.qty,
          ordersDetailsID: 0,
          productName: charges.chargeDescription,
          material: charges.materialDescription,
          materialsCodeId: charges.materialCodesID,
          weight: charges.unitWeightGrams,
          size: '',
          cipo: '',
          productPicPath: '',
          price: charges.price,
          priceExtension: 0,
          enamelColor: '',
          enamelName: '',
          uom: '',
          productWeights: [],
          stone: {} as RivaGems,
          overrideStoneSize: '',
          overrideStoneSizeID: 0,
          invoicesDetailsCharges: [],
          isCharges: true,
        })) ?? [];
      this.invoiceDetails.data = [
        ...this.invoiceDetail.invoicesDetails,
        ...charges,
      ].map((i) => ({ ...i, maxQty: i.qty }));
      this.hasStoneType = this.invoiceDetail.invoicesDetails.some(
        (i) => i.stoneType,
      );
      this.hasStoneColor = this.invoiceDetail.invoicesDetails.some(
        (i) => i.stoneColor,
      );
      this.hasStoneSize = this.invoiceDetail.invoicesDetails.some(
        (i) => i.stoneSize || i.overrideStoneSize,
      );
      this.hasStoneChain = this.invoiceDetail.invoicesDetails.some(
        (i) => i.chain,
      );
      this.hasLength = this.invoiceDetail.invoicesDetails.some(
        (i) => i.chainRawLength,
      );
      this.hasEnamel = this.invoiceDetail.invoicesDetails.some(
        (i) => !!i.enamelName,
      );

      this.hasPricePerInch = this.invoiceDetail.invoicesDetails.some(
        (i) => i.isUnfinishedChain,
      );

      if (!this.hasPricePerInch) {
        this.displayedColumns = this.displayedColumns.filter(
          (c) => c !== 'pricePerInch',
        );
      }

      if (!this.hasEnamel) {
        this.displayedColumns = this.displayedColumns.filter(
          (c) => c !== 'enamel',
        );
      }

      if (!this.hasLength) {
        this.displayedColumns = this.displayedColumns.filter(
          (c) => c !== 'chainRawLength',
        );
      }

      if (!this.hasStoneType) {
        this.displayedColumns = this.displayedColumns.filter(
          (c) => c !== 'stoneType',
        );
      }
      if (!this.hasStoneColor) {
        this.displayedColumns = this.displayedColumns.filter(
          (c) => c !== 'stoneColor',
        );
      }
      if (!this.hasStoneSize) {
        this.displayedColumns = this.displayedColumns.filter(
          (c) => c !== 'stoneSize',
        );
      }
      if (!this.hasStoneChain) {
        this.displayedColumns = this.displayedColumns.filter(
          (c) => c !== 'chain',
        );
      }
      this.selectedMetalMarket = {
        ...(invoice.metalMarket ?? {}),
        marketDate: DateTime.fromJSDate(
          new Date(invoice.metalMarket?.marketDate),
        ).toFormat('MM/dd/yyyy'),
        enteredDate: DateTime.fromJSDate(
          new Date(invoice.metalMarket?.enteredDate),
        ).toFormat('yyyy-MM-dd'),
      };
      this.getCustomer(this.invoiceDetail.custIDNo);
      this.invoiceDetail.invoicesDetails.forEach((i) => {
        this.expandedInvoice[i.key] =
          (i.invoicesDetailsCharges?.length ?? 0) > 0;
      });
      this.isLoading = false;
    });
    this.invoiceService.generatePdf(this.invoiceId).subscribe((data) => {
      this.invoiceUrl = data;
    });
  }

  getOrders(ordersId) {
    sessionStorage.removeItem('orderDetailByPassWorkOrderOrdersId');
    sessionStorage.removeItem('bypassWorkOrder');
    this.invoiceService.getOrderItems(ordersId).subscribe((items) => {
      const mappedItems = items.reduce((accum, item) => {
        if (item.qty === 0) return accum;
        const invoicedItem = this.invoiceDetails.data.find(
          (i) => i.ordersDetailsID === item.ordersDetailsID,
        );
        item.price = 0;
        item.weight =
          (item.avgWeightGrams ?? 0) > 0
            ? item.avgWeightGrams ?? 0
            : item.rollingWeightAverage;
        item.hasWeight = (item.avgWeightGrams ?? 0) > 0;
        item.isClosed = item.totalClosedQty > 0;
        item.maxQty = item.qty;
        if (invoicedItem == null) return [...accum, item];
        item.qty = item.qty - invoicedItem.qty;
        item.maxQty = item.qty;
        if (item.qty > 0) return [...accum, item];
        return accum;
      }, []);
      const chains = mappedItems.filter((item) => item.itemType > 0);
      const products = mappedItems.filter((item) => item.itemType === 0);

      const [item] = mappedItems;
      this.getCustomer(item.customerId);

      if (chains.length > 0) {
        this.processOverrideItems(chains, [], true);
        if (products.length === 0) {
          this.checkColumns();
        }
      }
      if (products.length > 0) {
        this.getStoneDetails(products).subscribe((stones: ProductStone[]) => {
          this.processOverrideItems(products, stones);
          this.checkColumns();
        });
      }
    });
  }

  filterCustomers() {
    this.filteredCustomers = orderBy(
      this.customers.filter((customer) =>
        customer.customerId
          ?.toLocaleLowerCase()
          .includes(this.customerFilterCtrl.value?.trim()),
      ),
      'companyName',
    );
  }

  goToInvoicingAndShipping() {
    this.router.navigate(['invoicing-and-shipping/invoicing']);
  }

  onScanWorkOrder() {
    const dialogRef = this.dialog.open(WorkOrdersReleaseComponent, {
      disableClose: true,
      maxWidth: '350px',
      width: '100%',
      data: {
        workOrders: [],
        action: 'invoiced',
      },
    });

    dialogRef.afterClosed().subscribe(({ code, workOrderType }) => {
      this.workOrderType = workOrderType;
      if (code) {
        this.invoiceService
          .getLookUpItems(code, workOrderType)
          .subscribe((items) => {
            const customerId =
              this.invoiceDetail.custIDNo ??
              this.invoiceDetails.data?.[0]?.customerId ??
              0;
            const hasOtherCustomer =
              customerId && items.some((i) => i.customerId !== customerId);
            if (hasOtherCustomer) {
              this.confirmation = {
                title: 'Invalid Customer',
                description: `Work order number ${code} is coming from a different customer.`,
                icon: 'error',
              };
              setTimeout(() => {
                this.confirmationDialog.fire();
              }, 100);
              return;
            }
            const [currentItem] = this.invoiceDetails.data ?? [];
            const [lookupItem] = items;
            const hasDifferentPO =
              currentItem != null &&
              this.dontAllowMixedInvoices &&
              (currentItem.poInternal !== lookupItem.poInternal ||
                currentItem.poExternal !== lookupItem.poExternal);
            if (hasDifferentPO) {
              this.confirmation = {
                title: 'Has Invalid PO',
                description: 'Different PO, Customer Requests Unmixed Invoices',
                icon: 'error',
              };
              setTimeout(() => {
                this.confirmationDialog.fire();
              }, 100);
              return;
            }
            const mappedItems = items.reduce((accum, item) => {
              const invoicedItem = this.invoiceDetails.data.find(
                (i) => i.ordersDetailsID === item.ordersDetailsID,
              );
              item.weight = item.wghtgrms ?? 0;
              item.hasWeight = (item.wghtgrms ?? 0) > 0;
              item.isClosed = item.totalClosedQty > 0;
              if (invoicedItem == null) return [...accum, item];
              item.qty = item.qty - invoicedItem.qty;
              if (item.qty > 0) return [...accum, item];
              return accum;
            }, []);

            if (mappedItems.length === 0) {
              this.confirmation = {
                title: 'Work Order Items',
                description: `Work order number ${code} is already invoiced.`,
                icon: 'error',
              };
              setTimeout(() => {
                this.confirmationDialog.fire();
              }, 100);
              return;
            }
            this.openWorkOrders(mappedItems);
          });
      }
    });
  }

  getStoneDetails(selectedItems) {
    const request = selectedItems.map((item) =>
      this.productBomService.getStoneByOrderDetailsId(item.ordersDetailsID),
    );
    return forkJoin(request);
  }

  openWorkOrders(items: InvoicingLookupItem[]) {
    const dialogRef = this.dialog.open(InvoicingDetailDialogComponent, {
      disableClose: true,
      maxWidth: '900px',
      width: '100%',
      autoFocus: false,
      data: {
        items,
      },
    });
    dialogRef.afterClosed().subscribe((selectedItems) => {
      if (!selectedItems) return;
      const [item] = selectedItems;
      this.getCustomer(item.customerId);

      if (this.workOrderType !== WorkOrderType.Product) {
        this.processDetails(selectedItems, []);
      } else {
        this.getStoneDetails(selectedItems).subscribe(
          (stones: ProductStone[]) => {
            this.processDetails(selectedItems, stones);
          },
        );
      }
    });
  }

  processDetails(selectedItems, stones = []) {
    this.invoiceDetails.data = this.invoiceDetails.data.map(
      (invoice, index) => {
        const itemQty =
          selectedItems.find(
            (s) => s.ordersDetailsID === invoice.ordersDetailsID,
          )?.qty ?? 0;
        return {
          ...invoice,
          qty: itemQty + invoice.qty,
          pricePerInch: 0,
        };
      },
    );
    this.invoiceDetails.data = selectedItems.reduce(
      (items, s, index) => {
        const isExist = items.some(
          (i) => i.ordersDetailsID === s.ordersDetailsID,
        );
        const overrideSize = stones[index]?.overrideStoneSize;
        const stone = stones[index]?.stone ?? ({} as RivaGems);
        if (isExist) return items;
        const hasPoExternalAndInternal = s.poExternal && s.poInternal;
        const isEqual =
          hasPoExternalAndInternal && s.poExternal === s.poInternal;
        return [
          ...items,
          {
            ...s,
            orderDetailsAltID:
              this.workOrderType !== WorkOrderType.Product
                ? s.ordersDetailsID
                : 0,
            poNumber:
              hasPoExternalAndInternal && !isEqual
                ? `${s.poExternal}/${s.poInternal}`
                : s.poExternal || s.poInternal,
            stoneType: stone.stoneType?.name,
            stoneColor: stone.stoneColor?.colorName,
            stoneSize: overrideSize || stone.stoneSize?.displayText,
          },
        ];
      },
      [...this.invoiceDetails.data],
    );
    this.invoiceDetails.data = this.invoiceDetails.data.map(
      (detail, index) => ({
        ...detail,
        key: index,
      }),
    );
    this.hasStoneType = this.invoiceDetails.data.some((i) => i.stoneType);
    this.hasStoneColor = this.invoiceDetails.data.some((i) => i.stoneColor);
    this.hasStoneSize = this.invoiceDetails.data.some(
      (i) => i.stoneSize || i.overrideStoneSize,
    );
    this.hasStoneChain = this.invoiceDetails.data.some((i) => i.chain);

    this.hasLength = this.invoiceDetails.data.some((i) => i.chainRawLength);

    this.hasEnamel = this.invoiceDetails.data.some((i) => !!i.enamelName);

    this.hasPricePerInch = this.invoiceDetails.data.some(
      (i) => i.isUnfinishedChain,
    );

    if (!this.hasPricePerInch) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'pricePerInch',
      );
    }

    if (!this.hasEnamel) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'enamel',
      );
    }

    if (!this.hasLength) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'chainRawLength',
      );
    }

    if (!this.hasStoneType) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'stoneType',
      );
    }
    if (!this.hasStoneColor) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'stoneColor',
      );
    }
    if (!this.hasStoneSize) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'stoneSize',
      );
    }
    if (!this.hasStoneChain) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'chain',
      );
    }
  }

  processOverrideItems(selectedItems, stones = [], isChain = false) {
    this.invoiceDetails.data = this.invoiceDetails.data.map(
      (invoice, index) => {
        const itemQty =
          selectedItems.find(
            (s) => s.ordersDetailsID === invoice.ordersDetailsID,
          )?.qty ?? 0;
        return {
          ...invoice,
          qty: itemQty + invoice.qty,
        };
      },
    );
    this.invoiceDetails.data = selectedItems.reduce(
      (items, s, index) => {
        const isExist = items.some(
          (i) => i.ordersDetailsID === s.ordersDetailsID,
        );
        const overrideSize = stones[index]?.overrideStoneSize;
        const stone = stones[index]?.stone ?? ({} as RivaGems);
        if (isExist) return items;
        const hasPoExternalAndInternal = s.poExternal && s.poInternal;
        const isEqual =
          hasPoExternalAndInternal && s.poExternal === s.poInternal;
        return [
          ...items,
          {
            ...s,
            orderDetailsAltID: isChain ? s.ordersDetailsID : 0,
            poNumber:
              hasPoExternalAndInternal && !isEqual
                ? `${s.poExternal}/${s.poInternal}`
                : s.poExternal || s.poInternal,
            stoneType: stone.stoneType?.name,
            stoneColor: stone.stoneColor?.colorName,
            stoneSize: overrideSize || stone.stoneSize?.displayText,
          },
        ];
      },
      [...this.invoiceDetails.data],
    );
    this.invoiceDetails.data = this.invoiceDetails.data.map(
      (detail, index) => ({
        ...detail,
        key: index,
      }),
    );
  }

  checkColumns() {
    this.hasStoneType = this.invoiceDetails.data.some((i) => i.stoneType);
    this.hasStoneColor = this.invoiceDetails.data.some((i) => i.stoneColor);
    this.hasStoneSize = this.invoiceDetails.data.some(
      (i) => i.stoneSize || i.overrideStoneSize,
    );
    this.hasStoneChain = this.invoiceDetails.data.some((i) => i.chain);
    this.hasLength = this.invoiceDetails.data.some((i) => i.chainRawLength);
    this.hasEnamel = this.invoiceDetails.data.some((i) => !!i.enamelName);

    this.hasPricePerInch = this.invoiceDetails.data.some(
      (i) => i.isUnfinishedChain,
    );

    if (!this.hasPricePerInch) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'pricePerInch',
      );
    }

    if (!this.hasEnamel) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'enamel',
      );
    }

    if (!this.hasLength) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'chainRawLength',
      );
    }

    if (!this.hasStoneType) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'stoneType',
      );
    }
    if (!this.hasStoneColor) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'stoneColor',
      );
    }
    if (!this.hasStoneSize) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'stoneSize',
      );
    }
    if (!this.hasStoneChain) {
      this.displayedColumns = this.displayedColumns.filter(
        (c) => c !== 'chain',
      );
    }
  }

  getCustomer(customerId, defaultCustomersAddressesId = null) {
    this.customerService.getCustomer(customerId).subscribe((data) => {
      this.dontAllowMixedInvoices = data.dontAllowMixedInvoices;
      this.invoiceDetail.custIDNo = data.custIdno;
      this.invoiceDetail.customer = {
        id: data.custIdno,
        companyName: data.companyName,
      };
      this.billingAddresses = (data.addresses ?? []).filter(
        (a) => a.addressType === 1,
      );
      const [firstAddress] = this.billingAddresses;

      if (this.invoiceDetail.invoicesID) return;
      this.invoiceDetail.billingAddressId =
        defaultCustomersAddressesId ??
        data.defaultBillAddress ??
        firstAddress?.customersAddressesId ??
        0;
    });
  }

  onSubmitInvoice(isUpdate = false) {
    const data = this.invoiceDetails.data.reduce((invoices, i) => {
      if (i.isCharges) return invoices;
      return [
        ...invoices,
        {
          invoiceDetailsId: i.invoicesDetailsID ?? 0,
          orderDetailsId:
            i.orderDetailsAltID || i.ordersDetailsAltID ? 0 : i.ordersDetailsID,
          orderDetailsAltId:
            i.orderDetailsAltID || i.ordersDetailsAltID
              ? i.orderDetailsAltID ?? i.ordersDetailsAltID ?? i.ordersDetailsID
              : 0,
          qty: i.qty,
          pricePerInch: i.pricePerInch,
          price: i.price,
          unitWeightGrams: i.hasWeight ? i.unitWeightGrams : i.weight,
          chargeItems:
            i.invoicesDetailsCharges?.map((c) => ({
              ...c,
              invoicesDetailsID: c.invoicesDetailsID,
              invoicesDetailsChargesID: c.invoicesDetailsChargesID ?? 0,
              invoicesID: i.invoicesID ?? 0,
            })) ?? [],
        },
      ];
    }, []);
    const charges = this.invoiceDetails.data.reduce((invoices, i) => {
      if (!i.isCharges) return invoices;
      return [
        ...invoices,
        {
          invoicesDetailsChargesID: i.invoicesDetailsChargesID ?? 0,
          invoicesID: i.invoicesID,
          invoicesDetailsID: i.invoicesDetailsID ?? 0,
          chargeDescription: i.productName,
          qty: i.qty,
          price: i.price,
          materialCodesID: i.materialsCodeId,
          unitWeightGrams: i.weight,
        },
      ];
    }, []);

    const invoice = {
      invoicesID: this.invoiceDetail.invoicesID,
      metalMarketID: this.invoiceDetail.metalMarket?.metalMarketID ?? 0,
      invoicePaid: this.invoiceDetail.invoicePaid,
      shippingCost: this.invoiceDetail.shippingCost,
      internalComment: this.invoiceDetail.internalComment,
      externalComment: this.invoiceDetail.externalComment,
      billingAddressId: this.invoiceDetail.billingAddressId,
      byPassWorkOrders: this.byPassWorkOrders,
      lineItems: data,
      chargeItems: charges,
      customerId: this.invoiceDetail.custIDNo,
    };

    const request = [];

    if (isUpdate) {
      const data = {
        invoicesID: invoice.invoicesID,
        metalMarketID: invoice.metalMarketID ?? 0,
        invoicePaid: invoice.invoicePaid,
        shippingCost: invoice.shippingCost,
        externalComments: invoice.externalComment,
        comments: invoice.internalComment,
      };
      request.push(this.invoiceService.updateInvoices(data));
    }

    forkJoin([this.invoiceService.setInvoices(invoice), ...request]).subscribe(
      (data) => {
        if (this.invoiceId > 0) {
          this.editMode = false;
          this.getInvoices();
        } else {
          this.router.navigate([`invoicing-and-shipping/invoicing/${data}`]);
        }
      },
    );
  }

  get invoiceSubmitValid() {
    return (
      this.invoiceDetail.custIDNo > 0 &&
      this.invoiceDetails.data.length > 0 &&
      this.invoiceDetails.data.every((i) => i.isCharges || i.price != 0) &&
      this.invoiceDetail.metalMarket.metalMarketID > 0
    );
  }

  get totalQty() {
    return this.invoiceDetails.data.reduce((total, i) => {
      return total + i.qty;
    }, 0);
  }

  get totalPriceExt() {
    return this.invoiceDetails.data.reduce((total, i) => {
      const totalChargesPrice =
        i.invoicesDetailsCharges?.reduce(
          (charges, c) => charges + c.qty * c.price,
          0,
        ) ?? 0;
      return total + totalChargesPrice + i.qty * i.price;
    }, this.invoiceDetail.shippingCost || 0);
  }

  onPrintInvoice() {
    this.dialog.open(InvoicingPrintDialogComponent, {
      disableClose: true,
      minWidth: '100vw',
      minHeight: '100vh',
      autoFocus: false,
      panelClass: 'invoicing-print-dialog-container',
      data: {
        detail: this.invoiceDetail,
      },
    });
  }

  onDownloadInvoice() {
    window.open(this.invoiceUrl);
  }

  onChangeDate(date: Date) {
    const selectedDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    const metalMarketId =
      this.metalMarkets.find((m) => m.marketDate === selectedDate)
        ?.metalMarketID ?? 0;
    this.invoiceDetail.metalMarket = {
      marketDate: date,
      metalMarketID: metalMarketId,
    };
    this.onGetSelectedMetalMarket(metalMarketId);
  }

  onGetSelectedMetalMarket(id) {
    this.selectedMetalMarket = {} as MetalMarket;
    if (!id) return;
    this.metalMarketService.getMetalMarketById(id).subscribe((data) => {
      this.selectedMetalMarket = data;
    });
  }

  onPaidInvoices() {
    this._confirmationService
      .showConfirmation({
        title: 'Paid invoice?',
        content: 'Has this invoice already been paid?',
        confirmLabel: 'Yes',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        const data = {
          invoicesID: this.invoiceDetail.invoicesID,
          metalMarketID: this.invoiceDetail.metalMarket?.metalMarketID ?? 0,
          invoicePaid: DateTime.local(),
          shippingCost: this.invoiceDetail.shippingCost,
          externalComments: this.invoiceDetail.externalComment,
          comments: this.invoiceDetail.internalComment,
        };
        this.invoiceService.updateInvoices(data).subscribe(() => {
          this.getInvoices();
        });
      });
  }

  onRemoveInvoice(invoice: InvoiceDetail) {
    this._confirmationService
      .showConfirmation({
        title: 'Remove order?',
        content: `Are you sure you want to remove this item? You can't undo this action after you remove it.`,
        confirmLabel: 'Yes',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.invoiceDetails.data = this.invoiceDetails.data.filter((i) =>
          i.tempKey
            ? i.tempKey !== invoice.tempKey
            : i.ordersDetailsID !== invoice.ordersDetailsID,
        );
        if (invoice.isCharges && invoice.invoicesDetailsChargesID) {
          this.invoiceService
            .deleteInvoiceCharge(invoice.invoicesDetailsChargesID)
            .subscribe();
        } else if (!invoice.isCharges && invoice.invoicesDetailsID) {
          this.invoiceService
            .deleteLineItem(invoice.invoicesDetailsID)
            .subscribe();
        }
      });
  }

  onOpenRelatedShipments() {
    this.dialog.open(RelatedShipmentDialogComponent, {
      disableClose: true,
      minWidth: '800px',
      autoFocus: false,
      data: {
        invoiceId: this.invoiceId,
      },
    });
  }

  onAddNewAddress() {
    const dialogRef = this.dialog.open(CustomerAddressDialogComponent, {
      disableClose: true,
      maxWidth: '700px',
      width: '100%',
      autoFocus: false,
      data: {
        customerId: this.invoiceDetail.custIDNo,
        addressType: 1,
      },
    });
    dialogRef.afterClosed().subscribe(({ customersAddressesId }) => {
      customersAddressesId &&
        this.getCustomer(this.invoiceDetail.custIDNo, customersAddressesId);
    });
  }

  updateExpandedInvoice(row: InvoiceDetail) {
    if (row.isCharges) return;
    this.expandedInvoice[row.key] = !this.expandedInvoice[row.key];
  }

  onAddCharges(row: InvoiceDetail) {
    row.invoicesDetailsCharges = [
      ...(row.invoicesDetailsCharges ?? []),
      {
        tempUniqueKey: +new Date(),
        invoicesDetailsChargesID: 0,
        invoicesID: row.invoicesID ?? 0,
        invoicesDetailsID: row.invoicesDetailsID ?? 0,
        chargeDescription: '',
        qty: row.qty,
        price: 0,
        priceTotal: 0,
      },
    ];
  }

  onDeleteCharges(row: InvoiceDetail, tempUniqueKey: number) {
    this._confirmationService
      .showConfirmation({
        title: 'Remove charge?',
        content: 'Are you sure you want to remove this charge?',
        confirmLabel: 'Yes',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        row.invoicesDetailsCharges = row.invoicesDetailsCharges.filter(
          (i) => i.tempUniqueKey !== tempUniqueKey,
        );
        if (row.invoicesDetailsChargesID) {
          this.invoiceService
            .deleteInvoiceCharge(row.invoicesDetailsChargesID)
            .subscribe();
        }
      });
  }

  onAddCharge() {
    this.invoiceDetails.data = [
      ...this.invoiceDetails.data,
      {
        tempKey: +new Date(),
        key: this.invoiceDetails.data.length,
        invoicesDetailsID: 0,
        orderDetailsAltID: 0,
        invoicesID: this.invoiceDetail.invoicesID ?? 0,
        qty: 1,
        ordersDetailsID: 0,
        productName: '',
        material: '',
        size: '',
        cipo: '',
        productPicPath: '',
        price: 0,
        priceExtension: 0,
        enamelColor: '',
        enamelName: '',
        uom: '',
        productWeights: [],
        stone: {} as RivaGems,
        overrideStoneSize: '',
        overrideStoneSizeID: 0,
        invoicesDetailsCharges: [],
        isCharges: true,
      },
    ];
    this.checkColumns();
  }
  onEditInvoice() {
    this.editMode = true;
    this.displayedColumns = ['expander', ...this.displayedColumns];
  }
  onCancelInvoiceEditing() {
    this._confirmationService
      .showConfirmation({
        title: 'Cancel changes?',
        content: `Are you sure you want to cancel your updates?`,
        confirmLabel: 'Yes',
      })
      .subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.editMode = false;
        this.getInvoices();
      });
  }

  onPricePerInchChange(item: InvoiceDetail) {
    item.price =
      Math.round(
        item.pricePerInch * (parseFloat(item.chainRawLength) ?? 0) * 100,
      ) / 100;
  }
  onPriceChange(item: InvoiceDetail) {
    // TODO: NaN when adding charges
    item.pricePerInch =
      Math.round((item.price / (parseFloat(item.chainRawLength) ?? 0)) * 100) /
      100;
  }

  checkWeightAlarming(item: InvoiceDetail) {
    if ((item.rollingWeightAverage ?? 0) === 0) return false;

    const lowerLimit = item.rollingWeightAverage * 0.9;
    const upperLimit = item.rollingWeightAverage * 1.1;
    const isWithinRange =
      item.weight >= lowerLimit && item.weight <= upperLimit;

    return !isWithinRange;
  }
}
