<div class="sub-items__container">
  <div class="sub-items__table-header">
    <button
      class="btn riva-button"
      *ngIf="editMode"
      (click)="onDialogOpen()"
      style="
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-plus"
        width="20"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#FFFFFF"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        style="margin-right: 3px"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="12" y1="5" x2="12" y2="19" />
        <line x1="5" y1="12" x2="19" y2="12" />
      </svg>
      <span>New Custom Component</span>
    </button>
  </div>
  <div class="sub-items__table-container" *ngIf="subItems.data.length">
    <table
      mat-table
      [dataSource]="subItems"
      matSort
      matSortActive="subItemsID"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="picPath">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div
            style="
              width: 70px;
              padding: 5px;
              display: flex;
              justify-content: center;
            "
            *ngIf="element.picPath"
          >
            <img
              style="max-height: 37px; max-width: 60px"
              [src]="element.picPath"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by name"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by description"
        >
          Description
        </th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>
      <ng-container matColumnDef="uom">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by uom"
        >
          UOM
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.unitOfMeasure?.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="batchQTY">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by batchQTY"
        >
          Batch QTY
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.batchQTY }}
        </td>
      </ng-container>
      <ng-container matColumnDef="moldingOptionText">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by moldingOptionText"
        >
          Size & MFG Method
        </th>
        <td mat-cell *matCellDef="let element">
          <div [ngClass]="{ 'two-column': element.subItemsInfo?.length > 4 }">
            <div
              class="sub-items__info"
              *ngFor="let subItem of element.subItemsInfo"
            >
              <div class="sub-items__info-size" *ngIf="subItem.size">
                <span>{{ subItem.size }}</span>
              </div>
              <div
                class="sub-items__info-details"
                *ngIf="subItem.mfgMethodText"
              >
                <div>{{ subItem.mfgMethodText }}</div>
                <span
                  class="view-stl-file"
                  *ngIf="subItem.stlPath"
                  (click)="onViewStlFile(element, subItem)"
                  >View STL File</span
                >
                <span class="other-details" *ngIf="subItem.mfgMethodDetails">{{
                  subItem.mfgMethodDetails
                }}</span>
                <span
                  class="other-details"
                  *ngIf="
                    (subItem.mfgMethodDetails || subItem.stlPath) &&
                    subItem.qty > 0
                  "
                  style="padding: 4px"
                >
                  |
                </span>
                <span class="other-details" *ngIf="subItem.qty > 0"
                  >Qty: {{ subItem.qty }}</span
                >
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="createdBy">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by createdBy"
        >
          Created By
        </th>
        <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by createdDate"
        >
          Created Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdDate | date : 'MM/dd/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            class="riva-button riva-button-sm"
            (click)="onDialogOpen(element)"
          >
            Edit
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            class="riva-button riva-button-sm"
            (click)="onDelete(element.subItemsID)"
            userPermission
            [elementKey]="productFeatureKey.deleteCustom"
          >
            Delete
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="10">No Available Data</td>
      </tr>
    </table>
  </div>
</div>
