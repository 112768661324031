import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { alphabet } from 'src/app/utilities/alphabet-characters';
import { InvoicingLookupItem } from '../../models/invoicing-lookup-item';

@Component({
  selector: 'invoicing-detail-dialog',
  templateUrl: './invoicing-detail-dialog.component.html',
  styleUrls: ['./invoicing-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvoicingDetailDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'selection',
    'index',
    'productPicPath',
    'productName',
    'material',
    'size',
    'weight',
    'qty',
    'cipo',
  ];
  invoiceOrders: InvoicingLookupItem[] = [];
  isSelectedAll = true;
  isSomeSelected = false;

  constructor(
    public dialogRef: MatDialogRef<InvoicingDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { items: InvoicingLookupItem[] },
  ) {}

  ngOnInit(): void {
    this.invoiceOrders = this.props.items.map((item, index) => ({
      ...item,
      maxQty: item.qty,
      index: alphabet[index] ?? `A${alphabet[index - 25]}`,
    }));
    this.selectAll(true);
  }

  onSelect() {
    const selected = this.invoiceOrders.filter((i) => i.selected);
    this.isSelectedAll = selected.length === this.invoiceOrders.length;
    this.isSomeSelected = selected.length > 0 && !this.isSelectedAll;
  }

  selectAll(checked) {
    this.invoiceOrders = this.invoiceOrders.map((i) => ({
      ...i,
      selected: i.isClosed ? checked : false,
      price: 0,
    }));
    this.onSelect();
  }

  onAddItem() {
    this.dialogRef.close(this.invoiceOrders.filter((i) => i.selected));
  }
}
