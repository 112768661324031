import { Guid } from '../components/generic/generic.component';
import { FindingsTypeSize } from '../components/riva-findings/model';

export class Findings {
  findingsId: number;
  name: string = 'Test';
  sku: string = 'Test SKU';
  suppliersId: number = 1;
  commentBox: string;
  unitsOfMeasureId: number = 1;
  picPath: string = '../../../assets/images/no-image.png';
  createdBy: string;
  createdDate: Date;
  findingsInfo: FindingsInfo[];
}

export class FindingsColumn {
  'ID': number = 0;
  'Name': string = '';
  'SKU': string = '';
  'Supplier': string = '';
  'Unit': string = '';
}

export class FindingsInfo {
  findingsInfoId: number;
  findingsId: number;
  materialCodeId: number;
  size: string;
  weight: number = 0;
  stockQty: number = 0;
  supplierSku: string;
  supplierPrice: number = 0;
}

export interface Supplier {
  suppliersId: number;
  supplierId: string;
  companyName: string;
}
interface UnitsOfMeasure {
  unitsOfMeasureId: number;
  uom: string;
}
export interface FindingsSize {
  findingSizesID: number;
  findingsID: number;
  size: string;
}
export interface FindingsMaterial {
  findingsMaterialsCodeId: number;
  materialCodeId: number;
  code: string;
  karat: string;
  color: string;
  description: string;
  printColor1?: string;
  printColor2?: string;
}
export interface RivaFindings {
  findingsId: number;
  name: string;
  sku: string;
  suppliersId: number;
  suppliersName?: string;
  commentBox: string;
  unitsOfMeasureId: number;
  picPath: string;
  createdBy: string;
  createdDate: string;
  source: true;
  supplier: Supplier;
  unitsOfMeasure: UnitsOfMeasure;
  sizes: FindingsSize[];
  materials: FindingsMaterial[];
}

export interface ProductFindings {
  tempProductsBOMFindingsID?: Guid;
  findings: {
    findingsId: number;
    name: string;
    sku: string;
    picPath: string | null;
  };
  productsBOMFindingsID: number;
  productsID: number;
  findingsID: number;
  findingsSizesID: number;
  findingsMaterialsID: number;
  qty: number;
  comment: string;
  size: string | FindingsTypeSize;
  sizeLabel?: string;
  material: FindingsMaterial;
}

export interface RivaFindingsDto {
  findingsId: number;
  name: string;
  sku: string;
  suppliersId: number;
  commentBox: string;
  unitsOfMeasureId: number;
  picPath: string;
  createdBy: string;
  source: boolean;
}

export interface ProductFindingsDto {
  productsBOMFindingsID: number;
  productsID: number;
  findingsID: number;
  findingsSizesID: number;
  findingsMaterialsID: number;
  qty: number;
  comment: string;
}
