<!-- <!DOCTYPE html>
<html>
<head>
<title>Access Denied</title>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<meta charset="UTF-8">
<link rel="stylesheet" href="style.css">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
</head>
<body>

</body>
</html> -->

<!-- <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">

<div class="w3-display-middle">
<h1 class="w3-jumbo w3-animate-top w3-center"><code style="color: red;">Access Denied</code></h1>
<hr class="w3-border-white w3-animate-left" style="margin:auto;width:50%">
<br/>
<h3 class="w3-center w3-animate-right" style="color: white; font-size: 24px;">You dont have permission to view this site.</h3>
<h3 class="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
<h6 class="w3-center w3-animate-zoom" style="font-size: 18px;">error code:403 forbidden</h6>
</div> -->

<div class="page-forbidden">
  <!-- <div>
    <div class="page-forbidden__illustration">
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 611.1897 448.81055"
        enable-background="new 0 0 1080 1080"
        xml:space="preserve"
        sodipodi:docname="begin-chat.svg"
        inkscape:version="1.2.1 (9c6d41e410, 2022-07-14)"
        width="611.1897"
        height="448.81055"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
      >
        <defs id="defs319">
          <rect
            x="432.01657"
            y="486.49655"
            width="220.78723"
            height="66.90522"
            id="rect479"
          />
        </defs>
        <path
          opacity="0.22"
          fill="#2a94f4"
          d="m 594.09699,255.23592 c -28.417,-35.628 -82.716,-40.791 -116.828,-70.982 -36.944,-32.696 -46.55,-78.891 -78.288,-116.656 -13.236,-15.75 -28.319,-38.07 -46.323,-48.376 -16.217,-9.2830003 -33.715,-17.3830003 -53.179,-18.95400025 -24.39,-1.96900005 -50.885,7.00099995 -71.091,24.07000025 -19.882,16.795 -34.196,41.096 -57.248,52.411 -28.948,14.209 -60.048,4.035 -88.322,1.967 -33.056,-2.417 -67.35,16.715 -78.5899996,48.951 -5.03900003,14.453 -5.05700003,30.248 -2.741,45.378 2.359,15.41 7.354,31.024 17.1229996,43.402 10.317,13.073 23.939,23.037 32.995,37.266 10.74,16.873 9.876,39.648 6.345,60.945 -3.532,21.299 -9.429,42.902 -7.664,63.73 2.593,30.613 23.057,56.827 51.614,66.116 36.661,11.925 79.528,-3.089 118.497,-18.579 34.89,-13.868 75.193,-30.109 111.612,-27.509 38.413,2.742 70.079,28.153 106.584,36.598 46.718,10.807 103.426,-4.641 139.269,-43.378 35.842,-38.737 45.133,-100.168 16.235,-136.4 z"
          id="path192"
        />
        <g
          opacity="0.55"
          id="g196"
          transform="translate(-234.40501,-315.33408)"
        >
          <path
            fill="#ffbe55"
            d="m 717.84,482.986 13.656,-8.843 c 5.658,-8.149 9.958,-16.326 12.847,-23.014 -5.943,-1.515 -18.241,4.754 -18.241,4.754 12.169,-7.424 16.55,-8.319 20.543,-10.732 3.489,-10.193 2.179,-21.42 2.267,-31.03 -12.644,12.064 -16.347,14.188 -16.347,14.188 7.997,-6.472 12.133,-13.433 16.306,-18.304 -0.641,-23.916 -8.269,-31.541 -8.269,-31.541 -5.909,4.088 -17.997,14.962 -22.762,18.973 -1.493,11.244 -1.286,25.35 -1.286,25.35 -1.489,-6.408 -1.72,-15.916 -1.65,-22.817 -6.794,5.986 -5.63,5.089 -10.309,11.059 -1.903,11.477 -0.355,31.246 -0.355,31.246 -2.278,-7.533 -3.02,-19.645 -3.26,-26.363 -7.58,10.859 -12.273,21.894 -15.437,33.578 1.295,9.799 3.657,19.597 3.657,19.597 -1.935,-3.931 -3.607,-9.366 -4.928,-14.53 -3.103,13.389 -4.516,27.687 -6.189,43.575 l 2.182,13.547 16.54,-6.076 c 12.529,-5.996 22.875,-15.884 30.951,-26.352 z"
            id="path194"
          />
        </g>
        <g
          opacity="0.55"
          id="g200"
          transform="translate(-234.40501,-315.33408)"
        >
          <path
            fill="#ffbe55"
            d="m 705.169,528.828 16.163,-1.85 c 8.691,-4.784 16.178,-10.196 21.74,-14.903 -4.649,-3.999 -18.452,-3.852 -18.452,-3.852 14.201,-1.239 18.522,-0.093 23.173,-0.48 7.657,-7.579 11.475,-18.218 15.827,-26.787 -16.69,5.185 -20.95,5.441 -20.95,5.441 10.041,-2.241 16.84,-6.638 22.744,-9.146 10.059,-21.707 6.617,-31.929 6.617,-31.929 -7.111,1.035 -22.772,5.4 -28.824,6.875 -6.336,9.408 -12.422,22.135 -12.422,22.135 1.515,-6.402 5.535,-15.021 8.666,-21.172 -8.747,2.341 -7.306,2.055 -14.151,5.323 -6.807,9.434 -14.21,27.83 -14.21,27.83 1.309,-7.76 6.029,-18.939 8.801,-25.064 -11.617,6.357 -20.728,14.155 -28.756,23.214 -3.197,9.353 -5.437,19.179 -5.437,19.179 0.015,-4.382 0.933,-9.993 2.046,-15.206 -8.732,10.613 -16.355,22.792 -24.917,36.28 l -4.069,13.105 17.517,1.911 c 13.888,0.2 27.552,-4.058 39.44,-9.844 z"
            id="path198"
          />
        </g>
        <g
          opacity="0.55"
          id="g204"
          transform="translate(-234.40501,-315.33408)"
        >
          <path
            fill="#ffbe55"
            d="m 355.465,479.249 c 8.076,10.468 18.422,20.356 30.951,26.352 l 16.54,6.076 2.182,-13.547 c -1.672,-15.888 -3.085,-30.186 -6.188,-43.575 -1.322,5.164 -2.994,10.599 -4.928,14.53 0,0 2.362,-9.798 3.657,-19.597 -3.163,-11.684 -7.857,-22.719 -15.437,-33.578 -0.241,6.719 -0.982,18.831 -3.26,26.363 0,0 1.548,-19.77 -0.355,-31.246 -4.679,-5.97 -3.516,-5.073 -10.309,-11.059 0.07,6.901 -0.161,16.409 -1.65,22.817 0,0 0.207,-14.105 -1.286,-25.35 -4.765,-4.012 -16.852,-14.885 -22.762,-18.973 0,0 -7.628,7.625 -8.269,31.541 4.173,4.871 8.309,11.832 16.306,18.304 0,0 -3.703,-2.124 -16.347,-14.188 0.089,9.61 -1.222,20.837 2.267,31.03 3.994,2.414 8.374,3.309 20.543,10.732 0,0 -12.298,-6.268 -18.241,-4.754 2.889,6.688 7.189,14.865 12.847,23.014 l 13.655,8.843 z"
            id="path202"
          />
        </g>
        <g
          opacity="0.55"
          id="g208"
          transform="translate(-234.40501,-315.33408)"
        >
          <path
            fill="#ffbe55"
            d="m 367.507,529.889 c 11.888,5.786 25.551,10.043 39.44,9.844 l 17.517,-1.911 -4.069,-13.105 c -8.561,-13.488 -16.184,-25.667 -24.917,-36.28 1.112,5.214 2.031,10.825 2.046,15.206 0,0 -2.24,-9.826 -5.437,-19.179 -8.028,-9.059 -17.139,-16.857 -28.756,-23.214 2.771,6.125 7.492,17.304 8.801,25.064 0,0 -7.403,-18.397 -14.21,-27.83 -6.846,-3.267 -5.405,-2.981 -14.151,-5.323 3.131,6.151 7.151,14.77 8.666,21.172 0,0 -6.086,-12.726 -12.422,-22.135 -6.052,-1.475 -21.713,-5.84 -28.824,-6.875 0,0 -3.442,10.221 6.617,31.929 5.903,2.507 12.703,6.905 22.744,9.146 0,0 -4.261,-0.256 -20.95,-5.441 4.352,8.569 8.17,19.207 15.827,26.787 4.65,0.386 8.972,-0.76 23.173,0.48 0,0 -13.803,-0.147 -18.452,3.852 5.561,4.707 13.049,10.119 21.74,14.903 l 16.163,1.85 z"
            id="path206"
          />
        </g>
        <g id="g236" transform="translate(-234.40501,-315.33408)">
          <g id="g212">
            <path
              fill="#ffffff"
              d="m 694.82,410.905 v 259.06 c 0,18.104 -14.673,32.786 -32.776,32.786 H 418.047 c -18.104,0 -32.786,-14.682 -32.786,-32.786 v -259.06 c 0.186,-17.946 14.794,-32.442 32.786,-32.442 h 243.997 c 17.992,0 32.59,14.496 32.776,32.442 z"
              id="path210"
            />
          </g>
          <g id="g216">
            <path
              fill="#ffbe55"
              d="M 694.82,410.905 H 385.261 c 0.186,-17.946 14.794,-32.442 32.786,-32.442 h 243.997 c 17.992,0 32.59,14.496 32.776,32.442 z"
              id="path214"
            />
          </g>
          <g id="g230">
            <g id="g220">
              <path
                fill="#ffffff"
                d="m 424.464,394.684 c 0,-3.307 -2.681,-5.987 -5.988,-5.987 -3.307,0 -5.988,2.681 -5.988,5.987 0,3.307 2.681,5.988 5.988,5.988 3.307,-0.001 5.988,-2.681 5.988,-5.988 z"
                id="path218"
              />
            </g>
            <g id="g224">
              <path
                fill="#ffffff"
                d="m 443.031,394.684 c 0,-3.307 -2.681,-5.987 -5.987,-5.987 -3.307,0 -5.988,2.681 -5.988,5.987 0,3.307 2.681,5.988 5.988,5.988 3.307,-0.001 5.987,-2.681 5.987,-5.988 z"
                id="path222"
              />
            </g>
            <g id="g228">
              <path
                fill="#ffffff"
                d="m 461.599,394.684 c 0,-3.307 -2.681,-5.987 -5.988,-5.987 -3.307,0 -5.987,2.681 -5.987,5.987 0,3.307 2.681,5.988 5.987,5.988 3.307,-0.001 5.988,-2.681 5.988,-5.988 z"
                id="path226"
              />
            </g>
          </g>
          <g id="g234">
            <path
              fill="#ffffff"
              d="M 598.641,400.671 H 485.769 c -3.307,0 -5.988,-2.681 -5.988,-5.988 v 0 c 0,-3.307 2.681,-5.987 5.988,-5.987 h 112.872 c 3.307,0 5.988,2.681 5.988,5.987 v 0 c -10e-4,3.308 -2.682,5.988 -5.988,5.988 z"
              id="path232"
            />
          </g>
        </g>
        <g id="g240" transform="translate(-234.40501,-315.33408)">
          <circle
            fill="#ffffff"
            cx="580.53101"
            cy="644.25299"
            r="3.6159999"
            id="circle238"
          />
        </g>
        <g id="g244" transform="translate(-234.40501,-315.33408)">
          <circle
            fill="#ffffff"
            cx="596.00403"
            cy="644.25299"
            r="3.6159999"
            id="circle242"
          />
        </g>
        <g id="g248" transform="translate(-234.40501,-315.33408)">
          <circle
            fill="#ffffff"
            cx="611.47699"
            cy="644.25299"
            r="3.6159999"
            id="circle246"
          />
        </g>
        <g id="g252" transform="translate(-234.40501,-315.33408)">
          <circle
            fill="#ffffff"
            cx="626.95099"
            cy="644.25299"
            r="3.6159999"
            id="circle250"
          />
        </g>
        <g id="g304" transform="translate(-234.40501,-315.33408)">
          <path
            fill="#ffbe55"
            d="m 405.056,585.519 c 0,0 12.428,28.377 23.533,22.682 11.105,-5.695 13.952,-9.112 13.952,-9.112 l 4.413,4.129 c 0,0 -10.678,14.305 -20.074,15.766 -9.396,1.461 -20.074,-2.81 -20.074,-2.81 z"
            id="path254"
          />
          <path
            fill="#fab9af"
            d="m 434.175,606.141 c -1.464,0.214 -2.908,0.589 -3.496,0.614 -0.246,0.01 -0.91,-0.161 -1.106,-0.197 -0.576,-0.107 -1.451,-0.363 -1.996,-0.148 l -2.307,0.863 c -1.108,0.436 -2.159,0.231 -2.594,-0.877 v 0 c -0.472,-1.2 0.338,-2.147 1.584,-2.477 l 1.604,-0.631 c 0.998,-0.264 1.78,-0.952 2.587,-1.595 1.642,-1.308 4.037,-1.7 5.988,-1.834 1.224,-0.084 2.435,1.04 3.965,2.625 0.752,0.779 1.685,2.211 0.33,2.053 -1.135,-0.132 -3.608,-2.378 -6.234,-0.382 -0.129,0.098 -0.442,0.373 -0.375,0.561 0.201,0.558 1.464,0.178 2.165,0.207 0.984,0.041 1.555,0.495 1.607,0.89 0.056,0.415 -0.677,0.175 -1.722,0.328 z"
            id="path256"
          />
          <g id="g302">
            <path
              fill="#fab9af"
              d="m 446.077,602.824 c 0.594,-0.844 1.511,-1.338 2.395,-1.872 1.797,-1.085 2.996,-3.195 3.8,-4.978 0.504,-1.118 -0.129,-2.645 -1.084,-4.63 -0.469,-0.975 -1.488,-2.348 -1.811,-1.023 -0.271,1.11 0.977,4.21 -1.807,5.978 -0.137,0.087 -0.504,0.285 -0.656,0.157 -0.454,-0.382 0.342,-1.435 0.558,-2.102 0.303,-0.937 0.076,-1.63 -0.276,-1.816 -0.372,-0.197 -0.402,0.574 -0.908,1.5 -0.709,1.298 -1.563,2.522 -1.79,3.065 -0.095,0.227 -0.165,0.909 -0.199,1.106 -0.1,0.577 -0.164,1.486 -0.554,1.923"
              id="path258"
            />
            <path fill="#fab9af" d="M 442.054,604.734" id="path260" />
            <g id="g290">
              <g id="g286">
                <polygon
                  fill="#fab9af"
                  points="382.454,582.417 371.019,642.281 412.589,642.695 404.439,585.435 "
                  id="polygon262"
                />
                <path
                  fill="#0e538c"
                  d="m 395.595,731.42 0.024,6.454 c 0.005,1.313 -0.174,2.62 -0.53,3.884 l -0.561,1.988 c -0.461,1.632 0.766,3.252 2.462,3.246 4.396,-0.016 11.909,-0.004 13.845,-10e-4 0.317,10e-4 0.627,-0.213 0.655,-0.529 0.163,-1.884 -5.279,-3.146 -9.379,-4.733 -3.123,-1.209 -2.956,-6.821 -2.571,-10.099 0.145,-1.238 -0.887,-2.292 -2.13,-2.189 v 0 c -1.028,0.085 -1.818,0.947 -1.815,1.979 z"
                  id="path264"
                />
                <path
                  fill="#0e538c"
                  d="m 365.383,729.61 -2.224,7.383 c -0.098,0.326 -0.245,0.636 -0.435,0.918 l -1.576,2.341 c -1.001,1.486 -0.28,3.517 1.433,4.04 0,0 3.178,1.118 6.455,2.195 3.354,1.102 7.573,0.181 7.573,-0.704 0,-1.966 -1.834,-0.406 -4.905,-2.459 -1.029,-0.688 -2.091,-1.408 -3.038,-2.152 -2.643,-2.075 -0.335,-7.328 1.283,-10.286 0.582,-1.064 -0.036,-2.384 -1.222,-2.637 l -0.766,-0.163 c -1.125,-0.241 -2.246,0.422 -2.578,1.524 z"
                  id="path266"
                />
                <path
                  fill="#0e538c"
                  d="m 388.098,643.249 24.362,-2.045 c 0,0 2.453,17.731 -3.704,49.828 -2.888,15.052 -8.905,43.815 -8.905,43.815 -1.492,0.653 -3.008,0.817 -4.552,0.432 0,0 -0.457,-12.687 -0.736,-26.351 -0.268,-13.104 -0.34,-27.106 -1.175,-31.687 -1.707,-9.358 -5.29,-33.992 -5.29,-33.992 z"
                  id="path268"
                />
                <path
                  fill="#0e538c"
                  d="m 393.127,643.558 c 2.515,13.499 8.134,33.684 -2.689,52.647 -10.823,18.963 -22.191,38.677 -22.191,38.677 -1.465,0.06 -2.793,-0.471 -4.027,-1.408 0,0 1.898,-8.597 4.641,-18.634 3.138,-11.483 7.113,-24.99 5.981,-32.033 -2.123,-13.199 -4.56,-30.777 -3.822,-39.313 0.738,-8.537 20.784,-7.034 22.107,0.064 z"
                  id="path270"
                />
                <path
                  fill="#ffffff"
                  d="m 413.43,641.237 -8.991,-55.802 -21.985,-3.018 -11.815,58.923 c -0.222,1.107 0.495,2.183 1.605,2.393 4.093,0.776 13.967,2.396 25.016,2.396 8.249,0 12.884,-1.604 14.993,-2.62 0.855,-0.411 1.328,-1.336 1.177,-2.272 z"
                  id="path272"
                />
                <path
                  fill="#ffbe55"
                  d="m 389.323,585.893 c 0,4.02 -0.731,8.001 -2.104,11.779 -2.686,7.391 -6.715,21.368 -4.321,33.681 3.448,17.731 7.881,24.899 2.134,29.032 -5.746,4.133 -16.767,-2.763 -17.085,-5.061 -0.318,-2.299 2.145,-30.534 4.936,-42.767 2.791,-12.233 10.609,-29.819 10.609,-29.819 l 3.312,0.354 c 1.432,0.152 2.519,1.36 2.519,2.801 z"
                  id="path274"
                />
                <path
                  fill="#ffbe55"
                  d="m 398.475,584.616 c 0,0 3.813,8.241 2.483,20.08 -1.33,11.839 -4.411,47.892 6.362,53.336 3.411,1.724 11.202,-5.91 11.202,-8.743 0,-2.833 -2.6,-12.313 -5.064,-20.933 -2.464,-8.62 -8.402,-42.837 -8.402,-42.837 z"
                  id="path276"
                />
                <g id="g284">
                  <path
                    fill="#fab9af"
                    d="m 395.406,559.077 c -4.907,0 -6.737,3.493 -6.831,9.954 -0.035,2.42 1.053,4.817 1.93,6.348 0.651,1.137 0.829,2.484 0.458,3.741 l -1.077,3.647 c -0.733,3.383 0.874,5.238 3.246,5.912 1.175,0.334 2.583,0.075 3.608,-0.588 1.22,-0.788 1.32,-2.483 1.093,-3.918 l -0.278,-1.04 c -0.375,-1.401 0.437,-2.911 1.854,-3.22 3.778,-0.826 4.319,-4.765 5.331,-10.881 1.054,-6.377 -4.428,-9.955 -9.334,-9.955 z"
                    id="path278"
                  />
                  <path
                    fill="#0e538c"
                    d="m 406.831,556.239 -2.814,0.856 c -1.034,0.314 -2.137,0.321 -3.175,0.02 l -5.618,-1.63 c -3.403,0 -9.023,1.922 -8.313,6.832 l 0.627,3.473 c 0.25,1.387 1.407,2.425 2.812,2.546 0,0 0,0 0,0 0.13,0.48 0.566,0.813 1.063,0.813 0.608,0 1.101,-0.493 1.101,-1.101 v 0 c 0,-1.566 1.1,-2.903 2.628,-3.246 1.874,-0.421 3.877,-0.521 5.972,0.042 3.905,1.049 8.022,-1.371 8.412,-5.395 0.025,-0.255 0.037,-0.514 0.037,-0.776 0.001,-1.623 -1.34,-2.818 -2.732,-2.434 z"
                    id="path280"
                  />
                  <path
                    fill="#fab9af"
                    d="m 391.28,569.15 c 0,1.498 -0.558,2.712 -2,2.712 -1.442,0 -2.368,-2.06 -2.368,-3.558 0,-1.498 0.926,-1.867 2.368,-1.867 1.442,0 2,1.214 2,2.713 z"
                    id="path282"
                  />
                </g>
              </g>
              <path
                fill="#ffbe55"
                d="m 390.683,575.726 c 0,0 3.665,5.038 8.225,4.355 0,0 -0.947,0.453 -1.177,1.189 -10e-4,0 -4.332,-0.32 -7.048,-5.544 z"
                id="path288"
              />
            </g>
            <path
              fill="#ffbe55"
              d="m 383.492,582.737 c 0,0 -1.572,12.224 14.715,16.495 16.287,4.271 29.1,3.172 29.1,3.172 v 5.37 c 0,0 -27.207,6.549 -43.857,-1.139 -16.65,-7.688 -6.622,-23.01 0.042,-23.898 z"
              id="path292"
            />
            <polygon
              fill="#0e538c"
              points="450.229,598.004 443.182,597.826 444,584.67 450.976,584.67 "
              id="polygon294"
            />
            <polygon
              fill="#0e538c"
              points="449.588,593.36 449.535,596.563 443.983,596.474 444.623,585.519 450.14,585.44 "
              id="polygon296"
            />
            <path
              fill="#fab9af"
              d="m 450.44,594.235 c 0,0 -0.959,-0.929 -1.386,-0.36 -0.427,0.57 1.602,3.31 1.602,3.31 l 0.32,-2.776 z"
              id="path298"
            />
            <path
              fill="#fab9af"
              d="m 443.713,589.285 c 0,0 1.942,0.711 1.818,2.384 -0.125,1.673 -1.353,3.933 -2.474,3.31 -1.121,-0.623 0.209,-5.657 0.656,-5.694 z"
              id="path300"
            />
          </g>
        </g>
        <rect
          x="585.40674"
          y="97.593109"
          transform="rotate(25.506701)"
          opacity="0.22"
          fill="#2a94f4"
          width="36.268597"
          height="36.268597"
          id="rect306"
          style="stroke-width: 0.999961"
        />
        <rect
          x="102.69917"
          y="168.52193"
          transform="rotate(25.506701)"
          opacity="0.41"
          fill="#2a94f4"
          width="36.268597"
          height="36.268597"
          id="rect308"
          style="stroke-width: 0.999961"
        />
        <circle
          opacity="0.22"
          fill="#2a94f4"
          cx="504.23203"
          cy="284.11093"
          r="14.829"
          id="circle310"
        />
        <circle
          opacity="0.22"
          fill="#2a94f4"
          cx="582.35999"
          cy="381.64291"
          r="14.829"
          id="circle312"
        />
        <circle
          opacity="0.22"
          fill="#2a94f4"
          cx="95.210991"
          cy="240.15091"
          r="14.829"
          id="circle314"
        />
        <text
          xml:space="preserve"
          id="text477"
          style="
            font-size: 74.6667px;
            white-space: pre;
            shape-inside: url(#rect479);
            display: inline;
            fill: #000000;
          "
          transform="translate(-234.40501,-315.33408)"
        >
          <tspan x="432.01562" y="621.52304" id="tspan6352"></tspan>
        </text>
        <style type="text/css" id="style660">
          .st0 {
            fill: #f2f2f2;
          }
          .st1 {
            fill: #d3d3d3;
          }
          .st2 {
            fill: #0b4870;
          }
          .st3 {
            fill: #f7f7f7;
          }
          .st4 {
            fill: #ffbe55;
          }
        </style>
        <g
          id="g704"
          transform="matrix(0.89030095,0,0,0.87277635,-6.6568696,-222.31882)"
        >
          <g
            id="g702"
            transform="matrix(0.55316346,0,0,0.52685671,212.85765,403.34623)"
          >
            <g id="g688">
              <g id="g686">
                <path
                  class="st4"
                  d="M 314.04,211.62 H 187.36 v -70.66 c 0,-34.93 28.42,-63.34 63.34,-63.34 34.92,0 63.34,28.42 63.34,63.34 z M 206.67,192.31 h 88.05 v -51.34 c 0,-24.27 -19.75,-44.03 -44.02,-44.03 -24.27,0 -44.03,19.75 -44.03,44.03 z"
                  id="path682"
                />
                <path
                  class="st2"
                  d="M 318.93,216.51 H 182.47 v -75.55 c 0,-37.62 30.61,-68.23 68.23,-68.23 37.62,0 68.23,30.61 68.23,68.23 z m -126.69,-9.78 h 116.91 v -65.77 c 0,-32.23 -26.22,-58.45 -58.45,-58.45 -32.23,0 -58.45,26.22 -58.45,58.45 v 65.77 z m 107.37,-9.54 h -97.82 v -56.23 c 0,-26.97 21.94,-48.91 48.91,-48.91 26.97,0 48.91,21.94 48.91,48.91 z m -88.05,-9.77 h 78.27 v -46.45 c 0,-21.58 -17.55,-39.14 -39.13,-39.14 -21.58,0 -39.14,17.56 -39.14,39.14 z"
                  id="path684"
                />
              </g>
            </g>
            <g id="g694">
              <path
                class="st4"
                d="m 299.82,310.25 h -98.24 c -18.02,0 -32.62,-14.61 -32.62,-32.62 v -65.3 c 0,-18.02 14.61,-32.62 32.62,-32.62 h 98.24 c 18.02,0 32.62,14.61 32.62,32.62 v 65.3 c 0,18.01 -14.61,32.62 -32.62,32.62 z"
                id="path690"
              />
              <path
                class="st2"
                d="m 300.55,315.14 h -99.7 c -20.28,0 -36.78,-16.5 -36.78,-36.78 V 211.6 c 0,-20.28 16.5,-36.78 36.78,-36.78 h 99.7 c 20.28,0 36.78,16.5 36.78,36.78 v 66.76 c 0,20.27 -16.5,36.78 -36.78,36.78 z m -99.7,-130.55 c -14.89,0 -27.01,12.12 -27.01,27.01 v 66.76 c 0,14.89 12.12,27.01 27.01,27.01 h 99.7 c 14.89,0 27.01,-12.12 27.01,-27.01 V 211.6 c 0,-14.89 -12.12,-27.01 -27.01,-27.01 z"
                id="path692"
              />
            </g>
            <g id="g700">
              <path
                class="st4"
                d="m 265.57,268.42 -6.59,-24.67 c 2.68,-2.33 4.45,-5.66 4.45,-9.49 0,-7.03 -5.7,-12.73 -12.74,-12.73 -7.03,0 -12.73,5.7 -12.73,12.73 0,3.83 1.78,7.16 4.45,9.49 l -6.59,24.67 z"
                id="path696"
              />
              <path
                class="st2"
                d="m 271.93,273.31 h -42.47 l 7.52,-28.16 c -2.54,-3.11 -3.91,-6.88 -3.91,-10.89 0,-9.72 7.91,-17.62 17.62,-17.62 9.72,0 17.62,7.91 17.62,17.62 0,4.01 -1.37,7.78 -3.91,10.89 z m -29.74,-9.78 h 17.01 l -5.73,-21.46 2.3,-2 c 1.04,-0.91 2.78,-2.86 2.78,-5.81 0,-4.33 -3.52,-7.85 -7.85,-7.85 -4.33,0 -7.85,3.52 -7.85,7.85 0,2.95 1.74,4.9 2.78,5.81 l 2.3,2 z"
                id="path698"
              />
            </g>
          </g>
        </g>
        <text
          xml:space="preserve"
          style="
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: 32px;
            font-family: sans-serif;
            -inkscape-font-specification: sans-serif;
            fill: #124b6f;
            fill-opacity: 1;
          "
          x="230.19682"
          y="332.95609"
          id="text736"
        >
          <tspan
            sodipodi:role="line"
            id="tspan734"
            x="230.19682"
            y="332.95609"
            style="font-size: 32px; fill: #124b6f; fill-opacity: 1"
          >
            Forbidden
          </tspan>
        </text>
      </svg>
    </div>
    <div class="page-forbidden__description">
      <div class="page-forbidden__description-container">
        <h1 class="forbidden-title">We are Sorry...</h1>
        <h3 class="forbidden-description">
          The page you're trying to access has restricted access. Please refer
          to your system administrator
        </h3>
      </div>
    </div>
  </div> -->
</div>
