import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class JiraService extends GenericService {
  baseUrl = environment.apiUrl + 'jira';

  constructor(http: HttpClient) {
    super(http);
  }
  scan(issueKey: string): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/scan`, {
        headers: this.headers,
        params: {
          issueKey
        },
      });
  }
}
