<div class="product-enamel">
  <div class="product-enamel__enamel-header">
    <div class="enamel__search">
      <mat-form-field class="search-input" appearance="fill">
        <mat-label>Search enamels to add</mat-label>
        <input
          type="text"
          matInput
          [(ngModel)]="selectedEnamel"
          [formControl]="enamelControl"
          [matAutocomplete]="auto"
          style="color: white"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #auto="matAutocomplete"
          [displayWith]="displayFn"
        >
          <mat-option class="riva-select-header riva-select__stick-header">
            <div class="riva-select">
              <div class="p5 riva-select__color">Color</div>
              <div class="p5 riva-select__name">Name</div>
              <div class="p5 riva-select__pantone-color">Pantone Color</div>
              <div class="p5 riva-select__hardness">Type</div>
            </div>
          </mat-option>
          <mat-option class="riva-select-header">
            <div class="riva-select">
              <div class="p5 riva-select__color">Color</div>
              <div class="p5 riva-select__sku">SKU</div>
              <div class="p5 riva-select__name">Name</div>
              <div class="p5 riva-select__pantone-color">Pantone Color</div>
              <div class="p5 riva-select__hardness">Type</div>
            </div>
          </mat-option>
          <mat-option
            *ngFor="let enamel of filteredEnamels | async"
            style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
            (click)="onSelectEnamel(enamel)"
          >
            <div class="country-container riva-select">
              <div class="p5 riva-select__color">
                <div
                  style="width: 30px; height: 30px"
                  [ngStyle]="{ 'background-color': enamel.colorHex }"
                ></div>
              </div>
              <div class="p5 riva-select__name">
                {{ enamel.enamelName }}
              </div>
              <div class="p5 riva-select__pantone-color">
                {{ enamel.pantoneColor }}
              </div>
              <div class="p5 riva-select__hardness">
                {{ getTypeLabel(enamel.hardness) }}
              </div>
            </div>
          </mat-option>
          <mat-option *ngIf="!(filteredEnamels | async)?.length">
            <div class="text-center riva-select__not-found">Not Found</div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-error class="add-enamel-error" *ngIf="isVariationLimit"
        >You reach the limit. Maximum variation per product is
        {{ maxVariation }}</mat-error
      >
    </div>
  </div>
  <div style="border: 1px solid #646464">
    <table mat-table [dataSource]="productEnamels">
      <ng-container matColumnDef="variation">
        <th mat-header-cell *matHeaderCellDef>Variation</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [(ngModel)]="element.variation"></mat-slide-toggle>
        </td>
      </ng-container>
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef>Color</th>
        <td mat-cell *matCellDef="let element">
          <div
            style="width: 30px; height: 30px"
            [ngStyle]="{ 'background-color': element.colorHex }"
          ></div>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.enamelName }}</td>
      </ng-container>
      <ng-container matColumnDef="pantoneColor">
        <th mat-header-cell *matHeaderCellDef>Pantone Color</th>
        <td mat-cell *matCellDef="let element">
          {{ element.pantoneColor }}
        </td>
      </ng-container>
      <ng-container matColumnDef="hardness">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">
          {{ getTypeLabel(element.hardness) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef>Supplier</th>
        <td mat-cell *matCellDef="let element">
          {{ element.supplierDisplayName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="hex">
        <th mat-header-cell *matHeaderCellDef>Color</th>
        <td mat-cell *matCellDef="let element">{{ element.colorHex }}</td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <i
            nz-icon
            nzType="delete"
            nzTheme="fill"
            class="variation-delete"
            (click)="onDeleteEnamel(element)"
            userPermission
            [elementKey]="productFeatureKey.deleteNonCustom"
          ></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="8">No Available Data</td>
      </tr>
    </table>
  </div>
</div>
