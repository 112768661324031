import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Optional,
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appNumberOnly]',
})
export class NumberOnlyDirective {
  @Input() allowNegative: boolean = false;
  @Input() minValue: number;
  @Input() maxValue: number;

  constructor(private el: ElementRef, @Optional() private ngModel: NgModel) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initialValue = this.el.nativeElement.value;
    let value = this.allowNegative
      ? initialValue.replace(/[^-0-9]/g, '')
      : initialValue.replace(/[^0-9]/g, '');

    const parsedValue = parseInt(value);

    if (!isNaN(parsedValue)) {
      if (this.minValue !== undefined && parsedValue < this.minValue) {
        value = String(this.minValue);
      } else if (this.maxValue !== undefined && parsedValue > this.maxValue) {
        value = String(this.maxValue);
      }
    }

    this.el.nativeElement.value = value;

    if (this.ngModel) {
      this.ngModel.update.emit(value);
    }

    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
