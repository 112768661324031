import { Component, Input } from '@angular/core';
import { User } from 'src/app/core/authentication/user';

@Component({
  selector: 'accounts-table-user',
  templateUrl: './accounts-table-user.component.html',
  styleUrls: ['./accounts-table-user.component.scss'],
})
export class AccountsTableUserComponent {
  @Input() account: User;
}
