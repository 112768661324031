import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductRoutingCodes, ProductsRouting } from '../models/product';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsRoutingService extends GenericService {
  baseUrl = environment.apiUrl + 'productsrouting';

  constructor(http: HttpClient) {
    super(http);
  }

  getListCodes(): Observable<any> {
    return this.http.get(this.baseUrl + '/listroutingcodes', {
      headers: this.headers,
    });
  }

  addupdate(productsRouting: ProductsRouting): Observable<any> {
    return this.http.post(this.baseUrl + '/addupdate', productsRouting, {
      headers: this.headers,
    });
  }

  delete(findingsID: number): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + findingsID, {
      headers: this.headers,
    });
  }

  getRoutingListByProduct(productId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/getbyproduct/' + productId, {
      headers: this.headers,
    });
  }

  getProductRoutings(productId: number): Observable<ProductsRouting[]> {
    return this.http.get<ProductsRouting[]>(
      `${environment.apiUrl}product-routing`,
      {
        headers: this.headers,
        params: { productId },
      },
    );
  }

  getProductRoutingCodes(): Observable<ProductRoutingCodes[]> {
    return this.http.get<ProductRoutingCodes[]>(
      `${environment.apiUrl}product-routing/codes`,
      {
        headers: this.headers,
      },
    );
  }

  setProductRouting(productRouting: ProductsRouting[]) {
    return this.http.post(
      `${environment.apiUrl}product-routing`,
      productRouting,
      {
        headers: this.headers,
      },
    );
  }

  deleteProductRouting(id: number) {
    return this.http.delete(`${environment.apiUrl}product-routing/${id}`, {
      headers: this.headers,
    });
  }
}
