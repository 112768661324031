export const FINDINGS_TYPE = {
  JUMPRINGS: 1,
  POST: 2,
  FLYER: 3,
  SPRING: 4,
  SPRING_RING: 5,
  LOBSTER_CLAW: 6,
  TAGS: 7,
};

export const FINDING_MAX_WEIGHT = 3;
