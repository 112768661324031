import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipmentService } from 'src/app/services/shipments.service';
import { ShipmentRelatedInvoice } from '../../models/shipment-related-invoice';

@Component({
  selector: 'shipping-related-invoice-dialog',
  templateUrl: './shipping-related-invoice-dialog.component.html',
  styleUrls: ['./shipping-related-invoice-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShippingRelatedInvoiceDialogComponent implements OnInit {
  displayedColumns: string[] = ['invoicesID', 'invoicedDate', 'invoicePaid'];
  relatedInvoices: ShipmentRelatedInvoice[] = [];

  constructor(
    private shipmentService: ShipmentService,
    public dialogRef: MatDialogRef<ShippingRelatedInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public props: { shipmentId: number },
  ) {}

  ngOnInit(): void {
    this.shipmentService
      .getRelatedInvoices(this.props.shipmentId)
      .subscribe((relatedShipments) => {
        this.relatedInvoices = relatedShipments;
      });
  }
}
