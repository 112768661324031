import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { User } from 'src/app/core/authentication/user';
import { UserPermission } from 'src/app/core/user-permission/user-permission';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { DEFAULT_ROUTE_PAGE, menus } from 'src/app/custom/riva-side-nav/menu';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './user-permission-navigation-dialog.component.html',
  styleUrls: ['./user-permission-navigation-dialog.component.scss'],
})
export class UserPermissionNavigationDialogComponent implements OnInit {
  displayedColumns = ['page', 'permission'];
  userPermissions: UserPermission[] = [];
  accessLevels = [
    {
      value: 'SuperUser',
      label: 'Super User',
    },
    {
      value: 'User',
      label: 'User',
    },
    {
      value: 'Viewer',
      label: 'Viewer',
    },
    {
      value: 'Disabled',
      label: 'Disabled',
    },
  ];
  isSaving = false;
  menuPermission = menus;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User = {} as User,
    public dialogRef: MatDialogRef<UserPermissionNavigationDialogComponent>,
    private userPermissionService: UserPermissionService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userPermissionService
      .getUserPermissions(this.data.usersID)
      .subscribe((data) => {
        this.menuPermission = menus.reduce((permissionMenus, menu) => {
          const adminPermission = {
            usersPagesPermissionsID: 0,
            usersID: this.data.usersID,
            accessLevel: 'SuperUser',
            pageName: menu.pageName,
          };
          const normalPermission =
            menu.pageName != null
              ? data?.[menu.pageName] ?? {
                  usersPagesPermissionsID: 0,
                  usersID: this.data.usersID,
                  accessLevel: 'Disabled',
                  pageName: menu.pageName,
                }
              : null;
          const permission = this.data.isAdmin
            ? adminPermission
            : normalPermission;
          return [
            ...permissionMenus,
            {
              ...menu,
              isDefaultPage: menu.url === this.data.defaultUrl,
              permission,
              subMenu: menu.subMenu?.map((s) => {
                const adminPermissionSubMenu = {
                  usersPagesPermissionsID: 0,
                  usersID: this.data.usersID,
                  accessLevel: 'SuperUser',
                  pageName: s.pageName,
                };
                const normalPermissionSubMenu =
                  s.pageName != null
                    ? data?.[s.pageName] ?? {
                        usersPagesPermissionsID: 0,
                        usersID: this.data.usersID,
                        accessLevel: 'Disabled',
                        pageName: s.pageName,
                      }
                    : null;
                const permissionSubMenu = this.data.isAdmin
                  ? adminPermissionSubMenu
                  : normalPermissionSubMenu;
                return {
                  ...s,
                  isDefaultPage: s.url === this.data.defaultUrl,
                  permission: permissionSubMenu,
                };
              }),
            },
          ];
        }, []);
      });
  }

  onSavePermission() {
    this.isSaving = true;

    const permissions = this.menuPermission.reduce((accum, menu) => {
      let menuPermissions = [];
      if (menu.permission != null) {
        menuPermissions = [menu.permission];
      }
      if (menu.subMenu != null) {
        const subMenuPermissions = menu.subMenu.reduce(
          (subsPermissions, sub) => {
            if (sub.permission == null) return subsPermissions;
            return [...subsPermissions, sub.permission];
          },
          [],
        );
        menuPermissions = [...menuPermissions, ...subMenuPermissions];
      }
      return [...accum, ...menuPermissions];
    }, []);

    forkJoin([
      this.userPermissionService.setUserPermissions(permissions),
      this.userService.updateUser(this.data),
    ]).subscribe(() => {
      this.dialogRef.close(true);
      this.isSaving = false;
    });
  }
  onSetDefaultPage(url: string) {
    this.data.defaultUrl = url ?? DEFAULT_ROUTE_PAGE;
    this.menuPermission = this.menuPermission.map((m) => ({
      ...m,
      isDefaultPage: m.url === url,
      subMenu: m.subMenu?.map((s) => ({
        ...s,
        isDefaultPage: s.url === url,
      })),
    }));
  }
}
