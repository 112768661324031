<div class="bill-of-material">
  <div class="bill-of-material__section">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="bill-of-material__accordion-title"
          >Component (Custom)</mat-panel-title
        >
      </mat-expansion-panel-header>
      <app-sub-items
        [productId]="productId"
        [editMode]="editMode"
      ></app-sub-items>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="bill-of-material__accordion-title"
          >Components</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div>
        <button
          type="button"
          class="riva-button"
          style="
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
          "
          (click)="onDialogOpen()"
          *ngIf="editMode"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-plus"
            width="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#FFFFFF"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            style="margin-right: 3px"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
          New Component
        </button>
        <app-enamel-variation [productId]="productId"></app-enamel-variation>
        <app-enamel-constant [productId]="productId"></app-enamel-constant>
        <div
          style="
            margin-top: 15px;
            border: 1px solid #646464;
            padding: 15px;
            font-size: 15px;
          "
          *ngIf="productFindings.length"
        >
          <div style="margin-bottom: 10px">Product Findings</div>
          <product-findings-table
            [productFindings]="productFindings"
            [editMode]="editMode"
            (onDeleteFindings)="onDeleteFindings($event)"
          ></product-findings-table>
        </div>
        <div *ngIf="productId && !isReloading" class="stone-product-view">
          <stone-product
            [productId]="productId"
            [isVariation]="true"
            [tableOnly]="true"
            [editMode]="editMode"
            tableTitle="Stone Variations"
          ></stone-product>
          <stone-product
            [productId]="productId"
            [isVariation]="false"
            [tableOnly]="true"
            [editMode]="editMode"
            tableTitle="Stone Constants"
          ></stone-product>
        </div>
        <div
          *ngIf="productChainUnfinished.length || productChainFinished.length"
          class="product-chain"
        >
          <div *ngIf="productChainUnfinished.length">
            <div style="margin-bottom: 10px">Chain Unfinished</div>
            <product-chain-unfinished-table
              [productChainUnfinished]="productChainUnfinished"
              [editMode]="editMode"
            ></product-chain-unfinished-table>
          </div>
          <div *ngIf="productChainFinished.length">
            <div style="margin-bottom: 10px">Chain Finished</div>
            <product-chain-finished-table
              [productChainFinished]="productChainFinished"
              [editMode]="editMode"
            ></product-chain-finished-table>
          </div>
        </div>
        <product-link-bom-table
          [productId]="productId"
          [isDisplayOnly]="true"
        ></product-link-bom-table>
      </div>
    </mat-expansion-panel>
  </div>
</div>
