<div class="shipping-container">
  <div class="shipping-container__header">
    <div class="shipping-filters">
      <mat-form-field class="search-input" appearance="fill">
        <mat-label>Search</mat-label>
        <input
          matInput
          style="color: white"
          [(ngModel)]="searchQuery"
          (ngModelChange)="onSearch()"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Customer</mat-label>
        <mat-select
          placeholder="Customer"
          [(ngModel)]="shipmentParams.CustomerId"
          (ngModelChange)="getShipments()"
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              [hideClearSearchButton]="true"
              [formControl]="customerFilterCtrl"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="0"> All </mat-option>
          <mat-option
            *ngFor="let customer of filteredCustomers"
            [value]="customer.custIdno"
          >
            {{ customer.companyName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <button
        class="btn riva-button"
        (click)="goToShipping()"
        userPermission
        [elementKey]="featureKey.create"
      >
        <span>New Shipment</span>
      </button>
    </div>
  </div>
  <div style="border: 1px solid #646464">
    <mat-paginator
      [length]="pagination.totalCount"
      (page)="onPageChanged($event)"
      [pageSizeOptions]="[25]"
      showFirstLastButtons
      hidePageSize
      #paginator
      style="
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      "
    ></mat-paginator>
    <table
      mat-table
      [dataSource]="shipments"
      matSort
      matSortActive="id"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by id"
        >
          Shipment #
        </th>
        <td mat-cell *matCellDef="let element">
          <a
            class="riva-button riva-button-sm riva-button-link"
            [routerLink]="element.link"
            style="font-size: 14px"
          >
            {{ element.shipmentsID }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by qty"
        >
          Qty
        </th>
        <td mat-cell *matCellDef="let element">{{ element.qtyTotal }}</td>
      </ng-container>
      <ng-container matColumnDef="shipDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by shipDate"
        >
          Shipped Date
        </th>
        <td mat-cell *matCellDef="let element">{{ element.shipDate }}</td>
      </ng-container>
      <ng-container matColumnDef="tracking">
        <th mat-header-cell *matHeaderCellDef>Tracking #</th>
        <td mat-cell *matCellDef="let element">{{ element.tracking }}</td>
      </ng-container>
      <ng-container matColumnDef="customer">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by customer"
        >
          Customer
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.customer.companyName }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="work-order__action"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          'work-order-almost-due': row.isAlmostDue,
          'work-order-already-due': row.isAlreadyDue
        }"
      ></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="5">No Available Data</td>
      </tr>
    </table>
  </div>
</div>
