<div class="riva-chain-page">
  <button
    type="button"
    class="riva-button"
    (click)="createNewChainStyle()"
    style="
      width: 250px;
      position: absolute;
      right: 20px;
      top: 70px;
      z-index: 10000;
    "
  >
    Chain Styles
  </button>
  <div class="tab-container">
    <nz-tabset
      nzType="card"
      [nzSelectedIndex]="selectedTabIndex"
      (nzSelectedIndexChange)="onTabChange($event)"
    >
      <nz-tab nzTitle="Raw Chain">
        <riva-chain></riva-chain>
      </nz-tab>
      <nz-tab nzTitle="Finished Chain">
        <riva-chain-finish-list></riva-chain-finish-list>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
