<h3 mat-dialog-title>{{ data.title ?? "Confirmation" }}</h3>
<mat-dialog-content class="mat-typography">
  <div>{{ data.content }}</div>
</mat-dialog-content>

<mat-dialog-actions
  style="
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 15px;
  "
>
  <button
    class="btn btn-default"
    mat-dialog-close
    style="font-size: 12px; padding: 5px; height: 40px; color: white"
  >
    {{ data.cancelLabel ?? "Cancel" }}
  </button>
  <button
    class="btn btn-primary"
    style="font-size: 12px; padding: 5px; height: 40px"
    [mat-dialog-close]="true"
    autofocus
  >
    {{ data.confirmLabel ?? "Confirm" }}
  </button>
</mat-dialog-actions>
