import { Component, Input, OnInit } from '@angular/core';
import { ProductBomService } from 'src/app/services/product-bom.service';

@Component({
  selector: 'product-link-bom',
  templateUrl: './product-link-bom.component.html',
  styleUrls: ['./product-link-bom.component.scss'],
})
export class ProductLinkBomComponent implements OnInit {
  @Input() productId: number;

  constructor(private productBomService: ProductBomService) {}

  ngOnInit(): void {}
}
