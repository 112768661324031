<div class="row main-row" style="margin-top: 70px">
  <div class="col ml-5">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Chain">
        <app-chain></app-chain>
      </mat-tab>
      <mat-tab label="Chain Finished">
        <app-chain-finished></app-chain-finished>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
