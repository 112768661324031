import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ChainService } from 'src/app/services/chain.service';
import { ChainRaw, ChainStyle } from '../models';

@Component({
  templateUrl: './chain-raw-dialog.component.html',
  styleUrls: ['./chain-raw-dialog.component.scss'],
})
export class ChainRawDialogComponent implements OnInit {
  isSaving = false;
  chainRaw: ChainRaw = {} as ChainRaw;
  chainStyles: ChainStyle[] = [];

  constructor(
    public dialogRef: MatDialogRef<ChainRawDialogComponent>,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private chainService: ChainService,
  ) {}

  ngOnInit(): void {
    this.chainService.getChainStyles().subscribe((data) => {
      this.chainStyles = data;
    });
  }

  get isFormValid() {
    const { chainStyleID, sku, linkDim1, linkDim2, wireSize } = this.chainRaw;
    const scenario1 =
      linkDim1 && linkDim1 < 1000 && linkDim2 && linkDim2 < 1000;

    const scenario2 =
      linkDim1 && linkDim1 < 1000 && linkDim2 && linkDim2 < 1000;

    const scenario3 = linkDim1 && linkDim1 < 1000 && !linkDim2;
    return (
      chainStyleID && sku && wireSize && (scenario1 || scenario2 || scenario3)
    );
  }

  onSaveChainRawSize() {
    this.chainService.setChainRaw(this.chainRaw).subscribe((data) => {
      this.dialogRef.close(data);
    });
  }

  onConvertMmToInches(fieldFrom, fieldTo) {
    this.chainRaw[fieldTo] = +(
      (this.chainRaw[fieldFrom] ?? 0) * 0.0393701
    ).toFixed(4);
  }
  onConvertInchesToMm(fieldFrom, fieldTo) {
    this.chainRaw[fieldTo] = +((this.chainRaw[fieldFrom] ?? 0) * 25.4).toFixed(
      4,
    );
  }
}
