<div
  style="margin: 10px 0"
  *ngIf="isDisplayOnly && productLinksBom.data.length > 0"
>
  Product BOM
</div>
<div
  class="product-link-bom-table"
  [ngClass]="{ 'is-display ': isDisplayOnly }"
  *ngIf="!isDisplayOnly || (isDisplayOnly && productLinksBom.data.length > 0)"
>
  <table mat-table [dataSource]="productLinksBom">
    <ng-container matColumnDef="picPath">
      <th mat-header-cell *matHeaderCellDef>Pic</th>
      <td mat-cell *matCellDef="let element">
        <div
          style="
            width: 55px;
            padding: 5px;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          "
        >
          <img
            style="max-width: 100%; max-height: 100%"
            [src]="element.picPath"
            *ngIf="element.picPath"
            onerror="this.src='assets/images/no-image.png'"
          />
          <img
            style="max-width: 100%; max-height: 100%"
            src="assets/images/no-image.png"
            *ngIf="!element.picPath"
          />
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="productName">
      <th mat-header-cell *matHeaderCellDef>Product</th>
      <td mat-cell *matCellDef="let element">{{ element.productName }}</td>
    </ng-container>
    <ng-container matColumnDef="sizes">
      <th mat-header-cell *matHeaderCellDef>Size</th>
      <td mat-cell *matCellDef="let element">
        <div [ngClass]="{ 'two-column': element.sizes?.length > 4 }">
          <div class="sizes__info" *ngFor="let size of element.sizes">
            <div class="sizes__info-size">
              <span>{{ size.bomSize }}</span>
            </div>
            <div class="sizes__info-details">
              <div>{{ size.primarySize || 'All' }}</div>
              <span class="other-details">Qty: {{ size.qty || 0 }}</span>
            </div>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <i
          nz-icon
          nzType="delete"
          nzTheme="fill"
          class="variation-delete"
          (click)="onDeleteProductLink(element)"
        ></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="6">No Available Data</td>
    </tr>
  </table>
</div>
