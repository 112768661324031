<div class="riva-findings">
  <div class="tab-container" style="margin-top: 30px">
    <nz-tabset
      nzType="card"
      [nzSelectedIndex]="selectedTabIndex"
      (nzSelectedIndexChange)="onTabChange($event)"
    >
      <nz-tab nzTitle="Jumprings">
        <finding-jumpring
          [findings]="findings"
          (onReloadList)="getFindingsSize()"
          (onOpenSetting)="onOpenSetting($event)"
        ></finding-jumpring>
      </nz-tab>
      <nz-tab nzTitle="Posts">
        <finding-post
          [findings]="findings"
          (onReloadList)="getFindingsSize()"
          (onOpenSetting)="onOpenSetting($event)"
        ></finding-post>
      </nz-tab>
      <nz-tab nzTitle="Flyers">
        <finding-flyer
          [findings]="findings"
          (onReloadList)="getFindingsSize()"
          (onOpenSetting)="onOpenSetting($event)"
        ></finding-flyer>
      </nz-tab>
      <nz-tab nzTitle="Springs">
        <finding-spring
          [findings]="findings"
          (onReloadList)="getFindingsSize()"
          (onOpenSetting)="onOpenSetting($event)"
        ></finding-spring>
      </nz-tab>
      <nz-tab nzTitle="Springrings">
        <finding-spring-ring
          [findings]="findings"
          (onReloadList)="getFindingsSize()"
          (onOpenSetting)="onOpenSetting($event)"
        ></finding-spring-ring>
      </nz-tab>
      <nz-tab nzTitle="Lobster Claw">
        <finding-lobster-claw
          [findings]="findings"
          (onReloadList)="getFindingsSize()"
          (onOpenSetting)="onOpenSetting($event)"
        ></finding-lobster-claw>
      </nz-tab>
      <nz-tab nzTitle="Tags">
        <finding-tags
          [findings]="findings"
          (onReloadList)="getFindingsSize()"
          (onOpenSetting)="onOpenSetting($event)"
        ></finding-tags>
      </nz-tab>
    </nz-tabset>
  </div>
</div>
