import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InvoiceItemType } from '../components/invoicing-shipping/invoicing/models/invoice';
import { GenericService } from './generic.service';

export interface AnalyticsInvoice {
  invoicedDate: string;
  qtyTotal: number;
  totalDue: number;
}
export interface AnalyticsCustomer {
  custIdNo: number;
  customer: string;
  invoices: AnalyticsInvoice[];
}

export interface AnalyticsShipment {
  shipDate: string;
  totalQty: number;
}
export interface AnalyticsCustomerShipment {
  custIdNo: number;
  customer: string;
  shipments: AnalyticsShipment[];
}

export interface AnalyticsOrder {
  entryDate: string;
  totalQty: number;
}
export interface AnalyticsCustomerOrder {
  custIdNo: number;
  customer: string;
  orders: AnalyticsOrder[];
}

export interface AnalyticsProduct {
  entryDate: string;
  totalQty: number;
  metal: string;
}

export interface AnalyticsProductOrder {
  productId: number;
  productName: string;
  picturePath: string;
  orderEntry: AnalyticsProduct[];
  materials?: AnalyticsProduct[];
}

export interface AnalyticsMaterial {
  materialCodeId: number;
  code: string;
  description: string;
  totalWeightShipped: number;
}

export interface InvoicedTimePeriod {
  totalInvoiced: number;
}

export interface InvoicedStone {
  stoneId: number;
  stoneType: string;
  stoneCut: string;
  stoneClarity: string;
  stoneColor: string;
  stoneSize: string;
  labGrown: boolean;
  traceable: boolean;
  invoicedQty: number;
  customerProvided: boolean;
}

export interface AnalyticsClosedItemWeight {
  workOrdersId: number;
  closedDate: string;
  workOrdersDetailsId: number;
  closedQty: number;
  individualClosedWeight: number;
  ordersDetailsId: number;
  ordersDetailsAltId: number;
  type: string;
  pictureFileName: string;
  picturePath: string;
  itemType: number;
  itemId: number;
  itemName: string;
  totalClosedWeight: number;
  metalDescription: string;
  size: string;
}

interface RollingWeightAverageParams {
  type: InvoiceItemType;
  itemId: number; // productId, chainRawId, chainFinishId, findingsId
  sizeId?: number;
  materialCodeId: number;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends GenericService {
  baseUrl = environment.apiUrl + 'analytics';

  constructor(http: HttpClient) {
    super(http);
  }

  getAnalyticsInvoices() {
    return this.http.get<AnalyticsCustomer[]>(`${this.baseUrl}/invoices`, {
      headers: this.headers,
    });
  }

  getAnalyticsShipments() {
    return this.http.get<AnalyticsCustomerShipment[]>(
      `${this.baseUrl}/shipments`,
      {
        headers: this.headers,
      },
    );
  }

  getAnalyticsOrders() {
    return this.http.get<AnalyticsCustomerOrder[]>(`${this.baseUrl}/orders`, {
      headers: this.headers,
    });
  }

  getAnalyticsTopProduct() {
    return this.http.get<AnalyticsProductOrder[]>(
      `${this.baseUrl}/popular-products`,
      {
        headers: this.headers,
      },
    );
  }
  getAnalyticsTopUnfinishedChain() {
    return this.http.get<AnalyticsProductOrder[]>(
      `${this.baseUrl}/popular-chain-raw`,
      {
        headers: this.headers,
      },
    );
  }
  getAnalyticsTopFinishedChain() {
    return this.http.get<AnalyticsProductOrder[]>(
      `${this.baseUrl}/popular-chain-finished`,
      {
        headers: this.headers,
      },
    );
  }
  getAnalyticsTopFindings() {
    return this.http.get<AnalyticsProductOrder[]>(
      `${this.baseUrl}/popular-findings`,
      {
        headers: this.headers,
      },
    );
  }
  getAnalyticsTopOtherComponents() {
    return this.http.get<AnalyticsProductOrder[]>(
      `${this.baseUrl}/popular-other-components`,
      {
        headers: this.headers,
      },
    );
  }
  getAnalyticsClosedItemWeights() {
    return this.http
      .get<AnalyticsClosedItemWeight[]>(`${this.baseUrl}/closed-item-weights`, {
        headers: this.headers,
      })
      .pipe(
        map((data) =>
          data.map((d) => ({
            ...d,
            itemName: d.itemName ?? '',
            totalClosedWeight: d.individualClosedWeight,
            individualClosedWeight: d.individualClosedWeight / d.closedQty,
          })),
        ),
      );
  }
  getAnalyticsInvoicedWeights({ start, end }) {
    return this.http.get<AnalyticsMaterial[]>(
      `${this.baseUrl}/invoiced-weights-report`,
      {
        headers: this.headers,
        params: {
          start,
          end,
        },
      },
    );
  }
  getAnalyticsInvoicedTimePeriod({ start, end }) {
    return this.http.get<InvoicedTimePeriod>(
      `${this.baseUrl}/invoiced-time-period`,
      {
        headers: this.headers,
        params: {
          start,
          end,
        },
      },
    );
  }
  getAnalyticsInvoicedStones({ start, end }) {
    return this.http.get<InvoicedStone[]>(`${this.baseUrl}/invoiced-stones`, {
      headers: this.headers,
      params: {
        start,
        end,
      },
    });
  }
  getRollingWeightAverage(params: RollingWeightAverageParams) {
    return this.http.get<number>(`${this.baseUrl}/rolling-weight-average`, {
      headers: this.headers,
      params: { ...params },
    });
  }
}
