import { Component, Input, OnInit } from '@angular/core';
import { ProductChainFinished } from '../model';

@Component({
  selector: 'product-chain-finished-table',
  styleUrls: ['./product-chain-finished-table.component.scss'],
  templateUrl: './product-chain-finished-table.component.html',
})
export class ProductChainFinishedTableComponent implements OnInit {
  @Input() productChainFinished: ProductChainFinished[];
  @Input() editMode: boolean;

  displayedColumns: string[] = ['picPath', 'name', 'styleName', 'sku', 'size'];
  action: string[] = ['delete'];

  ngOnInit(): void {}
}
