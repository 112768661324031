<div class="set-permission-dialog">
  <h3
    style="display: flex; align-items: center; justify-content: space-between"
    mat-dialog-title
  >
    <span>User Permissions</span>
    <div style="display: flex; align-items: center; font-size: 14px">
      <div style="margin-right: 15px">Administrator</div>
      <div>
        <nz-switch
          [(ngModel)]="data.isAdmin"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
          [disabled]="true"
        ></nz-switch>
        <ng-template #checkedTemplate
          ><i nz-icon nzType="check"></i
        ></ng-template>
        <ng-template #unCheckedTemplate
          ><i nz-icon nzType="close"></i
        ></ng-template>
      </div>
    </div>
  </h3>
  <mat-dialog-content class="mat-typography" style="min-height: 400px">
    <div style="border: 1px solid #646464">
      <table mat-table [dataSource]="userPermissions">
        <ng-container matColumnDef="page">
          <th mat-header-cell *matHeaderCellDef>Page</th>
          <td mat-cell *matCellDef="let element">{{ element.label }}</td>
        </ng-container>
        <ng-container matColumnDef="permission">
          <th mat-header-cell *matHeaderCellDef>Permission</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field
              appearance="fill"
              style="width: 150px; margin-bottom: -15px"
            >
              <mat-label>Access Level</mat-label>
              <mat-select
                [(ngModel)]="element.accessLevel"
                [disabled]="data.isAdmin || element.disabled"
              >
                <mat-option
                  *ngFor="let accessLevel of accessLevels"
                  [value]="accessLevel.value"
                >
                  {{ accessLevel.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center" colspan="2">No Available Data</td>
        </tr>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px"
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      (click)="onSavePermission()"
      [disabled]="isSaving"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
