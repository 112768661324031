<div class="invoicing-container">
  <div class="invoicing-container__header">
    <div class="invoicing-filters">
      <mat-form-field class="search-input" appearance="fill">
        <mat-label>Search</mat-label>
        <input
          matInput
          style="color: white"
          [(ngModel)]="searchQuery"
          (ngModelChange)="onSearch()"
        />
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Customer</mat-label>
        <mat-select
          placeholder="Customer"
          [(ngModel)]="invoiceParams.CustomerId"
          (ngModelChange)="getInvoices()"
        >
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="Search"
              [hideClearSearchButton]="true"
              [formControl]="customerFilterCtrl"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="0"> All </mat-option>
          <mat-option
            *ngFor="let customer of filteredCustomers"
            [value]="customer.custIdno"
          >
            {{ customer.companyName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="export-action">
        <riva-date-range-picker
          [startDate]="filters.dateRangeFrom"
          [endDate]="filters.dateRangeTo"
          (onChange)="onDateRangeChange($event)"
        ></riva-date-range-picker>
        <button
          [disabled]="isExportingFile"
          class="btn riva-button"
          (click)="onDownloadExcelFile()"
        >
          <span>Export to Excel</span>
        </button>
      </div>
    </div>
    <div class="invoice-action">
      <button
        class="btn riva-button"
        (click)="goToInvoice()"
        userPermission
        [elementKey]="featureKey.create"
      >
        <span>New Invoice</span>
      </button>
    </div>
  </div>
  <div style="border: 1px solid #646464">
    <mat-paginator
      [length]="pagination.totalCount"
      (page)="onPageChanged($event)"
      [pageSizeOptions]="[25]"
      showFirstLastButtons
      hidePageSize
      #paginator
      style="
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      "
    ></mat-paginator>
    <table
      mat-table
      [dataSource]="invoices"
      matSort
      matSortActive="id"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by id"
        >
          Invoice #
        </th>
        <td mat-cell *matCellDef="let element">
          <a
            class="riva-button riva-button-sm riva-button-link"
            [routerLink]="element.link"
            style="font-size: 14px"
          >
            {{ element.invoicesID }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="invoicedDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by invoicedDate"
        >
          Invoiced Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.invoicedDate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="customer">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by customer"
        >
          Customer
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.customer.companyName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="qty">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by qty"
        >
          Qty
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.qtyTotal }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalDue">
        <th mat-header-cell *matHeaderCellDef>Total Billed</th>
        <td mat-cell *matCellDef="let element">
          {{ element.totalDue | currency }}
        </td>
      </ng-container>
      <ng-container matColumnDef="invoicePaid">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by invoicePaid"
        >
          Paid Date
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.invoicePaid">{{
            element.invoicePaid | date : 'MM/dd/yyyy'
          }}</span>
          <button
            class="btn-riva btn-riva-sm"
            style="font-size: 12px"
            *ngIf="!element.invoicePaid"
            (click)="onPaidInvoices(element)"
            userPermission
            [elementKey]="featureKey.markAsPaid"
          >
            Paid
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="6">No Available Data</td>
      </tr>
    </table>
  </div>
</div>
