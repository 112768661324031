<div class="jumpring-dialog">
  <h3 mat-dialog-title>New Tag</h3>
  <mat-dialog-content class="mat-typography">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Customer</mat-label>
      <mat-select [(ngModel)]="tag.customerId">
        <mat-option
          *ngFor="let customer of customers"
          [value]="customer.custIdno"
        >
          {{ customer.companyName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="tagName" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Length</mat-label>
      <input matInput type="number" [(ngModel)]="tag.size_1" max="99.99" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Width</mat-label>
      <input matInput type="number" [(ngModel)]="tag.size_2" max="99.99" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Thickness</mat-label>
      <input matInput type="number" [(ngModel)]="tag.size_3" max="99.99" />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions
    style="display: flex; justify-content: center; align-items: center"
  >
    <div class="action-buttons">
      <button
        class="btn btn-default"
        mat-dialog-close
        style="font-size: 12px; padding: 5px; height: 40px; color: white"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary"
        style="font-size: 12px; padding: 5px; height: 40px"
        [disabled]="isSaving || !isFormValid"
        (click)="onSaveFinding()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
