import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Invoice } from '../components/invoicing-shipping/invoicing/models/invoice';
import {
  OpenOrder,
  OrderParams,
  Orders,
  RivaOrders,
  StoneOpenOrder,
} from '../models/orders.model';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends GenericService {
  baseUrl = environment.apiUrl + 'orders';
  ordersImportUrl = environment.apiUrl + 'orders-import';

  constructor(http: HttpClient) {
    super(http);
  }

  getListOrders(): Observable<any> {
    return this.http.get(this.baseUrl + '/list', { headers: this.headers });
  }

  getListOpenOrders(): Observable<any> {
    return this.http.get(this.baseUrl + '/open-orders', {
      headers: this.headers,
    });
  }

  getOrderById(orderId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/get/' + orderId, {
      headers: this.headers,
    });
  }

  getOrderByCustomerId(customerId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/GetByCustomerId/' + customerId, {
      headers: this.headers,
    });
  }

  getByOrderDetailsId(orderDetailsId: number): Observable<any> {
    return this.http.get(this.baseUrl + '/parent-order/' + orderDetailsId, {
      headers: this.headers,
    });
  }

  getListOrderStatus(): Observable<any> {
    return this.http.get(this.baseUrl + '/statuslist', {
      headers: this.headers,
    });
  }

  addupdate(order: Orders | any): Observable<any> {
    return this.http.post(this.baseUrl + '/addupdate', order, {
      headers: this.headers,
    });
  }

  upload(formData: FormData, dueDate: Date, client: string): Observable<any> {
    if (client === 'hoffulfill') {
      return this.http.post(
        this.baseUrl +
          '/' +
          client +
          '/upload?dueDate=' +
          dueDate.toLocaleString(),
        formData,
        { headers: this.customHeader, reportProgress: true, observe: 'events' },
      );
    } else {
      return this.http.post(this.baseUrl + '/' + client + '/upload', formData, {
        headers: this.customHeader,
        reportProgress: true,
        observe: 'events',
      });
    }
  }

  getOrders(): Observable<RivaOrders[]> {
    return this.http.get<RivaOrders[]>(this.baseUrl, {
      headers: this.headers,
    });
  }

  getOrdersWithPagination(params: OrderParams): Observable<any> {
    return this.http.get(`${this.baseUrl}/paged`, {
      headers: this.headers,
      params: { ...params },
      observe: 'response',
    });
  }

  getRelatedInvoices(id: number) {
    return this.http.get<Invoice[]>(`${this.baseUrl}/invoices/${id}`, {
      headers: this.headers,
    });
  }

  getOpenOrder(params: {
    CustomerId: number;
    FromDate: string;
    ToDate: string;
    OrderBy: string;
    SortDirection: string;
    ShowOverdueOnly: boolean;
  }) {
    return this.http.get<{
      data: OpenOrder[];
      customers: Array<{ customerId: number; customerName: string }>;
    }>(`${this.baseUrl}/open`, {
      headers: this.headers,
      params,
    });
  }

  getStoneOpenOrder(params: {
    CustomerId: number;
    Status: number;
    OrderBy: string;
    SortDirection: string;
    Take: number;
  }) {
    return this.http.get<{
      data: StoneOpenOrder[];
      customers: Array<{ customerId: number; customerName: string }>;
    }>(`${this.baseUrl}/open-stones`, {
      headers: this.headers,
      params,
    });
  }

  uploadOrders(formData, params) {
    return this.http.post(`${this.ordersImportUrl}/upload`, formData, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
      params
    });
  }
}
