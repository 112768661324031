import { HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import orderBy from 'lodash/orderBy';
import { ToastrService } from 'ngx-toastr';
import { MaterialCode } from 'src/app/models/material-code';
import { ChainService } from 'src/app/services/chain.service';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { ChainRoutingDialogComponent } from '../../riva-chain-routing/chain-routing-dialog.component';
import { ChainStyle } from '../../riva-chain/models';
import { ChainFinishBomDialogComponent } from '../chain-finish-bom-dialog/chain-finish-bom-dialog.component';
import { ChainFinishDialogComponent } from '../chain-finish-dialog/chain-finish-dialog.component';
import { ChainFinishedSkuGeneratorDialogComponent } from '../chain-finished-sku-generator-dialog/chain-finished-sku-generator-dialog.component';
import {
  ChainFinish,
  ChainFinishBillOfMaterial,
  ChainFinishMaterial,
  ChainFinishSize,
  ChainFinishStock,
} from '../models';

@Component({
  selector: 'riva-chain-finish-list',
  templateUrl: './riva-chain-finish-list.component.html',
  styleUrls: ['./riva-chain-finish-list.component.scss'],
})
export class RivaChainFinishListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['name', 'description', 'styleName', 'sku'];
  displayedColumnsForBOM = ['picPath', 'name', 'size', 'qty', 'comment'];

  search: string;
  editMode = false;

  chainFinishes = new MatTableDataSource<ChainFinish>([]);
  selectedChainFinish: ChainFinish = {} as ChainFinish;
  chainFinishSizes: ChainFinishSize[] = [];
  selectedChainFinishSize: ChainFinishSize = {} as ChainFinishSize;
  newSize = '';
  showSizeError = false;
  isSavingNewSize = false;
  isSavingStock = false;

  chainFinishStocks: ChainFinishStock[] = [];
  selectedChainFinishStock: ChainFinishStock = {} as ChainFinishStock;
  selectedMaterialCode: number;
  isSavingMaterial = false;

  chainFinishMaterials: ChainFinishMaterial[] = [];
  selectedChainFinishMaterial: ChainFinishMaterial = {} as ChainFinishMaterial;

  materialCodes: MaterialCode[] = [];
  filteredMaterialCodes: MaterialCode[] = [];

  billOfMaterials = new MatTableDataSource<ChainFinishBillOfMaterial>([]);

  chainStyles: ChainStyle[] = [];
  filterChainStyleID = 0;

  constructor(
    public dialog: MatDialog,
    private chainService: ChainService,
    private materialCodeService: MaterialCodeService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.materialCodeService.getList().subscribe(({ responseObject }) => {
      this.materialCodes = responseObject.filter((m) => !m.multiMetal);
      const chainId = localStorage.getItem('selected-chain-finished-id');
      this.getChains(chainId ? +chainId : null);
      localStorage.removeItem('selected-chain-finished-id');
    });
    this.chainService.reloadFinishChainTypes = () => {
      this.getChainStyles();
    };
    this.getChainStyles();
  }

  getChainStyles() {
    this.chainService.getChainStyles().subscribe((data) => {
      this.chainStyles = [
        {
          chainStylesID: 0,
          styleName: 'All',
        },
        ...data,
      ];
    });
  }

  ngAfterViewInit() {
    this.chainFinishes.sort = this.sort;
    this.chainFinishes.filterPredicate = (
      data: ChainFinish,
      filterValue: string,
    ) => {
      const { search = '', chainStyleId = 0 } = JSON.parse(filterValue) ?? {};
      const searchFilter =
        data.name?.toLowerCase().includes(search?.toLowerCase()) ||
        data.styleName?.toLowerCase().includes(search?.toLowerCase()) ||
        data.sku?.toLowerCase().includes(search?.toLowerCase());
      return (
        searchFilter &&
        (chainStyleId === 0 ||
          data.chainRaw.chainStyle.chainStylesID === chainStyleId)
      );
    };
  }

  getChains(chainsId?: number) {
    this.chainService.getChainFinished().subscribe((data) => {
      this.chainFinishes.data = data;
      const chain = chainsId
        ? this.chainFinishes.data.find((c) => c.chainFinishedID === chainsId)
        : this.chainFinishes.data[0];
      this.selectedChainFinish = { ...(chain ?? ({} as ChainFinish)) };
      this.getChainBom(this.selectedChainFinish.chainFinishedID);
      this.getChainSizes(this.selectedChainFinish.chainFinishedID);
      this.getChainMaterials(this.selectedChainFinish.chainFinishedID);
    });
  }
  getChainMaterials(chainFinishedId) {
    if (!chainFinishedId) {
      this.chainFinishMaterials = [];
      return;
    }
    this.chainService
      .getChainFinishedMaterials(chainFinishedId)
      .subscribe((data = []) => {
        this.chainFinishMaterials = orderBy(
          data,
          (d: ChainFinishMaterial) => d.material.code,
          'desc',
        );
        this.selectedChainFinishMaterial =
          data[0] ?? ({} as ChainFinishMaterial);
        this.filteredMaterialCodes = this.materialCodes.filter(
          (m) => !data.some((c) => c.materialsCodeID === m.materialCodeId),
        );
        this.getChainFinishedStocks();
      });
  }

  getChainSizes(chainFinishedId) {
    if (!chainFinishedId) return;
    this.chainService
      .getChainFinishedSizes(chainFinishedId)
      .subscribe((data = []) => {
        const sizes = data.reduce(
          (accum, size) => {
            const isNumber = !Number.isNaN(Number(size.size));
            if (isNumber) {
              const value = Number(size.size);
              accum.numeric = [...accum.numeric, { ...size, size: value }];
            } else {
              accum.alpha = [...accum.alpha, size];
            }
            return accum;
          },
          {
            alpha: [],
            numeric: [],
          },
        );
        const sortedNumber = orderBy(
          sizes.numeric,
          (s: ChainFinishSize) => s.size,
          'desc',
        );
        const convertedSizeToString = sortedNumber.map((s) => ({
          ...s,
          size: s.size?.toString(),
        }));

        this.chainFinishSizes = [
          ...convertedSizeToString,
          ...orderBy(sizes.alpha, (s: ChainFinishSize) => s.size, 'desc'),
        ];
        this.selectedChainFinishSize = data[0] ?? ({} as ChainFinishSize);
        this.getChainFinishedStocks();
      });
  }
  getChainBom(id) {
    if (!id) return;
    this.chainService.getChainFinishedBom(id).subscribe((data) => {
      this.billOfMaterials.data = data;
    });
  }
  onSelectChain(chain: ChainFinish) {
    this.selectedChainFinish = { ...chain };
    this.getChainBom(this.selectedChainFinish.chainFinishedID);
    this.getChainSizes(this.selectedChainFinish.chainFinishedID);
    this.getChainMaterials(this.selectedChainFinish.chainFinishedID);
  }
  onSelectSize(size: ChainFinishSize) {
    this.selectedChainFinishSize = { ...size };
    this.getChainFinishedStocks();
  }
  onSaveChainSize() {
    const isExist = this.chainFinishSizes.some(
      (c) => c.size?.toLowerCase() === this.newSize?.trim().toLowerCase(),
    );
    if (isExist) {
      this.showSizeError = true;
      return;
    }
    this.showSizeError = false;
    this.isSavingNewSize = true;
    this.chainService
      .setChainFinishedSizes({
        size: this.newSize,
        chainFinishedID: this.selectedChainFinish.chainFinishedID,
        chainFinishedSizesID: 0,
      })
      .subscribe(() => {
        this.newSize = '';
        this.getChainSizes(this.selectedChainFinish.chainFinishedID);
        this.isSavingNewSize = false;
      });
  }
  onSaveMaterial() {
    const data = {
      chainFinishedMaterialsID: 0,
      chainFinishedID: this.selectedChainFinish.chainFinishedID,
      materialsCodeID: this.selectedMaterialCode,
    };
    this.isSavingMaterial = true;
    this.chainService.setChainFinishedMaterial(data).subscribe(() => {
      this.selectedMaterialCode = 0;
      this.isSavingMaterial = false;
      this.getChainMaterials(this.selectedChainFinish.chainFinishedID);
    });
  }
  onSaveStock(isNew = false) {
    const {
      chainFinishedStockID,
      qtyInStock,
      chainFinishedSizesID,
      chainTotalWeight,
    } = this.selectedChainFinishStock;

    this.isSavingStock = true;
    this.chainService
      .setChainFinishedStocks({
        chainFinishedStockID: isNew ? 0 : chainFinishedStockID,
        materialCodesID: this.selectedChainFinishMaterial.materialsCodeID,
        qtyInStock,
        chainFinishedSizesID:
          chainFinishedSizesID ??
          this.selectedChainFinishSize.chainFinishedSizesID,
        chainTotalWeight,
      })
      .subscribe(() => {
        this.selectedMaterialCode = 0;
        this.isSavingStock = false;
        this.toastrService.success(
          'Successfully saved.',
          'Chain Finished Stock',
        );
        this.getChainFinishedStocks();
      });
  }
  getChainFinishedStocks() {
    if (!this.selectedChainFinishSize.chainFinishedSizesID) {
      this.selectedChainFinishStock = {} as ChainFinishStock;
      this.chainFinishStocks = [];
      return;
    }
    this.chainService
      .getChainFinishedStocks(this.selectedChainFinishSize.chainFinishedSizesID)
      .subscribe((data = []) => {
        this.chainFinishStocks = data;
        this.setSelectedStock();
      });
  }

  setSelectedStock() {
    if (
      !this.selectedChainFinishSize.chainFinishedSizesID ||
      !this.selectedChainFinishMaterial.materialsCodeID
    )
      return;

    this.selectedChainFinishStock =
      this.chainFinishStocks.find(
        (s) =>
          s.chainFinishedSizesID ===
            this.selectedChainFinishSize.chainFinishedSizesID &&
          s.materialCodesID ===
            this.selectedChainFinishMaterial.materialsCodeID,
      ) ?? ({} as ChainFinishStock);
  }

  onFilterChange() {
    this.chainFinishes.filter = JSON.stringify({
      search: this.search,
      chainStyleId: this.filterChainStyleID,
    });
  }

  createNewChainFinish() {
    const ref = this.dialog.open(ChainFinishDialogComponent, {
      disableClose: true,
      maxWidth: '800px',
      width: '100%',
    });
    ref.afterClosed().subscribe((data) => {
      this.getChains(data);
    });
  }

  createNewChainFinishBom() {
    const ref = this.dialog.open(ChainFinishBomDialogComponent, {
      disableClose: true,
      maxWidth: '400px',
      width: '100%',
      autoFocus: false,
      data: {
        chainFinishedID: this.selectedChainFinish.chainFinishedID,
      },
    });
    ref.afterClosed().subscribe(() => {
      this.getChainBom(this.selectedChainFinish.chainFinishedID);
    });
  }
  onChangeMaterialCode() {
    const stock = this.chainFinishStocks.find(
      (s) =>
        s.materialCodesID === this.selectedChainFinishStock.materialCodesID,
    );
    this.selectedChainFinishStock = stock
      ? { ...stock }
      : ({
          materialCodesID: this.selectedChainFinishStock.materialCodesID,
        } as ChainFinishStock);
  }
  get isStockFormValid() {
    return (
      this.selectedChainFinishSize.chainFinishedSizesID &&
      this.selectedChainFinishMaterial.materialsCodeID &&
      this.selectedChainFinishStock.qtyInStock &&
      Number.isInteger(this.selectedChainFinishStock.qtyInStock)
    );
  }
  onSetEditMode() {
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.getChains(this.selectedChainFinish.chainFinishedID);
    }
  }
  onSaveFinishDetail() {
    if (
      this.selectedChainFinish.findingsLength &&
      this.selectedChainFinish.findingsLength >= 10
    ) {
      return;
    }
    this.chainService
      .setChainFinished(this.selectedChainFinish)
      .subscribe(() => {
        this.chainFinishes.data = this.chainFinishes.data.map((c) => {
          if (c.chainFinishedID === this.selectedChainFinish.chainFinishedID) {
            return {
              ...c,
              name: this.selectedChainFinish.name,
              description: this.selectedChainFinish.description,
            };
          }
          return c;
        });
      });
  }

  onUploadImage(files: FileList) {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    formData.append('file[]', files[0]);

    formData.append(
      'chainFinishedId',
      this.selectedChainFinish.chainFinishedID.toString(),
    );

    this.chainService.uploadFinishedImage(formData).subscribe((event) => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log(
          'Uploading: ' + Math.round((100 * event.loaded) / event.total) + '%',
        );
      } else if (event.type === HttpEventType.Response) {
        this.selectedChainFinish.picPath = event.body;
        this.toastrService.success('Successfully uploaded.');
      }
    });
  }
  onSelectMaterial(material) {
    this.selectedChainFinishMaterial = { ...material };
    if (this.chainFinishStocks.length) {
      this.setSelectedStock();
    }
  }

  onRoutingDialogOpen() {
    this.dialog.open(ChainRoutingDialogComponent, {
      disableClose: true,
      maxWidth: '1400px',
      width: '100%',
      autoFocus: false,
      data: {
        chainId: this.selectedChainFinish.chainFinishedID,
        chainType: 1,
        editMode: this.editMode,
      },
    });
  }

  onOpenGenerateSkuDialog() {
    this.dialog.open(ChainFinishedSkuGeneratorDialogComponent, {
      disableClose: true,
      maxWidth: '450px',
      width: '100%',
      data: {
        chainId: this.selectedChainFinish.chainFinishedID,
        materialCodeId: this.selectedChainFinishMaterial.materialsCodeID,
        chainSizeId: this.selectedChainFinishSize.chainFinishedSizesID,
      },
      autoFocus: false,
    });
  }
}
