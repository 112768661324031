<div
  class="tracking-banner"
  *ngIf="workOrderTracking != null || jiraDetail != null"
>
  {{ bannerMessage }}
  <button class="riva-button" (click)="resetScan()" tabindex="-1">Reset</button>
</div>
<div class="work-order-tracking">
  <div
    class="qr-code-scanner-container"
    *ngIf="workOrderTracking == null && jiraDetail == null"
  >
    <div>
      <div class="scan-label">Scan Work Order</div>
      <div class="sub-label">Work Order Tracking</div>
      <div class="qr-code-scanner">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-qrcode"
          width="70%"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x="4" y="4" width="6" height="6" rx="1" />
          <line x1="7" y1="17" x2="7" y2="17.01" />
          <rect x="14" y="4" width="6" height="6" rx="1" />
          <line x1="7" y1="7" x2="7" y2="7.01" />
          <rect x="4" y="14" width="6" height="6" rx="1" />
          <line x1="17" y1="7" x2="17" y2="7.01" />
          <line x1="14" y1="14" x2="17" y2="14" />
          <line x1="20" y1="14" x2="20" y2="14.01" />
          <line x1="14" y1="14" x2="14" y2="17" />
          <line x1="14" y1="20" x2="17" y2="20" />
          <line x1="17" y1="17" x2="20" y2="17" />
          <line x1="20" y1="17" x2="20" y2="20" />
        </svg>
      </div>
    </div>
  </div>

  <div *ngIf="workOrderTracking != null">
    <div
      class="product-material"
      [ngStyle]="{ background: productMaterialStyle }"
    >
      <div *ngFor="let item of materialLabels; let i = index">
        {{
          i % 2 === 0
            ? workOrderTracking?.primaryMaterial?.code ??
              workOrderTracking?.material?.code
            : workOrderTracking?.secondaryMaterial?.code ??
              workOrderTracking?.material?.code
        }}
      </div>
    </div>
    <div class="work-order-details">
      <div>
        <div
          class="field"
          style="
            margin-bottom: 16px;
            font-size: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          WKO# {{ workOrderId }}
        </div>
        <div class="image-container">
          <img
            class="item-image"
            [src]="workOrderTracking?.product.picture"
            onerror="this.src='assets/images/no-image.png'"
          />
          <!-- <span
              class="img-custom-work-order work-order-table"
              *ngIf="element.customized"
              >CUSTOM</span
            > -->
        </div>
        <h1 class="item-name">
          {{ workOrderTracking?.product.productName }}
        </h1>
        <p style="margin-top: 16px">
          {{ workOrderTracking?.product.description }}
        </p>
        <div class="two-column">
          <div class="field" style="margin-bottom: 16px">
            <div class="field__title">Qty</div>
            <div class="field__value">
              {{ workOrderTracking?.qty || 0 }}
            </div>
          </div>
          <div class="field" style="margin-bottom: 16px">
            <div class="field__title">PO</div>
            <div class="field__value">
              {{
                workOrderTracking?.purchaseOrder.poExternal ||
                  workOrderTracking?.purchaseOrder.poInternal
              }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3>Sub SKU & Quantities</h3>
        <table>
          <thead>
            <tr>
              <th style="width: 90px">Index</th>
              <th style="width: 150px">CI-PO</th>
              <th style="width: 90px">QTY</th>
              <th>
                {{ workOrderTracking?.itemType === 2 ? 'Length' : 'Size' }}
              </th>
              <th *ngIf="workOrderTracking?.itemType === 1">Length</th>
              <th *ngIf="workOrderTracking?.itemType === 0">Enamel Color</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of workOrderTracking?.workOrderDetails">
              <td>
                {{ item.index }}
              </td>
              <td>{{ item.orderDetails.cipo }}</td>
              <td>{{ item.qty }}</td>
              <td>
                {{ item.orderDetails.size
                }}<span *ngIf="workOrderTracking?.itemType === 2">"</span>
              </td>
              <td *ngIf="workOrderTracking?.itemType === 1">
                {{ item.orderDetails?.chainRawLength || ''
                }}<span *ngIf="item.orderDetails?.chainRawLength">"</span>
              </td>
              <td *ngIf="workOrderTracking?.itemType === 0">
                <div class="enamel__color">
                  <div
                    style="width: 30px; height: 30px; cursor: pointer"
                    [ngStyle]="{
                      'background-color': item.orderDetails.enamel?.colorHex
                    }"
                  ></div>
                  <div>{{ item.orderDetails.enamel?.enamelName }}</div>
                </div>
              </td>
              <td>{{ item.orderDetails.comment }}</td>
            </tr>
          </tbody>
        </table>

        <div class="bill-of-materials">
          <ng-container *ngIf="workOrderTracking?.subItems?.length > 0">
            <h3>Bill of Materials</h3>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>UOM</th>
                  <th>Batch Qty</th>
                  <th>Size & MFG Method</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of workOrderTracking?.subItems">
                  <td>
                    <div
                      style="
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                      "
                      *ngIf="item.picPath"
                    >
                      <img
                        style="max-width: 100%; max-height: 100%"
                        [src]="item.picPath"
                        onerror="this.src='assets/images/no-image.png'"
                      />
                    </div>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.unitOfMeasure.description }}</td>
                  <td>{{ item.batchQTY }}</td>
                  <td>
                    <div
                      [ngClass]="{
                        'two-column': item.subItemsInfo?.length > 4
                      }"
                    >
                      <div
                        class="sub-items__info"
                        *ngFor="let subItem of item.subItemsInfoForDisplay"
                      >
                        <div class="sub-items__info-size" *ngIf="subItem.size">
                          <span>{{ subItem.size }}</span>
                        </div>
                        <div
                          class="sub-items__info-details"
                          *ngIf="subItem.mfgMethodText"
                        >
                          <div>{{ subItem.mfgMethodText }}</div>
                          <span
                            class="other-details"
                            *ngIf="
                              subItem.mfgMethodDetails &&
                              subItem?.mfgMethod !== 3
                            "
                            >{{ subItem.mfgMethodDetails }}</span
                          >
                          <span
                            class="other-details"
                            *ngIf="
                              subItem.mfgMethodDetails &&
                              subItem?.mfgMethod !== 3 &&
                              subItem.qty > 0
                            "
                            style="padding: 4px"
                          >
                            |
                          </span>
                          <span class="other-details" *ngIf="subItem.qty > 0"
                            >Qty: {{ subItem.qty }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>

          <ng-container *ngIf="workOrderTracking?.itemType === 0">
            <ng-container *ngIf="workOrderTracking?.findings.length > 0">
              <h3 style="margin-top: 16px">Findings</h3>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Qty</th>
                    <th>Name</th>
                    <th>Material</th>
                    <th>Size</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of workOrderTracking?.findings">
                    <td>
                      <div
                        style="
                          width: 35px;
                          height: 35px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          position: relative;
                        "
                        *ngIf="item.findings.picPath"
                      >
                        <img
                          style="max-width: 100%; max-height: 100%"
                          [src]="item.findings.picPath"
                          onerror="this.src='assets/images/no-image.png'"
                        />
                      </div>
                    </td>
                    <td>{{ item.qty }}</td>
                    <td>
                      {{ item.findings.name ?? item.size.findingsTypeName }}
                    </td>
                    <td>{{ item.material.description }}</td>
                    <td>{{ sizeMapperInstance(item.size) }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <ng-container *ngIf="workOrderTracking?.chains.length > 0">
              <h3 style="margin-top: 16px">Chain</h3>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Index</th>
                    <th>Name</th>
                    <th>Style Name</th>
                    <th>SKU</th>
                    <th>Size/Length</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of workOrderTracking?.chains">
                    <td>
                      <div
                        style="
                          width: 35px;
                          height: 35px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          position: relative;
                        "
                        *ngIf="item.picturePath"
                      >
                        <img
                          style="max-width: 100%; max-height: 100%"
                          [src]="item.picturePath"
                          onerror="this.src='assets/images/no-image.png'"
                        />
                      </div>
                    </td>
                    <td>{{ item.index }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.styleName }}</td>
                    <td>{{ item.sku }}</td>
                    <td>{{ item.lengthOrSize }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>

            <ng-container
              *ngIf="
                workOrderTracking?.stoneVariations?.length > 0 ||
                workOrderTracking?.stoneConstants?.length > 0
              "
            >
              <h3 style="margin-top: 16px">Stones</h3>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Index</th>
                    <th>Qty</th>
                    <th>Type</th>
                    <th>Cut</th>
                    <th>Clarity</th>
                    <th>Color</th>
                    <th>Size</th>
                    <th>Lab</th>
                    <th>Trace</th>
                    <th>Cut</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="stone-variation"
                    *ngFor="let item of workOrderTracking?.stoneVariations"
                  >
                    <td>
                      <div
                        style="
                          width: 35px;
                          height: 35px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          position: relative;
                        "
                        *ngIf="item.picturePath"
                      >
                        <img
                          style="max-width: 100%; max-height: 100%"
                          [src]="item.picturePath"
                          onerror="this.src='assets/images/no-image.png'"
                        />
                      </div>
                    </td>
                    <td>{{ item.index }}</td>
                    <td>{{ item.qty }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.cut }}</td>
                    <td>{{ item.clarity }}</td>
                    <td>{{ item.color }}</td>
                    <td>{{ stoneVariationSizeMapperInstance(item.size) }}</td>
                    <td>{{ item.labGrown ? 'Yes' : 'No' }}</td>
                    <td>{{ item.traceable ? 'Yes' : 'No' }}</td>
                    <td>{{ item.customerProvided ? 'Yes' : 'No' }}</td>
                  </tr>
                  <tr *ngFor="let item of workOrderTracking?.stoneConstants">
                    <td>
                      <div
                        style="
                          width: 35px;
                          height: 35px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          position: relative;
                        "
                        *ngIf="item.stone.picturePath"
                      >
                        <img
                          style="max-width: 100%; max-height: 100%"
                          [src]="item.stone.picturePath"
                          onerror="this.src='assets/images/no-image.png'"
                        />
                      </div>
                    </td>
                    <td>{{ item.index }}</td>
                    <td>
                      <div
                        style="display: flex; align-items: center; gap: 2px"
                        *ngFor="let override of item.overrides"
                      >
                        <div class="sub-items__info-size">
                          {{ override.size }}
                        </div>
                        <span>
                          {{ override.qty }}
                        </span>
                      </div>
                      <span *ngIf="!item.overrides?.length">{{
                        item.qty
                      }}</span>
                    </td>
                    <td>{{ item.stone.stoneType.name }}</td>
                    <td>{{ item.stone.stoneCut.name }}</td>
                    <td>{{ item.stone.stoneClarity.name }}</td>
                    <td>{{ item.stone.stoneColor.colorName }}</td>
                    <td>{{ stoneMapperInstance(item.stone.stoneSize) }}</td>
                    <td>{{ item.stone.labGrown ? 'Yes' : 'No' }}</td>
                    <td>{{ item.stone.traceable ? 'Yes' : 'No' }}</td>
                    <td>{{ item.stone.customerProvided ? 'Yes' : 'No' }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-container>
            <div
              class="enamel-list"
              *ngIf="workOrderTracking?.enamels?.length > 0"
            >
              <h3 style="margin: 0; margin-right: 8px">Enamels</h3>
              <div
                class="enamel__color"
                *ngFor="let item of workOrderTracking?.enamels"
              >
                <div
                  style="width: 30px; height: 30px; cursor: pointer"
                  [ngStyle]="{ 'background-color': item.colorHex }"
                ></div>
                <div>{{ item.enamelName }}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div>
        <div
          class="field"
          style="margin-bottom: 16px"
          [ngClass]="{ 'employee-success': employeeScannedSuccess }"
        >
          <div class="field__title">Employee</div>
          <div class="field__value">
            <span *ngIf="selectedEmployee">
              {{ selectedEmployee?.firstName }} {{ selectedEmployee?.lastName }}
            </span>
            <span *ngIf="!selectedEmployee"> - </span>
          </div>
        </div>
        <fieldset
          [disabled]="bannerMessage !== 'SCAN ROUTING CODE'"
          [ngClass]="{
            'disabled-field': bannerMessage !== 'SCAN ROUTING CODE'
          }"
        >
          <mat-form-field
            class="search-input"
            appearance="fill"
            style="width: 100%"
          >
            <mat-label>Search routing</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="selectedRouting"
              [formControl]="productRoutingControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option class="riva-select-header riva-select__stick-header">
                <div class="riva-select">
                  <div class="p5 riva-select__code">Activity Code</div>
                  <div class="p5 riva-select__department">Department</div>
                  <div class="p5 riva-select__description">Description</div>
                </div>
              </mat-option>
              <mat-option class="riva-select-header">
                <div class="riva-select">
                  <div class="p5 riva-select__code">Activity Code</div>
                  <div class="p5 riva-select__department">Department</div>
                  <div class="p5 riva-select__description">Description</div>
                </div>
              </mat-option>
              <mat-option
                *ngFor="
                  let productRoutingCode of filteredProductRoutingCodes | async
                "
                style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
                (click)="onSelectProductRoutingCode(productRoutingCode)"
              >
                <div class="riva-select">
                  <div class="p5 riva-select__code">
                    {{ productRoutingCode.activityCode }}
                  </div>
                  <div class="p5 riva-select__department">
                    {{ productRoutingCode.department }}
                  </div>
                  <div class="p5 riva-select__description">
                    {{ productRoutingCode.activityDesc }}
                  </div>
                </div>
              </mat-option>
              <mat-option
                *ngIf="!(filteredProductRoutingCodes | async)?.length"
              >
                <div class="text-center riva-select__not-found">Not Found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </fieldset>
        <div class="movement-history">
          <h2>Movement History</h2>
          <table>
            <thead>
              <tr>
                <th>Routing</th>
                <th>Scan Out</th>
                <th>Scan In</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of workOrderMovementHistory">
                <td>
                  {{ item.routingCode.activityDesc }}
                </td>
                <td>{{ item.scanOutTime }}</td>
                <td>{{ item.scanInTime }}</td>
              </tr>
              <tr *ngIf="workOrderMovementHistory.length === 0">
                <td colspan="3" style="text-align: center">
                  No Movement Available
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="jira-details" *ngIf="jiraDetail != null">
    <div style="width: 500px">
      <div
        class="field"
        style="
          margin-bottom: 16px;
          font-size: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        {{ jiraId?.toUpperCase() }}
      </div>
      <div class="image-container">
        <img
          class="item-image"
          [src]="jiraDetail?.picPath"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="fields">
        <div class="jira-field">
          <div class="jira-field__title">Name</div>
          <div class="jira-field__value">
            {{ jiraDetail?.name || '–' }}
          </div>
        </div>
        <div class="jira-field">
          <div class="jira-field__title">Status</div>
          <div class="jira-field__value">
            {{ jiraDetail?.status || '–' }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2>Description</h2>
      <div *ngFor="let item of jiraDetail.description?.content">
        <p *ngIf="item.type === 'paragraph' && item.content.length > 0">
          <span *ngFor="let i of item.content">{{ i.text }}</span>
        </p>
      </div>
    </div>
    <div>
      <div
        class="field"
        style="margin-bottom: 16px"
        [ngClass]="{ 'employee-success': employeeScannedSuccess }"
      >
        <div class="field__title">Employee</div>
        <div class="field__value">
          <span *ngIf="selectedEmployee">
            {{ selectedEmployee?.firstName }} {{ selectedEmployee?.lastName }}
          </span>
          <span *ngIf="!selectedEmployee"> - </span>
        </div>
      </div>
      <fieldset
        [disabled]="bannerMessage !== 'SCAN ROUTING CODE'"
        [ngClass]="{
          'disabled-field': bannerMessage !== 'SCAN ROUTING CODE'
        }"
      >
        <mat-form-field
          class="search-input"
          appearance="fill"
          style="width: 100%"
        >
          <mat-label>Search routing</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="selectedRouting"
            [formControl]="productRoutingControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="displayFn"
          >
            <mat-option class="riva-select-header riva-select__stick-header">
              <div class="riva-select">
                <div class="p5 riva-select__code">Activity Code</div>
                <div class="p5 riva-select__department">Department</div>
                <div class="p5 riva-select__description">Description</div>
              </div>
            </mat-option>
            <mat-option class="riva-select-header">
              <div class="riva-select">
                <div class="p5 riva-select__code">Activity Code</div>
                <div class="p5 riva-select__department">Department</div>
                <div class="p5 riva-select__description">Description</div>
              </div>
            </mat-option>
            <mat-option
              *ngFor="
                let productRoutingCode of filteredProductRoutingCodes | async
              "
              style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
              (click)="onSelectProductRoutingCodeForJira(productRoutingCode)"
            >
              <div class="riva-select">
                <div class="p5 riva-select__code">
                  {{ productRoutingCode.activityCode }}
                </div>
                <div class="p5 riva-select__department">
                  {{ productRoutingCode.department }}
                </div>
                <div class="p5 riva-select__description">
                  {{ productRoutingCode.activityDesc }}
                </div>
              </div>
            </mat-option>
            <mat-option *ngIf="!(filteredProductRoutingCodes | async)?.length">
              <div class="text-center riva-select__not-found">Not Found</div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </fieldset>
      <div class="movement-history">
        <h2>PD Movement History</h2>
        <table>
          <thead>
            <tr>
              <th>Routing</th>
              <th>Scan Out</th>
              <th>Scan In</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of pdWorkOrderMovementHistory">
              <td>
                {{ item.routingCode.activityDesc }}
              </td>
              <td>{{ item.scanOutTime }}</td>
              <td>{{ item.scanInTime }}</td>
            </tr>
            <tr *ngIf="pdWorkOrderMovementHistory?.length === 0">
              <td colspan="3" style="text-align: center">
                No Movement Available
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isScanning">
  <div class="stone-release-scanning-overlay" @fadeAnimation>
    <div class="scanning-message">
      <div
        class="scanning-content"
        [ngClass]="{
          'has-error':
            hasWorkOrderError ||
            hasEmployeeError ||
            hasRoutingError ||
            hasMovementError ||
            hasJiraScanError
        }"
      >
        <div class="scanning-icon">
          <svg
            *ngIf="
              !hasWorkOrderError &&
              !hasEmployeeError &&
              !hasRoutingError &&
              !hasMovementError &&
              !successMovement &&
              !hasJiraScanError
            "
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-qrcode"
            width="70"
            height="70"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ffffff"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 17l0 .01" />
            <path
              d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 7l0 .01" />
            <path
              d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M17 7l0 .01" />
            <path d="M14 14l3 0" />
            <path d="M20 14l0 .01" />
            <path d="M14 14l0 3" />
            <path d="M14 20l3 0" />
            <path d="M17 17l3 0" />
            <path d="M20 17l0 3" />
          </svg>

          <svg
            *ngIf="
              hasWorkOrderError ||
              hasEmployeeError ||
              hasRoutingError ||
              hasMovementError ||
              hasJiraScanError
            "
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-qrcode-off"
            width="70"
            height="70"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#ff4500"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M8 4h1a1 1 0 0 1 1 1v1m-.297 3.711a1 1 0 0 1 -.703 .289h-4a1 1 0 0 1 -1 -1v-4c0 -.275 .11 -.524 .29 -.705"
            />
            <path d="M7 17v.01" />
            <path
              d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M7 7v.01" />
            <path
              d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
            />
            <path d="M17 7v.01" />
            <path d="M20 14v.01" />
            <path d="M14 14v3" />
            <path d="M14 20h3" />
            <path d="M3 3l18 18" />
          </svg>

          <svg
            *ngIf="successMovement"
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-circle-check-filled"
            width="70"
            height="70"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#00b341"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
              stroke-width="0"
              fill="#00b341"
            />
          </svg>
        </div>
        <div>
          <div
            *ngIf="
              !hasWorkOrderError &&
              !hasEmployeeError &&
              !hasRoutingError &&
              !hasMovementError &&
              !successMovement &&
              !hasJiraScanError
            "
          >
            Scanning
          </div>
          <div *ngIf="hasJiraScanError">
            <div class="error-title">Oops! Something Went Wrong</div>
            <p class="error-message">
              {{ jiraScanErrorMessage }}
            </p>
            <button
              class="riva-button"
              style="width: 150px"
              (click)="resetScan()"
            >
              Close
            </button>
          </div>
          <div *ngIf="hasWorkOrderError">
            <div class="error-title">Oops! Something Went Wrong</div>
            <p class="error-message">
              We’re sorry, but we’re experiencing an issue. Please verify that
              the work order number is correct.
            </p>
            <button
              class="riva-button"
              style="width: 150px"
              (click)="resetScan()"
            >
              Close
            </button>
          </div>
          <div *ngIf="hasEmployeeError">
            <div *ngIf="employeeInProgressWorkOrderId === 0">
              <div class="error-title">Employee Not Found</div>
              <p class="error-message">
                We’re sorry, but we can't find the Employee ID :
                {{ this.code }}.
              </p>
            </div>
            <div *ngIf="employeeInProgressWorkOrderId > 0">
              <div class="error-title">Employee Has Check Out</div>
              <p class="error-message">
                User
                <span *ngIf="selectedEmployee.firstName"
                  >{{ selectedEmployee.firstName }}
                </span>
                <span *ngIf="selectedEmployee.lastName"
                  >{{ selectedEmployee.lastName }}
                </span>
                still has Work Order '{{ employeeInProgressWorkOrderId }}'
                checked out, please close it before checking out any others.
              </p>
            </div>
            <button
              class="riva-button"
              style="width: 150px"
              (click)="onCloseErrorMessage()"
            >
              Close
            </button>
          </div>
          <div *ngIf="hasRoutingError">
            <div class="error-title">Routing Code Not Found</div>
            <p class="error-message">
              We’re sorry, but we can't find the Routing Code : {{ this.code }}.
            </p>
            <button
              class="riva-button"
              style="width: 150px"
              (click)="onCloseErrorMessage()"
            >
              Close
            </button>
          </div>
          <div *ngIf="hasMovementError">
            <div class="error-title">Unable to move work order</div>
            <p class="error-message">
              We’re sorry, but we can't proceed the movement request for work
              order # '{{ this.workOrderId }}'. Please try again.
            </p>
            <button
              class="riva-button"
              style="width: 150px"
              (click)="onCloseErrorMessage()"
            >
              Close
            </button>
          </div>
          <div *ngIf="successMovement">
            <div style="color: #00b341">
              <span *ngIf="scanMovementStatus === 'ScanIn'">Scanned In</span>
              <span *ngIf="scanMovementStatus === 'ScanOut'">Scanned Out</span>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            !hasWorkOrderError &&
            !hasEmployeeError &&
            !hasRoutingError &&
            !hasMovementError &&
            !successMovement &&
            !hasJiraScanError
          "
        >
          <svg
            width="70"
            height="15"
            viewBox="0 0 120 30"
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
          >
            <circle cx="15" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="60" cy="15" r="9" fill-opacity="0.3">
              <animate
                attributeName="r"
                from="9"
                to="9"
                begin="0s"
                dur="0.8s"
                values="9;15;9"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="0.5"
                to="0.5"
                begin="0s"
                dur="0.8s"
                values=".5;1;.5"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="105" cy="15" r="15">
              <animate
                attributeName="r"
                from="15"
                to="15"
                begin="0s"
                dur="0.8s"
                values="15;9;15"
                calcMode="linear"
                repeatCount="indefinite"
              />
              <animate
                attributeName="fill-opacity"
                from="1"
                to="1"
                begin="0s"
                dur="0.8s"
                values="1;.5;1"
                calcMode="linear"
                repeatCount="indefinite"
              />
            </circle>
          </svg>
        </div>
      </div>
    </div>
  </div>
</ng-container>
