<div class="accounts-table-dialog">
  <h3
    mat-dialog-title
    style="display: flex; align-items: center; justify-content: space-between"
  >
    User Account

    <div style="display: flex; align-items: center; font-size: 14px">
      <div style="margin-right: 15px">No Login User</div>
      <div>
        <nz-switch
          [(ngModel)]="data.noLoginUser"
          [nzCheckedChildren]="checkedTemplate"
          [nzUnCheckedChildren]="unCheckedTemplate"
          (ngModelChange)="onChangeNoLoginUser()"
          [disabled]="data.usersID"
        ></nz-switch>
        <ng-template #checkedTemplate
          ><i nz-icon nzType="check"></i
        ></ng-template>
        <ng-template #unCheckedTemplate
          ><i nz-icon nzType="close"></i
        ></ng-template>
      </div>
    </div>
  </h3>
  <mat-dialog-content class="mat-typography">
    <div class="accounts-table-dialog__row">
      <mat-form-field class="account-input" appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput [(ngModel)]="data.firstName" />
      </mat-form-field>
      <mat-form-field class="account-input" appearance="fill">
        <mat-label>Last Name</mat-label>
        <input matInput [(ngModel)]="data.lastName" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="account-input" appearance="fill">
        <mat-label>Employee ID</mat-label>
        <input matInput [(ngModel)]="data.employeeID" [disabled]="true" />
      </mat-form-field>
    </div>
    <div
      class="username-container"
      [ngClass]="{ 'username-container-error': usernameExist }"
    >
      <mat-form-field class="account-input" appearance="fill">
        <mat-label>Username</mat-label>
        <input
          matInput
          [(ngModel)]="data.userName"
          (blur)="checkUsername()"
          [disabled]="data.noLoginUser"
        />
        <mat-error>Username already exist.</mat-error>
      </mat-form-field>
      <mat-error *ngIf="usernameExist" class="username-error"
        >Username already exist.</mat-error
      >
    </div>
    <div *ngIf="!data.usersID">
      <mat-form-field class="account-input" appearance="fill">
        <mat-label>Password</mat-label>
        <input
          matInput
          [(ngModel)]="data.password"
          type="password"
          [disabled]="data.noLoginUser"
        />
      </mat-form-field>
    </div>
    <mat-form-field class="account-input" appearance="fill">
      <mat-label>Timeout (min)</mat-label>
      <input matInput type="number" [(ngModel)]="data.tokenTimeout" min="0" />
    </mat-form-field>
    <div style="display: flex; align-items: center; font-size: 14px">
      <div style="margin-right: 15px">
        <nz-switch
          [(ngModel)]="data.allowedMultipleWorkOrder"
          [nzCheckedChildren]="checkedAllowedTemplate"
          [nzUnCheckedChildren]="unCheckedAllowedTemplate"
        ></nz-switch>
        <ng-template #checkedAllowedTemplate
          ><i nz-icon nzType="check"></i
        ></ng-template>
        <ng-template #unCheckedAllowedTemplate
          ><i nz-icon nzType="close"></i
        ></ng-template>
      </div>
      <div (click)="setAllowedMultipleWorkOrder()" style="cursor: pointer">
        Allow Multiple Work Order
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px"
  >
    <button
      class="btn btn-default"
      mat-dialog-close
      style="font-size: 12px; padding: 5px; height: 40px; color: white"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      style="font-size: 12px; padding: 5px; height: 40px"
      [disabled]="disabledSaveButton"
      (click)="onSaveAccount()"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
