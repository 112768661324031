import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export const getTypeLabel = (type: number) => {
  switch (type) {
    case 0:
      return 'Soft';
    case 1:
      return 'HyCeram';
    case 2:
      return 'Hard';
    default:
      return '';
  }
};
@Injectable()
export class GenericService {
  public baseUrl: string;
  public http: HttpClient;
  public ApiKey: string = environment.apiKey;
  public headers = new HttpHeaders()
    .set('Content-type', 'application/json')
    .set('X-ApiKey', this.ApiKey);

  public customHeader = new HttpHeaders().set('X-ApiKey', this.ApiKey);

  constructor(_http: HttpClient) {
    this.http = _http;
  }

  public get(id: number, endpoint: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + endpoint + '/' + id, {
      headers: this.headers,
    });
  }

  public getParamString(id: string, endpoint: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + endpoint + '/' + id, {
      headers: this.headers,
    });
  }

  public getList(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/list', {
      headers: this.headers,
    });
  }

  public getAll(): Observable<any> {
    return this.http.get<any>(this.baseUrl, {
      headers: this.headers,
    });
  }

  public post(object: any, endpoint: string): Observable<any> {
    return this.http.post(this.baseUrl + endpoint, JSON.stringify(object), {
      headers: this.headers,
    });
  }

  public put(object: any, endpoint: string): Observable<any> {
    return this.http.put(this.baseUrl + endpoint, JSON.stringify(object), {
      headers: this.headers,
    });
  }

  public delete(id: number, endpoint: string): Observable<any> {
    return this.http.delete(this.baseUrl + endpoint + '/' + id, {
      headers: this.headers,
    });
  }

  public uploadTempPhoto(object: FormData): Observable<any> {
    return this.http.post(this.baseUrl + '/upload-temp', object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
    });
  }

  public moveTemp(id: number, fileID: string): Observable<any> {
    return this.http.get(this.baseUrl + '/move-temp/' + id + '/' + fileID, {
      headers: this.headers,
    });
  }

  uploadPicture(object: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/upload`, object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
      responseType: 'text',
    });
  }
}
