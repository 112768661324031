import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FINDINGS_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/findings-permission';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { FindingRoutingComponent } from './finding-routing.component';

@Component({
  selector: 'finding-routing-dialog',
  templateUrl: './finding-routing-dialog.component.html',
})
export class FindingRoutingDialogComponent implements OnInit {
  @ViewChild(FindingRoutingComponent)
  findingRoutingComponent: FindingRoutingComponent;

  featureKey = FINDINGS_FEATURE_KEY;

  constructor(
    public dialogRef: MatDialogRef<FindingRoutingDialogComponent>,
    public loadService: LoadService,
    @Inject(MAT_DIALOG_DATA)
    public props: { findingId: number; editMode: boolean },
  ) {}

  ngOnInit(): void {}

  onSave() {
    this.findingRoutingComponent.onSaveFindingRouting();
  }
}
