import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { CustomerAddress } from 'src/app/models/customer';
import { CustomerAddressService } from 'src/app/services/customer-address.service';

@Component({
  selector: 'customer-address-dialog',
  templateUrl: './customer-address-dialog.component.html',
  styleUrls: ['./customer-address-dialog.component.scss'],
})
export class CustomerAddressDialogComponent implements OnInit {
  isSaving: boolean = false;

  customerAddress: CustomerAddress = {
    tempCustomersAddressesId: DateTime.now().valueOf(),
  } as CustomerAddress;

  constructor(
    public dialogRef: MatDialogRef<CustomerAddressDialogComponent>,
    public dialog: MatDialog,
    private customerAddressService: CustomerAddressService,
    @Inject(MAT_DIALOG_DATA)
    public props: {
      customerId: number;
      addressType?: number;
      customerAddress?: CustomerAddress;
    },
  ) {}

  ngOnInit(): void {
    if (this.props.customerAddress) {
      this.customerAddress = this.props.customerAddress;
    }
    this.customerAddress.custIdno = this.props.customerId;
    this.customerAddress.addressType = this.props.addressType ?? 0;
  }

  onSaveAddress() {
    if (!this.customerAddress.custIdno) {
      this.dialogRef.close(this.customerAddress);
      return;
    }
    this.isSaving = true;
    this.customerAddressService
      .setCustomerAddress(this.customerAddress)
      .subscribe((customerAddress) => {
        this.isSaving = false;
        this.dialogRef.close(customerAddress);
      });
  }

  get isFormValid() {
    const { address1, city, region, postalCode, country } =
      this.customerAddress;
    return address1 && city && region && postalCode && country;
  }
}
