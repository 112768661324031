import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Chain,
  ChainLinkSize,
  ChainType,
  RivaChain,
  RivaChainLength,
  RivaChainMaterial,
} from 'src/app/models/chain.model';
import { environment } from 'src/environments/environment';
import {
  ChainFinish,
  ChainFinishBillOfMaterial,
  ChainFinishSize,
  ChainFinishStock,
  OrderDetailAlt,
} from '../components/riva-chain/riva-chain-finish/models';
import {
  ChainRaw,
  ChainRawSize,
  ChainRawStock,
  ChainRouting,
  ChainStyle,
} from '../components/riva-chain/riva-chain/models';
import { ChainFinishMaterial } from './../components/riva-chain/riva-chain-finish/models';
import { GenericService } from './generic.service';
import { sizeMapper } from './product-bom.service';

@Injectable({
  providedIn: 'root',
})
export class ChainService extends GenericService {
  baseUrl = environment.apiUrl + 'chain';
  chainUrl = `${environment.apiUrl}chains`;
  chainRawUrl = `${environment.apiUrl}chains/raw`;
  chainRawSizeUrl = `${environment.apiUrl}chains/raw-sizes`;
  chainRawStockUrl = `${environment.apiUrl}chains/raw-stock`;
  chainMaterialUrl = `${environment.apiUrl}chains-materials`;
  chainLengthUrl = `${environment.apiUrl}chains-lengths`;
  chainFinishedUrl = `${environment.apiUrl}chain-finished`;
  chainOrderDetailUrl = `${environment.apiUrl}order-details-alt`;
  chainStyleUrl = `${environment.apiUrl}chains/styles`;
  chainRoutingUrl = `${environment.apiUrl}chain-routing`;

  reloadChains = (chainsId?: number) => {};
  reloadRawChainTypes = () => {};
  reloadFinishChainTypes = () => {};

  constructor(http: HttpClient) {
    super(http);
  }

  getList(): Observable<any> {
    return this.http.get(this.baseUrl + '/list', { headers: this.headers });
  }

  addupdate(chain: Chain): Observable<any> {
    return this.http.post(this.baseUrl + '/addupdate', chain, {
      headers: this.headers,
    });
  }

  getListType(): Observable<any> {
    return this.http.get(this.baseUrl + '/listtype', { headers: this.headers });
  }

  addchaintype(chainType: ChainType): Observable<any> {
    return this.http.post(this.baseUrl + '/addtype', chainType, {
      headers: this.headers,
    });
  }

  getListLinkSize(): Observable<any> {
    return this.http.get(this.baseUrl + '/listlinksize', {
      headers: this.headers,
    });
  }

  addlinksize(chainlinksize: ChainLinkSize): Observable<any> {
    return this.http.post(this.baseUrl + '/addlinksize', chainlinksize, {
      headers: this.headers,
    });
  }

  delete(findingsID: number): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + findingsID, {
      headers: this.headers,
    });
  }

  uploadImage(object: FormData): Observable<any> {
    return this.http.post(this.baseUrl + '/upload', object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
    });
  }

  lastupdate(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/lastupdate/' + id.toString(), {
      headers: this.headers,
    });
  }

  getChain(item: Chain): Observable<any> {
    return this.http.post(this.baseUrl + '/getchain', item, {
      headers: this.headers,
    });
  }

  getChains(): Observable<RivaChain[]> {
    return this.http.get<RivaChain[]>(this.chainUrl, {
      headers: this.headers,
    });
  }

  setChain(chain: RivaChain) {
    return this.http.post(this.chainUrl, chain, {
      headers: this.headers,
    });
  }

  getChainRaws(): Observable<ChainRaw[]> {
    return this.http
      .get<ChainRaw[]>(this.chainRawUrl, {
        headers: this.headers,
      })
      .pipe(
        map((data) =>
          data.map((c) => ({
            ...c,
            ...(c.chainStyle ?? {}),
          })),
        ),
      );
  }
  getChainRawSizes(chainRawId): Observable<ChainRawSize[]> {
    return this.http.get<ChainRawSize[]>(
      `${this.chainRawSizeUrl}/${chainRawId}`,
      {
        headers: this.headers,
      },
    );
  }
  setChainRawSize(chainSize: ChainRawSize) {
    return this.http.post(this.chainRawSizeUrl, chainSize, {
      headers: this.headers,
    });
  }

  getChainRawStock(chainRawID): Observable<ChainRawStock[]> {
    return this.http.get<ChainRawStock[]>(
      `${this.chainRawStockUrl}/${chainRawID}`,
      {
        headers: this.headers,
      },
    );
  }
  setChainRawStock(chainStock: ChainRawStock) {
    return this.http.post(this.chainRawStockUrl, chainStock, {
      headers: this.headers,
    });
  }

  setChainRaw(chain: ChainRaw) {
    return this.http.post(this.chainRawUrl, chain, {
      headers: this.headers,
    });
  }
  chainRawUploadImage(object: FormData): Observable<any> {
    return this.http.post(this.chainRawUrl + '/upload', object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
      responseType: 'text' as 'json',
    });
  }

  chainUploadImage(object: FormData): Observable<any> {
    return this.http.post(this.chainUrl + '/upload', object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
    });
  }

  getChainMaterials(chainsID: number): Observable<RivaChainMaterial[]> {
    return this.http.get<RivaChainMaterial[]>(this.chainMaterialUrl, {
      headers: this.headers,
      params: {
        chainsID,
      },
    });
  }

  setChainMaterial(chainMaterial: RivaChainMaterial) {
    return this.http.post(this.chainMaterialUrl, chainMaterial, {
      headers: this.headers,
    });
  }

  getChainLengths(chainsID: number): Observable<RivaChainLength[]> {
    return this.http.get<RivaChainLength[]>(this.chainLengthUrl, {
      headers: this.headers,
      params: {
        chainsID,
      },
    });
  }

  setChainLength(chainLength: RivaChainLength) {
    return this.http.post(this.chainLengthUrl, chainLength, {
      headers: this.headers,
    });
  }
  getChainFinished(): Observable<ChainFinish[]> {
    return this.http
      .get<ChainFinish[]>(this.chainFinishedUrl, {
        headers: this.headers,
      })
      .pipe(
        map((chains) =>
          chains.map((chain) => ({
            ...chain,
            styleName: chain.chainRaw?.chainStyle?.styleName ?? '',
            sku: chain.chainRaw?.sku ?? '',
          })),
        ),
      );
  }
  setChainFinished(chainFinished: ChainFinish) {
    return this.http.post(this.chainFinishedUrl, chainFinished, {
      headers: this.headers,
    });
  }
  uploadFinishedImage(object: FormData): Observable<any> {
    return this.http.post(this.chainFinishedUrl + '/upload', object, {
      headers: this.customHeader,
      reportProgress: true,
      observe: 'events',
      responseType: 'text' as 'json',
    });
  }
  getChainFinishedBom(id): Observable<ChainFinishBillOfMaterial[]> {
    return this.http
      .get<ChainFinishBillOfMaterial[]>(`${this.chainFinishedUrl}/bom/${id}`, {
        headers: this.headers,
      })
      .pipe(
        map((items) =>
          items.map((item) => ({
            ...item,
            name: item.findings.sizes.findingsTypeName,
            size: sizeMapper(item.findings.sizes),
          })),
        ),
      );
  }
  setChainFinishedBom(data: ChainFinishBillOfMaterial) {
    return this.http.post(`${this.chainFinishedUrl}/bom`, data, {
      headers: this.headers,
    });
  }
  getChainFinishedSizes(chainFinishedId: number) {
    return this.http.get<ChainFinishSize[]>(
      `${this.chainFinishedUrl}/sizes/${chainFinishedId}`,
      {
        headers: this.headers,
      },
    );
  }
  setChainFinishedSizes(size: ChainFinishSize) {
    return this.http.post(`${this.chainFinishedUrl}/sizes`, size, {
      headers: this.headers,
    });
  }
  getChainFinishedMaterials(chainFinishedId: number) {
    return this.http.get<ChainFinishMaterial[]>(
      `${this.chainFinishedUrl}/materials/${chainFinishedId}`,
      {
        headers: this.headers,
      },
    );
  }
  setChainFinishedMaterial(material: ChainFinishMaterial) {
    return this.http.post(`${this.chainFinishedUrl}/materials`, material, {
      headers: this.headers,
    });
  }
  getChainFinishedStocks(chainFinishedSizeId: number) {
    return this.http.get<ChainFinishStock[]>(
      `${this.chainFinishedUrl}/stock/${chainFinishedSizeId}`,
      {
        headers: this.headers,
      },
    );
  }
  setChainFinishedStocks(stock: ChainFinishStock) {
    return this.http.post(`${this.chainFinishedUrl}/stock`, stock, {
      headers: this.headers,
    });
  }

  getChainOrderDetail(orderId: number) {
    return this.http.get<OrderDetailAlt[]>(
      `${this.chainOrderDetailUrl}/${orderId}`,
      {
        headers: this.headers,
      },
    );
  }

  setChainOrderDetail(order: OrderDetailAlt) {
    return this.http.post(this.chainOrderDetailUrl, order, {
      headers: this.headers,
    });
  }

  getChainStyles(): Observable<ChainStyle[]> {
    return this.http.get<ChainStyle[]>(this.chainStyleUrl, {
      headers: this.headers,
    });
  }

  setChainStyles(chain: ChainStyle) {
    return this.http.post(this.chainStyleUrl, chain, {
      headers: this.headers,
    });
  }

  getChainRoutings(chainId, type): Observable<ChainRouting[]> {
    return this.http.get<ChainRouting[]>(this.chainRoutingUrl, {
      headers: this.headers,
      params: {
        chainId,
        type,
      },
    });
  }

  setChainRouting(chain: ChainRouting[]) {
    return this.http.post(this.chainRoutingUrl, chain, {
      headers: this.headers,
    });
  }

  deleteChainRouting(routingId) {
    return this.http.delete(`${this.chainRoutingUrl}/${routingId}`, {
      headers: this.headers,
    });
  }
}
