import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductFindings } from 'src/app/models/findings.model';
import { ProductChainRaw } from '../model';

@Component({
  selector: 'product-chain-unfinished-table',
  styleUrls: ['./product-chain-unfinished-table.component.scss'],
  templateUrl: './product-chain-unfinished-table.component.html',
})
export class ProductChainUnfinishedTableComponent implements OnInit {
  @Input() productChainUnfinished: ProductChainRaw[];
  @Input() editMode: boolean;
  @Output() onDeleteChain = new EventEmitter<ProductFindings>();

  displayedColumns: string[] = ['picPath', 'styleName', 'sku', 'lengthInches'];
  action: string[] = ['delete'];

  ngOnInit(): void {}
}
