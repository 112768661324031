import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateTime } from 'luxon';
import {
  AnalyticsMaterial,
  AnalyticsService,
  InvoicedStone,
} from 'src/app/services/analytics.service';
import { CustomerService } from 'src/app/services/customer.service';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}
@Component({
  selector: 'riva-reporting-invoice',
  templateUrl: './reporting-invoice.component.html',
  styleUrls: ['./reporting-invoice.component.scss'],
})
export class ReportingInvoiceComponent implements OnInit {
  @ViewChild('materialsSort', { read: MatSort, static: true })
  materialSort: MatSort;
  @ViewChild('stonesSort', { read: MatSort, static: true }) stoneSort: MatSort;

  filters: Filters = {
    dateRangeFrom: DateTime.now().toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.now().toFormat('yyyy-MM-dd'),
  };
  isDateRange = false;

  materialList = new MatTableDataSource<AnalyticsMaterial>([]);
  stoneList = new MatTableDataSource<InvoicedStone>([]);
  totalInvoiced: number = 0;
  displayedColumns = ['description', 'totalWeightShipped'];
  stoneColumns = [
    'stoneType',
    'stoneCut',
    'stoneClarity',
    'stoneColor',
    'stoneSize',
    'labGrown',
    'traceable',
    'customerProvided',
    'invoicedQty',
  ];

  constructor(
    private customerService: CustomerService,
    private analyticsService: AnalyticsService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.getReports();
  }
  ngAfterViewInit() {
    this.materialList.sort = this.materialSort;
    this.stoneList.sort = this.stoneSort;
  }

  getReports() {
    this.getAnalyticsMaterial();
    this.getAnalyticsInvoiced();
    this.getAnalyticsInvoicedStones();
  }
  getAnalyticsMaterial() {
    this.analyticsService
      .getAnalyticsInvoicedWeights({
        start: this.filters.dateRangeFrom,
        end: this.filters.dateRangeTo,
      })
      .subscribe((data) => {
        this.materialList.data = data.filter((d) => d.totalWeightShipped > 0);
      });
  }
  getAnalyticsInvoiced() {
    this.analyticsService
      .getAnalyticsInvoicedTimePeriod({
        start: this.filters.dateRangeFrom,
        end: this.filters.dateRangeTo,
      })
      .subscribe(({ totalInvoiced }) => {
        this.totalInvoiced = totalInvoiced;
      });
  }
  getAnalyticsInvoicedStones() {
    this.analyticsService
      .getAnalyticsInvoicedStones({
        start: this.filters.dateRangeFrom,
        end: this.filters.dateRangeTo,
      })
      .subscribe((data) => {
        this.stoneList.data = data;
      });
  }
  onSetDateRange(config) {
    this.filters.dateRangeFrom = config.start;
    this.filters.dateRangeTo = config.end;
    this.getReports();
  }
  onSetDate() {
    this.filters.dateRangeFrom = DateTime.fromJSDate(
      new Date(this.filters.dateRangeFrom),
    ).toFormat('yyyy-MM-dd');
    this.filters.dateRangeTo = DateTime.fromJSDate(
      new Date(this.filters.dateRangeFrom),
    ).toFormat('yyyy-MM-dd');
    this.getReports();
  }
}
